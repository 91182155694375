/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect } from "react";
import { Button } from "./Button"
import { Modal } from "./Modal"
import { useTranslation } from "./Translation";
import { useHook } from "./Utils";

const confirmationModalStyle = {
    root: css`
        display: flex;
        height: 31.6vh;
        width: 44.6vh;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px;

        & > div > p {
            font: normal normal normal 16px/24px Roboto;
            letter-spacing: 0.14px;
            color: #858585;
            margin-top: 41px;
        }

        & > div > h2 {
            color: #E0758A;
        }
    `,
    actions: css`
        display: flex;
        justify-content: flex-end;

        & > button:first-of-type {
            color: #858585;
            border-color: #858585;
            padding: 7.5px 40px;
        }

        & > button:last-of-type {
            background-color: #E0758A;
            margin-left: 20px;
            padding: 7.5px 40px;

        }
    `,
    modal: css`
        & .MuiDialog-paper {
            padding: 0;
        }
    `
}

export const DeleteConfirmationModal = (
    {
        title,
        description,
        onDelete,
        onCancel,
        deleteLabel,
        ...props
    }
) => {
    const { t } = useTranslation();
    const $disabled = useHook(false);

    useEffect(() => {
        $disabled.set(false);
    }, [props.open])

    return (
        <Modal css={confirmationModalStyle.modal}
            onClose={onCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableCloseButton
            {...props}
        >
            <div css={confirmationModalStyle.root}>
                <div>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
                <div css={confirmationModalStyle.actions}>
                    <Button
                        variant="outlined"
                        onClick={onCancel}
                    >
                        {t('actions.cancel')}
                    </Button>
                    <Button
                        disabled={$disabled.value}
                        onClick={() => {
                            $disabled.set(true);
                            onDelete();
                        }}
                    >
                        {deleteLabel || t('actions.delete')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
