/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { useTranslation } from "../CoreComponents/Translation"

const privacyPolicyStyle = css`
    margin: 40px;
    height: calc(100% - 120px);
    overflow: auto;
    margin-bottom: 40px;

    & .main-container {
        margin: 0 32px;
        height: 100%;

        & > ol {
            & > li {
                font-size: 24px;
                color: #3A817F;
            }

            & > ol > li {
                list-style-type: square;
            }
        }
    }

    & p, & li, & span {
        font-size: 18px;
    }

    & .black {
        color: #000;
    }

    & h1 {
        font-size: 2em;
    }
`

export const PrivacyPolicy = () => {
    const { t } = useTranslation();
    return (
        <div css={privacyPolicyStyle}>
            <div className="main-container">
                <h1>{t('privacyPolicy.title')}</h1>
                <p>{t('privacyPolicy.lastUpdated')}</p>
                <ol>
                    <li>{t('privacyPolicy.section1')}</li>
                    <p>{t('privacyPolicy.intro')}</p>
                    <li>{t('privacyPolicy.section2')}</li>
                    <p>{t('privacyPolicy.section2.info')}</p>
                    <ol>
                        <li>{t('privacyPolicy.section2.subsection1')}</li>
                        <li>{t('privacyPolicy.section2.subsection2')}</li>
                        <li>{t('privacyPolicy.section2.subsection3')}</li>
                    </ol>
                    <br />
                    <li>{t('privacyPolicy.section3')}</li>
                    <p>{t('privacyPolicy.section3.info')}</p>
                    <ol>
                        <li>{t('privacyPolicy.section3.subsection1')}</li>
                        <li>{t('privacyPolicy.section3.subsection2')}</li>
                        <li>{t('privacyPolicy.section3.subsection3')}</li>
                        <li>{t('privacyPolicy.section3.subsection4')}</li>
                        <li>{t('privacyPolicy.section3.subsection5')}</li>
                    </ol>
                    <br />
                    <li>{t('privacyPolicy.section4')}</li>
                    <p>
                        <span>{t('privacyPolicy.section4.info')}</span>
                        <a href="https://trazer.com/security-statement/">{t('privacyPolicy.section4.securityLink')}</a>
                        <span>{t('privacyPolicy.section4.info2')}</span>
                    </p>
                    <li>{t('privacyPolicy.section5')}</li>
                    <p>{t('privacyPolicy.section5.info')}</p>
                    <ol>
                        <li>{t('privacyPolicy.section5.subsection1')}</li>
                        <li>{t('privacyPolicy.section5.subsection2')}</li>
                        <li>{t('privacyPolicy.section5.subsection3')}</li>
                    </ol>
                    <br />
                    <li>{t('privacyPolicy.section6')}</li>
                    <p>{t('privacyPolicy.section6.info')}</p>
                    <li>{t('privacyPolicy.section7')}</li>
                    <p>{t('privacyPolicy.section7.info')}</p>
                    <ol>
                        <li>{t('privacyPolicy.section7.subsection1')}</li>
                        <li>{t('privacyPolicy.section7.subsection2')}</li>
                        <li>{t('privacyPolicy.section7.subsection3')}</li>
                        <li>{t('privacyPolicy.section7.subsection4')}</li>
                        <li>{t('privacyPolicy.section7.subsection5')}</li>
                    </ol>
                    <p>{t('privacyPolicy.section7.info2')}</p>
                    <li>{t('privacyPolicy.section8')}</li>
                    <p>{t('privacyPolicy.section8.info')}</p>
                    <li>{t('privacyPolicy.section9')}</li>
                    <p>{t('privacyPolicy.section9.info')}</p>
                    <br />
                    <span>{t('privacyPolicy.footer.line1')}</span>
                    <br />
                    <span>{t('privacyPolicy.footer.line2')}</span>
                    <br />
                    <span>{t('privacyPolicy.footer.line3')}</span>
                    <br />
                    <span>{t('privacyPolicy.footer.line4')}</span>
                    <br />
                    <span>{t('privacyPolicy.footer.line5')}</span>
                    <br />
                    <span>{t('privacyPolicy.footer.line6')}</span>
                </ol>
            </div>
        </div>
    )
}
