/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css, Switch as SwitchBase, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForceValidateContext } from './Utils';
import { FrozenEmptyObject } from '../Utils/Constants';

const styles = {
    root: css`
        width: 70px;
        height: 28px;
        background-color: #E0E0E0;
        border-radius: 14px;
        padding: 0;

        & > span {
            color: #FFFFFF;
            width: 20px;
            height: 20px;
            border-radius: 25px;
            padding: 0;
            top: 4px;
            left: 3px;
        }

        & .MuiSwitch-track {
            display: none;
        }

        & .MuiSwitch-input {
            left: 0 !important;
        }

        & .Mui-checked {
            transform: translateX(44px) !important;
            color: #FFFFFF !important;
        }

        &.checked {
            background-color: #00ABA5;
        }

        & > span > input:checked{
            transform: translateX(-40px);
        }
    `,
    label: css`
        position: relative;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        margin: 20px 20px 21px 20px;
        flex-wrap: wrap;
        margin: 0 auto 8px 0;
        letter-spacing: 0.14px;
        color: #858585;
        font: normal normal bold 16px Mustica Pro;
        margin-bottom: 18px;

        & > .switch {
            margin-top: 25px;
        }
    `,
    secondaryColorStyle: css`
        background-color: #E0E0E0;

        & > span {
            color: #FFFFFF;
        }

        & .Mui-checked {
            color: #00ABA5 !important;
        }

        &.checked {
            background-color: #FFFFFF;
        }
    `,
    disabledColorStyle: css`
        & .Mui-checked {
            opacity: 0.625 !important;
        }

        &.checked {
            opacity: 0.7 !important;
        }
    `,
}

export const Switch = ({
    color,
    $value,
    value,
    labelProps,
    onChange,
    validator,
    ...props
}) => {
    const forceValidateContext = useForceValidateContext();
    const [validationErr, setValidationErr] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const hasErrors = validationErr.length > 0;
    const lastCheckedValue = useRef(FrozenEmptyObject);

    useEffect(() => {
        if (!forceValidateContext
            && lastCheckedValue.current === FrozenEmptyObject
        ) {
            return;
        }

        if (validator) {
            const validatedValue = value || $value?.value;
            const validationResult = validator(validatedValue);
            setValidationErr(validationResult || []);
        } else if ($value?.validate) {
            setValidationErr($value.validate() || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceValidateContext, value, validator, $value?.isValid, $value?.value, $value?.validate]);

    const valueInternal = Boolean($value ? $value.value : value);

    const onChangeInternal = (e) => {
        const value = e.target.checked;
        lastCheckedValue.current = value;
        $value && $value.set(value);
        onChange && onChange(value);
    }

    const SwitchComponent = (
        <SwitchBase
            className={valueInternal ? 'switch checked' : 'switch'}
            css={[
                styles.root,
                color === 'secondary' && styles.secondaryColorStyle,
                props.disabled && styles.disabledColorStyle
            ]}
            checked={valueInternal}
            onChange={onChangeInternal}
            {...props}
        />
    );

    return props.label ?
        <Tooltip title={props.tooltip}>
            <label css={styles.label} {...labelProps}>
                {props.label}
                {SwitchComponent}
            </label>
        </Tooltip>
        :
        <Tooltip title={props.tooltip}>
            {SwitchComponent}
        </Tooltip>
}
