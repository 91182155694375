// Taken from MVC portal
// Trazer.Web.Core/wwwroot/Scripts/app/common.js

function standard(data) {
    let average = calcMean(data);
    let sum = data.reduce((sum, value) => {
        const diff = value - average;
        return (sum += diff * diff);
    }, 0);
    return Math.sqrt(sum / data.length);
}

function calcMean(data) {
    const sum = data.reduce((sum, value) => sum += value, 0);
    return sum / data.length;
}

function pick(args) {
    for (let i = 0; i < args.length; i++) {
        let arg = args[i];
        if (arg) {
            return arg;
        }
    }
};

function normalizeTickInterval(interval, multiples, magnitude, options) {
    let normalized;

    // round to a tenfold of 1, 2, 2.5 or 5
    magnitude = pick([magnitude, 1]);
    normalized = interval / magnitude;

    // multiples for a linear scale
    if (!multiples) {
        multiples = [1, 2, 2.5, 5, 10];

        // the allowDecimals option
        if (options && options.allowDecimals === false) {
            if (magnitude === 1) {
                multiples = [1, 2, 5, 10];
            } else if (magnitude <= 0.1) {
                multiples = [1 / magnitude];
            }
        }
    }

    // normalize the interval to the nearest multiple
    for (let i = 0; i < multiples.length; i++) {
        interval = multiples[i];
        if (normalized <= (multiples[i] + (multiples[i + 1] || multiples[i])) / 2) {
            break;
        }
    }

    // multiply back to the correct magnitude
    interval *= magnitude;

    return interval;
};

function getMagnitude(num) {
    const magnitude = Math.pow(10, Math.floor(Math.log(num) / Math.LN10));
    return magnitude;
}

function correctFloat(num, precision) {
    // When the number is higher than 1e14 use the number (#16275)
    return num > 1e14 ? num : parseFloat(
        num.toPrecision(precision || 14)
    );
}

function getLinearTickPositions(tickInterval, min, max) {
    const roundedMin = correctFloat(
        Math.floor(min / tickInterval) * tickInterval
    );
    const roundedMax = correctFloat(
        Math.ceil(max / tickInterval) * tickInterval
    );
    const tickPositions = [];

    let pos,
        lastPos,
        precision;

    // When the precision is higher than what we filter out in
    // correctFloat, skip it (#6183).
    if (correctFloat(roundedMin + tickInterval) === roundedMin) {
        precision = 20;
    }

    // For single points, add a tick regardless of the relative position
    // if (this.single) {
    //     return [min];
    // }

    // Populate the intermediate values
    pos = roundedMin;
    while (pos <= roundedMax) {
        // Place the tick on the rounded value
        tickPositions.push(pos);

        // Always add the raw tickInterval, not the corrected one.
        pos = correctFloat(
            pos + tickInterval,
            precision
        );

        // If the interval is not big enough in the current min - max range
        // to actually increase the loop constiable, we need to break out to
        // prevent endless loop.
        if (pos === lastPos) {
            break;
        }

        // Record the last value
        lastPos = pos;
    }

    return tickPositions;
}

export function calculateNormalDistributionData(data, labels, interval, pointsInInterval, rounding = 100) {
    const standardDeviation = standard(data);
    const endX = interval * pointsInInterval * 2 + 1;
    const mean = calcMean(data);
    const startX = mean - interval * standardDeviation;
    const normalDistributionData = [];

    const increment = standardDeviation / pointsInInterval;
    let x = startX;

    let normalDistributionMax = -Infinity;
    for (let i = 0; i < endX; i++) {
        const translation = x - mean;
        const y = Math.exp(-(translation * translation) / (2 * standardDeviation * standardDeviation)) / (standardDeviation * Math.sqrt(2 * Math.PI));
        normalDistributionData.push({ x, y });
        normalDistributionMax = Math.max(normalDistributionMax, y);
        x += increment;
    }

    for (let i = 0; i < normalDistributionData.length; i++) {
        normalDistributionData[i].status = labels[i];
    }

    const n = data.length;
    const binCountSturges = Math.ceil(Math.log(n) / Math.LN2 + 1);; // optimal # bins, Sturges' formula
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;
    for (let i = 0; i < n; i++) {
        min = Math.min(min, data[i]);
        max = Math.max(max, data[i]);
    }

    const binWidthSturges = (max - min) / binCountSturges; // ideal bin width
    const binWidth = normalizeTickInterval(binWidthSturges, null, getMagnitude(binWidthSturges)); // normalized bin width
    const bins = getLinearTickPositions(binWidth, min, max);
    const binCount = bins.length - 1; // actual number of bins
    const binLow = bins[0];
    const binHigh = bins[binCount];

    const binData = new Array(binCount);
    for (let bin = 0; bin < binCount; bin++) {
        binData[bin] = 0;
    }

    const a = binCount / (binHigh - binLow);
    const b = binLow * binCount / (binHigh - binLow);
    for (let i = 0; i < n; i++) {
        const bin = Math.min(Math.floor(a * data[i] - b), binCount - 1);
        binData[bin]++;
    }

    let histogramMax = -Infinity;
    const histogramData = [];
    for (let i = 0; i < bins.length; i++) {
        const y = binData[i] || null;
        histogramData.push({ x: bins[i], y });
        histogramMax = Math.max(histogramMax, y);
    }

    // const roundedHistogramData = histogramData.map(p => Math.round(p.x * rounding) / rounding);
    // for (let i = 0; i < normalDistributionData.length; i++) {
    //     const roundedValue = Math.round(normalDistributionData[i].x * rounding) / rounding;
    //     if (!roundedHistogramData.includes(roundedValue)) {
    //         histogramData.push({ x: roundedValue, y: null });
    //         roundedHistogramData.push(roundedValue);
    //     }
    // }

    histogramData.sort((a, b) => {
        if (a.x === b.x) {
            return 0
        }

        return a.x > b.x ? 1 : -1
    });

    return {
        histogramData,
        histogramLabels: histogramData.map(x => x.x),
        histogramMax,
        normalDistributionData,
        normalDistributionMax,
    };
}

if (!CanvasRenderingContext2D.prototype.roundRect) {
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, w, h, r) {
        if (w < 2 * r) r = w / 2;
        if (h < 2 * r) r = h / 2;
        this.beginPath();
        this.moveTo(x+r, y);
        this.arcTo(x+w, y, x+w, y+h, r);
        this.arcTo(x+w, y+h, x, y+h, r);
        this.arcTo(x, y+h, x, y, r);
        this.arcTo(x, y, x+w, y, r);
        this.closePath();
        return this;
    };
}

export const BellCurveAndHistogramPlugins = {
    id: 'dataLabels',
    afterDatasetsDraw(chart, args, options) {
        if (args < 1) {
            return;
        }

        const {
            unitType = '',
            borderColor = 'rgb(0, 171, 165)',
            backgroundColor = 'rgb(204, 238, 237)',
            borderRadius = 8,
            infoPlacement = 'left',
            t
        } = options;

        const { ctx } = chart;
        ctx.save();

        // =========================
        // Distribution labels
        // =========================
        ctx.font = 'bold 18px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fillStyle = '#000000';
        ctx.strokeStyle = 'white';
        for (let dsIndex = 0; dsIndex < chart.config.data.datasets.length; dsIndex++) {
            const dataset = chart.config.data.datasets[dsIndex];
            const datasetMeta = chart.getDatasetMeta(dsIndex);
            if (datasetMeta.hidden ?? dataset.hidden) {
                continue;
            }

            for (let i = 0; i < dataset.data.length; i++) {
                if (!dataset.data[i]) {
                    continue;
                }

                const textWidth = ctx.measureText(dataset.data[i].status).width;
                ctx.fillText(dataset.data[i].status || '',
                    datasetMeta.data[i].x - (textWidth / 2),
                    datasetMeta.data[i].y - 20);
            }
        }

        // =========================
        // Info box
        // =========================
        const textH = 16;
        const verticalSidePadding = 10;
        const horizontalSidePadding = 20;
        const lineOffset = 5;

        ctx.font = `${textH}px "Helvetica Neue", Helvetica, Arial, sans-serif`;

        const data = chart.config.data.datasets[2].data;
        const mean = calcMean(data);
        const std = chart.config.data.datasets[1].data[chart.config.data.datasets[1].pointsInInterval].x - chart.config.data.datasets[1].data[0].x;
        const lines = t ?
            [
                t('chart.normalDistribution.sizeInfo', data.length),
                t('chart.normalDistribution.meanOrAverageInfo', mean.toFixed(2), unitType),
                t('chart.normalDistribution.standardDeviationInfo', std.toFixed(2), unitType),
            ]
            :
            [
                `Size (n): ${data.length}`,
                `Mean/Average (μ): ${mean.toFixed(2)}${unitType}`,
                `Standard Deviation (σ): ${std.toFixed(2)}${unitType}`,
            ];

        const textMaxWidth = Math.max(...lines.map(x => ctx.measureText(x).width));
        const rectWidth = textMaxWidth + 2 * horizontalSidePadding;
        const rectHeight = lines.length * textH + (lines.length - 1) * lineOffset + 2 * verticalSidePadding;

        const rectX = infoPlacement === 'left' ?
            chart.chartArea.left + 2
            : chart.chartArea.right - rectWidth - 2;
        const rectY = chart.chartArea.top + 2;
        const textX = rectX + horizontalSidePadding;
        // text rendering is in the middle of the glyph thats why we need to offset it with textH * 0.5
        const textY = rectY + verticalSidePadding + textH * 0.5;

        ctx.strokeStyle = borderColor;
        ctx.fillStyle = backgroundColor;
        ctx.beginPath();
        ctx.roundRect = ctx.roundRect || ctx.rect
        ctx.roundRect(rectX, rectY, rectWidth, rectHeight, borderRadius);

        ctx.stroke();
        ctx.fill();
        ctx.fillStyle = '#000000';
        lines.forEach((x, index) => {
            ctx.fillText(x, textX, textY + index * (textH + lineOffset));
        });
        ctx.restore()
    },
    beforeLayout: (chart, options, c) => {
        let max = Number.MIN_VALUE;
        let min = Number.MAX_VALUE
        let grace = chart.options.plugins.customScale.grace
        const { ctx } = chart;
        ctx.save();

        chart.data.datasets.forEach((dataset) => {
            const dataValues = dataset.data.map(p => p?.y);
            max = Math.max(max, Math.max(...dataValues));
            min = Math.min(min, Math.min(...dataValues));
        })

        if (typeof grace === 'string' && grace.includes('%')) {
            grace = Number(grace.replace('%', '')) / 100
            chart.config.options.scales.yAxisA.ticks.suggestedMax = max + (max * grace)
            chart.config.options.scales.yAxisA.ticks.suggestedMin = min - (min * grace)
        } else if (typeof grace === 'number') {
            chart.config.options.scales.yAxisA.ticks.suggestedMax = max + grace
            chart.config.options.scales.yAxisA.yAxisA.ticks.suggestedMin = min - grace
        }

        ctx.restore()
    },
};
