/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { TagService } from '../../../Services/TagService';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { Button } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { AddTagContent } from './AddTagContent';
import { EditTagContent } from './EditTagContent';

import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { IconButton } from '../../../CoreComponents/Button';
import { AdminTypes } from '../../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setTags } from '../../../globalStates/tableDataState';

const tagsStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}

export const TagsList = ({ $selectedTag, $refreshTags }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $tags = useHook(tableDataState.tags);
    const $isLoading = useHook(!Boolean(tableDataState.tags.length));
    const $isAddTagModalOpened = useHook(false);
    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton disabled={isTrazerViewAdmin} onClick={() => $selectedTag.set(rowData)}>
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('tags.table.name'),
            isSortable: true,
        },
    ], [t]);

    useEffect(() => {
        TagService.getAll(9999)
            .then(tags => {
                dispatch(setTags(tags));
                $tags.set(tags);
                $isLoading.set(false);
            })
    }, [$refreshTags.value]);

    return (
        <div css={tagsStyles.root}>
            <TableCSF
                customActions={
                    <div css={tagsStyles.actions}>
                        <Button disabled={isTrazerViewAdmin} onClick={() => $isAddTagModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$tags.value}
                rowKeySelector={r => r.tagId}
                isLoading={$isLoading.value}
                totalItemsCount={$tags.value.length}
                showAdvancedSearch={false}
            />
            <Modal
                open={$isAddTagModalOpened.value}
                onClose={() => $isAddTagModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <AddTagContent onCancel={() => $isAddTagModalOpened.set(false)}
                    onAdd={() => {
                        $isAddTagModalOpened.set(false);
                        $refreshTags.set({});
                    }} />
            </Modal>
        </div>
    )
};

export const Tags = () => {
    const $selectedTag = useObjectHook(null);
    const $refreshTags = useHook({});
    const $prevSelectedTags = useHook(null);

    useEffect(() => {
        if ($prevSelectedTags.value && $selectedTag.value) {
            $refreshTags.set({});
            $prevSelectedTags.set(null);
            $selectedTag.set(null);
            return;
        }

        $prevSelectedTags.set($selectedTag.value);
    }, [$selectedTag.value]);

    return (
        <>
            <TagsList $selectedTag={$selectedTag} $refreshTags={$refreshTags} />
            <Modal
                open={!!$selectedTag.value}
                onClose={() => $selectedTag.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <EditTagContent $selectedTag={$selectedTag} onCancel={() => $selectedTag.set(null)} />
            </Modal>
        </>
    )
}
