/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    maxLengthValidator,
    requiredValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { CustomerService } from '../../../Services/CustomerService';

const customersTabStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .input-root {
            margin: 0;
            width: calc(100% - 40px);
            @media (min-width: 1200px) {
                width: calc(50% - 40px);
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button {
                margin-left: auto;
            }
        }
    `,
};

const CustomerValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
};

export const CustomerTab = ({ $selectedCustomer }) => {
    const { t } = useTranslation();
    const $isForceValidating = useHook(true);
    const $customerCopy = useObjectHook(
        $selectedCustomer.value,
        $selectedCustomer.value.admin ?
            { ...CustomerValidator, email: [requiredValidator, ...CustomerValidator.email] }
            : CustomerValidator,
        null,
        $isForceValidating.value
    );

    const updateCustomer = () => {
        $isForceValidating.set(true);
        const errors = $customerCopy.validate();
        if (errors) {
            return;
        }
        CustomerService.putCustomer($customerCopy.value)
            .then(() => $selectedCustomer.set($customerCopy.value));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={customersTabStyles.root}>
                <h2>{t('customers.editModal.editCustomerTabName')}</h2>
                <Input label={t('customers.fieldName.name')} required $value={$customerCopy.getPropHook('name')} />

                <div className="actions">
                    <Button onClick={updateCustomer}>{t('actions.update')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
