// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Divider, Menu, MenuItem, Pagination, Typography } from '@mui/material';
import { useMemo, useRef } from 'react';
import { useEffect } from 'react';
import {
    SortOrder,
    DefaultSortingOrder,
} from '../../Utils/Constants';
import { Button, IconButton } from '../Button';
import {
    CheckIcon,
    EllipsisVerticalIcon,
    CaretUpIcon,
    CaretDownIcon
} from '../CustomIcons';
import { CircularLoader } from '../Loaders';
import { useTranslation } from '../Translation';
import { useHook, useObjectHook } from '../Utils';
import { Checkbox } from '../Checkbox';
import { TableActions } from './TableActions';

const pageSettingsMinWidth = 145;
const styles = {
    root: css`
        height: 100%;
        width: 100%;
        position: relative;

        & .actions {
            display: flex;
            padding: 0 58px 0 12px;

            .custom-actions {
                margin-right: auto;
            }

            & > input {
                margin-left: auto;
                width: 240px;
            }

            & > button {
                min-width: unset;
                margin-left: 20px;
            }

            & .search {
                min-width: 150px;
            }
        }

        & .table-header {
            overflow-x: hidden;
            display: flex;
            flex-direction: row;
            width: calc(100% - 70px);
            padding: 28px 0 29px 0;
            margin-left: 12px;
        }
    `,
    paginationSettings: css`
        display: flex;
        position: relative;
        right: -8px;
        height: 100%;

        &.on-the-left {
            flex-direction: row-reverse;
            margin-right: auto;
            margin-left: 0;
        }

        & > * {
            margin: auto 0 auto 8px;
        }

        &.on-the-left, &.disable-pagination {
            position: auto;
            right: auto;
        }

        &.on-the-left {
            margin: auto auto auto 0;
            & > button {
                margin: auto 0;
            }
        }

        & > span {
            color: #858585;
            margin-left: auto;
            font: normal normal normal 16px/24px Roboto;
        }

        & > button {
            height: 1.5em;
            width: 1.5em;
        }
    `,
    menuItemIcon: css`
        width: 32px;
    `,
    menuTitle: css`
        width: 100%;
        padding: 9px 20px;
    `,
    settingsMenuApply: css`
        margin: 9px 20px;
    `,
    tableContainer: css`
        overflow: auto;
        height: 55vh;
        width: 100%;
        position: relative;

        margin-left: 12px;
        width: calc(100% - 12px);

        & .right-padding-div, & .bottom-padding-div {
            position: sticky;
            content: " ";
            background-color: #EEEEEE;
            // background-color: beige;
        }

        & .right-padding-div {
            height: calc(100% - 16px);
            width: 32px;
            left: calc(100% - 32px);
            top: 0;
        }

        & .bottom-padding-div {
            width: 100%;
            height: 16px;
            top: calc(100% - 16px);
            left: 0;
            right: 0;
            bottom: 0;
        }
    `,
    table: css`
        position: absolute;
        border-collapse: separate;
        border-spacing: 0 2em;
        border: 0;
        width: calc(100% - 32px);
    `,
    tableHeader: css`
        color: #3A817F;
        // white-space: nowrap;

        &.sortable {
            cursor: pointer;
            & > svg {
                margin-left: 20px;
            }
        }

        padding: 0 8px;

        &:first-of-type {
            padding-left: 40px;
        };

        &.pagination-settings:last-of-type {
            text-align: right;
        };
    `,
    tableRow: css`
        box-shadow: 0px 8px 16px #28326529;
        border-radius: 8px;

        & > td {
            font: normal normal normal 16px/24px Roboto;
            color: #858585;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            height: 80px;
            // max-width: 230px;
            word-wrap: anywhere;
            padding: 0 8px;

            &:first-of-type {
                border-radius: 8px 0 0 8px;
                background-color: white;
                padding-left: 40px;
            };

            &:last-of-type {
                border-radius: 0 8px 8px 0;
                background-color: white;
                padding-right: 40px;
                text-align: end;
            };
        };

        &:hover > td, &.selected > td {
            background: #EBF5F4 0% 0% no-repeat padding-box;
        };
    `,
    loadContainer: css`
        width: 100%;
        height: 100%;
        display: flex;
    `,
    noRecords: css`
        color: #858585;
        text-align: center;
    `,
    paginationRoot: css`
        display: flex;
        justify-content: end;
        margin-top: 36px;
        margin-right: 24px;
        margin-left: 0;
    `,
    paginationPages: css`
        & button {
            color: #00ABA5;
            &.Mui-disabled {
                opacity: 1;
            }
        }
    `,
    headerRoot: css`
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
    `,
    sortSelector: css`
        margin: auto auto auto 20px;
        display: flex;
        flex-direction: column;
        & [disabled] {
            color: #CBCBCB;
        }

        & svg:first-of-type {
            position: relative;
            bottom: -4px;
        }
        & svg:last-of-type {
            position: relative;
            top: -4px;
        }
    `,
};

export const Table = ({
    rows,
    totalItemsCount = rows.length,
    isLoading,
    onSort,
    sortOptions,
    headCells,
    rowKeySelector,
    additionalData,
    isRowSelected,
    showExport,
    showSearch,
    showAdvancedSearch,
    onExport,
    customActions,
    tableContainerHeight,
    paginationSettingsPosition = 'top-right',
    ...props
}) => {
    const { t } = useTranslation();
    const rootRef = useRef(null);
    const tableHeaderRowRef = useRef(null);
    const tableContainerRef = useRef(null);
    const divHeadersRef = useRef(null);
    const $sortBy = useHook(sortOptions ? sortOptions[0] : null);
    const $sortDirection = useHook(sortOptions ? sortOptions[1] : DefaultSortingOrder);
    const $page = useHook(sortOptions ? sortOptions[2] : 0);
    const $itemsPerPage = useHook(sortOptions ? sortOptions[3] : 10);
    const $searchText = useHook(sortOptions ? sortOptions[4] : '');
    const $advancedSearch = useObjectHook(sortOptions ? sortOptions[5] : {});
    const $itemsPerPageSelectionAnchor = useHook(null);

    const $forceRefresh = useHook(null);
    const $visibleHeadCells = useHook({});
    const $visibleHeadCellsSelection = useHook({});
    const $headCellsById = useHook({});
    const $isFirstRender = useHook(true);

    const $headCells = useMemo(() => {
        const hc = !headCells[headCells.length - 1].label || paginationSettingsPosition === 'bottom-left' ?
            headCells.map((x, i) => i === (headCells.length - 1) ?
                ({ ...x, _pagination: paginationSettingsPosition === 'top-right' })
                : x
            )
            : [...headCells, { id: 'pagination', _pagination: true, label: '' }];
        $headCellsById.set(
            hc.reduce((a, c) => ({ ...a, [c.id]: c }), {})
        );
        $visibleHeadCells.set(
            hc.map(x => x.id)
                .reduce((a, id) => ({ ...a, [id]: true }), {})
        );
        return hc;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headCells, paginationSettingsPosition]);
    const availablePaginationOptionsArray = useMemo(() =>
        [10, 25, 50].map(c => ({ label: t('coreComponents.table.paginationInfoMenuItemTemplate', c), value: c }))
        , [t]);

    const isOverflowing = useMemo(() => {
        if (!tableContainerRef.current) {
            return false;
        }

        return {
            width: tableContainerRef.current.clientWidth < tableContainerRef.current.scrollWidth,
            height: tableContainerRef.current.clientHeight < tableContainerRef.current.scrollHeight
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableContainerRef.current, $forceRefresh.value, t, headCells, $sortBy.value, $sortDirection.value, $page.value, $itemsPerPage.value, $searchText.value, $advancedSearch.value, isLoading])

    const totalPages = Math.ceil(totalItemsCount / $itemsPerPage.value);

    const handleOpenItemsPerPageMenu = (e) => {
        $visibleHeadCellsSelection.set({
            ...$visibleHeadCells.value
        });
        $itemsPerPageSelectionAnchor.set(e.currentTarget);
    };

    const handleCloseItemsPerPageMenu = () => $itemsPerPageSelectionAnchor.set(null);

    const observer = useRef(
        new ResizeObserver(entries => {
            $forceRefresh.set({});
        })
    );

    useEffect(() => {
        if (isLoading || !onSort) {
            return;
        }

        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }

        if (props.disablePagination) {
            onSort($sortBy.value, $sortDirection.value, 0, totalItemsCount, $searchText.value, $advancedSearch.value);
        } else {
            onSort($sortBy.value, $sortDirection.value, $page.value, $itemsPerPage.value, $searchText.value, $advancedSearch.value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$sortBy.value, $sortDirection.value, $page.value, $itemsPerPage.value, $searchText.value, $advancedSearch.value, isLoading]);

    useEffect(() => {
        if ($isFirstRender.value) {
            $isFirstRender.set(false);
            return;
        }

        $page.set(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$sortBy.value, $sortDirection.value, $itemsPerPage.value, $searchText.value, $advancedSearch.value]);

    useEffect(() => {
        if (!tableHeaderRowRef.current) {
            return;
        }

        const elRef = tableHeaderRowRef.current;
        observer.current.observe(elRef);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            observer.current.unobserve(elRef)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableHeaderRowRef.current, observer]);

    useEffect(() => {
        if (!tableContainerRef.current || !divHeadersRef.current) {
            return;
        }

        const tableContainer = tableContainerRef.current;
        const onScroll = event =>
            divHeadersRef.current.scrollLeft = event.target.scrollLeft
        tableContainer.addEventListener('scroll', onScroll);

        return () => {
            tableContainer.removeEventListener('scroll', onScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableContainerRef.current, divHeadersRef.current]);

    // WARN: this should be temporary solution until we have function to track multiple resize events
    useEffect(() => {
        setTimeout(function () {
            $forceRefresh.set({});
        }, 0);

        if (!rows.length && totalItemsCount) {
            $page.set(Math.max(0, Math.min($page.value, Math.ceil(totalItemsCount / $itemsPerPage.value) - 1)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows])

    const getTableHeight = () => {
        if (!rootRef.current) {
            return tableContainerHeight || 'auto';
        }

        const height = tableContainerHeight
            || Array.from(rootRef.current.children)
                .reduce((height, c) => {
                    const computedStyle = window.getComputedStyle(c);
                    height += parseInt(computedStyle.marginTop, 10);
                    height += parseInt(computedStyle.marginBottom, 10);
                    if (!c.classList.contains('table-container')) {
                        height += c.clientHeight
                    }

                    return height;
                    // Minimum height is 2 in order to avoid
                    // showing scroll bars due to rounding issues
                }, 2);

        return `calc(100% - ${height}px)`
    }

    const PaginationSettingsInternal = <>
        {props.disablePagination && props.showTableSizeInfo &&
            <div css={styles.paginationSettings} className='disable-pagination'>
                <span>
                    {t('coreComponents.table.paginationInfo',
                        ($page.value * $itemsPerPage.value) + 1,
                        totalItemsCount,
                        totalItemsCount
                    )}
                </span>
            </div>
        }
        {!props.disablePagination && !props.showTableSizeInfo &&
            <div
                className={paginationSettingsPosition === 'bottom-left' ? 'on-the-left' : ''}
                css={styles.paginationSettings}
            >
                <span>
                    {t('coreComponents.table.paginationInfo',
                        ($page.value * $itemsPerPage.value) + 1,
                        Math.min(totalItemsCount, ($page.value + 1) * $itemsPerPage.value),
                        totalItemsCount
                    )}
                </span>
                <IconButton onClick={handleOpenItemsPerPageMenu}>
                    <EllipsisVerticalIcon />
                </IconButton>
                <Menu
                    sx={{ mt: '32px' }}
                    id="pagination-settings-menu"
                    anchorEl={$itemsPerPageSelectionAnchor.value}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean($itemsPerPageSelectionAnchor.value)}
                    onClose={handleCloseItemsPerPageMenu}
                >
                    <Typography color="primary" textAlign="left" css={styles.menuTitle}>
                        {t('coreComponents.table.paginationInfoMenuTitle')}
                    </Typography>
                    {availablePaginationOptionsArray.map(({ label, value }) => (
                        <MenuItem
                            key={value}
                            onClick={() => {
                                $page.set(0);
                                $itemsPerPage.set(value);
                                handleCloseItemsPerPageMenu();
                            }}
                        >
                            <div css={styles.menuItemIcon}>
                                {$itemsPerPage.value === value ?
                                    <CheckIcon />
                                    : null}
                            </div>
                            <Typography textAlign="left">{label}</Typography>
                        </MenuItem>
                    ))}
                    <Divider />
                    <Typography color="primary" textAlign="left" css={styles.menuTitle}>
                        {t('coreComponents.table.visibleColumns')}
                    </Typography>
                    {$headCells.filter(x => x.label).map(({ id, label, canBeHidden }) => (
                        <MenuItem
                            key={`column_${id}`}
                            onClick={() => {
                                $visibleHeadCellsSelection.set({
                                    ...$visibleHeadCellsSelection.value,
                                    [id]: !$visibleHeadCellsSelection.value[id]
                                });
                            }}
                        >
                            <Checkbox
                                // disabled={!canBeHidden}
                                checked={$visibleHeadCellsSelection.value[id] || false}
                                onChange={(isChecked, e) => {
                                    e.preventDefault();
                                    $visibleHeadCellsSelection.set({
                                        ...$visibleHeadCellsSelection.value,
                                        [id]: !isChecked
                                    });
                                }}
                            />
                            <Typography textAlign="left">{label}</Typography>
                        </MenuItem>
                    ))}
                    <Button
                        css={styles.settingsMenuApply}
                        disabled={
                            !Object.keys($visibleHeadCellsSelection.value)
                                .some(k => $visibleHeadCellsSelection.value[k] && $headCellsById.value[k].label)
                        }
                        onClick={() => {
                            $visibleHeadCells.set(
                                $visibleHeadCellsSelection.value
                            );
                            setTimeout(() => {
                                $forceRefresh.set({});
                            }, 0);
                            handleCloseItemsPerPageMenu();
                        }}
                    >
                        {t('actions.apply')}
                    </Button>
                </Menu>
            </div>
        }
    </>

    const hasPaginationFooter =
        (totalPages > 1 && !props.disablePagination)
        || paginationSettingsPosition === 'bottom-left';

    const HeaderComponent = ({ hc }) =>
        <div css={styles.headerRoot}>
            {hc.label}
            {hc.isSortable &&
                <div css={styles.sortSelector}>
                    <CaretUpIcon disabled={
                        $sortBy.value !== hc.id
                        || $sortDirection.value === SortOrder.Desc
                    } />
                    <CaretDownIcon disabled={
                        $sortBy.value !== hc.id
                        || $sortDirection.value === SortOrder.Asc
                    } />
                </div>
            }
        </div>

    // TODO: add header and footer
    return (isLoading ?
        <div css={styles.loadContainer}>
            <CircularLoader />
        </div>
        :
        <div css={styles.root} ref={rootRef} className={props.className}>
            <TableActions
                headCells={headCells}
                showExport={showExport}
                showSearch={showSearch}
                showAdvancedSearch={showAdvancedSearch}
                onExport={onExport}
                customActions={customActions}
                isOverflowing={isOverflowing}
                sortOptions={sortOptions}
                $searchText={$searchText}
                $advancedSearch={$advancedSearch}
                $headCells={$headCells}
            />
            <div
                ref={divHeadersRef}
                aria-hidden={true}
                className="table-header"
                style={{
                    // height: `calc(2em + ${tableHeaderRowRef.current?.clientHeight || 0}px)`
                    paddingRight: isOverflowing.height ? 20 : 32,
                    width: `calc(100% - ${isOverflowing.height && !isOverflowing.width ? 32 : 45}px)`
                }}
            >
                {tableHeaderRowRef.current && $headCells
                    .filter(hc => $visibleHeadCells.value[hc.id])
                    .map((hc, i) => (
                        !hc.label
                            && hc._pagination
                            && totalItemsCount
                            && paginationSettingsPosition === 'top-right' ?
                            <div
                                key={hc.id}
                                css={styles.tableHeader}
                                className="pagination-settings"
                                style={{
                                    width: tableHeaderRowRef.current?.cells[i]?.clientWidth,
                                    minWidth: tableHeaderRowRef.current?.cells[i]?.clientWidth,
                                    height: tableHeaderRowRef.current?.cells[i]?.clientHeight,
                                }}
                            >
                                {PaginationSettingsInternal}
                            </div>
                            :
                            <div
                                key={hc.id}
                                css={styles.tableHeader}
                                className={hc.isSortable ? 'sortable' : ''}
                                style={{
                                    width: tableHeaderRowRef.current?.cells[i]?.clientWidth,
                                    height: tableHeaderRowRef.current?.cells[i]?.clientHeight,
                                    minWidth: tableHeaderRowRef.current?.cells[i]?.clientWidth,
                                    maxWidth: tableHeaderRowRef.current?.cells[i]?.clientWidth,
                                }}
                                onClick={() => {
                                    if (!hc.isSortable) {
                                        return;
                                    }

                                    // $page.set(0);
                                    $sortBy.set(hc.id);
                                    if ($sortBy.value === hc.id) {
                                        $sortDirection.set(
                                            $sortDirection.value === SortOrder.Desc ?
                                                SortOrder.Asc
                                                : SortOrder.Desc
                                        );
                                    } else {
                                        $sortDirection.set(DefaultSortingOrder);
                                    }
                                }}
                            >
                                <HeaderComponent hc={hc} />
                            </div>
                    ))
                }
            </div>
            <div
                ref={tableContainerRef}
                css={styles.tableContainer}
                className="table-container"
                style={{
                    height: getTableHeight(),
                    paddingRight: isOverflowing.width ? 0 : 20
                }}
            >
                <table
                    css={styles.table}
                    style={{
                        paddingRight: isOverflowing.width ? 32 : 0,
                        top: rows.length ?
                            `calc(-4em - ${tableHeaderRowRef.current?.clientHeight || 0}px)`
                            : '-5.5em'
                    }}
                >
                    <thead>
                        <tr ref={tableHeaderRowRef} className="hide">
                            {$headCells
                                .filter(hc => $visibleHeadCells.value[hc.id])
                                .map((hc, i) => (
                                    !hc.label
                                        && hc._pagination
                                        && totalItemsCount
                                        && paginationSettingsPosition === 'top-right' ?
                                        <th
                                            key={hc.id}
                                            css={styles.tableHeader}
                                            className="pagination-settings"
                                            style={{
                                                width: pageSettingsMinWidth,
                                                minWidth: pageSettingsMinWidth
                                            }}
                                        >
                                            {PaginationSettingsInternal}
                                        </th>
                                        :
                                        <th
                                            key={hc.id}
                                            css={styles.tableHeader}
                                            className={hc.isSortable ? 'sortable' : ''}
                                            style={hc.columnWidth ? {
                                                width: hc.columnWidth,
                                                minWidth: hc.columnWidth,
                                            } : null}
                                            onClick={() => {
                                                if (!hc.isSortable) {
                                                    return;
                                                }

                                                // $page.set(0);
                                                $sortBy.set(hc.id);
                                                if ($sortBy.value === hc.id) {
                                                    $sortDirection.set(
                                                        $sortDirection.value === SortOrder.Desc ?
                                                            SortOrder.Asc
                                                            : SortOrder.Desc
                                                    );
                                                } else {
                                                    $sortDirection.set(DefaultSortingOrder);
                                                }
                                            }}
                                        >
                                            <HeaderComponent hc={hc} />
                                        </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {!rows.length ?
                            <tr>
                                <td colSpan={$headCells.length || 1} css={styles.noRecords}>
                                    {t('coreComponents.table.noMatchingRecords')}
                                </td>
                            </tr>
                            : rows.map((row, index) => {
                                const rowId = (rowKeySelector && rowKeySelector(row)) || `${row.id}-${index}`;
                                return (
                                    <tr key={rowId} css={styles.tableRow} className={isRowSelected && isRowSelected(row) && 'selected'}>
                                        {$headCells
                                            .filter(hc => $visibleHeadCells.value[hc.id])
                                            .map(hc => (
                                                <td key={`${rowId}-${hc.id}`}>
                                                    {hc.CellRender ?
                                                        <hc.CellRender key={`${rowId}-${hc.id}`} rowIndex={index} rowData={row} additionalData={additionalData} />
                                                        : !hc._pagination ?
                                                            row[hc.id]
                                                            : null
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                {isOverflowing.width &&
                    <div className="right-padding-div"></div>
                }
                {isOverflowing.height &&
                    <div className="bottom-padding-div"></div>
                }
            </div>
            {hasPaginationFooter &&
                <div css={styles.paginationRoot}>
                    {paginationSettingsPosition === 'bottom-left' &&
                        PaginationSettingsInternal
                    }
                    {totalPages > 1 && !props.disablePagination &&
                        <Pagination
                            css={styles.paginationPages}
                            count={totalPages}
                            page={$page.value + 1}
                            onChange={(e, newPage) => $page.set(newPage - 1)}
                            color="primary"
                        />
                    }
                </div>
            }
        </div>
    );
}
