// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { useMemo, useRef, useState } from 'react';
import { useTranslation } from '../Translation';
import {
    ChartPadding,
    ChartResizeDelay,
    DefaultAnimationDuration,
    SharedPluginsConfig,
    baseColors,
    chartStyles,
    useChartPrintEffect
} from '../../Utils/Constants';
import { ChartOptionsMenu } from './ChartOptionsMenu';
import { Line } from 'react-chartjs-2';

export const LineChart = ({ datasets, isPrinting, fractionDigits = 2, xMaxAxisPrecision = 2, yMaxAxisPrecision = 2, additionalSettings, xAxisLabel, yAxisLabel, isHistory, ...props }) => {
    const chartRef = useRef(null);
    const { t } = useTranslation();
    const [activeDataSetIndex, setActiveDataSetIndex] = useState(null);
    const [size, setNewSize] = useState(null);
    const chartLabels = useMemo(() => {
        let labels = datasets[0].data;
        for (let i = 1; i < datasets.length; ++i) {
            const data = datasets[i].data;
            if (labels.length < data.length) {
                labels = data;
            }
        }

        return labels.map(x => x.label);
    }, [datasets]);

    /** @type import('chart.js/dist/types').ChartOptions<'line'> */
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        resizeDelay: isPrinting ? 0 : ChartResizeDelay,
        layout: {
            padding: ChartPadding,
        },
        onResize: (chart, newSize) => {
            setNewSize(newSize);
        },
        animation: {
            duration: DefaultAnimationDuration
        },
        plugins: {
            ...SharedPluginsConfig,
            tooltip: {
                ...SharedPluginsConfig.tooltip,
                fractionDigits,
                callbacks: isHistory && {
                    beforeBody: function (ctx) {
                        const context = ctx[0];
                        return context.dataset.plugins.tooltip.beforeBody[context.dataIndex];
                    }
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: xAxisLabel
                },
                ticks: {
                    precision: xMaxAxisPrecision,
                    callback: function (val, index, labels) {
                        const label = this.getLabelForValue(val);
                        if (isNaN(label)) {
                            return label;
                        }

                        const labelAsNumber = Number(label);
                        return Number.isInteger(labelAsNumber) ?
                            labelAsNumber
                            : labelAsNumber.toFixed(xMaxAxisPrecision);
                    }
                },
            },
            y: {
                title: {
                    display: true,
                    text: yAxisLabel
                },
                ticks: {
                    precision: yMaxAxisPrecision,
                    callback: function (val, index, labels) {
                        const label = this.getLabelForValue(val);
                        if (isNaN(label)) {
                            return label;
                        }

                        const labelAsNumber = Number(label);
                        return Number.isInteger(labelAsNumber) ?
                            labelAsNumber
                            : labelAsNumber.toFixed(yMaxAxisPrecision);
                    }
                },
            }
        },
        backgroundColor: 'rgba(255, 255, 255, 1)',
    };

    const onHover = (e, item) => {
        if (item.length) {
            setActiveDataSetIndex(item[0].datasetIndex);
        } else {
            setActiveDataSetIndex(null);
        }
    };

    if (datasets.length > 1) {
        options.onHover = onHover;
    }

    if (activeDataSetIndex != null || isPrinting) {
        options.animation.duration = 0;
    }

    /** @type import('chart.js/dist/types').ChartData<'radar'> */
    const chartData = {
        labels: chartLabels,
        datasets:
            datasets.map((ds, index) => {
                const baseColorsMap = baseColors.map(c => ({
                    color: c,
                    backgroundColor: `rgba(${c}, 0.2)`,
                    borderColor: `rgba(${c}, 1)`,
                    pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                    pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
                }))
                const colors = baseColorsMap[index % baseColorsMap.length];
                return ({
                    label: ds.label,
                    data: ds.data.map(x => x.value),

                    borderWidth: 4,

                    pointHitRadius: 8,
                    pointRadius: 8,
                    pointBorderWidth: 4,
                    pointHoverRadius: 8,
                    pointHoverBorderWidth: 4,

                    ...colors,

                    pointBackgroundColor: ds.data.map(x => x.isPartial ? colors.pointBackgroundColor : colors.borderColor),
                    pointHoverBackgroundColor: ds.data.map(x => x.isPartial ? colors.pointHoverBackgroundColor : colors.borderColor),
                    pointBorderColor: colors.borderColor,
                    plugins: isHistory && {
                        tooltip: {
                            beforeBody: ds.data.map(x => x.isPartial ? t('constants.testStatuses.partial') + ' ' + t('testReport.chartTooltip.test') : t("constants.testStatuses.complete") + ' ' + t('testReport.chartTooltip.test'))
                        },
                    },

                    ...(activeDataSetIndex != null && activeDataSetIndex !== index ?
                        {
                            borderColor: [`rgba(64, 64, 64, 0.1)`],
                            pointRadius: ds.data.map(x => !x.isPartial ? 10 : 8),
                            backgroundColor: ds.data.map(x => !x.isPartial ? `rgba(64, 64, 64, 0.1)` : 'rgba(255, 255, 255, 0.1)'),
                            pointBorderColor: ds.data.map(x => x.isPartial ? `rgba(64, 64, 64, 0.1)` : 'rgba(255, 255, 255, 0.1)'),
                            pointBackgroundColor: ds.data.map(x => !x.isPartial ? `rgba(64, 64, 64, 0.1)` : 'rgba(255, 255, 255, 0.1)'),
                            pointHoverBackgroundColor: ds.data.map(x => !x.isPartial ? `rgba(64, 64, 64, 0.1)` : 'rgba(255, 255, 255, 0.1)'),
                        } : {})
                })
            })
    };

    useChartPrintEffect(chartRef.current, size);

    return (props.enableOptionsMenu ?
        <ChartOptionsMenu
            chartRef={chartRef}
            title={props.title}
            unitType={props.unitType}
            exportFileName={props.exportFileName}
            datasets={datasets}
            additionalSettings={additionalSettings}
            isPrinting={isPrinting}
            chartComponent={
                <Line ref={chartRef} data={chartData} options={options} />
            }
        />
        :
        <div css={chartStyles.responsiveContainer}>
            <Line ref={chartRef} data={chartData} options={options} />
        </div>
    );
};
