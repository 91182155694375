/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { ActivityGroupService } from '../../../Services/ActivityGroupService';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { Button } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { AddActivityGroupContent } from './AddActivityGroupContent';
import { EditActivityGroupContent } from './EditActivityGroupContent';

import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { IconButton } from '../../../CoreComponents/Button';
import { AdminTypes } from '../../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setActivityGroups } from '../../../globalStates/tableDataState';

const activityGroupsStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}

export const ActivityGroupsList = ({ $selectedActivityGroup, $refreshActivityGroups }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $activityGroups = useHook(tableDataState.activityGroups);
    const $isLoading = useHook(!Boolean(tableDataState.activityGroups.length));
    const $isAddActivityGroupModalOpened = useHook(false);
    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton disabled={isTrazerViewAdmin} onClick={() => $selectedActivityGroup.set(rowData)}>
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('activityGroups.table.name'),
            isSortable: true,
        },
    ], [t]);

    useEffect(() => {
        ActivityGroupService.getAll(9999)
            .then(activityGroups => {
                dispatch(setActivityGroups(activityGroups));
                $activityGroups.set(activityGroups);
                $isLoading.set(false);
            })
    }, [$refreshActivityGroups.value]);

    return (
        <div css={activityGroupsStyles.root}>
            <TableCSF
                customActions={
                    <div css={activityGroupsStyles.actions}>
                        <Button disabled={isTrazerViewAdmin} onClick={() => $isAddActivityGroupModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$activityGroups.value}
                rowKeySelector={r => r.groupId}
                isLoading={$isLoading.value}
                totalItemsCount={$activityGroups.value.length}
                showAdvancedSearch={false}
            />
            <Modal
                open={$isAddActivityGroupModalOpened.value}
                onClose={() => $isAddActivityGroupModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <AddActivityGroupContent onCancel={() => $isAddActivityGroupModalOpened.set(false)}
                    onAdd={() => {
                        $isAddActivityGroupModalOpened.set(false);
                        $refreshActivityGroups.set({});
                    }} />
            </Modal>
        </div>
    )
};

export const ActivityGroups = () => {
    const $selectedActivityGroup = useObjectHook(null);
    const $refreshActivityGroups = useHook({});
    const $prevSelectedActivityGroups = useHook(null);

    useEffect(() => {
        if ($prevSelectedActivityGroups.value && $selectedActivityGroup.value) {
            $refreshActivityGroups.set({});
            $prevSelectedActivityGroups.set(null);
            $selectedActivityGroup.set(null);
            return;
        }

        $prevSelectedActivityGroups.set($selectedActivityGroup.value);
    }, [$selectedActivityGroup.value]);

    return (
        <>
            <ActivityGroupsList $selectedActivityGroup={$selectedActivityGroup} $refreshActivityGroups={$refreshActivityGroups} />
            <Modal
                open={!!$selectedActivityGroup.value}
                onClose={() => $selectedActivityGroup.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <EditActivityGroupContent $selectedActivityGroup={$selectedActivityGroup} onCancel={() => $selectedActivityGroup.set(null)} />
            </Modal>
        </>
    )
}
