/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

const checkboxStyle = {
    root: css`
        position: relative;
        color: #858585;
        display: flex;
        align-items: center;
        width: fit-content;

        & .box {
            height: 16px;
            width: 16px;
            margin-right: 4px;
            border: 2px solid #858585;
            border-radius: 4px;
            position: relative;

            & > div {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &.tick {
                    display: block;
                    height: calc(100% - 4px);
                    width: calc(100% - 4px);

                    background-color: #00ABA5;
                }
            }
        }
        & .box.checked {
            border-color: #00ABA5;
        }

        & input {
            margin: 0;

            &[disabled]+.box.checked {
                border-color: #858585;
            }

            &[disabled]+.box > .tick {
                background-color: #858585;
            }
        }

        & input:focus+div.box {
            outline: 10px solid #00ABA520;
        }
    `,
    secondaryColor: css`
        color: #FFFFFF;

        & .box {
            border: 2px solid #FFFFFF;

            & > div.tick {
                background-color: #FFFFFF;
            }
        }
        & .box.checked {
            border-color: #FFFFFF;
        }

        & input:focus+div.box {
            outline: 10px solid #FFFFFF20;
        }
    `
}

export const Checkbox = ({ label, checked, onChange, color = 'primary', ...props }) =>
    <label css={[checkboxStyle.root, color === 'secondary' && checkboxStyle.secondaryColor]} {...props}>
        {/* WARN: Safari needs a container with 'position: relative' for the .visually-hidden class */}
        <input disabled={props.disabled} className="visually-hidden" type="checkbox" checked={checked} onChange={e => onChange(!checked, e)} />
        <div className={checked ? 'box checked' : 'box'}>
            <div className={checked ? 'tick' : ''}></div>
        </div>
        {label && <span>{label}</span>}
    </label>
