/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { subYears } from "date-fns";
import { useEffect, useMemo } from "react";
import { Button } from "../../../CoreComponents/Button";
import { DateRangeSelector } from "../../../CoreComponents/DateRangeSelector";
import { CircularLoader } from "../../../CoreComponents/Loaders";
import { SingleSelect } from "../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../CoreComponents/SelectListComponents/SelectOption";
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { CustomerService } from "../../../Services/CustomerService";
import { dateAndMonth, parseDate } from "../../../Utils/Common";
import { useSelector } from "react-redux";
import { LineChart } from "../../../CoreComponents/ChartComponents/LineChart";

const sessionsWidgetStyle = {
    root: css`
        height: 100%;
        width: 100%;
        position: relative;

        & > .info {
            position: absolute;
            margin: 0;
            & > h2 {
                margin: 5px 0 0 0;
            }
        }
        & > .chart-with-options-container {
            margin: 0;
            position: initial;

            & > canvas {
                position: absolute;
                top: 25px;
            }
        }
    `,
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
    controls: css`
        display: flex;
        align-items: flex-start;
        height: 100%;
        justify-content: space-evenly;
        flex-wrap: wrap;
        flex-direction: column;
        margin-left: 40px;

        & .selector-wrapper {
            min-width: 280px;
        }
    }
    `
}

const DemoData = {
    name: 'user',
    chartData: [
        {
            "item1": "2022-47",
            "item2": 19
        },
        {
            "item1": "2022-48",
            "item2": 5
        },
        {
            "item1": "2022-49",
            "item2": 5
        },
        {
            "item1": "2022-50",
            "item2": 22
        },
        {
            "item1": "2022-51",
            "item2": 90
        },
        {
            "item1": "2022-52",
            "item2": 17
        },
        {
            "item1": "2023-1",
            "item2": 31
        },
        {
            "item1": "2023-11",
            "item2": 72
        },
        {
            "item1": "2023-12",
            "item2": 22
        },
        {
            "item1": "2023-2",
            "item2": 79
        }
    ]
}

export const CustomerNewUsersTrendWidget = ({ $settings, demoMode }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const { customers, isEditMode } = useSelector((state) => state.dashboardState);
    const $isLoading = useHook(!demoMode && ($settings.value?.customerId || !customers.length));
    const $showSettings = useHook(!demoMode && !$settings.value);
    const $dateRange = useObjectHook({
        dateInterval: 'week',
        startDate: !demoMode && $settings.value?.startDate ?
            parseDate($settings.value.startDate, 'yyyy-MM-dd')
            : subYears(new Date(), 2),
        endDate: !demoMode && $settings.value?.endDate ?
            parseDate($settings.value.endDate, 'yyyy-MM-dd')
            : new Date(),
    });
    const $oldSettings = useHook(null);
    const $reportData = useHook([]);
    const $rawData = useHook([]);
    const $selectedCustomer = useHook((!demoMode && $settings.value?.customerId) || storeState.currentUser.customerId);
    const $customerName = useHook('');

    const additionalSettings = useMemo(() => [
        {
            key: 'configure',
            label: t('widgets.actions.configure'),
            handleClick: () => {
                $showSettings.set(true);
                $oldSettings.set($settings.value);
            }
        }
    ], [t])

    const saveConfiguration = () => {
        if (!$selectedCustomer.value && $isLoading.value) {
            return;
        }

        const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
        const endDate = $dateRange.value.endDate.toISOString().split('T')[0];

        $settings.set({
            version: 1,
            settings:
            {
                startDate,
                endDate,
                customerId: $selectedCustomer.value
            }
        });
    }

    useEffect(() => {
        if (demoMode) {
            $rawData.set(
                DemoData.chartData.map(x => ({
                    label: dateAndMonth(parseDate(x.item1, 'YYYY-ww')),
                    value: x.item2
                })))
            $customerName.set('TRAZER')
            return;
        }

        if (!$selectedCustomer.value && customers.length) {
            $isLoading.set(false)
            $showSettings.set(true);
            return;
        }
        if (!$settings.value || !customers.length || $oldSettings.value?.settings === $settings.value) {
            return;
        }
        const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
        const endDate = $dateRange.value.endDate.toISOString().split('T')[0];
        const customer = customers.find(x => x.customerId === $selectedCustomer.value) || {}
        $customerName.set(customer?.name || '')
        $isLoading.set(true);

        CustomerService.overviewTrend(
            $selectedCustomer.value,
            'tests',
            $dateRange.value.dateInterval,
            startDate,
            endDate
        )
            .then((sessions) => {
                $rawData.set((sessions || [])
                    .map(x => ({
                        label: dateAndMonth(parseDate(x.item1, 'YYYY-ww')),
                        value: x.item2
                    })))
            })
            .finally(() => $isLoading.set(false))
            .catch(() => $showSettings.set(true))
    }, [$settings?.value, customers.length])

    useEffect(() => {
        if (!$rawData.value) {
            return;
        }

        $reportData.set(
            [{
                label: t('customerReport.chartData.users'),
                exportSettings: {
                    xAxis: t('customerReport.chartExportDataField.date'),
                    yAxis: t('customerReport.chartData.users'),
                },
                data: $rawData.value
            }]
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, $rawData.value.length]);

    return ($isLoading.value ?
        <div css={sessionsWidgetStyle.loadingContainer}>
            <CircularLoader />
        </div>
        :
        $showSettings.value && !$isLoading.value ?
            <div css={sessionsWidgetStyle.controls}>
                <SingleSelect
                    variant="outlined"
                    label={t('widgets.fieldName.customer')}
                    design="slim"
                    $value={$selectedCustomer}
                >
                    {customers.map(x => (
                        <SelectOption key={x.customerId} value={x.customerId}>
                            {x.name}
                        </SelectOption>
                    ))}
                </SingleSelect>
                <DateRangeSelector
                    label={t('customerReport.controls.dateRange')}
                    $value={$dateRange}
                    design="slim"
                    variant="outlined"
                    labelProps={{
                        className: 'date-range-selector'
                    }}
                />
                <Button onClick={() => {
                    $showSettings.set(false);
                    saveConfiguration();
                }}>{t('actions.save')}</Button>
            </div>
            :
            $reportData.value.length > 0 &&
            <div css={sessionsWidgetStyle.root}>
                <div className="info">
                    <h2>{t('customerReport.chartTitle.newUsersWeeklyTrend')}</h2>
                    <span>{$customerName.value}</span>
                </div>
                <LineChart
                    title={t('customerReport.chartTitle.newUsersWeeklyTrend')}
                    exportFileName={t('customerReport.chartTitle.newUsersWeeklyTrend')}
                    enableOptionsMenu={!demoMode && !isEditMode}
                    datasets={$reportData.value}
                    additionalSettings={additionalSettings}
                />
            </div>
    )
}
