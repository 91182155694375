import { getData, postData, putData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const CategoryService = {
    getAll: function(){
        return getData(`${ApiHost}/api/Categories`)
    },
    postCategory: function(category){
        return postData(`${ApiHost}/api/Categories`, category);
    },
    putCategory: function(category){
        return putData(`${ApiHost}/api/Categories`, category);
    },
}
