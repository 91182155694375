import { deleteData, getData, patchData, postData, urlQueryString } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const TestService = {
    getTestsByIds: function (testIds) {
        return postData(`${ApiHost}/api/Tests/byIds`, testIds)
            .then(tests =>
                tests.map(t => ({
                    ...t,
                    startTime: new Date(t.startTime),
                    endTime: new Date(t.endTime),
                    testSteps: (t.testSteps || []).map(ts => ({
                        ...ts,
                        startTime: t.startTime ? new Date(t.startTime) : null,
                        endTime: t.endTime ? new Date(t.endTime) : null,
                    })),
                    testNote: t.testNote ? {
                        ...t.testNote,
                        dateCreated: t.testNote.dateCreated ? new Date(t.testNote.dateCreated) : null,
                        dateModified: t.testNote.dateModified ? new Date(t.testNote.dateModified) : null,
                    }
                        :
                        null
                }))
            );
    },
    getAllTests: function (limit, userId, customerId, siteId) {
        const qs = urlQueryString({
            limit,
            userId,
            customerId,
            siteId
        }, true);
        return getData(`${ApiHost}/api/Tests?${qs}`)
            .then(tests =>
                tests.map(t => ({
                    ...t,
                    startTime: new Date(t.startTime),
                    endTime: new Date(t.endTime),
                    testSteps: (t.testSteps || []).map(ts => ({
                        ...ts,
                        startTime: t.startTime ? new Date(t.startTime) : null,
                        endTime: t.endTime ? new Date(t.endTime) : null,
                    }))
                }))
            );
    },
    getAllTestsNoMap: function (limit, userId, customerId, siteId) {
        const qs = urlQueryString({
            limit,
            userId,
            customerId,
            siteId
        }, true);
        return getData(`${ApiHost}/api/Tests?${qs}`);
    },
    getUserTests: function (userId, scriptedActivityId) {
        const qs = urlQueryString({
            userId,
            scriptedActivityId,
        }, true);
        return getData(`${ApiHost}/api/Tests/User?${qs}`)
    },
    patchTest: function (testId, content) {
        return patchData(`${ApiHost}/api/Tests/${testId}`, content)
    },
    /**
     * @param {{
     *  "customer_id": ["6f9619ff-8b86-d011-b42d-00c04fc964ff"],
     *  "site_id": ["6f9619ff-8b86-d011-b42d-00c04fc964ff"],
     *  "dateStart": "02/23/2021",
     *  "dateEnd":"02/23/2023",
     *  "genders": ["1","2"],
     *  "ageMin":"5",
     *  "ageMax":"50",
     *  "heightMin":"5",
     *  "heightMax":"500",
     *  "weightMin":"5",
     *  "weightMax":"500",
     *  "sports": ["1","5"],
     *  "positions": ["1","5","8"],
     *  "injuryLocations": ["1","5","8"],
     *  "injuryTypes": ["1","5","8"],
     *  "dominance": ["1","2"],
     *  "userGroups": ["2ae39aeb-3403-4002-9270-1cb646e7ed12"],
     *  "excludePartials": false,
     *  "excludeTUsers": false
     * }} filters Search filters
     */
    filtered: function (filters) {
        return postData(`${ApiHost}/api/Tests/filtered`, filters)
            .then(tests =>
                tests.map(t => ({
                    ...t,
                    startTime: t.startTime ? new Date(t.startTime) : null,
                    endTime: t.endTime ? new Date(t.endTime) : null,
                }))
            );
    },
    getSessionTrends: function (numDataPoints, customerId, siteId) {
        const qs = urlQueryString({
             num_data_points: numDataPoints,
             customer_id: customerId,
             site_id: siteId
             }, true);
        return getData(`${ApiHost}/api/Tests/Trend?${qs}`);
    },
    deleteTest: function (testId) {
        return deleteData(`${ApiHost}/api/Tests/${testId}`);
    },
    getTestReportData: function (testIds) {
        return postData(`${ApiHost}/api/Tests/TestReport`, testIds)
            .then(tests =>
                tests.map(t => ({
                    ...t,
                    startTime: new Date(t.startTime),
                    endTime: new Date(t.endTime),
                    testSteps: (t.testSteps || []).map(ts => ({
                        ...ts,
                        startTime: t.startTime ? new Date(t.startTime) : null,
                        endTime: t.endTime ? new Date(t.endTime) : null,
                    })),
                    testNote: t.testNote ? {
                        ...t.testNote,
                        dateCreated: t.testNote.dateCreated ? new Date(t.testNote.dateCreated) : null,
                        dateModified: t.testNote.dateModified ? new Date(t.testNote.dateModified) : null,
                    }
                        :
                        null
                }))
            );
    },
    getCompareModalTests: function (testId, startDate, endDate) {
        const qs = urlQueryString({
            testId: testId,
            startDate: startDate,
            endDate: endDate
        }, true);
        return getData(`${ApiHost}/api/Tests/CompareModal?${qs}`)
            .then(tests => tests.map(t => ({
                ...t,
                date: new Date(t.date)
            })));
    },
    getHistoryData: function (testId, startDate, endDate) {
        const qs = urlQueryString({
            testId: testId,
            startDate: startDate,
            endDate: endDate
        }, true);
        return getData(`${ApiHost}/api/Tests/History?${qs}`);
    },
    getReportEmail: function (testId, userId, providerId) {
        const qs = urlQueryString({
            userId: userId,
            providerId: providerId
        }, true);
        return getData(`${ApiHost}/api/Tests/EmailReport/${testId}?${qs}`);
    }
}
