/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    maxLengthValidator,
    requiredValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { ActivitySettingsTable } from '../../../CoreComponents/ActivitySettingsTable'
import { BaseActivityService } from '../../../Services/BaseActivityService';
import { ActivityTypesColors, ActivityTypesTranslationKeysArr } from '../../../Utils/Constants';
import { AlertText } from '../../../CoreComponents/Alert';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const BaseActivitiesTabStyles = {
    settingsTable: css`
        height: 50vh;
        overflow: auto;
        margin: 40px 0;
        margin-left: -20px;
    `,
    root: css`
        padding: 40px 0 40px 40px;

        & > h2 {
            margin-top: 0;
            margin-bottom: 40px;
        }

        & > .actions {
            margin-top: auto;
            width: 100%;
            display: flex;

            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }

        & > .horizontal-line {
            border-top: 2px solid rgb(211,211,211);
            width: 100%;
        }
    `,
    fieldsContainer: css`
        display: flex;
        width: 100%;
        justify-content: space-between;

        & > * {
            margin: 0 0 20px 0;
            width: calc(50% - 20px);
        }

        & .selected-item, & .select-option {
            display: flex;
            & > * {
                margin: auto 0;
            }
        }
    `
};

const EditBaseActivityValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
};

export const EditBaseActivity = ({ $selectedBaseActivity, onCancel }) => {
    const { t } = useTranslation();
    const $isForceValidating = useHook(true);
    const $baseActivityCopy = useObjectHook(
        $selectedBaseActivity.value,
        EditBaseActivityValidator,
        null,
        $isForceValidating.value
    );

    const updateBaseActivity = () => {
        // if we want to validate after submission
        // then it should be set to false by default
        $isForceValidating.set(true);
        const errors = $baseActivityCopy.validate();
        if (errors) {
            return;
        }
        BaseActivityService.putBaseActivity({
            ...$baseActivityCopy.value,
            isActive: true
        })
        .then(() => $selectedBaseActivity.set($baseActivityCopy.value));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={BaseActivitiesTabStyles.root}>
                <h2>{t('baseActivities.editModal.title')}</h2>

                <div css={BaseActivitiesTabStyles.fieldsContainer}>
                    <Input label={t('baseActivities.fieldName.name')} required $value={$baseActivityCopy.getPropHook('name')} />
                    <SingleSelect $value={$baseActivityCopy.getPropHook('activityTypeId')} required label={t('baseActivities.fieldName.type')}>
                        {ActivityTypesTranslationKeysArr.map(x =>
                            <SelectOption key={x.value} value={x.value}>
                                <div style={{
                                    backgroundColor: ActivityTypesColors[x.value],
                                    width: '12px',
                                    height: '12px',
                                    borderRadius: '50%',
                                    marginRight: '8px',
                                }}>
                                </div>
                                {t(x.translationKey)}
                            </SelectOption>
                        )}
                    </SingleSelect>
                </div>
                <div className="horizontal-line"></div>
                <div css={BaseActivitiesTabStyles.settingsTable}>
                    <ActivitySettingsTable
                        onChange={settings => $baseActivityCopy.set({
                            ...$baseActivityCopy.value,
                            defaultSettings: JSON.stringify(settings)
                        })}
                        settings={JSON.parse($baseActivityCopy.value.defaultSettings)}
                    />
                </div>
                <div className="actions">
                    {$baseActivityCopy.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}

                    <Button variant="outlined" onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={!$baseActivityCopy.isValid} onClick={updateBaseActivity}>{t('actions.update')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
