/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect } from "react";
import { Button } from "../../../CoreComponents/Button";
import { Input, TextareaInput } from "../../../CoreComponents/Input";
import { useTranslation } from "../../../CoreComponents/Translation";
import { ForceValidateContext, useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { ScriptedActivitiesService } from "../../../Services/ScriptedActivitiesService";
import { CategoryService } from "../../../Services/CategoryService";
import { TagService } from "../../../Services/TagService";
import { PillarService } from "../../../Services/PillarService";
import { ActivityGroupService } from "../../../Services/ActivityGroupService";
import { maxLengthValidator, requiredValidator } from "../../../Utils/Validators";
import { AlertText } from "../../../CoreComponents/Alert";
import { SingleSelect } from "../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../CoreComponents/SelectListComponents/SelectOption";
import { MultiSelect, MultiSelectOption } from "../../../CoreComponents/SelectListComponents/MultiSelect";

const addScriptedActivityStyles = {
    root: css`
        width: unset;
        height: 85vh;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0;
            margin-bottom: 40px;
        }

        & > .field-container {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            max-height: calc(100% - 110px - 1.5em);
            gap: 30px;

            & > .input-root {
                margin: 0;
                width: 100%;
            }

            @media (min-resolution: 96dpi),
                screen and (max-height: 1030px) {
                padding-right: 20px;
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }

        & > .alert-text-root {
            align-self: end;
            margin: 20px 0;
        }

        & textarea {
            height: 260px;
            resize: none;
            overflow-y: auto;
        }
    `
};

const AddScriptedActivityValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
    categoryId: [requiredValidator]
};

export const AddScriptedActivity = ({ onCancel, onAdd }) => {
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const $categories = useHook([]);
    const $tags = useHook([]);
    const $pillars = useHook([]);
    const $groups = useHook([]);
    const { t } = useTranslation();

    const $newScriptedActivity = useObjectHook(
        {
            name: '',
            alias: '',
            categoryId: 0,
            satags: [],
            sapillars: [],
            sagroups: [],
            summary: '',
        },
        AddScriptedActivityValidator,
        null,
        $isForceValidating.value
    );

    const addScriptedActivity = () => {
        $isForceValidating.set(true);
        const errors = $newScriptedActivity.validate();
        if (errors) {
            return;
        }

        $isSaving.set(true);
        ScriptedActivitiesService.postScriptedActivity($newScriptedActivity.value)
            .then(scriptedActivity => {
                onAdd(scriptedActivity)
            })
            .finally(() => $isSaving.set(false));
    };

    useEffect(() => {
        CategoryService.getAll()
            .then(categories => $categories.set(categories));

        TagService.getAll()
            .then(tags => $tags.set(tags));

        PillarService.getAll()
            .then(pillars => $pillars.set(pillars));

        ActivityGroupService.getAll()
            .then(groups => $groups.set(groups));
    }, [])

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addScriptedActivityStyles.root}>
                <h2>{t('scriptedActivity.addModal.title')}</h2>
                <div className="field-container">
                    <Input
                        label={t('scriptedActivity.fieldName.name')}
                        required
                        placeholder={t('input.placeholder')}
                        $value={$newScriptedActivity.getPropHook('name')}
                        />
                    <Input
                        label={t('scriptedActivity.fieldName.alias')}
                        placeholder={t('input.placeholder')}
                        $value={$newScriptedActivity.getPropHook('alias')}
                        />
                    <SingleSelect
                        label={t('scriptedActivity.fieldName.category')}
                        required
                        $value={$newScriptedActivity.getPropHook('categoryId')}
                        disableFiltering
                    >
                        {$categories.value.map(g => <SelectOption key={g.categoryId} value={g.categoryId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                    <MultiSelect
                        label={t('scriptedActivity.fieldName.tags')}
                        $value={$newScriptedActivity.getPropHook('satags')}
                    >
                        {$tags.value.map(t => <MultiSelectOption key={t.tagId} value={t}>{t.name}</MultiSelectOption>)}
                    </MultiSelect>
                    <MultiSelect
                        label={t('scriptedActivity.fieldName.pillars')}
                        $value={$newScriptedActivity.getPropHook('sapillars')}
                    >
                        {$pillars.value.map(p => <MultiSelectOption key={p.pillarId} value={p}>{p.name}</MultiSelectOption>)}
                    </MultiSelect>
                    <MultiSelect
                        label={t('scriptedActivity.fieldName.groups')}
                        $value={$newScriptedActivity.getPropHook('sagroups')}
                    >
                        {$groups.value.map(g => <MultiSelectOption key={g.groupId} value={g}>{g.name}</MultiSelectOption>)}
                    </MultiSelect>
                    <TextareaInput
                        placeholder={t('input.placeholder')}
                        label={t('scriptedActivity.fieldName.summary')}
                        $value={$newScriptedActivity.getPropHook('summary')}
                    />
                </div>

                {$newScriptedActivity.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                <div className="actions" css={addScriptedActivityStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newScriptedActivity.isValid} onClick={addScriptedActivity}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
}
