// WARN: this is written with double quotes because we can change it faster to a json
const ESTranslations = {
    "mainNavigation.overview": "Visión general",
    "mainNavigation.manage": "Gestionar",
    "mainNavigation.library": "Biblioteca",
    "mainNavigation.analytics": "Análisis",
    "mainNavigation.dashboard": "Tablero",
    "mainNavigation.profileSettingsTooltip": "Abrir perfil",
    "mainNavigation.profileSettingsProfile": "Mi perfil",
    "mainNavigation.profileSettingsHelp": "Ayuda",
    "mainNavigation.profileSettingsDocumentation": "Documentación",
    "mainNavigation.profileSettingsContactSupport": "Contactar soporte",
    "mainNavigation.userView": "Cambiar vista de usuario",
    "mainNavigation.userView.reset": "Restablecer vista de usuario",
    "mainNavigation.selectViewModal.title": "Seleccionar rol de vista",
    "manage.sideNavigation.categories": "Categorías",
    "manage.sideNavigation.tags": "Etiquetas",
    "manage.sideNavigation.baseActivities": "Actividades base",
    "manage.sideNavigation.scriptedActivities": "Actividades scriptadas",
    "manage.sideNavigation.customers": "Clientes",
    "manage.sideNavigation.sites": "Sitios",
    "manage.sideNavigation.units": "Unidades",
    "manage.sideNavigation.userGroups": "Grupos de usuarios",
    "manage.sideNavigation.users": "Usuarios",
    "manage.sideNavigation.tests": "Pruebas",
    "coreComponents.table.paginationInfo": "{0}-{1} de {2}",
    "coreComponents.table.paginationInfoMenuTitle": "Mostrar hasta",
    "coreComponents.table.paginationInfoMenuItemTemplate": "{0} elementos",
    "coreComponents.table.noMatchingRecords": "No se encontraron registros coincidentes",
    "coreComponents.table.advancedSearchTitle": "Búsqueda avanzada",
    "coreComponents.table.visibleColumns": "Columnas visibles",
    "coreComponents.select.noResultsSearchTemplate": "No hay resultados que coincidan con \"{0}\"",
    "constants.gender.nonBinary": "No binario",
    "constants.gender.female": "Femenino",
    "constants.gender.male": "Masculino",
    "constants.dominance.unknown": "Desconocido",
    "constants.dominance.left": "Izquierda",
    "constants.dominance.right": "Derecha",
    "constants.dominance.ambidextrous": "Ambidiestro",
    "constants.adminType.TRAZERAdmin": "Admin TRAZER",
    "constants.adminType.TRAZERView": "Vista TRAZER",
    "constants.adminType.SuperAdmin": "Super Admin",
    "constants.adminType.SiteAdmin": "Admin de sitio",
    "constants.adminType.Provider": "Proveedor",
    "constants.adminType.User": "Usuario",
    "constants.side.left": "Izquierda",
    "constants.side.right": "Derecha",
    "constants.side.none": "NA",
    "constants.testStatuses.empty": "Vacío",
    "constants.testStatuses.complete": "Completo",
    "constants.testStatuses.partial": "Parcial",
    "constants.testStatuses.deleted": "Eliminado",
    "constants.missTypes.leaningOrSway": "Se detecta inclinación o balanceo",
    "constants.missTypes.stumbleOrFall": "Se detecta tropiezo o caída",
    "constants.missTypes.leftHandOffHip": "Mano izquierda fuera de la cadera",
    "constants.missTypes.rightHandOffHip": "Mano derecha fuera de la cadera",
    "constants.missTypes.step": "Se detecta paso",
    "constants.missTypes.leftToeRaised": "Dedo del pie izquierdo levantado",
    "constants.missTypes.rightToeRaised": "Dedo del pie derecho levantado",
    "constants.missTypes.leftHeelRaised": "Talón izquierdo levantado",
    "constants.missTypes.rightHeelRaised": "Talón derecho levantado",
    "constants.missTypes.plantToeRaised": "Dedo del pie plantado levantado",
    "constants.missTypes.plantHeelRaised": "Talón plantado levantado",
    "constants.missTypes.freeFootDropped": "Pie libre caído",
    "constants.missTypes.freeAnkleDropped": "Tobillo libre caído",
    "constants.missTypes.freeKneeTooHigh": "Rodilla libre demasiado alta",
    "constants.missTypes.freeKneeTooFar": "Rodilla libre demasiado lejos",
    "constants.unitType.meters": "Metros (m)",
    "constants.unitType.feet": "Pies (ft)",
    "constants.unitType.metersShort": "m",
    "constants.unitType.feetShort": "ft",
    "constants.unitType.inchShort": "in",
    "constants.unitType.centimetersShort": "cm",
    "constants.unitType.poundShort": "libras",
    "constants.unitType.units": "Unidades",
    "constants.unitType.calories": "kcal",
    "constants.units.count": "Contar",

    "constants.units.seconds": "Segundos",
    "constants.units.secondsShort": "s",
    "constants.units.millisecondsShort": "ms",
    "constants.units.m/s": "m/s",
    "constants.units.ft/s": "ft/s",
    // "constants.units.m/s^2": "m/s<sup>2</sup>",
    // "constants.units.ft/s^2": "ft/s<sup>2</sup>",
    "constants.units.m/s^2": "m/s\u00B2",
    "constants.units.ft/s^2": "ft/s\u00B2",
    "constants.units.beatsPerMinute": "bpm",
    "constants.units.degrees": "\u00B0",
    "constants.units.calories": "Calorías",
    "constants.units.caloriesShort": "cal",
    "constants.dateRanges.currentWeek": "Semana actual",
    "constants.dateRanges.lastWeek": "Semana pasada",
    "constants.dateRanges.thisMonth": "Este mes",
    "constants.dateRanges.lastMonth": "Mes pasado",
    "constants.dateRanges.last6Months": "Últimos 6 meses",
    "constants.dateRanges.thisYear": "Este año",
    "constants.dateRanges.lastYear": "Año pasado",
    "constants.dateRanges.customRange": "Rango personalizado",
    "constants.activityTypes.mobility": "Movilidad",
    "constants.activityTypes.cognitive": "Cognitivo",
    "constants.activityTypes.balance": "Equilibrio",
    "constants.activityTypes.kinematics": "Cinética",
    "constants.activityTypes.games": "Juegos",
    "constants.activityTypes.upperExtremity": "Extremidad superior",
    "constants.alertType.info": "Información",
    "constants.alertType.warning": "Advertencia",
    "constants.alertType.success": "Éxito",
    "constants.alertType.error": "Error",
    "input.placeholder": "Escribe aquí...",
    "input.placeholder.min": "Mín",
    "input.placeholder.max": "Máx",
    "input.searchPlaceholder": "Buscar...",
    "dropdown.emptyOption": "Seleccionar",
    "chart.options.viewInFullScreen": "Ver a pantalla completa",
    "chart.options.exitFullScreen": "Salir de pantalla completa",
    "chart.options.print": "Imprimir",
    "chart.options.downloadPngImage": "Descargar imagen PNG",
    "chart.options.downloadJpgImage": "Descargar imagen JPEG",
    "chart.options.downloadCsv": "Descargar CSV",
    "chart.options.downloadXls": "Descargar XLS",
    "chart.normalDistribution.leftAxisClarification": "Conteo",
    "chart.normalDistribution.rightAxisClarification": "Distribución normal",
    "chart.normalDistribution.sizeInfo": "Tamaño (n): {0}",
    "chart.normalDistribution.meanOrAverageInfo": "Media/Promedio (μ): {0}{1}",
    "chart.normalDistribution.standardDeviationInfo": "Desviación estándar (σ): {0}{1}",
    "chart.normalDistribution.datasetNameHistogram": "Histograma",
    "chart.normalDistribution.datasetNameNormalDistribution": "Distribución normal",
    "chart.normalDistribution.datasetNameData": "Datos",
    "chart.normalDistribution.exportRangeTemplate": "{0} Rango",
    "chart.normalDistribution.exportColumnCount": "Conteo",
    "actions.deactivate": "Desactivar",
    "actions.activate": "Activar",
    "actions.repurpose": "Reutilizar",
    "actions.unregister": "Anular registro",
    "actions.retry": "Reintentar",
    "actions.logout": "Cerrar sesión",
    "actions.login": "Acceso",
    "actions.update": "Actualizar",
    "actions.add": "Agregar",
    "actions.cancel": "Cancelar",
    "actions.delete": "Eliminar",
    "actions.apply": "Aplicar",
    "actions.edit": "Editar",
    "actions.done": "Hecho",
    "actions.changePassword": "Cambiar contraseña",
    "actions.resendVerificationEmail": "Reenviar correo electrónico",
    "actions.export": "Exportar",
    "actions.exportCsv": "CSV",
    "actions.exportExcel": "MS-Excel",
    "actions.change": "Cambiar",
    "actions.next": "Siguiente",
    "actions.previous": "Anterior",
    "actions.chooseFile": "Seleccionar archivo",
    "actions.submit": "Enviar",
    "actions.backToManage": "Volver a gestionar",
    "actions.tryAgain": "Intentar de nuevo",
    "actions.save": "Guardar",
    "actions.markAll": "Marcar todo",
    "actions.selectAll": "Seleccionar todo",
    "actions.editUsers": "Editar usuarios",
    "actions.editName": "Editar nombre",
    "actions.compareTests": "Pruebas",
    "actions.compareSteps": "Pasos",
    "actions.compare": "Comparar",
    "actions.setAsBaseline": "Establecer como referencia",

    "actions.learnMore": "Más información",
    "actions.search": "Buscar",
    "actions.showMoreFilters": "Mostrar más filtros",
    "actions.showLessFilters": "Mostrar menos filtros",
    "actions.generateReport": "Generar informe",
    "actions.exportTestData": "Exportar datos de prueba",
    "actions.exportRepData": "Exportar datos de repeticiones",
    "actions.viewAll": "Ver todo",
    "actions.register": "Registrar",
    "actions.yes": "Sí",
    "actions.unblock": "Desbloquear",
    "actions.print": "Imprimir",
    "actions.syncAdmin": "Sincronizar administrador",
    "users.fieldName.firstName": "Nombre",
    "users.fieldName.lastName": "Apellido",
    "users.fieldName.username": "Nombre de usuario",
    "users.fieldName.email": "Correo electrónico",
    "users.fieldName.phoneNumber": "Número de teléfono",
    "users.fieldName.medicalRecordsNumber": "Número de expediente médico",
    "users.fieldName.dateOfBirth": "Fecha de nacimiento",
    "users.fieldName.height": "Altura",
    "users.fieldName.weight": "Peso",
    "users.fieldName.gender": "Género",
    "users.fieldName.dominance": "Dominancia",
    "users.fieldName.pin": "PIN TRAZER",
    "users.fieldName.optionalPin": "PIN TRAZER (opcional)",
    "users.fieldName.customer": "Cliente",
    "users.fieldName.site": "Sitio al que vincular al usuario",
    "users.fieldName.group": "Grupo al que vincular al usuario",
    "users.fieldName.password": "Contraseña",
    "users.fieldName.newPassword": "Nueva contraseña",
    "users.fieldName.confirmPassword": "Confirmar contraseña",
    "users.fieldName.adminRole": "Rol de administrador",
    "users.fieldName.role": "Rol",
    "users.addOptions.user": "Usuario",
    "users.addOptions.admin": "Administrador",
    "users.importOptions.user": "Usuario",
    "users.importOptions.admin": "Administrador",
    "register.title": "Registrar usuario",
    "register.fieldName.code": "Código",
    "register.message.invalidCode": "¡Código inválido!",
    "register.message.usernameTaken": "¡El nombre de usuario ya está en uso!",
    "register.message.success": "¡Éxito!",
    "register.message.successDescription": "Has completado el proceso de registro y ahora puedes usar TRAZER con tu nombre de usuario {0}.",
    "admins.fieldName.customer": "Cliente",
    "admins.fieldName.site": "Sitio",
    "admins.fieldName.password": "Contraseña",
    "admins.fieldName.user": "Usuario",
    "admins.fieldName.adminRole": "Rol de administrador",
    "customers.fieldName.name": "Nombre",
    "users.import": "Importar",
    "users.import.skip": "Omitir",
    "users.importUsers": "Importar usuarios",
    "users.editModal.title": "Editar usuario",
    "users.editModal.profileInformationTabName": "Información de perfil",
    "users.editModal.adminSettingsTabName": "Configuraciones de administrador",
    "users.editModal.testsTabName": "Pruebas",
    "users.editModal.groupsTabName": "Grupos",
    "users.editModal.connectedSitesTabName": "Sitios conectados",
    "users.editModal.sportsTabName": "Deportes",
    "users.editModal.injuryHistoryTabName": "Historial de lesiones",
    "customers.editModal.editCustomerTabName": "Cliente",
    "customers.editModal.sitesTabName": "Sitios",
    "sites.editModal.title": "Editar sitio",
    "sites.editModal.sitesTab": "Información del sitio",
    "sites.editModal.siteUnitsTab": "Unidades del sitio",
    "site.deleteModal.title": "Eliminar sitio",
    "site.deleteModal.description": "¿Estás seguro de que quieres eliminar este sitio? Este proceso no se puede deshacer.",
    "userGroups.removeGroupModal.title": "Eliminar grupo",
    "userGroups.removeUserGroupModal.title": "Eliminar usuario de un grupo",
    "userGroups.removeGroupModal.description": "¿Estás seguro de que quieres eliminar este grupo? Este proceso no se puede deshacer.",
    "userGroups.removeUserGroupModal.description": "¿Estás seguro de que quieres quitar al usuario de este grupo?",
    "userSport.deleteModal.title": "Eliminar deporte",
    "userSport.deleteModal.description": "¿Estás seguro de que quieres eliminar este deporte? Este proceso no se puede deshacer.",
    "userInjury.deleteModal.title": "Eliminar lesión",

    "userInjury.deleteModal.description": "¿Estás seguro de que quieres eliminar esta lesión? Este proceso no se puede deshacer.",
    "tests.deleteModal.title": "Eliminar prueba",
    "tests.deleteModal.description": "¿Estás seguro de que quieres eliminar esta prueba? Este proceso no se puede deshacer.",
    "customers.table.createdOn": "Creado el",
    "customers.table.name": "Nombre",
    "customers.table.sites": "Sitios",
    "users.addModal.title": "Agregar usuario",
    "users.addGroupModal.title": "Agregar a un grupo",
    "users.deactivateModal.title": "Desactivar usuario",
    "users.deactivateModal.description": "¿Estás seguro de que quieres desactivar a este usuario?",
    "userGroups.deactivateModal.title": "Eliminar grupo",
    "userGroups.deactivateModal.description": "¿Estás seguro de que quieres eliminar este grupo? Este proceso no se puede deshacer.",
    "admins.addModal.title": "Agregar administrador",
    "customers.addModal.title": "Agregar cliente",
    "sites.addModal.title": "Agregar sitio",
    "groups.addModal.title": "Agregar grupo",
    "sports.addModal.title": "Agregar deporte",
    "injury.addModal.title": "Agregar lesión",
    "injury.editModal.title": "Editar lesión",
    "changePassword.modal.title": "Cambiar contraseña",
    "setPin.modal.title": "Crear un PIN de administrador",
    "setPin.modal.description": "Crea un PIN de 4 dígitos para tu perfil de administrador y accede al Modo sin conexión en TRAZER.",
    "users.table.username": "Nombre de usuario",
    "users.table.name": "Nombre",
    "users.table.medicalRecordNumber": "Número de expediente médico",
    "users.table.sites": "Sitios",
    "users.table.role": "Rol",
    "users.table.createdOn": "Creado el",
    "users.table.lastUnitLogin": "Último inicio de sesión",
    "users.table.firstName": "Nombre",
    "users.table.lastName": "Apellido",
    "users.table.email": "Correo electrónico",
    "users.table.dateOfBirth": "Fecha de nacimiento",
    "users.table.gender": "Género",
    "users.table.height": "Altura",
    "users.table.weight": "Peso",
    "users.table.phoneNumber": "Número de teléfono",
    "users.table.timeAdded": "Tiempo añadido",
    "users.table.adminType": "Tipo de administrador",
    "users.tableFilter.isActive": "Activo",
    "users.tableFilterValue.all": "Todos",
    "users.tableFilterValue.activeOnly": "Solo activos",
    "users.tableFilterValue.inactiveOnly": "Solo inactivos",
    "importUsers.columnTemplate": "Columna {0}",
    "importUsers.editEntriesStep.hint": "Selecciona encabezado para cada columna.",
    "importUsers.editEntriesStep.errorMessage": "Uno o más usuarios tienen entradas faltantes. Por favor, carga un nuevo archivo.",
    "importUsers.attachStep.hint": "Selecciona el sitio al que vincular a los usuarios",
    "importUsers.attachStep.customer": "Cliente",
    "importUsers.attachStep.site": "Sitio",
    "importUsers.attachStep.userGroup": "Grupo de usuarios",
    "importUsers.importFileStep.hint": "Importa un archivo separado por comas (.csv) o (.txt) con información de usuario",
    "importUsers.importFileStep.unitFormatsTitle": "Información de usuario requerida y formatos aceptados:",
    "importUsers.importFileStep.dragToUpload": "Arrastra el archivo para cargarlo, o",
    "importUsers.importFileStep.unitFormatsGender": "Género",
    "importUsers.importFileStep.unitFormatsHeight": "Altura",
    "importUsers.importFileStep.unitFormatsFirstName": "Nombre",
    "importUsers.importFileStep.unitFormatsLastName": "Apellido",
    "importUsers.importFileStep.unitFormatsDateOfBirth": "Fecha de nacimiento",
    "importUsers.importFileStep.unitFormatsUsername": "Nombre de usuario",
    "importUsers.importFileStep.unitFormatsEmail": "Correo electrónico",
    "importUsers.importFileStep.unitFormatsPhoneNumber": "Número de teléfono",
    "importUsers.importFileStep.unitFormatsMedicalRecordNumber": "Número de expediente médico",
    "importUsers.importFileStep.unitFormatsTitleOptional": "Información de usuario opcional:",
    "importUsers.importFileStep.unitFormatsHeightDescription": "(si no hay unidades, se asume que las unidades son pulgadas)",
    "importUsers.importFileStep.unitFormatsWeight": "Peso",
    "importUsers.importFileStep.unitFormatsWeightDescription": "(si no hay unidades, se asume que las unidades son libras)",
    "importUsers.importFileStep.unitFormatsAdminType": "Tipo de administrador:",

    "importUsers.importFileStep.unitFormatsAdminTypeDescription": " (si no se proporciona el tipo de administrador, se asume que no hay rol de administrador)",
    "importUsers.importFileStep.columnHeaderIncludedTitle": "Por favor, indica si se incluye la fila de encabezado de columna",
    "importUsers.importFileStep.columnHeaderIncludedCheckbox": "Fila de encabezado de columna incluida",
    "importUsers.wrongUsers.errorMessage": "Hemos detectado problemas con los siguientes usuarios. Las causas probables podrían ser la falta o existencia de nombre de usuario y correo electrónico.",
    "importUsers.successfullyAdded.successMessage": "¡Usuarios agregados exitosamente!",
    "importUsers.steps.importFile": "Importar archivo",
    "importUsers.steps.editEntries": "Editar entradas",
    "importUsers.steps.attach": "Adjuntar",
    "tests.table.scriptedActivity": "Actividad guiada",
    "tests.table.time": "Tiempo",
    "tests.table.name": "Nombre",
    "tests.table.username": "Nombre de usuario",
    "tests.table.site": "Sitio",
    "tests.table.status": "Estado",
    "groups.table.group": "Grupo",
    "groups.table.customer": "Nombre del cliente",
    "groups.table.site": "Nombre del sitio",
    "groups.fieldName.name": "Nombre",
    "groups.fieldName.customer": "Cliente",
    "groups.fieldName.site": "Sitio",
    "groups.fieldName.group": "Grupo",
    "userGroup.deleteModal.title": "Eliminar usuario",
    "userGroup.deleteModal.description": "¿Estás seguro de que quieres eliminar al usuario de este grupo?",
    "sites.fieldName.site": "Sitio",
    "sites.fieldName.customer": "Cliente",
    "sites.fieldName.name": "Nombre del sitio",
    "sites.fieldName.contactName": "Nombre de contacto",
    "sites.fieldName.phone": "Número de teléfono",
    "sites.fieldName.email": "Correo electrónico",
    "sites.fieldName.address": "Dirección",
    "sites.fieldName.addressLine2": "Línea de dirección 2",
    "sites.fieldName.city": "Ciudad",
    "sites.fieldName.state": "Estado/Provincia",
    "sites.fieldName.zipCode": "Código postal",
    "sites.fieldName.country": "País",
    "sites.fieldName.pin": "PIN del sitio",
    "sites.table.name": "Nombre del sitio",
    "sites.table.pin": "PIN del sitio",
    "sites.table.customer.name": "Nombre del cliente",
    "sites.table.contactName": "Nombre de contacto",
    "sites.table.phone": "Número de teléfono",
    "sites.table.email": "Correo electrónico",
    "sports.table.sport": "Deporte",
    "sports.table.position": "Posición",
    "sports.table.level": "Nivel",
    "sport.fieldName.sport": "Deporte",
    "sport.fieldName.position": "Posición",
    "sport.fieldName.level": "Nivel",
    "units.table.serialNumber": "Número de serie",
    "units.table.skuNumber": "Número de SKU",
    "units.table.createdOn": "Creado el",
    "units.table.lastOnline": "Último en línea",
    "units.table.customerName": "Cliente",
    "units.table.siteName": "Sitio",
    "units.table.clientVersion": "Versión",
    "units.table.isActive": "Activo",
    "units.table.isRegistered": "Registrado",
    "units.addModal.title": "Agregar unidad",
    "units.editModal.title": "Información de la unidad",
    "units.editModal.activityLibraryTab": "Biblioteca de actividades",
    "units.editModal.unitSettingsTab": "Configuración de la unidad",
    "units.editModal.activityLibraryTab.title": "Editar biblioteca de actividades",
    "units.editModal.activityLibraryTab.assessments": "Evaluaciones",
    "units.editModal.activityLibraryTab.drillIndex": "Índice de ejercicios",
    "units.editModal.activityLibraryTab.games": "Juegos",
    "units.editModal.activityLibraryTab.rehabilitation": "Rehabilitación",
    "units.editModal.activityLibraryTab.sports": "Deportes",
    "units.editModal.activityLibraryTab.workouts": "Entrenamientos",
    "units.checkbox.searchByUsername": "Nombre de usuario",
    "units.checkbox.searchByFirstName": "Nombre",
    "units.checkbox.searchByLastName": "Apellido",
    "units.switch.requireUserPIN": "Requerir PIN de usuario",
    "units.switch.requireOrientationActivity": "Requerir actividad de orientación",
    "units.switch.displayFirstAndLastName": "Mostrar nombre y apellido (búsqueda de usuario)",
    "units.fieldName.customer": "Cliente",
    "units.fieldName.site": "Sitio",
    "units.fieldName.skuNumber": "Número de SKU",

    "units.fieldName.notes": "Notas",
    "units.fieldName.defaultUnits": "Unidades predeterminadas",
    "units.fieldName.adminLogout": "Cerrar sesión de administrador después de los siguientes minutos de inactividad:",
    "units.fieldName.userLogout": "Cerrar sesión de usuario después de los siguientes minutos de inactividad:",
    "units.fieldName.verifyUser": "Verificar cierre de sesión de usuario después de los siguientes minutos de inactividad:",
    "units.fieldName.searchBy": "Buscar por (Búsqueda de usuario)",
    "units.tableFilterValue.all": "Todo",
    "units.tableFilterValue.activeOnly": "Solo activos",
    "units.tableFilterValue.inactiveOnly": "Solo inactivos",
    "units.validationErrors.adminAutoLogoutTime": "Debe ser mayor o igual al tiempo de cierre de sesión automático del usuario",
    "units.validationErrors.userAutoLogoutTime": "Debe ser menor o igual al tiempo de cierre de sesión automático del administrador y mayor o igual al tiempo de inactividad del usuario",
    "units.validationErrors.userInactiveTime": "Debe ser menor o igual al tiempo de cierre de sesión automático del usuario",
    "injury.table.location": "Ubicación de la lesión",
    "injury.table.type": "Tipo",
    "injury.table.side": "Lado",
    "injury.table.date": "Fecha de la lesión",
    "injury.table.status": "Estado",
    "injury.table.surgeryDate": "Fecha de la cirugía",
    "injury.fieldName.location": "Ubicación de la lesión",
    "injury.fieldName.type": "Tipo de lesión",
    "injury.fieldName.side": "Lado de la lesión",
    "injury.fieldName.dateOfInjury": "Fecha de la lesión",
    "injury.fieldName.status": "Estado de la lesión",
    "injury.fieldName.dateOfSurgery": "Fecha de la cirugía",
    "injury.surgeryDate.error": "La fecha de la cirugía debe ser posterior a la fecha de la lesión",
    "customerReport.controls.dateRange": "Rango de fechas",
    "customerReport.tableTitle.sitesOverview": "Resumen de sitios",
    "customerReport.tableTitle.mostPopularScriptedActivities": "Actividades guiadas más populares",
    "customerReport.sitesOverviewTable.siteName": "Nombre del sitio",
    "customerReport.sitesOverviewTable.lastOnline": "Último en línea",
    "customerReport.sitesOverviewTable.totalUsers": "Usuarios",
    "customerReport.sitesOverviewTable.totalSessions": "Sesiones",
    "customerReport.scriptedActivitiesTable.name": "Nombre",
    "customerReport.scriptedActivitiesTable.sessionsCount": "Sesiones",
    "customerReport.chartExportDataField.date": "Fecha",
    "customerReport.chartExportDataField.siteName": "Nombre del sitio",
    "customerReport.chartData.sessions": "Sesiones",
    "customerReport.chartData.users": "Usuarios",
    "customerReport.chartTitle.sessionsWeeklyTrend": "Tendencia semanal de sesiones",
    "customerReport.chartTitle.newUsersWeeklyTrend": "Tendencia semanal de nuevos usuarios",
    "customerReport.chartTitle.usersAndSessionsBySite": "Usuarios y sesiones por sitio",
    "customerReport.chartTitle.providerSummary": "Resumen del proveedor",
    "customerReport.sessionsWeeklyTrend.xAxis": "Primer día de la semana",
    "customerReport.newUsersWeeklyTrend.xAxis": "Primer día de la semana",
    "customerReport.usersAndSessionsBySite.xAxis": "Sitios",
    "customerReport.providerSummary.xAxis": "Primer día de la semana",
    "customerReport.sessionsWeeklyTrend.yAxis": "Sesiones",
    "customerReport.newUsersWeeklyTrend.yAxis": "Usuarios",
    "customerReport.usersAndSessionsBySite.yAxis": "Recuento",
    "customerReport.providerSummary.yAxis": "Sesiones",
    "siteReport.sessionsWeeklyTrend.xAxis": "Primer día de la semana",
    "siteReport.newUsersWeeklyTrend.xAxis": "Primer día de la semana",
    "siteReport.sessionsWeeklyTrend.yAxis": "Sesiones",
    "siteReport.newUsersWeeklyTrend.yAxis": "Usuarios",
    "siteReport.overviewTitle.siteOverview": "Resumen del sitio",
    "tags.table.name": "Nombre",
    "tags.fieldName.name": "Nombre de la etiqueta",
    "tags.addModal.title": "Agregar etiqueta",
    "tags.editModal.title": "Editar etiqueta",
    "categories.table.name": "Nombre",
    "categories.fieldName.name": "Nombre de la categoría",
    "categories.addModal.title": "Agregar categoría",
    "categories.editModal.title": "Editar categoría",
    "scriptedActivities.table.name": "Nombre",
    "scriptedActivities.table.category": "Categoría",
    "scriptedActivities.table.tags": "Etiquetas",

    "unitScriptedActivities.table.name": "Nombre",
    "unitScriptedActivities.table.isDeprecated": "Obsoleto",
    "unitScriptedActivities.table.visible": "Visible",
    "scriptedActivities.edit.title": "Editar Actividad Guiada",
    "scriptedActivities.edit.information": "Información",
    "scriptedActivities.edit.units": "Unidades",
    "scriptedActivities.edit.steps": "Pasos",
    "scriptedActivity.addModal.title": "Agregar Actividad Guiada",
    "scriptedActivity.fieldName.name": "Nombre",
    "scriptedActivity.fieldName.category": "Categoría",
    "scriptedActivity.fieldName.tags": "Etiquetas",
    "scriptedActivity.fieldName.summary": "Resumen",
    "scriptedActivitiesSteps.table.order": "Orden",
    "scriptedActivitiesSteps.table.name": "Nombre",
    "scriptedActivitiesSteps.table.baseActivity": "Actividad Base",
    "activitySteps.addModal.stepInformation": "Información del Paso",
    "activitySteps.addModal.settings": "Configuraciones",
    "activitySteps.addModal.title": "Agregar Paso",
    "activitySteps.fieldName.name": "Nombre",
    "activitySteps.fieldName.order": "Orden",
    "scriptedActivity.fieldError.existingOrderNumber": "El número de orden ya está en uso",
    "activitySteps.fieldName.isSkipped": "Omitir",
    "activitySteps.fieldName.activity": "Actividad",
    "activitySteps.fieldName.instructions": "Instrucciones",
    "activitySteps.editModal.stepInformation": "Información del Paso",
    "activitySteps.editModal.settings": "Configuraciones",
    "activitySteps.editModal.title": "Editar Paso",
    "scriptedActivityStep.deleteModal.title": "Eliminar Paso",
    "scriptedActivityStep.deleteModal.description": "¿Estás seguro de que deseas eliminar este paso? Este proceso no se puede deshacer.",
    "userReport.section.userInformation": "Resumen del Usuario",
    "userReport.userInfo.name": "Nombre",
    "userReport.userInfo.totalTime": "Tiempo total en TRAZER",
    "userReport.userInfo.totalDistance": "Distancia total recorrida",
    "userReport.userInfo.totalCalories": "Calorías totales quemadas",
    "userReport.userInfo.totalTargets": "Objetivos totales alcanzados",
    "userReport.controls.dateRange": "Rango de fechas:",
    "userReport.controls.unitType": "Unidades:",
    "userReport.chartExportDataField.date": "Fecha",
    "userReport.chartData.distanceFeet": "Distancia recorrida (pies)",
    "userReport.chartData.distanceMeters": "Distancia recorrida (metros)",
    "userReport.chartData.sessions": "Sesiones",
    "userReport.chartData.calories": "Calorías quemadas",
    "userReport.chartData.targets": "Objetivos alcanzados",
    "userReport.chartTitle.distance": "Tendencia semanal de distancia recorrida",
    "userReport.chartTitle.sessions": "Tendencia semanal de sesiones",
    "userReport.chartTitle.calories": "Tendencia semanal de calorías quemadas",
    "userReport.chartTitle.targets": "Tendencia semanal de objetivos alcanzados",
    "userReport.distance.xAxis": "Primer día de la semana",
    "userReport.sessions.xAxis": "Primer día de la semana",
    "userReport.calories.xAxis": "Primer día de la semana",
    "userReport.targets.xAxis": "Primer día de la semana",
    "userReport.distance.yAxis": "Distancia ({0})",
    "userReport.sessions.yAxis": "Sesiones",
    "userReport.calories.yAxis": "Calorías",
    "userReport.targets.yAxis": "Objetivos",
    "userReport.section.weekly": "Semanal",
    "userReport.section.history": "Historial",
    "userReport.section.stats": "Estadísticas",
    "baseActivities.addModal.title": "Agregar Actividad Base",
    "baseActivities.editModal.title": "Editar Actividad Base",
    "baseActivities.fieldName.name": "Nombre",
    "baseActivities.fieldName.type": "Tipo",
    "userGroups.table.group": "Grupo",
    "userGroups.table.customer": "Cliente",
    "userGroups.table.site": "Sitio",
    "userGroups.editModal.title": "Cambiar Nombre",
    "userGroups.fieldName.newName": "Nuevo Nombre",
    "testReport.testNamePattern": "Prueba #{0}",
    "testReport.testNameBaseline": "Línea de base",
    "testReport.testNameDifference": "Diferencia",
    "testReport.kinematicRepetitionNamePattern": "Rep #{0}",
    "testReport.mobilityRepetitionNamePattern": "Rep #{0}",

    "testReport.cognitiveType": "Cognitivo",
    "testReport.testInfo.testNumber": "Número de Prueba",
    "testReport.testInfo.username": "Nombre de Usuario",
    "testReport.testInfo.name": "Nombre",
    "testReport.testInfo.medicalRecordNumber": "Número de Historia Clínica",
    "testReport.testInfo.provider": "Proveedor",
    "testReport.testInfo.date": "Fecha",
    "testReport.testInfo.duration": "Duración",
    "testReport.testInfo.location": "Ubicación",
    "testReport.testInfo.status": "Estado",
    "testReport.testInfo.scriptedActivity": "Actividad Guiada",
    "testReport.testNotes.createdBy": "Creado Por",
    "testReport.testNotes.modifiedBy": "Última Actualización Por",
    "testReport.testNotes.dateModified": "Última Actualización En",
    "testReport.compareTests.title": "Comparar Pruebas",
    "testReport.compareTestsTable.baseline": "Línea de Base",
    "testReport.compareTestsTable.date": "Fecha",
    "testReport.compareTestsTable.status": "Estado",
    "testReport.compareSteps.title": "Comparar Pasos",
    "testReport.compareStepsTable.name": "Nombre del Paso",
    "testReport.compareStepsTable.order": "Orden",
    "testReport.actions.previousTest": "Prueba Anterior",
    "testReport.actions.nextTest": "Prueba Siguiente",
    "testReport.controls.step": "Paso",
    "testReport.controls.unitType": "Unidades",
    "testReport.section.stepSummary": "Resumen del Paso",
    "testReport.section.averages": "Promedios",
    "testReport.section.sway": "Balanceo",
    "testReport.section.misses": "Errores",
    "testReport.section.details": "Detalles",
    "testReport.section.timeline": "Línea de Tiempo",
    "testReport.section.history": "Historial",
    "testReport.section.testInformation": "Información de la Prueba",
    "testReport.section.testNotes": "Notas de la Prueba",
    "testReport.section.kinematicAverages": "Promedios Cinemáticos",
    "testReport.section.kinematicReps": "Repeticiones Cinemáticas",
    "testReport.section.mobilityReps": "Repeticiones de Movilidad",
    "testReport.section.upperExtremityAverages": "Promedios de Extremidades Superiores",
    "testReport.section.neurocognitive": "Neurocognitivo",
    "testReport.section.totalDistance": "Distancia Total",
    "testReport.section.decelerationIndex": "Índice de Desaceleración",
    "testReport.section.reactionTime": "Tiempo de Reacción",
    "testReport.section.speed": "Velocidad",
    "testReport.section.acceleration": "Aceleración",
    "testReport.section.deceleration": "Desaceleración",
    "testReport.section.heartRate": "Frecuencia Cardíaca",
    "testReport.section.totalTargets": "Objetivos Totales",
    "testReport.section.neck": "Cuello",
    "testReport.section.spine": "Columna Vertebral",
    "testReport.section.shoulderFlexion": "Flexión del Hombro",
    "testReport.section.shoulderAbduction": "Abducción del Hombro",
    "testReport.section.elbowFlexion": "Flexión del Codo",
    "testReport.section.torso": "Tórax",
    "testReport.section.squatDepth": "Profundidad de Sentadilla",
    "testReport.section.jumpHeight": "Altura de Salto",
    "testReport.section.kneeFlexion": "Flexión de Rodilla",
    "testReport.section.kneeFlexionValgusOrVarus": "Valgo/Varo de Rodilla",
    "testReport.section.dorsiflexion": "Dorsiflexión",
    "testReport.section.timeline.xAxis": "Tiempo Transcurrido (s)",
    "testReport.section.history.xAxis": "Fecha",
    "testReport.section.timeline.reactionTime.yAxis": "Tiempo de Reacción (s)",
    "testReport.section.timeline.speed.yAxis": "Velocidad ({0})",
    "testReport.section.timeline.acceleration.yAxis": "Aceleración ({0})",
    "testReport.section.timeline.deceleration.yAxis": "Desaceleración ({0})",
    "testReport.section.timeline.heartRate.yAxis": "Frecuencia Cardíaca",
    "testReport.section.history.totalTargets.yAxis": "Objetivos Totales",
    "testReport.section.history.sway.yAxis": "Balanceo Total ({0})",
    "testReport.section.history.misses.yAxis": "Errores",
    "testReport.section.history.totalDistance.yAxis": "Distancia Total ({0})",
    "testReport.section.history.reactionTime.yAxis": "Tiempo de Reacción (s)",
    "testReport.section.history.speed.yAxis": "Velocidad ({0})",
    "testReport.section.history.acceleration.yAxis": "Aceleración ({0})",
    "testReport.section.history.deceleration.yAxis": "Desaceleración ({0})",

    "testReport.section.history.heartRate.yAxis": "Frecuencia Cardíaca (ppm)",
    "testReport.section.history.neck.yAxis": "Cuello (Grados \u00B0)",
    "testReport.section.history.spine.yAxis": "Columna Vertebral (Grados \u00B0)",
    "testReport.section.history.shoulderFlexion.yAxis": "Flexión del Hombro (Grados \u00B0)",
    "testReport.section.history.shoulderAbduction.yAxis": "Abducción del Hombro (Grados \u00B0)",
    "testReport.section.history.elbowFlexion.yAxis": "Flexión del Codo (Grados \u00B0)",
    "testReport.section.history.torso.yAxis": "Tórax (Grados \u00B0)",
    "testReport.section.history.squatDepth.yAxis": "Profundidad de Sentadilla ({0})",
    "testReport.section.history.jumpHeight.yAxis": "Altura de Salto ({0})",
    "testReport.section.history.kneeFlexion.yAxis": "Flexión de Rodilla (Grados \u00B0)",
    "testReport.section.history.kneeFlexionValgusOrVarus.yAxis": "Valgo/Varo de Rodilla (Grados \u00B0)",
    "testReport.section.history.dorsiflexion.yAxis": "Dorsiflexión (Grados \u00B0)",
    "testReport.fieldName.start": "Inicio",
    "testReport.fieldName.min": "Mínimo",
    "testReport.fieldName.average": "Promedio",
    "testReport.fieldName.max": "Máximo",
    "testReport.fieldName.end": "Fin",
    "testReport.fieldName.forward": "Adelante",
    "testReport.fieldName.forwardRight": "Adelante Derecha",
    "testReport.fieldName.right": "Derecha",
    "testReport.fieldName.backwardRight": "Atrás Derecha",
    "testReport.fieldName.backward": "Atrás",
    "testReport.fieldName.backwardLeft": "Atrás Izquierda",
    "testReport.fieldName.left": "Izquierda",
    "testReport.fieldName.forwardLeft": "Adelante Izquierda",
    "testReport.fieldName.lrDifference": "Diferencia L/D",
    "testReport.fieldName.flFRDifference": "Diferencia FL/FR",
    "testReport.fieldName.blBRDifference": "Diferencia BL/BR",
    "testReport.fieldName.totalSway": "Balanceo Total",
    "testReport.fieldName.duration": "Duración",
    "testReport.fieldName.targets": "Objetivos",
    "testReport.fieldName.score": "Puntuación",
    "testReport.fieldName.totalDistance": "Distancia Total",
    "testReport.fieldName.decelerationIndex": "Índice de Desaceleración",
    "testReport.fieldName.calories": "Calorías",
    "testReport.fieldName.averageHR": "Frec. Card. Prom.",
    "testReport.fieldName.maxHR": "Frec. Card. Máx.",
    "testReport.fieldName.averageJumpHeight": "Altura de Salto Prom.",
    "testReport.fieldName.maxJumpHeight": "Altura de Salto Máx.",
    "testReport.fieldName.averageSquatDepth": "Prof. de Sentadilla Prom.",
    "testReport.fieldName.maxSquatDepth": "Prof. de Sentadilla Máx.",
    "testReport.fieldName.correct": "Correcto",
    "testReport.fieldName.incorrect": "Incorrecto",
    "testReport.fieldName.total": "Total",
    "testReport.fieldName.stanceWidthRatio": "Relación de Ancho de Postura",
    "testReport.fieldName.plantFoot": "Pie de Apoyo",
    "testReport.fieldName.stanceWidthDistance": "Distancia de Ancho de Postura",
    "testReport.fieldName.abduction": "Abducción del Hombro",
    "testReport.fieldName.shoulderFlexion": "Flexión del Hombro",
    "testReport.fieldName.elbowFlexion": "Flexión del Codo",
    "testReport.fieldName.flexion": "Flexión",
    "testReport.fieldName.dorsiflexion": "Dorsiflexión",
    "testReport.fieldName.trunkLean": "Inclinación del Tronco",
    "testReport.fieldName.pelvicTilt": "Inclinación Pélvica",
    "testReport.fieldName.jumpHeight": "Altura de Salto",
    "testReport.fieldName.squatDepth": "Profundidad de Sentadilla",
    "testReport.fieldName.rightKneeValgusOrVarus": "Rodilla Derecha Valgo/Varo",
    "testReport.fieldName.rightKneeFlexion": "Flexión de Rodilla Derecha",
    "testReport.fieldName.rightAnkleDorsiflexion": "Dorsiflexión de Tobillo Derecho",
    "testReport.fieldName.rightDorsiflexion": "Dorsiflexión Derecha",
    "testReport.fieldName.leftKneeValgusOrVarus": "Rodilla Izquierda Valgo/Varo",
    "testReport.fieldName.leftKneeFlexion": "Flexión de Rodilla Izquierda",
    "testReport.fieldName.leftAnkleDorsiflexion": "Dorsiflexión de Tobillo Izquierdo",
    "testReport.fieldName.leftDorsiflexion": "Dorsiflexión Izquierda",
    "testReport.fieldName.neckRotation": "Rotación del Cuello",
    "testReport.fieldName.spineFlexion": "Flexión de Columna Vertebral",

    "testReport.fieldName.spineRotation": "Rotación de la Columna Vertebral",
    "testReport.fieldName.leftShoulderFlexion": "Flexión del Hombro Izquierdo",
    "testReport.fieldName.rightShoulderFlexion": "Flexión del Hombro Derecho",
    "testReport.fieldName.leftShoulderAbduction": "Abducción del Hombro Izquierdo",
    "testReport.fieldName.rightShoulderAbduction": "Abducción del Hombro Derecho",
    "testReport.fieldName.leftElbowFlexion": "Flexión del Codo Izquierdo",
    "testReport.fieldName.rightElbowFlexion": "Flexión del Codo Derecho",
    "testReport.fieldName.totalTargetsHit": "Total de Objetivos",
    "testReport.fieldName.targetsMissed": "Total de Objetivos Fallados",
    "testReport.fieldName.targetsHitLeft180": "Objetivos Izquierdos Superiores 180\u00B0",
    "testReport.fieldName.targetsHitLeft45": "Objetivos Izquierdos Laterales 45\u00B0",
    "testReport.fieldName.targetsHitLeft90": "Objetivos Izquierdos Laterales 90\u00B0",
    "testReport.fieldName.targetsHitLeft135": "Objetivos Izquierdos Laterales 135\u00B0",
    "testReport.fieldName.targetsHitForwardLeft45": "Objetivos Izquierdos Frontales 45\u00B0",
    "testReport.fieldName.targetsHitForwardLeft90": "Objetivos Izquierdos Frontales 90\u00B0",
    "testReport.fieldName.targetsHitForwardLeft135": "Objetivos Izquierdos Frontales 135\u00B0",
    "testReport.fieldName.targetsHitRight180": "Objetivos Derechos Superiores 180\u00B0",
    "testReport.fieldName.targetsHitRight45": "Objetivos Derechos Laterales 45\u00B0",
    "testReport.fieldName.targetsHitRight90": "Objetivos Derechos Laterales 90\u00B0",
    "testReport.fieldName.targetsHitRight135": "Objetivos Derechos Laterales 135\u00B0",
    "testReport.fieldName.targetsHitForwardRight45": "Objetivos Derechos Frontales 45\u00B0",
    "testReport.fieldName.targetsHitForwardRight90": "Objetivos Derechos Frontales 90\u00B0",
    "testReport.fieldName.targetsHitForwardRight135": "Objetivos Derechos Frontales 135\u00B0",
    "testReport.fieldName.lrTargetsHitDiff": "Diferencia de Objetivos Izq./Der.",
    "testReport.fieldName.reactionTimeAvg": "Tiempo de Reacción Promedio",
    "testReport.fieldName.reactionTimeLeft": "Tiempo de Reacción Izquierdo",
    "testReport.fieldName.reactionTimeRight": "Tiempo de Reacción Derecho",
    "testReport.fieldName.lrReactionTimeDiff": "Diferencia de Tiempo de Reacción Izq./Der.",
    "testReport.fieldName.leftCongruent": "Izquierdo Congruente",
    "testReport.fieldName.rightCongruent": "Derecho Congruente",
    "testReport.fieldName.leftIncongruent": "Izquierdo Incongruente",
    "testReport.fieldName.rightIncongruent": "Derecho Incongruente",
    "testReport.fieldName.totalTargets": "Total de Objetivos",
    "testReport.fieldName.misses": "Errores",
    "testReport.fieldName.averageReactionTime": "Tiempo Promedio de Reacción",
    "testReport.fieldName.avgReactionTime": "Tiempo Promedio de Reacción",
    "testReport.fieldName.averageAcceleration": "Aceleración Promedio",
    "testReport.fieldName.averageDeceleration": "Desaceleración Promedio",
    "testReport.fieldName.averageSpeed": "Velocidad Promedio",
    "testReport.fieldName.averageHeartRate": "Frecuencia Cardíaca Promedio",
    "testReport.fieldName.totalDistanceAvg": "Distancia Total Promedio",
    "testReport.fieldName.maxAcceleration": "Aceleración Máxima",
    "testReport.fieldName.maxDeceleration": "Desaceleración Máxima",
    "testReport.fieldName.maxSpeed": "Velocidad Máxima",
    "testReport.fieldName.minReactionTime": "Tiempo Mínimo de Reacción",
    "testReport.fieldName.minSquatDepth": "Profundidad Mínima de Sentadilla",
    "testReport.fieldName.minDistance": "Distancia Mínima",
    "testReport.fieldName.startingHR": "Frecuencia Cardíaca Inicial",
    "testReport.averages.reactionTime": "Tiempo de Reacción",
    "testReport.averages.speed": "Velocidad",
    "testReport.averages.acceleration": "Aceleración",
    "testReport.averages.deceleration": "Desaceleración",
    "testReport.neurocognitive.promptTime": "Tiempo de Indicación",
    "testReport.neurocognitive.scores": "Puntuaciones",
    "testReport.neurocognitive.averageTimeToTarget": "Tiempo Promedio hasta el Objetivo",
    "testReport.kinematic.left": "Izquierdo",
    "testReport.kinematic.right": "Derecho",
    "testReport.print.stepDisclaimer": "*Los tiempos de reacción en esta actividad son solo para fines de comparación.",
    "testReport.print.selectOptions": "Seleccione Secciones para Imprimir",

    "testReport.print.testInfo": "Información del Test",
    "testReport.print.testSummary": "Resumen del Test",
    "testReport.print.testDetails": "Detalles del Test",
    "testReport.print.testTimeline": "Línea de Tiempo",
    "testReport.print.testHistory": "Historial",
    "testReport.print.testNotes": "Notas del Test",
    "testReport.print.errorMessage": "¡Por favor, seleccione al menos una opción!",
    "testReport.chartTooltip.test": "Test",
    "library.noMatchingActivities": "No se encontraron actividades coincidentes",
    "analytics.filters.customer": "Cliente",
    "analytics.filters.site": "Sitio",
    "analytics.filters.scriptedActivity": "Actividad Scriptada",
    "analytics.filters.dateRange": "Rango de Fechas",
    "analytics.filters.gender": "Género",
    "analytics.filters.rangeTemplate": "a",
    "analytics.filters.heightInches": "Altura (pulgadas)",
    "analytics.filters.weightLbs": "Peso (libras)",
    "analytics.filters.ageYears": "Edad (años)",
    "analytics.filters.userGroup": "Grupo de Usuario",
    "analytics.filters.sport": "Deporte",
    "analytics.filters.position": "Posición",
    "analytics.filters.injury": "Ubicación de la Lesión",
    "analytics.filters.injuryType": "Tipo de Lesión",
    "analytics.filters.dominance": "Dominancia",
    "analytics.filters.excludeSectionLabel": "Excluir",
    "analytics.filters.partials": "Parciales",
    "analytics.filters.testUsers": "Usuarios de Prueba",
    "analytics.subTabs.graphs": "Gráficos",
    "analytics.subTabs.table": "Tabla",
    "analyticsTestsTab.table.scriptedActivity": "Actividad Scriptada",
    "analyticsTestsTab.table.name": "Nombre",
    "analyticsTestsTab.table.username": "Nombre de Usuario",
    "analyticsTestsTab.table.date": "Fecha",
    "analyticsTestsTab.table.status": "Estado",
    "analyticsTestsTab.controls.unitType": "Unidades",
    "analyticsSearchResultLayout.tabs.reactionTime": "Tiempo de Reacción",
    "analyticsSearchResultLayout.tabs.speed": "Velocidad",
    "analyticsSearchResultLayout.tabs.tests": "Tests",
    "analyticsSearchResultLayout.tabs.acceleration": "Aceleración",
    "analyticsSearchResultLayout.tabs.deceleration": "Desaceleración",
    "analyticsSearchResultLayout.tabs.totalDistance": "Distancia Total",
    "analyticsSearchResultLayout.tabs.calories": "Calorías",
    "analyticsSearchResultLayout.tabs.cognitive": "Cognitivo",
    "analyticsSearchResultLayout.tabs.sways": "Balanceo",
    "analyticsSearchResultLayout.tabs.misses": "Errores",
    "analyticsSearchResultLayout.tabs.kinematics": "Cinemática",
    "analytics.table.fullName": "Nombre Completo",
    "analytics.table.username": "Nombre de Usuario",
    "analytics.table.scriptedActivity": "Actividad Scriptada",
    "analytics.table.avg": "Promedio",
    "analytics.table.forward": "F",
    "analytics.table.forwardRight": "F/D",
    "analytics.table.right": "D",
    "analytics.table.backwardRight": "D/I",
    "analytics.table.backward": "I",
    "analytics.table.backwardLeft": "I/I",
    "analytics.table.left": "I",
    "analytics.table.forwardLeft": "F/I",
    "analytics.table.lrDifference": "Diferencia I/D",
    "analytics.table.flFRDifference": "Diferencia II/DD",
    "analytics.table.blBRDifference": "Diferencia II/DI",
    "analytics.table.date": "Fecha",
    "analytics.table.totalDistance": "Distancia Total",
    "analytics.table.calories": "Calorías",
    "analytics.table.promptTime": "Tiempo de Indicación",
    "analytics.table.congruentCorrectLeft": "Congruente Correcto Izquierdo",
    "analytics.table.averageTimeCongruentCorrectLeft": "Tiempo Promedio Congruente Correcto Izquierdo",
    "analytics.table.congruentIncorrectLeft": "Congruente Incorrecto Izquierdo",
    "analytics.table.averageTimeCongruentIncorrectLeft": "Tiempo Promedio Congruente Incorrecto Izquierdo",
    "analytics.table.incongruentCorrectLeft": "Incongruente Correcto Izquierdo",
    "analytics.table.averageTimeIncongruentCorrectLeft": "Tiempo Promedio Incongruente Correcto Izquierdo",
    "analytics.table.incongruentIncorrectLeft": "Incongruente Incorrecto Izquierdo",
    "analytics.table.averageTimeIncongruentIncorrectLeft": "Tiempo Promedio Incongruente Incorrecto Izquierdo",

    "analytics.table.congruentCorrectRight": "Correcto Congruente Derecho",
    "analytics.table.averageTimeCongruentCorrectRight": "Tiempo Promedio Correcto Congruente Derecho",
    "analytics.table.congruentIncorrectRight": "Incorrecto Congruente Derecho",
    "analytics.table.averageTimeCongruentIncorrectRight": "Tiempo Promedio Incorrecto Congruente Derecho",
    "analytics.table.incongruentCorrectRight": "Correcto Incongruente Derecho",
    "analytics.table.averageTimeIncongruentCorrectRight": "Tiempo Promedio Correcto Incongruente Derecho",
    "analytics.table.incongruentIncorrectRight": "Incorrecto Incongruente Derecho",
    "analytics.table.averageTimeIncongruentIncorrectRight": "Tiempo Promedio Incorrecto Incongruente Derecho",
    "analytics.table.totalSway": "Balanceo Total",
    "analytics.table.totalMisses": "Errores Totales",
    "analytics.table.plantFoot": "Pie de Apoyo",
    "analytics.table.stanceWidthRatio": "Ratio de Ancho de Postura",
    "analytics.table.stanceWidthDistance": "Distancia de Ancho de Postura",
    "analytics.table.trunkLean": "Inclinación del Tronco",
    "analytics.table.pelvicTilt": "Inclinación Pélvica",
    "analytics.table.rightKneeValgusVarus": "Valgo/Varo de Rodilla Derecha",
    "analytics.table.rightKneeFlexion": "Flexión de Rodilla Derecha",
    "analytics.table.rightAnkleDorsiflexion": "Dorsiflexión de Tobillo Derecho",
    "analytics.table.leftKneeValgusVarus": "Valgo/Varo de Rodilla Izquierda",
    "analytics.table.leftKneeFlexion": "Flexión de Rodilla Izquierda",
    "analytics.table.leftAnkleDorsiflexion": "Dorsiflexión de Tobillo Izquierdo",
    "analytics.table.neckRotation": "Rotación de Cuello",
    "analytics.table.spineFlexion": "Flexión de Espina Dorsal",
    "analytics.table.spineRotation": "Rotación de Espina Dorsal",
    "analytics.table.leftShoulderFlexion": "Flexión de Hombro Izquierdo",
    "analytics.table.rightShoulderFlexion": "Flexión de Hombro Derecho",
    "analytics.table.leftShoulderAbduction": "Abducción de Hombro Izquierdo",
    "analytics.table.rightShoulderAbduction": "Abducción de Hombro Derecho",
    "analytics.table.leftElbowFlexion": "Flexión de Codo Izquierdo",
    "analytics.table.rightElbowFlexion": "Flexión de Codo Derecho",
    "analytics.export.testStartTime": "Fecha de Inicio del Test",
    "analytics.export.fullName": "Nombre Completo",
    "analytics.export.username": "Nombre de Usuario",
    "analytics.export.scriptedActivity": "Actividad Scriptada",
    "analytics.export.testStepDate": "Fecha del Paso del Test",
    "analytics.export.scriptedActivityStep": "Paso de Actividad Scriptada",
    "analytics.export.stepNumber": "Número de Paso",
    "analytics.export.status": "Estado",
    "analytics.export.duration": "Duración",
    "analytics.export.targetsHit": "Objetivos Alcanzados",
    "analytics.export.reactionTimeAverage": "Tiempo Promedio de Reacción (s)",
    "analytics.export.reactionTimeForward": "Tiempo de Reacción Hacia Adelante (s)",
    "analytics.export.reactionTimeForwardRight": "Tiempo de Reacción Hacia Adelante Derecha (s)",
    "analytics.export.reactionTimeRight": "Tiempo de Reacción Derecha (s)",
    "analytics.export.reactionTimeBackwardRight": "Tiempo de Reacción Hacia Atrás Derecha (s)",
    "analytics.export.reactionTimeBackward": "Tiempo de Reacción Hacia Atrás (s)",
    "analytics.export.reactionTimeBackwardLeft": "Tiempo de Reacción Hacia Atrás Izquierda (s)",
    "analytics.export.reactionTimeLeft": "Tiempo de Reacción Izquierda (s)",
    "analytics.export.reactionTimeForwardLeft": "Tiempo de Reacción Hacia Adelante Izquierda (s)",
    "analytics.export.reactionTimeLRDifference": "Diferencia de Tiempo de Reacción Izquierda/Derecha",
    "analytics.export.reactionTimeFLFRDifference": "Diferencia de Tiempo de Reacción Adelante Derecha/Adelante Izquierda",
    "analytics.export.reactionTimeBLBRDifference": "Diferencia de Tiempo de Reacción Atrás Derecha/Atrás Izquierda",
    "analytics.export.speedAverage": "Velocidad Promedio ({0})",
    "analytics.export.speedForward": "Velocidad Hacia Adelante ({0})",
    "analytics.export.speedForwardRight": "Velocidad Hacia Adelante Derecha ({0})",
    "analytics.export.speedRight": "Velocidad Derecha ({0})",
    "analytics.export.speedBackwardRight": "Velocidad Hacia Atrás Derecha ({0})",
    "analytics.export.speedBackward": "Velocidad Hacia Atrás ({0})",
    "analytics.export.speedBackwardLeft": "Velocidad Hacia Atrás Izquierda ({0})",
    "analytics.export.speedLeft": "Velocidad Izquierda ({0})",

    "analytics.export.speedForwardLeft": "Velocidad Hacia Adelante Izquierda ({0})",
    "analytics.export.speedLRDifference": "Diferencia de Velocidad Izquierda/Derecha",
    "analytics.export.speedFLFRDifference": "Diferencia de Velocidad Adelante Izquierda/Adelante Derecha",
    "analytics.export.speedBLBRDifference": "Diferencia de Velocidad Atrás Izquierda/Atrás Derecha",
    "analytics.export.accelerationAverage": "Aceleración Promedio ({0})",
    "analytics.export.accelerationForward": "Aceleración Hacia Adelante ({0})",
    "analytics.export.accelerationForwardRight": "Aceleración Hacia Adelante Derecha ({0})",
    "analytics.export.accelerationRight": "Aceleración Derecha ({0})",
    "analytics.export.accelerationBackwardRight": "Aceleración Hacia Atrás Derecha ({0})",
    "analytics.export.accelerationBackward": "Aceleración Hacia Atrás ({0})",
    "analytics.export.accelerationBackwardLeft": "Aceleración Hacia Atrás Izquierda ({0})",
    "analytics.export.accelerationLeft": "Aceleración Izquierda ({0})",
    "analytics.export.accelerationForwardLeft": "Aceleración Hacia Adelante Izquierda ({0})",
    "analytics.export.accelerationLRDifference": "Diferencia de Aceleración Izquierda/Derecha",
    "analytics.export.accelerationFLFRDifference": "Diferencia de Aceleración Adelante Izquierda/Adelante Derecha",
    "analytics.export.accelerationBLBRDifference": "Diferencia de Aceleración Atrás Izquierda/Atrás Derecha",
    "analytics.export.decelerationAverage": "Desaceleración Promedio ({0})",
    "analytics.export.decelerationForward": "Desaceleración Hacia Adelante ({0})",
    "analytics.export.decelerationForwardRight": "Desaceleración Hacia Adelante Derecha ({0})",
    "analytics.export.decelerationRight": "Desaceleración Derecha ({0})",
    "analytics.export.decelerationBackwardRight": "Desaceleración Hacia Atrás Derecha ({0})",
    "analytics.export.decelerationBackward": "Desaceleración Hacia Atrás ({0})",
    "analytics.export.decelerationBackwardLeft": "Desaceleración Hacia Atrás Izquierda ({0})",
    "analytics.export.decelerationLeft": "Desaceleración Izquierda ({0})",
    "analytics.export.decelerationForwardLeft": "Desaceleración Hacia Adelante Izquierda ({0})",
    "analytics.export.decelerationLRDifference": "Diferencia de Desaceleración Izquierda/Derecha",
    "analytics.export.decelerationFLFRDifference": "Diferencia de Desaceleración Adelante Izquierda/Adelante Derecha",
    "analytics.export.decelerationBLBRDifference": "Diferencia de Desaceleración Atrás Izquierda/Atrás Derecha",
    "analytics.export.totalDistance": "Distancia Total ({0})",
    "analytics.export.calories": "Calorías",
    "analytics.export.promptTime": "Tiempo de Indicación (ms)",
    "analytics.export.congruentCorrectLeft": "Correcto Congruente Izquierdo",
    "analytics.export.averageTimeCongruentCorrectLeft": "Tiempo Promedio Correcto Congruente Izquierdo (s)",
    "analytics.export.congruentIncorrectLeft": "Incorrecto Congruente Izquierdo",
    "analytics.export.averageTimeCongruentIncorrectLeft": "Tiempo Promedio Incorrecto Congruente Izquierdo (s)",
    "analytics.export.incongruentCorrectLeft": "Correcto Incongruente Izquierdo",
    "analytics.export.averageTimeIncongruentCorrectLeft": "Tiempo Promedio Correcto Incongruente Izquierdo (s)",
    "analytics.export.incongruentIncorrectLeft": "Incorrecto Incongruente Izquierdo",
    "analytics.export.averageTimeIncongruentIncorrectLeft": "Tiempo Promedio Incorrecto Incongruente Izquierdo (s)",
    "analytics.export.congruentCorrectRight": "Correcto Congruente Derecho",
    "analytics.export.averageTimeCongruentCorrectRight": "Tiempo Promedio Correcto Congruente Derecho (s)",
    "analytics.export.congruentIncorrectRight": "Incorrecto Congruente Derecho",
    "analytics.export.averageTimeCongruentIncorrectRight": "Tiempo Promedio Incorrecto Congruente Derecho (s)",
    "analytics.export.incongruentCorrectRight": "Correcto Incongruente Derecho",
    "analytics.export.averageTimeIncongruentCorrectRight": "Tiempo Promedio Correcto Incongruente Derecho (s)",
    "analytics.export.incongruentIncorrectRight": "Incorrecto Incongruente Derecho",
    "analytics.export.averageTimeIncongruentIncorrectRight": "Tiempo Promedio Incorrecto Incongruente Derecho (s)",
    "analytics.export.totalSway": "Balanceo Total ({0})",
    "analytics.export.swayForward": "Balanceo Hacia Adelante ({0})",
    "analytics.export.swayForwardRight": "Balanceo Hacia Adelante Derecha ({0})",

    "analytics.export.swayRight": "Balanceo Derecho ({0})",
    "analytics.export.swayBackwardRight": "Balanceo Hacia Atrás Derecho ({0})",
    "analytics.export.swayBackward": "Balanceo Hacia Atrás ({0})",
    "analytics.export.swayBackwardLeft": "Balanceo Hacia Atrás Izquierda ({0})",
    "analytics.export.swayLeft": "Balanceo Izquierdo ({0})",
    "analytics.export.swayForwardLeft": "Balanceo Hacia Adelante Izquierda ({0})",
    "analytics.export.totalMisses": "Total de Errores",
    "analytics.export.neckRotation": "Rotación del Cuello",
    "analytics.export.spineFlexion": "Flexión de la Espina",
    "analytics.export.spineRotation": "Rotación de la Espina",
    "analytics.export.leftShoulderFlexion": "Flexión del Hombro Izquierdo",
    "analytics.export.rightShoulderFlexion": "Flexión del Hombro Derecho",
    "analytics.export.leftShoulderAbduction": "Abducción del Hombro Izquierdo",
    "analytics.export.rightShoulderAbduction": "Abducción del Hombro Derecho",
    "analytics.export.leftElbowFlexion": "Flexión del Codo Izquierdo",
    "analytics.export.rightElbowFlexion": "Flexión del Codo Derecho",
    "analytics.export.plantFoot": "Pie de Apoyo",
    "analytics.export.stanceWidthRatio": "Proporción del Ancho de la Postura",
    "analytics.export.stanceWidthDistance": "Distancia del Ancho de la Postura ({0})",
    "analytics.export.trunkLean": "Inclinación del Tronco",
    "analytics.export.pelvicTilt": "Inclinación Pélvica",
    "analytics.export.rightKneeValgusVarus": "Valgo/Varo de la Rodilla Derecha",
    "analytics.export.rightKneeFlexion": "Flexión de la Rodilla Derecha",
    "analytics.export.rightAnkleDorsiflexion": "Dorsiflexión del Tobillo Derecho",
    "analytics.export.leftKneeValgusVarus": "Valgo/Varo de la Rodilla Izquierda",
    "analytics.export.leftKneeFlexion": "Flexión de la Rodilla Izquierda",
    "analytics.export.leftAnkleDorsiflexion": "Dorsiflexión del Tobillo Izquierdo",
    "analytics.export.repNumber": "Número de Repeticiones",
    "analytics.export.reactionTimeAvg": "Tiempo de Reacción Promedio (s)",
    "analytics.export.reactionTimeMin": "Tiempo de Reacción Mínimo (s)",
    "analytics.export.speedAvg": "Velocidad Promedio ({0})",
    "analytics.export.speedMax": "Velocidad Máxima ({0})",
    "analytics.export.accelerationAvg": "Aceleración Promedio ({0})",
    "analytics.export.accelerationMax": "Aceleración Máxima ({0})",
    "analytics.export.decelerationAvg": "Desaceleración Promedio ({0})",
    "analytics.export.decelerationMax": "Desaceleración Máxima ({0})",
    "analytics.export.jumpHeightAvg": "Altura de Salto Promedio ({0})",
    "analytics.export.jumpHeightMax": "Altura de Salto Máxima ({0})",
    "analytics.export.squatHeightAvg": "Profundidad de Sentadilla Promedio ({0})",
    "analytics.export.squatHeightMin": "Profundidad de Sentadilla Mínima ({0})",
    "analytics.export.minimumDistance": "Distancia Mínima ({0})",
    "analytics.chartData.forward": "Adelante",
    "analytics.chartData.forwardRight": "Adelante Derecha",
    "analytics.chartData.right": "Derecha",
    "analytics.chartData.backwardRight": "Atrás Derecha",
    "analytics.chartData.backward": "Atrás",
    "analytics.chartData.backwardLeft": "Atrás Izquierda",
    "analytics.chartData.left": "Izquierda",
    "analytics.chartData.forwardLeft": "Adelante Izquierda",
    "analytics.chartExportDataField.date": "Fecha",
    "analytics.chartExportDataField.direction": "Dirección",
    "analytics.charts.reactionTimeTrend": "Tendencia del Tiempo de Reacción",
    "analytics.charts.reactionTimeDirectionalAverage": "Promedio Direccional del Tiempo de Reacción",
    "analytics.charts.reactionTimeDistribution": "Distribución del Tiempo de Reacción",
    "analytics.charts.speedTrend": "Tendencia de Velocidad",
    "analytics.charts.speedDirectionalAverage": "Promedio Direccional de Velocidad",
    "analytics.charts.speedDistribution": "Distribución de Velocidad",
    "analytics.charts.accelerationTrend": "Tendencia de Aceleración",
    "analytics.charts.accelerationDirectionalAverage": "Promedio Direccional de Aceleración",
    "analytics.charts.accelerationDistribution": "Distribución de Aceleración",
    "analytics.charts.decelerationTrend": "Tendencia de Desaceleración",
    "analytics.charts.decelerationDirectionalAverage": "Promedio Direccional de Desaceleración",
    "analytics.charts.decelerationDistribution": "Distribución de Desaceleración",

    "analytics.charts.totalDistanceTrend": "Tendencia de Distancia Total",
    "analytics.charts.totalDistanceDistribution": "Distribución de Distancia Total",
    "analytics.charts.caloriesTrend": "Tendencia de Calorías",
    "analytics.charts.caloriesDistribution": "Distribución de Calorías",
    "analytics.charts.cognitiveTrend": "Tendencia Cognitiva",
    "analytics.charts.cognitiveDistribution": "Distribución Cognitiva",
    "analytics.charts.swayTrend": "Tendencia de Balanceo",
    "analytics.charts.swayDirectionalAverage": "Promedio Direccional de Balanceo",
    "analytics.charts.swayDistribution": "Distribución de Balanceo",
    "analytics.charts.missesTrend": "Tendencia de Errores",
    "analytics.charts.missesDistribution": "Distribución de Errores",
    "analytics.charts.kinematicsNeckRotationDistribution": "Distribución de Rotación del Cuello",
    "analytics.charts.kinematicsNeckRotationTrend": "Tendencia de Rotación del Cuello",
    "analytics.charts.kinematicsSpineFlexionDistribution": "Distribución de Flexión de la Espina",
    "analytics.charts.kinematicsSpineFlexionTrend": "Tendencia de Flexión de la Espina",
    "analytics.charts.kinematicsSpineRotationDistribution": "Distribución de Rotación de la Espina",
    "analytics.charts.kinematicsSpineRotationTrend": "Tendencia de Rotación de la Espina",
    "analytics.charts.kinematicsLeftShoulderFlexionDistribution": "Distribución de Flexión del Hombro Izquierdo",
    "analytics.charts.kinematicsLeftShoulderFlexionTrend": "Tendencia de Flexión del Hombro Izquierdo",
    "analytics.charts.kinematicsRightShoulderFlexionDistribution": "Distribución de Flexión del Hombro Derecho",
    "analytics.charts.kinematicsRightShoulderFlexionTrend": "Tendencia de Flexión del Hombro Derecho",
    "analytics.charts.kinematicsLeftShoulderAbductionDistribution": "Distribución de Abducción del Hombro Izquierdo",
    "analytics.charts.kinematicsLeftShoulderAbductionTrend": "Tendencia de Abducción del Hombro Izquierdo",
    "analytics.charts.kinematicsRightShoulderAbductionDistribution": "Distribución de Abducción del Hombro Derecho",
    "analytics.charts.kinematicsRightShoulderAbductionTrend": "Tendencia de Abducción del Hombro Derecho",
    "analytics.charts.kinematicsLeftElbowFlexionDistribution": "Distribución de Flexión del Codo Izquierdo",
    "analytics.charts.kinematicsLeftElbowFlexionTrend": "Tendencia de Flexión del Codo Izquierdo",
    "analytics.charts.kinematicsRightElbowFlexionDistribution": "Distribución de Flexión del Codo Derecho",
    "analytics.charts.kinematicsRightElbowFlexionTrend": "Tendencia de Flexión del Codo Derecho",
    "analytics.charts.kinematicsTrunkLeanDistribution": "Distribución de Inclinación del Tronco",
    "analytics.charts.kinematicsTrunkLeanTrend": "Tendencia de Inclinación del Tronco",
    "analytics.charts.kinematicsPelvicTiltDistribution": "Distribución de Inclinación Pélvica",
    "analytics.charts.kinematicsPelvicTiltTrend": "Tendencia de Inclinación Pélvica",
    "analytics.charts.kinematicsLeftKneeValgusVarusDistribution": "Distribución de Valgo/Varo de la Rodilla Izquierda",
    "analytics.charts.kinematicsLeftKneeValgusVarusTrend": "Tendencia de Valgo/Varo de la Rodilla Izquierda",
    "analytics.charts.kinematicsRightKneeValgusVarusDistribution": "Distribución de Valgo/Varo de la Rodilla Derecha",
    "analytics.charts.kinematicsRightKneeValgusVarusTrend": "Tendencia de Valgo/Varo de la Rodilla Derecha",
    "analytics.charts.kinematicsLeftKneeFlexionDistribution": "Distribución de Flexión de la Rodilla Izquierda",
    "analytics.charts.kinematicsLeftKneeFlexionTrend": "Tendencia de Flexión de la Rodilla Izquierda",
    "analytics.charts.kinematicsRightKneeFlexionDistribution": "Distribución de Flexión de la Rodilla Derecha",
    "analytics.charts.kinematicsRightKneeFlexionTrend": "Tendencia de Flexión de la Rodilla Derecha",
    "analytics.charts.kinematicsLeftAnkleDorsiflexionDistribution": "Distribución de Dorsiflexión del Tobillo Izquierdo",
    "analytics.charts.kinematicsLeftAnkleDorsiflexionTrend": "Tendencia de Dorsiflexión del Tobillo Izquierdo",
    "analytics.charts.kinematicsRightAnkleDorsiflexionDistribution": "Distribución de Dorsiflexión del Tobillo Derecho",
    "analytics.charts.kinematicsRightAnkleDorsiflexionTrend": "Tendencia de Dorsiflexión del Tobillo Derecho",
    "analytics.charts.reactionTimeTrend.yAxis": "Promedio de Tiempo de Reacción (s)",
    "analytics.charts.reactionTimeDistribution.xAxis": "Promedio de Tiempo de Reacción (s)",

    "analytics.charts.speedTrend.yAxis": "Velocidad Promedio ({0})",
    "analytics.charts.speedDistribution.xAxis": "Velocidad Promedio ({0})",
    "analytics.charts.accelerationTrend.yAxis": "Aceleración Promedio ({0})",
    "analytics.charts.accelerationDistribution.xAxis": "Aceleración Promedio ({0})",
    "analytics.charts.decelerationTrend.yAxis": "Desaceleración Promedio ({0})",
    "analytics.charts.decelerationDistribution.xAxis": "Desaceleración Promedio ({0})",
    "analytics.charts.totalDistanceTrend.yAxis": "Distancia ({0})",
    "analytics.charts.totalDistanceDistribution.xAxis": "Distancia ({0})",
    "analytics.charts.caloriesTrend.yAxis": "Calorías",
    "analytics.charts.caloriesDistribution.xAxis": "Calorías",
    "analytics.charts.cognitiveTrend.yAxis": "Correcto %",
    "analytics.charts.cognitiveDistribution.xAxis": "Correcto %",
    "analytics.charts.swayTrend.yAxis": "Balanceo ({0})",
    "analytics.charts.swayDistribution.xAxis": "Balanceo ({0})",
    "analytics.charts.missesTrend.yAxis": "Errores",
    "analytics.charts.missesDistribution.xAxis": "Errores",
    "analytics.charts.xAxis": "Fecha",
    "overview.section.monthlySessionsTrend": "Tendencia Mensual de Sesiones",
    "overview.section.sessions": "Sesiones",
    "overview.section.monthlyUsersTrend": "Tendencia Mensual de Nuevos Usuarios",
    "overview.section.users": "Usuarios",
    "overview.section.units": "Unidades",
    "overview.section.date": "Mes-Año",
    "overview.section.recentlyAddedUsers": "Usuarios Agregados Recientemente",
    "overview.section.recentSessions": "Sesiones Recientes",
    "overview.chartExportDataField.date": "Fecha",
    "footer.privacyPolicy": "Política de Privacidad",
    "footer.protectedBy": "Protegido por las Patentes de EE. UU. N.º",
    "footer.TrazerInc": "© {0} - Trazer Inc. Todos los derechos reservados.",
    "privacyPolicy.title": "Política de Privacidad del Portal TRAZER",
    "privacyPolicy.lastUpdated": "Última Actualización",
    "privacyPolicy.section1": "Introducción",
    "privacyPolicy.intro": "Esta política describe cómo Trazer Inc. recopila, utiliza, almacena y protege los datos dentro del Portal TRAZER. Nos comprometemos a salvaguardar la privacidad de nuestros Clientes y la privacidad de sus Usuarios (atletas, clientes, pacientes, residentes y/o cualquier individuo a cargo de nuestros clientes) y garantizar la seguridad de los datos. Al utilizar nuestros servicios e interactuar con nuestras bases de datos, acepta las prácticas descritas en esta política.",
    "privacyPolicy.section2": "Recopilación de Datos",
    "privacyPolicy.section2.info": "Recopilamos y almacenamos datos en nuestras bases de datos de las siguientes maneras",
    "privacyPolicy.section2.subsection2": "Datos generados o recopilados automáticamente durante las interacciones con el sistema TRAZER.",
    "privacyPolicy.section2.subsection3": "Datos obtenidos de terceros de acuerdo con las leyes aplicables.",
    "privacyPolicy.section3": "Uso de Datos",
    "privacyPolicy.section3.info": "Utilizamos los datos almacenados en el Portal TRAZER con los siguientes propósitos",
    "privacyPolicy.section3.subsection2": "Personalizar experiencias de usuario.",
    "privacyPolicy.section3.subsection3": "Analizar y optimizar nuestras operaciones.",
    "privacyPolicy.section3.subsection4": "Cumplir con requisitos legales y regulatorios.",
    "privacyPolicy.section3.subsection5": "Salvaguardar los derechos, la seguridad y la propiedad de nuestros Clientes y sus Usuarios.",
    "privacyPolicy.section4": "Seguridad de Datos",
    "privacyPolicy.section4.info": "En Trazer Inc., priorizamos la seguridad. Implementamos medidas técnicas y organizativas para proteger los datos contra el acceso no autorizado, la divulgación, la alteración y la destrucción. El acceso a los datos está restringido solo al personal autorizado. Consulte la ",
    "privacyPolicy.section4.securityLink": "Declaración de Seguridad",
    "privacyPolicy.section4.info2": " de Trazer Inc. para obtener más información.",
    "privacyPolicy.section5": "Compartir Datos",
    "privacyPolicy.section5.info": "Trazer Inc. no vende, intercambia ni alquila datos personales a terceros. Sin embargo, podemos compartir datos con",

    "privacyPolicy.section5.subsection2": "Autoridades policiales o regulatorias cuando lo exija la ley.",
    "privacyPolicy.section5.subsection3": "Terceros con consentimiento explícito.",
    "privacyPolicy.section6": "Retención de Datos",
    "privacyPolicy.section6.info": "Retenemos datos solo durante el tiempo necesario para los fines descritos en esta política o según lo requiera la ley.",
    "privacyPolicy.section7": "Derechos del Cliente",
    "privacyPolicy.section7.info": "Los clientes tienen derecho a",
    "privacyPolicy.section7.subsection2": "Corregir datos inexactos.",
    "privacyPolicy.section7.subsection3": "Eliminar datos en ciertas circunstancias.",
    "privacyPolicy.section7.subsection4": "Oponerse o restringir el procesamiento de datos.",
    "privacyPolicy.section7.subsection5": "Retirar el consentimiento cuando sea aplicable.",
    "privacyPolicy.section7.info2": "Para ejercer estos derechos, comuníquese con nosotros utilizando la información proporcionada en la Sección 9.",
    "privacyPolicy.section8": "Cambios en la Política",
    "privacyPolicy.section8.info": "TRAZER Inc. se reserva el derecho de actualizar esta política periódicamente para reflejar cambios en nuestras prácticas o requisitos legales. Cualquier actualización se publicará en nuestro sitio web y la política revisada se aplicará a partir de la fecha de publicación.",
    "privacyPolicy.section9": "Contáctenos",
    "privacyPolicy.section9.info": "Si tiene alguna pregunta o inquietud sobre esta política o cómo manejamos los datos, contáctenos en",
    "privacyPolicy.footer.line2": "629 Euclid Ave",
    "privacyPolicy.footer.line3": "Piso 11, Suite 1101",
    "privacyPolicy.footer.line4": "Cleveland OH 44114",
    "privacyPolicy.footer.line5": "privacy@trazer.com",
    "privacyPolicy.footer.line6": "(440) 835-9191",
    "accessDenied.title": "Acceso Denegado",
    "accessDenied.line1": "No tiene acceso a esta página",
    "accessDenied.line2": "Si cree que esto es un error, comuníquese con el Soporte de TRAZER",
    "validatorErrors.emptyFormWarning": "Por favor complete todos los campos requeridos",
    "validatorErrors.invalidDataFormWarning": "Por favor complete todos los campos con datos válidos",
    "validatorErrors.userInactive": "Este usuario está inactivo, no puede editar su configuración de administrador.",
    "widgets.actions.configure": "Configurar",
    "widgets.fieldName.customer": "Cliente",
    "widgets.fieldName.user": "Usuario",
    "myProfile.editModal.title": "Mi Perfil",
    "support.title": "Contactar Soporte",
    "portalVersion.title": "Por favor, seleccione su versión",
    "login.wrongAlert": "Nombre de usuario o PIN incorrecto",
    "login.username": "Nombre de usuario",
    "login.pin": "PIN",
};

export default ESTranslations;
