/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import React, { forwardRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { Navigate, NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import { useHook, useObjectHook } from './Utils';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import { LanguageIcon, TrazerLogo } from './CustomIcons';
import MainRouterConfig from '../Utils/MainRouterConfig';
import { Button, IconButton } from './Button';
import { AdminTypes, AdminTypesTranslationKeysArr, AvailableLanguages, AvailableLanguagesArray } from '../Utils/Constants';
import { useTranslation } from './Translation';
import { Profile } from '../Components/Profile';
import { Modal } from './Modal';
import { SingleSelect } from './SelectListComponents/SelectList';
import { SelectOption } from './SelectListComponents/SelectOption';
import { CustomerService } from '../Services/CustomerService';
import { RadioGroup, RadioOption } from './RadioSelection';
import { SiteService } from '../Services/SiteService';
import { CircularLoader } from './Loaders';
import { minLengthValidator, requiredValidator } from '../Utils/Validators';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser, setOriginalUser } from '../globalStates/storeState';
import { MultiSelect, MultiSelectOption } from './SelectListComponents/MultiSelect';

const activeClassName = 'active';

const internalLinkStyles = css`
    cursor: pointer;
    letter-spacing: 0.18px;
    text-decoration: none;
`;
export const InternalLink = forwardRef(({ className, ...props }, ref) =>
    <NavLink css={internalLinkStyles} className={'link ' + (className || '')} {...props} ref={ref} />);

const NavigationHeight = '68px';

const layoutStyles = {
    header: css`
        display: flex;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        height: ${NavigationHeight};
        // justify-content: space-around;

        @media print {
            display: none;
        }
    `,
    main: css`
        height: calc(100vh - ${NavigationHeight});
        overflow-x: auto;
        overflow-y: hidden;

        @media print {
            overflow: auto;
            height: auto;
        }
    `,
    logo: css`
        margin: auto 0 auto 8px;
        color: #231F20;
        display: flex;
    `,
    navigation: css`
        margin: auto;
        text-transform: uppercase;
        font: normal normal normal 20px/24px Mustica Pro;
        letter-spacing: 0.18px;

        & > .link {
            height: 40px;
            padding: 7px 18px;
            margin: 0 11px;
            color: #2C2E35;

            &:hover {
                background: #28326519 0% 0% no-repeat padding-box;
                border-radius: 8px;
            }
        }

        & > .${activeClassName} {
            // color: #676767;
            // background: #28326529 0% 0% no-repeat padding-box;
            color: #ffffff;
            background: #00ABA5 0% 0% no-repeat padding-box;
            border-radius: 8px;

            &:hover {
                color: #2C2E35;
                background: #28326519 0% 0% no-repeat padding-box;
                border-radius: 8px;
            }
        }
    `,
    user: css`
        margin: auto 8px auto 0;
    `,
    languageSelect: css`
        margin: auto 8px auto 0;
        padding: 0.2em 0.5em;
        min-width: unset;

        & > span {
            margin-left: 8px;
        }
    `,
    logoutButton: css`
        margin: 20px 20px 12px 20px;
        min-width: 136px;
        height: 40px;
    `,
    userViewModal: css`
        display: flex;
        flex-direction: column;
        min-height: 340px;
        // height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 580px) {
            width: 480px;
        }

        & > .role {
            margin: 8px 20px 21px 20px;
        }

        & .actions {
            display: flex;
            width: 100%;
            padding: 40px 0 40px 40px;

            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }
    `,
    loadContainer: css`
        width: 100%;
        height: 100%;
        display: flex;
    `,
};

export const Layout = ({ children }) => {
    const { logout } = useAuth0();
    const $userMenuAnchor = useHook(null);
    const navigate = useNavigate();
    const $languageSelectionAnchor = useHook(null);
    const storeState = useSelector((state) => state.storeState);
    const dispatch = useDispatch();

    const { t, currentLanguage, changeLanguage } = useTranslation();

    const $isForceValidating = useHook(false);
    const $isLoading = useHook(true);
    const $isProfileOpened = useHook(false);
    const $isUserViewModalOpened = useHook(false);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $availableRoles = useHook([]);

    const $selectedUserView = useObjectHook({
        customerId: storeState.currentUser.admin.customerId,
        siteIds: [],
        adminTypeId: AdminTypes.Provider
    },
        {
            adminTypeId: [requiredValidator],
            customerId: [requiredValidator]
        },
        (oldState, newState) => {
            const validationResult = minLengthValidator(1)(newState.siteIds);
            if ((newState.adminTypeId === AdminTypes.Provider || newState.adminTypeId === AdminTypes.SiteAdmin) && validationResult.length) {
                return { siteIds: validationResult };
            }
        },
        $isForceValidating.value
    );

    useEffect(() => {
        CustomerService.getAll(9999, 'Name', true)
            .then(customers => $customers.set(customers));

        $availableRoles.set(
            AdminTypesTranslationKeysArr
                .filter(r => r.value > AdminTypes.User && r.value < AdminTypes.TRAZERAdmin)
        )
    }, []);

    useEffect(() => {
        if (!$selectedUserView.value.customerId) {
            return;
        }
        SiteService.getAll(9999, $selectedUserView.value.customerId, null, 'Name', true)
            .then(sites => {
                $sites.set(sites);
                $isLoading.set(false);
            });
    }, [$selectedUserView.value.customerId])

    const resetRole = () => {
        dispatch(setCurrentUser(storeState.originalUser))
        handleCloseUserMenu();
    }

    const setRole = () => {
        $isForceValidating.set(true);

        dispatch(setCurrentUser({
            ...storeState.currentUser,
            customerId: $selectedUserView.value.customerId,
            admin: {
                ...storeState.currentUser.admin,
                adminTypeId: $selectedUserView.value.adminTypeId,
                customerId: $selectedUserView.value.customerId,
                adminSites: $selectedUserView.value.siteIds.map(x => ({
                    userId: storeState.currentUser.userId,
                    siteId: x
                }))
            }
        }));

        dispatch(setOriginalUser(storeState.originalUser || storeState.currentUser));

        $isUserViewModalOpened.set(false);
        handleCloseUserMenu();
    }

    const handleOpenLanguageMenu = (e) => $languageSelectionAnchor.set(e.currentTarget);
    const handleCloseLanguageMenu = () => $languageSelectionAnchor.set(null);

    const handleOpenUserMenu = (e) => $userMenuAnchor.set(e.currentTarget);
    const handleCloseUserMenu = () => $userMenuAnchor.set(null);

    return <>
        <header css={layoutStyles.header} className="main-navigation hide-in-unity">
            <InternalLink to="/" css={layoutStyles.logo}>
                <TrazerLogo sx={{ height: '20px', width: 'auto', margin: 'auto' }} />
            </InternalLink>
            <nav css={layoutStyles.navigation}>
                {storeState.currentUser.admin.adminTypeId !== AdminTypes.Operator && storeState.currentUser.admin.adminTypeId !== AdminTypes.User ?
                <>
                    {MainRouterConfig.map(pl => (
                        <InternalLink
                            key={pl.to}
                            to={pl.to}
                        // className={({ isActive }) => isActive ? activeClassName : undefined}
                        >
                            {t(pl.translationKey)}
                        </InternalLink>
                    ))}
                </>
                :
                <>
                    <InternalLink
                        key="/library"
                        to="/library"
                    >
                        {t("mainNavigation.library")}
                    </InternalLink>
                </>
                }
            </nav>
            {
            // TODO: Uncomment when the multi-language feature is ready for release
            /*<Button onClick={handleOpenLanguageMenu} css={layoutStyles.languageSelect}>
                <LanguageIcon />
                <span>{AvailableLanguages[currentLanguage]}</span>
            </Button>

            <Menu
                sx={{ mt: '32px' }}
                id="language-appbar"
                anchorEl={$languageSelectionAnchor.value}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean($languageSelectionAnchor.value)}
                onClose={handleCloseLanguageMenu}
            >
                {AvailableLanguagesArray.map(([label, value]) => (
                    <MenuItem
                        key={value}
                        disabled={value === currentLanguage}
                        onClick={() => {
                            handleCloseLanguageMenu();
                            changeLanguage(value);
                        }}
                    >
                        <Typography textAlign="center">{label}</Typography>
                    </MenuItem>
                ))}
            </Menu>*/
            }
            <Box css={layoutStyles.user}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} tooltip={t('mainNavigation.profileSettingsTooltip')}>
                    <Avatar>{storeState.currentUser.firstName[0]}{storeState.currentUser.lastName[0]}</Avatar>
                </IconButton>
                <Menu
                    sx={{ mt: '32px' }}
                    id="menu-appbar"
                    anchorEl={$userMenuAnchor.value}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean($userMenuAnchor.value)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem onClick={() => {
                        handleCloseUserMenu();
                        $isProfileOpened.set(true);
                    }}>
                        {t('mainNavigation.profileSettingsProfile')}
                    </MenuItem>
                    {storeState.currentUser.admin.adminTypeId !== AdminTypes.User &&
                        <MenuItem onClick={() => {
                            handleCloseUserMenu();
                            window.open('https://university.trazer.com/');
                        }}>
                            {t('mainNavigation.profileSettingsDocumentation')}
                        </MenuItem>
                    }
                    <MenuItem onClick={() => {
                        handleCloseUserMenu();
                        navigate('/support');
                    }}>
                        {t('mainNavigation.profileSettingsContactSupport')}
                    </MenuItem>
                    {(storeState.originalUser.admin.adminTypeId === AdminTypes.TRAZERAdmin) &&
                        <MenuItem onClick={() => { $isUserViewModalOpened.set(true) }}>
                            {t('mainNavigation.userView')}
                        </MenuItem>
                    }
                    {(storeState.originalUser !== storeState.currentUser && storeState.originalUser.admin.adminTypeId === AdminTypes.TRAZERAdmin) &&
                        <Button
                            css={layoutStyles.logoutButton}
                            onClick={resetRole}
                        >
                            {t('mainNavigation.userView.reset')}
                        </Button>
                    }
                    <Button
                        css={layoutStyles.logoutButton}
                        onClick={() => {
                            logout({
                                returnTo: window.location.origin,
                                // federated: true,
                            });
                        }}
                    >
                        {t('actions.logout')}
                    </Button>
                </Menu>
            </Box>
        </header>
        <main css={layoutStyles.main}>
            {children}
        </main>
        <Profile $isProfileOpened={$isProfileOpened} />
        <Modal
            open={$isUserViewModalOpened.value}
            onClose={() => $isUserViewModalOpened.set(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {$isLoading.value ?
                <div css={layoutStyles.loadContainer}>
                    <CircularLoader />
                </div>
                :
                <div css={layoutStyles.userViewModal}>
                    <h2>{t('mainNavigation.selectViewModal.title')}</h2>
                    <SingleSelect
                        $value={$selectedUserView.getPropHook('customerId')}
                        required
                        label={t('sites.fieldName.customer')}
                    >
                        {$customers.value.map(c => <SelectOption key={c.customerId} value={c.customerId}>{c.name}</SelectOption>)}
                    </SingleSelect>
                    {$selectedUserView.value.adminTypeId === AdminTypes.Provider || $selectedUserView.value.adminTypeId === AdminTypes.SiteAdmin ?
                        <MultiSelect disabled={!$selectedUserView.value.customerId} required $value={$selectedUserView.getPropHook('siteIds')} label={t('users.fieldName.site')}>
                            {$sites.value.map(s => <MultiSelectOption key={s.siteId} value={s.siteId}>{s.name}</MultiSelectOption>)}
                        </MultiSelect>
                        : null
                    }
                    <RadioGroup className="role" $value={$selectedUserView.getPropHook('adminTypeId')} required label={t('users.fieldName.role')}>
                        {$availableRoles.value.map(r => (
                            <RadioOption key={r.value} value={r.value} label={t(r.translationKey)} />
                        ))}
                    </RadioGroup>
                    <div className="actions">
                        <Button variant="outlined" onClick={() => $isUserViewModalOpened.set(false)}>{t('actions.cancel')}</Button>
                        <Button disabled={!$selectedUserView.isValid} onClick={setRole}>{t('actions.submit')}</Button>
                    </div>
                </div>
            }
        </Modal>
    </>
};

const tabLayoutStyles = css`
    display: flex;
    flex-direction: row;
    background: #EEEEEE 0% 0% no-repeat padding-box;
`;

export const TabLayout = ({ items, isPageWide, type, sidePanelProps, className, activeTabProps, ...props }) => {
    const $activeItem = useHook(null);

    return (
        <div css={tabLayoutStyles} className={className}>
            <SideNavigation items={items} isPageWide={isPageWide} type={type} onSelectedItem={$activeItem.set} {...sidePanelProps} />
            <CustomScrollContainer isPageWide={isPageWide}>
                {type === 'router' ?
                    <Routes>
                        {items.map(i => (
                            <React.Fragment key={i.path}>
                                <Route path={i.path} element={<i.ContentComponent />} />
                                {i.isDefault &&
                                    <Route exact path="*" element={<Navigate to={i.path} replace={true} />} />
                                }
                            </React.Fragment>
                        ))}
                    </Routes>
                    : $activeItem.value ?
                        // eslint-disable-next-line react/jsx-pascal-case
                        <$activeItem.value.ContentComponent {...activeTabProps} />
                        : null
                }
            </CustomScrollContainer>
        </div>
    );
}

const customScrollContainerStyles = {
    root: css`
        overflow: auto;
        padding: 0;
        scrollbar-color: #858585 inherit;
        width: 100%;
    `,
    pageWide: css`height: calc(100vh - ${NavigationHeight});`
};

export const CustomScrollContainer = React.forwardRef(
    ({ children, isPageWide, ...props }, ref) =>
        <div ref={ref} css={[customScrollContainerStyles.root, isPageWide ? customScrollContainerStyles.pageWide : null]} {...props}>
            {children}
        </div>
);

const sideNavigationStyles = {
    root: css`
        font: normal normal normal 16px/24px "Mustica Pro";
        letter-spacing: 0.14px;
        display: flex;
        flex-direction: column;
        background: #2C2E35 0% 0% no-repeat padding-box;
        padding: 8px;
        padding-top: 52px;
        width: 240px;
        min-width: 240px;
        max-width: 240px;

        & ul {
            list-style: none;
            margin: 0;
            padding: 0;

            & > li {
                display: flex;
                width: 100%;
                padding: 2px;

                & > * {
                    width: 100%;
                    height: 100%;
                    padding: 0.5rem 0.5rem 0.5rem 0.8rem;
                    color: #929398;
                    padding-left:32px;

                    &.${activeClassName} {
                        background: #3D4048 0% 0% no-repeat padding-box;
                        border-radius: 8px;
                        color: #FFFFFF;
                    }

                    &:hover {
                        background: #3D4048 0% 0% no-repeat padding-box;
                        border-radius: 8px;
                    }
                }
            }
        }

        & > .actions {
            margin: auto auto 40px auto;
        }
    `,
    pageWide: css`height: calc(100vh - ${NavigationHeight});`,
    internalLink: internalLinkStyles
};

export const SideNavigation = ({ items, isPageWide, type, Actions, onSelectedItem }) => {
    const $activeItem = useHook(null);
    // const navigate = useNavigate();

    useEffect(() => {
        const defaultItem = items.find(x => x.isDefault);
        const activeItem = defaultItem || items[0];
        if (activeItem) {
            if (type === 'router') {
                // navigate(activeItem.path);
            }

            onSelectedItem(activeItem);
        }

        $activeItem.set(activeItem);
    }, [items]);

    return (
        <aside css={[sideNavigationStyles.root, isPageWide ? sideNavigationStyles.pageWide : null]}>
            <nav role="navigation">
                <ul>
                    {items.map(item => (type === 'router' ?
                        <li key={item.name}
                            css={sideNavigationStyles.internalLink}
                            className={item === $activeItem.value ? activeClassName : null}
                        >
                            <InternalLink
                                key={item.path}
                                to={item.path}
                                // className={({ isActive }) => isActive ? activeClassName : undefined}
                                onClick={() => {
                                    $activeItem.set(item);
                                    onSelectedItem && onSelectedItem(item);
                                }}
                            >
                                {item.name}
                            </InternalLink>
                        </li>
                        :
                        <li key={item.name}
                            css={sideNavigationStyles.internalLink}
                            onClick={() => {
                                $activeItem.set(item);
                                onSelectedItem && onSelectedItem(item);
                            }}
                        >
                            <span className={item === $activeItem.value ? activeClassName : null}>
                                {item.name}
                            </span>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="actions">
                {Actions && <Actions />}
            </div>
        </aside>
    );
}

// if needs to be changed see overview (possible issue)
const footerStyle = css`
    width: 100%;
    text-align: center;

    & h3 {
        text-decoration: underline;
        font: normal normal normal 16px/24px Roboto;
        letter-spacing: 0.14px;
        color: #929091;
    }

    & > p {
        font: normal normal normal 16px/24px Roboto;
        letter-spacing: 0.14px;
        color: #929091;
    }

    @media print {
        display: none;
    }
`

export const Footer = ({ className }) => {
    const { t } = useTranslation();
    return (
        <footer className={className} css={footerStyle}>
            <InternalLink to="/privacy-policy"><h3>{t('footer.privacyPolicy')}</h3></InternalLink>
            <p>
                {t('footer.protectedBy')}
                <br />
                {t('footer.TrazerInc', (new Date()).getUTCFullYear())}
            </p>
        </footer>
    )
}
