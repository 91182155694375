/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { IconButton } from '../../../CoreComponents/Button';
import AddIcon from '@mui/icons-material/Add';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { InternalLink, TabLayout } from '../../../CoreComponents/Layout';
import { defaultSort } from '../../../Utils/Common';
import { SiteTab } from './SiteTab';
import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { TrashCanIcon } from '../../../CoreComponents/CustomIcons';
import { Modal } from '../../../CoreComponents/Modal';
import { Button } from '../../../CoreComponents/Button';
import { useTranslation } from '../../../CoreComponents/Translation';
import { SiteService } from '../../../Services/SiteService';
import { AddSiteContent } from './AddSiteContent';
import { SiteUnitsTab } from './SiteUnitsTab';
import { AdminTypes, AdvancedSearchType, SortOrder, TableOptionsTypes } from '../../../Utils/Constants';
import { DeleteConfirmationModal } from '../../../CoreComponents/DeleteConfirmationModal';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { useDispatch, useSelector } from 'react-redux';
import { setSites } from '../../../globalStates/tableDataState';

const siteListStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}

const SiteList = ({ $selectedSite, $refreshSites }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin;
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const isProvider = storeState.currentUser.admin.adminTypeId === AdminTypes.Provider;
    const $sites = useHook(tableDataState.sites);
    const $customers = useHook([]);
    const $siteForDelete = useHook(null);
    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 150,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        {!isTrazerViewAdmin && !isProvider && 
                            <IconButton disabled={isTrazerViewAdmin || isProvider} onClick={() => $selectedSite.set(rowData)}>
                                <EditIcon />
                            </IconButton>
                        }
                        <IconButton
                            component={InternalLink}
                            to={`/reports/site/${rowData.siteId}`}
                        >
                            <SignalCellularAltIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('sites.table.name'),
            columnWidth: 300,
            isSortable: true,
        },
        {
            id: 'pin',
            label: t('sites.table.pin'),
            columnWidth: 150,
        },
        {
            id: 'customerName',
            label: isTrazerAdmin || isTrazerViewAdmin ? t('sites.table.customer.name') : '',
            columnWidth: isTrazerAdmin || isTrazerViewAdmin ? 300 : 0,
            isSortable: isTrazerAdmin || isTrazerViewAdmin,
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($customers.value, 'value', SortOrder.Asc)
            },
            CellRender: ({ rowData }) => {
                return isTrazerAdmin || isTrazerViewAdmin ? rowData?.customerName || '-' : '';
            }
        },
        {
            id: 'contactName',
            label: t('sites.table.contactName'),
            isSortable: true,
            columnWidth: 200,
        },
        {
            id: 'phone',
            label: t('sites.table.phone'),
            columnWidth: 150,
        },
        {
            id: 'email',
            label: t('sites.table.email'),
            isSortable: true,
        },
        isTrazerAdmin && {
            id: 'delete',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton onClick={() => $siteForDelete.set(rowData)} tooltip={t('actions.delete')}>
                            <TrashCanIcon />
                        </IconButton>
                    </>
                );
            }
        },
    ].filter(x => x), [t, $customers.value]);

    const $isAddSiteModalOpened = useHook(false);
    const $isLoading = useHook(!Boolean(tableDataState.sites.length));

    const deleteSite = siteId => {
        SiteService.deleteSite(siteId)
            .then(() => {
                $sites.set($sites.value.filter(x => x.siteId !== siteId))
                $siteForDelete.set(null);
            })
    }

    useEffect(() => {
        let siteIds = null;
        let customerIds = null;

        if (!isTrazerAdmin) {
            if (isLessThenSuperAdmin) {
                siteIds = storeState.currentUser.admin.adminSites.map(site => site.siteId);
            } else {
                customerIds = [storeState.currentUser.admin.customerId];
            }
        }

        SiteService.getAll(9999, customerIds, null, null, null, siteIds)
            .then(sites => {
                const customers = {};

                const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin;
                const isTrazerAdmin = storeState.currentUser.admin.adminTypeId > AdminTypes.SuperAdmin;
                let filteredSites = [];

                if (!isTrazerAdmin) {
                    if (isLessThenSuperAdmin) {
                        filteredSites = sites.filter(x => storeState.currentUser.admin.adminSites.map(site => site.siteId).includes(x.siteId));
                    } else {
                        filteredSites = sites.filter(x => x.customerId === storeState.currentUser.admin.customerId);
                    }
                } else {
                    filteredSites = sites;
                }

                for (const site of filteredSites) {
                    customers[site.customer.name] = 1;
                    site.customerName = site.customer.name;
                }

                $customers.set(Object.keys(customers).map(x => ({ value: x, label: x })))

                dispatch(setSites(filteredSites));
                $sites.set(filteredSites);
                $isLoading.set(false);
            });
    }, [$refreshSites.value, storeState.currentUser])

    return (
        <div css={siteListStyles.root}>
            <TableCSF
                customActions={isTrazerAdmin &&
                    <div css={siteListStyles.actions}>
                        <Button onClick={() => $isAddSiteModalOpened.set(true)}>
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$sites.value}
                rowKeySelector={r => r.siteId}
                isLoading={$isLoading.value}
                enablePersistingFilters
                pageName={TableOptionsTypes.Sites}
            />
            <Modal
                open={$isAddSiteModalOpened.value}
                onClose={() => $isAddSiteModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddSiteContent
                    onAdd={() => {
                        $isAddSiteModalOpened.set(false);
                        $refreshSites.set({});
                    }}
                    onCancel={() => $isAddSiteModalOpened.set(false)}
                    customerId={storeState.currentUser.admin.customerId}
                />
            </Modal>
            <DeleteConfirmationModal
                onDelete={() => deleteSite($siteForDelete.value.siteId)}
                open={Boolean($siteForDelete.value)}
                onCancel={() => $siteForDelete.set(null)}
                title={t('site.deleteModal.title')}
                description={t('site.deleteModal.description')}
                deleteLabel={t('actions.delete')}
            />
        </div>
    );
};

export const Sites = () => {
    const $selectedSite = useObjectHook(null);
    const { t } = useTranslation();
    const $tabs = useHook([]);
    const $refreshSites = useHook({});
    const $prevSelectedSite = useHook(null);

    useEffect(() => {
        if (!$selectedSite.value) {
            return;
        }

        $tabs.set([
            { name: t('sites.editModal.sitesTab'), ContentComponent: () => <SiteTab $selectedSite={$selectedSite} />, isDefault: true },
            { name: t('sites.editModal.siteUnitsTab'), ContentComponent: () => <SiteUnitsTab $selectedSite={$selectedSite} /> },
        ]);
    }, [$selectedSite.value]);

    useEffect(() => {
        if ($prevSelectedSite.value && $selectedSite.value) {
            $refreshSites.set({});
            $prevSelectedSite.set(null);
            $selectedSite.set(null);
            return;
        }

        $prevSelectedSite.set($selectedSite.value);
    }, [$selectedSite.value]);

    return (
        <>
            <SiteList $selectedSite={$selectedSite} $refreshSites={$refreshSites} />
            <Modal
                fixedModalPosition
                open={!!$selectedSite.value}
                onClose={() => $selectedSite.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <TabLayout items={$tabs.value} />
            </Modal>
        </>
    )
}
