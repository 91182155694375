/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { GroupService } from '../../../Services/GroupService';
import { TrashCanIcon } from '../../../CoreComponents/CustomIcons';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useMemo } from 'react';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { AdminTypes } from '../../../Utils/Constants';
import { EditGroupNameContent } from '../UserGroups/EditGroupNameContent';
import { EditUserGroup } from '../UserGroups/EditUserGroup';
import { AddUserToGroupContent } from '../UserGroups/AddUserToGroupContent';
import { DeleteConfirmationModal } from '../../../CoreComponents/DeleteConfirmationModal';
import { useSelector } from 'react-redux';

const styles = {
    root: css`
        display: flex;
        flex-direction: column;

        height: 85vh;
        padding: 40px 0 40px 28px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & h2 {
            margin-left: 12px;
            margin-top: 0;
        }
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    icon: css`
        color: #00ABA5
    `,
    input: css`
        width: 90%;
    `
}

export const UserGroupsTab = ({ $selectedUser }) => {
    const storeState = useSelector((state) => state.storeState);
    const { t } = useTranslation();
    const $refreshGroups = useHook({});
    const $selectedGroup = useObjectHook(null);
    const $groups = useHook([]);
    const $isLoading = useHook(true);
    const $isAddGroupModalOpened = useHook(false);
    const isMoreThenProvider = storeState.currentUser.admin.adminTypeId > AdminTypes.Provider;
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;
    const $isEditUsersModalOpen = useHook(false);
    const $isEditNameModalOpen = useHook(false);
    const $userCopy = useHook({...$selectedUser.value});
    const $groupForDelete = useHook(null);

    const headCells = useMemo(() => [
        {
            id: 'groupName',
            label: t('groups.table.group'),
            isSortable: true,
            columnWidth: 150,
            CellRender: ({ rowData }) => {
                return rowData.groupName || '-';
            }
        },
        isTrazerAdmin ?
            {
                id: 'customer',
                label: t('groups.table.customer'),
                isSortable: true,
                columnWidth: 195,
                CellRender: ({ rowData }) => {
                    return rowData.customer || '-';
                }
            }
            :
            null,
        {
            id: 'site',
            label: t('groups.table.site'),
            isSortable: true,
            columnWidth: 150,
            CellRender: ({ rowData }) => {
                return rowData.site || '-';
            }
        },
        isMoreThenProvider ?
            {
                id: 'delete',
                label: '',
                CellRender: ({ rowData }) => {
                    return (
                        <IconButton onClick={() => $groupForDelete.set(rowData)} tooltip={t('actions.delete')}>
                            <TrashCanIcon />
                        </IconButton>
                    )
                }
            }
            :
            null,
    ].filter(x => x), [$groups.value]);

    const deleteGroup = (groupId, userId) => {
        GroupService.deleteUserGroup(groupId, userId)
            .then(() => {
                $refreshGroups.set({});
                $groupForDelete.set(null);
            });
    }

    const isRowSelected = row => row.groupId === $selectedGroup.value?.groupId

    useEffect(() => {
        $isLoading.set(true);
        GroupService.getGroupsByUserId($selectedUser.value.userId)
            .then(groups => {
                $groups.set(groups.map(x => ({
                    ...x,
                    site: x.group.site.name,
                    customer: x.group.site.customer.name,
                    groupName: x.group.groupName,
                    group: {
                        ...x.group,
                        userGroups: [x].concat(x.group.userGroups),
                    }
                })));
                $isLoading.set(false);
            });
    }, [$refreshGroups.value])

    return (
        <>
            <div css={styles.root}>
                <h2>{t('users.editModal.title')}</h2>
                <TableCSF
                    headCells={headCells}
                    rows={$groups.value}
                    rowKeySelector={r => r.groupId}
                    isLoading={$isLoading.value}
                    isRowSelected={isRowSelected}
                    customActions={
                        <Button onClick={() => $isAddGroupModalOpened.set(true)}>{t('users.addGroupModal.title')}</Button>
                    }
                />
            </div>
            <Modal
                open={$isAddGroupModalOpened.value}
                onClose={() => $isAddGroupModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddUserToGroupContent
                    $user={$userCopy}
                    onCancel={() => $isAddGroupModalOpened.set(false)}
                    onAdd={() => {
                        $isAddGroupModalOpened.set(false);
                        $refreshGroups.set({});
                    }} />
            </Modal>
            <Modal
                open={$isEditNameModalOpen.value}
                onClose={() => {
                    $selectedGroup.set(null);
                    $isEditNameModalOpen.set(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EditGroupNameContent
                    onCancel={() => {
                        $selectedGroup.set(null);
                        $isEditNameModalOpen.set(false);
                    }}
                    onEdit={group => {
                        GroupService.updateGroup(group)
                            .then(() => {
                                const groups = $groups.value;
                                const currentGroup = groups.find(g => g.groupId === $selectedGroup.value.groupId);
                                currentGroup.groupName = group.groupName;
                                currentGroup.group = group
                                $groups.set(groups);
                                $selectedGroup.set(null);
                                $isEditNameModalOpen.set(false);
                            });
                    }}
                    $selectedGroup={$selectedGroup}
                />
            </Modal>
            <Modal
                open={$isEditUsersModalOpen.value}
                onClose={() => {
                    $selectedGroup.set(null);
                    $isEditUsersModalOpen.set(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EditUserGroup
                    $selectedGroup={$selectedGroup}
                    onCancel={() => {
                        $selectedGroup.set(null);
                        $isEditUsersModalOpen.set(false);
                    }}
                    onEdit={() => {
                        $isEditUsersModalOpen.set(false)
                        $selectedGroup.set(null);
                        $refreshGroups.set({});
                    }}
                />
            </Modal>
            <DeleteConfirmationModal
                onDelete={() => deleteGroup($groupForDelete.value.groupId, $groupForDelete.value.userId)}
                open={Boolean($groupForDelete.value)}
                onCancel={() => $groupForDelete.set(null)}
                title={t('userGroups.removeUserGroupModal.title')}
                description={t('userGroups.removeUserGroupModal.description')}
                deleteLabel={t('actions.yes')}
            />
        </>
    )
}
