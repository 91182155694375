import { deleteData, postData, putData, getData, urlQueryString, makeFetchData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const SiteService = {
    getAll: function (limit, customerId, userId, sortBy, isAsc,siteId) {
        const qs = urlQueryString({
            limit,
            customerId,
            userId,
            sortBy,
            isAsc,
            siteId
        }, true);
        return getData(`${ApiHost}/api/Sites?${qs}`)
    },
    deleteSite: function (siteId) {
        return deleteData(`${ApiHost}/api/Sites/${siteId}`);
    },
    postSite: function(site){
        return postData(`${ApiHost}/api/Sites`, site)
    },
    putSite: function(site){
        return putData(`${ApiHost}/api/Sites`, site)
    },
    overview: function(siteId, startDate, endDate) {
        const qs = urlQueryString({
            startDate,
            endDate,
        }, true);
        return getData(`${ApiHost}/api/Sites/${siteId}/overview?${qs}`)
            .then(res =>{
                res.lastOnline = res.lastOnline ? new Date(res.lastOnline) : null;
                return res;
            });
    },
    overviewScriptedActivities: function(siteId, startDate, endDate, limit = 10) {
        const qs = urlQueryString({
            startDate,
            endDate,
            limit,
        }, true);
        return getData(`${ApiHost}/api/Sites/${siteId}/overview/scriptedActivities?${qs}`);
    },
    overviewTrend: function(siteId, orderBy, dateInterval, startDate, endDate) {
        const qs = urlQueryString({
            order_by: orderBy,
            date_interval: dateInterval,
            startDate,
            endDate,
        }, true);
        return getData(`${ApiHost}/api/Sites/${siteId}/overview/trend?${qs}`);
    },
    getByPin: function (sitePin) {
        const url = `${ApiHost}/api/Sites/PIN/${sitePin}`;
        return window.extendedFetch(url, makeFetchData('GET'));
    },
    providerSummary: function(siteId, startDate, endDate) {
        const qs = urlQueryString({
            startDate,
            endDate,
        }, true);
        return getData(`${ApiHost}/api/Sites/${siteId}/provider/trend?${qs}`);
    },
};
