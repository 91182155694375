import { getData, putData, postData, deleteData, urlQueryString, patchData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const AdminService = {
    getAdminSites: function(adminId) {
        return getData(`${ApiHost}/api/admins/${adminId}/sites`)
    },
    postAdmin: function(admin) {
        return postData(`${ApiHost}/api/admins`, admin)
    },
    putAdmin: function(admin) {
        return putData(`${ApiHost}/api/admins`, admin)
    },
    deleteAdmin: function(adminId) {
        return deleteData(`${ApiHost}/api/admins/${adminId}`)
    },
    importAdmins: function (users, customerId, siteId, groupId) {
        const qs = urlQueryString({
            siteId,
            groupId,
            customerId
        }, true);
        return postData(`${ApiHost}/api/Admins/import?${qs}`, users)
    },
    unblockAdmin: function (adminId) {
        return patchData(`${ApiHost}/api/Admins/Unblock/${adminId}`)
    },
    syncAdmin: function(adminId) {
        return postData(`${ApiHost}/api/Admins/SyncAdminUserToAuth0/${adminId}`)
    },
};
