/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { IconButton } from '../../../CoreComponents/Button';
import AddIcon from '@mui/icons-material/Add';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { TabLayout } from '../../../CoreComponents/Layout';
import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { Modal } from '../../../CoreComponents/Modal';
import { Button } from '../../../CoreComponents/Button';
import { useTranslation } from '../../../CoreComponents/Translation';
import { UnitService } from '../../../Services/UnitService';
import { shortDate, defaultSort } from '../../../Utils/Common';
import { AddUnitContent } from './AddUnitContent';
import { UnitTab } from './UnitTab';
import { ActivityLibraryTab } from './ActivityLibraryTab';
import { UnitSettingsTab } from './UnitSettingsTab';
import { AdminTypes, AdvancedSearchMatchType, AdvancedSearchType, SortOrder } from '../../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setUnits } from '../../../globalStates/tableDataState';

const unitsListStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    sidePanelActions: css`
        display: flex;
        flex-direction: column;
        & > button{
            width: 100%;
            margin: 8px auto 0 auto;
        }
    `
}

const UnitsList = ({ $selectedUnit, $refreshUnits }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin;
    const isProvider = storeState.currentUser.admin.adminTypeId === AdminTypes.Provider;
    const $units = useHook(tableDataState.units);
    const $siteNames = useHook([]);
    const $customerNames = useHook([]);
    const $unitIsRegistered = useHook([]);
    const headCells = useMemo(() => [
        !isProvider && {
            id: 'actions',
            label: '',
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton disabled={isProvider} onClick={() => $selectedUnit.set(rowData)}>
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'serialNumber',
            label: t('units.table.serialNumber'),
            columnWidth: 200,
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData?.serialNumber || '-';
            }
        },
        {
            id: 'skuNumber',
            label: t('units.table.skuNumber'),
            isSortable: true,
        },
        {
            id: 'dateCreated',
            label: t('units.table.createdOn'),
            isSortable: true,
            // Just an idea of how we can implement custom filtering, if this is here longer than a month
            // probably we don't have a need for it
            // customFilter: ({ rowData, searchValue, searchValueLowerCase }) =>
            //     !searchValue
            //     || (value && shortDate(value).toLowerCase().includes(searchValueLowerCase)),
            // or just return it as text
            // asText: ({ rowData }) => rowData.dateCreated ? shortDate(rowData.dateCreated) : '-',
            CellRender: ({ rowData }) => {
                return rowData.dateCreated ? shortDate(new Date(rowData.dateCreated)) : '-';
            }
        },
        {
            id: 'lastOnline',
            label: t('units.table.lastOnline'),
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.lastOnline ? shortDate(new Date(rowData.lastOnline)) : '-';
            }
        },
        {
            id: 'customerName',
            label: isTrazerAdmin || isTrazerViewAdmin ? t('units.table.customerName') : '',
            columnWidth: isTrazerAdmin || isTrazerViewAdmin ? 300 : 0,
            isSortable: isTrazerAdmin || isTrazerViewAdmin,
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($customerNames.value, 'value', SortOrder.Asc)
            },
            CellRender: ({ rowData }) => {
                return isTrazerAdmin || isTrazerViewAdmin ? rowData?.customerName || '-' : '';
            }
        },
        {
            id: 'siteName',
            label: t('units.table.siteName'),
            isSortable: true,
            advancedSearch: {
                type: AdvancedSearchType.Select,
                label: '',
                matchType: AdvancedSearchMatchType.Exact,
                values: defaultSort($siteNames.value, 'value', SortOrder.Asc)
            },
            CellRender: ({ rowData }) => {
                return rowData?.siteName || '-';
            }
        },
        {
            id: 'clientVersion',
            label: t('units.table.clientVersion'),
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData?.clientVersion || '-';
            }
        },
        {
            id: 'isActive',
            label: t('units.table.isActive'),
            isSortable: true,
            advancedSearch: {
                type: AdvancedSearchType.Select,
                label: '',
                matchType: AdvancedSearchMatchType.Exact,
                defaultValue: true,
                values: [
                    {
                        value: null,
                        label: t('units.tableFilterValue.all')
                    },
                    {
                        value: true,
                        label: t('units.tableFilterValue.activeOnly')
                    },
                    {
                        value: false,
                        label: t('units.tableFilterValue.inactiveOnly')
                    },
                ]
            },
            CellRender: ({ rowData }) => {
                return rowData.isActive.toString();
            }
        },
        {
            id: 'isRegistered',
            label: t('units.table.isRegistered'),
            isSortable: true,
            advancedSearch: {
                type: AdvancedSearchType.Select,
                label: '',
                values: $unitIsRegistered.value
            },
            CellRender: ({ rowData }) => {
                return rowData.isRegistered.toString();
            }
        },
    ], [t, $siteNames.value, $customerNames.value, $unitIsRegistered.value]);

    const $isAddUnitModalOpened = useHook(false);
    const $isLoading = useHook(!Boolean(tableDataState.units.length));

    useEffect(() => {
        let siteIds = null;
        let customerIds = null;

        if (!isTrazerAdmin) {
            if (isLessThenSuperAdmin) {
                siteIds = storeState.currentUser.admin.adminSites.map(site => site.siteId);
            } else {
                customerIds = [storeState.currentUser.admin.customerId];
            }
        }

        UnitService.getAll(9999, customerIds, siteIds)
            .then(units => {
                const sites = {};
                const customers = {};
                const unitIsRegistered = {};

                const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin;
                const isTrazerAdmin = storeState.currentUser.admin.adminTypeId > AdminTypes.SuperAdmin;
                let filteredUnits = [];

                if (!isTrazerAdmin) {
                    if (isLessThenSuperAdmin) {
                        filteredUnits = units.filter(x => storeState.currentUser.admin.adminSites.map(site => site.siteId).includes(x.siteId));
                    } else {
                        filteredUnits = units.filter(x => x.site.customerId === storeState.currentUser.admin.customerId);
                    }
                } else {
                    filteredUnits = units;
                }

                for (const unit of filteredUnits) {
                    if (unit.site) {
                        sites[unit.site.name] = 1;
                        customers[unit.site.customer.name] = 1;
                        unit.customerName = unit.site.customer.name;
                        unit.siteName = unit.site.name;
                    }
                    unitIsRegistered[unit.isRegistered] = 1;
                }

                $siteNames.set(Object.keys(sites).map(value => ({ value, label: value })));
                $customerNames.set(Object.keys(customers).map(value => ({ value, label: value })));
                $unitIsRegistered.set(Object.keys(unitIsRegistered).map(value => ({ value, label: value })));

                dispatch(setUnits(filteredUnits));
                $units.set(filteredUnits);
                $isLoading.set(false);
            });
    }, [$refreshUnits.value, storeState.currentUser]);

    return (
        <div css={unitsListStyles.root}>
            <TableCSF
                customActions={isTrazerAdmin &&
                    <div css={unitsListStyles.actions}>
                        <Button disabled={!isTrazerAdmin} onClick={() => $isAddUnitModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$units.value}
                rowKeySelector={r => r.unitId}
                isLoading={$isLoading.value}
            />
            <Modal
                open={$isAddUnitModalOpened.value}
                onClose={() => $isAddUnitModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddUnitContent onCancel={() => $isAddUnitModalOpened.set(false)}
                    onAdd={() => {
                        $isAddUnitModalOpened.set(false);
                        $refreshUnits.set({});
                    }}
                />
            </Modal>
        </div>
    );
};

export const Units = () => {
    const $selectedUnit = useObjectHook(null);
    const { t } = useTranslation();
    const $tabs = useHook([]);
    const $refreshUnits = useHook({});
    const $prevSelectedUnit = useHook(null);
    const $disableActivateButton = useHook(false);
    const storeState = useSelector((state) => state.storeState);
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;

    useEffect(() => {
        if (!$selectedUnit.value) {
            return;
        }

        $tabs.set([
            { name: t('units.editModal.title'), ContentComponent: () => <UnitTab $selectedUnit={$selectedUnit} />, isDefault: true },
            { name: t('units.editModal.activityLibraryTab'), ContentComponent: () => <ActivityLibraryTab $selectedUnit={$selectedUnit} /> },
            { name: t('units.editModal.unitSettingsTab'), ContentComponent: () => <UnitSettingsTab $selectedUnit={$selectedUnit} /> },
        ]);
    }, [$selectedUnit.value]);

    useEffect(() => {
        if ($prevSelectedUnit.value && $selectedUnit.value) {
            $refreshUnits.set({});
            $selectedUnit.set(null);
            $prevSelectedUnit.set(null);
            return;
        }

        $prevSelectedUnit.set($selectedUnit.value);
    }, [$selectedUnit.value]);

    const repurpose = () => {
        const unitId = $selectedUnit.value.unitId;
        const unit = {
            ...$selectedUnit.value,
            isActive: false,
            isRegistered: false
        };
        UnitService.patchUnit(unitId, unit)
            .then(() => $selectedUnit.set(unit));
    }

    const changeActiveStatus = () => {
        $disableActivateButton.set(true);
        const unitId = $selectedUnit.value.unitId;
        const unit = {
            ...$selectedUnit.value,
            isActive: !$selectedUnit.value.isActive
        };
        UnitService.patchUnit(unitId, unit)
            .then(() => $selectedUnit.set(unit))
            .finally(() => $disableActivateButton.set(false));
    }

    const unregister = () => {
        const unitId = $selectedUnit.value.unitId;
        const unit = {
            ...$selectedUnit.value,
            isRegistered: false
        };
        UnitService.patchUnit(unitId, unit)
            .then(() => $selectedUnit.set(unit));
    }

    const tabActions = isTrazerAdmin && $selectedUnit.value ?
        () => <div css={unitsListStyles.sidePanelActions}>
            <Button
                disabled={!($selectedUnit.value.isActive || $selectedUnit.value.isRegistered)}
                onClick={repurpose}
            >
                {t('actions.repurpose')}
            </Button>

            <Button
                disabled={!$selectedUnit.value.isRegistered || $disableActivateButton.value }
                onClick={changeActiveStatus}
            >
                {$selectedUnit.value.isActive ? t('actions.deactivate') : t('actions.activate')}
            </Button>

            <Button
                disabled={!$selectedUnit.value.isRegistered}
                onClick={unregister}
            >
                {t('actions.unregister')}
            </Button>
        </div>
        : null;

    return (
        <>
            <UnitsList $selectedUnit={$selectedUnit} $refreshUnits={$refreshUnits} />
            <Modal
                open={!!$selectedUnit.value}
                onClose={() => $selectedUnit.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <TabLayout
                    items={$tabs.value}
                    sidePanelProps={{ Actions: tabActions }}
                />
            </Modal>
        </>
    )
}
