import {StepConnector, stepConnectorClasses, styled } from "@mui/material";


export const TRAZStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 6.5,
        left: '-50%',
        right: '50%',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#00ABA5',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#00ABA5',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#CBCBCB',
        borderTopWidth: 8,
        borderRadius: 1,
    },
}));

export function TRAZStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <TRAZStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <div className="TRAZStepIcon-circle completed" />
            ) : (
                <div className="TRAZStepIcon-circle" />
            )}
        </TRAZStepIconRoot>
    );
}

const TRAZStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: '#CBCBCB',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    zIndex: 1,
    ...(ownerState.active && {
        color: '#00ABA5',
    }),
    '& .TRAZStepIcon-completedIcon': {
        color: '#00ABA5',
        zIndex: 1,
        fontSize: 18,
    },
    '& .TRAZStepIcon-circle': {
        width: 28,
        height: 28,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    '& .completed': {
        color: '#00ABA5',
    }
}));
