/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button } from '../../../CoreComponents/Button';
import { Input } from '../../../CoreComponents/Input';
import { useTranslation } from '../../../CoreComponents/Translation';
import { ForceValidateContext, useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { CategoryService } from '../../../Services/CategoryService';
import { maxLengthValidator, requiredValidator } from '../../../Utils/Validators';

const addCategoryContentStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        min-height: 340px;
        // height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 580px) {
            width: 480px;
        }

        & > .input-root {
            margin: 0;
            width: 100%;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .actions {
            margin-top: auto;
            width: 100%;
            display: flex;

            & > button:first-of-type {
                margin-right: 20px;
                margin-left: auto;
            }
        }
    `,
};

const AddCategoryValidator = {
    name: [requiredValidator, maxLengthValidator(50)]
}

export const AddCategoryContent = ({ onCancel, onAdd }) => {
    const { t } = useTranslation();
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const $newCategory = useObjectHook(
        {
            name: '',
        },
        AddCategoryValidator,
        null,
        $isForceValidating.value
    );

    const addCategory = () => {
        $isForceValidating.set(true);
        const errors = $newCategory.validate();
        if (errors) {
            return;
        }

        const newCategory = {
            ...$newCategory.value,

            name: $newCategory.value.name
        };
        $newCategory.set(newCategory);

        $isSaving.set(true);
        CategoryService.postCategory(newCategory)
            .then(() => onAdd(newCategory))
            .finally(() => $isSaving.set(false));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addCategoryContentStyles.root}>
                <h2>{t('categories.addModal.title')}</h2>

                <Input placeholder={t('input.placeholder')} required label={t('categories.fieldName.name')} $value={$newCategory.getPropHook('name')} />

                <div className="actions" css={addCategoryContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newCategory.isValid} onClick={addCategory}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
}
