/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup as RadioGroupBase } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForceValidateContext } from './Utils';
import { FrozenEmptyObject } from '../Utils/Constants';

export const RadioGroup = ({ label, $value, value, onChange, validator, required, disabled, children, ...props }) => {
    const forceValidateContext = useForceValidateContext();
    const [validationErr, setValidationErr] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const hasErrors = validationErr.length > 0;
    const lastCheckedValue = useRef(FrozenEmptyObject);

    useEffect(() => {
        if (value == null && $value?.value == null) {
            console.warn(`Input value for [${label}] is null or undefined`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!forceValidateContext
            && lastCheckedValue.current === FrozenEmptyObject
        ) {
            return;
        }

        if (validator) {
            const validatedValue = value || $value?.value;
            const validationResult = validator(validatedValue);
            // console.log('useEffect validation', {label, validatedValue, validationResult})
            setValidationErr(validationResult || []);
        } else if ($value?.validate) {
            setValidationErr($value.validate() || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceValidateContext, value, validator, $value?.isValid, $value?.value, $value?.validate]);

    const valueInternal = $value ?
        $value.value != null ? $value.value : ''
        : value;

    const onChangeInternal = (event) => {
        const value = isNaN(event.target.value) ?
            event.target.value
            : +event.target.value
        lastCheckedValue.current = value;
        $value && $value.set(value);
        onChange && onChange(value);
    }

    return (
        <FormControl
            component="fieldset"
            disabled={disabled}
            sx={{ width: '100%' }}
            {...props}
        >
            <FormLabel component="legend">
                {label} {required && <span className="required">*</span>}
            </FormLabel>
            <RadioGroupBase
                // aria-label={label.toLowerCase()}
                // name={label.toLowerCase()}
                value={valueInternal}
                onChange={onChangeInternal}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginTop: '18px',
                    marginLeft: '20px',
                    '& > label:last-of-type': { margin: 0 }
                }}
                row
                className="radio-group"
            >
                {children}
            </RadioGroupBase>
        </FormControl>
    );
};

const radioStyle = {
    root: css`
        margin-left: 0;
        margin-bottom: 13px;

        & .Mui-checked {
            border-color: #00ABA5 ;
        }
    `,
    checked: css`
        background-color: #00ABA5;
        border-radius: 2px;
        width: 8px;
        height: 8px;
        margin: 2px;
    `,
    normal: css`
        width: 6px;
        height: 6px;
        margin: 3px;
    `
}

export const RadioOption = ({ value, label, ...props }) => {
    return (
        <FormControlLabel
            css={radioStyle.root}
            value={value}
            label={label}
            {...props}
            control={
                <Radio
                    checkedIcon={<span css={radioStyle.checked} />}
                    icon={<span css={radioStyle.normal} />}
                    sx={{
                        border: '2px solid #858585',
                        padding: 0,
                        borderRadius: '5px',
                        marginRight: '8px',
                        marginLeft: 'auto'
                    }}
                />}
        />
    );
};
