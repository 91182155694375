import { USStates } from '../Assets/us-states-en';
import Countries from '../Assets/world.json';

export const AddressService = {
    getCountries: function (currentLanguage) {
        return Promise.resolve(Countries.map(x => (
            { code: x.alpha3, name: x[currentLanguage] || x.en }
        )));
    },
    getStates: function (currentLanguage) {
        return Promise.resolve(USStates);
    }
};
