/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { subYears } from "date-fns";
import { useEffect, useMemo } from "react";
import { Button } from "../../../CoreComponents/Button";
import { DateRangeSelector } from "../../../CoreComponents/DateRangeSelector";
import { CircularLoader } from "../../../CoreComponents/Loaders";
import { SingleSelect } from "../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../CoreComponents/SelectListComponents/SelectOption";
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { UserService } from "../../../Services/UserService";
import { dateAndMonth, parseDate } from "../../../Utils/Common";
import { useSelector } from "react-redux";
import { LineChart } from "../../../CoreComponents/ChartComponents/LineChart";

const caloriesWidgetStyle = {
    root: css`
        height: 100%;
        width: 100%;
        position: relative;

        & > .info {
            position: absolute;
            margin: 0;
            & > h2 {
                margin: 5px 0 0 0;
            }
        }
        & > .chart-with-options-container {
            margin: 0;
            position: initial;

            & > canvas {
                position: absolute;
                top: 25px;
            }
        }
    `,
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
    controls: css`
        display: flex;
        align-items: flex-start;
        height: 100%;
        justify-content: space-evenly;
        flex-wrap: wrap;
        flex-direction: column;
        margin-left: 40px;

        & .selector-wrapper {
            min-width: 280px;
        }
    }
    `
}

const DemoData = {
    name: 'user',
    chartData: [
        {
            "item1": "2022-47",
            "item2": 3
        },
        {
            "item1": "2022-49",
            "item2": 73
        },
        {
            "item1": "2022-50",
            "item2": 13
        },
        {
            "item1": "2022-51",
            "item2": 3
        },
        {
            "item1": "2023-1",
            "item2": 27
        },
        {
            "item1": "2023-10",
            "item2": 1
        },
        {
            "item1": "2023-11",
            "item2": 9
        },
        {
            "item1": "2023-12",
            "item2": 27
        },
        {
            "item1": "2023-2",
            "item2": 7
        },
        {
            "item1": "2023-3",
            "item2": 11
        },
        {
            "item1": "2023-5",
            "item2": 3
        },
        {
            "item1": "2023-9",
            "item2": 15
        }
    ]
}

export const UserCaloriesBurnedTrendWidget = ({ demoMode, $settings }) => {
    const { t } = useTranslation();
    const { users, isEditMode } = useSelector((state) => state.dashboardState);
    const $isLoading = useHook(!demoMode && ($settings.value?.userId || !users.length));
    const $showSettings = useHook(!demoMode && !$settings.value);
    const $dateRange = useObjectHook({
        dateInterval: 'week',
        startDate: !demoMode && $settings.value?.startDate ?
            parseDate($settings.value.startDate, 'yyyy-MM-dd')
            : subYears(new Date(), 2),
        endDate: !demoMode && $settings.value?.endDate ?
            parseDate($settings.value.endDate, 'yyyy-MM-dd')
            : new Date(),
    });
    const $oldSettings = useHook(null);
    const $reportData = useHook([]);
    const $rawData = useHook([]);
    const $selectedUser = useHook((!demoMode && $settings.value?.userId) || null);
    const $userName = useHook('');

    const additionalSettings = useMemo(() => [
        {
            key: 'configure',
            label: t('widgets.actions.configure'),
            handleClick: () => {
                $showSettings.set(true);
                $oldSettings.set($settings.value);
            }
        }
    ], [t])

    const saveConfiguration = () => {
        if (!$selectedUser.value && $isLoading.value) {
            return;
        }

        const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
        const endDate = $dateRange.value.endDate.toISOString().split('T')[0];

        $settings.set({
            version: 1,
            settings:
            {
                startDate,
                endDate,
                userId: $selectedUser.value
            }
        });
    }

    useEffect(() => {
        if (demoMode) {
            $rawData.set(
                DemoData.chartData.map(x => ({
                    label: dateAndMonth(parseDate(x.item1, 'YYYY-ww')),
                    value: x.item2
                }))
            )
            $userName.set('User')
            return;
        }
        if (!$selectedUser.value && users.length) {
            $isLoading.set(false)
            $showSettings.set(true);
            return;
        }
        if (!$settings.value || !users.length || $oldSettings.value?.settings === $settings.value) {
            return;
        }
        const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
        const endDate = $dateRange.value.endDate.toISOString().split('T')[0];
        const user = users.find(x => x.userId === $selectedUser.value) || {}
        $userName.set(user?.firstName + ' ' + user?.lastName || '')
        $isLoading.set(true);

        UserService.overviewTrend(
            $selectedUser.value,
            'calories',
            $dateRange.value.dateInterval,
            startDate,
            endDate
        ).then((calories) => {
            $rawData.set((calories || [])
                .map(x => ({
                    label: dateAndMonth(parseDate(x.item1, 'YYYY-ww')),
                    value: x.item2
                })))
        })
            .finally(() => $isLoading.set(false))
            .catch(() => $showSettings.set(true))
    }, [$settings?.value, users.length])

    useEffect(() => {
        if (!$rawData.value) {
            return;
        }

        $reportData.set(
            [{
                label: t('userReport.chartData.calories'),
                exportSettings: {
                    xAxis: t('userReport.chartExportDataField.date'),
                    yAxis: t('userReport.chartData.calories'),
                },
                data: $rawData.value
            }]
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, $rawData.value.length]);
    return ($isLoading.value ?
        <div css={caloriesWidgetStyle.loadingContainer}>
            <CircularLoader />
        </div>
        :
        $showSettings.value && !$isLoading.value ?
            <div css={caloriesWidgetStyle.controls}>
                <SingleSelect
                    variant="outlined"
                    label={t('widgets.fieldName.user')}
                    design="slim"
                    $value={$selectedUser}
                >
                    {users.map(x => (
                        <SelectOption key={x.userId} value={x.userId}>
                            {`${x.firstName} ${x.lastName} (${x.username})`}
                        </SelectOption>
                    ))}
                </SingleSelect>
                <DateRangeSelector
                    label={t('userReport.controls.dateRange')}
                    $value={$dateRange}
                    design="slim"
                    variant="outlined"
                    labelProps={{
                        className: 'date-range-selector'
                    }}
                />
                <Button onClick={() => {
                    $showSettings.set(false);
                    saveConfiguration();
                }}>{t('actions.save')}</Button>
            </div>
            :
            $reportData.value.length > 0 &&
            <div css={caloriesWidgetStyle.root}>
                <div className="info">
                    <h2>{t('userReport.chartTitle.calories')}</h2>
                    <span>{$userName.value}</span>
                </div>
                <LineChart
                    title={t('userReport.chartTitle.calories')}
                    exportFileName={t('userReport.chartTitle.calories')}
                    enableOptionsMenu={!demoMode && !isEditMode}
                    datasets={$reportData.value}
                    additionalSettings={additionalSettings}
                />
            </div>
    )
}
