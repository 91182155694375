/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { ForceValidateContext, useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { SportService } from '../../../Services/SportService';
import { UserService } from '../../../Services/UserService';
import { TrashCanIcon } from '../../../CoreComponents/CustomIcons';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useMemo } from 'react';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { newGUID } from '../../../Utils/Common';
import { requiredValidator } from '../../../Utils/Validators';
import { AlertText } from '../../../CoreComponents/Alert';
import { DeleteConfirmationModal } from '../../../CoreComponents/DeleteConfirmationModal';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const styles = {
    root: css`
        display: flex;
        flex-direction: column;

        height: 85vh;
        padding: 40px 0 40px 28px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & h2 {
            margin-left: 12px;
            margin-top: 0;
        }
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    icon: css`
        color: #00ABA5
    `,
    input: css`
        width: 90%;
    `

}

export const UserSportsTab = ({ $selectedUser, isProfileOpened }) => {
    const { t } = useTranslation();
    const $refreshSports = useHook({});
    const $selectedSport = useObjectHook(null);
    const $sports = useHook([]);
    const $isLoading = useHook(true);
    const $isAddSportModalOpened = useHook(false);
    const $sportForDelete = useHook(null);

    const headCells = useMemo(() => [
        {
            id: 'sport',
            label: t('sports.table.sport'),
            isSortable: true,
            columnWidth: 295,
            CellRender: ({ rowData }) => {
                return rowData.sport || '-';
            }
        },
        {
            id: 'position',
            label: t('sports.table.position'),
            isSortable: true,
            columnWidth: 235,
            CellRender: ({ rowData }) => {
                return rowData.position || '-';
            }
        },
        {
            id: 'level',
            label: t('sports.table.level'),
            isSortable: true,
            columnWidth: 150,
            CellRender: ({ rowData }) => {
                return rowData.level || '-';
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData }) => {
                return <IconButton onClick={() => $sportForDelete.set(rowData)} tooltip={t('actions.delete')}>
                    <TrashCanIcon />
                </IconButton>
            }
        },
    ], []);

    const deleteSport = (userSportId) => {
        UserService.deleteUserSport(userSportId)
            .then(() => {
                $refreshSports.set({});
                $sportForDelete.set(null);
            });
    }

    const isRowSelected = row => row.userSportId === $selectedSport.value?.userSportId

    useEffect(() => {
        $isLoading.set(true);
        UserService.getUserSports($selectedUser.value.userId)
            .then(sports => {
                $sports.set(sports.map(x => ({
                    ...x,
                    sport: x.sport.name,
                    position: x.position.name,
                    level: x.sportLevel.name
                })));
                $isLoading.set(false);
            });
    }, [$refreshSports.value])

    return (
        <>
            <div css={styles.root}>
                <h2>{isProfileOpened ?
                    t('myProfile.editModal.title')
                    : t('users.editModal.title')
                }</h2>
                <TableCSF
                    headCells={headCells}
                    rows={$sports.value}
                    rowKeySelector={r => r.userSportId}
                    isLoading={$isLoading.value}
                    isRowSelected={isRowSelected}
                    customActions={
                        <Button onClick={() => $isAddSportModalOpened.set(true)} >{t('sports.addModal.title')}</Button>
                    }
                />
            </div>
            <Modal
                open={$isAddSportModalOpened.value}
                onClose={() => $isAddSportModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddSportContent
                    $selectedUser={$selectedUser}
                    onCancel={() => $isAddSportModalOpened.set(false)}
                    onAdd={() => {
                        $isAddSportModalOpened.set(false);
                        $refreshSports.set({});
                    }} />
            </Modal>
            <DeleteConfirmationModal
                onDelete={() => deleteSport($sportForDelete.value.userSportId)}
                open={Boolean($sportForDelete.value)}
                onCancel={() => $sportForDelete.set(null)}
                title={t('userSport.deleteModal.title')}
                description={t('userSport.deleteModal.description')}
                deleteLabel={t('actions.delete')}
            />
        </>
    )
}

const addSportModalContentStyles = {
    root: css`
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .fields-container {
            & > .input-root {
                margin: 0 0 30px 0;
                width: 100%;
            }
        }

        & > .actions {
            display: flex;
            margin: 0;
            margin-top: 20px;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }

        & > .alert-text-root {
            align-self: end;
        }
    `,
};

const AddSportContent = ({ $selectedUser, onCancel, onAdd }) => {
    const $isSaving = useHook(false);
    const $sports = useHook([]);
    const $positions = useHook([]);
    const $levels = useHook([]);
    const { t } = useTranslation();
    const $isForceValidating = useHook(false);
    const $newUserSport = useObjectHook(
        {
            userSportId: newGUID(),
            userId: $selectedUser.value.userId,
            sportId: '',
            positionId: '',
            sportLevelId: ''
        },
        {
            sportId: [requiredValidator],
            positionId: [requiredValidator],
            sportLevelId: [requiredValidator]
        },
        null,
        $isForceValidating.value
    );

    useEffect(() => {
        SportService.getAll()
            .then(sports => $sports.set(sports))

        SportService.getSportLevels()
            .then(levels => $levels.set(levels))
    }, [])

    useEffect(() => {
        if (!$newUserSport.value.sportId) {
            return;
        }

        SportService.getSportPositions($newUserSport.value.sportId)
            .then(positions => $positions.set(positions))
    }, [$newUserSport.value.sportId])

    const addSport = () => {
        $isForceValidating.set(true);
        const errors = $newUserSport.validate();
        if (errors) {
            return;
        }

        $isSaving.set(true);
        UserService.postUserSport($newUserSport.value)
            .then(() => onAdd($newUserSport.value))
            .finally(() => $isSaving.set(false));

    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addSportModalContentStyles.root}>
                <h2>{t('sports.addModal.title')}</h2>
                <div className="fields-container">
                    <SingleSelect $value={$newUserSport.getPropHook('sportId')} required label={t('sport.fieldName.sport')}>
                        {$sports.value.map(g => <SelectOption key={g.sportId} value={g.sportId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                    <SingleSelect $value={$newUserSport.getPropHook('positionId')} required label={t('sport.fieldName.position')}>
                        {$positions.value.map(g => <SelectOption key={g.positionId} value={g.positionId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                    <SingleSelect $value={$newUserSport.getPropHook('sportLevelId')} required label={t('sport.fieldName.level')}>
                        {$levels.value.map(g => <SelectOption key={g.sportLevelId} value={g.sportLevelId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                </div>

                {$newUserSport.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                <div className="actions" css={addSportModalContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value} onClick={addSport}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
