/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { ChevronLeftIcon, ChevronRightIcon, EnvelopeIcon, PrintIcon } from '../../../CoreComponents/CustomIcons';
import { CircularLoader } from '../../../CoreComponents/Loaders';
import { Modal } from '../../../CoreComponents/Modal';
import { useTranslation } from '../../../CoreComponents/Translation';
import { getCurrentSearchParams, useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { TestService } from '../../../Services/TestService';
import { UserService } from '../../../Services/UserService';
import { metersToFeet, metersToCentimeters, metersToInches } from '../../../Utils/Common';
import { AdminTypes, NoData, ReportSections, ReportSectionsTranslationKeysArr, UnitType, UnitTypeTranslationKeysArr, UnitTypeTranslationKeysObj } from '../../../Utils/Constants';
import { PrepareHistoryData, PrepareTestData } from './PrepareTestData';
import { Checkbox } from '../../../CoreComponents/Checkbox';
import { AlertText } from '../../../CoreComponents/Alert';
import { subMonths } from 'date-fns';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';
import { PrintWrapper } from './PrintWrapper';
import { TestReportBody } from './TestReportBody';
import { CompareModal } from './CompareModal';

/**
 * @template T
 * @typedef {import('../../../CoreComponents/Utils').Hook<T>} Hook<T>
 */

/**
 * @typedef {{
 *     testId: string,
 *     testName: string,
 *     username: string,
 *     userFullName: string,
 *     medicalRecordNumber?: string,
 *     providerFullName: string,
 *     startTime: string,
 *     duration: string,
 *     siteName: string,
 *     testStatus: string,
 *     activityName: string,
 *     testNotes: string,
 * }} TestInfo
 */

const testReportStyles = {
    loadingContainer: css`
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
    `,
    layout: css`
        display: flex;
        padding: 20px 40px;
        height: 100%;

        &.layout-print {
            display: flex;
            flex-direction: column;
        }

        & aside {
            display: flex;
            flex-direction: column;

            // display: none;
            // width: 448px;
            width: 400px;
            min-width: 360px;
            padding-right: 20px;
            border-right: 2px solid #CBCBCB;
            height: 100%;

            & .test-selection {
                display: flex;

                & > button {
                    font-size: 16px;
                    width: 1.75em;
                    height: 1.75em;

                    margin: auto 0 auto 16px;

                    background: #00ABA5 0% 0% no-repeat padding-box;
                    color: #FFFFFF;

                    &:disabled {
                        background: #CBCBCB 0% 0% no-repeat padding-box;
                    }
                }
                & > button:first-of-type {
                    margin-left: auto;
                }
            }

            & .test-info {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow-y: auto;
                padding-right: 20px;

                & > div:last-of-type {
                    flex: 1 0;
                }

                & dl {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    // width: 300px;      /* set the container width*/
                    overflow: visible;

                    // & * {
                    //     outline: 1px solid green;
                    // }

                    & dt {
                        float: left;
                        clear: left;
                        width: calc(40% - 20px);

                        text-align: right;
                        font: normal normal normal 16px/24px Roboto;
                        letter-spacing: 0.14px;
                        color: #858585;
                    }
                    & dd {
                        margin: 0 0 0 40px;
                        padding: 0 0 11px 0;
                        width: calc(60% - 20px);

                        text-align: left;
                        font: normal normal bold 16px/24px Mustica Pro;
                        letter-spacing: 0.14px;
                        color: #676767;
                    }
                }

                & .notes {
                    background: rgba(0, 0, 0, 0.08) 0% 0% no-repeat padding-box;
                    box-shadow: 0px 8px 16px #28326529;
                    border-radius: 8px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    height: calc(100% - 56px);
                    max-height: 400px;

                    & pre {
                        font: normal normal normal 16px/24px Roboto;
                        color: #676767;
                        padding: 20px;
                        white-space: pre-wrap;
                        margin: 0 0 40px 0;
                        // height: calc(100% - 2 * 20px - 40px);
                        flex: 1 0;
                        overflow: auto;
                    }

                    & textarea {
                        font: normal normal normal 16px/24px Roboto;
                        color: #676767;
                        padding: 20px;
                        margin: 0 0 40px 0;
                        flex: 1 0;

                        background: rgba(0, 0, 0, 0.08) 0% 0% no-repeat padding-box;
                        resize: none;
                        border: none;
                    }

                    & .edit-note-controls {
                        margin-left: auto;
                        margin-top: auto;

                        & > button {
                            min-width: unset;
                        }

                        & > button:nth-of-type(n + 2) {
                            margin-left: 20px;
                        }
                    }
                }
            }

            & .test-step-comparison {
                display: flex;
                flex-direction: column;
                height: auto;
                padding-right: 20px;

                & > div:last-of-type {
                    flex: 1 0;
                }

                & dl {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    overflow: visible;

                    & div {
                        display: flex;
                        width: 100%
                    }

                    & dt {
                        float: left;
                        clear: left;
                        width: calc(40% - 20px);

                        text-align: right;
                        font: normal normal normal 16px/24px Roboto;
                        letter-spacing: 0.14px;
                        color: #858585;
                    }
                    & dd {
                        margin: 0 0 0 40px;
                        padding: 0 0 11px 0;
                        width: calc(60% - 20px);

                        text-align: left;
                        font: normal normal bold 16px/24px Mustica Pro;
                        letter-spacing: 0.14px;
                        color: #676767;
                    }
                }
            }
        }

        // TODO: debug
        @media (max-width: 1200px) {
            height: 100%;
            display: block;
            overflow: auto;

            & aside {
                height: auto;
                width: 100%;
                border-right: none;

                & .test-info {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                    grid-gap: 20px;
                    margin-bottom: 20px;
                }

                & .test-step-comparison {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                    grid-gap: 20px;
                    margin-bottom: 20px;
                }
            }
        }

        @media (max-width: 550px) {
            padding: 5px;
        }

        @media print {
            height: auto;
            display: block;
            // flex-direction: column;

            & aside {
                width: 100%;
                height: auto;
                border-right: none;

                & .test-info {
                    //break-inside: avoid;
                    height: auto;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                    grid-gap: 20px;
                    margin-bottom: 20px;
                    margin-bottom: auto;
                    padding-bottom: 40px;
                    overflow: unset;

                    & .test-notes {
                        margin-bottom: 80px;

                        // & > h1 {
                        //     position: absolute;
                        //     top: 100px;
                        // }
                    }

                    & .notes {
                        max-height: unset;

                        & pre {
                            margin: 0;
                        }
                    }
                }

                & .test-step-comparison {
                    display: flex;
                    margin-bottom: 20px;
                    margin-bottom: auto;
                    padding-bottom: 40px;
                    overflow: unset;
                    width: 50%;
                    height: auto;
                }
            }

            & .test-selection > button, .edit-note-controls {
                display: none;
            }
        }
    `,
    testContent: css`
        // width: 100%;
        width: calc(100% - 360px);
        height: 100%;
        overflow: clip;
        display: flex;
        flex-direction: column;

        .low, .improvement {
            color: forestgreen;
        }

        .medium {
            color: #FFC107;
        }

        .high, .degradation {
            color: red;
        }

        & .content {
            padding: 0 40px;
            // width: calc(100vw - 448px - 40px);

            & .date-picker {
                display: flex;
                justify-content: flex-end;
            }

            & .no-records {
                margin-top: 80px;
                text-align: center;
                width: 100%;
            }
        }

        & .content.no-result {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
        }

        & .step-disclaimer {
            display: none;
        }

        & .table-section {
            margin-bottom: 40px;
            break-inside: avoid;
        }

        & .controls {
            gap: 20px 0;
            padding: 0 40px 50px 40px;
            background: #EEEEEE 0% 0% no-repeat padding-box;

            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & > button {
                width: unset;
                margin-right: 20px;
                &.go-back-action {
                    margin-right: 20px;
                    width: 48px;
                    min-width: unset;
                }

                &.print-action {
                    min-width: unset;
                    margin-right: 10px;
                }

                &.email-action {
                    min-width: unset;
                    margin-right: 20px;
                }
            }

            & > .sections {
                height: 40px;
                display: flex;
                margin-right: auto;

                & > button {
                    border-radius: 0;
                    border: 2px solid #00ABA5;
                    color: #00ABA5;
                }

                & > button.active {
                    color: #FFFFFF;
                    background: #00ABA5 0% 0% no-repeat padding-box;
                }

                & > button:first-of-type {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                & > button:last-of-type {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }

        // TODO: debug
        @media (max-width: 1200px) {
            overflow: unset;
            width: 100%;
            height: auto;

            & .content {
                height: auto;
                padding: 1px;
                overflow: unset;
            }
        }

        @media print {
            overflow: unset;
            width: 100%;
            height: auto;
            display: block;

            & .controls {
                display: none;
            }

            & .content {
                height: auto;
                padding: 1px;
                overflow: unset;

                & > h2 {
                    page-break-after: avoid;
                }
            }

            & .step-disclaimer {
                display: block;
            }

            td:nth-child(n+14), th:nth-child(n+14) {
                display: none;
            }
        }
    `,
    printOptions: css`
        padding: 40px 0 40px 40px;
        width: 400px;

        & > h2 {
            margin: 0;
        }

        display: flex;
        flex-direction: column;
        gap: 10px 0;

        & > .options {
            height: 120px;
            display: flex;
            align-content: space-between;
            flex-wrap: wrap;
            flex-direction: column;
            margin: 40px 0 40px 0;
            gap: 20px;
        }
        & .error {
            color: #E0758A;
        }
        & > .actions {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            gap: 20px;

            & .loading {
                display: flex;
                margin-left: auto;
            }
        }
    `,
};

export const TestReport = () => {
    const { testId } = useParams();
    const {
        unitType: unitTypeParam,
        isUnityView: isUnityWebView,
        enablePrint
    } = getCurrentSearchParams();
    const navigate = useNavigate();
    const testContentRef = useRef(null);
    const { t, /* tWithHtml */ } = useTranslation();
    const storeState = useSelector((state) => state.storeState);

    const $isLoading = useHook(true);
    const $isLoadingHistory = useHook(true);
    const $isCompareModalLoading = useHook(true);
    const $dateRangeHistory = useObjectHook({
        dateInterval: 'week',
        startDate: subMonths(new Date(), 12),
        endDate: new Date(),
    });

    const $isPrinting = useHook(enablePrint);
    const $isPrintOptionsOpened = useHook(false);
    const $isEmailOptionsOpened = useHook(false);
    const $isCompareSteps = useHook(false);
    const $enabledTestInfo = useHook(true);
    const $enabledTestSummary = useHook(true);
    const $enabledTestDetails = useHook(true);
    const $enabledTestNotes = useHook(true);
    const $enabledTimeline = useHook(true);
    const $enabledHistory = useHook(true);
    const $showAllSections = useHook(enablePrint);
    const $sendProviderEmail = useHook(true);
    const $sendUserEmail = useHook(true);
    const $showEmailButton = useHook(false);

    const $tests = useHook(null);
    const $baselineTest = useHook(null);
    const $selectedTests = useHook([]);
    const $selectedSteps = useHook([]);
    const $scriptedActivityName = useHook(null);
    const $scriptedActivitySteps = useHook([]);
    const $availableSteps = useHook({});
    const $testsCompareModal = useHook([]);
    const $selectedTab = useHook(1);

    const $isSelectingOtherTests = useHook(false);
    const $isSettingBaseline = useHook(false);
    const $selectedTestsModal = useHook([]);
    const $selectedStepsModal = useHook([]);
    const $selectedTestsIds = useHook([]);

    const $testInfoIndex = useHook(0);
    /** @type Hook<Array<TestInfo>> */
    const $testInfos = useHook([]);
    const $isEditingNotes = useHook(false);
    const $testNotes = useHook('');
    const $testReportData = useHook([]);
    const $historyData = useHook({});
    const $trackedMetrics = useHook({});

    const $hasTimelineDataBySAStepId = useHook({});
    const $selectedSection = useHook(ReportSections.Details);
    const $selectedTimelineInterval = useHook('10s');
    const $selectedTestStep = useHook(null);
    const $selectedUnitType = useHook(
        UnitTypeTranslationKeysObj[unitTypeParam] ?
            Number(unitTypeParam)
            : UnitType.Feet
    );

    const isFirefox = !navigator.userAgent.match(/firefox|fxios/i);

    const missTypes = useMemo(() => [
        t('constants.missTypes.leaningOrSway'),
        t('constants.missTypes.stumbleOrFall'),
        t('constants.missTypes.leftHandOffHip'),
        t('constants.missTypes.rightHandOffHip'),
        t('constants.missTypes.step'),
        t('constants.missTypes.leftToeRaised'),
        t('constants.missTypes.rightToeRaised'),
        t('constants.missTypes.leftHeelRaised'),
        t('constants.missTypes.rightHeelRaised'),
        t('constants.missTypes.plantToeRaised'),
        t('constants.missTypes.plantHeelRaised'),
        t('constants.missTypes.freeFootDropped'),
        t('constants.missTypes.freeAnkleDropped'),
        t('constants.missTypes.freeKneeTooHigh'),
        t('constants.missTypes.freeKneeTooFar'),
    ], [t]);
    // Determines what type of miss occurred during the activity
    const getBalanceMissDetailedMessage = (missType) =>
        missTypes[missType] || missTypes[0];

    const unitTypeShort = $selectedUnitType.value === UnitType.Meters ?
        t('constants.unitType.metersShort')
        : t('constants.unitType.feetShort');

    const unitTypeSmall = $selectedUnitType.value === UnitType.Meters ?
        t('constants.unitType.centimetersShort')
        : t('constants.unitType.inchShort');

    const unitPerSecond = $selectedUnitType.value === UnitType.Meters ?
        t('constants.units.m/s')
        : t('constants.units.ft/s');

    // const unitPerSecondSquared = $selectedUnitType.value === UnitType.Meters ?
    //     tWithHtml('sup', 'constants.units.m/s^2')
    //     : tWithHtml('sup', 'constants.units.ft/s^2');
    const unitPerSecondSquared = $selectedUnitType.value === UnitType.Meters ?
        t('constants.units.m/s^2')
        : t('constants.units.ft/s^2');

    useEffect(() => {
        $isLoadingHistory.set(true);

        const startDate = $dateRangeHistory.value.startDate.toISOString().split('T')[0];
        const endDate = $dateRangeHistory.value.endDate.toISOString().split('T')[0];

        TestService.getHistoryData(testId, startDate, endDate)
            .then(data => {
                $tests.set(data)
                $isLoadingHistory.set(false);
            });
    }, [$dateRangeHistory.value])

    useEffect(() => {
        if (!$isSelectingOtherTests.value) {
            return
        }

        $isCompareModalLoading.set(true);

        TestService.getCompareModalTests(testId)
            .then(tests => {
                $testsCompareModal.set(tests);
                $isCompareModalLoading.set(false);
                $baselineTest.set(tests.find(x => x.isBaseline));
            })
    }, [$isSelectingOtherTests.value])

    useEffect(() => {
        $isLoading.set(true);
        TestService.getTestReportData($selectedTestsIds.value?.length ? $selectedTestsIds.value : [testId])
            .then(data => {
                const currentTest = data.find(x => x.testId === testId) || data[0];
                const steps = currentTest.scriptedActivity
                    .scriptedActivitySteps;

                $scriptedActivityName.set(currentTest.scriptedActivity.name);
                
                $scriptedActivitySteps.set(steps.sort((a, b) => {
                    if (a.orderId > b.orderId) return 1;
                    if (a.orderId < b.orderId) return -1;
                    return 0;
                }));

                const stepsForCompare = currentTest.testSteps.map(x => {
                    const filteredSteps = steps.filter(x => currentTest.testSteps.map(y => y.scriptedActivityStepId).includes(x.scriptedActivityStepId));
                    const currentStep = filteredSteps.find(y => y.scriptedActivityStepId === x.scriptedActivityStepId);
                    return ({
                        ...x,
                        orderId: currentStep.orderId,
                        name: currentStep.name,
                        activityTypeId: currentStep.activityTypeId,
                        test: currentTest
                    })
                });

                $selectedSteps.set(stepsForCompare);
                $selectedTests.set(data);

                $isLoading.set(false);
            })
    }, [$selectedTestsIds.value]);

    useEffect(() => {
        if (!$tests.value) {
            return
        }

        const convertUnits = (metersValue, fractionDigits = 2) =>
            ($selectedUnitType.value === UnitType.Meters ?
                metersValue
                : metersToFeet(metersValue)
            ).toFixed(fractionDigits)

        const convertSmallUnits = (metersValue, fractionDigits = 2) =>
            metersValue !== null ? ($selectedUnitType.value === UnitType.Meters ?
                metersToCentimeters(metersValue).toFixed(fractionDigits)
                : metersToInches(metersValue).toFixed(fractionDigits)
            ) : NoData

        const jumpSquatFormatter = (jumpSquatValue, fractionDigits = 2) =>
            `${convertSmallUnits(jumpSquatValue, fractionDigits)} ${unitTypeSmall}`

        const historyData = PrepareHistoryData({
            convertSmallUnits,
            jumpSquatFormatter,
            convertUnits,
            allTests: $tests.value,
        })
        $historyData.set(historyData);
    }, [$tests.value, $selectedUnitType.value])

    useEffect(() => {
        if (!$selectedTests.value || !$selectedTests.value.length) {
            return;
        }

        const convertUnits = (metersValue, fractionDigits = 2) =>
            ($selectedUnitType.value === UnitType.Meters ?
                metersValue
                : metersToFeet(metersValue)
            ).toFixed(fractionDigits)

        const convertSmallUnits = (metersValue, fractionDigits = 2) =>
            metersValue !== null ? ($selectedUnitType.value === UnitType.Meters ?
                metersToCentimeters(metersValue).toFixed(fractionDigits)
                : metersToInches(metersValue).toFixed(fractionDigits)
            ) : NoData

        const distanceFormatter = (distanceValue, fractionDigits = 2) =>
            `${convertUnits(distanceValue, fractionDigits)} ${unitTypeShort}`
        const speedFormatter = (speedValue, fractionDigits = 2) =>
            `${convertUnits(speedValue, fractionDigits)} ${unitPerSecond}`
        const accelerationFormatter = (accelerationValue, fractionDigits = 2) =>
            `${convertUnits(accelerationValue, fractionDigits)} ${unitPerSecondSquared}`
        const jumpSquatFormatter = (jumpSquatValue, fractionDigits = 2) =>
            `${convertSmallUnits(jumpSquatValue, fractionDigits)} ${unitTypeSmall}`

        const {
            testInfos,
            hasTimelineDataBySAStepId,
            selectedTestsReportData,
            availableSteps,
            firstAvailableStep,
            trackedMetrics,
        } = PrepareTestData({
            t,
            convertUnits,
            getBalanceMissDetailedMessage,
            distanceFormatter,
            speedFormatter,
            accelerationFormatter,
            jumpSquatFormatter,
            allScriptedActivitySteps: $scriptedActivitySteps.value,
            selectedTests: $selectedTests.value,
            selectedTimelineInterval: $selectedTimelineInterval.value,
            isCompareSteps: $isCompareSteps.value
        });

        $availableSteps.set(availableSteps);
        if (!availableSteps[$selectedTestStep.value]) {
            $selectedTestStep.set(firstAvailableStep);
            $selectedSection.set(ReportSections.Details);
        }

        $testInfos.set(
            $isCompareSteps.value ?
                [testInfos[0]]
                : testInfos
        );
        $hasTimelineDataBySAStepId.set(hasTimelineDataBySAStepId);
        $testReportData.set(selectedTestsReportData);
        $testInfoIndex.set(0);
        $trackedMetrics.set(trackedMetrics);
    }, [t, $selectedTests.value, $selectedUnitType.value, $isCompareSteps.value, $selectedTimelineInterval.value])

    useEffect(() => {
        if(!$testInfos.value.length) {
            return;
        }

        UserService.getUser($testInfos.value[0].testRef.userId)
            .then(data => {
                $showEmailButton.set(data.admin && data.admin.adminTypeId === AdminTypes.User);
            });
    }, [$testInfos.value])

    /**
     * @param {TestInfo} testInfo
     */
    const updateTestNotes = (testInfo) => {
        const noteData = [{
            path: '/testNotes',
            op: 'replace',
            value: $testNotes.value
        }];
        TestService
            .patchTest(testInfo.testId, noteData)
            .then(() => {
                const testIndex = $selectedTests.value.findIndex(x => x.testId === testInfo.testId);
                const testNote = $testNotes.value ?
                    {
                        content: $testNotes.value,
                        dateModified: new Date(),
                        modifiedBy: storeState.currentUser,
                        createdBy: $selectedTests.value[testIndex].testNote?.createdBy || storeState.currentUser,
                        dateCreated: $selectedTests.value[testIndex].testNote?.dateCreated || new Date()
                    } : null;
                const newTestData = {
                    ...$selectedTests.value[testIndex],
                    testNote: testNote,
                };
                const newTestsState = $selectedTests.value
                    .slice(0, testIndex)
                    .concat([newTestData])
                    .concat($selectedTests.value.slice(testIndex + 1));

                $selectedTests.set(newTestsState);
                $isEditingNotes.set(false);
            });
    };

    const currentIndex = $testInfoIndex.value;
    return ($isLoading.value || !$testReportData.value ?
        <div css={testReportStyles.loadingContainer}>
            <CircularLoader />
        </div>
        :
        <PrintWrapper isPrinting={$isPrinting.value} isFirefox={isFirefox}>
            <div css={testReportStyles.layout} className={$isPrinting.value && isFirefox && 'layout-print'}>
                {(($isPrinting.value && $enabledTestInfo.value) || !$isPrinting.value) &&
                    <aside >
                        {$testInfos.value.length > 1 &&
                            <div className="test-step-comparison">
                                <h1>{t('testReport.section.testComparison')}</h1>
                                <dl>
                                    {$testInfos.value.map((testInfo, index) => (
                                        <div key={index}>
                                            <dt>{t('testReport.testComparison.testDate', testInfo.testName)}</dt>
                                            <dd>{testInfo.startTime}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        }

                        {$isCompareSteps.value &&
                            <div className="test-step-comparison">
                                <h1>{t('testReport.section.stepComparison')}</h1>
                                <dl>
                                    {$scriptedActivitySteps.value.map((sas, index) => (
                                        $availableSteps.value[sas.scriptedActivityStepId] &&
                                        <div key={index}>
                                            <dt>{t('testReport.stepComparison.stepName', sas.orderId)}</dt>
                                            <dd>{sas.name}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        }

                        <div>
                            <div className="test-selection">
                                <h1>{t('testReport.section.testInformation')}</h1>
                                {$selectedTests.value.length > 1 ?
                                    <>
                                        <IconButton
                                            tooltip={t('testReport.actions.previousTest')}
                                            disabled={currentIndex < 1}
                                            onClick={() => $testInfoIndex.set(currentIndex - 1)}
                                        >
                                            <ChevronLeftIcon />
                                        </IconButton>
                                        <IconButton
                                            tooltip={t('testReport.actions.nextTest')}
                                            disabled={currentIndex >= ($testInfos.value.length - 1)}
                                            onClick={() => $testInfoIndex.set(currentIndex + 1)}
                                        >
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </>
                                    : null}
                            </div>
                        </div>

                        {$testInfos.value.map((testInfo, index) => (
                            (index === currentIndex || $isPrinting.value) &&
                            <div className="test-info" key={testInfo.testId} style={($isPrinting.value && !$enabledTestNotes.value) ? {width: '50%'} : {width: '100%'}}>
                                {/* {rd.testId} */}
                                <dl>
                                    {$testInfos.value.length > 1 &&
                                        <>
                                            <dt>{t('testReport.testInfo.testNumber')}</dt>
                                            <dd>{testInfo.testName}</dd>
                                        </>
                                    }
                                    <dt>{t('testReport.testInfo.username')}</dt>
                                    <dd>{testInfo.username}</dd>
                                    <dt>{t('testReport.testInfo.name')}</dt>
                                    <dd>{testInfo.userFullName}</dd>
                                    {testInfo.medicalRecordNumber &&
                                        <>
                                            <dt>{t('testReport.testInfo.medicalRecordNumber')}</dt>
                                            <dd>{testInfo.medicalRecordNumber}</dd>
                                        </>
                                    }
                                    {testInfo.providerFullName &&
                                        <>
                                            <dt>{t('testReport.testInfo.provider')}</dt>
                                            <dd>{testInfo.providerFullName}</dd>
                                        </>
                                    }
                                    <dt>{t('testReport.testInfo.date')}</dt>
                                    <dd>{testInfo.startTime}</dd>
                                    <dt>{t('testReport.testInfo.duration')}</dt>
                                    <dd>{testInfo.duration}</dd>
                                    <dt>{t('testReport.testInfo.location')}</dt>
                                    <dd>{testInfo.siteName}</dd>
                                    <dt>{t('testReport.testInfo.status')}</dt>
                                    <dd>{testInfo.testStatus}</dd>
                                    <dt>{t('testReport.testInfo.scriptedActivity')}</dt>
                                    <dd>{testInfo.activityName}</dd>
                                </dl>
                                {(($isPrinting.value && $enabledTestNotes.value) || !$isPrinting.value) &&
                                    <div className="test-notes">
                                        <h1>{t('testReport.section.testNotes')}</h1>
                                        {testInfo.testNotes &&
                                            <dl>
                                                <dt>{t('testReport.testNotes.createdBy')}</dt>
                                                <dd>{testInfo.testNotes.createdBy.firstName + ' ' + testInfo.testNotes.createdBy.lastName}</dd>
                                                <dt>{t('testReport.testNotes.modifiedBy')}</dt>
                                                <dd>{testInfo.testNotes.modifiedBy.firstName + ' ' + testInfo.testNotes.modifiedBy.lastName}</dd>
                                                <dt>{t('testReport.testNotes.dateModified')}</dt>
                                                <dd>{testInfo.testNotes.dateModified}</dd>
                                            </dl>
                                        }
                                        <div className="notes">
                                            {$isEditingNotes.value && !$isPrinting.value ?
                                                <>
                                                    <textarea
                                                        value={$testNotes.value}
                                                        onChange={e => $testNotes.set(e.target.value)}
                                                        placeholder={t('input.placeholder')}
                                                    />
                                                    <div className="edit-note-controls">
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => $isEditingNotes.set(false)}
                                                        >
                                                            {t('actions.cancel')}
                                                        </Button>
                                                        <Button onClick={() => updateTestNotes(testInfo)}>
                                                            {t('actions.done')}
                                                        </Button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <pre>{testInfo.testNotes?.content || null}</pre>
                                                    <Button
                                                        className="edit-note-controls"
                                                        onClick={() => {
                                                            $isEditingNotes.set(true);
                                                            $testNotes.set(testInfo.testNotes?.content || '');
                                                        }}
                                                    >
                                                        {t('actions.edit')}
                                                    </Button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </aside>
                }

                <div css={testReportStyles.testContent}>
                    <div className="controls">
                        <Button
                            className="go-back-action"
                            disabled={Boolean(isUnityWebView)}
                            onClick={() => window.history.length > 2 ?
                                navigate(-1)
                                : navigate('/manage/tests')
                            }
                        >
                            <ChevronLeftIcon />
                        </Button>
                        <div className="sections">
                            {ReportSectionsTranslationKeysArr.map(x => {
                                if ((!$hasTimelineDataBySAStepId.value[$selectedTestStep.value] && x.value === ReportSections.Timeline) || 
                                    (storeState.currentUser.admin.adminTypeId === AdminTypes.User && x.value === ReportSections.Timeline)) {
                                    return null;
                                }

                                return (
                                    <Button
                                        key={x.value}
                                        {...($selectedSection.value !== x.value ?
                                            { variant: 'outlined' }
                                            : { className: 'active' }
                                        )}
                                        onClick={() => {
                                            $selectedSection.set(x.value);
                                            if (testContentRef.current) {
                                                testContentRef.current.scrollTop = 0;
                                            }
                                        }}
                                    >
                                        {t(x.translationKey)}
                                    </Button>
                                )
                            })}
                        </div>
                        {$selectedSection.value === ReportSections.Timeline &&
                            <SingleSelect
                                label={t('testReport.controls.timelineInterval')}
                                design="slim"
                                $value={$selectedTimelineInterval}
                                disableFiltering
                            >
                                <SelectOption key='10s' value='10s'>
                                    10s
                                </SelectOption>
                                <SelectOption key='0.5s' value='0.5s'>
                                    0.5s
                                </SelectOption>
                            </SingleSelect>
                        }
                        <SingleSelect
                            label={t('testReport.controls.step')}
                            design="slim"
                            $value={$selectedTestStep}
                            onChange={value => {
                                if ($selectedSection.value === ReportSections.Timeline
                                    && !$hasTimelineDataBySAStepId.value[value]
                                ) {
                                    $selectedSection.set(ReportSections.Details);
                                }
                            }}
                        >
                            {$scriptedActivitySteps.value.map(sas => (
                                $availableSteps.value[sas.scriptedActivityStepId] &&
                                <SelectOption
                                    key={sas.scriptedActivityStepId}
                                    disabled={!$availableSteps.value[sas.scriptedActivityStepId]}
                                    value={sas.scriptedActivityStepId}
                                >
                                    {sas.name}
                                </SelectOption>
                            ))}
                        </SingleSelect>
                        <SingleSelect
                            label={t('testReport.controls.unitType')}
                            design="slim"
                            $value={$selectedUnitType}
                            disableFiltering
                        >
                            {UnitTypeTranslationKeysArr.map(x => (
                                <SelectOption key={x.value} value={x.value}>
                                    {t(x.translationKey)}
                                </SelectOption>
                            ))}
                        </SingleSelect>
                        <Button onClick={() => {
                            $isSelectingOtherTests.set(true);
                        }}>
                            {t('actions.compare')}
                        </Button>
                        <CompareModal
                            $isSelectingOtherTests={$isSelectingOtherTests}
                            $isSettingBaseline={$isSettingBaseline}
                            $selectedSteps={$selectedSteps}
                            $selectedTab={$selectedTab}
                            $isCompareModalLoading={$isCompareModalLoading}
                            $testsCompareModal={$testsCompareModal}
                            $selectedTestsModal={$selectedTestsModal}
                            $isCompareSteps={$isCompareSteps}
                            $selectedTestsIds={$selectedTestsIds}
                            $selectedStepsModal={$selectedStepsModal}
                            $selectedTests={$selectedTests}
                            $baselineTest={$baselineTest}
                        />
                        <Button
                            className="print-action"
                            disabled={$isPrinting.value}
                            onClick={() => {
                                $isPrintOptionsOpened.set(true);
                            }}
                        >
                            <PrintIcon />
                        </Button>
                        {$showEmailButton.value &&
                            <Button
                                className="email-action"
                                disabled={$isPrinting.value}
                                onClick={() => {
                                    $isEmailOptionsOpened.set(true);
                                }}
                            >
                                <EnvelopeIcon />
                            </Button>
                        }
                        <Modal
                            open={$isPrintOptionsOpened.value}
                            onClose={() => $isPrintOptionsOpened.set(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            disableCloseButton
                        >
                            <div css={testReportStyles.printOptions}>
                                <h2>{t('testReport.print.selectOptions')}</h2>
                                <div className="options">
                                    <Checkbox label={t('testReport.print.testInfo')} checked={$enabledTestInfo.value} onChange={value => $enabledTestInfo.set(value)}></Checkbox>
                                    <Checkbox label={t('testReport.print.testNotes')} checked={$enabledTestInfo.value && $enabledTestNotes.value} onChange={value => $enabledTestNotes.set(value)}></Checkbox>
                                    <Checkbox label={t('testReport.print.testSummary')} checked={$enabledTestSummary.value} onChange={value => $enabledTestSummary.set(value)}></Checkbox>
                                    <Checkbox label={t('testReport.print.testDetails')} checked={$enabledTestDetails.value} onChange={value => $enabledTestDetails.set(value)}></Checkbox>
                                    <Checkbox label={t('testReport.print.testTimeline')} checked={$enabledTimeline.value} onChange={value => $enabledTimeline.set(value)}></Checkbox>
                                    <Checkbox label={t('testReport.print.testHistory')} checked={$enabledHistory.value} onChange={value => $enabledHistory.set(value)}></Checkbox>
                                </div>
                                {!$enabledTestInfo.value && !$enabledTestSummary.value && !$enabledTestDetails.value && !$enabledTimeline.value && !$enabledHistory.value &&
                                    <AlertText variant="large">{t('testReport.print.errorMessage')}</AlertText>}
                                <div className="actions">
                                    <Button variant="outlined" onClick={() => $isPrintOptionsOpened.set(false)}>{t('actions.cancel')}</Button>
                                    <Button
                                        disabled={!$enabledTestInfo.value && !$enabledTestSummary.value && !$enabledTestDetails.value}
                                        onClick={() => {
                                            $isPrintOptionsOpened.set(false);
                                            $isPrinting.set(true);
                                            $showAllSections.set(true);
                                            $isEditingNotes.set(false);
                                            setTimeout(function () {
                                                window.print();
                                                $showAllSections.set(false);
                                                $isPrinting.set(false);
                                            }, $scriptedActivitySteps.value.length * 200);
                                        }}
                                    >
                                        {t('actions.print')}</Button>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            open={$isEmailOptionsOpened.value}
                            onClose={() => $isEmailOptionsOpened.set(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            disableCloseButton
                        >
                            <div css={testReportStyles.printOptions}>
                                <h2>{t('testReport.email.selectOptions')}</h2>
                                <div className="options">
                                    <Checkbox label={t('testReport.email.providerOption')} checked={$sendProviderEmail.value} onChange={value => $sendProviderEmail.set(value)}></Checkbox>
                                    <Checkbox label={t('testReport.email.userOption')} checked={$sendUserEmail.value} onChange={value => $sendUserEmail.set(value)}></Checkbox>
                                </div>
                                <div className="actions">
                                    <Button variant="outlined" onClick={() => $isEmailOptionsOpened.set(false)}>{t('actions.cancel')}</Button>
                                    <Button
                                        disabled={!$sendProviderEmail.value && !$sendUserEmail.value}
                                        onClick={() => {
                                            TestService.getReportEmail(testId, $sendUserEmail.value ? $testInfos.value[0].testRef.userId : null, $sendProviderEmail.value ? storeState.currentUser.userId : null)
                                                .then(() => {
                                                    $isEmailOptionsOpened.set(false);
                                                });
                                        }}
                                    >
                                        {t('actions.send')}</Button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <TestReportBody
                        testContentRef={testContentRef}
                        unitTypeParam={unitTypeParam}
                        $selectedTests={$selectedTests}
                        $scriptedActivityName={$scriptedActivityName}
                        $scriptedActivitySteps={$scriptedActivitySteps}
                        $testReportData={$testReportData}
                        $isPrinting={$isPrinting}
                        $selectedTestStep={$selectedTestStep}
                        $historyData={$historyData}
                        $trackedMetrics={$trackedMetrics}
                        $selectedSection={$selectedSection}
                        $enabledTestSummary={$enabledTestSummary}
                        $enabledTestDetails={$enabledTestDetails}
                        $showAllSections={$showAllSections}
                        $dateRangeHistory={$dateRangeHistory}
                        $isLoadingHistory={$isLoadingHistory}
                        $enabledHistory={$enabledHistory}
                        $enabledTimeline={$enabledTimeline}
                        $selectedUnitType={$selectedUnitType}
                    />
                </div>
            </div>
        </PrintWrapper>
    );
};
