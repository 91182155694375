/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { CustomerService } from '../../../Services/CustomerService';
import { SiteService } from '../../../Services/SiteService';
import { Button } from '../../../CoreComponents/Button';
import { AdminTypes, AdminTypesTranslationKeysArr } from '../../../Utils/Constants';
import {
    minLengthValidator, requiredValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { RadioGroup, RadioOption } from '../../../CoreComponents/RadioSelection';
import { AdminService } from '../../../Services/AdminService';
import { UserService } from '../../../Services/UserService';
import { AlertText } from '../../../CoreComponents/Alert';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';
import { MultiSelect, MultiSelectOption } from '../../../CoreComponents/SelectListComponents/MultiSelect';

const addUserModalContentStyles = {
    root: css`
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > .input-root {
            display: inline-flex;
            margin: 30px 0 0 0;
            width: 100%;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .alert-text-root {
            margin: 0 0 20px 0;
            align-self: end;
        }

        & > .actions {
            display: flex;
            margin: 0;
            margin-top: 20px;
            width: 100%;

            & > button:first-of-type {
                margin-right: 20px;
                margin-left: auto;
            }
        }
    `,
    missingEmailOption: css`
        color: red;
    `
};

export const AddAdminContent = ({ onCancel, onAdd }) => {
    const storeState = useSelector((state) => state.storeState);
    const { t } = useTranslation();
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $users = useHook([]);
    const $selectedUsers = useHook([], minLengthValidator(1));
    const $isForceValidating = useHook(false);
    const $isSaving = useHook(false);
    const $errorMessage = useHook('');

    const $newAdmin = useObjectHook(
        {
            adminTypeId: 1,
            customerId: storeState.currentUser.admin?.customerId || '',
            adminSites: [],
        },
        {
            adminTypeId: [requiredValidator],
            customerId: [requiredValidator]
        },
        (oldState, newState) => {
            const validationResult = minLengthValidator(1)(newState.adminSites);
            if ((newState.adminTypeId === AdminTypes.Operator || newState.adminTypeId === AdminTypes.Provider || newState.adminTypeId === AdminTypes.SiteAdmin) && validationResult.length) {
                return { adminSites: validationResult };
            }
        },
        $isForceValidating.value
    );

    const $availableRoles = useHook(AdminTypesTranslationKeysArr
        .filter(r => (r.value <= storeState.currentUser.admin.adminTypeId || Number(r.value) === AdminTypes.Operator) && r.value !== 0));


    useEffect(() => {
        CustomerService.getAll(9999, 'Name', true)
            .then($customers.set);
    }, []);

    useEffect(() => {
        SiteService.getAll(9999, $newAdmin.value.customerId, null, 'Name', true)
            .then($sites.set);
    }, [$newAdmin.value.customerId]);

    useEffect(() => {
        UserService.getAll(9999, null, null, $newAdmin.value.customerId, $newAdmin.value.adminSites.length ? $newAdmin.value.adminSites : null)
            .then(users => {
                $users.set(users.filter(user => !user.admin && user.isActive));
            })
    }, [$newAdmin.value.customerId, $newAdmin.value.adminSites]);

    useEffect(() => {
        $errorMessage.set('');
    }, [$newAdmin.value]);

    const addAdmin = () => {
        $isForceValidating.set(true);
        const errors = [];
        const siteErrors = $newAdmin.validate();
        if (($newAdmin.value.adminTypeId === AdminTypes.Operator
            || $newAdmin.value.adminTypeId === AdminTypes.Provider
            || $newAdmin.value.adminTypeId === AdminTypes.SiteAdmin) && siteErrors) {
            errors.concat(siteErrors)
        }

        if (errors.length) {
            return;
        }

        $selectedUsers.value.forEach(userId => {
            const admin = {
                adminId: userId,
                email: $users.value.find(x => x.userId === userId).email,
                adminTypeId: $newAdmin.value.adminTypeId,
                customerId: $newAdmin.value.customerId,
                adminSites: $newAdmin.value.adminSites.map(siteId => ({ siteId, adminId: userId }))
            };

            AdminService.postAdmin(admin)
                .then(() => onAdd(admin))
                .finally(() => $isSaving.set(false));
        })
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addUserModalContentStyles.root}>
                <h2>{t('admins.addModal.title')}</h2>

                <div>
                    <RadioGroup $value={$newAdmin.getPropHook('adminTypeId')} label={t('admins.fieldName.adminRole')}>
                        {$availableRoles.value.map(r => (
                            <RadioOption key={r.value} value={r.value} label={t(r.translationKey)} />
                        ))}
                    </RadioGroup>
                </div>
                {storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin ?
                    <SingleSelect label={t('admins.fieldName.customer')} $value={$newAdmin.getPropHook('customerId')}>
                        {$customers.value.map(c => (
                            <SelectOption key={c.customerId} value={c.customerId}>
                                {c.name}
                            </SelectOption>
                        ))}
                    </SingleSelect>
                    : null
                }
                {($newAdmin.value.adminTypeId === AdminTypes.Operator || $newAdmin.value.adminTypeId === AdminTypes.Provider || $newAdmin.value.adminTypeId === AdminTypes.SiteAdmin) &&
                    <MultiSelect $value={$newAdmin.getPropHook('adminSites')} required label={t('admins.fieldName.site')}>
                        {$sites.value.map(s => (
                            <MultiSelectOption key={s.siteId} value={s.siteId}>{s.name}</MultiSelectOption>
                        ))}
                    </MultiSelect>
                }
                <MultiSelect $value={$selectedUsers} required label={t('admins.fieldName.user')}>
                    {$users.value.map(s =>
                        s.email ?
                            <MultiSelectOption key={s.userId} value={s.userId}>
                                {`${s.firstName} ${s.lastName} (${s.username})`}
                            </MultiSelectOption>
                            :
                            <MultiSelectOption disabled key={s.userId} value={s.userId}>
                                {`${s.firstName} ${s.lastName} (${s.username}) `}
                                <span css={addUserModalContentStyles.missingEmailOption}>Missing email</span>
                            </MultiSelectOption>)
                    }
                </MultiSelect>

                {(!$newAdmin.isValid || !$selectedUsers.isValid) && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                <div className="actions" css={addUserModalContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newAdmin.isValid || !$selectedUsers.isValid} onClick={addAdmin}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
