import { getData, urlQueryString } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const InjuryService = {
  getInjuryLocations: function () {
    return getData(`${ApiHost}/api/Injuries`)
  },
  getInjuryTypes: function (injuryLocationId) {
    return getData(`${ApiHost}/api/Injuries/${injuryLocationId}/types`)
  },
  getInjuryStatuses: function () {
    return getData(`${ApiHost}/api/Injuries/statuses`)
  },
  getInjuryTypesByIds: function (injuryLocationIds) {
    const qs = urlQueryString({
      injuryLocationIds: injuryLocationIds
    }, true);
    return getData(`${ApiHost}/api/Injuries/types?${qs}`)
  },
};
