import { getData, putData, urlQueryString } from '../Utils/Common';
import { postData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const CustomerService = {
    getAll: function(limit, sortBy, isAsc) {
        const qs = urlQueryString({
            limit,
            sort_by: sortBy,
            isAsc,
        }, true);
        return getData(`${ApiHost}/api/customers?${qs}`)
    },
    postCustomer: function(customer){
        return postData(`${ApiHost}/api/customers`, customer);
    },
    putCustomer: function(customerData) {
        return putData(`${ApiHost}/api/customers`,customerData);
    },
    overview: function(customerId, startDate, endDate) {
        const qs = urlQueryString({
            startDate,
            endDate,
        }, true);
        return getData(`${ApiHost}/api/customers/${customerId}/overview?${qs}`)
            .then(res =>
                res.map(c => ({
                    ...c,
                    lastOnline: c.lastOnline ? new Date(c.lastOnline) : null,
                }))
            );
    },
    overviewScriptedActivities: function(customerId, startDate, endDate, limit = 10) {
        const qs = urlQueryString({
            startDate,
            endDate,
            limit,
        }, true);
        return getData(`${ApiHost}/api/customers/${customerId}/overview/scriptedActivities?${qs}`);
    },
    overviewTrend: function(customerId, orderBy, dateInterval, startDate, endDate) {
        const qs = urlQueryString({
            order_by: orderBy,
            date_interval: dateInterval,
            startDate,
            endDate,
        }, true);
        return getData(`${ApiHost}/api/customers/${customerId}/overview/trend?${qs}`);
    },
    providerSummary: function(customerId, startDate, endDate) {
        const qs = urlQueryString({
            startDate,
            endDate,
        }, true);
        return getData(`${ApiHost}/api/customers/${customerId}/provider/trend?${qs}`);
    },
};
