import { format } from "date-fns"
import { isValidDateValue, shortDate } from "../Utils/Common"
import { Input } from "./Input"
import { useEffect, useRef } from "react";

const DatePickerDateFormat = 'yyyy-MM-dd';

const parseAsLocalDate = (value) => new Date(`${value}T00:00:00`)

/**
 * @param {Date} min
 * @param {Date} max
 * @returns {string[]}
 */
export function datePickerRangeValidator(min, max) {
    return function (value) {
        const dateValue = typeof (value) === 'string' ?
                parseAsLocalDate(value)
            : value instanceof Date ?
                value
            : null;
        return dateValue == null || (dateValue <= max && dateValue >= min) ?
            []
            : [`Date must be between ${shortDate(min)} and ${shortDate(max)}`];
    }
}

export const DatePicker = ({ $value, maxValue, minValue, ...props }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (!inputRef.current || !isValidDateValue($value.value)) {
            return
        }

        inputRef.current.value = format($value.value, DatePickerDateFormat);
    }, [$value.value]);

    return <Input
        ref={inputRef}
        allowUndefinedValue
        type="date"
        valueRef={inputRef.current?.value ? parseAsLocalDate(inputRef.current.value) : undefined}
        // validateWithValueRef
        onChange={value => $value.set(value ? parseAsLocalDate(value) : null)}
        validator={() => $value.validate()}
        inputProps={{
            max: isValidDateValue(maxValue) ? format(maxValue, DatePickerDateFormat) : maxValue,
            min: isValidDateValue(minValue) ? format(minValue, DatePickerDateFormat) : minValue
        }}
        {...props}
    />
}
