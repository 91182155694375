/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import {
    ForceValidateContext,
    useHook
} from '../../../CoreComponents/Utils';
import { CustomerService } from '../../../Services/CustomerService';
import { SiteService } from '../../../Services/SiteService';
import { GroupService } from '../../../Services/GroupService';
import { Button } from '../../../CoreComponents/Button';
import { requiredValidator } from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { AdminTypes } from '../../../Utils/Constants';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const addUserGroupModalContentStyles = {
    root: css`
        width: unset;
        height: 85vh;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .fields-container{
            & > .input-root {
                margin: 0 0 30px 0;
                width: 100%;
            }
        }

        & > .actions {
            display: flex;
            margin: 0;
            margin-top: auto;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }
    `,
};
export const AddUserToGroupContent = ({$user, onCancel, onAdd }) => {
    const storeState = useSelector((state) => state.storeState);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $groups = useHook([]);
    const $selectedCustomer = useHook(storeState.currentUser.admin?.customerId || null, requiredValidator);
    const $selectedSite = useHook(null, requiredValidator);
    const $selectedGroup = useHook(null, requiredValidator);
    const $isSaving = useHook(false);

    const { t } = useTranslation();
    const $isForceValidating = useHook(false);

    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;

    useEffect(() => {
        if (!isTrazerAdmin) {
            return;
        }
        CustomerService.getAll(9999, 'Name', true)
            .then(customers => $customers.set(customers));
    }, [])

    useEffect(() => {
        if (!$selectedCustomer.value) {
            return;
        }
        $selectedGroup.set(null);
        SiteService.getAll(9999, $selectedCustomer.value, null, 'Name', true)
            .then(sites => $sites.set(sites));
    }, [$selectedCustomer.value])

    useEffect(() => {
        if (!$selectedCustomer.value) {
            return;
        }
        GroupService.getGroups(9999, $selectedCustomer.value, $selectedSite.value)
        .then(groups => $groups.set(groups))
    }, [$selectedCustomer.value, $selectedSite.value])

    const addGroup = () => {
        $isSaving.set(true);
        GroupService.postUserGroup({
            userId: $user.value.userId,
            groupId: $selectedGroup.value
        })
        .then(group => {
            $user.set({
                ...$user.value,
                userGroups: [...$user.value.userGroups, group]
            });
            onAdd();
        })
        .catch(() => $isSaving.set(false));
    }

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addUserGroupModalContentStyles.root}>
                <h2>{t('groups.addModal.title')}</h2>
                <div className="fields-container">
                    {isTrazerAdmin ?
                        <SingleSelect $value={$selectedCustomer} required label={t('groups.fieldName.customer')}>
                            {$customers.value.map(g => <SelectOption key={g.customerId} value={g.customerId}>{g.name}</SelectOption>)}
                        </SingleSelect>
                        :
                        null
                    }
                    <SingleSelect onChange={() => $isForceValidating.set(true)} $value={$selectedSite} required label={t('groups.fieldName.site')}>
                        {$sites.value.map(g => <SelectOption key={g.siteId} value={g.siteId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                    <SingleSelect onChange={() => $isForceValidating.set(true)} $value={$selectedGroup} required label={t('groups.fieldName.group')}>
                        {$groups.value.map(g => !$user.value.userGroups.find(x => x.groupId === g.groupId) && <SelectOption key={g.groupId} value={g.groupId}>{g.groupName}</SelectOption>)}
                    </SingleSelect>
                </div>
                <div className="actions" css={addUserGroupModalContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$selectedGroup.isValid} onClick={addGroup}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
