import { getData, putData, postData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const BaseActivityService = {
    getBaseActivities: function() {
        return getData(`${ApiHost}/api/BaseActivities`)
    },
    postBaseActivity: function(baseActivity) {
        return postData(`${ApiHost}/api/BaseActivities`, baseActivity)
    },
    putBaseActivity: function(baseActivity) {
        return putData(`${ApiHost}/api/BaseActivities`, baseActivity)
    },
};
