// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReportTable } from "../../ReportTable";
import { useTranslation } from "../../../../CoreComponents/Translation";
import { useMemo } from "react";
import { KinematicChart } from "../../../../CoreComponents/ChartComponents/KinematicsChart";
import { chartCardStyles } from "./TestReportCharts";
import { RadioGroup, RadioOption } from '../../../../CoreComponents/RadioSelection';
import { useHook } from '../../../../CoreComponents/Utils';
import { BaseActivities } from "../../../../Utils/Constants";

const kinematicRepsStyles = {
    info: css`
        font: normal normal medium 16px/24px Roboto;
        letter-spacing: 0.14px;
        color: #FFFFFF;
        background: #00ABA5 0% 0% no-repeat padding-box;

        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 10px 40px;
        border-radius: 8px 0px;
    `
};
const radioGroupStyles = {
    test: css`
        align-self: center;
        & .radio-group {
            display: flex;
            flex-direction: row;
            margin-left: 0;
        }
    `
}
export const KinematicRepsSection = ({ isPrinting, sectionName, dataMap, datasets, trackedMetrics }) => {
    if (!datasets || !datasets.length) {
        return null;
    }

    const tableData = {};
    const tableDataKeys = Object.keys(datasets[0].data || {});

    const trackedDataMap =  {...dataMap};
    if (!trackedMetrics.torso){
        delete trackedDataMap.initialTrunkLean;
        delete trackedDataMap.trunkLean;
        delete trackedDataMap.initialTrunkLatFlexion;
        delete trackedDataMap.trunkLatFlexion;
        delete trackedDataMap.initialPelvisRotation;
        delete trackedDataMap.pelvisRotation;
        delete trackedDataMap.initialSquatDepth;
        delete trackedDataMap.squatDepth;
        delete trackedDataMap.jumpHeight;
    }
    if (!trackedMetrics.legs){
        delete trackedDataMap.initialRightHipRotation;
        delete trackedDataMap.rightHipRotation;
        delete trackedDataMap.initialRightKneeFlexion;
        delete trackedDataMap.rightKneeFlexion;
        delete trackedDataMap.initialRightKneeValgusOrVarus;
        delete trackedDataMap.rightKneeValgusOrVarus;
        delete trackedDataMap.initialRightAnkleDorsiflexion;
        delete trackedDataMap.rightAnkleDorsiflexion;
        delete trackedDataMap.initialLeftHipRotation;
        delete trackedDataMap.leftHipRotation;
        delete trackedDataMap.initialLeftKneeFlexion;
        delete trackedDataMap.leftKneeFlexion;
        delete trackedDataMap.initialLeftKneeValgusOrVarus;
        delete trackedDataMap.leftKneeValgusOrVarus;
        delete trackedDataMap.initialLeftAnkleDorsiflexion;
        delete trackedDataMap.leftAnkleDorsiflexion;
        delete trackedDataMap.plantFoot;
        delete trackedDataMap.initialStanceWidthDistance;
        delete trackedDataMap.stanceWidthDistance;
        delete trackedDataMap.initialStanceWidthRatio;
        delete trackedDataMap.stanceWidthRatio;
    }
    if (!trackedMetrics.spine){
        delete trackedDataMap.initialSpineFlexion;
        delete trackedDataMap.spineFlexion;
        delete trackedDataMap.initialSpineLatFlexion;
        delete trackedDataMap.spineLatFlexion;
        delete trackedDataMap.initialSpineRotation;
        delete trackedDataMap.spineRotation;
        delete trackedDataMap.initialNeckRotation;
        delete trackedDataMap.neckRotation;
        delete trackedDataMap.initialNeckFlexion;
        delete trackedDataMap.neckFlexion;
        delete trackedDataMap.initialNeckLatFlexion;
        delete trackedDataMap.neckLatFlexion;
        delete trackedDataMap.initialShoulderRotation;
        delete trackedDataMap.shoulderRotation;
        delete trackedDataMap.initialThoracicRotation;
        delete trackedDataMap.thoracicRotation;
        delete trackedDataMap.initialLumbarRotation;
        delete trackedDataMap.lumbarRotation;
    }
    if (!trackedMetrics.arms){
        delete trackedDataMap.initialRightShoulderRotation;
        delete trackedDataMap.rightShoulderRotation;
        delete trackedDataMap.initialRightShoulderFlexion;
        delete trackedDataMap.rightShoulderFlexion;
        delete trackedDataMap.initialRightShoulderAbduction;
        delete trackedDataMap.rightShoulderAbduction;
        delete trackedDataMap.initialRightShoulderHorizontalAbduction;
        delete trackedDataMap.rightShoulderHorizontalAbduction;
        delete trackedDataMap.initialRightElbowFlexion;
        delete trackedDataMap.rightElbowFlexion;
        delete trackedDataMap.initialLeftShoulderRotation;
        delete trackedDataMap.leftShoulderRotation;
        delete trackedDataMap.initialLeftShoulderFlexion;
        delete trackedDataMap.leftShoulderFlexion;
        delete trackedDataMap.initialLeftShoulderAbduction;
        delete trackedDataMap.leftShoulderAbduction;
        delete trackedDataMap.initialLeftShoulderHorizontalAbduction;
        delete trackedDataMap.leftShoulderHorizontalAbduction;
        delete trackedDataMap.initialLeftElbowFlexion;
        delete trackedDataMap.leftElbowFlexion;
    }
    for (const ds of datasets) {
        for (const key of tableDataKeys) {
            const mappedKey = trackedDataMap[key];
            if (!mappedKey) {
                continue;
            }

            if (!tableData[mappedKey]) {
                tableData[mappedKey] = {};
            }

            tableData[mappedKey][ds.label] = ds.data[key];
            tableData[mappedKey]['rowName'] = mappedKey;
        }
    }

    return (
        <div css={chartCardStyles}>
            <h2 css={kinematicRepsStyles.info}>
                {sectionName}
            </h2>
            <ReportTable
                enableRowHeader
                color="gray"
                disableRowShadow
                data={Object.values(tableData)}
            />
        </div>
    );
};

export const KinematicChartSection = ({ isPrinting, sectionName, baseActivityId, dataMap, datasets, trackedMetrics }) => {
    const { t } = useTranslation();
    const $isJumpActivity = baseActivityId === BaseActivities.KinematicJump || baseActivityId === BaseActivities.KinematicSingleLegJump || baseActivityId === BaseActivities.VerticalDropJump;
    const $isFlexionExtensionActivity = baseActivityId === BaseActivities.ROMElbowFlexionExtension || baseActivityId === BaseActivities.ROMFlexionExtension || baseActivityId === BaseActivities.ROMShoulderLinearFlexionExtension;
    const $isAdductionAbductionActivity = baseActivityId === BaseActivities.ROMShoulderHorizontalAdductionAbduction || baseActivityId === BaseActivities.ROMShoulderLateralAdductionAbduction;
    const $isLeftRightActivity = baseActivityId === BaseActivities.ROMRotation || baseActivityId === BaseActivities.ROMLateralFlexion;
    const $isInternalExternalActivity = baseActivityId === BaseActivities.ROMShoulderInternalExternalRotation;
    const $selectedJumpMetric = useHook($isJumpActivity ? 'Post-Jump' : '');
    const $selectedFlexionExtensionMetric = useHook($isFlexionExtensionActivity ? 'Flexion' : '');
    const $selectedAdductionAbductionMetric = useHook($isAdductionAbductionActivity ? 'Abduction' : '');
    const $selectedLeftRightMetric = useHook($isLeftRightActivity ? 'Left' : '');
    const $selectedInternalExternalMetric = useHook($isInternalExternalActivity ? 'External' : '');
    
    if (!datasets || !datasets.length) {
        return null;
    }

    // TODO: If possible move this to the PrepareTestData method!
    const tableData = {};
    const tableDataKeys = Object.keys(
        datasets.reduce((a, c) => ({ ...a, ...c?.data }), {})
    );

    const trackedDataMap = { ...dataMap };
    if (!trackedMetrics.torso){
        delete trackedDataMap.initialTrunkLean;
        delete trackedDataMap.trunkLean;
        delete trackedDataMap.initialTrunkLatFlexion;
        delete trackedDataMap.trunkLatFlexion;
        delete trackedDataMap.initialPelvisRotation;
        delete trackedDataMap.pelvisRotation;
        delete trackedDataMap.initialSquatDepth;
        delete trackedDataMap.squatDepth;
        delete trackedDataMap.jumpHeight;
    }
    if (!trackedMetrics.legs){
        delete trackedDataMap.initialRightHipRotation;
        delete trackedDataMap.rightHipRotation;
        delete trackedDataMap.initialRightKneeFlexion;
        delete trackedDataMap.rightKneeFlexion;
        delete trackedDataMap.initialRightKneeValgusOrVarus;
        delete trackedDataMap.rightKneeValgusOrVarus;
        delete trackedDataMap.initialRightAnkleDorsiflexion;
        delete trackedDataMap.rightAnkleDorsiflexion;
        delete trackedDataMap.initialLeftHipRotation;
        delete trackedDataMap.leftHipRotation;
        delete trackedDataMap.initialLeftKneeFlexion;
        delete trackedDataMap.leftKneeFlexion;
        delete trackedDataMap.initialLeftKneeValgusOrVarus;
        delete trackedDataMap.leftKneeValgusOrVarus;
        delete trackedDataMap.initialLeftAnkleDorsiflexion;
        delete trackedDataMap.leftAnkleDorsiflexion;
        delete trackedDataMap.plantFoot;
        delete trackedDataMap.initialStanceWidthDistance;
        delete trackedDataMap.stanceWidthDistance;
        delete trackedDataMap.initialStanceWidthRatio;
        delete trackedDataMap.stanceWidthRatio;
    }
    if (!trackedMetrics.spine){
        delete trackedDataMap.initialSpineFlexion;
        delete trackedDataMap.spineFlexion;
        delete trackedDataMap.initialSpineLatFlexion;
        delete trackedDataMap.spineLatFlexion;
        delete trackedDataMap.initialSpineRotation;
        delete trackedDataMap.spineRotation;
        delete trackedDataMap.initialNeckRotation;
        delete trackedDataMap.neckRotation;
        delete trackedDataMap.initialNeckFlexion;
        delete trackedDataMap.neckFlexion;
        delete trackedDataMap.initialNeckLatFlexion;
        delete trackedDataMap.neckLatFlexion;
        delete trackedDataMap.initialShoulderRotation;
        delete trackedDataMap.shoulderRotation;
        delete trackedDataMap.initialThoracicRotation;
        delete trackedDataMap.thoracicRotation;
        delete trackedDataMap.initialLumbarRotation;
        delete trackedDataMap.lumbarRotation;
    }
    if (!trackedMetrics.arms){
        delete trackedDataMap.initialRightShoulderRotation;
        delete trackedDataMap.rightShoulderRotation;
        delete trackedDataMap.initialRightShoulderFlexion;
        delete trackedDataMap.rightShoulderFlexion;
        delete trackedDataMap.initialRightShoulderAbduction;
        delete trackedDataMap.rightShoulderAbduction;
        delete trackedDataMap.initialRightShoulderHorizontalAbduction;
        delete trackedDataMap.rightShoulderHorizontalAbduction;
        delete trackedDataMap.initialRightElbowFlexion;
        delete trackedDataMap.rightElbowFlexion;
        delete trackedDataMap.initialLeftShoulderRotation;
        delete trackedDataMap.leftShoulderRotation;
        delete trackedDataMap.initialLeftShoulderFlexion;
        delete trackedDataMap.leftShoulderFlexion;
        delete trackedDataMap.initialLeftShoulderAbduction;
        delete trackedDataMap.leftShoulderAbduction;
        delete trackedDataMap.initialLeftShoulderHorizontalAbduction;
        delete trackedDataMap.leftShoulderHorizontalAbduction;
        delete trackedDataMap.initialLeftElbowFlexion;
        delete trackedDataMap.leftElbowFlexion;
    }
    if(($isJumpActivity && $selectedJumpMetric.value === 'Post-Jump') ||
    ($isFlexionExtensionActivity && $selectedFlexionExtensionMetric.value === 'Extension') ||
    ($isAdductionAbductionActivity && $selectedAdductionAbductionMetric.value === 'Adduction') ||
    ($isLeftRightActivity && $selectedLeftRightMetric.value === 'Right') ||
    ($isInternalExternalActivity && $selectedInternalExternalMetric.value === 'Internal')){
        delete trackedDataMap.initialTrunkLean;
        delete trackedDataMap.initialTrunkLatFlexion;
        delete trackedDataMap.initialPelvisRotation;
        delete trackedDataMap.initialSquatDepth;
        delete trackedDataMap.initialRightHipRotation;
        delete trackedDataMap.initialRightKneeFlexion;
        delete trackedDataMap.initialRightKneeValgusOrVarus;
        delete trackedDataMap.initialRightAnkleDorsiflexion;
        delete trackedDataMap.initialLeftHipRotation;
        delete trackedDataMap.initialLeftKneeFlexion;
        delete trackedDataMap.initialLeftKneeValgusOrVarus;
        delete trackedDataMap.initialLeftAnkleDorsiflexion;
        delete trackedDataMap.initialStanceWidthDistance;
        delete trackedDataMap.initialStanceWidthRatio;
        delete trackedDataMap.initialSpineFlexion;
        delete trackedDataMap.initialSpineLatFlexion;
        delete trackedDataMap.initialSpineRotation;
        delete trackedDataMap.initialNeckRotation;
        delete trackedDataMap.initialNeckFlexion;
        delete trackedDataMap.initialNeckLatFlexion;
        delete trackedDataMap.initialShoulderRotation;
        delete trackedDataMap.initialThoracicRotation;
        delete trackedDataMap.initialLumbarRotation;
        delete trackedDataMap.initialRightShoulderRotation;
        delete trackedDataMap.initialRightShoulderFlexion;
        delete trackedDataMap.initialRightShoulderAbduction;
        delete trackedDataMap.initialRightShoulderHorizontalAbduction;
        delete trackedDataMap.initialRightElbowFlexion;
        delete trackedDataMap.initialLeftShoulderRotation;
        delete trackedDataMap.initialLeftShoulderFlexion;
        delete trackedDataMap.initialLeftShoulderAbduction;
        delete trackedDataMap.initialLeftShoulderHorizontalAbduction;
        delete trackedDataMap.initialLeftElbowFlexion;
    }
    else if($isJumpActivity || $isFlexionExtensionActivity ||$isAdductionAbductionActivity || $isLeftRightActivity || $isInternalExternalActivity){
        delete trackedDataMap.trunkLean;
        delete trackedDataMap.trunkLatFlexion;
        delete trackedDataMap.pelvisRotation;
        delete trackedDataMap.squatDepth;
        delete trackedDataMap.rightHipRotation;
        delete trackedDataMap.rightKneeFlexion;
        delete trackedDataMap.rightKneeValgusOrVarus;
        delete trackedDataMap.rightAnkleDorsiflexion;
        delete trackedDataMap.leftHipRotation;
        delete trackedDataMap.leftKneeFlexion;
        delete trackedDataMap.leftKneeValgusOrVarus;
        delete trackedDataMap.leftAnkleDorsiflexion;
        delete trackedDataMap.stanceWidthDistance;
        delete trackedDataMap.stanceWidthRatio;
        delete trackedDataMap.spineFlexion;
        delete trackedDataMap.spineLatFlexion;
        delete trackedDataMap.spineRotation;
        delete trackedDataMap.neckRotation;
        delete trackedDataMap.neckFlexion;
        delete trackedDataMap.neckLatFlexion;
        delete trackedDataMap.shoulderRotation;
        delete trackedDataMap.thoracicRotation;
        delete trackedDataMap.lumbarRotation;
        delete trackedDataMap.rightShoulderRotation;
        delete trackedDataMap.rightShoulderFlexion;
        delete trackedDataMap.rightShoulderAbduction;
        delete trackedDataMap.rightShoulderHorizontalAbduction;
        delete trackedDataMap.rightElbowFlexion;
        delete trackedDataMap.leftShoulderRotation;
        delete trackedDataMap.leftShoulderFlexion;
        delete trackedDataMap.leftShoulderAbduction;
        delete trackedDataMap.leftShoulderHorizontalAbduction;
        delete trackedDataMap.leftElbowFlexion;
    }
    for (const ds of datasets) {
        for (const key of tableDataKeys) {
            const mappedKey = trackedDataMap[key];
            if (!mappedKey){
                continue;
            }

            if (!tableData[mappedKey]) {
                tableData[mappedKey] = {
                    rowName: mappedKey,
                };
            }

            tableData[mappedKey][ds.label] = ds.data[key];
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { chartData } = useMemo(() => {
        const chartData = datasets.map(x => {
            const currentChartData = x.data;
            const isPostSelected = $selectedJumpMetric.value === 'Post-Jump' || $selectedFlexionExtensionMetric.value === 'Extension' || $selectedAdductionAbductionMetric.value === 'Adduction' || $selectedLeftRightMetric.value === 'Right' || $selectedInternalExternalMetric === 'Internal' ||
                ($selectedJumpMetric.value === '' && $selectedFlexionExtensionMetric.value === '' && $selectedAdductionAbductionMetric.value === '' && $selectedLeftRightMetric.value === '' && $selectedInternalExternalMetric.value === '');
            const torso = [];
            if (trackedMetrics.torso) {
                torso.push(
                    { name: t('testReport.fieldName.trunkLean'), value: isPostSelected ? currentChartData.trunkLean : currentChartData.initialTrunkLean },
                    { name: t('testReport.fieldName.pelvicTilt'), value: isPostSelected ? currentChartData.trunkLatFlexion : currentChartData.initialTrunkLatFlexion },
                );
            }
            if (trackedMetrics.spine) {
                torso.push(
                    { name: t('testReport.fieldName.neckRotation'), value: isPostSelected ? currentChartData.neckRotation : currentChartData.initialNeckRotation },
                    { name: t('testReport.fieldName.spineFlexion'), value: isPostSelected ? currentChartData.spineFlexion : currentChartData.initialSpineFlexion },
                    { name: t('testReport.fieldName.spineLatFlexion'), value: isPostSelected ? currentChartData.spineLatFlexion : currentChartData.initialSpineLatFlexion },
                    { name: t('testReport.fieldName.spineRotation'), value: isPostSelected ? currentChartData.spineRotation : currentChartData.initialSpineRotation },
                    { name: t('testReport.fieldName.shoulderRotation'), value: isPostSelected ? currentChartData.shoulderRotation : currentChartData.initialShoulderRotation },
                    { name: t('testReport.fieldName.thoracicRotation'), value: isPostSelected ? currentChartData.thoracicRotation : currentChartData.initialThoracicRotation },
                    { name: t('testReport.fieldName.lumbarRotation'), value: isPostSelected ? currentChartData.lumbarRotation : currentChartData.initialLumbarRotation },
                );
            }

            const leftKnee = [];
            const rightKnee = [];
            const leftAnkle = [];
            const rightAnkle = [];
            if (trackedMetrics.legs) {
                leftKnee.push(
                    { name: t('testReport.fieldName.leftHipRotation'), value: isPostSelected ? currentChartData.leftHipRotation : currentChartData.initialLeftHipRotation },
                    { value: isPostSelected ? currentChartData.leftKneeValgusOrVarus : currentChartData.initialLeftKneeValgusOrVarus },
                    { name: t('testReport.fieldName.flexion'), value: isPostSelected ? currentChartData.leftKneeFlexion : currentChartData.initialLeftKneeFlexion },
                );
                rightKnee.push(
                    { name: t('testReport.fieldName.rightHipRotation'), value: isPostSelected ? currentChartData.rightHipRotation : currentChartData.initialRightHipRotation },
                    { value: isPostSelected ? currentChartData.rightKneeValgusOrVarus : currentChartData.initialRightKneeValgusOrVarus },
                    { name: t('testReport.fieldName.flexion'), value: isPostSelected ? currentChartData.rightKneeFlexion : currentChartData.initialRightKneeFlexion },
                );
                leftAnkle.push(
                    { name: t('testReport.fieldName.dorsiflexion'), value: isPostSelected ? currentChartData.leftAnkleDorsiflexion : currentChartData.initialLeftAnkleDorsiflexion },
                );
                rightAnkle.push(
                    { name: t('testReport.fieldName.dorsiflexion'), value: isPostSelected ? currentChartData.rightAnkleDorsiflexion : currentChartData.initialRightAnkleDorsiflexion },
                );
            }

            const leftShoulder = [];
            const rightShoulder = [];
            const leftElbow = [];
            const rightElbow = [];
            if (trackedMetrics.arms) {
                rightShoulder.push(
                    { name: t('testReport.fieldName.rotation'), value: isPostSelected ? currentChartData.rightShoulderRotation : currentChartData.initialRightShoulderRotation },
                    { name: t('testReport.fieldName.abduction'), value: isPostSelected ? currentChartData.rightShoulderAbduction : currentChartData.initialRightShoulderAbduction },
                    { name: t('testReport.fieldName.horizontalAbduction'), value: isPostSelected ? currentChartData.rightShoulderHorizontalAbduction : currentChartData.initialRightShoulderHorizontalAbduction },
                    { name: t('testReport.fieldName.shoulderFlexion'), value: isPostSelected ? currentChartData.rightShoulderFlexion : currentChartData.initialRightShoulderFlexion },
                );
                leftShoulder.push(
                    { name: t('testReport.fieldName.rotation'), value: isPostSelected ? currentChartData.leftShoulderRotation : currentChartData.initialLeftShoulderRotation },
                    { name: t('testReport.fieldName.abduction'), value: isPostSelected ? currentChartData.leftShoulderAbduction : currentChartData.initialLeftShoulderAbduction},
                    { name: t('testReport.fieldName.horizontalAbduction'), value: isPostSelected ? currentChartData.leftShoulderHorizontalAbduction : currentChartData.initialLeftShoulderHorizontalAbduction },
                    { name: t('testReport.fieldName.shoulderFlexion'), value: isPostSelected ? currentChartData.leftShoulderFlexion : currentChartData.initialLeftShoulderFlexion },
                );
                rightElbow.push(
                    { name: t('testReport.fieldName.elbowFlexion'), value: isPostSelected ? currentChartData.rightElbowFlexion : currentChartData.initialRightElbowFlexion },
                );
                leftElbow.push(
                    { name: t('testReport.fieldName.elbowFlexion'), value: isPostSelected ? currentChartData.leftElbowFlexion : currentChartData.initialLeftElbowFlexion },
                );
            }

            return {
                ...x,
                data: {
                    torso,
                    leftKnee,
                    rightKnee,
                    leftAnkle,
                    rightAnkle,
                    leftShoulder,
                    rightShoulder,
                    leftElbow,
                    rightElbow,
                },
            }
        });
        return {
            chartData
        }
    }, [t, datasets, trackedMetrics, $selectedJumpMetric.value, $selectedFlexionExtensionMetric.value, $selectedAdductionAbductionMetric.value, $selectedLeftRightMetric.value, $selectedInternalExternalMetric.value]);

    return ((trackedMetrics.torso || trackedMetrics.legs) &&
        <div css={chartCardStyles} className={datasets.length > 2 ? 'multiple-results' : null}>
            <div className="info">
                <h2>{sectionName}</h2>
            </div>
            <div className="chart-container kinematic-chart-container">
                <KinematicChart
                    isPrinting={isPrinting}
                    datasets={chartData}
                />
            </div>
            {$isJumpActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedJumpMetric}>
                        <RadioOption key='Pre-Jump' value='Pre-Jump' label='Pre-Jump' />
                        <RadioOption key='Post-Jump' value='Post-Jump' label='Post-Jump' />
                    </RadioGroup>
                </div>
            }
            {$isFlexionExtensionActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedFlexionExtensionMetric}>
                        <RadioOption key='Flexion' value='Flexion' label='Flexion' />
                        <RadioOption key='Extension' value='Extension' label='Extension' />
                    </RadioGroup>
                </div>
            }
            {$isAdductionAbductionActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedAdductionAbductionMetric}>
                        <RadioOption key='Abduction' value='Abduction' label='Abduction' />
                        <RadioOption key='Adduction' value='Adduction' label='Adduction' />
                    </RadioGroup>
                </div>
            }
            {$isLeftRightActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedLeftRightMetric}>
                        <RadioOption key='Left' value='Left' label='Left' />
                        <RadioOption key='Right' value='Right' label='Right' />
                    </RadioGroup>
                </div>
            }
            {$isInternalExternalActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedInternalExternalMetric}>
                        <RadioOption key='External' value='External' label='External' />
                        <RadioOption key='Internal' value='Internal' label='Internal' />
                    </RadioGroup>
                </div>
            }
            <div className="vertical-line"></div>
            <div className="table-data">
                <ReportTable
                    enableRowHeader
                    color="gray"
                    disableRowShadow
                    data={Object.values(tableData)}
                />
            </div>
        </div>
    );
};
