import { getData, urlQueryString } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const SportService = {
    getAll: function () {
        return getData(`${ApiHost}/api/Sports`)
    },
    getSportPositions: function (sportId) {
        return getData(`${ApiHost}/api/Sports/${sportId}/positions`)
    },
    getSportLevels: function () {
        return getData(`${ApiHost}/api/Sports/levels`)
    },
    getPositions: function (sportIds) {
        const qs = urlQueryString({
            sport_ids: sportIds
        }, true);
        return getData(`${ApiHost}/api/Sports/positions?${qs}`);
    },
};
