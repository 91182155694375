/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    maxLengthValidator,
    requiredValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { TagService } from '../../../Services/TagService';

const tagTabStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        min-height: 340px;
        // height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 380px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .input-root {
            margin: 0;
            width: 100%;
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;

            & > button {
                border-radius: 8px;
                min-width: 136px;
                box-shadow: none;
            }

            & > button:first-of-type {
                margin-right: 20px;
                margin-left: auto;
                color: #858585;
                background: #EEEEEE;
                border: 2px solid;
            }
        }
    `,
};

const EditTagValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
};

export const EditTagContent = ({ $selectedTag, onCancel }) => {
    const { t } = useTranslation();
    const $isForceValidating = useHook(true);
    const $tagCopy = useObjectHook(
        $selectedTag.value,
        EditTagValidator,
        null,
        $isForceValidating.value
    );

    const updateTag = () => {
        $isForceValidating.set(true);
        const errors = $tagCopy.validate();
        if (errors) {
            return;
        }
        TagService.putTag($tagCopy.value)
            .then(() => $selectedTag.set($tagCopy.value));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={tagTabStyles.root}>
                <h2>{t('tags.editModal.title')}</h2>

                <Input label={t('tags.fieldName.name')} required $value={$tagCopy.getPropHook('name')} />

                <div className="actions">
                    <Button onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={!$tagCopy.isValid} onClick={updateTag}>{t('actions.save')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
