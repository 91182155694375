/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../CoreComponents/Button";
import { Input, TextareaInput } from "../../../CoreComponents/Input";
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { CategoryService } from "../../../Services/CategoryService";
import { ScriptedActivitiesService } from "../../../Services/ScriptedActivitiesService";
import { TagService } from "../../../Services/TagService";
import { PillarService } from "../../../Services/PillarService";
import { ActivityGroupService } from "../../../Services/ActivityGroupService";
import AddIcon from '@mui/icons-material/Add';
import { ScriptedActivitySteps } from "./ScriptedActivitySteps/ScriptedActivitySteps";
import { Modal } from "../../../CoreComponents/Modal";
import { AddActivityStepModal } from "./ScriptedActivitySteps/AddStepContent";
import { BaseActivityService } from "../../../Services/BaseActivityService";
import { CircularLoader } from "../../../CoreComponents/Loaders";
import { SiteService } from "../../../Services/SiteService";
import { Checkbox } from "../../../CoreComponents/Checkbox";
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from "../../../CoreComponents/CustomIcons";
import { Switch } from "../../../CoreComponents/Switch";
import { InternalLink } from "../../../CoreComponents/Layout";
import { maxLengthValidator, requiredValidator } from "../../../Utils/Validators";
import { AlertText } from "../../../CoreComponents/Alert";
import { SingleSelect } from "../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../CoreComponents/SelectListComponents/SelectOption";
import { MultiSelect, MultiSelectOption } from "../../../CoreComponents/SelectListComponents/MultiSelect";

const scriptedActivitiesEditStyles = {
    root: css`
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 160px 20px 200px;

        & > div:first-of-type {
            display: flex;
            height: calc(100% - 190px);
        }

        & > .mark-all {
            margin-left: 30.1em;
        }

        & > h2 {
            margin: 20px 0 40px 0;
        }
    `,
    actions: css`
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding-right: 80px;
        top: 50px;

        & > a, & > button {
            min-width: unset;
            margin-right: 8px;
        }

        & > .alert-text-root {
            align-self: center;
            margin-right: 20px;
        }
    `,

    tabs: css`
        border: 2px solid #00ABA5;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        padding: 2px;
        max-width: 256px;

        & > .tab-selector {
            padding: 6px 32px;
            min-height: 32px;
            max-height: 32px;
            box-shadow: none;
        }
    `,
    informationTab: css`
        width: 400px;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        & > div {
            margin-left: 0;
        }

        & textarea {
            height: 264px;
            resize: none;
        }
    `,
    content: css`
        height: 100%;
        width: calc(100% - 400px);
        padding: 0 40px 0 38px;

        & .controls {
            display: flex;
            justify-content: space-between;
            padding-right: 48px;

            & button {
                min-width: unset;
            }
        }
    `,
    verticalLine: css`
        border:1px solid #CBCBCB
    `,
    steps: css`
        display: block;
    `,
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
}

const EditScriptedActivityValidator = {
    name: [requiredValidator, maxLengthValidator(50)]
};

export const EditScriptedActivity = () => {
    const { t } = useTranslation();
    const $selectedTab = useHook(1);
    const $categories = useHook([]);
    const $tags = useHook([]);
    const $pillars = useHook([]);
    const $groups = useHook([]);
    const $sites = useHook([]);
    const $customers = useHook([]);
    const { scriptedActivityId } = useParams();
    const $baseActivities = useHook([]);
    const $isLoading = useHook(true);
    const $isAddStepModalOpened = useHook(false);
    const $search = useHook('');
    const $isSaving = useHook(false);
    const navigate = useNavigate();

    const $selectedActivity = useObjectHook(
        {
            name: '',
            alias: '',
            category: { categoryId: '' },
            tags: '',
            pillars: '',
            groups: '',
            summary: '',
            scriptedActivitySteps: []
        },
        EditScriptedActivityValidator
    );

    const $selectedActivityCopy = useObjectHook(
        $selectedActivity.value,
        EditScriptedActivityValidator
    );

    const isMarkAllChecked = useMemo(() => {
        if (!$selectedActivity.value.unitScriptedActivities) {
            return false;
        }

        const unitsCount = $sites.value
            .reduce((a, c) => a + (c.units?.length || 0), 0);

        return $selectedActivity.value.unitScriptedActivities.length === unitsCount;
    }, [$selectedActivity.value.unitScriptedActivities, $sites.value]);

    useEffect(() => {
        (async () => {

            await Promise.all([
                ScriptedActivitiesService
                    .getScriptedActivity(scriptedActivityId)
                    .then(scriptedActivity => {
                        const activity = {
                            ...scriptedActivity,
                            category: { categoryId: scriptedActivity.category.categoryId },
                            tags: scriptedActivity.satags.map(t => t.tagId),
                            pillars: scriptedActivity.sapillars.map(p => p.pillarId),
                            groups: scriptedActivity.sagroups.map(g => g.groupId)
                        }
                        $selectedActivity.set(activity)
                        $selectedActivityCopy.set(activity)
                    }),

                CategoryService.getAll()
                    .then(categories => $categories.set(categories)),

                TagService.getAll()
                    .then(tags => $tags.set(tags)),

                PillarService.getAll()
                    .then(pillars => $pillars.set(pillars)),

                ActivityGroupService.getAll()
                    .then(groups => $groups.set(groups)),

                BaseActivityService.getBaseActivities()
                    .then(baseActivities => $baseActivities.set(baseActivities)),

                SiteService.getAll()
                    .then(sites => {
                        $sites.set(sites);

                        const customerSites = {};
                        for (let site of sites) {
                            if (!customerSites[site.customerId]) {
                                customerSites[site.customerId] = {
                                    ...site.customer
                                };
                            }

                            customerSites[site.customerId]
                                .sites
                                .push(site);
                        }
                        $customers.set(Object.values(customerSites));
                    })
            ])
            $isLoading.set(false);
        })()
    }, [])

    const updateActivity = () => {
        $isSaving.set(true);
        ScriptedActivitiesService.updateScriptedActivity(
            {
                ...$selectedActivity.value,
                satags: $selectedActivity.value.tags.map(t => ({ tagId: t })),
                sapillars: $selectedActivity.value.pillars.map(p => ({ pillarId: p })),
                sagroups: $selectedActivity.value.groups.map(g => ({ groupId: g }))
            }
        ).then(() => {
            $isSaving.set(false)
            navigate('/manage/scripted-activities')
        })
    }

    return $isLoading.value ?
        <div css={scriptedActivitiesEditStyles.loadingContainer}>
            <CircularLoader />
        </div>
        : (
            <div css={scriptedActivitiesEditStyles.root}>
                <h2>{t('scriptedActivities.edit.title')}</h2>

                <div>
                    <aside css={scriptedActivitiesEditStyles.informationTab}>
                        <Input
                            placeholder={t('input.placeholder')}
                            required
                            label={t('scriptedActivity.fieldName.name')}
                            $value={$selectedActivity.getPropHook('name')}
                        />
                        <Input
                            placeholder={t('input.placeholder')}
                            label={t('scriptedActivity.fieldName.alias')}
                            $value={$selectedActivity.getPropHook('alias')}
                        />
                        <SingleSelect
                            label={t('scriptedActivity.fieldName.category')}
                            $value={$selectedActivity.getPropHook('categoryId')}
                        >
                            {$categories.value.map(g => <SelectOption key={g.categoryId} value={g.categoryId}>{g.name}</SelectOption>)}
                        </SingleSelect>
                        <MultiSelect
                            label={t('scriptedActivity.fieldName.tags')}
                            $value={$selectedActivity.getPropHook('tags')}
                        >
                            {$tags.value.map(t => <MultiSelectOption key={t.tagId} value={t.tagId}>{t.name}</MultiSelectOption>)}
                        </MultiSelect>
                        <MultiSelect
                            label={t('scriptedActivity.fieldName.pillars')}
                            $value={$selectedActivity.getPropHook('pillars')}
                        >
                            {$pillars.value.map(p => <MultiSelectOption key={p.pillarId} value={p.pillarId}>{p.name}</MultiSelectOption>)}
                        </MultiSelect>
                        <MultiSelect
                            label={t('scriptedActivity.fieldName.groups')}
                            $value={$selectedActivity.getPropHook('groups')}
                        >
                            {$groups.value.map(g => <MultiSelectOption key={g.groupId} value={g.groupId}>{g.name}</MultiSelectOption>)}
                        </MultiSelect>
                        <TextareaInput
                            placeholder={t('input.placeholder')}
                            label={t('scriptedActivity.fieldName.summary')}
                            $value={$selectedActivity.getPropHook('summary')}
                        />
                    </aside>
                    <div css={scriptedActivitiesEditStyles.verticalLine}></div>
                    <div css={scriptedActivitiesEditStyles.content}>
                        <div className="controls">
                            <div css={scriptedActivitiesEditStyles.tabs}>
                                <Button
                                    className="tab-selector"
                                    variant={$selectedTab.value === 1 ? 'contained' : 'text'}
                                    onClick={() => $selectedTab.set(1)}
                                >
                                    {t('scriptedActivities.edit.steps')}
                                </Button>
                                <Button
                                    className="tab-selector"
                                    variant={$selectedTab.value === 2 ? 'contained' : 'text'}
                                    onClick={() => $selectedTab.set(2)}
                                >
                                    {t('scriptedActivities.edit.units')}
                                </Button>
                            </div>

                            {$selectedTab.value === 1 ?
                                <Button onClick={() => { $isAddStepModalOpened.set(true) }}>
                                    <AddIcon />
                                </Button>
                                :
                                <Input
                                    $value={$search}
                                    onChange={$search.set}
                                    placeholder={t('input.searchPlaceholder')}
                                    endIcon={<MagnifyingGlassIcon />}
                                />
                            }
                        </div>
                        <Modal
                            open={!!$isAddStepModalOpened.value}
                            onClose={() => $isAddStepModalOpened.set(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <AddActivityStepModal
                                scriptedActivity={$selectedActivity.value}
                                $baseActivities={$baseActivities}
                                $isSaving={$isSaving}
                                onAdd={newStep => {
                                    const scriptedActivitySteps = [
                                        ...$selectedActivity.value.scriptedActivitySteps,
                                        {
                                            ...newStep,
                                            scriptedActivityId: $selectedActivity.value.scriptedActivityId,
                                            settings: JSON.stringify(newStep.settings)
                                        }
                                    ];

                                    const updatedScriptedActivity = {
                                        ...$selectedActivityCopy.value,
                                        scriptedActivitySteps
                                    };

                                    $isSaving.set(true);
                                    ScriptedActivitiesService.updateScriptedActivity(updatedScriptedActivity)
                                        .then((activity) => {
                                            $isSaving.set(false);
                                            $isAddStepModalOpened.set(false);
                                            activity.tags= activity.satags.map(t => t.tagId);
                                            activity.pillars= activity.sapillars.map(p => p.pillarId);
                                            activity.groups= activity.sagroups.map(g => g.groupId);
                                            $selectedActivity.set(activity)
                                            $selectedActivityCopy.set(activity);
                                        },
                                        err => $isSaving.set(false));
                                }}
                            />
                        </Modal>
                        {$selectedTab.value === 1 ?
                            <ScriptedActivitySteps
                                $selectedActivity={$selectedActivity}
                                $selectedActivityCopy={$selectedActivityCopy}
                                $isSaving={$isSaving}
                            />
                            :
                            <UnitsTab
                                $selectedActivity={$selectedActivity}
                                $customers={$customers}
                                $sites={$sites}
                                $search={$search}
                            />
                        }
                    </div>
                </div>
                {$selectedTab.value === 2 &&
                    <div className="mark-all" >
                        <Checkbox
                            checked={isMarkAllChecked}
                            onChange={(value, e) => {
                                var newActivity = null;
                                if (value) {
                                    const allUnits = [];
                                    const sitesUnits = $sites.value.filter(s => s.units.length).map(s => s.units)
                                    for (const units of sitesUnits) {
                                        for (const unit of units) {
                                            allUnits.push({
                                                unitId: unit.unitId,
                                                scriptedActivityId: $selectedActivity.value?.scriptedActivityId,
                                                unit: unit,
                                                isVisible: true
                                            })
                                        }
                                    }
                                    newActivity = {
                                        ...$selectedActivity.value,
                                        unitScriptedActivities: allUnits,
                                    }
                                } else {
                                    newActivity = {
                                        ...$selectedActivity.value,
                                        unitScriptedActivities: [],
                                    }
                                }

                                $selectedActivity.set(newActivity);
                            }}
                            label={t('actions.markAll')}
                        />
                    </div>
                }
                <div className="actions" css={scriptedActivitiesEditStyles.actions}>
                    {$selectedActivity.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                    <Button
                        variant="outlined"
                        disabled={$isSaving.value}
                        component={InternalLink}
                        to={`/manage/scripted-activities`}
                    >
                        {t('actions.cancel')}
                    </Button>
                    <Button disabled={$isSaving.value || !$selectedActivity.isValid} onClick={updateActivity}>{t('actions.update')}</Button>
                </div>
            </div>
        )
}

const unitsStyles = {
    root: css`
        height: calc(100% - 200px);
        overflow-y: auto;
        margin-top: 28px;
        position: relative;

        & > .customer-section {
            padding-right: 40px;

            & > h2 {
                color: #858585;
                margin-bottom: 40px
            }

            & .site-section > div {
                background-color: #FFFFFF;
                border-radius: 8px;
                min-height: 50px;
                display: flex;
                align-items: center;
                padding: 0 100px 0 40px;
                justify-content: space-between;
                margin-top: 12px;
            }

            & .site-section > div:first-of-type {
                width: 100%;
                background-color: #00ABA5;
                position: relative;
            }
        }
    `,
    unit: css`
        margin-left: 40px;
    `,
    arrow: css`
        position: absolute;
        right: 24px;
    `
};

const UnitsTab = ({ $customers, $selectedActivity, $search }) => {
    const $filteredCustomers = useHook([]);

    useEffect(() => {
        const searchVal = $search.value.toLowerCase();
        const siteFilter = s =>
            s.name.toLowerCase().includes(searchVal)
            || s.units.some(v => v.skuNumber.toLowerCase().includes(searchVal))
        $filteredCustomers.set($customers.value
            .filter(c =>
                c.name.toLowerCase().includes(searchVal)
                || c.sites.some(siteFilter)
            ).map(c => ({
                ...c,
                sites: c.sites.filter(siteFilter)
            }))
        );
    }, [$search.value])

    return (
        <div css={unitsStyles.root}>
            {$filteredCustomers.value.map(c =>
                <div key={c.customerId} className="customer-section">
                    {c.sites.length > 0 && c.sites.some(s => s.units.length) &&
                        <h2>{c.name}</h2>
                    }
                    {c.sites.map((s) => !!s.units.length &&
                        <SiteRow
                            key={s.siteId}
                            $selectedActivity={$selectedActivity}
                            name={s.name}
                            units={s.units}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

const SiteRow = ({ $selectedActivity, name, units }) => {
    const $expanded = useHook(true);

    return (
        <div className="site-section">
            <div onClick={() => $expanded.set(!$expanded.value)}>
                <Checkbox
                    color="secondary"
                    checked={units.every(x =>
                        $selectedActivity.value.unitScriptedActivities
                            ?.some(u => u.unitId === x.unitId)
                    )}
                    onChange={isChecked => {
                        const unitSA = $selectedActivity.value.unitScriptedActivities
                            .filter(x => !units.some(u => u.unitId === x.unitId));
                        let newActivity = null;
                        if (isChecked) {
                            newActivity = {
                                ...$selectedActivity.value,
                                unitScriptedActivities: unitSA.concat(units.map(u =>
                                ({
                                    unitId: u.unitId,
                                    scriptedActivityId: $selectedActivity.value.scriptedActivityId,
                                    unit: u,
                                    isVisible: true
                                }))),
                            };
                        } else {
                            newActivity = {
                                ...$selectedActivity.value,
                                unitScriptedActivities: unitSA
                            }
                        }

                        $selectedActivity.set(newActivity);
                        $expanded.set(true);
                    }}
                    label={name}
                />
                <Switch
                    color="secondary"
                    value={units.every(x =>
                        $selectedActivity.value.unitScriptedActivities
                            ?.some(u => u.unitId === x.unitId && u.isVisible)
                    )}
                    onChange={isChecked => {
                        const newActivity = {
                            ...$selectedActivity.value,
                            unitScriptedActivities: $selectedActivity.value.unitScriptedActivities
                                .filter(x => !units.some(u => u.unitId === x.unitId))
                                .concat(units.map(u =>
                                ({
                                    unitId: u.unitId,
                                    scriptedActivityId: $selectedActivity.value.scriptedActivityId,
                                    unit: u,
                                    isVisible: isChecked
                                }))),
                        }
                        $selectedActivity.set(newActivity);
                        $expanded.set(true);
                    }}
                />
                {$expanded.value ?
                    <ChevronUpIcon css={unitsStyles.arrow} />
                    :
                    <ChevronDownIcon css={unitsStyles.arrow} />
                }
            </div>
            {$expanded.value && units.map((u, index) =>
                <div key={u.unitId} css={unitsStyles.unit}>
                    <Checkbox
                        checked={$selectedActivity.value.unitScriptedActivities.some(x => x.unitId === u.unitId)}
                        onChange={isChecked => {
                            let newActivity = null;
                            if (isChecked) {
                                newActivity = {
                                    ...$selectedActivity.value,
                                    unitScriptedActivities: $selectedActivity.value.unitScriptedActivities
                                        ?.concat([{
                                            unitId: u.unitId,
                                            scriptedActivityId: $selectedActivity.value.scriptedActivityId,
                                            unit: u,
                                            isVisible: true
                                        }])
                                }
                            } else {
                                newActivity = {
                                    ...$selectedActivity.value,
                                    unitScriptedActivities: $selectedActivity.value.unitScriptedActivities
                                        ?.filter(x => x.unitId !== u.unitId)
                                }
                            }

                            $selectedActivity.set(newActivity);
                        }}
                        label={u.skuNumber} />
                    <Switch
                        value={
                            $selectedActivity.value.unitScriptedActivities
                                ?.some(x => x.unitId === u.unitId && x.isVisible)
                        }
                        onChange={isChecked => {
                            const newActivity = {
                                ...$selectedActivity.value,
                                unitScriptedActivities: $selectedActivity.value.unitScriptedActivities
                                    ?.filter(x => x.unitId !== u.unitId)
                                    .concat([{
                                        unitId: u.unitId,
                                        scriptedActivityId: $selectedActivity.value.scriptedActivityId,
                                        unit: u,
                                        isVisible: isChecked
                                    }])
                            }
                            $selectedActivity.set(newActivity);
                        }}
                    />
                </div>
            )}
        </div>
    )
}
