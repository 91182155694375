import { createSlice } from "@reduxjs/toolkit";

const initialStoreState = {
    currentUser: null,
    originalUser: null,
    auth0User: null,
    language: 'EN',
    accessToken: null,
    hideMainContent: false
};

export const storeState = createSlice({
    name: 'storeState',
    initialState: initialStoreState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setOriginalUser: (state, action) => {
            state.originalUser = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setAuth0User: (state, action) => {
            state.auth0User = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setStoreState: (state, action) => {
            state = action.payload;
        },
        setHideMainContent: (state, action) => {
            state.hideMainContent = action.payload;
        },
    }
})

export const {
    setCurrentUser,
    setOriginalUser,
    setLanguage,
    setAuth0User,
    setAccessToken,
    setStoreState,
    setHideMainContent
} = storeState.actions;

export default storeState.reducer;
