// ts-check

/** @type import('@mui/material/styles/createTheme').ThemeOptions */
export const ThemeColors = {
    palette: {
        // mode: 'dark',
        primary: {
            light: '#33bbb7',
            main: '#00ABA5',
            dark: '#007773',
            contrastText: '#fff',
        },
        // text: {
        //     primary: '#858585'
        // },
    },
    typography: {
        fontFamily: ['Mustica Pro'],
        allVariants: {
            color: '#858585',
            // background: '#EEEEEE',
        },
    },
    components: {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    width: '240px',
                    padding: '8px 0',
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0,
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: '10px 20px',
                    '&:hover': {
                        background: '#E0E0E0 0% 0% no-repeat padding-box'
                    }
                }
            }
        }
    }
};
