/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { AddSiteContent } from '../Sites/AddSiteContent';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useMemo } from 'react';
import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { TrashCanIcon } from '../../../CoreComponents/CustomIcons';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { SiteService } from '../../../Services/SiteService';
import { TabLayout } from '../../../CoreComponents/Layout';
import { SiteTab } from '../Sites/SiteTab';
import { SiteUnitsTab } from '../Sites/SiteUnitsTab';

const styles = {
    root: css`
        display: flex;
        flex-direction: column;

        height: 85vh;
        padding: 40px 0 40px 28px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & > h2 {
            margin: 0 0 40px 12px;
        }
    `,
    actions: css`
        position: relative;

        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    icon: css`
        color: #00ABA5
    `,
    input: css`
        width: 90%;
    `
}

export const SitesTab = ({ $selectedCustomer, $refreshCustomers }) => {
    const { t } = useTranslation();
    const $selectedSite = useObjectHook(null);
    const $prevSelectedSite = useHook(null);
    const $tabs = useHook([]);
    const $isAddSiteModalOpened = useHook(false);

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton onClick={() => $selectedSite.set(rowData)}>
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('sites.table.name'),
        },
        {
            id: 'pin',
            label: t('sites.table.pin'),
        },
        {
            id: 'customerName',
            label: t('sites.table.customer.name'),
            CellRender: ({ rowData }) => {
                return $selectedCustomer.value.name || '-';
            }
        },
        {
            id: 'contactName',
            label: t('sites.table.contactName'),
        },
        {
            id: 'phone',
            label: t('sites.table.phone'),
        },
        {
            id: 'email',
            label: t('sites.table.email'),
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton onClick={() => deleteCustomerSite(rowData.siteId)} tooltip={t('actions.delete')}>
                            <TrashCanIcon />
                        </IconButton>
                    </>
                );
            }
        },
    ], []);

    useEffect(() => {
        if (!$selectedSite.value) {
            return;
        }

        $tabs.set([
            { name: t('sites.editModal.sitesTab'), ContentComponent: () => <SiteTab $selectedSite={$selectedSite} />, isDefault: true },
            { name: t('sites.editModal.siteUnitsTab'), ContentComponent: () => <SiteUnitsTab $selectedSite={$selectedSite} /> },
        ]);
    }, [$selectedSite.value]);

    useEffect(() => {
        if ($prevSelectedSite.value && $selectedSite.value) {
            $refreshCustomers.set({});
            $selectedCustomer.set(null);
            $prevSelectedSite.set(null);
            $selectedSite.set(null);
            return;
        }

        $prevSelectedSite.set($selectedSite.value);
    }, [$selectedSite.value]);

    const deleteCustomerSite = (siteId) => {
        SiteService.deleteSite(siteId)
            .then(() => {
                $selectedCustomer.set(null);
                $refreshCustomers.set({})
            })
    }

    return (
        <>
            <div css={styles.root}>
                <h2>{t('sites.editModal.title')}</h2>
                <TableCSF
                    headCells={headCells} rows={$selectedCustomer.value.sitesObj} rowKeySelector={r => r.siteId}
                    customActions={
                        <Button onClick={() => $isAddSiteModalOpened.set(true)} >{t('sites.addModal.title')}</Button>
                    }
                />
            </div>
            <Modal
                open={$isAddSiteModalOpened.value}
                onClose={() => $isAddSiteModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddSiteContent
                    onAdd={() => {
                        $isAddSiteModalOpened.set(false);
                        $refreshCustomers.set({});
                        $selectedCustomer.set(null);
                    }}
                    onCancel={() => $isAddSiteModalOpened.set(false)}
                    customerId={$selectedCustomer.value.customerId} />
            </Modal>
            <Modal
                open={!!$selectedSite.value}
                onClose={() => $selectedSite.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <TabLayout items={$tabs.value} />
            </Modal>
        </>
    )
}
