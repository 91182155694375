/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { Button, IconButton } from "../../../CoreComponents/Button";
import { PenIcon, TrashCanIcon } from "../../../CoreComponents/CustomIcons";
import { Modal } from "../../../CoreComponents/Modal";
import { TableCSF } from "../../../CoreComponents/TableComponents/TableCSF";
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook } from "../../../CoreComponents/Utils";
import { GroupService } from "../../../Services/GroupService";
import { UserService } from "../../../Services/UserService";
import { shortDate } from "../../../Utils/Common";
import { EditGroupNameContent } from "./EditGroupNameContent";
import { DeleteConfirmationModal } from "../../../CoreComponents/DeleteConfirmationModal";
import { MultiSelect, MultiSelectOption } from "../../../CoreComponents/SelectListComponents/MultiSelect";

const userGroupsListStyles = {
    root: css`
        padding: 40px 0 40px 28px;
        width: unset;
        height: 85vh;
        display: flex;
        flex-direction: column;

        @media (min-width: 1200px) {
            width: 900px;
        }

        & > .group-name {
            display: flex;
            align-items: center;
            padding: 0 0 40px 12px;

            & > h2 {
                margin: 0;
            }

            & > button {
                margin-left: 20px;
                color: #ffffff;
                background-color: #00aba5;
                border-radius: 8px;
                width: 50px;
                height: 40px;

                & > svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        & .table-container {
            & > .bottom-padding-div {
                height: 40px;
                top: calc(100% - 40px);
            }
        }
    `,
    customActions: css`
        display: flex;
        width: 320px;

        & .input-root {
            margin: 0;
        }

        & .MuiSelect-root {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            width: 280px;
            min-width: unset;
        }

        & .add-button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    `
}
export const EditUserGroup = ({ $selectedGroup, enableGroupNameEdit }) => {
    const { t } = useTranslation();
    const $users = useHook([]);
    const $selectedUsers = useHook([]);
    const $isSaving = useHook(false);
    const $isEditGroupNameModalOpened = useHook(false);
    const $userIdToDelete = useHook(null);
    const userGroups = useMemo(() =>
        $selectedGroup.value?.userGroups.map(ug => ({
            ...ug,
            username: ug.user.username,
            name: ug.user.firstName + ' ' + ug.user.lastName,
            dateCreated: ug.user.dateCreated ? shortDate(new Date(ug.user.dateCreated)) : '-',
            lastUnitLogin: ug.user.lastUnitLogin ? shortDate(new Date(ug.user.lastUnitLogin)) : '-'
        })) || []
        , [$selectedGroup.value])

    const headCells = useMemo(() => [
        {
            id: 'username',
            label: t('users.table.username'),
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.user.username;
            }
        },
        {
            id: 'name',
            label: t('users.table.name'),
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.user.firstName + ' ' + rowData.user.lastName;
            }
        },
        {
            id: 'dateCreated',
            label: t('users.table.createdOn'),
            isSortable: true,
            columnWidth: 140,
            CellRender: ({ rowData }) => {
                return rowData.user.dateCreated ? shortDate(new Date(rowData.user.dateCreated)) : '-';
            }
        },
        {
            id: 'lastUnitLogin',
            label: t('users.table.lastUnitLogin'),
            isSortable: true,
            columnWidth: 140,
            CellRender: ({ rowData }) => {
                return rowData.user.lastUnitLogin ? shortDate(new Date(rowData.user.lastUnitLogin)) : '-';
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData, additionalData: $selectedGroup }) => {
                return (
                    <IconButton onClick={() => $userIdToDelete.set(rowData.userId)} tooltip={t('actions.delete')}>
                        <TrashCanIcon />
                    </IconButton>
                );
            }
        },
    ], [t]);

    useEffect(() => {
        UserService.getAll(9999, null, null, $selectedGroup.value.site.customerId)
            .then(users => {
                $users.set(users)
            });
    }, [])

    const addUsersToGroup = () => {
        var newGroup = {
            ...$selectedGroup.value,
            userGroups: $selectedGroup.value?.userGroups
                ?.concat($selectedUsers.value.map(u => ({
                    groupId: $selectedGroup.value.groupId,
                    userId: u.userId,
                    user: u
                })))
        };
        $isSaving.set(true);
        (async () => {
            // adding the new users in the group
            const userGroupsRequests = newGroup.userGroups
                .filter(u => !$selectedGroup.value.userGroups.some(ug => ug.userId === u.userId))
                .map(ug => GroupService.postUserGroup(ug));

            await Promise.all(userGroupsRequests).finally(() => $isSaving.set(false));
            $selectedGroup.set(newGroup);

        })()
    }

    const deleteUserFromGroup = (userId) => {
        $isSaving.set(true);
        const newGroup = ({
            ...$selectedGroup.value,
            userGroups: $selectedGroup.value.userGroups.filter(u => u.userId !== userId)
        });
            (async () => {
                // delete users from the group if they are not in the new object
                const userGroupsRequests = newGroup.userGroups.concat($selectedGroup.value.userGroups
                    .filter(u => !newGroup.userGroups.some(ug => ug.userId === u.userId))
                    .map(ug => GroupService.deleteUserGroup(ug.groupId, ug.userId)))

                await Promise.all(userGroupsRequests).finally(() => {
                    $isSaving.set(false);
                    $userIdToDelete.set(null);
                });
                $selectedGroup.set(newGroup);

            })()
    }

    return ($selectedGroup.value &&
        <div css={userGroupsListStyles.root}>
            <div className="group-name">
                <h2>{$selectedGroup.value.groupName}</h2>
                {enableGroupNameEdit &&
                    <>
                        <IconButton onClick={() => $isEditGroupNameModalOpened.set(true)}>
                            <PenIcon />
                        </IconButton>
                        <Modal
                            open={$isEditGroupNameModalOpened.value}
                            onClose={() => $isEditGroupNameModalOpened.set(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            disableCloseButton
                        >
                            <EditGroupNameContent
                                onCancel={() => $isEditGroupNameModalOpened.set(false)}
                                onEdit={() => $isEditGroupNameModalOpened.set(false)}
                                $selectedGroup={$selectedGroup}
                            />
                        </Modal>
                    </>
                }
            </div>
            <TableCSF
                customActions={
                    <div css={userGroupsListStyles.customActions}>
                        <MultiSelect $value={$selectedUsers}>
                            {$users.value
                                .filter(u => !$selectedGroup.value.userGroups.some(ug => ug.userId === u.userId))
                                .map(u =>
                                    <MultiSelectOption key={u.userId} value={u}>
                                        {`${u.firstName} ${u.lastName} (${u.username})`}
                                    </MultiSelectOption>
                                )
                            }
                        </MultiSelect>
                        <Button
                            className="add-button"
                            disabled={!$selectedUsers.value?.length || $isSaving.value}
                            onClick={addUsersToGroup}
                        >
                            {t('actions.add')}
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={userGroups}
                rowKeySelector={r => r.userId}
                showAdvancedSearch={false}
                additionalData={$selectedGroup}
                disablePagination
            />
             <DeleteConfirmationModal
                onDelete={() => deleteUserFromGroup($userIdToDelete.value)}
                open={Boolean($userIdToDelete.value)}
                onCancel={() => $userIdToDelete.set(null)}
                title={t('userGroup.deleteModal.title')}
                description={t('userGroup.deleteModal.description')}
                deleteLabel={t('actions.delete')}
            />
        </div>
    )
}
