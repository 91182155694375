import { deleteData, getData, postData, putData, urlQueryString } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const GroupService = {
    getGroups: function (limit, customerId, siteId) {
        const qs = urlQueryString({
            limit,
            customer_id: customerId,
            site_id: siteId,
        }, true);
        return getData(`${ApiHost}/api/UserGroups/group?${qs}`)
    },
    getGroupsByUserId:  function (userId){
        return getData(`${ApiHost}/api/UserGroups/${userId}`)
    },
    deleteUserGroup: function (groupId, userId){
        return deleteData(`${ApiHost}/api/UserGroups/${groupId}/user/${userId}`)
    },
    deleteGroup: function (groupId) {
        return deleteData(`${ApiHost}/api/UserGroups/group/${groupId}`)
    },
    updateGroup: function (group){
        return putData(`${ApiHost}/api/UserGroups/group`, group)
    },
    postGroup: function (group){
        return postData(`${ApiHost}/api/UserGroups/group`, group)
    },
    postUserGroup: function (userGroup){
        return postData(`${ApiHost}/api/UserGroups`, userGroup)
    }
};
