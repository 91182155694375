/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { Input, TextareaInput } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    maxLengthValidator,
    requiredValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { UnitService } from '../../../Services/UnitService';
import { AdminTypes } from '../../../Utils/Constants';
import { AlertText } from '../../../CoreComponents/Alert';
import { useSelector } from 'react-redux';

const unitTabStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .inputs {
            display: flex;
            flex-direction: column;
            width: 100%;

            & textarea {
                width: calc(100% - 20px);
                height: 120px;
                resize: none;
                overflow-y: auto;
            }

            & > .sku-number {
                display: inline-flex;
                margin: 0 0 30px 0;

                width: calc(100% - 40px);
                @media (min-width: 1200px) {
                    width: calc(50% - 40px);
                }
            }

            & > .notes {
                margin: 0;
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button {
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }
    `,
};

const UnitValidator = {
    skuNumber: [requiredValidator, maxLengthValidator(50)],
};

export const UnitTab = ({ $selectedUnit }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const $isForceValidating = useHook(true);
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;
    const $unitCopy = useObjectHook(
        $selectedUnit.value,
        UnitValidator,
        null,
        $isForceValidating.value
    );

    const updateUnit = () => {
        $isForceValidating.set(true);
        const errors = $unitCopy.validate();
        if (errors) {
            return;
        }
        UnitService.putUnit($unitCopy.value.unitId, $unitCopy.value)
            .then(() => $selectedUnit.set($unitCopy.value));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={unitTabStyles.root}>
                <h2>{t('units.editModal.title')}</h2>
                <div className="inputs">
                    <Input
                        disabled={!isTrazerAdmin}
                        className="sku-number"
                        placeholder={t('input.placeholder')}
                        required
                        label={t('units.fieldName.skuNumber')}
                        $value={$unitCopy.getPropHook('skuNumber')}
                    />
                    {storeState.currentUser.admin.adminTypeId >= AdminTypes.TRAZERAdmin &&
                        <TextareaInput className="notes" placeholder={t('input.placeholder')} label={t('units.fieldName.notes')} $value={$unitCopy.getPropHook('notes')} />
                    }
                </div>

                <div className="actions">
                    {$unitCopy.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                    <Button disabled={!$unitCopy.isValid || !isTrazerAdmin} onClick={updateUnit}>{t('actions.update')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
