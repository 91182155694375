/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

/* eslint-disable react-hooks/exhaustive-deps */
import { css } from "@emotion/react";
import { useMemo } from "react";
import { IconButton } from "../../../../CoreComponents/Button";
import { EditIcon, TrashCanIcon } from "../../../../CoreComponents/CustomIcons";
import { DeleteConfirmationModal } from "../../../../CoreComponents/DeleteConfirmationModal";
import { Modal } from "../../../../CoreComponents/Modal";
import { TableCSF } from "../../../../CoreComponents/TableComponents/TableCSF";
import { useTranslation } from "../../../../CoreComponents/Translation";
import { useHook } from "../../../../CoreComponents/Utils";
import { EditActivityStepModal } from "./EditStepContent";
import { ScriptedActivitiesService } from "../../../../Services/ScriptedActivitiesService";

const tableStyle = css`
    width: calc(100% - 4px);
    height: calc(100% - 40px);
    margin-left: -12px;
`
export const ScriptedActivitySteps = ({ $selectedActivity, $selectedActivityCopy, $isSaving }) => {
    const { t } = useTranslation();
    const $stepIdToDelete = useHook(null);
    const $stepForEdit = useHook(null);

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <IconButton
                        tooltip={t('actions.edit')}
                        onClick={() => $stepForEdit.set(rowData)}
                    >
                        <EditIcon />
                    </IconButton>
                );
            }
        },
        {
            id: 'orderId',
            label: t('scriptedActivitiesSteps.table.order'),
            isSortable: true
        },
        {
            id: 'name',
            label: t('scriptedActivitiesSteps.table.name'),
            isSortable: true
        },
        {
            id: 'baseActivity',
            label: t('scriptedActivitiesSteps.table.baseActivity'),
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.baseActivity?.name;
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData, additionalData: { $isSaving } }) => {
                return (
                    <>
                        <IconButton disabled={$isSaving.value} onClick={() => $stepIdToDelete.set(rowData.scriptedActivityStepId)}>
                            <TrashCanIcon />
                        </IconButton>
                    </>
                );
            }
        },
    ], [t]);

    const deleteStep = id => {
        const scriptedActivitySteps = $selectedActivity.value.scriptedActivitySteps
            .filter(s => s.scriptedActivityStepId !== id);
        $selectedActivity.set({
            ...$selectedActivity.value,
            scriptedActivitySteps
        });

        const updatedScriptedActivity = {
            ...$selectedActivityCopy.value,
            scriptedActivitySteps
        };
        $selectedActivityCopy.set(updatedScriptedActivity);

        $isSaving.set(true);
        ScriptedActivitiesService.updateScriptedActivity(updatedScriptedActivity)
            .then(() => {
                $isSaving.set(false);
                $stepForEdit.set(null);
            },
            err => $isSaving.set(false));

        $stepIdToDelete.set(null);
    }

    return (
        <div css={tableStyle}>
            <TableCSF
                headCells={headCells}
                rows={$selectedActivity.value.scriptedActivitySteps}
                rowKeySelector={r => r.scriptedActivityStepId}
                showSearch={false}
                disablePagination
                additionalData={{ $isSaving }}
            />
            <Modal
                open={!!$stepForEdit.value}
                onClose={() => $stepForEdit.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EditActivityStepModal
                    scriptedActivity={$selectedActivity.value}
                    activityStep={$stepForEdit.value}
                    $isSaving={$isSaving}
                    onEdit={editedStep => {
                        const stepIndex = $selectedActivity.value.scriptedActivitySteps
                            .findIndex(x => x.scriptedActivityStepId === editedStep.scriptedActivityStepId)

                        const scriptedActivitySteps = [
                            ...$selectedActivity.value.scriptedActivitySteps.slice(0, stepIndex),
                            {
                                ...editedStep,
                                scriptedActivityId: $selectedActivity.value.scriptedActivityId,
                                settings: JSON.stringify(editedStep.settings)
                            },
                            ...$selectedActivity.value.scriptedActivitySteps.slice(stepIndex + 1)
                        ];

                        const newActivity = {
                            ...$selectedActivity.value,
                            scriptedActivitySteps
                        };
                        $selectedActivity.set(newActivity);

                        const updatedScriptedActivity = {
                            ...$selectedActivityCopy.value,
                            scriptedActivitySteps
                        };
                        $selectedActivityCopy.set(updatedScriptedActivity);

                        $isSaving.set(true);
                        ScriptedActivitiesService.updateScriptedActivity(updatedScriptedActivity)
                            .then(() => {
                                $isSaving.set(false);
                                $stepForEdit.set(null);
                            },
                            err => $isSaving.set(false));
                    }}
                />
            </Modal>
            <DeleteConfirmationModal
                onDelete={() => deleteStep($stepIdToDelete.value)}
                open={Boolean($stepIdToDelete.value)}
                onCancel={() => $stepIdToDelete.set(null)}
                title={t('scriptedActivityStep.deleteModal.title')}
                description={t('scriptedActivityStep.deleteModal.description')}
                deleteLabel={t('actions.delete')}
            />
        </div>
    )
}
