/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../../CoreComponents/Translation";
import { useHook, useObjectHook } from "../../CoreComponents/Utils";
import { subMonths} from "date-fns";
import { useEffect, useMemo } from "react";
import { SiteService } from '../../Services/SiteService';
import { parseDate, shortDate } from "../../Utils/Common";
import { DateRangeSelector } from "../../CoreComponents/DateRangeSelector";
import { Button } from "../../CoreComponents/Button";
import { ChevronLeftIcon, PrintIcon } from "../../CoreComponents/CustomIcons";
import { CircularLoader } from "../../CoreComponents/Loaders";
import { CustomScrollContainer } from "../../CoreComponents/Layout";
import { ReportTable } from "./ReportTable";
import { css } from "@emotion/react";
import { Card } from "../../CoreComponents/Card";
import { LineChart } from "../../CoreComponents/ChartComponents/LineChart";
import { BarChart } from '../../CoreComponents/ChartComponents/BarChart';

const asideWidth = '520px';
const infoMargin = 40;
const siteReportStyles = {
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
    layout: css`
        display: flex;
        padding: 20px 40px;
        height: 100%;

        & aside {
            display: flex;
            flex-direction: column;

            width: ${asideWidth};
            min-width: ${asideWidth};
            padding-right: 39px;
            border-right: 2px solid #CBCBCB;
            height: 100%;

            & .site-info {
                display: flex;
                flex-direction: column;
                height: 100%;

                & > div:last-of-type {
                    flex: 1 0;
                }

                & dl {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    overflow: visible;

                    & dt {
                        float: left;
                        clear: left;
                        width: calc(50% - 20px);

                        text-align: right;
                        font: normal normal normal 16px/24px Roboto;
                        letter-spacing: 0.14px;
                        color: #858585;
                    }
                    & dd {
                        margin: 0 0 0 40px;
                        padding: 0 0 11px 0;
                        width: calc(50% - 20px);

                        text-align: left;
                        font: normal normal bold 16px/24px Mustica Pro;
                        letter-spacing: 0.14px;
                        color: #676767;
                    }
                }
            }

            & > .table-section {
                height: calc(50% - ${infoMargin / 2}px);
                display: flex;
                flex-direction: column;

                & > .table {
                    height: calc(100% - 40px);
                    overflow-y: auto;
                    padding: 0 20px 20px 20px;

                    & thead tr:nth-child(1) th {
                        position: sticky;
                        top: 0;
                        z-index: 10;
                        background: #EEEEEE 0% 0% no-repeat padding-box;
                    }
                }

                &:first-of-type {
                    margin-bottom: ${infoMargin}px;
                }
            }
        }
        @media (max-width: 1200px) {

            & aside {
                width: unset;
                min-width: unset;
            }
        }

        @media print {
            height: auto;
            display: block;

            & aside {
                width: 100%;
                border-right: none;

                & .site-info {
                    break-inside: avoid;
                }

                & .table-section {
                    break-inside: avoid;
                }
            }
        }
    `,
    mainContent: css`
        width: calc(100% - ${asideWidth});
        height: 100%;
        overflow: clip;

        & .content {
            height: calc(100% - 90px);
            padding: 0 40px;

            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        & .controls {
            padding: 0 40px 50px 40px;
            background: #EEEEEE 0% 0% no-repeat padding-box;

            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & .date-range-selector {
                margin-left: auto;
            }

            & > button {
                width: unset;

                &.go-back-action {
                    min-width: unset;
                }

                &.print-action {
                    min-width: unset;
                    margin-right: 10px;
                }
            }
        }

        @media (max-width: 1200px) {
            overflow: unset;
            width: 100%;
            height: auto;

            & .content {
                height: auto;
                padding: 1px;
                overflow: unset;
            }
        }

        @media print {
            overflow: unset;
            width: 100%;
            height: auto;

            & .controls {
                display: none;
            }

            & .content {
                height: auto;
                padding: 1px;
                overflow: unset;
            }
        }
    `,
};

export const SiteReport = () => {
    const { siteId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const $isLoading = useHook(true);
    const $isPrinting = useHook(false);
    const $dateRange = useObjectHook({
        dateInterval: 'week',
        startDate: subMonths(new Date(), 2),
        endDate: new Date(),
    });

    const $rawData = useHook(null);
    const $reportData = useHook(null);

    const mostPopularScriptedActivitiesHeaders = useMemo(() => ({
        name: t('customerReport.scriptedActivitiesTable.name'),
        sessionsCount: t('customerReport.scriptedActivitiesTable.sessionsCount'),
    }), [t]);

    const providerSummaryHeaders = useMemo(() => ({
        providerName: t('customerReport.providerTable.providerName'),
        testsAdministered: t('customerReport.providerTable.testsAdministered'),
    }), [t]);

    useEffect(() => {
        (async () => {
            $isLoading.set(true);
            const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
            const endDate = $dateRange.value.endDate.toISOString().split('T')[0];

            const [
                siteOverview,
                sessionsWeeklyTrend,
                newUsersWeeklyTrend,
                scriptedActivities,
                providerSummary
            ] = await Promise.all([
                SiteService.overview(
                    siteId,
                    startDate,
                    endDate
                ),
                SiteService.overviewTrend(
                    siteId,
                    'tests',
                    $dateRange.value.dateInterval,
                    startDate,
                    endDate
                ),
                SiteService.overviewTrend(
                    siteId,
                    'users',
                    $dateRange.value.dateInterval,
                    startDate,
                    endDate
                ),
                SiteService.overviewScriptedActivities(
                    siteId,
                    startDate,
                    endDate,
                    10
                ),
                SiteService.providerSummary(
                    siteId,
                    startDate,
                    endDate
                ),
            ]);

            siteOverview.lastOnline = siteOverview.lastOnline ? shortDate(siteOverview.lastOnline) : null;
            $rawData.set({
                siteOverview: siteOverview,
                sessionsWeeklyTrend: sessionsWeeklyTrend.map(x => ({ ...x, item1: shortDate(parseDate(x.item1, 'YYYY-ww')) })),
                newUsersWeeklyTrend: newUsersWeeklyTrend.map(x => ({ ...x, item1: shortDate(parseDate(x.item1, 'YYYY-ww')) })),
                scriptedActivities,
                providerSummary
            })
            $isLoading.set(false);
        })()
    }, [$dateRange.value]);

    useEffect(() => {
        if (!$rawData.value) {
            return;
        }

        $reportData.set({
            siteOverview: $rawData.value.siteOverview,
            mostPopularScriptedActivities: $rawData.value.scriptedActivities.map(x => ({ name: x.item1, sessionsCount: x.item2 })),
            sessionsWeeklyTrend: [{
                label: t('customerReport.chartData.sessions'),
                exportSettings: {
                    xAxis: t('customerReport.chartExportDataField.date'),
                    yAxis: t('customerReport.chartData.sessions'),
                },
                data: $rawData.value.sessionsWeeklyTrend.map(x => ({ label: x.item1, value: x.item2 }))
            }],
            newUsersWeeklyTrend: [{
                label: t('customerReport.chartData.users'),
                exportSettings: {
                    xAxis: t('customerReport.chartExportDataField.date'),
                    yAxis: t('customerReport.chartData.users'),
                },
                data: $rawData.value.newUsersWeeklyTrend.map(x => ({ label: x.item1, value: x.item2 }))
            }],
            providerSummaryGraph: [{
                label: t('customerReport.chartData.sessions'),
                exportSettings: {
                    xAxis: t('customerReport.chartData.providers'),
                    yAxis: t('customerReport.chartData.sessions'),
                },
                data: $rawData.value.providerSummary.filter(x => x.testCount > 0).map(x => ({ label: `${x.provider.firstName} ${x.provider.lastName}`, value: x.testCount }))
            }],
            providerSummaryTable: $rawData.value.providerSummary.map(x => ({ providerName: `${x.provider.firstName} ${x.provider.lastName}`, testsAdministered: x.testCount })),
        });
    }, [t, $rawData.value])

    return ($isLoading.value || !$reportData.value ?
        <div css={siteReportStyles.loadingContainer}>
            <CircularLoader />
        </div>
        :
        <div css={siteReportStyles.layout}>
            <aside>
                <div className="site-info">
                    <h2>{t('siteReport.overviewTitle.siteOverview')}</h2>
                    <dl>
                        <dt>{t('customerReport.sitesOverviewTable.siteName')}</dt>
                        <dd>{$reportData.value.siteOverview.siteName}</dd>
                        <dt>{t('customerReport.sitesOverviewTable.lastOnline')}</dt>
                        <dd>{$reportData.value.siteOverview.lastOnline}</dd>
                        <dt>{t('customerReport.sitesOverviewTable.totalUsers')}</dt>
                        <dd>{$reportData.value.siteOverview.totalUsers}</dd>
                        <dt>{t('customerReport.sitesOverviewTable.totalSessions')}</dt>
                        <dd>{$reportData.value.siteOverview.totalSessions}</dd>
                    </dl>
                </div>
                {$reportData.value.mostPopularScriptedActivities.length ?
                    <div className="table-section">
                        <h2>{t('customerReport.tableTitle.mostPopularScriptedActivities')}</h2>
                        <div className="table">
                            <ReportTable
                                disableRowHeader
                                headers={mostPopularScriptedActivitiesHeaders}
                                data={$reportData.value.mostPopularScriptedActivities}
                            />
                        </div>
                    </div>
                : null}
            </aside>
            <div css={siteReportStyles.mainContent}>
                <div className="controls">
                    <Button
                        className="go-back-action"
                        onClick={() => window.history.length > 2 ?
                            navigate(-1)
                            : navigate('/manage/sites')
                        }
                    >
                        <ChevronLeftIcon />
                    </Button>
                    <DateRangeSelector
                        label={t('customerReport.controls.dateRange')}
                        $value={$dateRange}
                        design="slim"
                        labelProps={{
                            className: 'date-range-selector'
                        }}
                    />
                    <Button
                        className="print-action"
                        disabled={$isPrinting.value || $isLoading.value}
                        onClick={() => {
                            $isPrinting.set(true);
                            setTimeout(function () {
                                window.print();
                                $isPrinting.set(false);
                            }, 1000);
                        }}
                    >
                        <PrintIcon />
                    </Button>
                </div>

                {$isLoading.value ?
                    <div css={siteReportStyles.loadingContainer}>
                        <CircularLoader />
                    </div>
                    :
                    <CustomScrollContainer className="content">
                        <Card title={t('customerReport.chartTitle.sessionsWeeklyTrend')}>
                            <LineChart
                                title={t('customerReport.chartTitle.sessionsWeeklyTrend')}
                                exportFileName={t('customerReport.chartTitle.sessionsWeeklyTrend')}
                                enableOptionsMenu
                                datasets={$reportData.value.sessionsWeeklyTrend}
                                xAxisLabel={t('siteReport.sessionsWeeklyTrend.xAxis')}
                                yAxisLabel={t('siteReport.sessionsWeeklyTrend.yAxis')}
                                isPrinting={$isPrinting.value}
                            />
                        </Card>
                        <Card title={t('customerReport.chartTitle.newUsersWeeklyTrend')}>
                            <LineChart
                                title={t('customerReport.chartTitle.newUsersWeeklyTrend')}
                                exportFileName={t('customerReport.chartTitle.newUsersWeeklyTrend')}
                                enableOptionsMenu
                                datasets={$reportData.value.newUsersWeeklyTrend}
                                xAxisLabel={t('customerReport.newUsersWeeklyTrend.xAxis')}
                                yAxisLabel={t('customerReport.newUsersWeeklyTrend.yAxis')}
                                isPrinting={$isPrinting.value}
                            />
                        </Card>
                        <Card title={t('customerReport.chartTitle.providerSummary')}>
                            <BarChart
                                title={t('customerReport.chartTitle.providerSummary')}
                                exportFileName={t('customerReport.chartTitle.providerSummary')}
                                enableOptionsMenu
                                datasets={$reportData.value.providerSummaryGraph}
                                xAxisLabel={t('customerReport.providerSummary.xAxis')}
                                yAxisLabel={t('customerReport.providerSummary.yAxis')}
                                displayHorizontally={true}
                            />
                        </Card>
                        <div className="table-section">
                            <h2>{t('customerReport.tableTitle.providerSummary')}</h2>
                            <div className="table">
                                <ReportTable
                                    disableRowHeader
                                    headers={providerSummaryHeaders}
                                    data={$reportData.value.providerSummaryTable}
                                />
                            </div>
                        </div>
                    </CustomScrollContainer>
                }
            </div>
        </div>
    );
};
