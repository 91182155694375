// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

const summaryCardStyle = css`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #28326529;
    border-radius: 8px;
    // width: 270px;

    & > div {
        display: flex;
        padding: 8px 0;

        & > div {
            display: flex;
            flex-direction: column;
            padding: 10px 15px;
        }
    }

    & > .single-summary {
        justify-content: center;
    }

    & > .title-header {
        background: #00ABA5 0% 0% no-repeat padding-box;
        border-radius: 8px 8px 0px 0px;
        height: 40px;
        padding: 0;

        text-align: center;
        font: normal normal medium 16px/24px Roboto;
        letter-spacing: 0.14px;
        color: #FFFFFF;

        & > span {
            margin: auto;
        }
    }

    & .separator {
        // height: 100%;
        height: 60px;
        width: 2px;
        padding: 0;
        margin: auto;
        background: #CBCBCB 0% 0% no-repeat padding-box;
    }

    & .value-name {
        font: normal normal bold 16px/24px Mustica Pro;
        letter-spacing: 0.14px;
        color: #858585;
        white-space: nowrap;
    }
    & .value {
        font: normal normal normal 16px/24px Roboto;
        letter-spacing: 0.14px;
        color: #CBCBCB;
    }

    & .single-value {
        font: normal normal bold 24px/24px Mustica Pro;
        letter-spacing: 0.14px;
        color: #CBCBCB;
    }

    & .summary {
        // width: 100%;
        min-width: 220px;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;

        & > span {
            text-align: left;
            font: normal normal bold 24px/24px Mustica Pro;
            letter-spacing: 0.22px;
            color: #3A817F;
            // white-space: nowrap;

            margin-bottom: 5px;
        }

        & > div {
            padding: 0;

            text-align: left;
            font: normal normal normal 16px/24px Roboto;
            letter-spacing: 0.14px;
            color: #CBCBCB;
        }
    }
`;

export const SummaryCard = ({ title, left, right }) => (
    <div css={summaryCardStyle}>
        <div className="title-header">
            <span>{title}</span>
        </div>
        {left && right ?
            <div>
                <div>
                    <span className="value-name">{left.name}</span>
                    <span className="value">{left.value}</span>
                </div>
                <div className="separator"></div>
                <div>
                    <span className="value-name">{right.name}</span>
                    <span className="value">{right.value}</span>
                </div>
            </div>
            :
            <div className='single-summary'>
                <div>
                    <span className="single-value">{left.value}</span>
                </div>
            </div>
        }
    </div>
);

export const SummaryTitleCard = ({ activityName, completion, testType }) => (
    <div css={summaryCardStyle}>
        <div className="summary">
            <span>{activityName}</span>
            {completion != null &&
                <div>{completion} {testType}</div>
            }
        </div>
    </div>
);
