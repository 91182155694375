/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Button } from '../../CoreComponents/Button';
import { Checkbox } from '../../CoreComponents/Checkbox';
import { TableCSF } from '../../CoreComponents/TableComponents/TableCSF';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook } from '../../CoreComponents/Utils';
import { TestService } from '../../Services/TestService';
import { exportCSV, metersToFeet, metersToCentimeters, metersToInches, shortTime } from '../../Utils/Common';
import { NoData, TestStatusesTranslationKeysObj, UnitType, UnitTypeTranslationKeysArr } from '../../Utils/Constants';
import { CalculatedLeftRightDifference, CalculatedReactionTimeLeftRightDifference } from '../Reports/TestReport/PrepareTestData';
import { SingleSelect } from '../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../CoreComponents/SelectListComponents/SelectOption';
import { useDispatch } from 'react-redux';
import { setTests } from '../../globalStates/analyticsState';

const testsTabStyles = {
    root: css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        & .custom-actions {
            width: 100%;
        }
    `,
    selectAllCheckbox: css`
        margin: 40px 0 0 52px;
        & > label {
            width: 100px;
        }
    `,
    actions: css`
        display: flex;
        gap: 20px;

        & > .units-selection {
            margin-left: auto;
        }
    `,
};

export const TestsTab = ({
    $availableTests,
    $selectedTests,
    $cachedTestsList,
    $selectedUnitType,
    $rawReportData,
    onGenerateReport,
    $isGeneratingReportData
}) => {
    const { t } = useTranslation();
    const $selectedTestsCount = useHook(
        Object.values($selectedTests.value)
            .filter(x => x)
            .length
    );
    const $isLoading = useHook(false);
    const $hasRepsData = useHook(Object.values($selectedTests.value).some(x => x.hasRepsData));
    const dispatch = useDispatch();

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            CellRender: ({ rowData, additionalData }) => {
                const { $selectedTests, $selectedTestsCount } = additionalData;
                const isTestSelected = !!$selectedTests.value[rowData.testId];
                return (
                    <Checkbox
                        checked={isTestSelected}
                        onChange={value => {
                            const newSelectedTests = {
                                ...$selectedTests.value,
                                [rowData.testId]: rowData,
                            };

                            if (!value) {
                                delete newSelectedTests[rowData.testId];
                            }

                            $hasRepsData.set(Object.values(newSelectedTests).some(x => x.hasRepsData));
                            $selectedTests.set(newSelectedTests);
                            $selectedTestsCount.set(
                                $selectedTestsCount.value + (value ? 1 : -1)
                            )
                        }}
                    />
                );
            }
        },
        {
            id: 'scriptedActivityName',
            label: t('analyticsTestsTab.table.scriptedActivity'),
            isSortable: true
        },
        {
            id: 'fullName',
            label: t('analyticsTestsTab.table.name'),
            isSortable: true
        },
        {
            id: 'username',
            label: t('analyticsTestsTab.table.username'),
            isSortable: true
        },
        {
            id: 'startTime',
            label: t('analyticsTestsTab.table.date'),
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.startTime ? shortTime(rowData.startTime) : '-';
            }
        },
        {
            id: 'status',
            label: t('analyticsTestsTab.table.status'),
            isSortable: true,
            textRenderer: ({ rowData }) => {
                return t(TestStatusesTranslationKeysObj[rowData.status]);
            },
            CellRender: ({ rowData }) => {
                return t(TestStatusesTranslationKeysObj[rowData.status]);
            }
        },
    ], [t]);

    const unitTypeShort = $selectedUnitType.value === UnitType.Meters ?
        t('constants.unitType.metersShort')
        : t('constants.unitType.feetShort');

    const unitPerSecond = $selectedUnitType.value === UnitType.Meters ?
        t('constants.units.m/s')
        : t('constants.units.ft/s');

    const unitPerSecondSquared = $selectedUnitType.value === UnitType.Meters ?
        t('constants.units.m/s^2')
        : t('constants.units.ft/s^2');

    const jumpSquatUnitTypeShort = $selectedUnitType.value === UnitType.Meters ?
        t('constants.unitType.centimetersShort')
        : t('constants.unitType.inchShort');

    const exportTranslationMap = useMemo(() => ({
        testStartTime: t('analytics.export.testStartTime'),
        fullName: t('analytics.export.fullName'),
        username: t('analytics.export.username'),
        scriptedActivity: t('analytics.export.scriptedActivity'),
        testStepDate: t('analytics.export.testStepDate'),
        scriptedActivityStep: t('analytics.export.scriptedActivityStep'),
        stepNumber: t('analytics.export.stepNumber'),

        status: t('analytics.export.status'),
        duration: t('analytics.export.duration'),
        targetsHit: t('analytics.export.targetsHit'),

        // reactionTimeMapping
        unadjustedReactionTimeAvg: t('analytics.export.reactionTimeAverage'),
        unadjustedReactionTimeForward: t('analytics.export.reactionTimeForward'),
        unadjustedReactionTimeForwardRight: t('analytics.export.reactionTimeForwardRight'),
        unadjustedReactionTimeRight: t('analytics.export.reactionTimeRight'),
        unadjustedReactionTimeBackwardRight: t('analytics.export.reactionTimeBackwardRight'),
        unadjustedReactionTimeBackward: t('analytics.export.reactionTimeBackward'),
        unadjustedReactionTimeBackwardLeft: t('analytics.export.reactionTimeBackwardLeft'),
        unadjustedReactionTimeLeft: t('analytics.export.reactionTimeLeft'),
        unadjustedReactionTimeForwardLeft: t('analytics.export.reactionTimeForwardLeft'),
        unadjustedReactionTimeLRDifference: t('analytics.export.reactionTimeLRDifference'),
        unadjustedReactionTimeFLFRDifference: t('analytics.export.reactionTimeFLFRDifference'),
        unadjustedReactionTimeBLBRDifference: t('analytics.export.reactionTimeBLBRDifference'),

        // dynamicReactionTimeMapping
        reactionTimeAvg: t('analytics.export.dynamicReactionTimeAverage'),
        reactionTimeForward: t('analytics.export.dynamicReactionTimeForward'),
        reactionTimeForwardRight: t('analytics.export.dynamicReactionTimeForwardRight'),
        reactionTimeRight: t('analytics.export.dynamicReactionTimeRight'),
        reactionTimeBackwardRight: t('analytics.export.dynamicReactionTimeBackwardRight'),
        reactionTimeBackward: t('analytics.export.dynamicReactionTimeBackward'),
        reactionTimeBackwardLeft: t('analytics.export.dynamicReactionTimeBackwardLeft'),
        reactionTimeLeft: t('analytics.export.dynamicReactionTimeLeft'),
        reactionTimeForwardLeft: t('analytics.export.dynamicReactionTimeForwardLeft'),
        reactionTimeLRDifference: t('analytics.export.dynamicReactionTimeLRDifference'),
        reactionTimeFLFRDifference: t('analytics.export.dynamicReactionTimeFLFRDifference'),
        reactionTimeBLBRDifference: t('analytics.export.dynamicReactionTimeBLBRDifference'),
        
        // speedMapping
        speedAvg: t('analytics.export.speedAverage', unitPerSecond),
        speedForward: t('analytics.export.speedForward', unitPerSecond),
        speedForwardRight: t('analytics.export.speedForwardRight', unitPerSecond),
        speedRight: t('analytics.export.speedRight', unitPerSecond),
        speedBackwardRight: t('analytics.export.speedBackwardRight', unitPerSecond),
        speedBackward: t('analytics.export.speedBackward', unitPerSecond),
        speedBackwardLeft: t('analytics.export.speedBackwardLeft', unitPerSecond),
        speedLeft: t('analytics.export.speedLeft', unitPerSecond),
        speedForwardLeft: t('analytics.export.speedForwardLeft', unitPerSecond),
        speedLRDifference: t('analytics.export.speedLRDifference'),
        speedFLFRDifference: t('analytics.export.speedFLFRDifference'),
        speedBLBRDifference: t('analytics.export.speedBLBRDifference'),

        // accelerationMapping
        accelerationAvg: t('analytics.export.accelerationAverage', unitPerSecondSquared),
        accelerationForward: t('analytics.export.accelerationForward', unitPerSecondSquared),
        accelerationForwardRight: t('analytics.export.accelerationForwardRight', unitPerSecondSquared),
        accelerationRight: t('analytics.export.accelerationRight', unitPerSecondSquared),
        accelerationBackwardRight: t('analytics.export.accelerationBackwardRight', unitPerSecondSquared),
        accelerationBackward: t('analytics.export.accelerationBackward', unitPerSecondSquared),
        accelerationBackwardLeft: t('analytics.export.accelerationBackwardLeft', unitPerSecondSquared),
        accelerationLeft: t('analytics.export.accelerationLeft', unitPerSecondSquared),
        accelerationForwardLeft: t('analytics.export.accelerationForwardLeft', unitPerSecondSquared),
        accelerationLRDifference: t('analytics.export.accelerationLRDifference'),
        accelerationFLFRDifference: t('analytics.export.accelerationFLFRDifference'),
        accelerationBLBRDifference: t('analytics.export.accelerationBLBRDifference'),

        // decelerationMapping
        decelerationAvg: t('analytics.export.decelerationAverage', unitPerSecondSquared),
        decelerationForward: t('analytics.export.decelerationForward', unitPerSecondSquared),
        decelerationForwardRight: t('analytics.export.decelerationForwardRight', unitPerSecondSquared),
        decelerationRight: t('analytics.export.decelerationRight', unitPerSecondSquared),
        decelerationBackwardRight: t('analytics.export.decelerationBackwardRight', unitPerSecondSquared),
        decelerationBackward: t('analytics.export.decelerationBackward', unitPerSecondSquared),
        decelerationBackwardLeft: t('analytics.export.decelerationBackwardLeft', unitPerSecondSquared),
        decelerationLeft: t('analytics.export.decelerationLeft', unitPerSecondSquared),
        decelerationForwardLeft: t('analytics.export.decelerationForwardLeft', unitPerSecondSquared),
        decelerationLRDifference: t('analytics.export.decelerationLRDifference'),
        decelerationFLFRDifference: t('analytics.export.decelerationFLFRDifference'),
        decelerationBLBRDifference: t('analytics.export.decelerationBLBRDifference'),

        // deceleration index
        decelerationIndex: t('analytics.export.decelerationIndex'),

        // distance
        totalDistance: t('analytics.export.totalDistance', unitTypeShort),

        // calories
        calories: t('analytics.export.calories'),

        // cognitive mapping
        promptTime: t('analytics.export.promptTime'),
        congruentCorrectLeft: t('analytics.export.congruentCorrectLeft'),
        averageTimeCongruentCorrectLeft: t('analytics.export.averageTimeCongruentCorrectLeft'),
        congruentIncorrectLeft: t('analytics.export.congruentIncorrectLeft'),
        averageTimeCongruentIncorrectLeft: t('analytics.export.averageTimeCongruentIncorrectLeft'),
        incongruentCorrectLeft: t('analytics.export.incongruentCorrectLeft'),
        averageTimeIncongruentCorrectLeft: t('analytics.export.averageTimeIncongruentCorrectLeft'),
        incongruentIncorrectLeft: t('analytics.export.incongruentIncorrectLeft'),
        averageTimeIncongruentIncorrectLeft: t('analytics.export.averageTimeIncongruentIncorrectLeft'),
        congruentCorrectRight: t('analytics.export.congruentCorrectRight'),
        averageTimeCongruentCorrectRight: t('analytics.export.averageTimeCongruentCorrectRight'),
        congruentIncorrectRight: t('analytics.export.congruentIncorrectRight'),
        averageTimeCongruentIncorrectRight: t('analytics.export.averageTimeCongruentIncorrectRight'),
        incongruentCorrectRight: t('analytics.export.incongruentCorrectRight'),
        averageTimeIncongruentCorrectRight: t('analytics.export.averageTimeIncongruentCorrectRight'),
        incongruentIncorrectRight: t('analytics.export.incongruentIncorrectRight'),
        averageTimeIncongruentIncorrectRight: t('analytics.export.averageTimeIncongruentIncorrectRight'),

        //  swayMapping
        totalSway: t('analytics.export.totalSway', unitTypeShort),
        swayForward: t('analytics.export.swayForward', unitTypeShort),
        swayForwardRight: t('analytics.export.swayForwardRight', unitTypeShort),
        swayRight: t('analytics.export.swayRight', unitTypeShort),
        swayBackwardRight: t('analytics.export.swayBackwardRight', unitTypeShort),
        swayBackward: t('analytics.export.swayBackward', unitTypeShort),
        swayBackwardLeft: t('analytics.export.swayBackwardLeft', unitTypeShort),
        swayLeft: t('analytics.export.swayLeft', unitTypeShort),
        swayForwardLeft: t('analytics.export.swayForwardLeft', unitTypeShort),

        // misses
        totalMisses: t('analytics.export.totalMisses'),

        // kinematic upper body
        neckRotation: t('analytics.export.neckRotation'),
        spineFlexion: t('analytics.export.spineFlexion'),
        spineRotation: t('analytics.export.spineRotation'),
        leftShoulderFlexion: t('analytics.export.leftShoulderFlexion'),
        rightShoulderFlexion: t('analytics.export.rightShoulderFlexion'),
        leftShoulderAbduction: t('analytics.export.leftShoulderAbduction'),
        rightShoulderAbduction: t('analytics.export.rightShoulderAbduction'),
        leftElbowFlexion: t('analytics.export.leftElbowFlexion'),
        rightElbowFlexion: t('analytics.export.rightElbowFlexion'),

        // kinematic
        plantFoot: t('analytics.export.plantFoot'),
        stanceWidthRatio: t('analytics.export.stanceWidthRatio'),
        stanceWidthDistance: t('analytics.export.stanceWidthDistance', unitTypeShort),
        trunkLean: t('analytics.export.trunkLean'),
        pelvicTilt: t('analytics.export.pelvicTilt'),
        pelvicRotation: t('analytics.export.pelvicRotation'),
        shoulderRotation: t('analytics.export.shoulderRotation'),
        thoracicRotation: t('analytics.export.shoulderRotation'),
        lumbarRotation: t('analytics.export.lumbarRotation'),
        rightHipRotation: t('analytics.export.rightHipRotation'),
        rightKneeValgusVarus: t('analytics.export.rightKneeValgusVarus'),
        rightKneeFlexion: t('analytics.export.rightKneeFlexion'),
        rightAnkleDorsiflexion: t('analytics.export.rightAnkleDorsiflexion'),
        leftHipRotation: t('analytics.export.leftHipRotation'),
        leftKneeValgusVarus: t('analytics.export.leftKneeValgusVarus'),
        leftKneeFlexion: t('analytics.export.leftKneeFlexion'),
        leftAnkleDorsiflexion: t('analytics.export.leftAnkleDorsiflexion'),
    }), [t, $selectedUnitType.value]);

    const exportRepsTranslationMap = useMemo(() => ({
        testStartTime: t('analytics.export.testStartTime'),
        fullName: t('analytics.export.fullName'),
        username: t('analytics.export.username'),
        scriptedActivity: t('analytics.export.scriptedActivity'),
        testStepDate: t('analytics.export.testStepDate'),
        scriptedActivityStep: t('analytics.export.scriptedActivityStep'),
        stepNumber: t('analytics.export.stepNumber'),
        //
        repNumber: t('analytics.export.repNumber'),
        //
        unadjustedReactionTimeAvg: t('analytics.export.reactionTimeAvg'),
        unadjustedReactionTimeMin: t('analytics.export.reactionTimeMin'),
        reactionTimeAvg: t('analytics.export.dynamicReactionTimeAvg'),
        reactionTimeMin: t('analytics.export.dynamicReactionTimeMin'),
        speedAvg: t('analytics.export.speedAvg', unitPerSecond),
        speedMax: t('analytics.export.speedMax', unitPerSecond),
        accelerationAvg: t('analytics.export.accelerationAvg', unitPerSecondSquared),
        accelerationMax: t('analytics.export.accelerationMax', unitPerSecondSquared),
        decelerationAvg: t('analytics.export.decelerationAvg', unitPerSecondSquared),
        decelerationMax: t('analytics.export.decelerationMax', unitPerSecondSquared),
        jumpHeightAvg: t('analytics.export.jumpHeightAvg', jumpSquatUnitTypeShort),
        jumpHeightMax: t('analytics.export.jumpHeightMax', jumpSquatUnitTypeShort),
        squatHeightAvg: t('analytics.export.squatHeightAvg', jumpSquatUnitTypeShort),
        squatHeightMin: t('analytics.export.squatHeightMin', jumpSquatUnitTypeShort),
        totalDistance: t('analytics.export.totalDistance', unitTypeShort),
        minimumDistance: t('analytics.export.minimumDistance', unitTypeShort),

        // kinematic upper body
        neckRotation: t('analytics.export.neckRotation'),
        spineFlexion: t('analytics.export.spineFlexion'),
        spineRotation: t('analytics.export.spineRotation'),
        leftShoulderFlexion: t('analytics.export.leftShoulderFlexion'),
        rightShoulderFlexion: t('analytics.export.rightShoulderFlexion'),
        leftShoulderAbduction: t('analytics.export.leftShoulderAbduction'),
        rightShoulderAbduction: t('analytics.export.rightShoulderAbduction'),
        leftElbowFlexion: t('analytics.export.leftElbowFlexion'),
        rightElbowFlexion: t('analytics.export.rightElbowFlexion'),

        // kinematic
        plantFoot: t('analytics.export.plantFoot'),
        stanceWidthRatio: t('analytics.export.stanceWidthRatio'),
        stanceWidthDistance: t('analytics.export.stanceWidthDistance', unitTypeShort),
        trunkLean: t('analytics.export.trunkLean'),
        pelvicTilt: t('analytics.export.pelvicTilt'),
        pelvicRotation: t('analytics.export.pelvicRotation'),
        shoulderRotation: t('analytics.export.shoulderRotation'),
        thoracicRotation: t('analytics.export.thoracicRotation'),
        lumbarRotation: t('analytics.export.lumbarRotation'),
        rightHipRotation: t('analytics.export.rightHipRotation'),
        rightKneeValgusVarus: t('analytics.export.rightKneeValgusVarus'),
        rightKneeFlexion: t('analytics.export.rightKneeFlexion'),
        rightAnkleDorsiflexion: t('analytics.export.rightAnkleDorsiflexion'),
        leftHipRotation: t('analytics.export.leftHipRotation'),
        leftKneeValgusVarus: t('analytics.export.leftKneeValgusVarus'),
        leftKneeFlexion: t('analytics.export.leftKneeFlexion'),
        leftAnkleDorsiflexion: t('analytics.export.leftAnkleDorsiflexion'),
    }), [t, $selectedUnitType.value]);

    const downloadTests = (callback) => {
        const ids = Object.keys($selectedTests.value);
        if (ids.every(x => $cachedTestsList.value.map(y => y.testId).includes(x))) {
            // if all of the selected tests has been requested in other call af the API we can reuse the data
            callback($cachedTestsList.value.filter(x => ids.includes(x.testId)));
            return;
        }

        const testIds = Object.keys($selectedTests.value)
            .filter(x => $selectedTests.value[x]);
        $isLoading.set(true);
        (async () => {
            let testsList = [];
            const chunkSize = 100;
            for (let i = 0; i < testIds.length; i += chunkSize) {
                const tests = await TestService.getTestsByIds(testIds.slice(i, i + chunkSize));
                testsList = testsList.concat(tests);
            }

            $isLoading.set(false);
            $rawReportData.set(testsList);
            const cachedTests = $cachedTestsList.value.filter(x => testsList.some(y => y.testId === x.testId)).concat(testsList);
            if ($cachedTestsList.value.length !== cachedTests.length) {
                $cachedTestsList.set(cachedTests);
                dispatch(setTests(cachedTests.map(x =>
                ({
                    ...x,
                    startTime: x.startTime?.toString(),
                    endTime: x.endTime?.toString(),
                    testSteps: (x.testSteps || []).map(ts => ({
                        ...ts,
                        startTime: ts.startTime?.toString(),
                        endTime: ts.endTime?.toString(),
                    })),
                    testNote: x.testNote ? {
                        ...x.testNote,
                        dateCreated: x.testNote.dateCreated ? x.testNote.dateCreated?.toString() : null,
                        dateModified: x.testNote.dateModified ? x.testNote.dateModified?.toString() : null
                    } : null
                })
                )));
            }
            callback(testsList);
        })();
    }

    const generateReportHandler = () =>{
        $isGeneratingReportData.set(true);
        downloadTests(tests => {
                onGenerateReport(tests);
                $isGeneratingReportData.set(false);
            }
        );
    }


    const convertUnits = (metersValue, fractionDigits = 4) =>
        ($selectedUnitType.value === UnitType.Meters || !metersValue ?
            metersValue
            : metersToFeet(metersValue)
        )?.toFixed(fractionDigits);

    const convertSmallUnits = (metersValue, fractionDigits = 4) =>
        ($selectedUnitType.value === UnitType.Meters || !metersValue ?
            metersToCentimeters(metersValue)
            : metersToInches(metersValue)
        ).toFixed(fractionDigits);

    const degreeFormatter = (valueDeg, valueDir) => {
        if (valueDeg == null) {
            return;
        }

        return valueDir ?
            `${Math.round(valueDeg, 2)}\u00B0 ${valueDir}`
            : `${Math.round(valueDeg, 2)}\u00B0`;
    };

    const buildKinematicDataEntry = (kinematics) => ({
        plantFoot: kinematics.plantFoot,
        stanceWidthRatio: kinematics.stanceWidthRatio,
        stanceWidthDistance: convertUnits(kinematics.stanceWidthDistance, 4),
        trunkLean: degreeFormatter(kinematics.trunkLeanDeg, kinematics.trunkLeanDir),
        pelvicTilt: degreeFormatter(kinematics.trunkLatFlexionDeg, kinematics.trunkLatFlexion),
        pelvicRotation: degreeFormatter(kinematics.pelvisRotation, kinematics.pelvisRotation),
        shoulderRotation: degreeFormatter(kinematics.shoulderRotation, kinematics.shoulderRotationDir),
        thoracicRotation: degreeFormatter(kinematics.thoracicRotation, kinematics.thoracicRotationDir),
        lumbarRotation: degreeFormatter(kinematics.lumbarRotation, kinematics.lumbarRotationDir),
        rightHipRotation: degreeFormatter(kinematics.rightHipRotation, kinematics.rightHipRotationDir),
        rightKneeValgusVarus: degreeFormatter(kinematics.rightKneeValgusVarusDeg, kinematics.rightKneeValgusVarus),
        rightKneeFlexion: degreeFormatter(kinematics.rightKneeFlexion),
        rightAnkleDorsiflexion: degreeFormatter(kinematics.rightAnkleDorsiflexion),
        leftHipRotation: degreeFormatter(kinematics.leftHipRotation, kinematics.leftHipRotationDir),
        leftKneeValgusVarus: degreeFormatter(kinematics.leftKneeValgusVarusDeg, kinematics.leftKneeValgusVarus),
        leftKneeFlexion: degreeFormatter(kinematics.leftKneeFlexion),
        leftAnkleDorsiflexion: degreeFormatter(kinematics.leftAnkleDorsiflexion),

        neckRotation: degreeFormatter(kinematics.neckRotation, kinematics.neckRotationDir),
        spineFlexion: degreeFormatter(kinematics.spineFlexion, kinematics.spineFlexionDir),
        spineRotation: degreeFormatter(kinematics.spineRotation, kinematics.spineRotationDir),
        leftShoulderFlexion: degreeFormatter(kinematics.leftShoulderFlexion),
        rightShoulderFlexion: degreeFormatter(kinematics.rightShoulderFlexion),
        leftShoulderAbduction: degreeFormatter(kinematics.leftShoulderAbduction),
        rightShoulderAbduction: degreeFormatter(kinematics.rightShoulderAbduction),
        leftElbowFlexion: degreeFormatter(kinematics.leftElbowFlexion),
        rightElbowFlexion: degreeFormatter(kinematics.rightElbowFlexion),
    });

    const exportTestDataHandler = () =>
        downloadTests(tests => {
            const reportData = [];
            const stepNames = {};

            const calculateAverageTime = (timeValue, numCount) => {
                if (!timeValue || !numCount) {
                    return NoData;
                }

                return (timeValue / numCount).toFixed(2);
            };

            const translationKeys = Object.keys(exportTranslationMap);
            for (const test of tests) {
                for (const sas of test.scriptedActivity.scriptedActivitySteps) {
                    stepNames[sas.scriptedActivityStepId] = sas.name;
                }

                const testStartTime = shortTime(test.startTime);
                const fullName = test.userFullName;
                const username = test.username;
                const scriptedActivity = test.scriptedActivity.name;
                for (const step of test.testSteps) {
                    const testStepDate = shortTime(step.startTime);
                    const scriptedActivityStep = stepNames[step.scriptedActivityStepId];
                    const mappedData = {};
                    const data = {
                        testStartTime,
                        fullName,
                        username,
                        scriptedActivity,
                        testStepDate,
                        scriptedActivityStep,
                        stepNumber: step.orderId,
                        status: t(TestStatusesTranslationKeysObj[step.stepStatus]),
                        duration: step.duration,
                        targetsHit: step.testResultMobility?.targetsHit || NoData
                    };
                    const mobility = step.testResultMobility;
                    if (mobility) {
                        Object.assign(data, {
                            // includes Reaction Time, Dynamic Reaction Time, Speed, Acceleration, Deceleration, Total Distance and Calories
                            ...mobility,
                            reactionTimeLRDifference: CalculatedReactionTimeLeftRightDifference(mobility.unadjustedReactionTimeLeft, mobility.unadjustedReactionTimeRight, true),
                            reactionTimeFLFRDifference: CalculatedReactionTimeLeftRightDifference(mobility.unadjustedReactionTimeForwardLeft, mobility.unadjustedReactionTimeForwardRight, true),
                            reactionTimeBLBRDifference: CalculatedReactionTimeLeftRightDifference(mobility.unadjustedReactionTimeBackwardLeft, mobility.unadjustedReactionTimeBackwardRight, true),
                            dynamicReactionTimeLRDifference: CalculatedReactionTimeLeftRightDifference(mobility.reactionTimeLeft, mobility.reactionTimeRight, true),
                            dynamicReactionTimeFLFRDifference: CalculatedReactionTimeLeftRightDifference(mobility.reactionTimeForwardLeft, mobility.reactionTimeForwardRight, true),
                            dynamicReactionTimeBLBRDifference: CalculatedReactionTimeLeftRightDifference(mobility.reactionTimeBackwardLeft, mobility.reactionTimeBackwardRight, true),
                            speedLRDifference: CalculatedLeftRightDifference(mobility.speedLeft, mobility.speedRight, true),
                            speedFLFRDifference: CalculatedLeftRightDifference(mobility.speedForwardLeft, mobility.speedForwardRight, true),
                            speedBLBRDifference: CalculatedLeftRightDifference(mobility.speedBackwardLeft, mobility.speedBackwardRight, true),
                            accelerationLRDifference: CalculatedLeftRightDifference(mobility.accelerationLeft, mobility.accelerationRight, true),
                            accelerationFLFRDifference: CalculatedLeftRightDifference(mobility.accelerationForwardLeft, mobility.accelerationForwardRight, true),
                            accelerationBLBRDifference: CalculatedLeftRightDifference(mobility.accelerationBackwardLeft, mobility.accelerationBackwardRight, true),
                            decelerationLRDifference: CalculatedLeftRightDifference(mobility.decelerationLeft, mobility.decelerationRight, true),
                            decelerationFLFRDifference: CalculatedLeftRightDifference(mobility.decelerationForwardLeft, mobility.decelerationForwardRight, true),
                            decelerationBLBRDifference: CalculatedLeftRightDifference(mobility.decelerationBackwardLeft, mobility.decelerationBackwardRight, true),
                            decelerationIndex: !isNaN((mobility.decelerationMax / mobility.accelerationMax)) ? (mobility.decelerationMax / mobility.accelerationMax).toFixed(2) : '--',
                        });
                    }

                    const balance = step.testResultBalance;
                    if (balance) {
                        // include data for Sway and Misses
                        Object.assign(data, balance);
                    }

                    const cognitive = step.testResultCognitive;
                    if (cognitive) {
                        Object.assign(data, {
                            promptTime: cognitive.promptTime,
                            congruentCorrectLeft: cognitive.numCongruentCorrectLeft,
                            averageTimeCongruentCorrectLeft: calculateAverageTime(cognitive.timeCongruentCorrectLeft, cognitive.numCongruentCorrectLeft),
                            congruentIncorrectLeft: cognitive.numCongruentIncorrectLeft,
                            averageTimeCongruentIncorrectLeft: calculateAverageTime(cognitive.timeCongruentIncorrectLeft, cognitive.numCongruentIncorrectLeft),
                            incongruentCorrectLeft: cognitive.numIncongruentCorrectLeft,
                            averageTimeIncongruentCorrectLeft: calculateAverageTime(cognitive.timeIncongruentCorrectLeft, cognitive.numIncongruentCorrectLeft),
                            incongruentIncorrectLeft: cognitive.numIncongruentIncorrectLeft,
                            averageTimeIncongruentIncorrectLeft: calculateAverageTime(cognitive.timeIncongruentIncorrectLeft, cognitive.numIncongruentIncorrectLeft),
                            congruentCorrectRight: cognitive.numCongruentCorrectRight,
                            averageTimeCongruentCorrectRight: calculateAverageTime(cognitive.timeCongruentCorrectRight, cognitive.numCongruentCorrectRight),
                            congruentIncorrectRight: cognitive.numCongruentIncorrectRight,
                            averageTimeCongruentIncorrectRight: calculateAverageTime(cognitive.timeCongruentIncorrectRight, cognitive.numCongruentIncorrectRight),
                            incongruentCorrectRight: cognitive.numIncongruentCorrectRight,
                            averageTimeIncongruentCorrectRight: calculateAverageTime(cognitive.timeIncongruentCorrectRight, cognitive.numIncongruentCorrectRight),
                            incongruentIncorrectRight: cognitive.numIncongruentIncorrectRight,
                            averageTimeIncongruentIncorrectRight: calculateAverageTime(cognitive.timeIncongruentIncorrectRight, cognitive.numIncongruentIncorrectRight),
                        });
                    }

                    const kinematics = step.testResultKinematic;
                    if (kinematics) {
                        Object.assign(data, buildKinematicDataEntry(kinematics));
                    }

                    const convertibleValues = [
                        'speedAvg',
                        'speedForward',
                        'speedForwardRight',
                        'speedRight',
                        'speedBackwardRight',
                        'speedBackward',
                        'speedBackwardLeft',
                        'speedLeft',
                        'speedForwardLeft',
                        'accelerationAvg',
                        'accelerationForward',
                        'accelerationForwardRight',
                        'accelerationRight',
                        'accelerationBackwardRight',
                        'accelerationBackward',
                        'accelerationBackwardLeft',
                        'accelerationLeft',
                        'accelerationForwardLeft',
                        'decelerationAvg',
                        'decelerationForward',
                        'decelerationForwardRight',
                        'decelerationRight',
                        'decelerationBackwardRight',
                        'decelerationBackward',
                        'decelerationBackwardLeft',
                        'decelerationLeft',
                        'decelerationForwardLeft',
                        'totalDistance',
                        'totalSway',
                        'swayForward',
                        'swayForwardRight',
                        'swayRight',
                        'swayBackwardRight',
                        'swayBackward',
                        'swayBackwardLeft',
                        'swayLeft',
                        'swayForwardLeft',
                        'stanceWidthDistance',
                    ];

                    for (const key of convertibleValues) {
                        data[key] = convertUnits(data[key]);
                    }

                    for (const key of translationKeys) {
                        mappedData[exportTranslationMap[key] || key] = data[key] || NoData;
                    }

                    reportData.push(mappedData);
                }
            }

            exportCSV(reportData, `analytics-report-${(new Date()).toISOString().split('T')[0]}`);
        });

    const exportRepDataHandler = () =>
        downloadTests(tests => {
            let reportData = [];
            const stepNames = {};

            const translationKeys = Object.keys(exportRepsTranslationMap);
            for (const test of tests) {
                for (const sas of test.scriptedActivity.scriptedActivitySteps) {
                    stepNames[sas.scriptedActivityStepId] = sas.name;
                }

                const testStartTime = shortTime(test.startTime);
                const fullName = test.userFullName;
                const username = test.username;
                const scriptedActivity = test.scriptedActivity.name;
                for (const step of test.testSteps) {
                    const testStepDate = shortTime(step.startTime);
                    const scriptedActivityStep = stepNames[step.scriptedActivityStepId];
                    const data = {
                        testStartTime,
                        fullName,
                        username,
                        scriptedActivity,
                        testStepDate,
                        scriptedActivityStep,
                        stepNumber: step.orderId,
                    };
                    const repsData = [];

                    const mobilityReps = step.testResultMobilityReps;
                    if (mobilityReps) {
                        const convertibleValues = [
                            'speedAvg',
                            'speedMax',
                            'accelerationAvg',
                            'accelerationMax',
                            'decelerationAvg',
                            'decelerationMax',
                            'minimumDistance',
                            'totalDistance'
                        ];

                        const convertibleSmallValues = [
                            'jumpHeightAvg',
                            'jumpHeightMax',
                            'squatHeightAvg',
                            'squatHeightMin'
                        ];

                        for (let mobilityRep of mobilityReps) {
                            const entry = { ...data, ...mobilityRep };
                            for (const key of convertibleValues) {
                                entry[key] = convertUnits(entry[key]);
                            }

                            for (const key of convertibleSmallValues) {
                                entry[key] = convertSmallUnits(entry[key]);
                            }

                            const mappedData = {}
                            for (const key of translationKeys) {
                                mappedData[exportRepsTranslationMap[key] || key] = entry[key] || NoData;
                            }

                            repsData.push(mappedData);
                        }
                    }

                    const kinematicsReps = step.testResultKinematicsReps;
                    if (kinematicsReps) {
                        for (let kinematicsRep of kinematicsReps) {
                            const entry = { ...data, ...buildKinematicDataEntry(kinematicsRep) };
                            const mappedData = {}
                            for (const key of translationKeys) {
                                mappedData[exportRepsTranslationMap[key] || key] = entry[key] || NoData;
                            }

                            repsData.push(mappedData);
                        }
                    }

                    reportData = reportData.concat(repsData);
                }
            }

            exportCSV(reportData, `analytics-reps-report-${(new Date()).toISOString().split('T')[0]}`);
        });

    return (
        <div css={testsTabStyles.root}>
            <TableCSF
                customActions={
                    <div css={testsTabStyles.actions}>
                        <Button
                            disabled={$isLoading.value || !$selectedTestsCount.value}
                            onClick={generateReportHandler}
                        >
                            {t('actions.generateReport')}
                        </Button>
                        <Button
                            disabled={$isLoading.value || !$selectedTestsCount.value}
                            onClick={exportTestDataHandler}
                        >
                            {t('actions.exportTestData')}
                        </Button>
                        <Button
                            disabled={$isLoading.value || !$selectedTestsCount.value || !$hasRepsData.value}
                            onClick={exportRepDataHandler}
                        >
                            {t('actions.exportRepData')}
                        </Button>
                        <SingleSelect
                            className="units-selection"
                            label={t('analyticsTestsTab.controls.unitType')}
                            design="slim"
                            $value={$selectedUnitType}
                            disableFiltering
                        >
                            {UnitTypeTranslationKeysArr.map(x => (
                                <SelectOption key={x.value} value={x.value}>
                                    {t(x.translationKey)}
                                </SelectOption>
                            ))}
                        </SingleSelect>
                    </div>
                }
                headCells={headCells}
                rows={$availableTests.value}
                totalItemsCount={$availableTests.value.length}
                disablePagination
                rowKeySelector={r => r.testId}
                isRowSelected={rowData => $selectedTests.value[rowData.testId]}
                isLoading={false}
                additionalData={{ $selectedTests, $selectedTestsCount }}
                showTableSizeInfo
                showAdvancedSearch={false}
                showExport
                exportFileNameGenerator={() => `available-tests-${(new Date()).toISOString().split('T')[0]}`}
            />
            <div css={testsTabStyles.selectAllCheckbox}>
                <Checkbox
                    checked={$selectedTestsCount.value === $availableTests.value.length}
                    onChange={isChecked => {
                        if (isChecked) {
                            const newSelectedTests = $availableTests.value
                                .reduce((a, c) => ({ ...a, [c.testId]: c }), {})

                            $selectedTests.set(newSelectedTests);
                            $selectedTestsCount.set($availableTests.value.length);
                            $hasRepsData.set(Object.values(newSelectedTests).some(x => x.hasRepsData));
                        } else {
                            $selectedTests.set({});
                            $selectedTestsCount.set(0);
                        }
                    }}
                    label={t('actions.selectAll')}
                />
            </div>
        </div>
    );
};
