/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { UserService } from '../../../Services/UserService';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import { GendersTranslationKeysArr } from '../../../Utils/Constants';
import {
    emailValidator,
    maxLengthValidator,
    minValueValidator,
    requiredValidator,
    rangeLengthValidator,
    usernameValidator,
    digitsValidator,
    combineValidators,
    phoneValidator,
    maxValueValidator,
    PINValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { DatePicker, datePickerRangeValidator } from '../../../CoreComponents/DatePicker';
import { AlertText } from '../../../CoreComponents/Alert';
import { subYears } from 'date-fns';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const profileInformationTabStyles = {
    root: css`
        display: flex;
        flex-wrap: wrap;
        height: 85vh;
        width: unset;

        & > h2 {
            width: 100%;
            padding-left: 40px;
            padding-top: 40px;
            margin-bottom: 25px;
            margin-top: 0;
        }


        & > .scroll-content {
            overflow-y: auto;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            height: calc(100% - 205px);
            padding: 0;
            justify-content: space-between;

            & > .height-weight {
                & > div:nth-of-type(2) {
                    margin-left: 0;
                    width: calc(30% - 10px);
                }

                & > .height {
                    width: calc(70% - 30px);
                    display: flex;
                    flex-wrap: wrap;
                    margin: 16px 40px 0 0;

                    & > * {
                        width: calc(50% - 10px);

                        &:last-child {
                            margin-left: 20px;
                        }
                    }


                    & > label {
                        width: 100%;
                        margin: 0 auto 8px 0;
                        letter-spacing: 0.14px;
                        color: #858585;
                        font: normal normal bold 16px Mustica Pro;
                        margin-bottom: 0;
                        // padding-top: 8px;
                        // height: 25px;

                        &.invalid {
                            color: #E0758A;
                            margin-bottom: 25px;
                        }
                    }
                }
                & > .weight {
                    margin-top: 16px;
                    margin-right: 0;
                }
            }

            & > * {
                display: inline-flex;
                margin: 15px 32px 15px 40px;
                width: calc(100% - 78px);
                &.height-weight {
                    width: 100%;
                }

                @media (min-width: 1200px) {
                    width: calc(50% - 78px);
                    &.height-weight {
                        width: calc(50% - 78px);
                    }
                }
            }
        }

        @media (min-width: 1200px) {
            width: 920px;
        }

        & > .actions {
            display: flex;
            width: 100%;
            margin-top: 40px;
            margin-bottom: 40px;

            & > button {
                margin-left: auto;
            }

            & > .alert-text-root {
                margin-left: 40px;
                align-self: center;
            }
        }
    `
};

const UserValidators = {
    firstName: [requiredValidator, maxLengthValidator(50)],
    lastName: [requiredValidator, maxLengthValidator(50)],
    username: [usernameValidator, rangeLengthValidator(6, 20)],
    email: [maxLengthValidator(50), emailValidator],
    weight: [requiredValidator, digitsValidator],
    pin: [PINValidator],
    phoneNumber: [phoneValidator],
    dateOfBirth: [
        requiredValidator,
        datePickerRangeValidator(subYears(new Date(), 150), new Date())
    ],
    medicalRecordNumber: [maxLengthValidator(50)]
};

export const ProfileInformationTab = ({ $selectedUser, isProfileOpened }) => {
    const { t } = useTranslation();
    const $isForceValidating = useHook(false);
    const $userCopy = useObjectHook(
        {
            ...$selectedUser.value,
            dateOfBirth: new Date($selectedUser.value.dateOfBirth)
        },
        $selectedUser.value.admin ?
            { ...UserValidators, email: [requiredValidator, ...UserValidators.email], pin: [requiredValidator, ...UserValidators.pin] }
            : UserValidators,
        null,
        $isForceValidating.value
    );
    const $feet = useHook(Math.floor($selectedUser.value.height / 12), combineValidators([minValueValidator(1), digitsValidator, maxValueValidator(10)]));
    const $inches = useHook($selectedUser.value.height % 12, combineValidators([digitsValidator, requiredValidator]));
    const $genderOptions = useHook(
        GendersTranslationKeysArr.map(gt => ({ ...gt, label: t(gt.translationKey) }))
    );

    const $feetErrors = useHook(null);
    const $inchesErrors = useHook(null);

    useEffect(() => {
        $userCopy.set({ ...$userCopy.value, height: ($feet.value * 12) + Number($inches.value) });
    }, [$feet.value, $inches.value]);

    const updateUser = () => {
        $isForceValidating.set(true);
        const errors = $userCopy.validate();
        const feetErrors = $feet.validate();
        const inchesErrors = $inches.validate();

        $feetErrors.set(feetErrors);
        $inchesErrors.set(inchesErrors);
        if (errors || feetErrors || inchesErrors) {
            return;
        }

        UserService.patchUser($userCopy.value)
            .then(() => {
                if (!isProfileOpened) {
                    $selectedUser.value.onEdit($userCopy.value);
                }
                $selectedUser.set($userCopy.value);
            });
    };
    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={profileInformationTabStyles.root}>
                <h2>{isProfileOpened ?
                    t('myProfile.editModal.title')
                    : t('users.editModal.title')
                }</h2>
                <div className="scroll-content">
                    <Input label={t('users.fieldName.firstName')} required placeholder={t('input.placeholder')} $value={$userCopy.getPropHook('firstName')} />
                    <Input label={t('users.fieldName.lastName')} required placeholder={t('input.placeholder')} $value={$userCopy.getPropHook('lastName')} />
                    <Input label={t('users.fieldName.username')} placeholder={t('input.placeholder')} $value={$userCopy.getPropHook('username')} />
                    <Input label={t('users.fieldName.email')} placeholder={t('input.placeholder')} $value={$userCopy.getPropHook('email')} />
                    <Input label={t('users.fieldName.phoneNumber')} placeholder={t('input.placeholder')} $value={$userCopy.getPropHook('phoneNumber')} />
                    <Input label={t('users.fieldName.medicalRecordsNumber')} placeholder={t('input.placeholder')} $value={$userCopy.getPropHook('medicalRecordNumber')} />
                    <DatePicker
                        label={t('users.fieldName.dateOfBirth')}
                        placeholder={t('input.placeholder')}
                        required
                        $value={$userCopy.getPropHook('dateOfBirth')}
                        maxValue={new Date()}
                        minValue={subYears(new Date(), 150)}
                    />
                    <div className="height-weight">
                        <div className="height">
                            <label className={($feetErrors.value || $inchesErrors.value) && "invalid"}>
                                {t('users.fieldName.height')} <span className="required">*</span>
                            </label>
                            <div>
                                <Input endIcon={<span>{t('constants.unitType.feetShort')}</span>} $value={$feet} />
                            </div>
                            <div>
                                <Input
                                    onBlur={() => {
                                        const value = Number($inches.value)

                                        const feet = Math.floor(value / 12);
                                        if (feet) {
                                            $feet.set(feet);
                                        }
                                        $inches.set('' + (value - feet * 12));
                                    }}
                                    endIcon={<span>{t('constants.unitType.inchShort')}</span>}
                                    $value={$inches}
                                />
                            </div>
                        </div>
                        <Input
                            className="weight"
                            endIcon={<span>{t('constants.unitType.poundShort')}</span>}
                            label={t('users.fieldName.weight')}
                            required
                            $value={$userCopy.getPropHook('weight')}
                        />
                    </div>
                    <SingleSelect label={t('users.fieldName.gender')} $value={$userCopy.getPropHook('gender')} disableFiltering>
                        {$genderOptions.value.map(x => (
                            <SelectOption key={x.value} value={x.value}>
                                {x.label}
                            </SelectOption>
                        ))}
                    </SingleSelect>
                    <Input label={t('users.fieldName.pin')} required={Boolean($selectedUser.value.admin)} placeholder={t('input.placeholder')} $value={$userCopy.getPropHook('pin')} />
                </div>
                <div className="actions">
                    {$userCopy.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                    <Button disabled={!$userCopy.isValid} onClick={updateUser}>{t('actions.update')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
