/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { ForceValidateContext, useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { SiteService } from '../../../Services/SiteService';
import { UserService } from '../../../Services/UserService';
import { TrashCanIcon } from '../../../CoreComponents/CustomIcons';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useMemo } from 'react';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { requiredValidator } from '../../../Utils/Validators';
import { AdminTypes } from '../../../Utils/Constants';
import { CustomerService } from '../../../Services/CustomerService';
import { AlertText } from '../../../CoreComponents/Alert';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const styles = {
    root: css`
        display: flex;
        flex-direction: column;

        height: 85vh;
        padding: 40px 0 40px 28px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & h2 {
            margin-left: 12px;
            margin-top: 0;
        }
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    icon: css`
        color: #00ABA5
    `,
    input: css`
        width: 90%;
    `
}
export const ConnectedSitesTab = ({ $selectedUser }) => {
    const { t } = useTranslation();
    const $refreshSites = useHook({});
    const $sites = useHook([]);
    const $isLoading = useHook(true);
    const $isAddSiteModalOpened = useHook(false);

    const headCells = useMemo(() => [
        {
            id: 'customer',
            label: t('groups.table.customer'),
            isSortable: true,
            columnWidth: 350,
            CellRender: ({ rowData }) => {
                return rowData.customer || '-';
            }
        },
        {
            id: 'site',
            label: t('groups.table.site'),
            isSortable: true,
            columnWidth: 300,
            CellRender: ({ rowData }) => {
                return rowData.site || '-';
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData, additionalData }) => {
                return (
                    <IconButton
                        disabled={additionalData.length < 2}
                        onClick={() => deleteSite(rowData.siteId)}
                        tooltip={t('actions.delete')}
                    >
                        <TrashCanIcon />
                    </IconButton>
                )
            }
        },
    ], [t]);

    const deleteSite = siteId => {
        UserService.deleteUserSite($selectedUser.value.userId, siteId)
            .then(() => $refreshSites.set({}))
    }

    useEffect(() => {
        $isLoading.set(true);
        SiteService.getAll(9999, null, $selectedUser.value.userId)
            .then(sites => {
                $sites.set(sites.map(x => ({
                    ...x,
                    customer: x.customer.name,
                    site: x.name
                })));
                $isLoading.set(false);
            });
    }, [$refreshSites.value])

    return (
        <>
            <div css={styles.root}>
                <h2>{t('users.editModal.title')}</h2>
                <TableCSF
                    headCells={headCells}
                    rows={$sites.value}
                    rowKeySelector={r => r.siteId}
                    isLoading={$isLoading.value}
                    additionalData={$sites.value}
                    customActions={
                        <Button onClick={() => $isAddSiteModalOpened.set(true)} >{t('sites.addModal.title')}</Button>
                    }
                />
            </div>
            <Modal
                open={$isAddSiteModalOpened.value}
                onClose={() => $isAddSiteModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddConnectedSiteContent
                    $selectedUser={$selectedUser}
                    onCancel={() => $isAddSiteModalOpened.set(false)}
                    onAdd={() => {
                        $isAddSiteModalOpened.set(false);
                        $refreshSites.set({});
                    }} />
            </Modal>
        </>
    )
}

const addUserSitesModalContentStyles = {
    root: css`
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .fields-container{
            & > .input-root {
                margin: 0 0 30px 0;
                width: 100%;
            }
        }

        & > .actions {
            display: flex;
            margin: 0;
            margin-top: 20px;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }

        & > .alert-text-root {
            align-self: end;
        }
    `,
};

const AddConnectedSiteContent = ({ $selectedUser, onCancel, onAdd }) => {
    const storeState = useSelector((state) => state.storeState);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $selectedSite = useHook(null, requiredValidator);
    const $selectedCustomer = useHook(storeState.currentUser.admin?.customerId || null, requiredValidator);
    const $isSaving = useHook(false);

    const { t } = useTranslation();
    const $isForceValidating = useHook(false);
    const $newSite = useObjectHook(
        {
            siteId: '',
            userId: ''
        }
    );

    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;

    useEffect(() => {
        if (!isTrazerAdmin) {
            return;
        }
        CustomerService.getAll(9999, 'Name', true)
            .then(customers => $customers.set(customers));
    }, [])

    useEffect(() => {
        if (!$selectedCustomer.value) {
            return;
        }
        $selectedSite.set(null);
        SiteService.getAll(9999, $selectedCustomer.value, null, 'Name', true)
            .then(sites => $sites.set(sites));
    }, [$selectedCustomer.value])

    const addSite = () => {
        $isForceValidating.set(true);
        const errors = $newSite.validate();
        if (errors || !$selectedCustomer.value || !$selectedSite.value) {
            return;
        }

        const newSite = {
            siteId: $selectedSite.value,
            userId: $selectedUser.value.userId
        };
        $newSite.set(newSite);

        $isSaving.set(true);
        UserService.postUserSite(newSite)
            .then(() => onAdd(newSite))
            .finally(() => $isSaving.set(false));

    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addUserSitesModalContentStyles.root}>
                <h2>{t('sites.addModal.title')}</h2>

                <div className="fields-container">
                    {isTrazerAdmin ?
                        <SingleSelect $value={$selectedCustomer} required label={t('sites.fieldName.customer')}>
                            {$customers.value.map(g => <SelectOption key={g.customerId} value={g.customerId}>{g.name}</SelectOption>)}
                        </SingleSelect>
                        :
                        null
                    }
                    <SingleSelect $value={$selectedSite} required label={t('sites.fieldName.site')}>
                        {$sites.value.map(g => <SelectOption key={g.siteId} value={g.siteId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                </div>

                {$selectedSite.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                <div className="actions" css={addUserSitesModalContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value} onClick={addSite}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
