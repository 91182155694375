import { getData, postData, putData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const ActivityGroupService = {
    getAll: function(){
        return getData(`${ApiHost}/api/ActivityGroups`)
    },
    postActivityGroup: function(group){
        return postData(`${ApiHost}/api/ActivityGroups`, group);
    },
    putActivityGroup: function(group){
        return putData(`${ApiHost}/api/ActivityGroups`, group);
    },
    updateScriptedActivities: function(groupId, scriptedActivities) {
        return putData(`${ApiHost}/api/ActivityGroups/${groupId}/scriptedActivities`, scriptedActivities);
    },
}
