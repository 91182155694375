// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import React, { useCallback, useMemo } from "react";
import { ActivityTypes, AdminTypes, NoData, ReportSections, UnitType, BaseActivities } from "../../../Utils/Constants";
import { ReportTable } from "../ReportTable";
import { SummaryCard, SummaryTitleCard } from "./SummaryCard";
import {
    LineChartHistorySection,
    LineChartTimelineSection,
    NeurocognitiveSection,
    RadarChartSection,
    MobilityRepsSection
} from "./TestReportCharts/TestReportCharts";
import { CircularLoader } from "../../../CoreComponents/Loaders";
import { DateRangeSelector } from "../../../CoreComponents/DateRangeSelector";
import { useTranslation } from "../../../CoreComponents/Translation";
import { CustomScrollContainer } from "../../../CoreComponents/Layout";
import { css } from "@emotion/react";
import { UpperExtremityChartSection, UpperExtremityKinematicChartSection } from "./TestReportCharts/UpperExtremitySection";
import { KinematicChartSection, KinematicRepsSection } from "./TestReportCharts/KinematicsSection";
import { useSelector } from 'react-redux';

const styles = {
    summaryData: css`
        display: grid;
        // TODO: check size
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-gap: 20px;
        margin-bottom: 20px;
    `,
}

export const TestReportBody = ({
    testContentRef,
    unitTypeParam,
    $selectedTests,
    $scriptedActivityName,
    $scriptedActivitySteps,
    $testReportData,
    $isPrinting,
    $selectedTestStep,
    $historyData,
    $trackedMetrics,
    $selectedSection,
    $enabledTestSummary,
    $enabledTestDetails,
    $showAllSections,
    $dateRangeHistory,
    $isLoadingHistory,
    $enabledHistory,
    $enabledTimeline,
    $selectedUnitType
}) => {
    const storeState = useSelector((state) => state.storeState);
    const { t, /* tWithHtml */ } = useTranslation();
    /*const $selectedUnitType = useHook(
        UnitTypeTranslationKeysObj[unitTypeParam] ?
            Number(unitTypeParam)
            : UnitType.Feet
    );*/

    const unitTypeShort = $selectedUnitType.value === UnitType.Meters ?
        t('constants.unitType.metersShort')
        : t('constants.unitType.feetShort');

    const unitTypeSmall = $selectedUnitType.value === UnitType.Meters ?
        t('constants.unitType.centimetersShort')
        : t('constants.unitType.inchShort');

    const unitPerSecond = $selectedUnitType.value === UnitType.Meters ?
        t('constants.units.m/s')
        : t('constants.units.ft/s');

    const unitPerSecondSquared = $selectedUnitType.value === UnitType.Meters ?
        t('constants.units.m/s^2')
        : t('constants.units.ft/s^2');

    const isDebugging = useMemo(() =>
        JSON.parse(localStorage.getItem('show-debug-info') || 'false')
        , []);

    function classNameDetermine() {
        const currentStep = $scriptedActivitySteps.value.find(x => x.scriptedActivityStepId === $selectedTestStep.value);
        const uniqueIdentifier = `${currentStep?.scriptedActivityStepId}_${currentStep?.orderId}`;
        return "content" + (!$historyData.value[uniqueIdentifier]
            && $selectedSection.value === ReportSections.History ? " no-result" : "");
    }

    function getPrefix(isInitial, isJump, isFlexionExtension, isAdductionAbduction, isLeftRight, isInternalExternal) {
        if (isJump){
            return isInitial ? t('testReport.initialPrefix.jumpActivity') : t('testReport.postPrefix.jumpActivity');
        }
        else if (isFlexionExtension) {
            return isInitial ? t('testReport.initialPrefix.flexionExtensionActivity') : t('testReport.postPrefix.flexionExtensionActivity');
        }
        else if (isAdductionAbduction) {
            return isInitial ? t('testReport.initialPrefix.abductionAdductionActivity') : t('testReport.postPrefix.abductionAdductionActivity');
        }
        else if (isLeftRight) {
            return isInitial ? t('testReport.initialPrefix.leftRightActivity') : t('testReport.postPrefix.leftRightActivity');
        }
        else if (isInternalExternal) {
            return isInitial ? t('testReport.initialPrefix.internalExternalActivity') : t('testReport.postPrefix.internalExternalActivity');
        }
        else {
            return '';
        }
    }

    const unitType = $selectedUnitType.value === UnitType.Meters ?
        t('constants.unitType.meters')
        : t('constants.unitType.feet');

    const currentStepBaseActivityId = $selectedTestStep.value ? $scriptedActivitySteps.value.find(x => x.scriptedActivityStepId === $selectedTestStep.value).baseActivityId : "";

    const isJumpActivity = currentStepBaseActivityId === BaseActivities.KinematicJump || currentStepBaseActivityId === BaseActivities.KinematicSingleLegJump || currentStepBaseActivityId === BaseActivities.VerticalDropJump;
    const isFlexionExtensionActivity = currentStepBaseActivityId === BaseActivities.ROMElbowFlexionExtension || currentStepBaseActivityId === BaseActivities.ROMFlexionExtension || currentStepBaseActivityId === BaseActivities.ROMShoulderLinearFlexionExtension;
    const isAdductionAbductionActivity = currentStepBaseActivityId === BaseActivities.ROMShoulderHorizontalAdductionAbduction || currentStepBaseActivityId === BaseActivities.ROMShoulderLateralAdductionAbduction;
    const isLeftRightActivity = currentStepBaseActivityId === BaseActivities.ROMRotation || currentStepBaseActivityId === BaseActivities.ROMLateralFlexion;
    const isInternalExternalActivity = currentStepBaseActivityId === BaseActivities.ROMShoulderInternalExternalRotation;
    const initialPrefix = getPrefix(true, isJumpActivity, isFlexionExtensionActivity, isAdductionAbductionActivity, isLeftRightActivity, isInternalExternalActivity);
    const postPrefix = getPrefix(false, isJumpActivity, isFlexionExtensionActivity, isAdductionAbductionActivity, isLeftRightActivity, isInternalExternalActivity);

    const summaryDataMap = useCallback((cognitiveType, activityTypeId) => ({
        ...(cognitiveType ? { completion: t('testReport.cognitiveType') } : null),
        duration: t('testReport.fieldName.duration'),
        targets: activityTypeId !== ActivityTypes.Games ? t('testReport.fieldName.targets') : t('testReport.fieldName.score'),
        calories: t('testReport.fieldName.calories'),
        distance: t('testReport.section.totalDistance'),
        decelerationIndex: t('testReport.fieldName.decelerationIndex'),
    }), [t]);

    const performanceDataMap = useMemo(() => ({
        averageJumpHeight: t('testReport.fieldName.averageJumpHeight'),
        averageSquatDepth: t('testReport.fieldName.averageSquatDepth'),
        averageReactionTime: t('testReport.averages.reactionTime'),
        averageDynamicReactionTime: t('testReport.averages.dynamicReactionTime'),
        averageSpeed: t('testReport.averages.speed'),
        averageAcceleration: t('testReport.averages.acceleration'),
        averageDeceleration: t('testReport.averages.deceleration'),
        averageHR: t('testReport.fieldName.averageHR'),
    }), [t]);

    const mobilityDataMap = useMemo(() => ({
        totalDistance: t('testReport.fieldName.totalDistanceAvg'),
        reactionTimeAvg: t('testReport.fieldName.avgReactionTime'),
        dynamicReactionTimeAvg: t('testReport.fieldName.avgDynamicReactionTime'),
        accelerationAvg: t('testReport.fieldName.averageAcceleration'),
        decelerationAvg: t('testReport.fieldName.averageDeceleration'),
        decelerationIndex: t('testReport.fieldName.decelerationIndex'),
        speedAvg: t('testReport.fieldName.averageSpeed'),
        jumpHeightAvg: t('testReport.fieldName.averageJumpHeight'),
        squatHeightAvg: t('testReport.fieldName.averageSquatDepth'),
        hrAverage: t('testReport.fieldName.averageHR'),
    }), [t]);
    
    const kinematicOverviewDataMap = useMemo(() => ({
        initialStanceWidthRatio: t('testReport.fieldName.stanceWidthRatio'),
        stanceWidthRatio: t('testReport.fieldName.stanceWidthRatio'),
        plantFoot: $scriptedActivityName.value.toLowerCase() === "base rotation screen" ? t('testReport.fieldName.rotationDirection') : t('testReport.fieldName.plantFoot'),
        initialStanceWidthDistance: t('testReport.fieldName.stanceWidthDistance'),
        stanceWidthDistance: t('testReport.fieldName.stanceWidthDistance'),
        initialSquatDepth: t('testReport.fieldName.squatDepth'),
        squatDepth: t('testReport.fieldName.squatDepth'),
        jumpHeight: t('testReport.fieldName.jumpHeight'),
        initialTrunkLean: t('testReport.fieldName.trunkLean'),
        trunkLean: t('testReport.fieldName.trunkLean'),
        initialTrunkLatFlexion: t('testReport.fieldName.pelvicTilt'),
        trunkLatFlexion: t('testReport.fieldName.pelvicTilt'),
        initialPelvisRotation: t('testReport.fieldName.pelvicRotation'),
        pelvisRotation: t('testReport.fieldName.pelvicRotation'),
        initialRightKneeValgusOrVarus: t('testReport.fieldName.rightKneeValgusOrVarus'),
        rightKneeValgusOrVarus: t('testReport.fieldName.rightKneeValgusOrVarus'),
        initialRightKneeFlexion: t('testReport.fieldName.rightKneeFlexion'),
        rightKneeFlexion: t('testReport.fieldName.rightKneeFlexion'),
        initialRightAnkleDorsiflexion: t('testReport.fieldName.rightAnkleDorsiflexion'),
        rightAnkleDorsiflexion: t('testReport.fieldName.rightAnkleDorsiflexion'),
        initialLeftKneeValgusOrVarus: t('testReport.fieldName.leftKneeValgusOrVarus'),
        leftKneeValgusOrVarus: t('testReport.fieldName.leftKneeValgusOrVarus'),
        initialLeftKneeFlexion: t('testReport.fieldName.leftKneeFlexion'),
        leftKneeFlexion: t('testReport.fieldName.leftKneeFlexion'),
        initialLeftAnkleDorsiflexion: t('testReport.fieldName.leftAnkleDorsiflexion'),
        leftAnkleDorsiflexion: t('testReport.fieldName.leftAnkleDorsiflexion'),
        initialShoulderRotation: t('testReport.fieldName.shoulderRotation'),
        shoulderRotation: t('testReport.fieldName.shoulderRotation'),
        initialThoracicRotation: t('testReport.fieldName.thoracicRotation'),
        thoracicRotation: t('testReport.fieldName.thoracicRotation'),
        initialLumbarRotation: t('testReport.fieldName.lumbarRotation'),
        lumbarRotation: t('testReport.fieldName.lumbarRotation'),
        initialRightHipRotation: t('testReport.fieldName.rightHipRotation'),
        rightHipRotation: t('testReport.fieldName.rightHipRotation'),
        initialLeftHipRotation: t('testReport.fieldName.leftHipRotation'),
        leftHipRotation: t('testReport.fieldName.leftHipRotation'),
        initialNeckRotation: t('testReport.fieldName.neckRotation'),
        neckRotation: t('testReport.fieldName.neckRotation'),
        initialNeckFlexion: t('testReport.fieldName.neckFlexion'),
        neckFlexion: t('testReport.fieldName.neckFlexion'),
        initialNeckLatFlexion: t('testReport.fieldName.neckLatFlexion'),
        neckLatFlexion: t('testReport.fieldName.neckLatFlexion'),
        initialSpineFlexion: t('testReport.fieldName.spineFlexion'),
        spineFlexion: t('testReport.fieldName.spineFlexion'),
        initialSpineLatFlexion: t('testReport.fieldName.spineLatFlexion'),
        spineLatFlexion: t('testReport.fieldName.spineLatFlexion'),
        initialSpineRotation: t('testReport.fieldName.spineRotation'),
        spineRotation: t('testReport.fieldName.spineRotation'),
        initialLeftShoulderRotation: t('testReport.fieldName.leftShoulderRotation'),
        initialRightShoulderRotation: t('testReport.fieldName.rightShoulderRotation'),
        leftShoulderRotation: t('testReport.fieldName.leftShoulderRotation'),
        rightShoulderRotation: t('testReport.fieldName.rightShoulderRotation'),
        initialLeftShoulderFlexion: t('testReport.fieldName.leftShoulderFlexion'),
        initialRightShoulderFlexion: t('testReport.fieldName.rightShoulderFlexion'),
        leftShoulderFlexion: t('testReport.fieldName.leftShoulderFlexion'),
        rightShoulderFlexion: t('testReport.fieldName.rightShoulderFlexion'),
        initialLeftShoulderAbduction: t('testReport.fieldName.leftShoulderAbduction'),
        initialRightShoulderAbduction: t('testReport.fieldName.rightShoulderAbduction'),
        leftShoulderAbduction: t('testReport.fieldName.leftShoulderAbduction'),
        rightShoulderAbduction: t('testReport.fieldName.rightShoulderAbduction'),
        initialLeftShoulderHorizontalAbduction: t('testReport.fieldName.leftShoulderHorizontalAbduction'),
        initialRightShoulderHorizontalAbduction: t('testReport.fieldName.rightShoulderHorizontalAbduction'),
        leftShoulderHorizontalAbduction: t('testReport.fieldName.leftShoulderHorizontalAbduction'),
        rightShoulderHorizontalAbduction: t('testReport.fieldName.rightShoulderHorizontalAbduction'),
        initialLeftElbowFlexion: t('testReport.fieldName.leftElbowFlexion'),
        initialRightElbowFlexion: t('testReport.fieldName.rightElbowFlexion'),
        leftElbowFlexion: t('testReport.fieldName.leftElbowFlexion'),
        rightElbowFlexion: t('testReport.fieldName.rightElbowFlexion'),
    }), [t, $scriptedActivityName.value]);

    const kinematicRepDataMap = useMemo(() => ({
        initialStanceWidthRatio: initialPrefix + t('testReport.fieldName.stanceWidthRatio'),
        stanceWidthRatio: postPrefix + t('testReport.fieldName.stanceWidthRatio'),
        plantFoot: $scriptedActivityName.value.toLowerCase() === "base rotation screen" ? t('testReport.fieldName.rotationDirection') : t('testReport.fieldName.plantFoot'),
        initialStanceWidthDistance: initialPrefix + t('testReport.fieldName.stanceWidthDistance'),
        stanceWidthDistance: postPrefix + t('testReport.fieldName.stanceWidthDistance'),
        initialSquatDepth: initialPrefix + t('testReport.fieldName.squatDepth'),
        squatDepth: postPrefix + t('testReport.fieldName.squatDepth'),
        jumpHeight: t('testReport.fieldName.jumpHeight'),
        initialTrunkLean: initialPrefix + t('testReport.fieldName.trunkLean'),
        trunkLean: postPrefix + t('testReport.fieldName.trunkLean'),
        initialTrunkLatFlexion: initialPrefix + t('testReport.fieldName.pelvicTilt'),
        trunkLatFlexion: postPrefix + t('testReport.fieldName.pelvicTilt'),
        initialPelvisRotation: initialPrefix + t('testReport.fieldName.pelvicRotation'),
        pelvisRotation: postPrefix + t('testReport.fieldName.pelvicRotation'),
        initialRightKneeValgusOrVarus: initialPrefix + t('testReport.fieldName.rightKneeValgusOrVarus'),
        rightKneeValgusOrVarus: postPrefix + t('testReport.fieldName.rightKneeValgusOrVarus'),
        initialRightKneeFlexion: initialPrefix + t('testReport.fieldName.rightKneeFlexion'),
        rightKneeFlexion: postPrefix + t('testReport.fieldName.rightKneeFlexion'),
        initialRightAnkleDorsiflexion: initialPrefix + t('testReport.fieldName.rightAnkleDorsiflexion'),
        rightAnkleDorsiflexion: postPrefix + t('testReport.fieldName.rightAnkleDorsiflexion'),
        initialLeftKneeValgusOrVarus: initialPrefix + t('testReport.fieldName.leftKneeValgusOrVarus'),
        leftKneeValgusOrVarus: postPrefix + t('testReport.fieldName.leftKneeValgusOrVarus'),
        initialLeftKneeFlexion: initialPrefix + t('testReport.fieldName.leftKneeFlexion'),
        leftKneeFlexion: postPrefix + t('testReport.fieldName.leftKneeFlexion'),
        initialLeftAnkleDorsiflexion: initialPrefix + t('testReport.fieldName.leftAnkleDorsiflexion'),
        leftAnkleDorsiflexion: postPrefix + t('testReport.fieldName.leftAnkleDorsiflexion'),
        initialShoulderRotation: initialPrefix + t('testReport.fieldName.shoulderRotation'),
        shoulderRotation: postPrefix + t('testReport.fieldName.shoulderRotation'),
        initialThoracicRotation: initialPrefix + t('testReport.fieldName.thoracicRotation'),
        thoracicRotation: postPrefix + t('testReport.fieldName.thoracicRotation'),
        initialLumbarRotation: initialPrefix + t('testReport.fieldName.lumbarRotation'),
        lumbarRotation: postPrefix + t('testReport.fieldName.lumbarRotation'),
        initialRightHipRotation: initialPrefix + t('testReport.fieldName.rightHipRotation'),
        rightHipRotation: postPrefix + t('testReport.fieldName.rightHipRotation'),
        initialLeftHipRotation: initialPrefix + t('testReport.fieldName.leftHipRotation'),
        leftHipRotation: postPrefix + t('testReport.fieldName.leftHipRotation'),
    }), [t, initialPrefix, postPrefix, $scriptedActivityName.value]);

    const upperExtremityKinematicDataMap = useMemo(() => ({
        initialNeckRotation: initialPrefix + t('testReport.fieldName.neckRotation'),
        neckRotation: postPrefix + t('testReport.fieldName.neckRotation'),
        initialNeckFlexion: initialPrefix + t('testReport.fieldName.neckFlexion'),
        neckFlexion: postPrefix + t('testReport.fieldName.neckFlexion'),
        initialNeckLatFlexion: initialPrefix + t('testReport.fieldName.neckLatFlexion'),
        neckLatFlexion: postPrefix + t('testReport.fieldName.neckLatFlexion'),
        initialSpineFlexion: initialPrefix + t('testReport.fieldName.spineFlexion'),
        spineFlexion: postPrefix + t('testReport.fieldName.spineFlexion'),
        initialSpineLatFlexion: initialPrefix + t('testReport.fieldName.spineLatFlexion'),
        spineLatFlexion: postPrefix + t('testReport.fieldName.spineLatFlexion'),
        initialSpineRotation: initialPrefix + t('testReport.fieldName.spineRotation'),
        spineRotation: postPrefix + t('testReport.fieldName.spineRotation'),
        initialLeftShoulderRotation: initialPrefix + t('testReport.fieldName.leftShoulderRotation'),
        initialRightShoulderRotation: initialPrefix + t('testReport.fieldName.rightShoulderRotation'),
        leftShoulderRotation: postPrefix + t('testReport.fieldName.leftShoulderRotation'),
        rightShoulderRotation: postPrefix + t('testReport.fieldName.rightShoulderRotation'),
        initialLeftShoulderFlexion: initialPrefix + t('testReport.fieldName.leftShoulderFlexion'),
        initialRightShoulderFlexion: initialPrefix + t('testReport.fieldName.rightShoulderFlexion'),
        leftShoulderFlexion: postPrefix + t('testReport.fieldName.leftShoulderFlexion'),
        rightShoulderFlexion: postPrefix + t('testReport.fieldName.rightShoulderFlexion'),
        initialLeftShoulderAbduction: initialPrefix + t('testReport.fieldName.leftShoulderAbduction'),
        initialRightShoulderAbduction: initialPrefix + t('testReport.fieldName.rightShoulderAbduction'),
        leftShoulderAbduction: postPrefix + t('testReport.fieldName.leftShoulderAbduction'),
        rightShoulderAbduction: postPrefix + t('testReport.fieldName.rightShoulderAbduction'),
        initialLeftShoulderHorizontalAbduction: initialPrefix + t('testReport.fieldName.leftShoulderHorizontalAbduction'),
        initialRightShoulderHorizontalAbduction: initialPrefix + t('testReport.fieldName.rightShoulderHorizontalAbduction'),
        leftShoulderHorizontalAbduction: postPrefix + t('testReport.fieldName.leftShoulderHorizontalAbduction'),
        rightShoulderHorizontalAbduction: postPrefix + t('testReport.fieldName.rightShoulderHorizontalAbduction'),
        initialLeftElbowFlexion: initialPrefix + t('testReport.fieldName.leftElbowFlexion'),
        initialRightElbowFlexion: initialPrefix + t('testReport.fieldName.rightElbowFlexion'),
        leftElbowFlexion: postPrefix + t('testReport.fieldName.leftElbowFlexion'),
        rightElbowFlexion: postPrefix + t('testReport.fieldName.rightElbowFlexion'),
    }), [t, initialPrefix, postPrefix]);

    const upperExtremityDataMap = useMemo(() => ({
        totalTargetsHit: t('testReport.fieldName.totalTargetsHit'),
        targetsMissed: t('testReport.fieldName.targetsMissed'),
        targetsHitLeft180: t('testReport.fieldName.targetsHitLeft180'),
        targetsHitLeft45: t('testReport.fieldName.targetsHitLeft45'),
        targetsHitLeft90: t('testReport.fieldName.targetsHitLeft90'),
        targetsHitLeft135: t('testReport.fieldName.targetsHitLeft135'),
        targetsHitForwardLeft45: t('testReport.fieldName.targetsHitForwardLeft45'),
        targetsHitForwardLeft90: t('testReport.fieldName.targetsHitForwardLeft90'),
        targetsHitForwardLeft135: t('testReport.fieldName.targetsHitForwardLeft135'),
        targetsHitRight180: t('testReport.fieldName.targetsHitRight180'),
        targetsHitRight45: t('testReport.fieldName.targetsHitRight45'),
        targetsHitRight90: t('testReport.fieldName.targetsHitRight90'),
        targetsHitRight135: t('testReport.fieldName.targetsHitRight135'),
        targetsHitForwardRight45: t('testReport.fieldName.targetsHitForwardRight45'),
        targetsHitForwardRight90: t('testReport.fieldName.targetsHitForwardRight90'),
        targetsHitForwardRight135: t('testReport.fieldName.targetsHitForwardRight135'),
        lrTargetsHitDiff: t('testReport.fieldName.lrTargetsHitDiff'),
        unadjustedReactionTimeAvg: t('testReport.fieldName.reactionTimeAvg'),
        unadjustedReactionTimeLeft: t('testReport.fieldName.reactionTimeLeft'),
        unadjustedReactionTimeRight: t('testReport.fieldName.reactionTimeRight'),
        lrReactionTimeDiff: t('testReport.fieldName.lrReactionTimeDiff'),
        reactionTimeAvg: t('testReport.fieldName.dynamicReactionTimeAvg'),
        reactionTimeLeft: t('testReport.fieldName.dynamicReactionTimeLeft'),
        reactionTimeRight: t('testReport.fieldName.dynamicReactionTimeRight'),
        lrDynamicReactionTimeDiff: t('testReport.fieldName.lrDynamicReactionTimeDiff'),
    }), [t]);

    const historyDataMaps = useMemo(() => ({
        totalTargetsHit: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.totalTargets'),
        },
        totalSway: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.totalSway'),
        },
        misses: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.misses'),
        },
        totalDistance: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.totalDistance'),
        },
        reactionTime: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.averageReactionTime'),
        },
        dynamicReactionTime: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.averageDynamicReactionTime'),
        },
        speed: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.averageSpeed'),
        },
        acceleration: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.averageAcceleration'),
        },
        deceleration: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.averageDeceleration'),
        },
        heartRate: {
            label: t('testReport.testInfo.date'),
            value: t('testReport.fieldName.averageHeartRate'),
        },
        initialNeckRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                initialNeckRotation: initialPrefix + t('testReport.fieldName.neckRotation'),
            }
        },
        neckRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                neckRotation: postPrefix + t('testReport.fieldName.neckRotation'),
            }
        },
        initialNeckFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                initialNeckFlexion: initialPrefix + t('testReport.fieldName.neckFlexion'),
                initialNeckLatFlexion: initialPrefix + t('testReport.fieldName.neckLatFlexion'),
            }
        },
        neckFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                neckFlexion: postPrefix + t('testReport.fieldName.neckFlexion'),
                neckLatFlexion: postPrefix + t('testReport.fieldName.neckLatFlexion'),
            }
        },
        initialSpineRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                initialSpineRotation: initialPrefix + t('testReport.fieldName.spineRotation'),
            }
        },
        spineRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                spineRotation: postPrefix + t('testReport.fieldName.spineRotation'),
            }
        },
        initialSpineFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                initialSpineFlexion: initialPrefix + t('testReport.fieldName.spineFlexion'),
                initialSpineLatFlexion: initialPrefix + t('testReport.fieldName.spineLatFlexion'),
            }
        },
        spineFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                spineFlexion: postPrefix + t('testReport.fieldName.spineFlexion'),
                spineLatFlexion: postPrefix + t('testReport.fieldName.spineLatFlexion'),
            }
        },
        initialLRShoulderRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftShoulderRotation: initialPrefix + t('testReport.fieldName.leftShoulderRotation'),
                initialRightShoulderRotation: initialPrefix + t('testReport.fieldName.rightShoulderRotation'),
            }
        },
        lrShoulderRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                leftShoulderRotation: postPrefix + t('testReport.fieldName.leftShoulderRotation'),
                rightShoulderRotation: postPrefix + t('testReport.fieldName.rightShoulderRotation'),
            }
        },
        initialShoulderFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftShoulderFlexion: initialPrefix + t('testReport.fieldName.leftShoulderFlexion'),
                initialRightShoulderFlexion: initialPrefix + t('testReport.fieldName.rightShoulderFlexion'),
            }
        },
        shoulderFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                leftShoulderFlexion: postPrefix + t('testReport.fieldName.leftShoulderFlexion'),
                rightShoulderFlexion: postPrefix + t('testReport.fieldName.rightShoulderFlexion'),
            }
        },
        initialShoulderAbduction: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftShoulderAbduction: initialPrefix + t('testReport.fieldName.leftShoulderAbduction'),
                initialRightShoulderAbduction: initialPrefix + t('testReport.fieldName.rightShoulderAbduction'),
            }
        },
        shoulderAbduction: {
            label: t('testReport.testInfo.date'),
            values: {
                leftShoulderAbduction: postPrefix + t('testReport.fieldName.leftShoulderAbduction'),
                rightShoulderAbduction: postPrefix + t('testReport.fieldName.rightShoulderAbduction'),
            }
        },
        initialShoulderHorizontalAbduction: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftShoulderHorizontalAbduction: initialPrefix + t('testReport.fieldName.leftShoulderHorizontalAbduction'),
                initialRightShoulderHorizontalAbduction: initialPrefix + t('testReport.fieldName.rightShoulderHorizontalAbduction'),
            }
        },
        shoulderHorizontalAbduction: {
            label: t('testReport.testInfo.date'),
            values: {
                leftShoulderHorizontalAbduction: postPrefix + t('testReport.fieldName.leftShoulderHorizontalAbduction'),
                rightShoulderHorizontalAbduction: postPrefix + t('testReport.fieldName.rightShoulderHorizontalAbduction'),
            }
        },
        initialElbowFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftElbowFlexion: initialPrefix + t('testReport.fieldName.leftElbowFlexion'),
                initialRightElbowFlexion: initialPrefix + t('testReport.fieldName.rightElbowFlexion'),
            }
        },
        elbowFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                leftElbowFlexion: postPrefix + t('testReport.fieldName.leftElbowFlexion'),
                rightElbowFlexion: postPrefix + t('testReport.fieldName.rightElbowFlexion'),
            }
        },
        initialTorso: {
            label: t('testReport.testInfo.date'),
            values: {
                initialTrunkLean: initialPrefix + t('testReport.fieldName.trunkLean'),
                initialTrunkLatFlexion: initialPrefix + t('testReport.fieldName.pelvicTilt'),
            }
        },
        torso: {
            label: t('testReport.testInfo.date'),
            values: {
                trunkLean: postPrefix + t('testReport.fieldName.trunkLean'),
                trunkLatFlexion: postPrefix + t('testReport.fieldName.pelvicTilt'),
            }
        },
        initialPelvisRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                initialPelvisRotation: initialPrefix + t('testReport.fieldName.pelvicRotation'),
            }
        },
        pelvisRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                pelvisRotation: postPrefix + t('testReport.fieldName.pelvicRotation'),
            }
        },
        initialSquatDepth: {
            label: t('testReport.testInfo.date'),
            values: {
                initialSquatDepth: initialPrefix + t('testReport.fieldName.squatDepth'),
            }
        },
        squatDepth: {
            label: t('testReport.testInfo.date'),
            values: {
                squatDepth: postPrefix + t('testReport.fieldName.squatDepth'),
            }
        },
        jumpHeight: {
            label: t('testReport.testInfo.date'),
            values: {
                jumpHeight: t('testReport.fieldName.jumpHeight'),
            }
        },
        initialKneeFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftKneeFlexion: initialPrefix + t('testReport.fieldName.leftKneeFlexion'),
                initialRightKneeFlexion: initialPrefix + t('testReport.fieldName.rightKneeFlexion'),
            }
        },
        kneeFlexion: {
            label: t('testReport.testInfo.date'),
            values: {
                leftKneeFlexion: postPrefix + t('testReport.fieldName.leftKneeFlexion'),
                rightKneeFlexion: postPrefix + t('testReport.fieldName.rightKneeFlexion'),
            }
        },
        initialKneeValgusOrVarus: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftKneeValgusOrVarus: initialPrefix + t('testReport.fieldName.leftKneeValgusOrVarus'),
                initialRightKneeValgusOrVarus: initialPrefix + t('testReport.fieldName.rightKneeValgusOrVarus'),
            }
        },
        kneeValgusOrVarus: {
            label: t('testReport.testInfo.date'),
            values: {
                leftKneeValgusOrVarus: postPrefix + t('testReport.fieldName.leftKneeValgusOrVarus'),
                rightKneeValgusOrVarus: postPrefix + t('testReport.fieldName.rightKneeValgusOrVarus'),
            }
        },
        initialDorsiflexion: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftAnkleDorsiflexion: initialPrefix + t('testReport.fieldName.leftDorsiflexion'),
                initialRightAnkleDorsiflexion: initialPrefix + t('testReport.fieldName.rightDorsiflexion'),
            }
        },
        dorsiflexion: {
            label: t('testReport.testInfo.date'),
            values: {
                leftAnkleDorsiflexion: postPrefix + t('testReport.fieldName.leftDorsiflexion'),
                rightAnkleDorsiflexion: postPrefix + t('testReport.fieldName.rightDorsiflexion'),
            }
        },
        initialShoulderRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                initialShoulderRotation: initialPrefix + t('testReport.fieldName.shoulderRotation'),
            }
        },
        shoulderRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                shoulderRotation: postPrefix + t('testReport.fieldName.shoulderRotation'),
            }
        },
        initialThoracicRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                initialThoracicRotation: initialPrefix + t('testReport.fieldName.thoracicRotation'),
            }
        },
        thoracicRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                thoracicRotation: postPrefix + t('testReport.fieldName.thoracicRotation'),
            }
        },
        initialLumbarRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLumbarRotation: initialPrefix + t('testReport.fieldName.lumbarRotation'),
            }
        },
        lumbarRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                lumbarRotation: postPrefix + t('testReport.fieldName.lumbarRotation'),
            }
        },
        initialHipRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                initialLeftHipRotation: initialPrefix + t('testReport.fieldName.leftHipRotation'),
                initialRightHipRotation: initialPrefix + t('testReport.fieldName.rightHipRotation'),
            }
        },
        hipRotation: {
            label: t('testReport.testInfo.date'),
            values: {
                leftHipRotation: postPrefix + t('testReport.fieldName.leftHipRotation'),
                rightHipRotation: postPrefix + t('testReport.fieldName.rightHipRotation'),
            }
        },
    }), [t, initialPrefix, postPrefix]);

    return (
        <CustomScrollContainer
            ref={testContentRef}
            className={classNameDetermine()}>
            {isDebugging &&
                <div>
                    SELECTED TESTS [{$selectedTests.value.map(x => x.testId).join(', ')}]
                </div>
            }

            {$scriptedActivitySteps.value.map((sas, index) => {
                const currentStepReportData = $testReportData.value
                    .map(trd => {
                        const uniqueStepKey = `${sas.scriptedActivityStepId}_${sas.orderId}`;
                        return trd.steps[uniqueStepKey]
                    })
                    .filter(x => x);

                // show all steps if is printing or
                // only the selected step
                if (!currentStepReportData.length
                    || (!$isPrinting.value
                        && currentStepReportData[0].scriptedActivityStepId !== $selectedTestStep.value
                    )
                ) {
                    return null;
                }

                const hasBalanceData = currentStepReportData.some(x => x.hasBalanceData);
                const hasBalanceMissesData = currentStepReportData.some(trd => trd.hasBalanceMissesData);
                const hasMobilityData = currentStepReportData.some(x => x.hasMobilityData);
                const hasMobilityTimelineData = currentStepReportData.some(x => x.hasMobilityTimelineData);
                const hasKinematicData = currentStepReportData.some(x => x.hasKinematicData);
                const hasUpperExtremityData = currentStepReportData.some(x => x.hasUpperExtremityData);
                const hasNeurocognitiveData = currentStepReportData.some(x => x.neurocognitive);
                const addDisclaimer = currentStepReportData.some(x => x.addDisclaimer);
                const currentStepHistoryData = $historyData.value[`${sas.scriptedActivityStepId}_${sas.orderId}`];
                const hasSummaryData = currentStepReportData.some(x => x.summary);
                const isCompare = $testReportData.value.length > 1;

                const hasUpperExtremityKinematicsData = hasKinematicData
                    && currentStepReportData.some(x => x.kinematic &&
                        (
                            (x.kinematic.neckRotation !== NoData
                                || x.kinematic.neckFlexion !== NoData
                                || x.kinematic.neckLatFlexion !== NoData
                                || x.kinematic.spineFlexion !== NoData
                                || x.kinematic.spineLatFlexion !== NoData
                                || x.kinematic.spineRotation !== NoData
                                || x.kinematic.leftShoulderFlexion !== NoData
                                || x.kinematic.rightShoulderFlexion !== NoData
                                || x.kinematic.leftShoulderAbduction !== NoData
                                || x.kinematic.rightShoulderAbduction !== NoData
                                || x.kinematic.leftShoulderHorizontalAbduction !== NoData
                                || x.kinematic.rightShoulderHorizontalAbduction !== NoData
                                || x.kinematic.leftElbowFlexion !== NoData
                                || x.kinematic.rightElbowFlexion !== NoData)
                            && (x.kinematic.rightKneeFlexion === NoData
                                && x.kinematic.leftKneeFlexion === NoData
                                && x.kinematic.rightAnkleDorsiflexion === NoData
                                && x.kinematic.leftAnkleDorsiflexion === NoData)
                        ));

                const trackedPerformanceDataMap = ({
                    ...performanceDataMap
                });
                const uniqueStepKey = `${sas.scriptedActivityStepId}_${sas.orderId}`;

                const metricsSettings = $trackedMetrics.value[uniqueStepKey];
                if (!metricsSettings.trackedDistance) {
                    delete trackedPerformanceDataMap.totalDistance;
                }
                if (!metricsSettings.trackedAcceleration || !metricsSettings.trackedDeceleration) {
                    delete trackedPerformanceDataMap.decelerationIndex;
                }
                if (!metricsSettings.trackedJumpHeight) {
                    delete trackedPerformanceDataMap.averageJumpHeight;
                }
                if (!metricsSettings.trackedSquatDepth) {
                    delete trackedPerformanceDataMap.averageSquatDepth;
                }
                if (!metricsSettings.trackedHeartRate) {
                    delete trackedPerformanceDataMap.averageHR;
                    delete trackedPerformanceDataMap.maxHR;
                }

                return (
                    <React.Fragment key={sas.scriptedActivityStepId}>
                        {($selectedSection.value === ReportSections.Details || $showAllSections.value) && hasSummaryData ?
                            (($isPrinting.value && $enabledTestSummary.value) || !$isPrinting.value) &&
                            <>
                                <h2>{t('testReport.section.stepSummary')}</h2>
                                {isCompare ?
                                    <>
                                        {$isPrinting.value &&
                                            <div css={styles.summaryData}>
                                                <SummaryTitleCard
                                                    activityName={sas.name}
                                                />
                                            </div>
                                        }
                                        <ReportTable
                                            enableRowHeader
                                            headers={summaryDataMap(currentStepReportData.some(x => x.summary?.completion), sas.activityTypeId)}
                                            data={currentStepReportData.map(srd => ({ ...srd.summary, rowName: srd.testName }))}
                                        />
                                    </>
                                    :
                                    currentStepReportData.map(({ summary: summaryData }) => summaryData &&
                                        <div key={summaryData.scriptedActivityStepName} css={styles.summaryData}>
                                            <SummaryTitleCard
                                                activityName={summaryData.scriptedActivityStepName}
                                                // TODO: ask about this
                                                testType={t('testReport.cognitiveType')}
                                                completion={summaryData.completion}
                                            />
                                            <SummaryCard
                                                title={`${t('testReport.fieldName.duration')}`}
                                                left={{ name: t('testReport.fieldName.total'), value: summaryData.duration }}
                                            />
                                            <SummaryCard
                                                title={sas.activityTypeId !== ActivityTypes.Games ? `${t('testReport.fieldName.targets')}` : `${t('testReport.fieldName.score')}`}
                                                left={{ name: t('testReport.fieldName.total'), value: summaryData.targets }}
                                            />
                                            <SummaryCard
                                                title={t('testReport.fieldName.calories')}
                                                left={{ name: t('testReport.fieldName.total'), value: summaryData.calories }}
                                            />
                                            {metricsSettings.trackedDistance && summaryData.distance &&
                                                <SummaryCard
                                                    title={t('testReport.section.totalDistance')}
                                                    left={{ name: t('testReport.fieldName.total'), value: summaryData.distance }}
                                                />
                                            }
                                            {
                                                metricsSettings.trackedAcceleration && metricsSettings.trackedDeceleration && summaryData.decelerationIndex !== NoData &&
                                                <SummaryCard
                                                    title={t('testReport.fieldName.decelerationIndex')}
                                                    left={{ name: t('testReport.fieldName.total'), value: summaryData.decelerationIndex }}
                                                />
                                            }
                                        </div>
                                    )}
                            </>
                            : $isPrinting.value ?
                                <div className={!$enabledTestSummary.value ? 'disabledSection' : null}>
                                    <h2>{t('testReport.section.stepSummary')}</h2>
                                    <div css={styles.summaryData}>
                                        <SummaryTitleCard
                                            activityName={sas.name}
                                        />
                                    </div>
                                </div>
                                : null}

                        <>
                            {hasBalanceData && ($selectedSection.value === ReportSections.Details || $showAllSections.value) &&
                                (($isPrinting.value && $enabledTestDetails.value) || !$isPrinting.value) &&
                                metricsSettings.trackedSway &&
                                <RadarChartSection
                                    isPrinting={$isPrinting.value}
                                    sectionName={t('testReport.section.sway')}
                                    units={unitType}
                                    datasets={currentStepReportData
                                        .map((srd, i) => ({
                                            label: srd.testName,
                                            data: srd.chartBalanceSwayData
                                        }))
                                        .filter(x => x.data)
                                    }
                                />
                            }

                            {hasBalanceMissesData && ($selectedSection.value === ReportSections.Details || $showAllSections.value) &&
                                (($isPrinting.value && $enabledTestDetails.value) || !$isPrinting.value) &&
                                metricsSettings.trackedMisses &&
                                <div className="table-section">
                                    <h2>{t('testReport.section.misses')}</h2>
                                    <ReportTable
                                        enableRowHeader
                                        data={currentStepReportData.map(trd => trd.balanceMissesData)}
                                    />
                                </div>
                            }

                            {hasKinematicData && ($selectedSection.value === ReportSections.Details || $showAllSections.value) &&
                                (($isPrinting.value && $enabledTestDetails.value) || !$isPrinting.value) &&
                                <>
                                    {hasUpperExtremityKinematicsData ?
                                        <UpperExtremityKinematicChartSection
                                            isPrinting={$isPrinting.value}
                                            sectionName={t('testReport.section.kinematicAverages')}
                                            baseActivityId={sas.baseActivityId}
                                            dataMap={kinematicOverviewDataMap}
                                            datasets={currentStepReportData
                                                .map((srd, i) => ({
                                                    label: srd.testName,
                                                    isDifference: srd.isDifference,
                                                    data: srd.kinematic
                                                }))
                                                .filter(x => x.data)
                                            }
                                            trackedMetrics={{ spine: metricsSettings.trackedSpine, arms: metricsSettings.trackedArms }}
                                        />
                                        :
                                        <KinematicChartSection
                                            isPrinting={$isPrinting.value}
                                            sectionName={t('testReport.section.kinematicAverages')}
                                            baseActivityId={sas.baseActivityId}
                                            dataMap={kinematicOverviewDataMap}
                                            datasets={currentStepReportData
                                                .map((srd, i) => ({
                                                    label: srd.testName,
                                                    isDifference: srd.isDifference,
                                                    data: srd.kinematic
                                                }))
                                                .filter(x => x.data)
                                            }
                                            trackedMetrics={
                                                {
                                                    torso: metricsSettings.trackedTorso,
                                                    legs: metricsSettings.trackedLegs,
                                                    spine: metricsSettings.trackedSpine,
                                                    arms: metricsSettings.trackedArms
                                                }
                                            }
                                        />
                                    }
                                    {storeState.currentUser.admin.adminTypeId !== AdminTypes.User &&
                                        <>
                                            <h2>{t('testReport.section.kinematicReps')}</h2>
                                            {currentStepReportData.map(srd => (
                                                <KinematicRepsSection
                                                    key={srd.testName}
                                                    sectionName={srd.testName}
                                                    dataMap={hasUpperExtremityKinematicsData ?
                                                        upperExtremityKinematicDataMap
                                                        : ({ ...kinematicRepDataMap, ...upperExtremityKinematicDataMap })
                                                    }
                                                    datasets={srd.kinematicReps}
                                                    trackedMetrics={{
                                                        torso: metricsSettings.trackedTorso,
                                                        legs: metricsSettings.trackedLegs,
                                                        spine: metricsSettings.trackedSpine,
                                                        arms: metricsSettings.trackedArms
                                                    }}
                                                />
                                            ))}
                                        </>
                                    }
                                </>
                            }

                            {hasUpperExtremityData && ($selectedSection.value === ReportSections.Details || $showAllSections.value) &&
                                (($isPrinting.value && $enabledTestDetails.value) || !$isPrinting.value) &&
                                <UpperExtremityChartSection
                                    isPrinting={$isPrinting.value}
                                    sectionName={t('testReport.section.upperExtremityAverages')}
                                    dataMap={upperExtremityDataMap}
                                    datasets={currentStepReportData
                                        .map((srd, i) => ({
                                            label: srd.testName,
                                            isDifference: srd.isDifference,
                                            data: srd.upperExtremity
                                        }))
                                        .filter(x => x.data)
                                    }
                                />
                            }

                            {hasNeurocognitiveData && ($selectedSection.value === ReportSections.Details || $showAllSections.value) &&
                                (($isPrinting.value && $enabledTestDetails.value) || !$isPrinting.value) && storeState.currentUser.admin.adminTypeId !== AdminTypes.User &&
                                <>
                                    <h2>{t('testReport.section.neurocognitive')}</h2>
                                    {currentStepReportData.map(srd => (
                                        <NeurocognitiveSection
                                            key={srd.testName}
                                            sectionName={srd.testName}
                                            data={srd.neurocognitive}
                                        />
                                    ))}
                                </>
                            }

                            {hasUpperExtremityData && ($selectedSection.value === ReportSections.Details || $showAllSections.value) &&
                                (($isPrinting.value && $enabledTestDetails.value) || !$isPrinting.value) && storeState.currentUser.admin.adminTypeId !== AdminTypes.User &&
                                <>
                                    {metricsSettings.trackedReaction &&
                                        <>
                                            <RadarChartSection
                                                isPrinting={$isPrinting.value}
                                                sectionName={t('testReport.section.reactionTime')}
                                                units={t('constants.units.seconds')}
                                                datasets={currentStepReportData
                                                    .map((srd, i) => ({
                                                        label: srd.testName,
                                                        data: srd.chartReactionTimeData
                                                    }))
                                                    .filter(x => x.data)
                                                }
                                            />
                                            <RadarChartSection
                                                isPrinting={$isPrinting.value}
                                                sectionName={t('testReport.section.dynamicReactionTime')}
                                                units={t('constants.units.seconds')}
                                                datasets={currentStepReportData
                                                    .map((srd, i) => ({
                                                        label: srd.testName,
                                                        data: srd.chartDynamicReactionTimeData
                                                    }))
                                                    .filter(x => x.data)
                                                }
                                            />
                                        </>
                                    }
                                </>
                            }

                            {hasMobilityData && ($selectedSection.value === ReportSections.Details || $showAllSections.value) &&
                                (($isPrinting.value && $enabledTestDetails.value) || !$isPrinting.value) &&
                                <>
                                    {(currentStepReportData.some(trd => Object.values(trd.performance).filter(Boolean).length > 1) && currentStepBaseActivityId !== BaseActivities.ChairStand30 && currentStepBaseActivityId !== BaseActivities.BalanceGame) &&
                                        <div className="table-section">
                                            <h2>{t('testReport.section.averages')}</h2>
                                            <ReportTable
                                                enableRowHeader
                                                headers={trackedPerformanceDataMap}
                                                data={currentStepReportData.map(trd => trd.performance)}
                                            />
                                        </div>
                                    }
                                    {storeState.currentUser.admin.adminTypeId !== AdminTypes.User &&
                                        <>
                                            {metricsSettings.trackedReaction &&
                                                <>
                                                    <RadarChartSection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.reactionTime')}
                                                        units={t('constants.units.seconds')}
                                                        datasets={currentStepReportData
                                                            .map((srd, i) => ({
                                                                label: srd.testName,
                                                                data: srd.chartReactionTimeData
                                                            }))
                                                            .filter(x => x.data)
                                                        }
                                                    />
                                                    <RadarChartSection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.dynamicReactionTime')}
                                                        units={t('constants.units.seconds')}
                                                        datasets={currentStepReportData
                                                            .map((srd, i) => ({
                                                                label: srd.testName,
                                                                data: srd.chartDynamicReactionTimeData
                                                            }))
                                                            .filter(x => x.data)
                                                        }
                                                    />
                                                </>
                                            }
                                            {metricsSettings.trackedSpeed &&
                                                <RadarChartSection
                                                    isPrinting={$isPrinting.value}
                                                    sectionName={t('testReport.section.speed')}
                                                    units={unitPerSecond}
                                                    datasets={currentStepReportData
                                                        .map((srd, i) => ({
                                                            label: srd.testName,
                                                            data: srd.chartSpeedData
                                                        }))
                                                        .filter(x => x.data)
                                                    }
                                                />
                                            }
                                            {metricsSettings.trackedAcceleration &&
                                                <RadarChartSection
                                                    isPrinting={$isPrinting.value}
                                                    sectionName={t('testReport.section.acceleration')}
                                                    units={unitPerSecondSquared}
                                                    datasets={currentStepReportData
                                                        .map((srd, i) => ({
                                                            label: srd.testName,
                                                            data: srd.chartAccelerationData
                                                        }))
                                                        .filter(x => x.data)
                                                    }
                                                />
                                            }
                                            {metricsSettings.trackedDeceleration &&
                                                <RadarChartSection
                                                    isPrinting={$isPrinting.value}
                                                    sectionName={t('testReport.section.deceleration')}
                                                    units={unitPerSecondSquared}
                                                    datasets={currentStepReportData
                                                        .map((srd, i) => ({
                                                            label: srd.testName,
                                                            data: srd.chartDecelerationData
                                                        }))
                                                        .filter(x => x.data)
                                                    }
                                                />
                                            }
                                            {sas.activityTypeId !== ActivityTypes.Games &&
                                                <>
                                                    <h2>{t('testReport.section.mobilityReps')}</h2>
                                                    {currentStepReportData.map(srd => (
                                                        <MobilityRepsSection
                                                            key={srd.testName}
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={srd.testName}
                                                            dataMap={mobilityDataMap}
                                                            datasets={srd.mobilityReps}
                                                            trackedMetrics={{
                                                                acceleration: metricsSettings.trackedAcceleration,
                                                                deceleration: metricsSettings.trackedDeceleration,
                                                                speed: metricsSettings.trackedSpeed,
                                                                heartRate: metricsSettings.trackedHeartRate,
                                                                jumpHeight: metricsSettings.trackedJumpHeight,
                                                                distance: metricsSettings.trackedDistance,
                                                                reactionTime: metricsSettings.trackedReaction,
                                                                squatDepth: metricsSettings.trackedSquatDepth,
                                                            }}
                                                        />
                                                    ))}
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {($selectedSection.value === ReportSections.Timeline || $showAllSections.value) && hasMobilityTimelineData &&
                                (($isPrinting.value && $enabledTimeline.value) || !$isPrinting.value) && storeState.currentUser.admin.adminTypeId !== AdminTypes.User &&
                                <>
                                    <h2>{t('testReport.section.timeline')}</h2>
                                    {metricsSettings.trackedReaction &&
                                        <>
                                            <LineChartTimelineSection
                                                isPrinting={$isPrinting.value}
                                                sectionName={t('testReport.section.reactionTime')}
                                                units={t('constants.units.seconds')}
                                                yAxisLabel={t('testReport.section.timeline.reactionTime.yAxis')}
                                                datasets={currentStepReportData
                                                    .map((srd, i) => ({
                                                        label: srd.testName,
                                                        data: srd.chartReactionTimeTimelineData
                                                    }))
                                                    .filter(x => x.data)
                                                }
                                            />
                                            <LineChartTimelineSection
                                                isPrinting={$isPrinting.value}
                                                sectionName={t('testReport.section.dynamicReactionTime')}
                                                units={t('constants.units.seconds')}
                                                yAxisLabel={t('testReport.section.timeline.dynamicReactionTime.yAxis')}
                                                datasets={currentStepReportData
                                                    .map((srd, i) => ({
                                                        label: srd.testName,
                                                        data: srd.chartDynamicReactionTimeTimelineData
                                                    }))
                                                    .filter(x => x.data)
                                                }
                                            />
                                        </>
                                    }
                                    {metricsSettings.trackedSpeed &&
                                        <LineChartTimelineSection
                                            isPrinting={$isPrinting.value}
                                            sectionName={t('testReport.section.speed')}
                                            units={unitPerSecond}
                                            yAxisLabel={t('testReport.section.timeline.speed.yAxis', unitPerSecond)}
                                            datasets={currentStepReportData
                                                .map((srd, i) => ({
                                                    label: srd.testName,
                                                    data: srd.chartSpeedTimelineData
                                                }))
                                                .filter(x => x.data)
                                            }
                                        />
                                    }
                                    {metricsSettings.trackedAcceleration &&
                                        <LineChartTimelineSection
                                            isPrinting={$isPrinting.value}
                                            sectionName={t('testReport.section.acceleration')}
                                            units={unitPerSecondSquared}
                                            yAxisLabel={t('testReport.section.timeline.acceleration.yAxis', unitPerSecondSquared)}
                                            datasets={currentStepReportData
                                                .map((srd, i) => ({
                                                    label: srd.testName,
                                                    data: srd.chartAccelerationTimelineData
                                                }))
                                                .filter(x => x.data)
                                            }
                                        />
                                    }
                                    {metricsSettings.trackedDeceleration &&
                                        <LineChartTimelineSection
                                            isPrinting={$isPrinting.value}
                                            sectionName={t('testReport.section.deceleration')}
                                            units={unitPerSecondSquared}
                                            yAxisLabel={t('testReport.section.timeline.deceleration.yAxis', unitPerSecondSquared)}
                                            datasets={currentStepReportData
                                                .map((srd, i) => ({
                                                    label: srd.testName,
                                                    data: srd.chartDecelerationTimelineData
                                                }))
                                                .filter(x => x.data)
                                            }
                                        />
                                    }
                                    {metricsSettings.trackedHeartRate &&
                                        <LineChartTimelineSection
                                            isPrinting={$isPrinting.value}
                                            sectionName={t('testReport.section.heartRate')}
                                            units={t('constants.units.beatsPerMinute')}
                                            yAxisLabel={t('testReport.section.timeline.heartRate.yAxis')}
                                            datasets={currentStepReportData
                                                .map((srd, i) => ({
                                                    label: srd.testName,
                                                    data: srd.chartHeartRateTimelineData
                                                }))
                                                .filter(x => x.data)
                                            }
                                        />
                                    }
                                </>
                            }

                            {($selectedSection.value === ReportSections.History || $showAllSections.value) &&
                                (($isPrinting.value && $enabledHistory.value) || !$isPrinting.value) &&
                                ($isLoadingHistory.value ?
                                    <div css={styles.loadingContainer}>
                                        <CircularLoader />
                                    </div>
                                    :
                                    <>
                                        <div className="date-picker">
                                            <DateRangeSelector
                                                label={t('userReport.controls.dateRange')}
                                                $value={$dateRangeHistory}
                                                design="slim"
                                                labelProps={{
                                                    className: 'date-range-selector'
                                                }}
                                            />
                                        </div>
                                        {!currentStepHistoryData ?
                                            <span className="no-records">{t('coreComponents.table.noMatchingRecords')}</span>
                                            :
                                            <>
                                                <h2>{t('testReport.section.history')}</h2>
                                                <LineChartHistorySection
                                                    isPrinting={$isPrinting.value}
                                                    sectionName={t('testReport.section.totalTargets')}
                                                    units={t('constants.units.count')}
                                                    yAxisLabel={t('testReport.section.history.totalTargets.yAxis')}
                                                    dataMap={historyDataMaps.totalTargetsHit}
                                                    data={currentStepHistoryData.totalTargetsHit}
                                                />
                                                {metricsSettings.trackedSway &&
                                                    <LineChartHistorySection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.sway')}
                                                        units={unitType}
                                                        yAxisLabel={t('testReport.section.history.sway.yAxis', unitType)}
                                                        dataMap={historyDataMaps.totalSway}
                                                        data={currentStepHistoryData.sway}
                                                    />
                                                }
                                                {metricsSettings.trackedMisses &&
                                                    <LineChartHistorySection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.misses')}
                                                        units={t('constants.units.count')}
                                                        yAxisLabel={t('testReport.section.history.misses.yAxis')}
                                                        dataMap={historyDataMaps.misses}
                                                        data={currentStepHistoryData.misses}
                                                    />
                                                }
                                                {metricsSettings.trackedDistance &&
                                                    <LineChartHistorySection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.totalDistance')}
                                                        units={unitTypeShort}
                                                        yAxisLabel={t('testReport.section.history.totalDistance.yAxis', unitTypeShort)}
                                                        dataMap={historyDataMaps.totalDistance}
                                                        data={currentStepHistoryData.totalDistance}
                                                    />
                                                }
                                                {metricsSettings.trackedReaction &&
                                                    <>
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={t('testReport.section.reactionTime')}
                                                            units={t('constants.units.seconds')}
                                                            yAxisLabel={t('testReport.section.history.reactionTime.yAxis')}
                                                            dataMap={historyDataMaps.reactionTime}
                                                            data={currentStepHistoryData.reactionTime}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={t('testReport.section.dynamicReactionTime')}
                                                            units={t('constants.units.seconds')}
                                                            yAxisLabel={t('testReport.section.history.dynamicReactionTime.yAxis')}
                                                            dataMap={historyDataMaps.dynamicReactionTime}
                                                            data={currentStepHistoryData.dynamicReactionTime}
                                                        />
                                                    </>
                                                }
                                                {metricsSettings.trackedSpeed &&
                                                    <LineChartHistorySection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.speed')}
                                                        units={unitPerSecond}
                                                        yAxisLabel={t('testReport.section.history.speed.yAxis', unitPerSecond)}
                                                        dataMap={historyDataMaps.speed}
                                                        data={currentStepHistoryData.speed}
                                                    />
                                                }
                                                {metricsSettings.trackedAcceleration &&
                                                    <LineChartHistorySection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.acceleration')}
                                                        units={unitPerSecondSquared}
                                                        yAxisLabel={t('testReport.section.history.acceleration.yAxis', unitPerSecondSquared)}
                                                        dataMap={historyDataMaps.acceleration}
                                                        data={currentStepHistoryData.acceleration}
                                                    />
                                                }
                                                {metricsSettings.trackedDeceleration &&
                                                    <LineChartHistorySection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.deceleration')}
                                                        units={unitPerSecondSquared}
                                                        yAxisLabel={t('testReport.section.history.deceleration.yAxis', unitPerSecondSquared)}
                                                        dataMap={historyDataMaps.deceleration}
                                                        data={currentStepHistoryData.deceleration}
                                                    />
                                                }
                                                {metricsSettings.trackedHeartRate &&
                                                    <LineChartHistorySection
                                                        isPrinting={$isPrinting.value}
                                                        sectionName={t('testReport.section.heartRate')}
                                                        units={t('constants.units.beatsPerMinute')}
                                                        yAxisLabel={t('testReport.section.history.heartRate.yAxis')}
                                                        dataMap={historyDataMaps.heartRate}
                                                        data={currentStepHistoryData.heartRate}
                                                    />
                                                }
                                                {metricsSettings.trackedSpine &&
                                                    <>
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.neckRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.neck.yAxis')}
                                                            dataMap={historyDataMaps.initialNeckRotation}
                                                            data={currentStepHistoryData.initialNeckRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.neckRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.neck.yAxis')}
                                                            dataMap={historyDataMaps.neckRotation}
                                                            data={currentStepHistoryData.neckRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.neckFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.neck.yAxis')}
                                                            dataMap={historyDataMaps.initialNeckFlexion}
                                                            data={currentStepHistoryData.initialNeckFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.neckFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.neck.yAxis')}
                                                            dataMap={historyDataMaps.neckFlexion}
                                                            data={currentStepHistoryData.neckFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.spineRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.spine.yAxis')}
                                                            dataMap={historyDataMaps.initialSpineRotation}
                                                            data={currentStepHistoryData.initialSpineRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.spineRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.spine.yAxis')}
                                                            dataMap={historyDataMaps.spineRotation}
                                                            data={currentStepHistoryData.spineRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.spineFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.spine.yAxis')}
                                                            dataMap={historyDataMaps.initialSpineFlexion}
                                                            data={currentStepHistoryData.initialSpineFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.spineFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.spine.yAxis')}
                                                            dataMap={historyDataMaps.spineFlexion}
                                                            data={currentStepHistoryData.spineFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.shoulderRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.shoulderRotation.yAxis')}
                                                            dataMap={historyDataMaps.initialShoulderRotation}
                                                            data={currentStepHistoryData.initialShoulderRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.shoulderRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.shoulderRotation.yAxis')}
                                                            dataMap={historyDataMaps.shoulderRotation}
                                                            data={currentStepHistoryData.shoulderRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.thoracicRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.thoracicRotation.yAxis')}
                                                            dataMap={historyDataMaps.initialThoracicRotation}
                                                            data={currentStepHistoryData.initialThoracicRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.thoracicRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.thoracicRotation.yAxis')}
                                                            dataMap={historyDataMaps.thoracicRotation}
                                                            data={currentStepHistoryData.thoracicRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.lumbarRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.lumbarRotation.yAxis')}
                                                            dataMap={historyDataMaps.initialLumbarRotation}
                                                            data={currentStepHistoryData.initialLumbarRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.lumbarRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.lumbarRotation.yAxis')}
                                                            dataMap={historyDataMaps.lumbarRotation}
                                                            data={currentStepHistoryData.lumbarRotation}
                                                        />
                                                    </>
                                                }
                                                {metricsSettings.trackedArms &&
                                                    <>
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.lrShoulderRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.lrShoulderRotation.yAxis')}
                                                            dataMap={historyDataMaps.initialLRShoulderRotation}
                                                            data={currentStepHistoryData.initialLRShoulderRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.lrShoulderRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.lrShoulderRotation.yAxis')}
                                                            dataMap={historyDataMaps.lrShoulderRotation}
                                                            data={currentStepHistoryData.lrShoulderRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.shoulderFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.shoulderFlexion.yAxis')}
                                                            dataMap={historyDataMaps.initialShoulderFlexion}
                                                            data={currentStepHistoryData.initialShoulderFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.shoulderFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.shoulderFlexion.yAxis')}
                                                            dataMap={historyDataMaps.shoulderFlexion}
                                                            data={currentStepHistoryData.shoulderFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.shoulderAbduction')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.shoulderAbduction.yAxis')}
                                                            dataMap={historyDataMaps.initialShoulderAbduction}
                                                            data={currentStepHistoryData.initialShoulderAbduction}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.shoulderAbduction')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.shoulderAbduction.yAxis')}
                                                            dataMap={historyDataMaps.shoulderAbduction}
                                                            data={currentStepHistoryData.shoulderAbduction}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.shoulderHorizontalAbduction')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.shoulderHorizontalAbduction.yAxis')}
                                                            dataMap={historyDataMaps.initialShoulderHorizontalAbduction}
                                                            data={currentStepHistoryData.initialShoulderHorizontalAbduction}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.shoulderHorizontalAbduction')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.shoulderHorizontalAbduction.yAxis')}
                                                            dataMap={historyDataMaps.shoulderHorizontalAbduction}
                                                            data={currentStepHistoryData.shoulderHorizontalAbduction}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.elbowFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.elbowFlexion.yAxis')}
                                                            dataMap={historyDataMaps.initialElbowFlexion}
                                                            data={currentStepHistoryData.initialElbowFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.elbowFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.elbowFlexion.yAxis')}
                                                            dataMap={historyDataMaps.elbowFlexion}
                                                            data={currentStepHistoryData.elbowFlexion}
                                                        />
                                                    </>
                                                }
                                                {metricsSettings.trackedTorso &&
                                                    <>
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.torso')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.torso.yAxis')}
                                                            dataMap={historyDataMaps.initialTorso}
                                                            data={currentStepHistoryData.initialTorso}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.torso')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.torso.yAxis')}
                                                            dataMap={historyDataMaps.torso}
                                                            data={currentStepHistoryData.torso}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.pelvicRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.pelvicRotation.yAxis')}
                                                            dataMap={historyDataMaps.initialPelvisRotation}
                                                            data={currentStepHistoryData.initialPelvisRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.pelvicRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.pelvicRotation.yAxis')}
                                                            dataMap={historyDataMaps.pelvisRotation}
                                                            data={currentStepHistoryData.pelvisRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.squatDepth')}
                                                            units={unitTypeSmall}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.squatDepth.yAxis', unitTypeSmall)}
                                                            dataMap={historyDataMaps.initialSquatDepth}
                                                            data={currentStepHistoryData.initialSquatDepth}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.squatDepth')}
                                                            units={unitTypeSmall}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.squatDepth.yAxis', unitTypeSmall)}
                                                            dataMap={historyDataMaps.squatDepth}
                                                            data={currentStepHistoryData.squatDepth}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={t('testReport.section.jumpHeight')}
                                                            units={unitTypeSmall}
                                                            yAxisLabel={t('testReport.section.history.jumpHeight.yAxis', unitTypeSmall)}
                                                            dataMap={historyDataMaps.jumpHeight}
                                                            data={currentStepHistoryData.jumpHeight}
                                                        />
                                                    </>
                                                }
                                                {metricsSettings.trackedLegs &&
                                                    <>
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.hipRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.hipRotation.yAxis')}
                                                            dataMap={historyDataMaps.initialHipRotation}
                                                            data={currentStepHistoryData.initialHipRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.hipRotation')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.hipRotation.yAxis')}
                                                            dataMap={historyDataMaps.hipRotation}
                                                            data={currentStepHistoryData.hipRotation}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.kneeFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.kneeFlexion.yAxis')}
                                                            dataMap={historyDataMaps.initialKneeFlexion}
                                                            data={currentStepHistoryData.initialKneeFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.kneeFlexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.kneeFlexion.yAxis')}
                                                            dataMap={historyDataMaps.kneeFlexion}
                                                            data={currentStepHistoryData.kneeFlexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.kneeFlexionValgusOrVarus')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.kneeFlexionValgusOrVarus.yAxis')}
                                                            dataMap={historyDataMaps.initialKneeValgusOrVarus}
                                                            data={currentStepHistoryData.initialKneeValgusOrVarus}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.kneeFlexionValgusOrVarus')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.kneeFlexionValgusOrVarus.yAxis')}
                                                            dataMap={historyDataMaps.kneeValgusOrVarus}
                                                            data={currentStepHistoryData.kneeValgusOrVarus}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={initialPrefix + t('testReport.section.dorsiflexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={initialPrefix + t('testReport.section.history.dorsiflexion.yAxis')}
                                                            dataMap={historyDataMaps.initialDorsiflexion}
                                                            data={currentStepHistoryData.initialDorsiflexion}
                                                        />
                                                        <LineChartHistorySection
                                                            isPrinting={$isPrinting.value}
                                                            sectionName={postPrefix + t('testReport.section.dorsiflexion')}
                                                            units={t('constants.units.degrees')}
                                                            yAxisLabel={postPrefix + t('testReport.section.history.dorsiflexion.yAxis')}
                                                            dataMap={historyDataMaps.dorsiflexion}
                                                            data={currentStepHistoryData.dorsiflexion}
                                                        />
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                )
                            }
                            {addDisclaimer && sas.activityTypeId !== ActivityTypes.Games &&
                                <div className="step-disclaimer">{t('testReport.print.stepDisclaimer')}</div>
                            }
                        </>
                    </React.Fragment>
                );
            })}
        </CustomScrollContainer>
    )
}
