/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect } from "react";
import { Button } from "../../../CoreComponents/Button";
import { Checkbox } from "../../../CoreComponents/Checkbox";
import { Input } from "../../../CoreComponents/Input";
import { Switch } from "../../../CoreComponents/Switch";
import { useTranslation } from "../../../CoreComponents/Translation";
import { ForceValidateContext } from "../../../CoreComponents/Utils";
import { useObjectHook } from "../../../CoreComponents/Utils";
import { useHook } from "../../../CoreComponents/Utils";
import { UnitService } from "../../../Services/UnitService";
import { AdminTypes, SystemModes, UnitTypeTranslationKeysArr, SystemModesTranslationKeysArr, UserLiteTimesTranslationKeysArr } from "../../../Utils/Constants";
import { digitsValidator, maxValueValidator, minValueValidator, requiredValidator } from "../../../Utils/Validators";
import { AlertText } from "../../../CoreComponents/Alert";
import { useSelector } from "react-redux";
import { SingleSelect } from "../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../CoreComponents/SelectListComponents/SelectOption";

const unitSettingsTabStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 1200px) {
            width: 900px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .container {
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;

            & > * {
                margin: 0 0 30px 0;
            }

            & > .columns {
                width: 100%;
                @media (min-width: 1200px) {
                    width: calc(50% - 20px);
                }

                & > * {
                    margin: 0 0 30px 0;
                }
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button {
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }
        & .row {
            display:flex;
        }
        & .search-by {
            margin-left: 20px;
            & > * {
                margin-left: 18px;
                margin-top: 18px;
            }
            & > span {
                margin-left: 0px;
                font: normal normal bold 16px/24px Mustica Pro;
                letter-spacing: 0.14px;
                color: #858585;
            }
        }
    `,
    loadContainer: css`
        width: unset;
        height: 85vh;
        display: flex;
        @media (min-width: 1200px) {
            width: 900px;
        }
    `,
};

const UnitSettingsValidator = {
    adminAutoLogoutTime: [
        requiredValidator,
        digitsValidator,
        minValueValidator(1),
        maxValueValidator(30),
        (value, t, obj) => {
            if (Number(value) < Number(obj.userAutoLogoutTime)) {
                return [t('units.validationErrors.adminAutoLogoutTime')];
            }

            return [];
        },
    ],
    userAutoLogoutTime: [
        requiredValidator,
        digitsValidator,
        minValueValidator(1),
        maxValueValidator(30),
        (value, t, obj) => {
            if (Number(obj.adminAutoLogoutTime) < Number(value)
                || Number(value) < Number(obj.userInactiveTime)
            ) {
                return [t('units.validationErrors.userAutoLogoutTime')];
            }

            return [];
        },
    ],
    userInactiveTime: [
        requiredValidator,
        digitsValidator,
        minValueValidator(1),
        maxValueValidator(30),
        (value, t, obj) => {
            if (Number(obj.userAutoLogoutTime) < Number(value)) {
                return [t('units.validationErrors.userInactiveTime')];
            }

            return [];
        },
    ],
};

export const UnitSettingsTab = ({ $selectedUnit }) => {
    const { t } = useTranslation();
    const $isForceValidating = useHook(true);
    const unitId = $selectedUnit.value.unitId;
    const storeState = useSelector((state) => state.storeState);
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;

    const $unitSettings = useObjectHook(
        {
            adminAutoLogoutTime: 0,
            defaultUnits: 0,
            unitId: 0,
            unitModel: 0,
            userAutoLogoutTime: 0,
            userInactiveTime: 0,
            userLiteTime: 0,
            userSearchFilterBy: ['username'],
            userSearchDisplayFullName: true,
            requireUserPIN: true,
            requireOrientationActivity: true,
        },
        UnitSettingsValidator,
        null,
        $isForceValidating.value
    );

    const userSearchFilterByUsername = $unitSettings.value.userSearchFilterBy.includes('username');
    const userSearchFilterByFirstName = $unitSettings.value.userSearchFilterBy.includes('firstName');
    const userSearchFilterByLastName = $unitSettings.value.userSearchFilterBy.includes('lastName');

    useEffect(() => {
        let userSearchFilterBy = $selectedUnit.value?.adminGlobalSetting.userSearchFilterBy ?
            JSON.parse($selectedUnit.value?.adminGlobalSetting.userSearchFilterBy)
            : ['username'];
        while (userSearchFilterBy && !Array.isArray(userSearchFilterBy)) {
            userSearchFilterBy = JSON.parse(userSearchFilterBy);
        }

        $unitSettings.set({
            ...$selectedUnit.value.adminGlobalSetting,
            userSearchFilterBy,
        });
    }, []);

    const updateUnitSettings = () => {
        $isForceValidating.set(true);
        const unitSettings = {
            ...$unitSettings.value,
            userSearchFilterBy: JSON.stringify($unitSettings.value.userSearchFilterBy)
        };
        UnitService.patchUnitSettings(unitId, unitSettings)
            .then(() => {
                $selectedUnit.set({
                    ...$selectedUnit.value,
                    adminGlobalSetting: unitSettings
                })
            });
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={unitSettingsTabStyles.root}>
                <h2>{t('units.editModal.title')}</h2>
                <div className="container">
                    <div className="columns">
                        <SingleSelect
                            label={t('units.fieldName.defaultUnits')}
                            $value={$unitSettings.getPropHook('defaultUnits')}
                            disabled={isTrazerViewAdmin}
                            disableFiltering
                        >
                            {UnitTypeTranslationKeysArr.map(x => (
                                <SelectOption key={x.value} value={x.value}>
                                    {t(x.translationKey)}
                                </SelectOption>
                            ))}
                        </SingleSelect>
                        <Input
                            placeholder={t('input.placeholder')}
                            required
                            label={t('units.fieldName.adminLogout')}
                            $value={$unitSettings.getPropHook('adminAutoLogoutTime')}
                            disabled={isTrazerViewAdmin}
                        />
                        <Input
                            placeholder={t('input.placeholder')}
                            required
                            label={t('units.fieldName.userLogout')}
                            $value={$unitSettings.getPropHook('userAutoLogoutTime')}
                            disabled={isTrazerViewAdmin}
                        />
                        <Input
                            placeholder={t('input.placeholder')}
                            required
                            label={t('units.fieldName.verifyUser')}
                            $value={$unitSettings.getPropHook('userInactiveTime')}
                            disabled={isTrazerViewAdmin}
                        />
                        {$unitSettings.getPropHook('unitModel').value === SystemModes.UserLite &&
                            <SingleSelect
                                label={t('units.fieldName.userLiteTimeout')}
                                $value={$unitSettings.getPropHook('userLiteTime')}
                                disableFiltering
                            >
                                {UserLiteTimesTranslationKeysArr.map(x => (
                                    <SelectOption key={x.value} value={x.value}>
                                        {t(x.translationKey)}
                                    </SelectOption>
                                ))}
                            </SingleSelect>
                        }
                        {isTrazerAdmin &&
                            <SingleSelect
                                label={t('units.fieldName.systemMode')}
                                $value={$unitSettings.getPropHook('unitModel')}
                                disableFiltering
                            >
                                {SystemModesTranslationKeysArr.map(x => (
                                    <SelectOption key={x.value} value={x.value}>
                                        {t(x.translationKey)}
                                    </SelectOption>
                                ))}
                            </SingleSelect>
                        }
                    </div>
                    <div className="columns">
                        <div className="row">
                            <Switch
                                label={t('units.switch.requireUserPIN')}
                                $value={$unitSettings.getPropHook('requireUserPIN')}
                                disabled={isTrazerViewAdmin}
                            />
                            <Switch
                                label={t('units.switch.requireOrientationActivity')}
                                $value={$unitSettings.getPropHook('requireOrientationActivity')}
                                disabled={isTrazerViewAdmin}
                            />
                        </div>
                        <Switch
                            label={t('units.switch.displayFirstAndLastName')}
                            $value={$unitSettings.getPropHook('userSearchDisplayFullName')}
                            disabled={isTrazerViewAdmin}
                        />
                        <div className="search-by">
                            <span>
                                {t('units.fieldName.searchBy')}
                            </span>
                            <Checkbox
                                checked={userSearchFilterByUsername}
                                label={t('units.checkbox.searchByUsername')}
                                disabled
                            />
                            <Checkbox checked={userSearchFilterByFirstName}
                                label={t('units.checkbox.searchByFirstName')}
                                onChange={(isChecked) => {
                                    $unitSettings.set({
                                        ...$unitSettings.value,
                                        userSearchFilterBy: isChecked ?
                                            ['firstName'].concat($unitSettings.value.userSearchFilterBy)
                                            : $unitSettings.value.userSearchFilterBy.filter(x => x !== 'firstName')
                                    })
                                }}
                                disabled={isTrazerViewAdmin}
                            />
                            <Checkbox checked={userSearchFilterByLastName}
                                label={t('units.checkbox.searchByLastName')}
                                onChange={(isChecked) => {
                                    $unitSettings.set({
                                        ...$unitSettings.value,
                                        userSearchFilterBy: isChecked ?
                                            ['lastName'].concat($unitSettings.value.userSearchFilterBy)
                                            : $unitSettings.value.userSearchFilterBy.filter(x => x !== 'lastName')
                                    })
                                }}
                                disabled={isTrazerViewAdmin}
                            />
                        </div>
                    </div>
                </div>
                <div className="actions">
                    {$unitSettings.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                    <Button disabled={!$unitSettings.isValid || isTrazerViewAdmin} onClick={updateUnitSettings}>{t('actions.update')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
