/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHook, useOnResize, usePrintEffect } from '../../CoreComponents/Utils';
import { NoData } from '../../Utils/Constants';

const reportTableStyles = {
    table: css`
        border-collapse: separate;
        border-spacing: 0 6px;
        border: 0;
        width: 100%;

        // margin-bottom: 40px;

        & tr:hover > td {
            background: #EBF5F4 0% 0% no-repeat padding-box;
        };

        & thead tr th {
            font: normal normal bold 16px/24px Mustica Pro;
            letter-spacing: 0.14px;
            color: #3A817F;

            &:nth-of-type(2) {
                padding-left: 40px;
            };

            &:nth-of-type(n+3) {
                padding-left: 20px;
            };

            &:last-of-type {
                padding-right: 40px;
            };

            &:only-of-type {
                padding: 0 40px;
            }
        }

        & tbody > tr {
            position: relative;

            & .shadow-box {
                box-shadow: 0px 8px 16px #28326529;
                border-radius: 8px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0px;
            }

            & > th {
                font: normal normal bold 16px/24px Mustica Pro;
                letter-spacing: 0.14px;
                color: #858585;
                padding-right: 20px;
            }

            & > td {
                font: normal normal normal 16px/24px Roboto;
                color: #858585;
                background: #FFFFFF 0% 0% no-repeat padding-box;

                border-radius: 0;
                height: 40px;

                // @media print {
                //     height: auto
                // }

                // max-width: 230px;
                word-wrap: break-word;

                &:first-of-type {
                    border-radius: 8px 0 0 8px;
                    padding-left: 40px;
                };

                &:nth-of-type(n+2) {
                    padding-left: 20px;
                };

                // &:nth-of-type(2) {
                //     border-radius: 8px 0 0 8px;
                //     background: #FFFFFF 0% 0% no-repeat padding-box;
                //     padding-left: 40px;
                // };

                // &:nth-of-type(n+3) {
                //     border-radius: 0;
                //     background: #FFFFFF 0% 0% no-repeat padding-box;
                //     // padding-left: 40px;
                // };

                &:last-of-type {
                    border-radius: 0 8px 8px 0;
                    padding-right: 40px;
                };

                &:only-of-type {
                    border-radius: 8px;
                    padding: 0 40px;
                }
            };
        }
    `,
    grayColorMode: css`
        & tbody > tr {
            & .shadow-box {
                box-shadow: none;
            }

            & > td {
                color: #676767;
                background: rgba(0, 0, 0, 0.08) 0% 0% no-repeat padding-box;
            }
        }
    `,
    disableRowHeader: css`
        & thead tr th {
            &:first-of-type {
                padding-left: 40px;
            };

            &:nth-of-type(n+2) {
                padding-left: 20px;
            };
        }

        & tbody > tr {
            box-shadow: 0px 8px 16px #28326529;
            border-radius: 8px;
        }
    `,
    disableRowShadow: css`
        & tbody > tr {
            box-shadow: none;
        }
    `,
};

export const ReportTable = ({ headers, data, rowNameHeader, color = 'default', showEmptyRows = false, ...props }) => {
    const $forceRefresh = useHook(null);
    const thRef = useRef(null);
    const tbodyRef = useRef(null);
    const filteredData = data.filter(x => x);
    const headerKeys = Object
        .keys(headers || filteredData[0] || {})
        .filter(x => x !== 'rowName');
    headers = headers || headerKeys.reduce((a, c) => ({ ...a, [c]: c }), {});

    // TODO: check if we change first column to have a fixed width
    // will work so we can avoid tracking of so many events
    const shadowStyle = useMemo(() => ({
        width: `calc(100% - ${thRef.current?.clientWidth || 120}px)`
    }), [thRef.current?.clientWidth]);
    useOnResize(() => {
        if (props.disableRowShadow
            || props.disableRowHeader
        ) {
            return;
        }

        $forceRefresh.set({});
    }, thRef);

    usePrintEffect(
        () => {
            const allShadowBoxes = tbodyRef.current.querySelectorAll('.shadow-box');
            for (let shadowBox of allShadowBoxes) {
                shadowBox.style.width = `calc(100% - ${thRef.current?.clientWidth || 120}px)`;
            }
        },
        () => {
            const allShadowBoxes = tbodyRef.current.querySelectorAll('.shadow-box');
            for (let shadowBox of allShadowBoxes) {
                shadowBox.style.width = `calc(100% - ${thRef.current?.clientWidth || 120}px)`;
            }
        },
        () => {
            return !props.disableRowShadow
                && !props.disableRowHeader
                && tbodyRef.current
                && thRef.current;
        },
        [thRef.current, tbodyRef.current]
    );

    return filteredData.length && (
        <table css={[
            reportTableStyles.table,
            color === 'gray' && reportTableStyles.grayColorMode,
            props.disableRowHeader && reportTableStyles.disableRowHeader,
            props.disableRowShadow && reportTableStyles.disableRowShadow,
        ]}>
            <thead>
                <tr>
                    {!props.disableRowHeader &&
                        <th ref={thRef}>
                            <span className="visually-hidden">{rowNameHeader || '--'}</span>
                        </th>
                    }
                    {headerKeys.map((key, keyIndex) => filteredData.some(x => x && x[key] != null && x[key] !== NoData) && (
                        <th key={`${key}_${keyIndex}`} scope="col">{headers[key]}</th>
                    ))}
                </tr>
            </thead>
            <tbody ref={tbodyRef}>
                {filteredData.map((row, rowIndex) => {
                    if (!row || (!showEmptyRows && !Object.keys(row).some(x => row && x !== 'rowName' && row[x] != null && row[x] !== NoData))) {
                        return null;
                    }

                    return (
                        <tr key={`${row?.rowName}_${rowIndex}`}>
                            {!props.disableRowHeader &&
                                <th scope="row">
                                    {row?.rowName}
                                    {!props.disableRowShadow &&
                                        <div className="shadow-box disable-on-safari" style={shadowStyle}></div>
                                    }
                                </th>
                            }
                            {headerKeys.map((key, keyIndex) => filteredData.some(x => x && x[key] != null && x[key] !== NoData) && (
                                <td key={`${key}_${keyIndex}`}>
                                    {row[key] ?? NoData}
                                </td>
                            ))}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
};

ReportTable.propTypes = {
    headers: PropTypes.object,
    data: PropTypes.array,
    rowNameHeader: PropTypes.string,

    disableRowShadow: PropTypes.bool,
    disableRowHeader: PropTypes.bool,

    /** @type {'default'|'gray'} */
    color: PropTypes.oneOf(['default', 'gray']),
};
