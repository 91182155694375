/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Menu, MenuItem, Typography } from "@mui/material";
import { subYears } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { Button, IconButton } from "../../../CoreComponents/Button";
import { EllipsisVerticalIcon } from "../../../CoreComponents/CustomIcons";
import { DateRangeSelector } from "../../../CoreComponents/DateRangeSelector";
import { CircularLoader } from "../../../CoreComponents/Loaders";
import { Modal } from "../../../CoreComponents/Modal";
import { SingleSelect } from "../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../CoreComponents/SelectListComponents/SelectOption";
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { UserService } from "../../../Services/UserService";
import { durationInHours, metersToFeet, parseDate } from "../../../Utils/Common";
import { NoData, UnitType, UnitTypeTranslationKeysArr } from "../../../Utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setHideMainContent, setStoreState } from "../../../globalStates/storeState";

const overviewWidgetStyle = {
    root: css`
        height: 100%;
        width: 100%;
        position: relative;

        & > .info {
            margin: 0;
            & > h2 {
                margin: 0;
            }
        }
        & > .user-info {
            display: flex;
            flex-direction: column;
            height: 100%;

            & .options {
                position: absolute;
                right: 0;
                top: 0;
            }

            & dl {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                overflow: visible;

                & dt {
                    float: left;
                    clear: left;
                    width: calc(50% - 20px);

                    text-align: right;
                    font: normal normal normal 16px/24px Roboto;
                    letter-spacing: 0.14px;
                    color: #858585;
                }
                & dd {
                    margin: 0 0 0 40px;
                    padding: 0 0 11px 0;
                    width: calc(50% - 20px);

                    text-align: left;
                    font: normal normal bold 16px/24px Mustica Pro;
                    letter-spacing: 0.14px;
                    color: #676767;
                }
        }
    `,
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
    controls: css`
        display: flex;
        align-items: flex-start;
        height: 100%;
        justify-content: space-evenly;
        flex-wrap: wrap;
        flex-direction: column;
        margin-left: 40px;

        & .selector-wrapper {
            min-width: 280px;
        }
    }
    `,
    optionsButton: css`
        height: 1.5em;
        width: 1.5em;
    `,
    fullScreenContainer: css`
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 160px 40px 160px 80px;
        width: 100%;
        height: 100%;
        background: #EEEEEE 0% 0% no-repeat padding-box;

        @media print {
            padding: 80px 0px 80px 40px;
        }
    `,
    modalCard: css`
        background-color: #ffffff;
        border-radius: 8px;
        padding: 20px;
        height: 350px;
        // min-height: 300px;
        width: 100%;
        // min-width: 1120px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    `
}

const DemoData = {
    name: 'user',
    data: {
        name: 'TRAZER User',
        totalCalories: 3.9834,
        totalDistance: 14.3716,
        totalTargets: 5,
        totalTime: 403
    }
}

export const UserOverviewWidget = ({ demoMode, $settings }) => {
    const storeState = useSelector((state) => state.storeState);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const { users, isEditMode } = useSelector((state) => state.dashboardState);
    const $isLoading = useHook(!demoMode && ($settings.value?.userId || !users.length));
    const $showSettings = useHook(!demoMode && !$settings.value);
    const $dateRange = useObjectHook({
        dateInterval: 'week',
        startDate: !demoMode && $settings.value?.startDate ?
            parseDate($settings.value.startDate, 'yyyy-MM-dd')
            : subYears(new Date(), 2),
        endDate: !demoMode && $settings.value?.endDate ?
            parseDate($settings.value?.endDate, 'yyyy-MM-dd')
            : new Date(),
    });
    const $oldSettings = useHook(null);
    const $reportData = useHook([]);
    const $rawData = useHook(null);
    const $selectedUser = useHook((!demoMode && $settings?.value?.userId) || null);
    const $selectedUnitType = useHook($settings?.value?.unitType || UnitType.Feet);

    const $isFullscreen = useHook(false);
    const $isPrinting = useHook(false);
    const [exportSelectionAnchor, setExportSelectionAnchor] = useState(null);

    const handleOpenExportMenu = (e) => setExportSelectionAnchor(e.currentTarget);
    const handleCloseExportMenu = () => setExportSelectionAnchor(null);

    const title = t('userReport.section.userInformation');

    const printHandler = () => {
        handleCloseExportMenu();
        $isFullscreen.set(true);
        $isPrinting.set(true);
        dispatch(setHideMainContent(true))

        setTimeout(() => {
            try {
                window.print();
                dispatch(setStoreState(storeState));
                $isFullscreen.set($isFullscreen.value);
            } catch (ex) {
                console.error(ex)
            } finally {
                $isPrinting.set(false);
            }
        }, 500);
    }

    const additionalSettings = useMemo(() => [
        {
            key: 'configure',
            label: t('widgets.actions.configure'),
            handleClick: () => {
                $showSettings.set(true);
                handleCloseExportMenu();
                $oldSettings.set($settings.value);
            }
        }
    ], [t])

    const saveConfiguration = () => {
        if (!$selectedUser.value && $isLoading.value) {
            return;
        }
        $showSettings.set(false)
        const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
        const endDate = $dateRange.value.endDate.toISOString().split('T')[0];

        $settings.set({
            version: 1,
            settings:
            {
                startDate,
                endDate,
                userId: $selectedUser.value
            }
        });
    }

    useEffect(() => {
        if (demoMode) {
            $rawData.set(DemoData.data)
            return;
        }
        if (!$selectedUser.value && users.length) {
            $isLoading.set(false)
            $showSettings.set(true);
            return;
        }
        if (!$settings.value || !users.length || $oldSettings.value?.settings === $settings.value) {
            return;
        }
        const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
        const endDate = $dateRange.value.endDate.toISOString().split('T')[0];
        const user = users.find(x => x.userId === $selectedUser.value) || {}
        $isLoading.set(true);

        UserService.overview(
            $selectedUser.value,
            startDate,
            endDate
        ).then((overview) => {
            $rawData.set({
                name: user?.firstName + ' ' + user?.lastName || '',
                totalTime: overview.totalTime || 0,
                totalCalories: overview.totalCalories || 0,
                totalDistance: overview.totalDistance || 0,
                totalTargets: overview.totalTargets || 0,
            })
        })
            .finally(() => $isLoading.set(false))
            .catch(() => $showSettings.set(true))
    }, [$settings?.value, users.length])

    useEffect(() => {
        if (!$rawData.value) {
            return;
        }

        const convertUnits = (metersValue, fractionDigits = 2) =>
            ($selectedUnitType.value === UnitType.Meters ?
                metersValue
                : metersToFeet(metersValue)
            ).toFixed(fractionDigits)

        $reportData.set({
            ...$rawData.value,
            name: $rawData.value.name || NoData,
            totalTime: durationInHours($rawData.value.totalTime),
            totalDistance: convertUnits($rawData.value.totalDistance),
            totalCalories: $rawData.value.totalCalories.toFixed(2),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, $rawData.value]);

    const Content = ($isLoading.value ?
        <div css={overviewWidgetStyle.loadingContainer}>
            <CircularLoader />
        </div>
        :
        $showSettings.value && !$isLoading.value ?
            <div css={overviewWidgetStyle.controls}>
                <SingleSelect
                    variant="outlined"
                    label={t('widgets.fieldName.user')}
                    design="slim"
                    $value={$selectedUser}
                >
                    {users.map(x => (
                        <SelectOption key={x.userId} value={x.userId}>
                            {`${x.firstName} ${x.lastName} (${x.username})`}
                        </SelectOption>
                    ))}
                </SingleSelect>
                <DateRangeSelector
                    label={t('userReport.controls.dateRange')}
                    $value={$dateRange}
                    design="slim"
                    variant="outlined"
                    labelProps={{
                        className: 'date-range-selector'
                    }}
                />
                <SingleSelect
                    label={t('userReport.controls.unitType')}
                    variant="outlined"
                    design="slim"
                    $value={$selectedUnitType}
                    disableFiltering
                >
                    {UnitTypeTranslationKeysArr.map(x => (
                        <SelectOption key={x.value} value={x.value}>
                            {t(x.translationKey)}
                        </SelectOption>
                    ))}
                </SingleSelect>
                <Button onClick={() => saveConfiguration()}>{t('actions.save')}</Button>
            </div>
            :
            $reportData.value &&
            <div css={overviewWidgetStyle.root}>
                <div className="info">
                    <h2>{title}</h2>
                </div>
                <div className="user-info">
                    {!demoMode && !isEditMode &&
                        <div className="options">
                            {!$isPrinting.value &&
                                <IconButton css={overviewWidgetStyle.optionsButton} onClick={handleOpenExportMenu}>
                                    <EllipsisVerticalIcon />
                                </IconButton>
                            }
                            <Menu
                                sx={{ mt: '32px' }}
                                // id="chart-options"
                                anchorEl={exportSelectionAnchor}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(exportSelectionAnchor)}
                                onClose={handleCloseExportMenu}
                            >
                                {additionalSettings.map(x =>
                                    <MenuItem
                                        key={x.key}
                                        onClick={x.handleClick}
                                    >
                                        <Typography textAlign="left">
                                            {x.label}
                                        </Typography>
                                    </MenuItem>
                                )}
                                <MenuItem
                                    onClick={() => {
                                        $isFullscreen.set(!$isFullscreen.value);
                                        handleCloseExportMenu();
                                    }}
                                >
                                    <Typography textAlign="left">
                                        {$isFullscreen.value ?
                                            t('chart.options.exitFullScreen')
                                            : t('chart.options.viewInFullScreen')
                                        }
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={printHandler}
                                >
                                    <Typography textAlign="left">{t('chart.options.print')}</Typography>
                                </MenuItem>
                            </Menu>
                        </div>
                    }
                    <dl>
                        <dt>{t('userReport.userInfo.name')}</dt>
                        <dd>{$reportData.value.name}</dd>
                        <dt>{t('userReport.userInfo.totalTime')}</dt>
                        <dd>{$reportData.value.totalTime}</dd>
                        <dt>{t('userReport.userInfo.totalDistance')}</dt>
                        <dd>{$reportData.value.totalDistance}</dd>
                        <dt>{t('userReport.userInfo.totalCalories')}</dt>
                        <dd>{$reportData.value.totalCalories}</dd>
                        <dt>{t('userReport.userInfo.totalTargets')}</dt>
                        <dd>{$reportData.value.totalTargets}</dd>
                    </dl>
                </div>
            </div>
    )
    return ($isFullscreen.value ?
        <Modal
            fullScreen
            open={$isFullscreen.value}
            onClose={() => $isFullscreen.set(false)}
        // TransitionComponent={Transition}
        >
            <div css={overviewWidgetStyle.fullScreenContainer}>
                <div css={overviewWidgetStyle.modalCard}>
                    {Content}
                </div>
            </div>
        </Modal>
        : Content
    )
}
