// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const styles = css`
    // border: 1px solid red;

    & .MuiDialog-paper {
        padding: 0;
        max-width: unset;
        background-color: #EEEEEE;
        border-radius: 8px;
        padding-right: 40px;
        // max-height: unset;
    }
    & .MuiBackdrop-root {
        @media print:  {
            background-color: unset;
        }
    }

    &.fixed-modal-position .MuiDialog-paper {
        position: absolute;
        left: 8%;
        max-width: 90%;
        margin-right: 10px;
    }
`

const BootstrapDialog = forwardRef(({ fixedModalPosition, className, ...props }, ref) =>
    <Dialog ref={ref} {...props} className={className + (fixedModalPosition ? ' fixed-modal-position' : '')} css={styles} />
);

export const Modal = ({ children, disableCloseButton, ...props }) =>
    <BootstrapDialog {...props}>
        {props.onClose && !disableCloseButton ? (
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    // by design is 40px but there's 8px padding
                    right: 32,
                    top: 32,
                    zIndex: 1,
                    color: '#00ABA5',
                    '@media print':  {
                        display: 'none',
                    },
                }}
            >
                <CloseIcon />
            </IconButton>
        ) : null}
        {children}
    </BootstrapDialog>
