/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    emailValidator,
    exactLengthValidator,
    maxLengthValidator,
    phoneValidator,
    requiredValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { SiteService } from '../../../Services/SiteService';
import { useEffect } from 'react';
import { AddressService } from '../../../Services/AddressService';
import { AlertText } from '../../../CoreComponents/Alert';
import { AdminTypes } from '../../../Utils/Constants';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const sitesTabStyles = {
    root: css`
        height: 85vh;
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 1200px) {
            width: 900px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 30px 0;
        }

        & > .fields-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow-y: auto;
            max-height: calc(100% - 110px - 1.5em);
            gap: 30px 40px;

            & > .input-root {
                margin: 0;
                width: 100%;
                box-sizing: border-box;

                @media (min-width: 1200px) {
                    width: calc(50% - 20px);
                }
            }

            @media (min-resolution: 97dpi)),
            screen and (max-height: 868px) {
                padding-right: 20px;
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;

            & > button {
                min-width: 136px;
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }
    `,
};

const SiteValidator = {
    name: [requiredValidator, maxLengthValidator(250)],
    contactName: [maxLengthValidator(150)],
    phone: [phoneValidator],
    email: [maxLengthValidator(150), emailValidator],
    address: [maxLengthValidator(150)],
    address2: [maxLengthValidator(150)],
    city: [maxLengthValidator(150)],
    // if it's changed back to text input
    // state: [maxLengthValidator(150)],
    zipCode: [maxLengthValidator(150)],
    // if it's changed back to text input
    // country: [maxLengthValidator(150)],
    pin: [exactLengthValidator(8)],
};

export const SiteTab = ({ $selectedSite }) => {
    const { t, currentLanguage } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin
    const $isForceValidating = useHook(true);
    const $countries = useHook([]);
    const $states = useHook([]);
    const $siteCopy = useObjectHook(
        $selectedSite.value,
        $selectedSite.value.admin ?
            { ...SiteValidator, email: [requiredValidator, ...SiteValidator.email] }
            : SiteValidator,
        null,
        $isForceValidating.value
    );

    useEffect(() => {
        const currentLanguageLowerCase = currentLanguage.toLowerCase();
        AddressService.getCountries(currentLanguageLowerCase)
            .then($countries.set);
        AddressService.getStates(currentLanguageLowerCase)
            .then($states.set);
    }, [currentLanguage]);

    const updateSite = () => {
        $isForceValidating.set(true);
        const errors = $siteCopy.validate();
        if (errors) {
            return;
        }

        SiteService.putSite($siteCopy.value)
            .then(() => $selectedSite.set($siteCopy.value));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={sitesTabStyles.root}>
                <h2>{t('sites.editModal.title')}</h2>
                <div className="fields-container">
                    <Input placeholder={t('input.placeholder')} required label={t('sites.fieldName.name')} $value={$siteCopy.getPropHook('name')} />
                    {isTrazerAdmin &&
                        <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.pin')} $value={$siteCopy.getPropHook('pin')} />
                    }
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.contactName')} $value={$siteCopy.getPropHook('contactName')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.phone')} $value={$siteCopy.getPropHook('phone')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.email')} $value={$siteCopy.getPropHook('email')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.address')} $value={$siteCopy.getPropHook('address')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.addressLine2')} $value={$siteCopy.getPropHook('address2')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.city')} $value={$siteCopy.getPropHook('city')} />
                    <SingleSelect
                        placeholder={t('input.placeholder')}
                        label={t('sites.fieldName.country')}
                        $value={$siteCopy.getPropHook('country')}
                        onChange={country => {
                            $siteCopy.set({
                                ...$siteCopy.value,
                                country,
                                state: ''
                            });
                        }}
                    >
                        {$countries.value.map(x => <SelectOption key={x.code} value={x.code}>{x.name}</SelectOption>)}
                    </SingleSelect>
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.zipCode')} $value={$siteCopy.getPropHook('zipCode')} />
                    {$siteCopy.value.country !== 'usa' ?
                        <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.state')} $value={$siteCopy.getPropHook('state')} />
                        :
                        <SingleSelect placeholder={t('input.placeholder')} label={t('sites.fieldName.state')} $value={$siteCopy.getPropHook('state')}>
                            {$states.value.map(x => <SelectOption key={x.abbreviation} value={x.abbreviation}>{x.name}</SelectOption>)}
                        </SingleSelect>
                    }
                </div>
                <div className="actions">
                    {$siteCopy.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                    <Button disabled={!$siteCopy.isValid} onClick={updateSite}>{t('actions.save')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
