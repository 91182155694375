// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { TrazerLogo } from "../../../CoreComponents/CustomIcons";

const printWrapperStyles = {
    root: css`
        & .header-space,
        & .footer-space {
            height: 0;
        }

        & #print-header {
            display: none;
            position: fixed;
            top: 40px;
            padding: 0 40px;
        }

        @media print {
            & .header-space,
            & .footer-space {
                height: 70px;
            }

            & #print-header {
                display: table-header-group;
            }
        }
    `,
};

export const PrintWrapper = ({ children, isPrinting, isFirefox }) => {
    return (!isPrinting || !isFirefox ?
        children
        :
        <table css={printWrapperStyles.root}>
            <thead>
                <tr>
                    <td>
                        <div className="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div id="print-header">
                            <TrazerLogo sx={{ height: '20px', width: 'auto', margin: 'auto' }} />
                        </div>
                        {children}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}
