/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { Button } from '../CoreComponents/Button';
import { TabLayout } from '../CoreComponents/Layout';
import { Modal } from '../CoreComponents/Modal';
import { useTranslation } from '../CoreComponents/Translation';
import { useHook } from '../CoreComponents/Utils';
import { ChangePasswordContent } from './Manage/Users/ChangePasswordContent';
import { InjuryHistoryTab } from './Manage/Users/InjuryHistoryTab';
import { ProfileInformationTab } from './Manage/Users/ProfileInformationTab';
import { UserSportsTab } from './Manage/Users/UserSportsTab';
import { UserService } from '../Services/UserService';
import { CircularLoader } from '../CoreComponents/Loaders';
import { deepCopy } from '../Utils/Common';
import { useSelector } from 'react-redux';

const usersStyles = {
    sidePanelActions: css`
        & > button {
            width: 100%;
            margin-top: 8px;
        }
    `,
    loadContainer: css`
        display: flex;
        height: 85vh;
        width: 70vw;
        @media (min-width: 1200px) {
            width: 900px;
        }
    `,
}

export const Profile = ({ $isProfileOpened }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const $currentUser = useHook({
        ...deepCopy(storeState.currentUser),
        dateOfBirth: new Date(storeState.currentUser.dateOfBirth)
    });
    const $initialUser = useHook(null);
    const $isLoading = useHook(true);
    const $isChangePassword = useHook(false);
    const $tabs = useHook([]);

    useEffect(() => {
        if (!$isProfileOpened.value) {
            return;
        }

        $isLoading.set(true);
        UserService.getMe()
            .then(x => {
                x.dateOfBirth = new Date(x.dateOfBirth);
                $currentUser.set(x);
            })
            .finally(() => $isLoading.set(false));
    }, [$isProfileOpened.value])

    useEffect(() => {
        if (!$currentUser.value) {
            return;
        }

        $tabs.set([
            { name: t('users.editModal.profileInformationTabName'), ContentComponent: () => <ProfileInformationTab $selectedUser={$currentUser} isProfileOpened={true} />, isDefault: true },
            { name: t('users.editModal.sportsTabName'), ContentComponent: () => <UserSportsTab $selectedUser={$currentUser} isProfileOpened={true} /> },
            { name: t('users.editModal.injuryHistoryTabName'), ContentComponent: () => <InjuryHistoryTab $selectedUser={$currentUser} isProfileOpened={true} /> },
        ]);
    }, [t, $currentUser.value]);

    useEffect(() => {
        if (!$isProfileOpened.value) {
            return;
        }

        if ($initialUser.value && $currentUser.value && $initialUser.value.userId !== $currentUser.value.userId) {
            $currentUser.set(null);
            $initialUser.set(null);
            $isProfileOpened.set(false);
            return;
        }

        $initialUser.set($currentUser.value);
    }, [$currentUser.value]);

    const tabActions = () =>
        <div css={usersStyles.sidePanelActions}>
            <Button onClick={() => $isChangePassword.set(true)}>{t('actions.changePassword')}</Button>
        </div>

    return ($isLoading.value ?
        <Modal
            open={$isProfileOpened.value}
            onClose={() => $isProfileOpened.set(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div css={usersStyles.loadContainer}>
                <CircularLoader />
            </div>
        </Modal>
        :
        <>
            <Modal
                open={$isProfileOpened.value}
                onClose={() => $isProfileOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <TabLayout
                    items={$tabs.value}
                    sidePanelProps={{ Actions: tabActions }}
                />
            </Modal>
            <Modal
                open={$isChangePassword.value}
                onClose={() => $isChangePassword.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ChangePasswordContent
                    $selectedUser={$currentUser}
                    onCancel={() => $isChangePassword.set(false)}
                    onSuccess={() => $isChangePassword.set(false)}
                />
            </Modal>
        </>
    );
};
