// @ts-nocheck

import React, { useEffect } from 'react';
import { useState } from 'react';
import DefaultTranslations from '../Assets/en';
import DETranslations from '../Assets/de';
import ESTranslations from '../Assets/es';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../globalStates/storeState';

// https://reactjs.org/docs/context.html#reactcreatecontext
export const TranslateContext = React.createContext({ t: key => key, tWithHtml: key => key, currentLanguage: 'EN', changeLanguage: () => {} });

/**
 * @typedef {<K extends keyof DefaultTranslations>(key: K, ...args) => string} TranslationFunction
 * @returns {{ t: TranslationFunction, tWithHtml: TranslationFunction, currentLanguage: string, changeLanguage: (newLanguage: string) => any }}
 */
export const useTranslation = () => React.useContext(TranslateContext);

export const TranslationProvider = ({ children }) => {
    // @ts-ignore TODO: fix this when we have time
    const storeState = useSelector((state) => state.storeState);
    const dispatch = useDispatch();

    const [translations, setTranslations] = useState(DefaultTranslations);
    const [translationUtils, setTranslationUtils] = useState({
        t: key => key,
        tWithHtml: key => key,
        currentLanguage: storeState.language,
        changeLanguage: newLanguage => dispatch(setLanguage(newLanguage)),
    });

    useEffect(() => {
        // TODO: rewrite this when translations are provided from the backend
        const translateFunction = (key, ...args) => {
            const v = translations[key] || `[${storeState.language}, ${key}, ${args}]`; // || key
            if (!args || !args.length) {
                return v
            }

            return args.reduce(
                (accumulator, currentValue, currentIndex) =>
                    accumulator.replaceAll(`{${currentIndex}}`, currentValue)
                , v);
        };

        // NOTE: See the warning in tWithHtml
        const SafeEscapeHtml = ({ htmlString, tag }) => {
            const leftIndex = htmlString.indexOf(`<${tag}>`);
            const rightIndex = htmlString.lastIndexOf(`</${tag}>`);
            if (leftIndex <= 0 || rightIndex <= 0) {
                return htmlString;
            }

            const leftString = htmlString.substring(0, leftIndex);
            const rightString = htmlString.substring(rightIndex + tag.length + 3);
            const middleString = htmlString.substring(leftIndex + tag.length + 2, rightIndex);

            return (
                <>
                    {leftString}
                    {middleString && React.createElement(
                        tag,
                        null,
                        <SafeEscapeHtml htmlString={middleString} tag={tag} />
                    )}
                    {rightString}
                </>
            )
        }

        const cachedHtmlTranslations = {};
        setTranslationUtils({
            t: translateFunction,
            tWithHtml: (tagToEscape, key, ...args) => {
                if (cachedHtmlTranslations[key]) {
                    return cachedHtmlTranslations[key];
                }

                const htmlString = translateFunction(key, ...args);
                cachedHtmlTranslations[key] = <SafeEscapeHtml htmlString={htmlString} tag={tagToEscape} />;
                return cachedHtmlTranslations[key];

                // WARN: If we have to insert plain HTML we can with this.
                // However it's not recommended because it's easy to inadvertently expose users to a cross-site scripting (XSS) attack.
                // https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
                // return <span dangerouslySetInnerHTML={{ __html: htmlString }}/>;
            },
            currentLanguage: storeState.language,
            changeLanguage: newLanguage => {
                switch (newLanguage){
                    case 'EN':
                        setTranslations(DefaultTranslations);
                        break;
                    case 'DE':
                        setTranslations(DETranslations);
                        break;
                    case 'ES':
                        setTranslations(ESTranslations);
                        break;
                    default:
                        setTranslations({});
                    break;
                }

                dispatch(setLanguage(newLanguage));
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeState.language, DefaultTranslations, storeState.currentUser]);

    return (
        <TranslateContext.Provider value={translationUtils}>
            {children}
        </TranslateContext.Provider>
    );
};
