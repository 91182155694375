import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0Settings } from './Utils/Constants';
import { createTheme, ThemeProvider } from '@mui/material';
import { ThemeColors } from './Utils/ThemeColors';
import store from './app/store'
import { Provider } from 'react-redux'

const theme = createTheme(ThemeColors);

const onRedirectCallback = (appState) => {
    if (appState?.callback) {
        localStorage.setItem('updateUser', true)
    }
    window.location.replace(appState?.returnTo || window.location.pathname);
};

// Use React.memo to memoize the App component
const MemoizedApp = React.memo(App);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <Auth0Provider
                domain={Auth0Settings.Domain}
                clientId={Auth0Settings.ClientId}
                redirectUri={window.location.origin}
                audience={Auth0Settings.ApiAudience}
                scope={Auth0Settings.Scope}
                onRedirectCallback={onRedirectCallback}
                cacheLocation="localstorage"
            >
                <ThemeProvider theme={theme}>
                    <MemoizedApp />
                </ThemeProvider>
            </Auth0Provider>
        </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
