/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button } from "../../../CoreComponents/Button";
import { Input } from "../../../CoreComponents/Input"
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook } from "../../../CoreComponents/Utils";
import { combineValidators, maxLengthValidator, requiredValidator } from "../../../Utils/Validators";
import { GroupService } from "../../../Services/GroupService";

const editGroupNameStyle = css`
    min-height: 340px;
    min-width: 480px;
    padding: 40px 0 40px 40px;
    display: flex;
    flex-direction: column;

    & > h2 {
        margin: 0 auto 40px 0;
    }

    & .new-name {
        margin-left: 0;
        margin-right: 0;
    }

    & > .actions {
        margin: auto 0 0 auto;

        & > button:first-of-type {
            margin-right: 20px;
        }
    }
`;

export const EditGroupNameContent = ({ $selectedGroup, onCancel, onEdit }) => {
    const { t } = useTranslation();
    const $newName = useHook($selectedGroup.value?.groupName, combineValidators([requiredValidator, maxLengthValidator(50)]));
    const $isSaving = useHook(false);

    const updateGroupName = () => {
        $isSaving.set(true);
        const updatedGroup = {
            ...$selectedGroup.value,
            groupName: $newName.value
        };
        GroupService.updateGroup(updatedGroup)
            .then(() => {
                $selectedGroup.set(updatedGroup);
                onEdit && onEdit(updatedGroup);
            })
            .finally(() => $isSaving.set(false))
    }

    return (
        <div css={editGroupNameStyle}>
            <h2>{t('userGroups.editModal.title')}</h2>
            <Input $value={$newName} className="new-name" label={t('userGroups.fieldName.newName')}></Input>
            <div className="actions">
                <Button variant="outlined" onClick={onCancel}>{t('actions.cancel')}</Button>
                <Button disabled={$isSaving.value || $newName.value === $selectedGroup.value?.groupName} onClick={updateGroupName}>{t('actions.change')}</Button>
            </div>
        </div>
    )
}
