/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { IconButton } from './Button';
import { Checkbox } from './Checkbox';
import { ChevronLeftIcon, ChevronRightIcon } from './CustomIcons';
import { RadioGroup, RadioOption } from './RadioSelection';
import { SingleSelect } from './SelectListComponents/SelectList';
import { SelectOption } from './SelectListComponents/SelectOption';
import { Switch } from './Switch';

const reportTableStyles = {
    table: css`
        border-collapse: separate;
        border-spacing: 0 6px;
        border: 0;
        width: 100%;
        padding: 0 40px 0 20px;

        // margin-bottom: 40px;

        & tr:hover > td {
            background: #EBF5F4 0% 0% no-repeat padding-box;
        };

        & thead tr th {
            font: normal normal bold 16px/24px Mustica Pro;
            letter-spacing: 0.14px;
            color: #3A817F;

            &:first-of-type {
                padding-left: 40px;
            };

            &:nth-of-type(n+2) {
                padding-left: 20px;
                width: 30%;
            };

            &:last-of-type {
                padding-right: 40px;
                width: 15%;
            };
        }

        & tbody > tr {
            position: relative;
            box-shadow: 0px 8px 16px #28326529;
            border-radius: 8px;

            & > td {
                font: normal normal normal 16px/24px Roboto;
                color: #858585;
                background: #FFFFFF 0% 0% no-repeat padding-box;

                border-radius: 0;
                height: 40px;

                // @media print {
                //     height: auto
                // }

                // max-width: 230px;
                word-wrap: anywhere;

                & > .dropdown {
                    margin: 6px 0;
                }

                & .radio-group {
                    display: flex;
                    flex-direction: row;
                    margin-left: 0;
                }

                &:first-of-type {
                    border-radius: 8px 0 0 8px;
                    padding-left: 40px;
                };

                &:nth-of-type(n+2) {
                    padding-left: 20px;
                };

                &:last-of-type {
                    border-radius: 0 8px 8px 0;
                    padding-right: 40px;
                };

                &:only-of-type {
                    border-radius: 8px;
                    padding: 0 12px 6px 12px;
                    background: #CBCBCB 0% 0% no-repeat padding-box;

                    & > table {
                        padding: 0;
                    }

                    & thead, & thead > tr, & th {
                        display: block;
                        max-height: 0;
                        height: 0;
                    }
                }
            };
        }
    `
};

export const ActivitySettingsTable = ({ settings = [], onChange, parentValue, ...props }) => {
    const data = Array.isArray(settings) ? settings : Object.entries(settings);
    const onChangeInternal = (rowName, newValue) =>
        onChange && onChange({
            ...settings,
            [rowName]: newValue,
        });
    return (
        <table className="settingsTable" css={reportTableStyles.table}>
            <thead>
                {props.disableHeaders ?
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                    :
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                        <th scope="col">Editable</th>
                    </tr>
                }
            </thead>
            <tbody>
                {data.map((row, rowIndex) => {
                    var rowSettings = row[1] || row;
                    const filteredSettings = Object.entries(rowSettings && rowSettings.ChildSettings ? rowSettings.ChildSettings : {});
                    const filteredSettingsObject = {};

                    if (parentValue && rowSettings.ParentValue && parentValue !== rowSettings.ParentValue){
                        return null;
                    }

                    for (const setting of filteredSettings) {
                        filteredSettingsObject[setting[0]] = setting[1];
                    }

                    return (
                        <React.Fragment key={`${row[0]}_${rowIndex}`}>
                            <tr>
                                <td>{rowSettings.Name}</td>
                                <td>{rowSettings.Type === 'increment' ?
                                    <IncrementInput
                                        minValue={Number(rowSettings.MinValue)}
                                        maxValue={Number(rowSettings.MaxValue)}
                                        stepSize={Number(rowSettings.StepSize)}
                                        value={Number(rowSettings.SelectedValue)}
                                        onChange={value => onChangeInternal(
                                            row[0],
                                            {
                                                ...row[1],
                                                SelectedValue: value
                                            }
                                        )}
                                    />
                                    : rowSettings.Type === 'radio' && rowSettings.Options.length === 2 && rowSettings.Options[0] === "On" && rowSettings.Options[1] === "Off" ?
                                        <Switch value={rowSettings.SelectedValue === 'On'} onChange={value => onChangeInternal(
                                            row[0],
                                            {
                                                ...row[1],
                                                SelectedValue: value ? 'On' : 'Off'
                                            }
                                        )} />
                                    : rowSettings.Type === 'radio' ?
                                        <RadioGroup value={rowSettings.SelectedValue} onChange={value => onChangeInternal(
                                            row[0],
                                            {
                                                ...row[1],
                                                SelectedValue: value
                                            }
                                        )}
                                        >
                                            {Object.entries(rowSettings.Options).map(x => <RadioOption key={x[1]} value={x[1]} label={x[1]} />)}
                                        </RadioGroup>
                                    : rowSettings.Type === 'dropdown' ?
                                        <SingleSelect
                                            value={rowSettings.SelectedValue}
                                            onChange={value => onChangeInternal(
                                                row[0],
                                                {
                                                    ...row[1],
                                                    SelectedValue: value
                                                }
                                            )}
                                            className="dropdown"
                                            variant="outlined"
                                        >
                                            {Object.entries(rowSettings.Options).map(x => <SelectOption key={x[1]} value={x[1]}>{x[1]}</SelectOption>)}
                                        </SingleSelect>
                                    : null
                                }</td>
                                <td>
                                    <Checkbox checked={rowSettings.IsEditable === 'TRUE' ? true : false}
                                        onChange={() => onChangeInternal(
                                            row[0],
                                            {
                                                ...row[1],
                                                IsEditable: rowSettings.IsEditable === 'TRUE' ? 'FALSE' : 'TRUE'
                                            }
                                        )}
                                    />
                                </td>
                            </tr>
                            {filteredSettings.filter(x => x[1].ParentValue === rowSettings.SelectedValue)?.length > 0 &&
                                <tr>
                                    <td colSpan="3" className="nested-table">
                                        <ActivitySettingsTable
                                            disableHeaders
                                            parentValue={rowSettings.SelectedValue}
                                            settings={filteredSettingsObject}
                                            onChange={values => onChangeInternal(
                                                row[0],
                                                {
                                                    ...row[1],
                                                    ChildSettings: values
                                                }
                                            )}
                                        />
                                    </td>
                                </tr>
                            }
                        </React.Fragment>
                    )
                })}
            </tbody>
        </table>
    );
};

const iconButtonStyle = css`
    & > button:first-of-type {
        padding-left: 0;
        padding-right: 30px;
    }

    & > button:last-of-type {
        padding-right: 0;
        padding-left: 30px;
    }

    & > button {
        width: 8px;
        height: 13px;
    }
`

const IncrementInput = ({ value, minValue, maxValue, stepSize, onChange }) => (
    <div css={iconButtonStyle}>
        <IconButton
            disabled={value === minValue}
            onClick={() => onChange(value - stepSize)}
        >
            <ChevronLeftIcon />
        </IconButton>
        <span>{value}</span>
        <IconButton
            disabled={value === maxValue}
            onClick={() => onChange(value + stepSize)}
        >
            <ChevronRightIcon />
        </IconButton>
    </div>
);
