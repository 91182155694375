/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { UserService } from '../../../Services/UserService';
import { shortDate, defaultSort } from '../../../Utils/Common';
import { InternalLink, TabLayout } from '../../../CoreComponents/Layout';
import { EditIcon } from '../../../CoreComponents/CustomIcons';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { Modal } from '../../../CoreComponents/Modal';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { ProfileInformationTab } from './ProfileInformationTab';
import { AddUserContent } from './AddUserContent';
import { AddAdminContent } from './AddAdminContent';
import { AdminSettingsTab } from './AdminSettingsTab';
import { UserGroupsTab } from './UserGroupsTab';
import { useTranslation } from '../../../CoreComponents/Translation';
import { AdminTypes, AdminTypesTranslationKeysObj, TableOptionsTypes, SortOrder, AdvancedSearchType, AdvancedSearchMatchType } from '../../../Utils/Constants';
import { ConnectedSitesTab } from './ConnectedSitesTab';
import { UserSportsTab } from './UserSportsTab';
import { InjuryHistoryTab } from './InjuryHistoryTab';
import { ChangePasswordContent } from './ChangePasswordContent';
import { Menu, MenuItem, Typography } from '@mui/material';
import { DeleteConfirmationModal } from '../../../CoreComponents/DeleteConfirmationModal';
import { Switch } from "../../../CoreComponents/Switch";
import { AdminService } from '../../../Services/AdminService';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from '../../../globalStates/storeState';
import { setUsers } from '../../../globalStates/tableDataState';

const usersListStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}

const UsersList = ({ $selectedUser, $refreshUsers }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin || storeState.currentUser.admin.adminTypeId === AdminTypes.Operator;
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId > AdminTypes.SuperAdmin && storeState.currentUser.admin.adminTypeId !== AdminTypes.Operator;
    const isProvider = storeState.currentUser.admin.adminTypeId === AdminTypes.Provider;
    const $users = useHook(tableDataState.users);
    const $rolesNames = useHook([]);
    const $siteNames = useHook([]);

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 150,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton
                            disabled={isTrazerViewAdmin}
                            onClick={() => {
                                const user = rowData;
                                const index = $users.value.findIndex(x => x.userId === user.userId);
                                const onEdit = (newUser) =>
                                    $users.set([
                                        ...$users.value.slice(0, index),
                                        newUser,
                                        ...$users.value.slice(index + 1)
                                    ]);
                                $selectedUser.set({ ...rowData, onEdit });
                            }}
                            tooltip={t('actions.edit')}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            component={InternalLink}
                            to={`/reports/user/${rowData.userId}`}
                        >
                            <SignalCellularAltIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'username',
            label: t('users.table.username'),
            isSortable: true,
            columnWidth: 155,
        },
        {
            id: 'name',
            label: t('users.table.name'),
            isSortable: true,
            columnWidth: 200,
        },
        {
            id: 'email',
            label: '',
            columnWidth: 0,
            advancedSearch: {
                type: AdvancedSearchType.Text,
                label: t('users.table.email'),
            },
            CellRender: ({ rowData }) => {
                return '';
            }
        },
        {
            id: 'sites',
            label: t('users.table.sites'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($siteNames.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            columnWidth: 175,
            textRenderer: ({ rowData }) => {
                return rowData.siteNames;
            },
            CellRender: ({ rowData }) => {
                return rowData.siteNames;
            }
        },
        {
            id: 'medicalRecordNumber',
            label: t('users.table.medicalRecordNumber'),
            isSortable: true,
            columnWidth: 220,
            CellRender: ({ rowData }) => {
                return rowData?.medicalRecordNumber || '-';
            }
        },
        {
            id: 'role',
            label: t('users.table.role'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: $rolesNames.value.map(x => ({
                    value: x,
                    label: t(AdminTypesTranslationKeysObj[x])
                }))
            },
            isSortable: true,
            columnWidth: 150,
            textRenderer: ({ rowData }) => {
                if (rowData.auth0_sub && rowData.role === 0) {
                    return t('constants.adminType.AuthorizedUser');
                } else {
                    return t(AdminTypesTranslationKeysObj[rowData.role] || AdminTypesTranslationKeysObj[0]);
                }
            },
            CellRender: ({ rowData }) => {
                if (rowData.auth0_sub && rowData.role === 0) {
                    return t('constants.adminType.AuthorizedUser');
                } else {
                    return t(AdminTypesTranslationKeysObj[rowData.role] || AdminTypesTranslationKeysObj[0]);
                }
            }
        },
        {
            id: 'dateCreated',
            label: t('users.table.createdOn'),
            isSortable: true,
            columnWidth: 180,
            CellRender: ({ rowData }) => {
                return rowData.dateCreated ? shortDate(new Date(rowData.dateCreated)) : '-';
            }
        },
        {
            id: 'lastUnitLogin',
            label: t('users.table.lastUnitLogin'),
            isSortable: true,
            columnWidth: 130,
            CellRender: ({ rowData }) => {
                return rowData.lastUnitLogin ? shortDate(new Date(rowData.lastUnitLogin)) : '-';
            }
        },
        {
            id: 'isActive',
            label: '',
            advancedSearch: {
                type: AdvancedSearchType.Select,
                label: t('users.tableFilter.isActive'),
                matchType: AdvancedSearchMatchType.Exact,
                defaultValue: true,
                values: [
                    {
                        value: null,
                        label: t('users.tableFilterValue.all')
                    },
                    {
                        value: true,
                        label: t('users.tableFilterValue.activeOnly')
                    },
                    {
                        value: false,
                        label: t('users.tableFilterValue.inactiveOnly')
                    },
                ]
            },
            CellRender: ({ rowData }) => {
                const $disabled = useHook(false);

                return (
                    <Switch
                        disabled={$disabled.value || isTrazerViewAdmin}
                        value={rowData.isActive}
                        tooltip={rowData.isActive ? t('actions.deactivate') : t('actions.activate')}
                        onChange={value => toggleUserActive(value, rowData.userId, $disabled)}
                    />
                );
            }
        },
    ], [t, $users.value]);

    const $isAddUserModalOpened = useHook(false);
    const $isAddAdminModalOpened = useHook(false);
    const $isLoading = useHook(!Boolean(tableDataState.users.length));
    const $addOptionsSelectionAnchor = useHook(null);
    const $importOptionsSelectionAnchor = useHook(null);
    const $userIdToDelete = useHook(null);

    const handleOpenAddOptionsMenu = (e) => $addOptionsSelectionAnchor.set(e.currentTarget);
    const handleCloseAddOptionsMenu = () => $addOptionsSelectionAnchor.set(null);
    const handleOpenImportOptionsMenu = (e) => $importOptionsSelectionAnchor.set(e.currentTarget);
    const handleCloseImportOptionsMenu = () => $importOptionsSelectionAnchor.set(null);

    useEffect(() => {
        let siteIds = null;
        let customerIds = null;

        if (!isTrazerAdmin) {
            if (isLessThenSuperAdmin) {
                siteIds = storeState.currentUser.admin.adminSites.map(site => site.siteId);
            } else {
                customerIds = [storeState.currentUser.admin.customerId];
            }
        }

        UserService.getAll(9999, null, null, customerIds, siteIds)
            .then(users => {
                const roles = {};
                const sites = {};

                for (const user of users) {
                    user.name = `${user.firstName} ${user.lastName}`;
                    user.role = user.admin?.adminTypeId || AdminTypes.User;
                    user.sites = user.userSites.map(s => s.site.name);
                    user.siteNames = user.sites.join(', ');
                    user.dateOfBirth = user.dateOfBirth?.toString();
                    user.dateCreated = user.dateCreated ? user.dateCreated.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      }) : '';
                    user.lastUnitLogin = user.lastUnitLogin ? user.lastUnitLogin.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      }) : '';
                    roles[user.role] = 1;
                    for (const site of user.userSites) {
                        sites[site.site.name] = 1
                    }
                }

                $rolesNames.set(Object.keys(roles));
                $siteNames.set(Object.keys(sites).map(value => ({ value, label: value })));

                dispatch(setUsers(users));
                $users.set(users);
                $isLoading.set(false);
            });
    }, [$refreshUsers.value, storeState.currentUser]);

    const acceptUserChanges = (userId, value) => {
        let userIndex = $users.value.findIndex(x => x.userId === userId);
        if (userIndex !== -1) {
            // Create a new user object with updated properties
            let updatedUser = {
                ...$users.value[userIndex],
                isActive: value,
                role: 0,
                admin: null,
                email: ''
            };

            // Update the array with the new user object
            $users.set([
                ...$users.value.slice(0, userIndex),
                updatedUser,
                ...$users.value.slice(userIndex + 1)
            ]);
        }
    }

    const toggleUserActive = (value, userId, $disabled) => {
        if (value) {
            $disabled.set(true);
            UserService.activateUser(userId)
                .then(() => acceptUserChanges(userId, true))
                .catch(() => $disabled.set(false))
        } else {
            $userIdToDelete.set(userId);
        }
    }

    const deleteUser = (userId) => {
        UserService.deactivateUser(userId)
            .then(() => {
                acceptUserChanges(userId, false);
                $userIdToDelete.set(null);
            })
    }

    return <div css={usersListStyles.root}>
        <TableCSF
            customActions={
                <div css={usersListStyles.actions}>
                    {isProvider ?
                        <>
                            <Button onClick={() => $isAddUserModalOpened.set(true)}>
                                <AddIcon />
                            </Button>
                            <Button
                                component={InternalLink}
                                to={'/users/import'}
                            >
                                {t('users.importUsers')}
                            </Button>
                        </>
                        :
                        <>
                            <Button disabled={isTrazerViewAdmin} onClick={handleOpenAddOptionsMenu}>
                                <AddIcon />
                            </Button>
                            <Menu
                                sx={{ mt: '32px' }}
                                id="add-user-options"
                                anchorEl={$addOptionsSelectionAnchor.value}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean($addOptionsSelectionAnchor.value)}
                                onClose={handleCloseAddOptionsMenu}
                            >

                                <MenuItem
                                    onClick={() => {
                                        handleCloseAddOptionsMenu();
                                        $isAddUserModalOpened.set(true)
                                    }}
                                >
                                    <Typography textAlign="center">{t('users.addOptions.user')}</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleCloseAddOptionsMenu();
                                        $isAddAdminModalOpened.set(true)
                                    }}
                                >
                                    <Typography textAlign="center">{t('users.addOptions.admin')}</Typography>
                                </MenuItem>
                            </Menu>
                            <Button
                                disabled={isTrazerViewAdmin}
                                onClick={handleOpenImportOptionsMenu}
                            >
                                {t('users.import')}
                            </Button>
                            <Menu
                                sx={{ mt: '32px' }}
                                id="add-user-options"
                                anchorEl={$importOptionsSelectionAnchor.value}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean($importOptionsSelectionAnchor.value)}
                                onClose={handleCloseImportOptionsMenu}
                            >
                                <MenuItem
                                    component={InternalLink}
                                    to={'/users/import'}
                                >
                                    <Typography textAlign="center">{t('users.importOptions.user')}</Typography>
                                </MenuItem>
                                <MenuItem
                                    component={InternalLink}
                                    to={'/admins/import'}
                                >
                                    <Typography textAlign="center">{t('users.importOptions.admin')}</Typography>
                                </MenuItem>
                            </Menu>
                        </>
                    }
                </div>
            }
            headCells={headCells}
            rows={$users.value}
            totalItemsCount={$users.value.length}
            rowKeySelector={r => r.userId}
            isLoading={$isLoading.value}
            showExport
            exportFileNameGenerator={() => `users-${(new Date()).toISOString().split('T')[0]}`}
            enablePersistingFilters
            pageName={TableOptionsTypes.Users}
        />
        <Modal
            open={$isAddUserModalOpened.value}
            onClose={() => $isAddUserModalOpened.set(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <AddUserContent onCancel={() => $isAddUserModalOpened.set(false)}
                onAdd={() => {
                    $isAddUserModalOpened.set(false);
                    $refreshUsers.set({});
                }} />
        </Modal>
        <Modal
            open={$isAddAdminModalOpened.value}
            onClose={() => $isAddAdminModalOpened.set(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <AddAdminContent onCancel={() => $isAddAdminModalOpened.set(false)}
                onAdd={() => {
                    $isAddAdminModalOpened.set(false);
                    $refreshUsers.set({});
                }} />
        </Modal>
        <DeleteConfirmationModal
            onDelete={() => deleteUser($userIdToDelete.value)}
            open={Boolean($userIdToDelete.value)}
            onCancel={() => $userIdToDelete.set(null)}
            title={t('users.deactivateModal.title')}
            description={t('users.deactivateModal.description')}
            deleteLabel={t('actions.deactivate')}
        />
    </div>
}

const usersStyles = {
    sidePanelActions: css`
        & > button{
            width: 100%;
            margin-top: 8px;
        }
    `
}

export const Users = () => {
    const storeState = useSelector((state) => state.storeState);
    const dispatch = useDispatch();

    const $selectedUser = useObjectHook(null);
    const { t } = useTranslation();
    const $tabs = useHook([]);
    const $refreshUsers = useHook({});
    const $prevSelectedUser = useHook(null);
    const $emailSentSuccessfully = useHook(false);
    const $isChangePassword = useHook(false);
    const isMoreThenProvider = storeState.currentUser.admin.adminTypeId > AdminTypes.Provider && storeState.currentUser.admin.adminTypeId !== AdminTypes.Operator;
    const isTrazerAndTrazerViewAdmin = storeState.currentUser.admin.adminTypeId >= AdminTypes.TRAZERView && storeState.currentUser.admin.adminTypeId !== AdminTypes !== AdminTypes.Operator;
    const $disableSyncAdmin = useHook(false);
    const $showSyncAdmin = useHook(false);
    const $disableUnblock = useHook(false);
    const $showUnblockAdmin = useHook(false);
    const $showUnblock = useHook(false);

    useEffect(() => {
        if (!$selectedUser.value) {
            return;
        }

        $showSyncAdmin.set($selectedUser.value.admin && !$selectedUser.value?.auth0_sub && storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin);

        $showUnblockAdmin.set($selectedUser.value.admin && storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin);

        $showUnblock.set($selectedUser.value?.isLocked);

        $tabs.set([
            {
                name: t('users.editModal.profileInformationTabName'),
                ContentComponent: () => <ProfileInformationTab $selectedUser={$selectedUser} />,
                isDefault: true
            },
            isMoreThenProvider ?
                { name: t('users.editModal.adminSettingsTabName'), ContentComponent: () => <AdminSettingsTab $selectedUser={$selectedUser} /> }
                : null,
            { name: t('users.editModal.groupsTabName'), ContentComponent: () => <UserGroupsTab $selectedUser={$selectedUser} /> },
            { name: t('users.editModal.connectedSitesTabName'), ContentComponent: () => <ConnectedSitesTab $selectedUser={$selectedUser} /> },
            { name: t('users.editModal.sportsTabName'), ContentComponent: () => <UserSportsTab $selectedUser={$selectedUser} /> },
            !isTrazerAndTrazerViewAdmin ?
                { name: t('users.editModal.injuryHistoryTabName'), ContentComponent: () => <InjuryHistoryTab $selectedUser={$selectedUser} /> }
                :
                null,
        ].filter(x => x));
    }, [$selectedUser.value]);

    useEffect(() => {
        if ($prevSelectedUser.value && $selectedUser.value) {
            if ($prevSelectedUser.value.userId === storeState.currentUser.userId) {
                updateCurrentUser();
            }

            $refreshUsers.set({});
            $selectedUser.set(null);
            $prevSelectedUser.set(null);
            return;
        }

        $prevSelectedUser.set($selectedUser.value);
        $emailSentSuccessfully.set(false);
    }, [$selectedUser.value]);

    const updateCurrentUser = async () => {
        try {
            const currentUser = await UserService.getMe();
            dispatch(setCurrentUser(currentUser));
        } catch (e) {
            console.log(e.message);
        }
    };

    const tabActions = isMoreThenProvider ?
        () => <div css={usersStyles.sidePanelActions}>
            {($showSyncAdmin.value) &&
                <Button disabled={$disableSyncAdmin.value} onClick={e => {
                    $disableSyncAdmin.set(true);
                    const promises = [];
                    if ($showSyncAdmin.value) {
                        promises.push(
                            new Promise(
                                resolve => {
                                    AdminService.syncAdmin($selectedUser.value.userId)
                                        .then(() => $selectedUser.set({ ...$selectedUser.value }))
                                }
                            )
                        );
                    }
                    Promise.all(promises).finally($disableSyncAdmin.set(false))
                }}
                >
                    {t('actions.syncAdmin')}
                </Button>
            }
            {($showUnblock.value || $showUnblockAdmin.value) &&
                <Button disabled={$disableUnblock.value} onClick={e => {
                    $disableUnblock.set(true);
                    const promises = [];
                    if ($showUnblock.value) {
                        promises.push(
                            new Promise(
                                resolve => {
                                    UserService.unblockUser($selectedUser.value.userId)
                                        .then(() => $selectedUser.set({ ...$selectedUser.value, isLocked: false }))
                                }
                            )
                        );
                    }
                    if ($showUnblockAdmin.value) {
                        promises.push(
                            new Promise(
                                resolve => {
                                    AdminService.unblockAdmin($selectedUser.value.userId)
                                        .then(() => $selectedUser.set({ ...$selectedUser.value, admin: { ...$selectedUser.value.admin, isBlocked: false } }))
                                }
                            )
                        );
                    }
                    Promise.all(promises).finally($disableUnblock.set(false))
                }}
                >
                    {t('actions.unblock')}
                </Button>
            }
            {$selectedUser.value?.admin && !$emailSentSuccessfully.value &&
                <Button
                    onClick={() =>
                        UserService.sendVerificationEmail($selectedUser.value.userId)
                            .then(() => $emailSentSuccessfully.set(true))
                    }
                >
                    {t('actions.resendVerificationEmail')}
                </Button>
            }
            {$selectedUser.value?.admin &&
                <Button onClick={() => $isChangePassword.set(true)}>{t('actions.changePassword')}</Button>
            }
        </div>
        : null;

    return (
        <>
            <UsersList $selectedUser={$selectedUser} $refreshUsers={$refreshUsers} />
            <Modal
                fixedModalPosition
                open={!!$selectedUser.value}
                onClose={() => $selectedUser.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <TabLayout
                    items={$tabs.value}
                    sidePanelProps={{ Actions: tabActions }}
                />
            </Modal>
            <Modal
                open={$isChangePassword.value}
                onClose={() => $isChangePassword.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ChangePasswordContent
                    $selectedUser={$selectedUser}
                    onCancel={() => $isChangePassword.set(false)}
                    onSuccess={() => $isChangePassword.set(false)}
                />
            </Modal>
        </>
    )
}
