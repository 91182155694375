// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { blue, grey } from '../../Utils/Constants';
import React from 'react';

const selectOptionStyles = css`
    list-style: none;
    padding: 8px;
    margin: 2px 0;
    cursor: default;
    // display: flex;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flow-root;

    & > * {
        margin: auto 0;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
    }

    &.${optionUnstyledClasses.selected} {
        background-color: ${blue[100]};
    }

    &.${optionUnstyledClasses.highlighted} {
        background-color: rgba(224, 224, 224, 0.41);
        // color: ${grey[900]};
    }

    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: rgba(224, 224, 224, 1.0);
    }

    &.${optionUnstyledClasses.disabled} {
        background-color: ${grey[50]};
    }

    &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: rgba(224, 224, 224, 0.41);
    }
`;

export const SelectOption = React.forwardRef(function ({ className, ...props }, ref) {
    return (
        <OptionUnstyled
            ref={ref}
            className={className ? className + ' select-option' : 'select-option'}
            css={selectOptionStyles}
            {...props}
        />
    );
});
