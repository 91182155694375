// WARN: this is written with double quotes because we can change it faster to a json
const DETranslations = {
    "mainNavigation.overview": "Übersicht",
    "mainNavigation.manage": "Verwalten",
    "mainNavigation.library": "Bibliothek",
    "mainNavigation.analytics": "Analytik",
    "mainNavigation.dashboard": "Dashboard",
    "mainNavigation.profileSettingsTooltip": "Profil öffnen",
    "mainNavigation.profileSettingsProfile": "Mein Profil",
    "mainNavigation.profileSettingsHelp": "Hilfe",
    "mainNavigation.profileSettingsDocumentation": "Dokumentation",
    "mainNavigation.profileSettingsContactSupport": "Kontakt Support",
    "mainNavigation.userView": "Benutzeransicht ändern",
    "mainNavigation.userView.reset": "Benutzeransicht zurücksetzen",
    "mainNavigation.selectViewModal.title": "Ansichtsrolle auswählen",
    "manage.sideNavigation.categories": "Kategorien",
    "manage.sideNavigation.tags": "Tags",
    "manage.sideNavigation.baseActivities": "Grundaktivitäten",
    "manage.sideNavigation.scriptedActivities": "Skriptgesteuerte Aktivitäten",
    "manage.sideNavigation.customers": "Kunden",
    "manage.sideNavigation.sites": "Standorte",
    "manage.sideNavigation.units": "Einheiten",
    "manage.sideNavigation.userGroups": "Benutzergruppen",
    "manage.sideNavigation.users": "Benutzer",
    "manage.sideNavigation.tests": "Tests",
    "coreComponents.table.paginationInfo": "{0}-{1} von {2}",
    "coreComponents.table.paginationInfoMenuTitle": "Bis zu anzeigen",
    "coreComponents.table.paginationInfoMenuItemTemplate": "{0} Elemente",
    "coreComponents.table.noMatchingRecords": "Keine übereinstimmenden Datensätze gefunden",
    "coreComponents.table.advancedSearchTitle": "Erweiterte Suche",
    "coreComponents.table.visibleColumns": "Sichtbare Spalten",
    "coreComponents.select.noResultsSearchTemplate": "Keine Ergebnisse für \"{0}\" gefunden",
    "constants.gender.nonBinary": "Nicht-binär",
    "constants.gender.female": "Weiblich",
    "constants.gender.male": "Männlich",
    "constants.dominance.unknown": "Unbekannt",
    "constants.dominance.left": "Links",
    "constants.dominance.right": "Rechts",
    "constants.dominance.ambidextrous": "Beidhändig",
    "constants.adminType.TRAZERAdmin": "TRAZER-Administrator",
    "constants.adminType.TRAZERView": "TRAZER-Ansicht",
    "constants.adminType.SuperAdmin": "Superadministrator",
    "constants.adminType.SiteAdmin": "Standortadministrator",
    "constants.adminType.Provider": "Anbieter",
    "constants.adminType.User": "Benutzer",
    "constants.side.left": "Links",
    "constants.side.right": "Rechts",
    "constants.side.none": "N/A",
    "constants.testStatuses.empty": "Leer",
    "constants.testStatuses.complete": "Vollständig",
    "constants.testStatuses.partial": "Teilweise",
    "constants.testStatuses.deleted": "Gelöscht",
    "constants.missTypes.leaningOrSway": "Neigen oder Schwanken erkannt",
    "constants.missTypes.stumbleOrFall": "Stolpern oder Fallen erkannt",
    "constants.missTypes.leftHandOffHip": "Linke Hand vom Hüftbereich entfernt",
    "constants.missTypes.rightHandOffHip": "Rechte Hand vom Hüftbereich entfernt",
    "constants.missTypes.step": "Schritt erkannt",
    "constants.missTypes.leftToeRaised": "Linker Zeh angehoben",
    "constants.missTypes.rightToeRaised": "Rechter Zeh angehoben",
    "constants.missTypes.leftHeelRaised": "Linker Absatz angehoben",
    "constants.missTypes.rightHeelRaised": "Rechter Absatz angehoben",
    "constants.missTypes.plantToeRaised": "Pflanzenzehen angehoben",
    "constants.missTypes.plantHeelRaised": "Pflanzenabsatz angehoben",
    "constants.missTypes.freeFootDropped": "Freier Fuß abgesenkt",
    "constants.missTypes.freeAnkleDropped": "Freier Knöchel abgesenkt",
    "constants.missTypes.freeKneeTooHigh": "Freies Knie zu hoch",
    "constants.missTypes.freeKneeTooFar": "Freies Knie zu weit entfernt",
    "constants.unitType.meters": "Meter (m)",
    "constants.unitType.feet": "Fuß (ft)",
    "constants.unitType.metersShort": "m",
    "constants.unitType.feetShort": "ft",
    "constants.unitType.inchShort": "in",
    "constants.unitType.centimetersShort": "cm",
    "constants.unitType.poundShort": "lbs",
    "constants.unitType.units": "Einheiten",
    "constants.unitType.calories": "Kalorien",
    "constants.units.count": "Anzahl",

    "constants.units.seconds": "Sekunden",
    "constants.units.secondsShort": "s",
    "constants.units.millisecondsShort": "ms",
    "constants.units.m/s": "m/s",
    "constants.units.ft/s": "ft/s",
    // "constants.units.m/s^2": "m/s<sup>2</sup>",
    // "constants.units.ft/s^2": "ft/s<sup>2</sup>",
    "constants.units.m/s^2": "m/s²",
    "constants.units.ft/s^2": "ft/s²",
    "constants.units.beatsPerMinute": "Schläge pro Minute",
    "constants.units.degrees": "°",
    "constants.units.calories": "Kalorien",
    "constants.units.caloriesShort": "Kal.",
    "constants.dateRanges.currentWeek": "Aktuelle Woche",
    "constants.dateRanges.lastWeek": "Letzte Woche",
    "constants.dateRanges.thisMonth": "Dieser Monat",
    "constants.dateRanges.lastMonth": "Letzter Monat",
    "constants.dateRanges.last6Months": "Letzte 6 Monate",
    "constants.dateRanges.thisYear": "Dieses Jahr",
    "constants.dateRanges.lastYear": "Letztes Jahr",
    "constants.dateRanges.customRange": "Benutzerdefiniert",
    "constants.activityTypes.mobility": "Beweglichkeit",
    "constants.activityTypes.cognitive": "Kognitive Fähigkeiten",
    "constants.activityTypes.balance": "Balance",
    "constants.activityTypes.kinematics": "Kinematik",
    "constants.activityTypes.games": "Spiele",
    "constants.activityTypes.upperExtremity": "Obere Extremität",
    "constants.alertType.info": "Information",
    "constants.alertType.warning": "Warnung",
    "constants.alertType.success": "Erfolg",
    "constants.alertType.error": "Fehler",
    "input.placeholder": "Hier eingeben...",
    "input.placeholder.min": "Min",
    "input.placeholder.max": "Max",
    "input.searchPlaceholder": "Suchen...",
    "dropdown.emptyOption": "Auswählen",
    "chart.options.viewInFullScreen": "Vollbild anzeigen",
    "chart.options.exitFullScreen": "Vollbild verlassen",
    "chart.options.print": "Drucken",
    "chart.options.downloadPngImage": "PNG-Bild herunterladen",
    "chart.options.downloadJpgImage": "JPEG-Bild herunterladen",
    "chart.options.downloadCsv": "CSV herunterladen",
    "chart.options.downloadXls": "XLS herunterladen",
    "chart.normalDistribution.leftAxisClarification": "Anzahl",
    "chart.normalDistribution.rightAxisClarification": "Normalverteilung",
    "chart.normalDistribution.sizeInfo": "Größe (n): {0}",
    "chart.normalDistribution.meanOrAverageInfo": "Mittelwert (μ): {0}{1}",
    "chart.normalDistribution.standardDeviationInfo": "Standardabweichung (σ): {0}{1}",
    "chart.normalDistribution.datasetNameHistogram": "Histogramm",
    "chart.normalDistribution.datasetNameNormalDistribution": "Normalverteilung",
    "chart.normalDistribution.datasetNameData": "Daten",
    "chart.normalDistribution.exportRangeTemplate": "{0}-Bereich",
    "chart.normalDistribution.exportColumnCount": "Anzahl",
    "actions.deactivate": "Deaktivieren",
    "actions.activate": "Aktivieren",
    "actions.repurpose": "Umschreiben",
    "actions.unregister": "Abmelden",
    "actions.retry": "Wiederholen",
    "actions.logout": "Abmelden",
    "actions.login": "Anmeldung",
    "actions.update": "Aktualisieren",
    "actions.add": "Hinzufügen",
    "actions.cancel": "Abbrechen",
    "actions.delete": "Löschen",
    "actions.apply": "Anwenden",
    "actions.edit": "Bearbeiten",
    "actions.done": "Fertig",
    "actions.changePassword": "Passwort ändern",
    "actions.resendVerificationEmail": "E-Mail erneut senden",
    "actions.export": "Exportieren",
    "actions.exportCsv": "CSV",
    "actions.exportExcel": "MS-Excel",
    "actions.change": "Ändern",
    "actions.next": "Weiter",
    "actions.previous": "Zurück",
    "actions.chooseFile": "Datei auswählen",
    "actions.submit": "Absenden",
    "actions.backToManage": "Zurück zur Verwaltung",
    "actions.tryAgain": "Erneut versuchen",
    "actions.save": "Speichern",
    "actions.markAll": "Alle markieren",
    "actions.selectAll": "Alle auswählen",
    "actions.editUsers": "Benutzer bearbeiten",
    "actions.editName": "Name bearbeiten",
    "actions.compareTests": "Tests vergleichen",
    "actions.compareSteps": "Schritte vergleichen",
    "actions.compare": "Vergleichen",
    "actions.setAsBaseline": "Als Basis festlegen",
    "actions.learnMore": "Mehr erfahren",
    "actions.search": "Suchen",

    "actions.showMoreFilters": "Mehr Filter anzeigen",
    "actions.showLessFilters": "Weniger Filter anzeigen",
    "actions.generateReport": "Bericht generieren",
    "actions.exportTestData": "Testdaten exportieren",
    "actions.exportRepData": "Replikationsdaten exportieren",
    "actions.viewAll": "Alle anzeigen",
    "actions.register": "Registrieren",
    "actions.yes": "Ja",
    "actions.unblock": "Freischalten",
    "actions.print": "Drucken",
    "actions.syncAdmin": "Admin synchronisieren",
    "users.fieldName.firstName": "Vorname",
    "users.fieldName.lastName": "Nachname",
    "users.fieldName.username": "Benutzername",
    "users.fieldName.email": "E-Mail",
    "users.fieldName.phoneNumber": "Telefonnummer",
    "users.fieldName.medicalRecordsNumber": "Medizinische Aktennummer",
    "users.fieldName.dateOfBirth": "Geburtsdatum",
    "users.fieldName.height": "Größe",
    "users.fieldName.weight": "Gewicht",
    "users.fieldName.gender": "Geschlecht",
    "users.fieldName.dominance": "Dominanz",
    "users.fieldName.pin": "TRAZER PIN",
    "users.fieldName.optionalPin": "TRAZER PIN (optional)",
    "users.fieldName.customer": "Kunde",
    "users.fieldName.site": "Standort, um Benutzer zuzuordnen",
    "users.fieldName.group": "Gruppe, um Benutzer zuzuordnen",
    "users.fieldName.password": "Passwort",
    "users.fieldName.newPassword": "Neues Passwort",
    "users.fieldName.confirmPassword": "Passwort bestätigen",
    "users.fieldName.adminRole": "Admin-Rolle",
    "users.fieldName.role": "Rolle",
    "users.addOptions.user": "Benutzer",
    "users.addOptions.admin": "Admin",
    "users.importOptions.user": "Benutzer",
    "users.importOptions.admin": "Admin",
    "register.title": "Benutzer registrieren",
    "register.fieldName.code": "Code",
    "register.message.invalidCode": "Ungültiger Code!",
    "register.message.usernameTaken": "Benutzername ist bereits vergeben!",
    "register.message.success": "Erfolg!",
    "register.message.successDescription": "Sie haben den Registrierungsvorgang abgeschlossen und können TRAZER nun mit Ihrem Benutzernamen {0} verwenden.",
    "admins.fieldName.customer": "Kunde",
    "admins.fieldName.site": "Standort",
    "admins.fieldName.password": "Passwort",
    "admins.fieldName.user": "Benutzer",
    "admins.fieldName.adminRole": "Admin-Rolle",
    "customers.fieldName.name": "Name",
    "users.import": "Importieren",
    "users.import.skip": "Überspringen",
    "users.importUsers": "Benutzer importieren",
    "users.editModal.title": "Benutzer bearbeiten",
    "users.editModal.profileInformationTabName": "Profilinformation",
    "users.editModal.adminSettingsTabName": "Admin-Einstellungen",
    "users.editModal.testsTabName": "Tests",
    "users.editModal.groupsTabName": "Gruppen",
    "users.editModal.connectedSitesTabName": "Verbundene Standorte",
    "users.editModal.sportsTabName": "Sportarten",
    "users.editModal.injuryHistoryTabName": "Verletzungshistorie",
    "customers.editModal.editCustomerTabName": "Kunde",
    "customers.editModal.sitesTabName": "Standorte",
    "sites.editModal.title": "Standort bearbeiten",
    "sites.editModal.sitesTab": "Standortinformation",
    "sites.editModal.siteUnitsTab": "Standorteinheiten",
    "site.deleteModal.title": "Standort löschen",
    "site.deleteModal.description": "Sind Sie sicher, dass Sie diesen Standort löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "userGroups.removeGroupModal.title": "Gruppe löschen",
    "userGroups.removeUserGroupModal.title": "Benutzer aus Gruppe entfernen",
    "userGroups.removeGroupModal.description": "Sind Sie sicher, dass Sie diese Gruppe löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "userGroups.removeUserGroupModal.description": "Sind Sie sicher, dass Sie den Benutzer aus dieser Gruppe entfernen möchten?",
    "userSport.deleteModal.title": "Sportart löschen",
    "userSport.deleteModal.description": "Sind Sie sicher, dass Sie diese Sportart löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "userInjury.deleteModal.title": "Verletzung löschen",
    "userInjury.deleteModal.description": "Sind Sie sicher, dass Sie diese Verletzung löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "tests.deleteModal.title": "Test löschen",

    "tests.deleteModal.description": "Sind Sie sicher, dass Sie diesen Test löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "customers.table.createdOn": "Erstellt am",
    "customers.table.name": "Name",
    "customers.table.sites": "Standorte",
    "users.addModal.title": "Benutzer hinzufügen",
    "users.addGroupModal.title": "Zu einer Gruppe hinzufügen",
    "users.deactivateModal.title": "Benutzer deaktivieren",
    "users.deactivateModal.description": "Sind Sie sicher, dass Sie diesen Benutzer deaktivieren möchten?",
    "userGroups.deactivateModal.title": "Gruppe löschen",
    "userGroups.deactivateModal.description": "Sind Sie sicher, dass Sie diese Gruppe löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "admins.addModal.title": "Admin hinzufügen",
    "customers.addModal.title": "Kunde hinzufügen",
    "sites.addModal.title": "Standort hinzufügen",
    "groups.addModal.title": "Gruppe hinzufügen",
    "sports.addModal.title": "Sportart hinzufügen",
    "injury.addModal.title": "Verletzung hinzufügen",
    "injury.editModal.title": "Verletzung bearbeiten",
    "changePassword.modal.title": "Passwort ändern",
    "setPin.modal.title": "Erstellen Sie eine Admin-PIN",
    "setPin.modal.description": "Erstellen Sie eine 4-stellige PIN für Ihr Admin-Profil, um den Offline-Modus in TRAZER zu nutzen.",
    "users.table.username": "Benutzername",
    "users.table.name": "Name",
    "users.table.medicalRecordNumber": "Medizinische Aktennummer",
    "users.table.sites": "Standorte",
    "users.table.role": "Rolle",
    "users.table.createdOn": "Erstellt am",
    "users.table.lastUnitLogin": "Letzter Login",
    "users.table.firstName": "Vorname",
    "users.table.lastName": "Nachname",
    "users.table.email": "E-Mail",
    "users.table.dateOfBirth": "Geburtsdatum",
    "users.table.gender": "Geschlecht",
    "users.table.height": "Größe",
    "users.table.weight": "Gewicht",
    "users.table.phoneNumber": "Telefonnummer",
    "users.table.timeAdded": "Hinzugefügt am",
    "users.table.adminType": "Admin-Typ",
    "users.tableFilter.isActive": "Aktiv",
    "users.tableFilterValue.all": "Alle",
    "users.tableFilterValue.activeOnly": "Nur aktive",
    "users.tableFilterValue.inactiveOnly": "Nur inaktive",
    "importUsers.columnTemplate": "Spalte {0}",
    "importUsers.editEntriesStep.hint": "Wählen Sie einen Header für jede Spalte aus.",
    "importUsers.editEntriesStep.errorMessage": "Ein oder mehrere Benutzer haben fehlende Einträge. Bitte laden Sie eine neue Datei hoch.",
    "importUsers.attachStep.hint": "Wählen Sie den Standort aus, um Benutzer zuzuordnen",
    "importUsers.attachStep.customer": "Kunde",
    "importUsers.attachStep.site": "Standort",
    "importUsers.attachStep.userGroup": "Benutzergruppe",
    "importUsers.importFileStep.hint": "Importieren Sie eine kommagetrennte (.csv) oder (.txt) Datei mit Benutzerinformationen",
    "importUsers.importFileStep.unitFormatsTitle": "Erforderliche Benutzerinformationen und akzeptierte Formate:",
    "importUsers.importFileStep.dragToUpload": "Datei ziehen, um sie hochzuladen, oder",
    "importUsers.importFileStep.unitFormatsGender": "Geschlecht",
    "importUsers.importFileStep.unitFormatsHeight": "Größe",
    "importUsers.importFileStep.unitFormatsFirstName": "Vorname",
    "importUsers.importFileStep.unitFormatsLastName": "Nachname",
    "importUsers.importFileStep.unitFormatsDateOfBirth": "Geburtsdatum",
    "importUsers.importFileStep.unitFormatsUsername": "Benutzername",
    "importUsers.importFileStep.unitFormatsEmail": "E-Mail",
    "importUsers.importFileStep.unitFormatsPhoneNumber": "Telefonnummer",
    "importUsers.importFileStep.unitFormatsMedicalRecordNumber": "Medizinische Aktennummer",
    "importUsers.importFileStep.unitFormatsTitleOptional": "Optionale Benutzerinformationen:",
    "importUsers.importFileStep.unitFormatsHeightDescription": "(wenn keine Einheiten angegeben sind, wird angenommen, dass Einheiten Zoll sind)",
    "importUsers.importFileStep.unitFormatsWeight": "Gewicht",
    "importUsers.importFileStep.unitFormatsWeightDescription": "(wenn keine Einheiten angegeben sind, wird angenommen, dass Einheiten Pfund sind)",
    "importUsers.importFileStep.unitFormatsAdminType": "Admin-Typ:",
    "importUsers.importFileStep.unitFormatsAdminTypeDescription": " (wenn kein Admin-Typ angegeben ist, wird davon ausgegangen, dass keine Admin-Rolle vorhanden ist)",
    "importUsers.importFileStep.columnHeaderIncludedTitle": "Bitte geben Sie an, ob die Zeile mit den Spaltenüberschriften enthalten ist",

    "importUsers.importFileStep.columnHeaderIncludedCheckbox": "Zeile mit Spaltenüberschriften enthalten",
    "importUsers.wrongUsers.errorMessage": "Wir haben Probleme bei folgenden Benutzern festgestellt. Wahrscheinliche Ursachen könnten fehlende oder bereits vorhandene Benutzernamen und E-Mails sein.",
    "importUsers.successfullyAdded.successMessage": "Benutzer erfolgreich hinzugefügt!",
    "importUsers.steps.importFile": "Datei importieren",
    "importUsers.steps.editEntries": "Einträge bearbeiten",
    "importUsers.steps.attach": "Anhängen",
    "tests.table.scriptedActivity": "Skriptgesteuerte Aktivität",
    "tests.table.time": "Zeit",
    "tests.table.name": "Name",
    "tests.table.username": "Benutzername",
    "tests.table.site": "Standort",
    "tests.table.status": "Status",
    "groups.table.group": "Gruppe",
    "groups.table.customer": "Kundenname",
    "groups.table.site": "Standortname",
    "groups.fieldName.name": "Name",
    "groups.fieldName.customer": "Kunde",
    "groups.fieldName.site": "Standort",
    "groups.fieldName.group": "Gruppe",
    "userGroup.deleteModal.title": "Benutzer löschen",
    "userGroup.deleteModal.description": "Sind Sie sicher, dass Sie den Benutzer aus dieser Gruppe löschen möchten?",
    "sites.fieldName.site": "Standort",
    "sites.fieldName.customer": "Kunde",
    "sites.fieldName.name": "Standortname",
    "sites.fieldName.contactName": "Kontaktname",
    "sites.fieldName.phone": "Telefonnummer",
    "sites.fieldName.email": "E-Mail",
    "sites.fieldName.address": "Adresse",
    "sites.fieldName.addressLine2": "Adresszeile 2",
    "sites.fieldName.city": "Stadt",
    "sites.fieldName.state": "Bundesland/Provinz",
    "sites.fieldName.zipCode": "Postleitzahl",
    "sites.fieldName.country": "Land",
    "sites.fieldName.pin": "Standort-PIN",
    "sites.table.name": "Standortname",
    "sites.table.pin": "Standort-PIN",
    "sites.table.customer.name": "Kundenname",
    "sites.table.contactName": "Kontaktname",
    "sites.table.phone": "Telefonnummer",
    "sites.table.email": "E-Mail",
    "sports.table.sport": "Sportart",
    "sports.table.position": "Position",
    "sports.table.level": "Level",
    "sport.fieldName.sport": "Sportart",
    "sport.fieldName.position": "Position",
    "sport.fieldName.level": "Level",
    "units.table.serialNumber": "Seriennummer",
    "units.table.skuNumber": "SKU-Nummer",
    "units.table.createdOn": "Erstellt am",
    "units.table.lastOnline": "Zuletzt online",
    "units.table.customerName": "Kunde",
    "units.table.siteName": "Standort",
    "units.table.clientVersion": "Version",
    "units.table.isActive": "Aktiv",
    "units.table.isRegistered": "Registriert",
    "units.addModal.title": "Gerät hinzufügen",
    "units.editModal.title": "Geräteinformationen",
    "units.editModal.activityLibraryTab": "Aktivitätsbibliothek",
    "units.editModal.unitSettingsTab": "Geräteeinstellungen",
    "units.editModal.activityLibraryTab.title": "Aktivitätsbibliothek bearbeiten",
    "units.editModal.activityLibraryTab.assessments": "Bewertungen",
    "units.editModal.activityLibraryTab.drillIndex": "Bohrindex",
    "units.editModal.activityLibraryTab.games": "Spiele",
    "units.editModal.activityLibraryTab.rehabilitation": "Rehabilitation",
    "units.editModal.activityLibraryTab.sports": "Sportarten",
    "units.editModal.activityLibraryTab.workouts": "Workouts",
    "units.checkbox.searchByUsername": "Benutzername",
    "units.checkbox.searchByFirstName": "Vorname",
    "units.checkbox.searchByLastName": "Nachname",
    "units.switch.requireUserPIN": "Benutzer-PIN erforderlich",
    "units.switch.requireOrientationActivity": "Orientierungsaktivität erforderlich",
    "units.switch.displayFirstAndLastName": "Vor- und Nachnamen anzeigen (Benutzersuche)",
    "units.fieldName.customer": "Kunde",
    "units.fieldName.site": "Standort",
    "units.fieldName.skuNumber": "SKU-Nummer",
    "units.fieldName.notes": "Notizen",
    "units.fieldName.defaultUnits": "Standard Einheiten",

    "units.fieldName.adminLogout": "Admin-Logout nach folgender Minuten Inaktivität:",
    "units.fieldName.userLogout": "Benutzer-Logout nach folgender Minuten Inaktivität:",
    "units.fieldName.verifyUser": "Benutzer-Logout nach folgender Minuten Inaktivität überprüfen:",
    "units.fieldName.searchBy": "Suche nach (Benutzersuche)",
    "units.tableFilterValue.all": "Alle",
    "units.tableFilterValue.activeOnly": "Nur Aktive",
    "units.tableFilterValue.inactiveOnly": "Nur Inaktive",
    "units.validationErrors.adminAutoLogoutTime": "Muss größer oder gleich der Benutzer-Auto-Logout-Zeit sein",
    "units.validationErrors.userAutoLogoutTime": "Muss kleiner oder gleich der Admin-Auto-Logout-Zeit sein und größer oder gleich der Benutzer-Inaktivitätszeit sein",
    "units.validationErrors.userInactiveTime": "Muss kleiner oder gleich der Benutzer-Auto-Logout-Zeit sein",
    "injury.table.location": "Verletzungsort",
    "injury.table.type": "Art der Verletzung",
    "injury.table.side": "Seite der Verletzung",
    "injury.table.date": "Verletzungsdatum",
    "injury.table.status": "Verletzungsstatus",
    "injury.table.surgeryDate": "Operationsdatum",
    "injury.fieldName.location": "Verletzungsort",
    "injury.fieldName.type": "Art der Verletzung",
    "injury.fieldName.side": "Seite der Verletzung",
    "injury.fieldName.dateOfInjury": "Verletzungsdatum",
    "injury.fieldName.status": "Verletzungsstatus",
    "injury.fieldName.dateOfSurgery": "Operationsdatum",
    "injury.surgeryDate.error": "Das Operationsdatum muss nach dem Verletzungsdatum liegen",
    "customerReport.controls.dateRange": "Datumsbereich",
    "customerReport.tableTitle.sitesOverview": "Übersicht über Standorte",
    "customerReport.tableTitle.mostPopularScriptedActivities": "Beliebteste skriptgesteuerte Aktivitäten",
    "customerReport.sitesOverviewTable.siteName": "Standortname",
    "customerReport.sitesOverviewTable.lastOnline": "Zuletzt online",
    "customerReport.sitesOverviewTable.totalUsers": "Benutzer",
    "customerReport.sitesOverviewTable.totalSessions": "Sitzungen",
    "customerReport.scriptedActivitiesTable.name": "Name",
    "customerReport.scriptedActivitiesTable.sessionsCount": "Sitzungen",
    "customerReport.chartExportDataField.date": "Datum",
    "customerReport.chartExportDataField.siteName": "Standortname",
    "customerReport.chartData.sessions": "Sitzungen",
    "customerReport.chartData.users": "Benutzer",
    "customerReport.chartTitle.sessionsWeeklyTrend": "Wöchentliche Sitzungstrends",
    "customerReport.chartTitle.newUsersWeeklyTrend": "Wöchentlicher Trend bei neuen Benutzern",
    "customerReport.chartTitle.usersAndSessionsBySite": "Benutzer und Sitzungen nach Standort",
    "customerReport.chartTitle.providerSummary": "Anbieterzusammenfassung",
    "customerReport.sessionsWeeklyTrend.xAxis": "Erster Tag der Woche",
    "customerReport.newUsersWeeklyTrend.xAxis": "Erster Tag der Woche",
    "customerReport.usersAndSessionsBySite.xAxis": "Standorte",
    "customerReport.providerSummary.xAxis": "Erster Tag der Woche",
    "customerReport.sessionsWeeklyTrend.yAxis": "Sitzungen",
    "customerReport.newUsersWeeklyTrend.yAxis": "Benutzer",
    "customerReport.usersAndSessionsBySite.yAxis": "Anzahl",
    "customerReport.providerSummary.yAxis": "Sitzungen",
    "siteReport.sessionsWeeklyTrend.xAxis": "Erster Tag der Woche",
    "siteReport.newUsersWeeklyTrend.xAxis": "Erster Tag der Woche",
    "siteReport.sessionsWeeklyTrend.yAxis": "Sitzungen",
    "siteReport.newUsersWeeklyTrend.yAxis": "Benutzer",
    "siteReport.overviewTitle.siteOverview": "Standortübersicht",
    "tags.table.name": "Name",
    "tags.fieldName.name": "Tag-Name",
    "tags.addModal.title": "Tag hinzufügen",
    "tags.editModal.title": "Tag bearbeiten",
    "categories.table.name": "Name",
    "categories.fieldName.name": "Kategorienname",
    "categories.addModal.title": "Kategorie hinzufügen",
    "categories.editModal.title": "Kategorie bearbeiten",
    "scriptedActivities.table.name": "Name",
    "scriptedActivities.table.category": "Kategorie",
    "scriptedActivities.table.tags": "Tags",
    "unitScriptedActivities.table.name": "Name",
    "unitScriptedActivities.table.isDeprecated": "Veraltet",

    "unitScriptedActivities.table.visible": "Sichtbar",
    "scriptedActivities.edit.title": "Skriptgesteuerte Aktivität bearbeiten",
    "scriptedActivities.edit.information": "Informationen",
    "scriptedActivities.edit.units": "Einheiten",
    "scriptedActivities.edit.steps": "Schritte",
    "scriptedActivity.addModal.title": "Skriptgesteuerte Aktivität hinzufügen",
    "scriptedActivity.fieldName.name": "Name",
    "scriptedActivity.fieldName.category": "Kategorie",
    "scriptedActivity.fieldName.tags": "Tags",
    "scriptedActivity.fieldName.summary": "Zusammenfassung",
    "scriptedActivitiesSteps.table.order": "Reihenfolge",
    "scriptedActivitiesSteps.table.name": "Name",
    "scriptedActivitiesSteps.table.baseActivity": "Basisaktivität",
    "activitySteps.addModal.stepInformation": "Schrittdetails",
    "activitySteps.addModal.settings": "Einstellungen",
    "activitySteps.addModal.title": "Schritt hinzufügen",
    "activitySteps.fieldName.name": "Name",
    "activitySteps.fieldName.order": "Reihenfolge",
    "scriptedActivity.fieldError.existingOrderNumber": "Reihenfolgennummer ist bereits vergeben",
    "activitySteps.fieldName.isSkipped": "Überspringen",
    "activitySteps.fieldName.activity": "Aktivität",
    "activitySteps.fieldName.instructions": "Anweisungen",
    "activitySteps.editModal.stepInformation": "Schrittdetails",
    "activitySteps.editModal.settings": "Einstellungen",
    "activitySteps.editModal.title": "Schritt bearbeiten",
    "scriptedActivityStep.deleteModal.title": "Schritt löschen",
    "scriptedActivityStep.deleteModal.description": "Sind Sie sicher, dass Sie diesen Schritt löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "userReport.section.userInformation": "Benutzerübersicht",
    "userReport.userInfo.name": "Name",
    "userReport.userInfo.totalTime": "Gesamte Zeit auf TRAZER",
    "userReport.userInfo.totalDistance": "Gesamte zurückgelegte Entfernung",
    "userReport.userInfo.totalCalories": "Gesamte verbrannte Kalorien",
    "userReport.userInfo.totalTargets": "Gesamte getroffene Ziele",
    "userReport.controls.dateRange": "Datumsbereich:",
    "userReport.controls.unitType": "Einheiten:",
    "userReport.chartExportDataField.date": "Datum",
    "userReport.chartData.distanceFeet": "Zurückgelegte Entfernung (ft)",
    "userReport.chartData.distanceMeters": "Zurückgelegte Entfernung (m)",
    "userReport.chartData.sessions": "Sitzungen",
    "userReport.chartData.calories": "Verbrannte Kalorien",
    "userReport.chartData.targets": "Getroffene Ziele",
    "userReport.chartTitle.distance": "Wöchentlicher Trend der zurückgelegten Entfernung",
    "userReport.chartTitle.sessions": "Wöchentlicher Trend der Sitzungen",
    "userReport.chartTitle.calories": "Wöchentlicher Trend der verbrannten Kalorien",
    "userReport.chartTitle.targets": "Wöchentlicher Trend der getroffenen Ziele",
    "userReport.distance.xAxis": "Erster Tag der Woche",
    "userReport.sessions.xAxis": "Erster Tag der Woche",
    "userReport.calories.xAxis": "Erster Tag der Woche",
    "userReport.targets.xAxis": "Erster Tag der Woche",
    "userReport.distance.yAxis": "Entfernung ({0})",
    "userReport.sessions.yAxis": "Sitzungen",
    "userReport.calories.yAxis": "Kalorien",
    "userReport.targets.yAxis": "Ziele",
    "userReport.section.weekly": "Wöchentlich",
    "userReport.section.history": "Verlauf",
    "userReport.section.stats": "Statistiken",
    "baseActivities.addModal.title": "Basisaktivität hinzufügen",
    "baseActivities.editModal.title": "Basisaktivität bearbeiten",
    "baseActivities.fieldName.name": "Name",
    "baseActivities.fieldName.type": "Typ",
    "userGroups.table.group": "Gruppe",
    "userGroups.table.customer": "Kunde",
    "userGroups.table.site": "Standort",
    "userGroups.editModal.title": "Namen ändern",
    "userGroups.fieldName.newName": "Neuer Name",
    "testReport.testNamePattern": "Test #{0}",
    "testReport.testNameBaseline": "Basislinie",
    "testReport.testNameDifference": "Unterschied",
    "testReport.kinematicRepetitionNamePattern": "Rep #{0}",
    "testReport.mobilityRepetitionNamePattern": "Rep #{0}",
    "testReport.cognitiveType": "Kognitive",
    "testReport.testInfo.testNumber": "Testnummer",

    "testReport.testInfo.username": "Benutzername",
    "testReport.testInfo.name": "Name",
    "testReport.testInfo.medicalRecordNumber": "Medizinische Aktennummer",
    "testReport.testInfo.provider": "Anbieter",
    "testReport.testInfo.date": "Datum",
    "testReport.testInfo.duration": "Dauer",
    "testReport.testInfo.location": "Ort",
    "testReport.testInfo.status": "Status",
    "testReport.testInfo.scriptedActivity": "Skriptgesteuerte Aktivität",
    "testReport.testNotes.createdBy": "Erstellt von",
    "testReport.testNotes.modifiedBy": "Zuletzt aktualisiert von",
    "testReport.testNotes.dateModified": "Zuletzt aktualisiert am",
    "testReport.compareTests.title": "Tests vergleichen",
    "testReport.compareTestsTable.baseline": "Basislinie",
    "testReport.compareTestsTable.date": "Datum",
    "testReport.compareTestsTable.status": "Status",
    "testReport.compareSteps.title": "Schritte vergleichen",
    "testReport.compareStepsTable.name": "Schrittnamen",
    "testReport.compareStepsTable.order": "Reihenfolge",
    "testReport.actions.previousTest": "Vorheriger Test",
    "testReport.actions.nextTest": "Nächster Test",
    "testReport.controls.step": "Schritt",
    "testReport.controls.unitType": "Einheiten",
    "testReport.section.stepSummary": "Schrittübersicht",
    "testReport.section.averages": "Durchschnittswerte",
    "testReport.section.sway": "Schwanken",
    "testReport.section.misses": "Fehler",
    "testReport.section.details": "Details",
    "testReport.section.timeline": "Zeitachse",
    "testReport.section.history": "Verlauf",
    "testReport.section.testInformation": "Testinformationen",
    "testReport.section.testNotes": "Testnotizen",
    "testReport.section.kinematicAverages": "Durchschnittswerte",
    "testReport.section.kinematicReps": "Wiederholungen",
    "testReport.section.mobilityReps": "Wiederholungen",
    "testReport.section.upperExtremityAverages": "Durchschnittswerte",
    "testReport.section.neurocognitive": "Neurokognitive",
    "testReport.section.totalDistance": "Entfernung",
    "testReport.section.decelerationIndex": "Verzögerungsindex",
    "testReport.section.reactionTime": "Reaktionszeit",
    "testReport.section.speed": "Geschwindigkeit",
    "testReport.section.acceleration": "Beschleunigung",
    "testReport.section.deceleration": "Verzögerung",
    "testReport.section.heartRate": "Herzfrequenz",
    "testReport.section.totalTargets": "Gesamtziele",
    "testReport.section.neck": "Nacken",
    "testReport.section.spine": "Wirbelsäule",
    "testReport.section.shoulderFlexion": "Schulterflexion",
    "testReport.section.shoulderAbduction": "Schulterabduktion",
    "testReport.section.elbowFlexion": "Ellbogenflexion",
    "testReport.section.torso": "Rumpf",
    "testReport.section.squatDepth": "Kniebeugung",
    "testReport.section.jumpHeight": "Sprunghöhe",
    "testReport.section.kneeFlexion": "Kniebeugung",
    "testReport.section.kneeFlexionValgusOrVarus": "Knie Valgus/Varus",
    "testReport.section.dorsiflexion": "Dorsalflexion",
    "testReport.section.timeline.xAxis": "Verstrichene Zeit (s)",
    "testReport.section.history.xAxis": "Datum",
    "testReport.section.timeline.reactionTime.yAxis": "Reaktionszeit (s)",
    "testReport.section.timeline.speed.yAxis": "Geschwindigkeit ({0})",
    "testReport.section.timeline.acceleration.yAxis": "Beschleunigung ({0})",
    "testReport.section.timeline.deceleration.yAxis": "Verzögerung ({0})",
    "testReport.section.timeline.heartRate.yAxis": "Herzfrequenz",
    "testReport.section.history.totalTargets.yAxis": "Gesamtziele",
    "testReport.section.history.sway.yAxis": "Gesamtes Schwanken ({0})",
    "testReport.section.history.misses.yAxis": "Fehler",
    "testReport.section.history.totalDistance.yAxis": "Gesamte Entfernung ({0})",
    "testReport.section.history.reactionTime.yAxis": "Reaktionszeit (s)",
    "testReport.section.history.speed.yAxis": "Geschwindigkeit ({0})",
    "testReport.section.history.acceleration.yAxis": "Beschleunigung ({0})",
    "testReport.section.history.deceleration.yAxis": "Verzögerung ({0})",
    "testReport.section.history.heartRate.yAxis": "Herzfrequenz (bpm)",
    "testReport.section.history.neck.yAxis": "Nacken (Grad \u00B0)",

    "testReport.section.history.spine.yAxis": "Wirbelsäule (Grad \u00B0)",
    "testReport.section.history.shoulderFlexion.yAxis": "Schulterflexion (Grad \u00B0)",
    "testReport.section.history.shoulderAbduction.yAxis": "Schulterabduktion (Grad \u00B0)",
    "testReport.section.history.elbowFlexion.yAxis": "Ellbogenflexion (Grad \u00B0)",
    "testReport.section.history.torso.yAxis": "Rumpf (Grad \u00B0)",
    "testReport.section.history.squatDepth.yAxis": "Kniebeugung ({0})",
    "testReport.section.history.jumpHeight.yAxis": "Sprunghöhe ({0})",
    "testReport.section.history.kneeFlexion.yAxis": "Kniebeugung (Grad \u00B0)",
    "testReport.section.history.kneeFlexionValgusOrVarus.yAxis": "Knie Valgus/Varus (Grad \u00B0)",
    "testReport.section.history.dorsiflexion.yAxis": "Dorsalflexion (Grad \u00B0)",
    "testReport.fieldName.start": "Start",
    "testReport.fieldName.min": "Min",
    "testReport.fieldName.average": "Durchschnitt",
    "testReport.fieldName.max": "Max",
    "testReport.fieldName.end": "Ende",
    "testReport.fieldName.forward": "Vorwärts",
    "testReport.fieldName.forwardRight": "Vorwärts rechts",
    "testReport.fieldName.right": "Rechts",
    "testReport.fieldName.backwardRight": "Rückwärts rechts",
    "testReport.fieldName.backward": "Rückwärts",
    "testReport.fieldName.backwardLeft": "Rückwärts links",
    "testReport.fieldName.left": "Links",
    "testReport.fieldName.forwardLeft": "Vorwärts links",
    "testReport.fieldName.lrDifference": "L/R Unterschied",
    "testReport.fieldName.flFRDifference": "FL/FR Unterschied",
    "testReport.fieldName.blBRDifference": "BL/BR Unterschied",
    "testReport.fieldName.totalSway": "Gesamtes Schwanken",
    "testReport.fieldName.duration": "Dauer",
    "testReport.fieldName.targets": "Ziele",
    "testReport.fieldName.score": "Punktzahl",
    "testReport.fieldName.totalDistance": "Gesamte Entfernung",
    "testReport.fieldName.decelerationIndex": "Verzögerungsindex",
    "testReport.fieldName.calories": "Kalorien",
    "testReport.fieldName.averageHR": "Durchschnittl. HF",
    "testReport.fieldName.maxHR": "Maximale HF",
    "testReport.fieldName.averageJumpHeight": "Durchschnittl. Sprunghöhe",
    "testReport.fieldName.maxJumpHeight": "Maximale Sprunghöhe",
    "testReport.fieldName.averageSquatDepth": "Durchschnittl. Kniebeugung",
    "testReport.fieldName.maxSquatDepth": "Maximale Kniebeugung",
    "testReport.fieldName.correct": "Korrekt",
    "testReport.fieldName.incorrect": "Inkorrekt",
    "testReport.fieldName.total": "Gesamt",
    "testReport.fieldName.stanceWidthRatio": "Standbreitenverhältnis",
    "testReport.fieldName.plantFoot": "Stützfuß",
    "testReport.fieldName.stanceWidthDistance": "Standbreitendistanz",
    "testReport.fieldName.abduction": "Schulterabduktion",
    "testReport.fieldName.shoulderFlexion": "Schulterflexion",
    "testReport.fieldName.elbowFlexion": "Ellbogenflexion",
    "testReport.fieldName.flexion": "Flexion",
    "testReport.fieldName.dorsiflexion": "Dorsalflexion",
    "testReport.fieldName.trunkLean": "Rumpfneigung",
    "testReport.fieldName.pelvicTilt": "Beckenneigung",
    "testReport.fieldName.jumpHeight": "Sprunghöhe",
    "testReport.fieldName.squatDepth": "Kniebeugung",
    "testReport.fieldName.rightKneeValgusOrVarus": "Rechtes Knie Valgus/Varus",
    "testReport.fieldName.rightKneeFlexion": "Rechte Kniebeugung",
    "testReport.fieldName.rightAnkleDorsiflexion": "Rechte Knöcheldorsalflexion",
    "testReport.fieldName.rightDorsiflexion": "Rechte Dorsalflexion",
    "testReport.fieldName.leftKneeValgusOrVarus": "Linkes Knie Valgus/Varus",
    "testReport.fieldName.leftKneeFlexion": "Linke Kniebeugung",
    "testReport.fieldName.leftAnkleDorsiflexion": "Linke Knöcheldorsalflexion",
    "testReport.fieldName.leftDorsiflexion": "Linke Dorsalflexion",
    "testReport.fieldName.neckRotation": "Nackenrotation",
    "testReport.fieldName.spineFlexion": "Wirbelsäulenflexion",
    "testReport.fieldName.spineRotation": "Wirbelsäulendrehung",
    "testReport.fieldName.leftShoulderFlexion": "Linke Schulterflexion",

    "testReport.fieldName.rightShoulderFlexion": "Rechte Schulterflexion",
    "testReport.fieldName.leftShoulderAbduction": "Linke Schulterabduktion",
    "testReport.fieldName.rightShoulderAbduction": "Rechte Schulterabduktion",
    "testReport.fieldName.leftElbowFlexion": "Linke Ellenbogenflexion",
    "testReport.fieldName.rightElbowFlexion": "Rechte Ellenbogenflexion",
    "testReport.fieldName.totalTargetsHit": "Gesamtziele",
    "testReport.fieldName.targetsMissed": "Gesamtziele verfehlt",
    "testReport.fieldName.targetsHitLeft180": "Überkopfziele links 180\u00B0",
    "testReport.fieldName.targetsHitLeft45": "Seitliche Ziele links 45\u00B0",
    "testReport.fieldName.targetsHitLeft90": "Seitliche Ziele links 90\u00B0",
    "testReport.fieldName.targetsHitLeft135": "Seitliche Ziele links 135\u00B0",
    "testReport.fieldName.targetsHitForwardLeft45": "Vorwärtsseitliche Ziele links 45\u00B0",
    "testReport.fieldName.targetsHitForwardLeft90": "Vorwärtsseitliche Ziele links 90\u00B0",
    "testReport.fieldName.targetsHitForwardLeft135": "Vorwärtsseitliche Ziele links 135\u00B0",
    "testReport.fieldName.targetsHitRight180": "Überkopfziele rechts 180\u00B0",
    "testReport.fieldName.targetsHitRight45": "Seitliche Ziele rechts 45\u00B0",
    "testReport.fieldName.targetsHitRight90": "Seitliche Ziele rechts 90\u00B0",
    "testReport.fieldName.targetsHitRight135": "Seitliche Ziele rechts 135\u00B0",
    "testReport.fieldName.targetsHitForwardRight45": "Vorwärtsseitliche Ziele rechts 45\u00B0",
    "testReport.fieldName.targetsHitForwardRight90": "Vorwärtsseitliche Ziele rechts 90\u00B0",
    "testReport.fieldName.targetsHitForwardRight135": "Vorwärtsseitliche Ziele rechts 135\u00B0",
    "testReport.fieldName.lrTargetsHitDiff": "L/R Zielunterschied",
    "testReport.fieldName.reactionTimeAvg": "Durchschnittliche Reaktionszeit",
    "testReport.fieldName.reactionTimeLeft": "Linke Reaktionszeit",
    "testReport.fieldName.reactionTimeRight": "Rechte Reaktionszeit",
    "testReport.fieldName.lrReactionTimeDiff": "L/R Reaktionszeitunterschied",
    "testReport.fieldName.leftCongruent": "Linke Kongruent",
    "testReport.fieldName.rightCongruent": "Rechte Kongruent",
    "testReport.fieldName.leftIncongruent": "Linke Inkongruent",
    "testReport.fieldName.rightIncongruent": "Rechte Inkongruent",
    "testReport.fieldName.totalTargets": "Gesamtziele",
    "testReport.fieldName.misses": "Verfehlungen",
    "testReport.fieldName.averageReactionTime": "Durchschnittliche Reaktionszeit",
    "testReport.fieldName.avgReactionTime": "Durchschnittliche Reaktionszeit",
    "testReport.fieldName.averageAcceleration": "Durchschnittliche Beschleunigung",
    "testReport.fieldName.averageDeceleration": "Durchschnittliche Verzögerung",
    "testReport.fieldName.averageSpeed": "Durchschnittliche Geschwindigkeit",
    "testReport.fieldName.averageHeartRate": "Durchschnittliche Herzfrequenz",
    "testReport.fieldName.totalDistanceAvg": "Durchschnittliche Gesamtdistanz",
    "testReport.fieldName.maxAcceleration": "Maximale Beschleunigung",
    "testReport.fieldName.maxDeceleration": "Maximale Verzögerung",
    "testReport.fieldName.maxSpeed": "Maximale Geschwindigkeit",
    "testReport.fieldName.minReactionTime": "Minimale Reaktionszeit",
    "testReport.fieldName.minSquatDepth": "Minimale Kniebeugung",
    "testReport.fieldName.minDistance": "Minimale Distanz",
    "testReport.fieldName.startingHR": "Anfangs-Herzfrequenz",
    "testReport.averages.reactionTime": "Reaktionszeit",
    "testReport.averages.speed": "Geschwindigkeit",
    "testReport.averages.acceleration": "Beschleunigung",
    "testReport.averages.deceleration": "Verzögerung",
    "testReport.neurocognitive.promptTime": "Promptzeit",
    "testReport.neurocognitive.scores": "Werte",
    "testReport.neurocognitive.averageTimeToTarget": "Durchschnittliche Zeit zum Ziel",
    "testReport.kinematic.left": "Links",
    "testReport.kinematic.right": "Rechts",
    "testReport.print.stepDisclaimer": "*Die Reaktionszeiten in dieser Aktivität dienen nur Vergleichszwecken.",
    "testReport.print.selectOptions": "Bereiche zum Drucken auswählen",
    "testReport.print.testInfo": "Testinformation",
    "testReport.print.testSummary": "Testzusammenfassung",

    "testReport.print.testDetails": "Testdetails",
    "testReport.print.testTimeline": "Zeitstrahl",
    "testReport.print.testHistory": "Verlauf",
    "testReport.print.testNotes": "Testnotizen",
    "testReport.print.errorMessage": "Bitte wählen Sie mindestens eine Option aus!",
    "testReport.chartTooltip.test": "Test",
    "library.noMatchingActivities": "Keine passenden Aktivitäten gefunden",
    "analytics.filters.customer": "Kunde",
    "analytics.filters.site": "Standort",
    "analytics.filters.scriptedActivity": "Skriptgesteuerte Aktivität",
    "analytics.filters.dateRange": "Datumsbereich",
    "analytics.filters.gender": "Geschlecht",
    "analytics.filters.rangeTemplate": "bis",
    "analytics.filters.heightInches": "Größe (Zoll)",
    "analytics.filters.weightLbs": "Gewicht (lbs)",
    "analytics.filters.ageYears": "Alter (Jahre)",
    "analytics.filters.userGroup": "Benutzergruppe",
    "analytics.filters.sport": "Sport",
    "analytics.filters.position": "Position",
    "analytics.filters.injury": "Verletzungsort",
    "analytics.filters.injuryType": "Verletzungsart",
    "analytics.filters.dominance": "Dominanz",
    "analytics.filters.excludeSectionLabel": "Ausschließen",
    "analytics.filters.partials": "Teilweise",
    "analytics.filters.testUsers": "Testbenutzer",
    "analytics.subTabs.graphs": "Diagramme",
    "analytics.subTabs.table": "Tabelle",
    "analyticsTestsTab.table.scriptedActivity": "Skriptgesteuerte Aktivität",
    "analyticsTestsTab.table.name": "Name",
    "analyticsTestsTab.table.username": "Benutzername",
    "analyticsTestsTab.table.date": "Datum",
    "analyticsTestsTab.table.status": "Status",
    "analyticsTestsTab.controls.unitType": "Einheiten",
    "analyticsSearchResultLayout.tabs.reactionTime": "Reaktionszeit",
    "analyticsSearchResultLayout.tabs.speed": "Geschwindigkeit",
    "analyticsSearchResultLayout.tabs.tests": "Tests",
    "analyticsSearchResultLayout.tabs.acceleration": "Beschleunigung",
    "analyticsSearchResultLayout.tabs.deceleration": "Verzögerung",
    "analyticsSearchResultLayout.tabs.totalDistance": "Gesamtdistanz",
    "analyticsSearchResultLayout.tabs.calories": "Kalorien",
    "analyticsSearchResultLayout.tabs.cognitive": "Kognitiv",
    "analyticsSearchResultLayout.tabs.sways": "Schwankungen",
    "analyticsSearchResultLayout.tabs.misses": "Fehler",
    "analyticsSearchResultLayout.tabs.kinematics": "Kinematik",
    "analytics.table.fullName": "Vollständiger Name",
    "analytics.table.username": "Benutzername",
    "analytics.table.scriptedActivity": "Skriptgesteuerte Aktivität",
    "analytics.table.avg": "Durchschnitt",
    "analytics.table.forward": "Vorwärts",
    "analytics.table.forwardRight": "Vorwärts rechts",
    "analytics.table.right": "Rechts",
    "analytics.table.backwardRight": "Rückwärts rechts",
    "analytics.table.backward": "Rückwärts",
    "analytics.table.backwardLeft": "Rückwärts links",
    "analytics.table.left": "Links",
    "analytics.table.forwardLeft": "Vorwärts links",
    "analytics.table.lrDifference": "Links/Rechts Unterschied",
    "analytics.table.flFRDifference": "VL/VR Unterschied",
    "analytics.table.blBRDifference": "HL/HR Unterschied",
    "analytics.table.date": "Datum",
    "analytics.table.totalDistance": "Gesamtdistanz",
    "analytics.table.calories": "Kalorien",
    "analytics.table.promptTime": "Aufforderungszeit",
    "analytics.table.congruentCorrectLeft": "Kongruent korrekt links",
    "analytics.table.averageTimeCongruentCorrectLeft": "Durchschnittliche Zeit kongruent korrekt links",
    "analytics.table.congruentIncorrectLeft": "Kongruent inkorrekt links",
    "analytics.table.averageTimeCongruentIncorrectLeft": "Durchschnittliche Zeit kongruent inkorrekt links",
    "analytics.table.incongruentCorrectLeft": "Inkongruent korrekt links",
    "analytics.table.averageTimeIncongruentCorrectLeft": "Durchschnittliche Zeit inkongruent korrekt links",
    "analytics.table.incongruentIncorrectLeft": "Inkongruent inkorrekt links",
    "analytics.table.averageTimeIncongruentIncorrectLeft": "Durchschnittliche Zeit inkongruent inkorrekt links",
    "analytics.table.congruentCorrectRight": "Kongruent korrekt rechts",
    "analytics.table.averageTimeCongruentCorrectRight": "Durchschnittliche Zeit kongruent korrekt rechts",

    "analytics.table.congruentIncorrectRight": "Kongruent inkorrekt rechts",
    "analytics.table.averageTimeCongruentIncorrectRight": "Durchschnittliche Zeit kongruent inkorrekt rechts",
    "analytics.table.incongruentCorrectRight": "Inkongruent korrekt rechts",
    "analytics.table.averageTimeIncongruentCorrectRight": "Durchschnittliche Zeit inkongruent korrekt rechts",
    "analytics.table.incongruentIncorrectRight": "Inkongruent inkorrekt rechts",
    "analytics.table.averageTimeIncongruentIncorrectRight": "Durchschnittliche Zeit inkongruent inkorrekt rechts",
    "analytics.table.totalSway": "Gesamtschwankung",
    "analytics.table.totalMisses": "Gesamtfehler",
    "analytics.table.plantFoot": "Aufsetzfuß",
    "analytics.table.stanceWidthRatio": "Beinabstand-Verhältnis",
    "analytics.table.stanceWidthDistance": "Beinabstand-Distanz",
    "analytics.table.trunkLean": "Rumpfbeugung",
    "analytics.table.pelvicTilt": "Beckenneigung",
    "analytics.table.rightKneeValgusVarus": "Rechtes Knie Valgus/Varus",
    "analytics.table.rightKneeFlexion": "Rechtes Kniebeugen",
    "analytics.table.rightAnkleDorsiflexion": "Rechte Knöchel Dorsalflexion",
    "analytics.table.leftKneeValgusVarus": "Linkes Knie Valgus/Varus",
    "analytics.table.leftKneeFlexion": "Linkes Kniebeugen",
    "analytics.table.leftAnkleDorsiflexion": "Linke Knöchel Dorsalflexion",
    "analytics.table.neckRotation": "Halsrotation",
    "analytics.table.spineFlexion": "Wirbelsäulenbeugung",
    "analytics.table.spineRotation": "Wirbelsäulenrotation",
    "analytics.table.leftShoulderFlexion": "Linke Schulterbeugung",
    "analytics.table.rightShoulderFlexion": "Rechte Schulterbeugung",
    "analytics.table.leftShoulderAbduction": "Linke Schulterabduktion",
    "analytics.table.rightShoulderAbduction": "Rechte Schulterabduktion",
    "analytics.table.leftElbowFlexion": "Linke Ellenbogenbeugung",
    "analytics.table.rightElbowFlexion": "Rechte Ellenbogenbeugung",
    "analytics.export.testStartTime": "Testdatum",
    "analytics.export.fullName": "Vollständiger Name",
    "analytics.export.username": "Benutzername",
    "analytics.export.scriptedActivity": "Skriptgesteuerte Aktivität",
    "analytics.export.testStepDate": "Testschrittdatum",
    "analytics.export.scriptedActivityStep": "Skriptgesteuerter Aktivitätsschritt",
    "analytics.export.stepNumber": "Schritt Nummer",
    "analytics.export.status": "Status",
    "analytics.export.duration": "Dauer",
    "analytics.export.targetsHit": "Getroffene Ziele",
    "analytics.export.reactionTimeAverage": "Durchschnittliche Reaktionszeit (s)",
    "analytics.export.reactionTimeForward": "Reaktionszeit Vorwärts (s)",
    "analytics.export.reactionTimeForwardRight": "Reaktionszeit Vorwärts Rechts (s)",
    "analytics.export.reactionTimeRight": "Reaktionszeit Rechts (s)",
    "analytics.export.reactionTimeBackwardRight": "Reaktionszeit Rückwärts Rechts (s)",
    "analytics.export.reactionTimeBackward": "Reaktionszeit Rückwärts (s)",
    "analytics.export.reactionTimeBackwardLeft": "Reaktionszeit Rückwärts Links (s)",
    "analytics.export.reactionTimeLeft": "Reaktionszeit Links (s)",
    "analytics.export.reactionTimeForwardLeft": "Reaktionszeit Vorwärts Links (s)",
    "analytics.export.reactionTimeLRDifference": "Reaktionszeit L/R Unterschied",
    "analytics.export.reactionTimeFLFRDifference": "Reaktionszeit VL/VR Unterschied",
    "analytics.export.reactionTimeBLBRDifference": "Reaktionszeit HL/HR Unterschied",
    "analytics.export.speedAverage": "Durchschnittliche Geschwindigkeit ({0})",
    "analytics.export.speedForward": "Geschwindigkeit Vorwärts ({0})",
    "analytics.export.speedForwardRight": "Geschwindigkeit Vorwärts Rechts ({0})",
    "analytics.export.speedRight": "Geschwindigkeit Rechts ({0})",
    "analytics.export.speedBackwardRight": "Geschwindigkeit Rückwärts Rechts ({0})",
    "analytics.export.speedBackward": "Geschwindigkeit Rückwärts ({0})",
    "analytics.export.speedBackwardLeft": "Geschwindigkeit Rückwärts Links ({0})",
    "analytics.export.speedLeft": "Geschwindigkeit Links ({0})",
    "analytics.export.speedForwardLeft": "Geschwindigkeit Vorwärts Links ({0})",
    "analytics.export.speedLRDifference": "Geschwindigkeitsunterschied L/R",

    "analytics.export.speedFLFRDifference": "Geschwindigkeitsunterschied FL/FR",
    "analytics.export.speedBLBRDifference": "Geschwindigkeitsunterschied BL/BR",
    "analytics.export.accelerationAverage": "Durchschnittliche Beschleunigung ({0})",
    "analytics.export.accelerationForward": "Beschleunigung Vorwärts ({0})",
    "analytics.export.accelerationForwardRight": "Beschleunigung Vorwärts Rechts ({0})",
    "analytics.export.accelerationRight": "Beschleunigung Rechts ({0})",
    "analytics.export.accelerationBackwardRight": "Beschleunigung Rückwärts Rechts ({0})",
    "analytics.export.accelerationBackward": "Beschleunigung Rückwärts ({0})",
    "analytics.export.accelerationBackwardLeft": "Beschleunigung Rückwärts Links ({0})",
    "analytics.export.accelerationLeft": "Beschleunigung Links ({0})",
    "analytics.export.accelerationForwardLeft": "Beschleunigung Vorwärts Links ({0})",
    "analytics.export.accelerationLRDifference": "Beschleunigungsunterschied L/R",
    "analytics.export.accelerationFLFRDifference": "Beschleunigungsunterschied FL/FR",
    "analytics.export.accelerationBLBRDifference": "Beschleunigungsunterschied BL/BR",
    "analytics.export.decelerationAverage": "Durchschnittliche Verzögerung ({0})",
    "analytics.export.decelerationForward": "Verzögerung Vorwärts ({0})",
    "analytics.export.decelerationForwardRight": "Verzögerung Vorwärts Rechts ({0})",
    "analytics.export.decelerationRight": "Verzögerung Rechts ({0})",
    "analytics.export.decelerationBackwardRight": "Verzögerung Rückwärts Rechts ({0})",
    "analytics.export.decelerationBackward": "Verzögerung Rückwärts ({0})",
    "analytics.export.decelerationBackwardLeft": "Verzögerung Rückwärts Links ({0})",
    "analytics.export.decelerationLeft": "Verzögerung Links ({0})",
    "analytics.export.decelerationForwardLeft": "Verzögerung Vorwärts Links ({0})",
    "analytics.export.decelerationLRDifference": "Verzögerungsunterschied L/R",
    "analytics.export.decelerationFLFRDifference": "Verzögerungsunterschied FL/FR",
    "analytics.export.decelerationBLBRDifference": "Verzögerungsunterschied BL/BR",
    "analytics.export.totalDistance": "Gesamtdistanz ({0})",
    "analytics.export.calories": "Kalorien",
    "analytics.export.promptTime": "Aufforderungszeit (ms)",
    "analytics.export.congruentCorrectLeft": "Kongruent korrekt links",
    "analytics.export.averageTimeCongruentCorrectLeft": "Durchschnittliche Zeit kongruent korrekt links (s)",
    "analytics.export.congruentIncorrectLeft": "Kongruent inkorrekt links",
    "analytics.export.averageTimeCongruentIncorrectLeft": "Durchschnittliche Zeit kongruent inkorrekt links (s)",
    "analytics.export.incongruentCorrectLeft": "Inkongruent korrekt links",
    "analytics.export.averageTimeIncongruentCorrectLeft": "Durchschnittliche Zeit inkongruent korrekt links (s)",
    "analytics.export.incongruentIncorrectLeft": "Inkongruent inkorrekt links",
    "analytics.export.averageTimeIncongruentIncorrectLeft": "Durchschnittliche Zeit inkongruent inkorrekt links (s)",
    "analytics.export.congruentCorrectRight": "Kongruent korrekt rechts",
    "analytics.export.averageTimeCongruentCorrectRight": "Durchschnittliche Zeit kongruent korrekt rechts (s)",
    "analytics.export.congruentIncorrectRight": "Kongruent inkorrekt rechts",
    "analytics.export.averageTimeCongruentIncorrectRight": "Durchschnittliche Zeit kongruent inkorrekt rechts (s)",
    "analytics.export.incongruentCorrectRight": "Inkongruent korrekt rechts",
    "analytics.export.averageTimeIncongruentCorrectRight": "Durchschnittliche Zeit inkongruent korrekt rechts (s)",
    "analytics.export.incongruentIncorrectRight": "Inkongruent inkorrekt rechts",
    "analytics.export.averageTimeIncongruentIncorrectRight": "Durchschnittliche Zeit inkongruent inkorrekt rechts (s)",
    "analytics.export.totalSway": "Gesamtschwankung ({0})",
    "analytics.export.swayForward": "Schwankung Vorwärts ({0})",
    "analytics.export.swayForwardRight": "Schwankung Vorwärts Rechts ({0})",
    "analytics.export.swayRight": "Schwankung Rechts ({0})",
    "analytics.export.swayBackwardRight": "Schwankung Rückwärts Rechts ({0})",

    "analytics.export.swayBackward": "Schwankung Rückwärts ({0})",
    "analytics.export.swayBackwardLeft": "Schwankung Rückwärts Links ({0})",
    "analytics.export.swayLeft": "Schwankung Links ({0})",
    "analytics.export.swayForwardLeft": "Schwankung Vorwärts Links ({0})",
    "analytics.export.totalMisses": "Gesamtverfehlungen",
    "analytics.export.neckRotation": "Nackendrehung",
    "analytics.export.spineFlexion": "Wirbelsäulenbeugung",
    "analytics.export.spineRotation": "Wirbelsäulenrotation",
    "analytics.export.leftShoulderFlexion": "Linke Schulterbeugung",
    "analytics.export.rightShoulderFlexion": "Rechte Schulterbeugung",
    "analytics.export.leftShoulderAbduction": "Linke Schulterabduktion",
    "analytics.export.rightShoulderAbduction": "Rechte Schulterabduktion",
    "analytics.export.leftElbowFlexion": "Linke Ellenbogenbeugung",
    "analytics.export.rightElbowFlexion": "Rechte Ellenbogenbeugung",
    "analytics.export.plantFoot": "Stützfuß",
    "analytics.export.stanceWidthRatio": "Stehbreitenverhältnis",
    "analytics.export.stanceWidthDistance": "Stehbreitendistanz ({0})",
    "analytics.export.trunkLean": "Rumpfbeugung",
    "analytics.export.pelvicTilt": "Beckenneigung",
    "analytics.export.rightKneeValgusVarus": "Rechtes Knie Valgus/Varus",
    "analytics.export.rightKneeFlexion": "Rechte Kniebeugung",
    "analytics.export.rightAnkleDorsiflexion": "Rechte Fußgelenkdorsalextension",
    "analytics.export.leftKneeValgusVarus": "Linkes Knie Valgus/Varus",
    "analytics.export.leftKneeFlexion": "Linke Kniebeugung",
    "analytics.export.leftAnkleDorsiflexion": "Linke Fußgelenkdorsalextension",
    "analytics.export.repNumber": "Wiederholungsnummer",
    "analytics.export.reactionTimeAvg": "Durchschnittliche Reaktionszeit (s)",
    "analytics.export.reactionTimeMin": "Minimale Reaktionszeit (s)",
    "analytics.export.speedAvg": "Durchschnittliche Geschwindigkeit ({0})",
    "analytics.export.speedMax": "Maximale Geschwindigkeit ({0})",
    "analytics.export.accelerationAvg": "Durchschnittliche Beschleunigung ({0})",
    "analytics.export.accelerationMax": "Maximale Beschleunigung ({0})",
    "analytics.export.decelerationAvg": "Durchschnittliche Verzögerung ({0})",
    "analytics.export.decelerationMax": "Maximale Verzögerung ({0})",
    "analytics.export.jumpHeightAvg": "Durchschnittliche Sprunghöhe ({0})",
    "analytics.export.jumpHeightMax": "Maximale Sprunghöhe ({0})",
    "analytics.export.squatHeightAvg": "Durchschnittliche Kniebeugungstiefe ({0})",
    "analytics.export.squatHeightMin": "Minimale Kniebeugungstiefe ({0})",
    "analytics.export.minimumDistance": "Minimale Entfernung ({0})",
    "analytics.chartData.forward": "Vorwärts",
    "analytics.chartData.forwardRight": "Vorwärts Rechts",
    "analytics.chartData.right": "Rechts",
    "analytics.chartData.backwardRight": "Rückwärts Rechts",
    "analytics.chartData.backward": "Rückwärts",
    "analytics.chartData.backwardLeft": "Rückwärts Links",
    "analytics.chartData.left": "Links",
    "analytics.chartData.forwardLeft": "Vorwärts Links",
    "analytics.chartExportDataField.date": "Datum",
    "analytics.chartExportDataField.direction": "Richtung",
    "analytics.charts.reactionTimeTrend": "Trend der Reaktionszeit",
    "analytics.charts.reactionTimeDirectionalAverage": "Durchschnittliche Richtungsreaktionszeit",
    "analytics.charts.reactionTimeDistribution": "Verteilung der Reaktionszeit",
    "analytics.charts.speedTrend": "Geschwindigkeitstrend",
    "analytics.charts.speedDirectionalAverage": "Durchschnittliche Richtungsgeschwindigkeit",
    "analytics.charts.speedDistribution": "Geschwindigkeitsverteilung",
    "analytics.charts.accelerationTrend": "Beschleunigungstrend",
    "analytics.charts.accelerationDirectionalAverage": "Durchschnittliche Richtungsbeschleunigung",
    "analytics.charts.accelerationDistribution": "Beschleunigungsverteilung",
    "analytics.charts.decelerationTrend": "Verzögerungstrend",
    "analytics.charts.decelerationDirectionalAverage": "Durchschnittliche Richtungsverzögerung",
    "analytics.charts.decelerationDistribution": "Verzögerungsverteilung",
    "analytics.charts.totalDistanceTrend": "Gesamtstreckentrend",
    "analytics.charts.totalDistanceDistribution": "Gesamtstreckenverteilung",

    "analytics.charts.caloriesTrend": "Kalorientrend",
    "analytics.charts.caloriesDistribution": "Kalorienverteilung",
    "analytics.charts.cognitiveTrend": "Kognitiver Trend",
    "analytics.charts.cognitiveDistribution": "Kognitive Verteilung",
    "analytics.charts.swayTrend": "Schwankungstrend",
    "analytics.charts.swayDirectionalAverage": "Durchschnittliche Schwankung",
    "analytics.charts.swayDistribution": "Schwankungsverteilung",
    "analytics.charts.missesTrend": "Fehlertrend",
    "analytics.charts.missesDistribution": "Fehlerverteilung",
    "analytics.charts.kinematicsNeckRotationDistribution": "Halsrotation Verteilung",
    "analytics.charts.kinematicsNeckRotationTrend": "Halsrotationstrend",
    "analytics.charts.kinematicsSpineFlexionDistribution": "Wirbelsäulenbeugung Verteilung",
    "analytics.charts.kinematicsSpineFlexionTrend": "Wirbelsäulenbeugungstrend",
    "analytics.charts.kinematicsSpineRotationDistribution": "Wirbelsäulenrotation Verteilung",
    "analytics.charts.kinematicsSpineRotationTrend": "Wirbelsäulenrotationstrend",
    "analytics.charts.kinematicsLeftShoulderFlexionDistribution": "Linker Schulterbeugung Verteilung",
    "analytics.charts.kinematicsLeftShoulderFlexionTrend": "Linker Schulterbeugungstrend",
    "analytics.charts.kinematicsRightShoulderFlexionDistribution": "Rechter Schulterbeugung Verteilung",
    "analytics.charts.kinematicsRightShoulderFlexionTrend": "Rechter Schulterbeugungstrend",
    "analytics.charts.kinematicsLeftShoulderAbductionDistribution": "Linker Schulterabduktion Verteilung",
    "analytics.charts.kinematicsLeftShoulderAbductionTrend": "Linker Schulterabduktionstrend",
    "analytics.charts.kinematicsRightShoulderAbductionDistribution": "Rechter Schulterabduktion Verteilung",
    "analytics.charts.kinematicsRightShoulderAbductionTrend": "Rechter Schulterabduktionstrend",
    "analytics.charts.kinematicsLeftElbowFlexionDistribution": "Linker Ellbogenbeugung Verteilung",
    "analytics.charts.kinematicsLeftElbowFlexionTrend": "Linker Ellbogenbeugungstrend",
    "analytics.charts.kinematicsRightElbowFlexionDistribution": "Rechter Ellbogenbeugung Verteilung",
    "analytics.charts.kinematicsRightElbowFlexionTrend": "Rechter Ellbogenbeugungstrend",
    "analytics.charts.kinematicsTrunkLeanDistribution": "Rumpfbeugung Verteilung",
    "analytics.charts.kinematicsTrunkLeanTrend": "Rumpfbeugungstrend",
    "analytics.charts.kinematicsPelvicTiltDistribution": "Beckenkippen Verteilung",
    "analytics.charts.kinematicsPelvicTiltTrend": "Beckenkippenstrend",
    "analytics.charts.kinematicsLeftKneeValgusVarusDistribution": "Linker Knie Valgus/Varus Verteilung",
    "analytics.charts.kinematicsLeftKneeValgusVarusTrend": "Linker Knie Valgus/Varus Trend",
    "analytics.charts.kinematicsRightKneeValgusVarusDistribution": "Rechter Knie Valgus/Varus Verteilung",
    "analytics.charts.kinematicsRightKneeValgusVarusTrend": "Rechter Knie Valgus/Varus Trend",
    "analytics.charts.kinematicsLeftKneeFlexionDistribution": "Linker Kniebeugung Verteilung",
    "analytics.charts.kinematicsLeftKneeFlexionTrend": "Linker Kniebeugungstrend",
    "analytics.charts.kinematicsRightKneeFlexionDistribution": "Rechter Kniebeugung Verteilung",
    "analytics.charts.kinematicsRightKneeFlexionTrend": "Rechter Kniebeugungstrend",
    "analytics.charts.kinematicsLeftAnkleDorsiflexionDistribution": "Linker Fußknöchel Dorsalflexion Verteilung",
    "analytics.charts.kinematicsLeftAnkleDorsiflexionTrend": "Linker Fußknöchel Dorsalflexionstrend",
    "analytics.charts.kinematicsRightAnkleDorsiflexionDistribution": "Rechter Fußknöchel Dorsalflexion Verteilung",
    "analytics.charts.kinematicsRightAnkleDorsiflexionTrend": "Rechter Fußknöchel Dorsalflexionstrend",
    "analytics.charts.reactionTimeTrend.yAxis": "Reaktionszeit Durchschnitt (s)",
    "analytics.charts.reactionTimeDistribution.xAxis": "Reaktionszeit Durchschnitt (s)",
    "analytics.charts.speedTrend.yAxis": "Durchschnittsgeschwindigkeit ({0})",
    "analytics.charts.speedDistribution.xAxis": "Durchschnittsgeschwindigkeit ({0})",

    "analytics.charts.accelerationTrend.yAxis": "Durchschnittsbeschleunigung ({0})",
    "analytics.charts.accelerationDistribution.xAxis": "Durchschnittsbeschleunigung ({0})",
    "analytics.charts.decelerationTrend.yAxis": "Durchschnittsverzögerung ({0})",
    "analytics.charts.decelerationDistribution.xAxis": "Durchschnittsverzögerung ({0})",
    "analytics.charts.totalDistanceTrend.yAxis": "Strecke ({0})",
    "analytics.charts.totalDistanceDistribution.xAxis": "Strecke ({0})",
    "analytics.charts.caloriesTrend.yAxis": "Kalorien",
    "analytics.charts.caloriesDistribution.xAxis": "Kalorien",
    "analytics.charts.cognitiveTrend.yAxis": "Richtig %",
    "analytics.charts.cognitiveDistribution.xAxis": "Richtig %",
    "analytics.charts.swayTrend.yAxis": "Schwanken ({0})",
    "analytics.charts.swayDistribution.xAxis": "Schwanken ({0})",
    "analytics.charts.missesTrend.yAxis": "Fehler",
    "analytics.charts.missesDistribution.xAxis": "Fehler",
    "analytics.charts.xAxis": "Datum",
    "overview.section.monthlySessionsTrend": "Monatlicher Sitzungstrend",
    "overview.section.sessions": "Sitzungen",
    "overview.section.monthlyUsersTrend": "Monatlicher Neuer-Benutzer-Trend",
    "overview.section.users": "Benutzer",
    "overview.section.units": "Einheiten",
    "overview.section.date": "Monat-Jahr",
    "overview.section.recentlyAddedUsers": "Kürzlich hinzugefügte Benutzer",
    "overview.section.recentSessions": "Aktuelle Sitzungen",
    "overview.chartExportDataField.date": "Datum",
    "footer.privacyPolicy": "Datenschutzrichtlinie",
    "footer.protectedBy": "Geschützt durch US-Patent Nr.",
    "footer.TrazerInc": "© {0} - Trazer Inc. Alle Rechte vorbehalten.",
    "privacyPolicy.title": "Datenschutzrichtlinie des TRAZER-Portals",
    "privacyPolicy.lastUpdated": "Zuletzt aktualisiert",
    "privacyPolicy.section1": "Einleitung",
    "privacyPolicy.intro": "Diese Richtlinie erläutert, wie Trazer Inc. Daten im TRAZER-Portal erhebt, verwendet, speichert und schützt. Wir verpflichten uns, die Privatsphäre unserer Kunden und ihrer Benutzer (Athleten, Klienten, Patienten, Bewohner und/oder jegliche Personen, die in der Obhut unserer Kunden stehen) zu schützen und die Sicherheit der Daten zu gewährleisten. Durch die Nutzung unserer Dienste und die Interaktion mit unseren Datenbanken erklären Sie sich mit den in dieser Richtlinie beschriebenen Praktiken einverstanden.",
    "privacyPolicy.section2": "Datenerfassung",
    "privacyPolicy.section2.info": "Wir erfassen und speichern Daten in unseren Datenbanken auf folgende Weise:",
    "privacyPolicy.section2.subsection2": "Daten, die während der Interaktion mit dem TRAZER-System automatisch generiert oder erfasst werden.",
    "privacyPolicy.section2.subsection3": "Daten, die gemäß geltenden Gesetzen von Dritten beschafft werden.",
    "privacyPolicy.section3": "Datenverwendung",
    "privacyPolicy.section3.info": "Wir verwenden die im TRAZER-Portal gespeicherten Daten für folgende Zwecke:",
    "privacyPolicy.section3.subsection2": "Personalisierung der Benutzererfahrungen.",
    "privacyPolicy.section3.subsection3": "Analyse und Optimierung unserer Abläufe.",
    "privacyPolicy.section3.subsection4": "Einhaltung gesetzlicher und behördlicher Anforderungen.",
    "privacyPolicy.section3.subsection5": "Schutz der Rechte, Sicherheit und Eigentum unserer Kunden und ihrer Benutzer.",
    "privacyPolicy.section4": "Datensicherheit",
    "privacyPolicy.section4.info": "Bei Trazer Inc. haben wir Sicherheit oberste Priorität. Wir setzen technische und organisatorische Maßnahmen ein, um Daten vor unbefugtem Zugriff, Offenlegung, Veränderung und Zerstörung zu schützen. Der Zugriff auf Daten ist nur autorisiertem Personal gestattet. Weitere Informationen finden Sie in der Trazer Inc.",
    "privacyPolicy.section4.securityLink": "Sicherheitsrichtlinie",
    "privacyPolicy.section4.info2": "Bitte beachten Sie für weitere Informationen.",
    "privacyPolicy.section5": "Datenweitergabe",
    "privacyPolicy.section5.info": "Trazer Inc. verkauft, tauscht oder vermietet personenbezogene Daten nicht an Dritte. Wir können jedoch Daten teilen mit",
    "privacyPolicy.section5.subsection2": "Strafverfolgungsbehörden oder Aufsichtsbehörden, wenn dies gesetzlich vorgeschrieben ist.",
    "privacyPolicy.section5.subsection3": "Dritte mit ausdrücklicher Zustimmung.",

    "privacyPolicy.section6": "Datenspeicherung",
    "privacyPolicy.section6.info": "Wir bewahren Daten nur so lange auf, wie dies in dieser Richtlinie festgelegt ist oder wie es das Gesetz vorschreibt.",
    "privacyPolicy.section7": "Kundenrechte",
    "privacyPolicy.section7.info": "Kunden haben das Recht",
    "privacyPolicy.section7.subsection2": "Ungenaue Daten zu korrigieren.",
    "privacyPolicy.section7.subsection3": "Daten unter bestimmten Umständen zu löschen.",
    "privacyPolicy.section7.subsection4": "Der Verarbeitung von Daten zu widersprechen oder sie einzuschränken.",
    "privacyPolicy.section7.subsection5": "Die Einwilligung zu widerrufen, wenn dies zutrifft.",
    "privacyPolicy.section7.info2": "Um diese Rechte auszuüben, kontaktieren Sie uns bitte unter den in Abschnitt 9 angegebenen Informationen.",
    "privacyPolicy.section8": "Änderungen an der Richtlinie",
    "privacyPolicy.section8.info": "TRAZER Inc. behält sich das Recht vor, diese Richtlinie regelmäßig zu aktualisieren, um Änderungen in unseren Praktiken oder gesetzlichen Anforderungen widerzuspiegeln. Alle Aktualisierungen werden auf unserer Website veröffentlicht, und die überarbeitete Richtlinie gilt ab dem Veröffentlichungsdatum.",
    "privacyPolicy.section9": "Kontaktieren Sie uns",
    "privacyPolicy.section9.info": "Wenn Sie Fragen oder Bedenken zu dieser Richtlinie oder zur Handhabung von Daten haben, kontaktieren Sie uns bitte unter",
    "privacyPolicy.footer.line2": "629 Euclid Ave",
    "privacyPolicy.footer.line3": "11. Stock Suite 1101",
    "privacyPolicy.footer.line4": "Cleveland OH 44114",
    "privacyPolicy.footer.line5": "privacy@trazer.com",
    "privacyPolicy.footer.line6": "(440) 835-9191",
    "accessDenied.title": "Zugriff verweigert",
    "accessDenied.line1": "Sie haben keinen Zugriff auf diese Seite",
    "accessDenied.line2": "Wenn Sie glauben, dass dies ein Fehler ist, kontaktieren Sie bitte den TRAZER-Support",
    "validatorErrors.emptyFormWarning": "Bitte füllen Sie alle erforderlichen Felder aus.",
    "validatorErrors.invalidDataFormWarning": "Bitte füllen Sie alle Felder mit gültigen Daten aus.",
    "validatorErrors.userInactive": "Dieser Benutzer ist inaktiv. Sie können seine Administrator-Einstellungen nicht bearbeiten.",
    "widgets.actions.configure": "Konfigurieren",
    "widgets.fieldName.customer": "Kunde",
    "widgets.fieldName.user": "Benutzer",
    "myProfile.editModal.title": "Mein Profil",
    "support.title": "Support kontaktieren",
    "portalVersion.title": "Bitte wählen Sie Ihre Version",
    "login.wrongAlert": "Falscher Benutzername oder falsche PIN",
    "login.username": "Benutzername",
    "login.pin": "PIN",
};

export default DETranslations;
