/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { newGUID } from '../../../Utils/Common';
import { CustomerService } from '../../../Services/CustomerService';
import { SiteService } from '../../../Services/SiteService';
import { GroupService } from '../../../Services/GroupService';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import { maxLengthValidator, requiredValidator } from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { AdminTypes } from '../../../Utils/Constants';
import { AlertText } from '../../../CoreComponents/Alert';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const addUserGroupModalContentStyles = {
    root: css`
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .fields-container {
            & > .input-root {
                margin: 0 0 30px 0;
                width: 100%;
            }
        }

        & > .actions {
            display: flex;
            margin: 0;
            margin-top: 20px;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }

        & > .alert-text-root {
            align-self: end;
        }
    `,
};

export const AddGroupContent = ({ onCancel, onAdd }) => {
    const storeState = useSelector((state) => state.storeState);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $selectedCustomer = useHook(storeState.currentUser.admin?.customerId || null, requiredValidator);
    const $selectedGroup = useHook(null);
    const $isSaving = useHook(false);

    const { t } = useTranslation();
    const $isForceValidating = useHook(false);
    const groupId = newGUID();
    const $newGroup = useObjectHook(
        {
            groupId: groupId,
            groupName: '',
            siteId: '',
            site: { customerId: '' },
            isActive: true,
            dateCreated: new Date().toISOString(),
            createdBy: storeState.currentUser.userId,
            dateModified: new Date().toISOString(),
            modifiedBy: storeState.currentUser.userId
        },
        {
            groupName: [requiredValidator, maxLengthValidator(50)],
            siteId: [requiredValidator]
        },
        null,
        $isForceValidating.value
    );

    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;

    useEffect(() => {
        if (!isTrazerAdmin) {
            return;
        }
        CustomerService.getAll(9999, 'Name', true)
            .then(customers => $customers.set(customers));
    }, [])

    useEffect(() => {
        if (!$selectedCustomer.value) {
            return;
        }
        $selectedGroup.set(null);
        SiteService.getAll(9999, $selectedCustomer.value, null, 'Name', true)
            .then(sites => $sites.set(sites));
            $newGroup.set({...$newGroup.value,site:{customerId: $selectedCustomer.value}})
    }, [$selectedCustomer.value])

    const addGroup = () => {
        $isForceValidating.set(true);
        const errors = $newGroup.validate();
        if (errors || !$selectedCustomer.value) {
            return;
        }

        $isSaving.set(true);
        GroupService.postGroup($newGroup.value)
            .then(() => onAdd($newGroup.value))
            .finally(() => $isSaving.set(false));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addUserGroupModalContentStyles.root}>
                <h2>{t('groups.addModal.title')}</h2>
                <div className="fields-container">
                    <Input placeholder={t('input.placeholder')} required label={t('groups.fieldName.name')} $value={$newGroup.getPropHook('groupName')} />
                    {isTrazerAdmin ?
                        <SingleSelect $value={$selectedCustomer} required label={t('groups.fieldName.customer')}>
                            {$customers.value.map(g => <SelectOption key={g.customerId} value={g.customerId}>{g.name}</SelectOption>)}
                        </SingleSelect>
                        :
                        null
                    }
                    <SingleSelect onChange={() => $isForceValidating.set(true)} $value={$newGroup.getPropHook('siteId')} required label={t('groups.fieldName.site')}>
                        {$sites.value.map(g => <SelectOption key={g.siteId} value={g.siteId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                </div>
                {$newGroup.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                <div className="actions" css={addUserGroupModalContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newGroup.isValid} onClick={addGroup}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
