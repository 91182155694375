// @ts-check

import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { shortTime } from '../Utils/Common';
import { useTranslation } from './Translation';
import { requiredValidator } from '../Utils/Validators';

/** @typedef {import('./Translation').TranslationFunction} TranslationFunction */

/**
 * @template T
 * @template ObjectType
 * @typedef {(value: T, t: TranslationFunction, obj?: ObjectType) => string[] | undefined} Validator<T>
 */
/** @typedef {{ [propName: string]: string[] } | undefined} ObjectErrorResult */
/**
 * @template T
 * @template ObjectType
 * @typedef {{
 *     value: T;
 *     set: (newValue: T) => void;
 *     isValid: Boolean,
 *     validator: Validator<T, ObjectType> | undefined | null;
 *     validate: (value: T) => string[] | undefined;
 *     resetValidity: () => void;
 * }} Hook<T>
 */

/**
 * @template T
 * @template K
 * @typedef {<K extends keyof T>(propName: K) => Hook<T[K], T>} PropHook<T>
 */

/**
 * @template T
 * @typedef {{
 *     value: T;
 *     set: (newValue: T) => void;
 *     hasEmptyFields: Boolean,
 *     isValid: Boolean,
 *     getPropHook: PropHook<T, string>;
 *     validator: (value: T) => ObjectErrorResult;
 *     validate: () => ObjectErrorResult;
 *     resetValidity: () => void;
 * }} ObjectHook<T>
 */

/**
 * @typedef {{ [key: string]: Validator<any, any>[] }} ValidatorObject
 */

/**
 * @template T
 * @param {T} value
 * @param {Validator<T, undefined> | null} validator
 * @returns {Hook<T, undefined>}
 */
export function useHook(value, validator) {
    const { t } = useTranslation();
    const [val, setter] = useState(value);
    const [isValid, setIsValid] = useState(true);
    const validatorInternal = (v, setValidity) => {
        if (validator) {
            const errors = validator(v, t);
            if (setValidity) {
                setIsValid(Boolean(!errors?.length));
            }

            if (errors?.length) {
                return errors;
            }
        }
    };

    return {
        value: val,
        set: setter,
        isValid: isValid,
        /**
         * @deprecated For internal use only - CoreComponents like Input, SingleSelect, ...
         */
        validator: v => validatorInternal(v, false),
        validate: () => validatorInternal(val, true),
        resetValidity: () => setIsValid(true),
    }
}

/**
 * @template T
 * @param {T} value
 * @param {ValidatorObject | undefined | null} validators
 * @param {(oldState: T, newState: T) => ObjectErrorResult | undefined | null} objectValidator
 * @param {boolean | undefined | null} forceValidateForm
 * @returns {ObjectHook<T>}
 */
export function useObjectHook(value, validators, objectValidator, forceValidateForm) {
    const { t } = useTranslation();
    const [obj, setter] = useState(value);
    const [hasEmptyFields, setHasEmptyFields] = useState(false);
    const [isValid, setIsValid] = useState(true);
    /** @type any */
    const propHasErrorsRef = useRef({});

    /**
     * @param {T} val
     * @param {string | undefined} checkFieldValidityName
     * @returns {{ [propName: string] } | undefined | {}}
     */
    const objValidator = (val, checkFieldValidityName) => {
        const result = {};
        let hasErrors = false;
        let hasEmptyFields = false;

        for (const key in propHasErrorsRef.current) {
            if (checkFieldValidityName && key !== checkFieldValidityName) {
                continue;
            }

            propHasErrorsRef.current[key] = false;
        }

        if (validators) {
            for (const propName in validators) {
                /** string[] */
                const errors = [];
                for (const validator of validators[propName]) {
                    const err = validator(val[propName], t, obj);
                    if (err && err.length) {
                        hasEmptyFields ||= (validator === requiredValidator);
                        errors.push(...err);
                        if (checkFieldValidityName && propName !== checkFieldValidityName) {
                            continue;
                        }

                        propHasErrorsRef.current[propName] = true;
                    }
                }

                if (errors.length) {
                    result[propName] = errors;
                    hasErrors = true;
                }
            }
        }

        if (objectValidator) {
            const err = objectValidator(obj, val);
            if (err) {
                for (const propName in err) {
                    if (result[propName]) {
                        result[propName] = err[propName].concat(result[propName]);
                    } else {
                        result[propName] = err[propName];
                    }

                    if (checkFieldValidityName && propName !== checkFieldValidityName) {
                        continue;
                    }

                    if (result[propName] && result[propName].length) {
                        propHasErrorsRef.current[propName] = true;
                    }
                }

                hasErrors = true;
            }
        }

        if (!checkFieldValidityName || result[checkFieldValidityName]) {
            setIsValid(!hasErrors);
            setHasEmptyFields(hasEmptyFields);
        } else {
            setIsValid(true);
            setHasEmptyFields(false);
        }

        return hasErrors ? result : undefined;
    };

    return {
        value: obj,
        set: setter,
        getPropHook: (propName) => {
            const validateInternal = (val, checkFieldValidityName) => {
                const errors = [];

                const err = objValidator({ ...obj, [propName]: val }, checkFieldValidityName ?? (forceValidateForm ? undefined : propName));
                // @ts-ignore
                if (err && err[propName]) {
                    // @ts-ignore
                    errors.push(...err[propName]);
                }

                return errors.length ? errors : undefined;
            };

            return {
                value: obj[propName],
                set: (v) => setter({ ...obj, [propName]: v }),
                isValid: !propHasErrorsRef.current[propName],
                /**
                 * @deprecated For internal use only - CoreComponents like Input, SingleSelect, ...
                 */
                validator: v => validateInternal(v, ' '),
                validate: () => validateInternal(obj[propName], undefined),
                resetValidity: () => {
                    let isFormValid = true;
                    for (const key in propHasErrorsRef.current) {
                        if (key === propName) {
                            delete propHasErrorsRef.current[key];
                        }

                        if (propHasErrorsRef.current[key]) {
                            isFormValid = false;
                        }
                    }

                    // Probably for propHook we should never reset this
                    setIsValid(isFormValid);
                }
            };
        },
        hasEmptyFields: hasEmptyFields,
        isValid: isValid,
        /**
         * @deprecated For internal use only - CoreComponents like Input, SingleSelect, ...
         */
        validator: v => objValidator(v, ' '),
        validate: () => objValidator(obj, undefined),
        resetValidity: () => {
            setIsValid(true);
            for (const key in propHasErrorsRef.current) {
                delete propHasErrorsRef.current[key];
            }
        },
    }
}

/**
    Usage

    useOnResize(({ contentRect = {} }) => {
        if (!chartRef.current) {
            return;
        }

        const { width, height } = contentRect;
        setDimensions({ width, height });
    }, containerRef);

    @param {(resizeEntry: ResizeObserverEntry) => void} callback
    @param {React.MutableRefObject<Element>} elRef
 */
export const useOnResize = (callback, elRef) => {
    const observer = useRef(
        new ResizeObserver(entries => {
            callback(entries[0]);
        })
    );
    useEffect(() => {
        if (!elRef.current) {
            return;
        }

        const elRefInternal = elRef.current;
        observer.current.observe(elRefInternal);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            observer.current.unobserve(elRefInternal)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elRef.current, observer]);
};

/**
    Usage

    usePrintEffect(
        () => {
            chart.resize();
            chart.update('none');
            setIsPrinting(true);
        },
        () => {
            chart.resize();
            chart.update('none');
            setIsPrinting(false);
        },
        () => {
            return chart
                && chart.canvas
                && !isPrinting;
        },
        [chart, chartSize, isPrinting]
    );

    @param {() => void} beforePrintCallback Called before print window is opened
    @param {() => void} afterPrintCallback Called after print window is closed
    @param {() => boolean} canSubscribeCondition If it's not provided will always subscribe
    @param {[]} deps Dependency list to track
 */
export const usePrintEffect = (beforePrintCallback, afterPrintCallback, canSubscribeCondition, deps = []) => {
    useEffect(() => {
        if (canSubscribeCondition ? !canSubscribeCondition() : false) {
            return;
        }

        let mediaQueryList = null;
        let changeMediaHandler = null;
        if (window.matchMedia) {
            mediaQueryList = window.matchMedia('print');
            changeMediaHandler = function (mql) {
                if (mql.matches) {
                    beforePrintCallback();
                } else if (afterPrintCallback) {
                    afterPrintCallback();
                }
            };
            mediaQueryList.addEventListener('change', changeMediaHandler);
        }

        if (beforePrintCallback) {
            window.addEventListener('beforeprint', beforePrintCallback);
        }
        if (afterPrintCallback) {
            window.addEventListener('afterprint', afterPrintCallback);
        }
        return () => {
            if (mediaQueryList && changeMediaHandler) {
                mediaQueryList.removeEventListener('change', changeMediaHandler);
            }

            if (beforePrintCallback) {
                window.removeEventListener('beforeprint', beforePrintCallback);
            }
            if (afterPrintCallback) {
                window.removeEventListener('afterprint', afterPrintCallback);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tuple
        result[key] = value;
    }

    return result;
}

export function getCurrentSearchParams() {
    const entries = (new URL(window.location.toString())).searchParams.entries();
    return paramsToObject(entries);
}

export function useQuery() {
    const entries = (new URLSearchParams(useLocation().search)).entries();
    return paramsToObject(entries);
}

export const ForceValidateContext = React.createContext(false);

export const useForceValidateContext = () => React.useContext(ForceValidateContext);

export const getLabelFromChildren = (children) => {
    let label = '';

    React.Children.map(children, (child) => {
        if (!child || (!React.isValidElement(child) && typeof (child) !== 'string')) {
            return;
        }

        if (typeof (child) === 'string') {
            label += child;
        } else if (child.props.children) {
            label += getLabelFromChildren(child.props.children);
        }
    });

    return label;
};

export const getTextContent = (element) => {
    if (!element) {
        return '';
    }

    if (element instanceof Date) {
        return shortTime(element);
    }

    if (typeof element === 'string' || typeof element === 'number') {
        return element;
    }

    if (typeof element === 'boolean') {
        return element ? 'true' : 'false';
    }

    const children = element.props && element.props.children;
    if (children instanceof Array) {
        return children.map(getTextContent).join(' ');
    }

    return getTextContent(children);
}

/**
 *
 * @param {*} obj
 * @param {*} validators
 * @param {string[] | undefined | null} filteredProperties
 * @param {string[] | undefined | null} excludedProperties
 * @returns {boolean}
 */
export function areFieldsValid(obj, validators, filteredProperties, excludedProperties) {
    for (const k in validators) {
        if (filteredProperties && !filteredProperties.includes(k)) {
            continue;
        }

        if (excludedProperties && excludedProperties.includes(k)) {
            continue;
        }

        if (!obj.hasOwnProperty(k)) {
            console.warn(`validation error missing object property (${k})`);
            // return false;
            continue;
        }

        for (const validator of validators[k]) {
            const err = validator(obj[k]);
            if (err && err.length) {
                console.log('validation err', k, err)
                return false;
            }
        }
    }

    return true;
}
