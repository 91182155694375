const MainRouterConfig = [
    {
        to: '/overview',
        name: 'Overview',
        translationKey: 'mainNavigation.overview',
    },
    {
        to: '/manage',
        name: 'Manage',
        translationKey: 'mainNavigation.manage',
    },
    {
        to: '/library',
        name: 'Library',
        translationKey: 'mainNavigation.library',
    },
    {
        to: '/analytics',
        name: 'Analytics',
        translationKey: 'mainNavigation.analytics',
    },
    // {
    //     to: '/dashboard',
    //     name: 'Dasboard',
    //     translationKey: 'mainNavigation.dashboard',
    // },
];

export default MainRouterConfig;
