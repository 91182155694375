/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useHook } from '../../../CoreComponents/Utils';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { ScriptedActivitiesService } from '../../../Services/ScriptedActivitiesService';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { EditIcon } from '../../../CoreComponents/CustomIcons';
import AddIcon from '@mui/icons-material/Add';
import { Modal } from '../../../CoreComponents/Modal';
import { AddScriptedActivity } from './AddScriptedActivities';
import { InternalLink } from '../../../CoreComponents/Layout';
import { AdminTypes, AdvancedSearchType, SortOrder } from '../../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setScriptedActivities } from '../../../globalStates/tableDataState';
import { defaultSort } from '../../../Utils/Common';

const scriptedActivitiesListStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    icon: css`
        color: #00ABA5
    `,
}

export const ScriptedActivities = () => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $refreshScriptedActivities = useHook({});
    const $scriptedActivities = useHook(tableDataState.scriptedActivities);
    const $categoryNames = useHook([]);
    const $tags = useHook([]);
    const $pillars = useHook([]);
    const $groups = useHook([]);
    const $isLoading = useHook(!Boolean(tableDataState.scriptedActivities.length));
    const $isAddScriptedActivitiesModalOpened = useHook(false);

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton
                            disabled={isTrazerViewAdmin}
                            component={InternalLink}
                            to={`/scripted-activity/edit/${rowData.scriptedActivityId}`}
                            tooltip={t('actions.edit')}
                        >
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('scriptedActivities.table.name'),
            isSortable: true,
            columnWidth: 300,
        },
        {
            id: 'alias',
            label: t('scriptedActivities.table.alias'),
            isSortable: true,
            columnWidth: 300,
        },
        {
            id: 'category',
            label: t('scriptedActivities.table.category'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: $categoryNames.value
            },
            isSortable: true,
            columnWidth: 180,
        },
        {
            id: 'tags',
            label: t('scriptedActivities.table.tags'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($tags.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            textRenderer: ({ rowData }) => {
                return rowData.tagNames;
            },
            CellRender: ({ rowData }) => {
                return rowData.tagNames;
            }
        },
        {
            id: 'pillars',
            label: t('scriptedActivities.table.pillars'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($pillars.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            textRenderer: ({ rowData }) => {
                return rowData.pillarNames;
            },
            CellRender: ({ rowData }) => {
                return rowData.pillarNames;
            }
        },
        {
            id: 'groups',
            label: t('scriptedActivities.table.groups'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($groups.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            textRenderer: ({ rowData }) => {
                return rowData.groupNames;
            },
            CellRender: ({ rowData }) => {
                return rowData.groupNames;
            }
        }
    ], [t, $categoryNames.value, $tags.value, $pillars.value, $groups.value]);

    useEffect(() => {
        ScriptedActivitiesService.getAllScriptedActivities(99999)
            .then(scriptedActivitiesResponse => {
                const categoryNames = {};
                const scriptedActivities = scriptedActivitiesResponse.rows;
                const tags = {};
                const pillars = {};
                const groups = {};
                for (const scriptedActivity of scriptedActivities) {
                    categoryNames[scriptedActivity.category.name] = 1;
                    scriptedActivity.alias = scriptedActivity.alias ? scriptedActivity.alias : '-';
                    scriptedActivity.category = scriptedActivity.category.name;
                    scriptedActivity.tags = scriptedActivity.satags?.length ?
                        scriptedActivity.satags.map(t => t.tag.name)
                        : '';
                    scriptedActivity.tagNames = scriptedActivity.tags?.length ?
                        scriptedActivity.tags.join(', ')
                        : '-';
                        
                    scriptedActivity.pillars = scriptedActivity.sapillars?.length ?
                        scriptedActivity.sapillars.map(p => p.pillar.name)
                        : '';
                    scriptedActivity.pillarNames = scriptedActivity.pillars?.length ?
                        scriptedActivity.pillars.join(', ')
                        : '-';

                    scriptedActivity.groups = scriptedActivity.sagroups?.length ?
                        scriptedActivity.sagroups.map(g => g.group.name)
                        : '';
                    scriptedActivity.groupNames = scriptedActivity.groups?.length ?
                        scriptedActivity.groups.join(', ')
                        : '-';

                    if (scriptedActivity.satags?.length) {
                        for (const tag of scriptedActivity.satags) {
                            tags[tag.tag.name] = 1;
                        }
                    }

                    if (scriptedActivity.sapillars?.length) {
                        for (const pillar of scriptedActivity.sapillars) {
                            pillars[pillar.pillar.name] = 1;
                        }
                    }

                    if (scriptedActivity.sagroups?.length) {
                        for (const group of scriptedActivity.sagroups) {
                            groups[group.group.name] = 1;
                        }
                    }
                }

                $tags.set(
                    Object.keys(tags)
                        .map(value => ({
                            value,
                            label: value
                        }))
                );

                $pillars.set(
                    Object.keys(pillars)
                        .map(value => ({
                            value,
                            label: value
                        }))
                );

                $groups.set(
                    Object.keys(groups)
                        .map(value => ({
                            value,
                            label: value
                        }))
                );

                $categoryNames.set(Object.keys(categoryNames).map(x => ({ value: x, label: x })))

                dispatch(setScriptedActivities(scriptedActivities));
                $scriptedActivities.set(scriptedActivities);
                $isLoading.set(false);
            });
    }, [$refreshScriptedActivities.value])

    return (
        <div css={scriptedActivitiesListStyles.root}>
            <TableCSF
                customActions={
                    <div css={scriptedActivitiesListStyles.actions}>
                        <Button disabled={isTrazerViewAdmin}  onClick={() => $isAddScriptedActivitiesModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$scriptedActivities.value}
                rowKeySelector={r => r.scriptedActivityId}
                isLoading={$isLoading.value}
            />
            <Modal
                open={$isAddScriptedActivitiesModalOpened.value}
                onClose={() => $isAddScriptedActivitiesModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddScriptedActivity onCancel={() => $isAddScriptedActivitiesModalOpened.set(false)}
                    onAdd={() => {
                        $isAddScriptedActivitiesModalOpened.set(false);
                        $refreshScriptedActivities.set({});
                    }}
                />
            </Modal>
        </div>
    )
}
