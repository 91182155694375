/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button } from "../../../CoreComponents/Button";
import { ForceValidateContext, useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { useTranslation } from '../../../CoreComponents/Translation';
import { Input } from "../../../CoreComponents/Input";
import { UserService } from "../../../Services/UserService";
import { minLengthValidator, passwordValidator, requiredValidator } from "../../../Utils/Validators";

const changePasswordModalContentStyles = {
    root: css`
        width: unset;
        // height: 85vh;
        height: 420px;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .input-root {
            margin: 0 0 30px 0;
            width: 100%;
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;

            & > button:first-of-type {
                margin-right: 20px;
                margin-left: auto;
            }
        }
    `,
};

const ChangePassValidators = {
    password: [requiredValidator, minLengthValidator(8), passwordValidator],
    confirmPassword: [requiredValidator]
};

export const ChangePasswordContent = ({ $selectedUser, onCancel, onSuccess }) => {
    const { t } = useTranslation();
    const $isForceValidating = useHook(false);
    const $isSaving = useHook(false);
    const $newPassword = useObjectHook(
        {
            password: '',
            confirmPassword: ''
        },
        ChangePassValidators,
        (oldState, newState) => {
            if (newState.password === newState.confirmPassword) {
                return null;
            }

            return {
                confirmPassword: ['Passwords don\'t match']
            };
        },
        $isForceValidating.value
    );

    const changePassword = () => {
        $isForceValidating.set(true);
        const errors = $newPassword.validate();
        if (errors) {
            return;
        }
        $isSaving.set(true);
        UserService.changeUserPassword($selectedUser.value.userId, $newPassword.value.password)
            .then(() => onSuccess())
            .finally(() => $isSaving.set(false))
    }

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={changePasswordModalContentStyles.root}>
                <h2>{t('changePassword.modal.title')}</h2>
                <Input
                    placeholder={t('input.placeholder')}
                    required
                    label={t('users.fieldName.newPassword')}
                    type="password"
                    $value={$newPassword.getPropHook('password')}
                />
                <Input
                    placeholder={t('input.placeholder')}
                    required
                    label={t('users.fieldName.confirmPassword')}
                    type="password"
                    $value={$newPassword.getPropHook('confirmPassword')}
                />

                <div className="actions" css={changePasswordModalContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value} onClick={changePassword}>{t('actions.change')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
