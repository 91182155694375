/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { useTranslation } from '../../../CoreComponents/Translation';
import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { useEffect, useMemo } from 'react';
import { shortDate } from '../../../Utils/Common';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { Modal } from '../../../CoreComponents/Modal';
import { AddUnitContent } from '../Units/AddUnitContent';
import { UnitService } from '../../../Services/UnitService';
import { UnitTab } from '../Units/UnitTab';
import { AdminTypes } from '../../../Utils/Constants';
import { useSelector } from 'react-redux';

const unitsTabStyles = {
    root: css`
        height: 85vh;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 28px;

        width: unset;
        @media (min-width: 1500px) {
            width: 1400px;
        }

        & > h2 {
            margin: 0 0 40px 12px;
        }

        & > .table {
            height: calc(100% - 40px - 1.5em);
        }
    `,
    actions: css`
        & > button {
            min-width: unset;
        }
    `,
};

export const SiteUnitsTab = ({ $selectedSite }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const $units = useHook([]);
    const $refreshUnits = useHook(null);
    const $selectedUnit = useObjectHook(null);
    const $prevSelectedUnit = useHook(null);
    const $isAddUnitModalOpened = useHook(false);
    const $isLoading = useHook(false);
    const headCells = useMemo(() => [
        {
            id: 'actions',
            columnWidth: 80,
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton onClick={() => $selectedUnit.set(rowData)}>
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'serialNumber',
            label: t('units.table.serialNumber'),
            isSortable: true,
            columnWidth: 160,
            CellRender: ({ rowData }) => {
                return rowData?.serialNumber || '-';
            }
        },
        {
            id: 'skuNumber',
            label: t('units.table.skuNumber'),
            isSortable: true,
            columnWidth: 150,
        },
        {
            id: 'dateCreated',
            label: t('units.table.createdOn'),
            isSortable: true,
            columnWidth: 140,
            CellRender: ({ rowData }) => {
                return rowData.dateCreated ? shortDate(new Date(rowData.dateCreated)) : '-';
            }
        },
        {
            id: 'lastOnline',
            label: t('units.table.lastOnline'),
            isSortable: true,
            columnWidth: 140,
            CellRender: ({ rowData }) => {
                return rowData.lastOnline ? shortDate(new Date(rowData.lastOnline)) : '-';
            }
        },
        {
            id: 'customerName',
            label: t('units.table.customerName'),
            isSortable: true,
            columnWidth: 120,
            CellRender: ({ rowData }) => {
                return rowData?.customerName || '-';
            }
        },
        {
            id: 'siteName',
            label: t('units.table.siteName'),
            isSortable: true,
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return rowData?.siteName || '-';
            }
        },
        {
            id: 'clientVersion',
            label: t('units.table.clientVersion'),
            isSortable: true,
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return rowData?.clientVersion || '-';
            }
        },
        {
            id: 'isActive',
            label: t('units.table.isActive'),
            isSortable: true,
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return rowData.isActive.toString();
            }
        },
        {
            id: 'isRegistered',
            label: t('units.table.isRegistered'),
            isSortable: true,
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return rowData.isRegistered.toString();
            }
        },
    ], [t]);

    useEffect(() => {
        if ($prevSelectedUnit.value && $selectedUnit.value) {
            $refreshUnits.set({});
            $prevSelectedUnit.set(null);
            $selectedUnit.set(null);
            return;
        }

        $prevSelectedUnit.set($selectedUnit.value);
    }, [$selectedUnit.value]);

    useEffect(() => {
        $isLoading.set(true);
        UnitService.getAll(9999, null, $selectedSite.value.siteId)
            .then(units => {
                const unitsResponse = units.map(u => ({ ...u, customerName: u.site.customer.name, siteName: u.site.name }))
                $units.set(unitsResponse);
                $isLoading.set(false);
            });
    }, [$refreshUnits.value]);

    return (
        <div css={unitsTabStyles.root}>
            <h2>{t('sites.editModal.siteUnitsTab')}</h2>
            <div className="table">
                <TableCSF
                    headCells={headCells} rows={$units.value} rowKeySelector={r => r.unitId}
                    customActions={
                        <div css={unitsTabStyles.actions}>
                            <Button
                             onClick={() => $isAddUnitModalOpened.set(true)}
                             disabled={storeState.currentUser.admin.adminTypeId !== AdminTypes.TRAZERAdmin}
                             >
                                <AddIcon />
                            </Button>
                        </div>
                    }
                    isLoading={$isLoading.value}
                />
            </div>
            <Modal
                open={$isAddUnitModalOpened.value}
                onClose={() => $isAddUnitModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddUnitContent
                    onCancel={() => $isAddUnitModalOpened.set(false)}
                    onAdd={() => {
                        $isAddUnitModalOpened.set(false);
                        $refreshUnits.set({});
                    }}
                    customerId={$selectedSite.value.customerId}
                    siteId={$selectedSite.value.siteId}
                />
            </Modal>
            <Modal
                open={!!$selectedUnit.value}
                onClose={() => $selectedUnit.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {$selectedUnit.value &&
                    <UnitTab $selectedUnit={$selectedUnit} />
                }
            </Modal>
        </div>
    )
};
