/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { Popover } from '@mui/material';
import {
    addDays,
    addMonths,
    differenceInCalendarDays,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    isSameDay,
    isWithinInterval,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subMonths,
    subWeeks,
    subYears
} from 'date-fns';
import { isValidDateValue, shortDate } from '../Utils/Common';
import { Button, IconButton } from './Button';
import { ChevronLeftIcon, ChevronRightIcon } from './CustomIcons';
import { Input } from './Input';
import { useTranslation } from './Translation';
import { useHook } from './Utils';
import { DatePicker } from './DatePicker';

const dateRangeStyles = {
    root: css`
        position: relative;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        margin: 8px 20px 21px 20px;
        flex-wrap: wrap;

        & > input {
            padding: 11px 20px 10px 20px;
            line-height: 19px;
            height: auto;
        }

        & span, & input {
            min-width: unset;
            min-height: unset;
            width: unset;
        }

        & > input {
            min-width: 140px;
        }
    `,
    slim: css`
        flex-direction: row;
        margin: 0 20px 0 0;
        & .label-text {
            margin: auto 8px auto 0;
        }
    `,
    label: css`
        letter-spacing: 0.14px;
        color: #858585;
        font: normal normal bold 16px Mustica Pro;
        margin: 0 auto 18px 0;
    `,
    popover: {
        display: 'flex',
        padding: '8px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 8px 16px #28326529',
        borderRadius: '8px',
    },
    rangeList: css`
        display: flex;
        flex-direction: column;

        & > button {
            padding: 10px 12px;
            border-radius: 4px;
            // margin: 2px 0;
            margin-bottom: 2px;

            &:last-of-type {
                margin: 0;
            }

            text-align: left;
            font: normal normal normal 16px/24px Roboto;
            letter-spacing: 0.14px;
            background: none;
            border: none;
            color: #858585;

            &.active, &:hover {
                background: #E0E0E0 0% 0% no-repeat padding-box;
            }
        }
    `,
    customRangeRoot: css`
        display: grid;
        grid-template-columns: 200px 200px;
        grid-template-rows: auto auto 1fr auto;
        grid-gap: 12px;
        padding-left: 8px;

        & .month-selection {
            display: grid;
            grid-template-columns: auto 1fr auto;
            justify-items: center;
            align-items: center;

            font: normal normal bold 16px/24px Mustica Pro;
            letter-spacing: 0.14px;
            color: #676767;
            margin-bottom: -8px;
            font-weight: 400;

            & > button {
                width: 1.5em;
                height: 1.5em;
                color: #676767;
            }
        }

        & .apply-button {
            min-width: 120px;
            margin-top: auto;
            margin-left: auto;
        }
    `,
};

export const DateRangeSelector = ({ label, $value, ...props }) => {
    const { t } = useTranslation();
    const $rangeSelectionAnchor = useHook(null);
    const $currentDate = useHook(new Date());
    const $leftMonth = useHook($value.value.startDate);
    const rightMonth = addMonths($leftMonth.value, 1);
    const $firstSelectedDate = useHook(null);
    const $lastSelectedDate = useHook(null);
    const $isSelecting = useHook(false);
    const $startDate = useHook($value.value.startDate);
    const $endDate = useHook($value.value.endDate);
    const $currentRange = useHook('customRange');
    const $lastSelectedRange = useHook('customRange');

    const rangeOptionsTranslations = useMemo(() => ({
        currentWeek: t('constants.dateRanges.currentWeek'),
        lastWeek: t('constants.dateRanges.lastWeek'),
        thisMonth: t('constants.dateRanges.thisMonth'),
        lastMonth: t('constants.dateRanges.lastMonth'),
        last6Months: t('constants.dateRanges.last6Months'),
        thisYear: t('constants.dateRanges.thisYear'),
        lastYear: t('constants.dateRanges.lastYear'),
        customRange: t('constants.dateRanges.customRange'),
    }), [t]);

    useEffect(() => {
        $leftMonth.set($value.value.startDate);
        $startDate.set($value.value.startDate);
        $endDate.set($value.value.endDate);
    }, [$value.value, $rangeSelectionAnchor.value]);

    useEffect(() => {
        if (!isValidDateValue($startDate.value)) {
            return;
        }

        $leftMonth.set($startDate.value);
    }, [$startDate.value]);

    useEffect(() => {
        if (!isValidDateValue($endDate.value)) {
            return;
        }

        $leftMonth.set(subMonths($endDate.value, 1));
    }, [$endDate.value]);

    const handleOpenRangeMenu = (e) => {
        $rangeSelectionAnchor.set(e.currentTarget);
        $currentRange.set($lastSelectedRange.value);
    };
    const handleCloseRangeMenu = () => {
        $rangeSelectionAnchor.set(null);
        stopSelection();
    };

    const startSelection = (value) => {
        $firstSelectedDate.set(value);
        $lastSelectedDate.set(value);
        $isSelecting.set(true);
    };
    const stopSelection = () => {
        $firstSelectedDate.set(null);
        $lastSelectedDate.set(null);
        $isSelecting.set(false);
    };

    const updateSelectedValue = (startDate, endDate) => {
        if (startDate > endDate) {
            const tmp = startDate;
            startDate = endDate;
            endDate = tmp;
        }

        $startDate.set(startDate);
        $endDate.set(endDate);
        return { startDate, endDate };
    };

    const updateRangeHandler = (range, startDate, endDate) => {
        $value.set({
            ...$value.value,
            ...updateSelectedValue(startDate, endDate)
        });
        handleCloseRangeMenu();
        $currentRange.set(range);
        $lastSelectedRange.set(range);
    }

    const rangeOptions = {
        currentWeek: range => updateRangeHandler(range, startOfWeek($currentDate.value), $currentDate.value),
        lastWeek: range => updateRangeHandler(range, startOfWeek(subWeeks($currentDate.value, 1)), endOfWeek(subWeeks($currentDate.value, 1))),
        thisMonth: range => updateRangeHandler(range, startOfMonth($currentDate.value), endOfMonth($currentDate.value)),
        lastMonth: range => updateRangeHandler(range, startOfMonth(subMonths($currentDate.value, 1)), endOfMonth(subMonths($currentDate.value, 1))),
        last6Months: range => updateRangeHandler(range, subMonths($currentDate.value, 6), $currentDate.value),
        thisYear: range => updateRangeHandler(range, startOfYear($currentDate.value), $currentDate.value),
        lastYear: range => updateRangeHandler(range, startOfYear(subYears($currentDate.value, 1)), endOfYear(subYears($currentDate.value, 1))),
        customRange: range => $currentRange.set(range),
    };

    return <>
        {label ?
            <label
                css={[
                    dateRangeStyles.root,
                    props.design === 'slim' && dateRangeStyles.slim
                ]}
                {...props.labelProps}
            >
                <span css={dateRangeStyles.label} className="label-text">{label}</span>
                <Input variant={props.variant} value={`${shortDate($value.value.startDate)} - ${shortDate($value.value.endDate)}`} inputProps={{ onClick: handleOpenRangeMenu }} />
            </label>
            :
            <Input variant={props.variant} value={`${shortDate($value.value.startDate)} - ${shortDate($value.value.endDate)}`} inputProps={{ onClick: handleOpenRangeMenu }} />
        }
        <Popover
            sx={{ mt: '8px' }}
            PaperProps={{ sx: dateRangeStyles.popover }}
            // id="date-range-selector"
            anchorEl={$rangeSelectionAnchor.value}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean($rangeSelectionAnchor.value)}
            onClose={handleCloseRangeMenu}
        >
            <div css={dateRangeStyles.rangeList}>
                {Object.keys(rangeOptions).map(x => (
                    <button
                        key={x}
                        className={x === $currentRange.value ? 'active' : ''}
                        onClick={() => rangeOptions[x](x)}
                    >
                        {rangeOptionsTranslations[x]}
                    </button>
                ))}
            </div>
            {$currentRange.value === 'customRange' &&
                <div css={dateRangeStyles.customRangeRoot}>
                    <DatePicker
                        variant="outlined"
                        $value={$startDate}
                        maxValue={$endDate.value}
                    />
                    <DatePicker
                        variant="outlined"
                        $value={$endDate}
                        minValue={$startDate.value}
                    />
                    <div className="month-selection">
                        <IconButton
                            onClick={() => $leftMonth.set(subMonths($leftMonth.value, 1))}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                        {format($leftMonth.value, 'MMM yyyy')}
                        <div></div>
                    </div>
                    <div className="month-selection">
                        <div></div>
                        {format(rightMonth, 'MMM yyyy')}
                        <IconButton
                            onClick={() => $leftMonth.set(addMonths($leftMonth.value, 1))}
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <CalendarDaySelector
                        currentDate={$leftMonth.value}
                        startOfSelection={$firstSelectedDate.value ?? ($startDate.value || $value.value.startDate)}
                        endOfSelection={$lastSelectedDate.value ?? ($endDate.value || $value.value.endDate)}
                        onChange={value => {
                            if (!$firstSelectedDate.value) {
                                startSelection(value);
                                return;
                            }

                            updateSelectedValue($firstSelectedDate.value, value);
                            stopSelection();
                        }}
                        onHoverChange={value => {
                            if (!$firstSelectedDate.value) {
                                return;
                            }

                            $lastSelectedDate.set(value);
                        }}
                    />
                    <CalendarDaySelector
                        currentDate={rightMonth}
                        startOfSelection={$firstSelectedDate.value ?? ($startDate.value || $value.value.startDate)}
                        endOfSelection={$lastSelectedDate.value ?? ($endDate.value || $value.value.endDate)}
                        onChange={value => {
                            if (!$firstSelectedDate.value) {
                                startSelection(value);
                                return;
                            }

                            updateSelectedValue($firstSelectedDate.value, value);
                            stopSelection();
                        }}
                        onHoverChange={value => {
                            if (!$firstSelectedDate.value) {
                                return;
                            }

                            $lastSelectedDate.set(value);
                        }}
                    />
                    <div></div>
                    <Button
                        disabled={$isSelecting.value}
                        className="apply-button"
                        onClick={() => updateRangeHandler($currentRange.value, $startDate.value, $endDate.value)}
                    >
                        {t('actions.apply')}
                    </Button>
                </div>
            }
        </Popover>
    </>;
};

const calendarDaySelectorStyles = {
    root: css`
        & > div {
            display: grid;
            grid-template-columns: repeat(7, 28px);
            grid-row-template: 28px;
            align-content: center;
            justify-items: center;
            grid-row-gap: 2px;

            & span {
                text-align: center;
                font: normal normal bold 16px/24px Mustica Pro;
                letter-spacing: 0.14px;
                color: #676767;
                font-weight: 400;
            }

            & button {
                width: 100%;
                height: 100%;
                text-align: center;
                font: normal normal normal 16px/24px Roboto;
                letter-spacing: 0.14px;
                color: #676767;
                border: none;
                background: none;
                padding: 4px;

                &.different-month {
                    color: #858585;
                    opacity: 0.5;
                }

                &.inside-interval {
                    background: #EBF5F4 0% 0% no-repeat padding-box;
                }

                &.start-of-selection {
                    color: #FFFFFF;
                    background: #00ABA5 0% 0% no-repeat padding-box;
                    // border-radius: 4px 0px 0px 4px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &.end-of-selection {
                    color: #FFFFFF;
                    background: #00ABA5 0% 0% no-repeat padding-box;
                    // border-radius: 0px 4px 4px 0px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                &:hover {
                    color: #FFFFFF;
                    background: #00ABA5 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                }
            }
        }
    `,
};

const CalendarDaySelector = ({ currentDate, onChange, onHoverChange, startOfSelection, endOfSelection, minDate, maxDate }) => {
    const weekStart = { weekStartsOn: 0 }
    const {
        // firstDayOfMonth,
        // lastDayOfMonth,
        firstRenderDay,
        lastRenderDay,
    } = useMemo(() => {
        const firstDayOfMonth = startOfMonth(currentDate);
        const lastDayOfMonth = endOfMonth(currentDate);
        return {
            firstDayOfMonth,
            lastDayOfMonth,
            firstRenderDay: startOfWeek(firstDayOfMonth, weekStart),
            lastRenderDay: endOfWeek(lastDayOfMonth, weekStart),
        };
    }, [currentDate]);

    const headers = useMemo(() => {
        const headers = [];
        for (let i = 0; i < 7; i++) {
            const dayOfWeek = format(addDays(firstRenderDay, i), 'eee', weekStart).slice(0, 2);
            headers.push(dayOfWeek);
        }

        return headers;
    }, []);

    const days = [];
    const daysCount = differenceInCalendarDays(lastRenderDay, firstRenderDay);
    for (let i = 0; i <= daysCount; ++i) {
        const day = addDays(firstRenderDay, i);
        days.push(
            <button
                key={`${day.getMonth()}_${day.getDate()}`}
                className={[
                    currentDate.getMonth() !== day.getMonth() ? 'different-month' : '',
                    isWithinInterval(
                        day,
                        startOfSelection < endOfSelection ?
                            { start: startOfSelection, end: endOfSelection }
                            : { start: endOfSelection, end: startOfSelection }
                    ) ? 'inside-interval' : '',
                    isSameDay(day, startOfSelection) ? 'start-of-selection' : '',
                    isSameDay(day, endOfSelection) ? 'end-of-selection' : '',
                ].join(' ')}
                onClick={() => onChange(day)}
                onMouseEnter={() => onHoverChange(day)}
            >
                {day.getDate()}
            </button>
        );
    }

    return (
        <div css={calendarDaySelectorStyles.root} role="grid">
            <div role="row">
                {headers.map(x => (
                    <span key={x} role="columnheader">{x}</span>
                ))}
            </div>
            <div role="presentation">
                {days.concat()}
            </div>
        </div>
    );
};
