import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@mui/material';
import {
    faEdit,
    faLanguage,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faEllipsisVertical,
    faCheck,
    faMagnifyingGlass,
    faFilter,
    faFilterCircleXmark,
    faFileCircleXmark,
    faPrint,
    faArrowUp,
    faPen,
    faUsers,
    faCaretUp,
    faCaretDown,
    faEnvelope,
    faPhone,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
    faTrashCan,
    faCircleCheck
} from '@fortawesome/free-regular-svg-icons';
import { ReactComponent as TrazerBlackLogo } from '../Assets/logos/TRAZER_black_long.svg';
import { ReactComponent as TRAZER_logo_Small } from '../Assets/logos/TRAZER_logo_Small.svg';

export const TrazerLogoSmall = props =>
    <SvgIcon component={TRAZER_logo_Small} viewBox="0 0 150 150" data-testid="TrazerLogoSmall" {...props} /> //366.53 66.21

export const TrazerLogo = props =>
    <SvgIcon component={TrazerBlackLogo} viewBox="0 0 366.53 66.21" data-testid="TrazerLogo" {...props} /> //366.53 66.21

export const EditIcon = props =>
    <FontAwesomeIcon icon={faEdit} {...props} />

export const TrashCanIcon = props =>
    <FontAwesomeIcon icon={faTrashCan} {...props} />

export const LanguageIcon = props =>
    <FontAwesomeIcon icon={faLanguage} {...props} />

export const ChevronDownIcon = props =>
    <FontAwesomeIcon icon={faChevronDown} {...props} />

export const ChevronUpIcon = props =>
    <FontAwesomeIcon icon={faChevronUp} {...props} />

export const SortDownIcon = ChevronDownIcon;
export const SortUpIcon = ChevronUpIcon;

export const ChevronLeftIcon = props =>
    <FontAwesomeIcon icon={faChevronLeft} {...props} />

export const ChevronRightIcon = props =>
    <FontAwesomeIcon icon={faChevronRight} {...props} />

export const EllipsisVerticalIcon = props =>
    <FontAwesomeIcon icon={faEllipsisVertical} {...props} />

export const CheckIcon = props =>
    <FontAwesomeIcon icon={faCheck} {...props} />

export const MagnifyingGlassIcon = props =>
    <FontAwesomeIcon icon={faMagnifyingGlass} {...props} />

export const FilterIcon = props =>
    <FontAwesomeIcon icon={faFilter} {...props} />

export const FilterCircleXmarkIcon = props =>
    <FontAwesomeIcon icon={faFilterCircleXmark} {...props} />

export const FileCircleXmarkIcon = props =>
    <FontAwesomeIcon icon={faFileCircleXmark} {...props} />

export const PrintIcon = props =>
    <FontAwesomeIcon icon={faPrint} {...props} />

export const ArrowUpIcon = props =>
    <FontAwesomeIcon icon={faArrowUp} {...props} />

export const CircleCheckIcon = props =>
    <FontAwesomeIcon icon={faCircleCheck} {...props} />

export const PenIcon = props =>
    <FontAwesomeIcon icon={faPen} {...props} />

export const UsersIcon = props =>
    <FontAwesomeIcon icon={faUsers} {...props} />

export const CaretUpIcon = props =>
    <FontAwesomeIcon icon={faCaretUp} {...props} />

export const CaretDownIcon = props =>
    <FontAwesomeIcon icon={faCaretDown} {...props} />

export const EnvelopeIcon = props =>
    <FontAwesomeIcon icon={faEnvelope} {...props} />

export const PhoneIcon = props =>
    <FontAwesomeIcon icon={faPhone} {...props} />

export const XMarkIcon = props =>
    <FontAwesomeIcon icon={faXmark} {...props} />

// export const BellIcon = props => {
//     const classes = useImageIconStyles();
//     return <img
//         alt="Bell icon"
//         src="./icons/bell.png"
//         className={classes.root}
//         {...props}
//     />
// }
