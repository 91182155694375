/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css, Menu, MenuItem, Pagination, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useMemo } from "react";
import { Button, IconButton } from "../../../CoreComponents/Button";
import { Modal } from "../../../CoreComponents/Modal";
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { BaseActivityService } from "../../../Services/BaseActivityService";
import { ActivityTypes, ActivityTypesColors, ActivityTypesTranslationKeysObj, AdminTypes } from "../../../Utils/Constants";
import { AddBaseActivitiesContent } from "./AddBaseActivitiesContent";
import { EditBaseActivity } from "./EditBaseActivity";
import { Input } from "../../../CoreComponents/Input";
import { CheckIcon, EllipsisVerticalIcon, MagnifyingGlassIcon} from "../../../CoreComponents/CustomIcons";
import { CircularLoader } from "../../../CoreComponents/Loaders";
import { useDispatch, useSelector } from "react-redux";
import { setBaseActivities } from "../../../globalStates/tableDataState";

const baseActivitiesStyles = {
    loadContainer: css`
        width: 100%;
        height: 100%;
        display: flex;
    `,
    root: css`
        height: 100%;
        width: 100%;
        padding: 20px 40px;

        & .custom-actions {
            display: flex;
            margin-bottom: 20px;

            & button {
                min-width: unset;
            }
        }

        & .filter {
            margin-left: auto;
            padding-right: 15px;
        }

        & .card-container {
            height: calc(100% - 148px);
            overflow-y: auto;
            padding: 20px 20px 20px 0px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, 280px);
            grid-template-rows: repeat(auto-fit, 240px);
            grid-gap: 30px;
            justify-content: center;
        }

        & .card {
            min-height: 240px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 8px 16px #28326529;
            border-radius: 8px;

            & .color-header {
                border-radius: 8px 8px 0px 0px;
                height: 16px;
                width: 100%;

                &.color-header-1 {
                    background: ${ActivityTypesColors[ActivityTypes.Mobility]} 0% 0% no-repeat padding-box;
                }
                &.color-header-2 {
                    background: ${ActivityTypesColors[ActivityTypes.Cognitive]} 0% 0% no-repeat padding-box;
                }
                &.color-header-3 {
                    background: ${ActivityTypesColors[ActivityTypes.Balance]} 0% 0% no-repeat padding-box;
                }
                &.color-header-4 {
                    background: ${ActivityTypesColors[ActivityTypes.Kinematics]} 0% 0% no-repeat padding-box;
                }
                &.color-header-5 {
                    background: ${ActivityTypesColors[ActivityTypes.Games]} 0% 0% no-repeat padding-box;
                }
                &.color-header-6 {
                    background: ${ActivityTypesColors[ActivityTypes.UpperExtremity]} 0% 0% no-repeat padding-box;
                }
            }

            & .card-content {
                height: calc(100% - 16px);
                display: flex;
                padding: 20px;

                & button {
                    margin-top: 40px;
                    min-width: 120px;
                }

                & > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: auto;
                }

                & .activityName {
                    text-align: center;
                    font: normal normal bold 24px/24px Mustica Pro;
                    letter-spacing: 0.22px;
                    color: #3A817F;
                }

                & span {
                    padding-top: 9px;
                }
            }
        }
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-left: 20px;
        }
    `,
    paginationRoot: css`
        display: flex;
        justify-content: end;
        margin-bottom: 24px;
        margin-top: 24px;
    `,
    paginationPages: css`
        & button {
            color: #00ABA5;
            &.Mui-disabled {
                opacity: 1;
            }
        }
    `,
    paginationSettings: css`
        display: flex;

        & > * {
            margin: auto 0 auto 8px;
        }

        & > span {
            color: #858585;
            margin-left: auto;
            font: normal normal normal 16px/24px Roboto;
        }

        & > button {
            height: 1.5em;
            width: 1.5em;
        }
    `,
    menuItemIcon: css`
        width: 32px;
    `,
    menuTitle: css`
        width: 100%;
        padding: 9px 20px;
    `,
};

export const BaseActivitiesList = ({ $selectedBaseActivity, $refreshBaseActivities }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;
    const $baseActivities = useHook(tableDataState.baseActivities);
    const $isLoading = useHook(!Boolean(tableDataState.baseActivities.length));
    const $itemsPerPage = useHook(10);
    const $page = useHook(0);
    const $displayedBaseActivities = useHook([]);
    const $isAddBaseActivityModalOpened = useHook(false);
    const $searchText = useHook('');
    const totalItemsCount = $baseActivities.value.length;
    const totalPages = Math.ceil($baseActivities.value.length / $itemsPerPage.value);
    const $itemsPerPageSelectionAnchor = useHook(null);
    const handleOpenItemsPerPageMenu = (e) => $itemsPerPageSelectionAnchor.set(e.currentTarget);
    const handleCloseItemsPerPageMenu = () => $itemsPerPageSelectionAnchor.set(null);
    const availablePaginationOptionsArray = useMemo(() =>
        [10, 25, 50].map(c => ({ label: t('coreComponents.table.paginationInfoMenuItemTemplate', c), value: c }))
        , [t]);

    const onSort = (page, itemsPerPage, searchText) => {
        const filteredRows = searchText ?
            $baseActivities.value.filter(x =>
                x.name.toLowerCase().includes(searchText)
            )
            : $baseActivities.value.slice(0);

        $displayedBaseActivities.set(filteredRows.slice(page * itemsPerPage, (page + 1) * itemsPerPage));
    };

    useEffect(() => {
        if ($isLoading.value) {
            return;
        }

        onSort && onSort($page.value, $itemsPerPage.value, $searchText.value.toLowerCase());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$page.value, $itemsPerPage.value, $searchText.value, $isLoading.value]);

    useEffect(() => {
        $isLoading.set(true);
        BaseActivityService.getBaseActivities(9999)
            .then(BActivity => {
                dispatch(setBaseActivities(BActivity));
                $baseActivities.set(BActivity);
                $isLoading.set(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$refreshBaseActivities.value]);

    return (
        $isLoading.value ?
        <div css={baseActivitiesStyles.loadContainer}>
            <CircularLoader />
        </div>
        :
        <div css={baseActivitiesStyles.root}>
            <div className="custom-actions">
                <Button disabled={!isTrazerAdmin} onClick={() => $isAddBaseActivityModalOpened.set(true)} >
                    <AddIcon />
                </Button>
                <div className="filter">
                    <Input
                        $value={$searchText}
                        placeholder={t('input.searchPlaceholder')}
                        endIcon={<MagnifyingGlassIcon />}
                    />
                </div>
            </div>
            <div className="pagination-settings">
                <div css={baseActivitiesStyles.paginationSettings}>
                    <span>
                        {t('coreComponents.table.paginationInfo',
                            ($page.value * $itemsPerPage.value) + 1,
                            Math.min(totalItemsCount, ($page.value + 1) * $itemsPerPage.value),
                            totalItemsCount
                        )}
                    </span>
                    <IconButton onClick={handleOpenItemsPerPageMenu}>
                        <EllipsisVerticalIcon />
                    </IconButton>
                    <Menu
                        sx={{ mt: '32px' }}
                        id="language-appbar"
                        anchorEl={$itemsPerPageSelectionAnchor.value}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean($itemsPerPageSelectionAnchor.value)}
                        onClose={handleCloseItemsPerPageMenu}
                    >
                        <Typography color="primary" textAlign="left" css={baseActivitiesStyles.menuTitle}>
                            {t('coreComponents.table.paginationInfoMenuTitle')}
                        </Typography>
                        {availablePaginationOptionsArray.map(({ label, value }) => (
                            <MenuItem
                                key={value}
                                onClick={() => {
                                    $page.set(0);
                                    $itemsPerPage.set(value);
                                    handleCloseItemsPerPageMenu();
                                }}
                            >
                                <div css={baseActivitiesStyles.menuItemIcon}>
                                    {$itemsPerPage.value === value ?
                                        <CheckIcon />
                                        : null}
                                </div>
                                <Typography textAlign="left">{label}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </div>

            <div className="card-container">
                {$displayedBaseActivities.value.map(x => (
                    <div key={x.baseActivityId} className="card">
                        <div className={`color-header color-header-${x.activityTypeId}`}></div>
                        <div className="card-content">
                            <div>
                                <div className="activityName">
                                    <span>{x.name}</span>
                                </div>
                                <span> {t(ActivityTypesTranslationKeysObj[x.activityTypeId])}</span>
                                <Button disabled={!isTrazerAdmin} onClick={() => $selectedBaseActivity.set(x)} >
                                    {t('actions.edit')}
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Modal
                open={$isAddBaseActivityModalOpened.value}
                onClose={() => $isAddBaseActivityModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddBaseActivitiesContent onCancel={() => $isAddBaseActivityModalOpened.set(false)}
                    onAdd={() => {
                        $isAddBaseActivityModalOpened.set(false);
                        $refreshBaseActivities.set({});
                    }} />
            </Modal>
            {totalPages > 1 &&
                <div css={baseActivitiesStyles.paginationRoot}>
                    <Pagination
                        css={baseActivitiesStyles.paginationPages}
                        count={totalPages}
                        page={$page.value + 1}
                        onChange={(e, newPage) => $page.set(newPage - 1)}
                        color="primary"
                    />
                </div>
            }
        </div>
    )
};

export const BaseActivities = () => {
    const $selectedBaseActivity = useObjectHook(null);
    const $refreshBaseActivities = useHook({});
    const $prevSelectedBaseActivities = useHook(null);

    useEffect(() => {
        if ($prevSelectedBaseActivities.value && $selectedBaseActivity.value) {
            // changes...
            $refreshBaseActivities.set({});
            $prevSelectedBaseActivities.set(null);
            $selectedBaseActivity.set(null);
            return;
        }
        $prevSelectedBaseActivities.set($selectedBaseActivity.value);
    }, [$selectedBaseActivity.value]);

    return (
        <>
            <BaseActivitiesList $selectedBaseActivity={$selectedBaseActivity} $refreshBaseActivities={$refreshBaseActivities} />
            <Modal
                open={!!$selectedBaseActivity.value}
                onClose={() => $selectedBaseActivity.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EditBaseActivity
                    onCancel={() => $selectedBaseActivity.set(null)}
                    $selectedBaseActivity={$selectedBaseActivity}
                />
            </Modal>
        </>
    )
}
