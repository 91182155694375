/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { InternalLink, TabLayout } from '../../../CoreComponents/Layout';
import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { Modal } from '../../../CoreComponents/Modal';
import { Button } from '../../../CoreComponents/Button';
import { AddCustomerContent } from './AddCustomerContent';
import { useTranslation } from '../../../CoreComponents/Translation';
import { CustomerService } from '../../../Services/CustomerService';
import { shortDate, defaultSort } from '../../../Utils/Common';
import { SitesTab } from './SitesTab';
import { CustomerTab } from './CustomerTab';
import { IconButton } from '../../../CoreComponents/Button';
import { AdminTypes, AdvancedSearchType, TableOptionsTypes, SortOrder } from '../../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomers } from '../../../globalStates/tableDataState';

const customersListStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}

const CustomerList = ({ $selectedCustomer, $refreshCustomers }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const isSuperAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.SuperAdmin;
    const $customers = useHook(tableDataState.customers);
    const $siteNames = useHook([]);

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 150,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        {!isTrazerViewAdmin && !isSuperAdmin &&
                            <IconButton disabled={isTrazerViewAdmin || isSuperAdmin} onClick={() => $selectedCustomer.set(rowData)}>
                                <EditIcon />
                            </IconButton>
                        }
                        <IconButton
                            component={InternalLink}
                            to={`/reports/customer/${rowData.customerId}`}
                        >
                            <SignalCellularAltIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('customers.table.name'),
            columnWidth: 280,
            isSortable: true,
        },
        {
            id: 'dateCreated',
            label: t('customers.table.createdOn'),
            columnWidth: 150,
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.dateCreated ? shortDate(new Date(rowData.dateCreated)) : '-';
            }
        },
        {
            id: 'sites',
            label: t('customers.table.sites'),
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($siteNames.value, 'value', SortOrder.Asc)
            },
            isSortable: true,
            // textRenderer: ({ rowData }) => {
            //     return rowData.siteNames;
            // },
            CellRender: ({ rowData }) => {
                return rowData.siteNames;
            }
        },
    ], [t, $siteNames.value, $customers.value]);

    const $isAddCustomerModalOpened = useHook(false);
    const $isLoading = useHook(!Boolean(tableDataState.customers.length));

    useEffect(() => {
        CustomerService.getAll(9999)
            .then(customers => {
                const sites = {};
                let updatedCustomers = customers;

                if (isSuperAdmin) {
                    updatedCustomers = updatedCustomers.filter(x => x.customerId === storeState.currentUser.admin.customerId)
                }
                
                for (const customer of updatedCustomers) {
                    customer.sitesObj = customer.sites;
                    customer.sites = customer.sites?.map(x => x.name);
                    customer.siteNames = customer.sites?.length ? customer.sites.join(', ') : '-';
                    for (const site of customer.sites) {
                        sites[site] = 1
                    }
                }
                $siteNames.set(Object.keys(sites).map(value => ({ value, label: value })));
                
                dispatch(setCustomers(updatedCustomers));
                $customers.set(updatedCustomers);
                $isLoading.set(false);
            });
    }, [$refreshCustomers.value]);

    return (
        <div css={customersListStyles.root}>
            <TableCSF
                customActions={!isTrazerViewAdmin && !isSuperAdmin &&
                    <div css={customersListStyles.actions}>
                        <Button disabled={isTrazerViewAdmin || isSuperAdmin} onClick={() => $isAddCustomerModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$customers.value}
                rowKeySelector={r => r.customerId}
                isLoading={$isLoading.value}
                totalItemsCount={$customers.value.length}
                enablePersistingFilters
                pageName={TableOptionsTypes.Customers}
            />
            <Modal
                open={$isAddCustomerModalOpened.value}
                onClose={() => $isAddCustomerModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddCustomerContent onCancel={() => $isAddCustomerModalOpened.set(false)}
                    onAdd={() => {
                        $isAddCustomerModalOpened.set(false);
                        $refreshCustomers.set({});
                    }} />
            </Modal>
        </div>
    );
};

export const Customers = () => {
    const $selectedCustomer = useObjectHook(null);
    const { t } = useTranslation();
    const $tabs = useHook([]);
    const $refreshCustomers = useHook({});
    const $prevSelectedCustomer = useHook(null);

    useEffect(() => {
        if (!$selectedCustomer.value) {
            return;
        }

        $tabs.set([
            { name: t('customers.editModal.editCustomerTabName'), ContentComponent: () => <CustomerTab $selectedCustomer={$selectedCustomer} />, isDefault: true },
            { name: t('customers.editModal.sitesTabName'), ContentComponent: () => <SitesTab $selectedCustomer={$selectedCustomer} $refreshCustomers={$refreshCustomers} />},
        ]);
    }, [$selectedCustomer.value]);

    useEffect(() => {
        if ($prevSelectedCustomer.value && $selectedCustomer.value) {
            $refreshCustomers.set({});
            $prevSelectedCustomer.set(null);
            $selectedCustomer.set(null);
            return;
        }

        $prevSelectedCustomer.set($selectedCustomer.value);
    }, [$selectedCustomer.value]);

    return (
        <>
            <CustomerList $selectedCustomer={$selectedCustomer} $refreshCustomers={$refreshCustomers} />
            <Modal
                open={!!$selectedCustomer.value}
                onClose={() => $selectedCustomer.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fixedModalPosition
            >
                <TabLayout items={$tabs.value} />
            </Modal>
        </>
    )
}
