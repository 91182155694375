// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { useMemo, useRef, useState } from 'react';
import {
    ChartPadding,
    ChartResizeDelay,
    DefaultAnimationDuration,
    SharedPluginsConfig,
    ChartColors,
    useChartPrintEffect,
    chartStyles
} from '../../Utils/Constants';
import { ChartOptionsMenu } from './ChartOptionsMenu';
import { Radar } from 'react-chartjs-2';

export const RadarChart = ({ datasets, maxHeight, isPrinting, ...props }) => {
    const chartRef = useRef(null);
    const [activeDataSetIndex, setActiveDataSetIndex] = useState(null);
    const [size, setNewSize] = useState(null);
    const chartLabels = useMemo(() => {
        const labels = {};
        for (let ds of datasets) {
            for (let dataEntry of ds.data) {
                labels[dataEntry.label] = 1;
            }
        }

        return Object.keys(labels);
    }, [datasets]);

    /** @type import('chart.js/dist/types').ChartOptions<'radar'> */
    const options = {
        responsive: true,
        // aspectRatio: 1.5,
        maintainAspectRatio: false,
        resizeDelay: isPrinting ? 0 : ChartResizeDelay,
        layout: {
            padding: ChartPadding,
        },

        // color: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(255, 255, 255, 1)',

        onResize: (chart, newSize) => {
            // if (maxHeight && newSize.height > maxHeight) {
            //     chart.resize(maxHeight / chart.aspectRatio, maxHeight);
            // }
            setNewSize(newSize);
        },

        animation: {
            duration: DefaultAnimationDuration
        },

        plugins: {
            ...SharedPluginsConfig,
        },

        scales: {
            r: {
                // min: 0,
                beginAtZero: true,
            }
        },
    };

    const onHover = (e, item) => {
        if (item.length) {
            setActiveDataSetIndex(item[0].datasetIndex);
        } else {
            setActiveDataSetIndex(null);
        }
    };

    if (datasets.length > 1) {
        options.onHover = onHover;
    }

    if (activeDataSetIndex != null || isPrinting) {
        options.animation.duration = 0;
    }

    /** @type import('chart.js/dist/types').ChartData<'radar'> */
    const chartData = {
        labels: chartLabels,
        datasets:
            datasets.map((ds, index) => ({
                label: ds.label,
                data: ds.data.map(x => x.value),
                // fill: true,
                spanGaps: true,
                // backgroundColor: 'rgba(0, 171, 165, 0.2)',
                // borderColor: 'rgba(0, 171, 165, 1)',
                borderWidth: 4,

                pointHitRadius: 8,
                // pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                pointRadius: 8,
                pointBorderWidth: 4,
                // pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
                pointHoverRadius: 8,
                pointHoverBorderWidth: 4,

                ...ChartColors[index % ChartColors.length],

                ...(activeDataSetIndex != null && activeDataSetIndex !== index ?
                    {
                        backgroundColor: `rgba(64, 64, 64, 0.1)`,
                        borderColor: `rgba(64, 64, 64, 0.1)`,
                        pointBackgroundColor: 'rgba(255, 255, 255, 0.1)',
                        pointHoverBackgroundColor: 'rgba(255, 255, 255, 0.1)',
                    } : {})
            }))
    };

    useChartPrintEffect(chartRef.current, size);

    return (props.enableOptionsMenu ?
        <ChartOptionsMenu
            chartRef={chartRef}
            title={props.title}
            unitType={props.unitType}
            exportFileName={props.exportFileName}
            datasets={datasets}
            chartComponent={
                <Radar ref={chartRef} data={chartData} options={options} />
            }
        />
        :
        <div css={chartStyles.responsiveContainer}>
            <Radar ref={chartRef} data={chartData} options={options} />
        </div>
    );
};
