import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
    isEditMode: false,
    users: [],
    customers: []
}

export const dashboardState = createSlice({
    name: 'dashboardState',
    initialState: initialDashboardState,
    reducers: {
        setEditMode: (state, action) => {
            state.isEditMode = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setCustomers: (state, action) => {
            state.customers = action.payload;
        },
    }
})

export const {
    setEditMode,
    setUsers,
    setCustomers
} = dashboardState.actions;

export default dashboardState.reducer;
