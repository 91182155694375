import { getData, postData, putData, urlQueryString } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const ScriptedActivitiesService = {
    getAllScriptedActivities: function (limit) {
        const qs = urlQueryString({
            limit,
        }, true);
        return getData(`${ApiHost}/api/ScriptedActivities?${qs}`);
    },
    getScriptedActivity: function (id) {
        return getData(`${ApiHost}/api/ScriptedActivities/${id}`);
    },
    getScriptedActivitiesFiltered: function (customerId, siteId) {
        return postData(`${ApiHost}/api/ScriptedActivities/filtered`, {
            customerId,
            siteId,
        });
    },
    getScriptedActivitiesByCategory: function (categoryId) {
        return getData(`${ApiHost}/api/ScriptedActivities/ByCategory/${categoryId}`);
    },
    getScriptedActivitiesCustomerLibrary: function (categoryId) {
        return getData(`${ApiHost}/api/ScriptedActivities/CustomerLibrary/${categoryId}`);
    },
    postScriptedActivity: function (scriptedActivity) {
        return postData(`${ApiHost}/api/ScriptedActivities`, scriptedActivity);
    },
    updateScriptedActivity: function (scriptedActivity) {
        return putData(`${ApiHost}/api/ScriptedActivities`, scriptedActivity);
    },
};
