/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {  useRef } from 'react';
import { Button } from '../CoreComponents/Button';
import { useTranslation } from '../CoreComponents/Translation';
import { useHook, useObjectHook } from '../CoreComponents/Utils';
import { UserService } from '../Services/UserService';
import { newGUID } from '../Utils/Common'
import { SiteService } from '../Services/SiteService';
import { Input } from '../CoreComponents/Input';
import { AlertText } from '../CoreComponents/Alert';
import { PINValidator, combineValidators, digitsValidator, emailValidator, maxLengthValidator, maxValueValidator, minValueValidator, nameValidator, rangeLengthValidator, requiredValidator, usernameValidator } from '../Utils/Validators';
import { DatePicker, datePickerRangeValidator } from '../CoreComponents/DatePicker';
import { DominanceTranslationKeysArr, GendersTranslationKeysArr } from '../Utils/Constants';
import { subYears } from 'date-fns';
import { SingleSelect } from '../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../CoreComponents/SelectListComponents/SelectOption';

const inputVerticalGap = 30;
const registerPageStyles = {
    root: css`
        width: 100vw;
        max-width: 1200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 28px;
        margin: auto;
        overflow-y: auto;

        & > h1 {
            width: 100%;
            text-align: center;
            margin-bottom: 50px;
        }

        & > .code-input, & > .success-message {
            max-width: 800px;
            margin: auto;
            line-height: 1.14em;
            text-align: center;
        }

        & > .content {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
            gap: ${inputVerticalGap}px 40px;

            & > .height-weight {
                & > div:nth-of-type(2) {
                    margin: 0;
                    width: 30%;
                }

                & > .height {
                    width: 70%;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 20px 0 0;

                    & > * {
                        width: calc(50% - 10px);

                        &:last-child {
                            margin-left: 20px;
                        }
                    }

                    & > label {
                        width: 100%;
                        margin: 0 auto 16px 0;
                        letter-spacing: 0.14px;
                        color: #858585;
                        font: normal normal bold 16px Mustica Pro;

                        &.invalid {
                            color: #E0758A;
                        }
                    }
                }
            }

            & > * {
                display: inline-flex;

                & .MuiSelect-root {
                    min-width: unset;
                }

                width: 100%;
                margin: 0;
                &.height-weight {
                    margin: 0;
                    width: 100%;
                }

                @media (min-width: 800px) {
                    width: calc(50% - 40px);
                    &.height-weight {
                        width: calc(50% - 40px);
                    }
                }
            }
        }

        & > .alert-text-root {
            margin-top: auto;
            align-self: center;
            justify-content: center;
            @media (min-width: 400px) {
                margin-left: auto;
            }
        }

        & > .actions {
            margin-top: auto;
            display: flex;
            width: 100%;
            gap: 10px;

            justify-content: center;
            & > button {
                width: 50%;
            }
            @media (min-width: 400px) {
                & > button:first-of-type {
                    margin-left: auto;
                }

                & > button {
                    width: unset;
                }
            }

            & > button:only-of-type {
                width: 100%;
            }
        }

        & .alert-text-root+.actions {
            margin-top: 20px;
        }
    `,
};

const cumulativeOffset = (element) => {
    let top = 0;
    do {
        top += element.offsetTop || 0;
        element = element.offsetParent;
    } while (element);

    return top;
};

const UserValidators = {
    firstName: [requiredValidator, maxLengthValidator(50), nameValidator],
    lastName: [requiredValidator, maxLengthValidator(50), nameValidator],
    username: [usernameValidator, rangeLengthValidator(6, 20)],
    email: [maxLengthValidator(50), emailValidator],
    weight: [requiredValidator, digitsValidator, minValueValidator(1)],
    phoneNumber: [],
    pin: [PINValidator],
    dateOfBirth: [
        requiredValidator,
        datePickerRangeValidator(subYears(new Date(), 150), new Date())
    ],
    gender: [requiredValidator],
    dominance: [],
};

const RegistrationSteps = {
    Code: 1,
    ProfileInfo: 2,
    SuccessMessage: 3,
};

// TODO: Split in 3 components
export const RegisterPage = () => {
    const { t } = useTranslation();
    const $currentStep = useHook(RegistrationSteps.Code);
    const inputRef = useRef(null);
    const nextButtonRef = useRef(null);
    const $currentProfilePage = useHook(0);
    const $profileItemsPerPageCount = useHook(5);
    const $code = useHook('', requiredValidator);
    const $errorMessage = useHook('');
    const $site = useHook(null);
    const $isCheckingSite = useHook(false);
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const $currentPageValidators = useHook({});
    const $newUser = useObjectHook(
        {
            userId: newGUID(),
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            phoneNumber: '',
            dateOfBirth: '',
            gender: '',
            dominance: 0,
            height: '',
            weight: '',
            pin: '',
            userSites: [],
            userGroups: []
        },
        $currentPageValidators.value,
        null,
        $isForceValidating.value
    );
    const $feet = useHook('', combineValidators([digitsValidator, minValueValidator(1), maxValueValidator(10)]));
    const $inches = useHook('', combineValidators([requiredValidator, digitsValidator]));

    const validateSitePin = event => {
        event.preventDefault();
        $isCheckingSite.set(true);
        SiteService.getByPin($code.value)
            .then(site => {
                $errorMessage.set('');
                $site.set(site);
                $currentStep.set(RegistrationSteps.ProfileInfo);

                $newUser.resetValidity();
                const elementHeight = $code.isValid ? inputRef.current.clientHeight : 80;
                const containerHeight = cumulativeOffset(nextButtonRef.current) - cumulativeOffset(inputRef.current);
                const profileItemsPerPageCount = Math.trunc(containerHeight / (elementHeight + inputVerticalGap));
                $profileItemsPerPageCount.set(profileItemsPerPageCount);
                refreshCurrentPageValidators(profileItemsPerPageCount, 0);
            })
            .catch(e => {
                $errorMessage.set(t('register.message.invalidCode', $newUser.value.username));
            })
            .finally(() => $isCheckingSite.set(false));

        return false;
    }

    const firstInputIndex = $profileItemsPerPageCount.value * $currentProfilePage.value;
    const inputs = [
        {
            component: <Input key="firstName" placeholder={t('input.placeholder')} required label={t('users.fieldName.firstName')} $value={$newUser.getPropHook('firstName')} />,
            validators: { firstName: UserValidators.firstName },
        },
        {
            component: <Input key="lastName" placeholder={t('input.placeholder')} required label={t('users.fieldName.lastName')} $value={$newUser.getPropHook('lastName')} />,
            validators: { lastName: UserValidators.lastName },
        },
        {
            component: <Input key="username" placeholder={t('input.placeholder')} label={t('users.fieldName.username')} $value={$newUser.getPropHook('username')} />,
            validators: { username: UserValidators.username },
        },
        {
            component: <Input key="email" placeholder={t('input.placeholder')} label={t('users.fieldName.email')} $value={$newUser.getPropHook('email')} />,
            validators: { email: UserValidators.email },
        },
        {
            component: <Input key="phoneNumber" placeholder={t('input.placeholder')} label={t('users.fieldName.phoneNumber')} $value={$newUser.getPropHook('phoneNumber')} />,
            validators: { phoneNumber: UserValidators.phoneNumber },
        },
        {
            component:
                <SingleSelect key="gender" $value={$newUser.getPropHook('gender')} required label={t('users.fieldName.gender')} disableFiltering>
                    {GendersTranslationKeysArr.map(x => (
                        <SelectOption key={x.value} value={x.value}>{t(x.translationKey)}</SelectOption>
                    ))}
                </SingleSelect>,
            validators: { gender: UserValidators.gender },
        },
        {
            component:
                <SingleSelect key="dominance" $value={$newUser.getPropHook('dominance')} label={t('users.fieldName.dominance')} disableFiltering>
                    {DominanceTranslationKeysArr.map(x => (
                        <SelectOption key={x.value} value={x.value}>{t(x.translationKey)}</SelectOption>
                    ))}
                </SingleSelect>,
            validators: { dominance: UserValidators.dominance },
        },
        {
            component:
                <DatePicker
                    key="dateOfBirth"
                    label={t('users.fieldName.dateOfBirth')}
                    required
                    placeholder={t('input.placeholder')}
                    $value={$newUser.getPropHook('dateOfBirth')}
                    maxValue={new Date()}
                    minValue={subYears(new Date(), 150)}
                />,
            validators: { dateOfBirth: UserValidators.dateOfBirth },
        },
        {
            component:
                <div key="height-weight" className="height-weight">
                    <div className="height">
                        <label className={(!$feet.isValid || !$inches.isValid) ? "invalid" : ''}>
                            {t('users.fieldName.height')} <span className="required">*</span>
                        </label>
                        <div>
                            <Input endIcon={<span>{t('constants.unitType.feetShort')}</span>} $value={$feet} />
                        </div>
                        <div>
                            <Input
                                onBlur={() => {
                                    const value = Number($inches.value)

                                    const feet = Math.floor(value / 12);
                                    if (feet) {
                                        $feet.set(feet);
                                    }
                                    $inches.set('' + (value - feet * 12));
                                }}
                                endIcon={<span>{t('constants.unitType.inchShort')}</span>}
                                $value={$inches}
                            />
                        </div>
                    </div>
                    <Input
                        label={t('users.fieldName.weight')}
                        required
                        $value={$newUser.getPropHook('weight')}
                        endIcon={<span>{t('constants.unitType.poundShort')}</span>}
                    />
                </div>,
            validators: { weight: UserValidators.weight, height: [] },
        },
        {
            component: <Input key="pin" placeholder={t('input.placeholder')} label={t('users.fieldName.optionalPin')} $value={$newUser.getPropHook('pin')} />,
            validators: { pin: UserValidators.pin },
        },
    ];

    const currentPageInputs = inputs
        .slice(firstInputIndex, firstInputIndex + $profileItemsPerPageCount.value)
        .map(x => x.component);

    const refreshCurrentPageValidators = (profileItemsPerPageCount, currentProfilePage) => {
        const firstInputIndex = profileItemsPerPageCount * currentProfilePage;
        const currentPageValidators = inputs
            .slice(firstInputIndex, firstInputIndex + profileItemsPerPageCount)
            .map(x => x.validators)
            .reduce((a, c) => ({ ...a, ...c }), {});
        $currentPageValidators.set(currentPageValidators);
    };

    const addUser = (direction) => {
        $errorMessage.set('');
        $newUser.resetValidity();
        $feet.resetValidity();
        $inches.resetValidity();

        if (direction < 0) {
            $isForceValidating.set(false);

            if ($currentProfilePage.value) {
                $currentProfilePage.set($currentProfilePage.value - 1);
                refreshCurrentPageValidators($profileItemsPerPageCount.value, $currentProfilePage.value - 1);
            } else {
                $currentStep.set(RegistrationSteps.Code);
                $code.resetValidity();
            }

            return;
        }

        $isForceValidating.set(true);
        const errors = $newUser.validate();

        if (errors) {
            $errorMessage.set(t('validatorErrors.emptyFormWarning'));
            return;
        }

        if (Object.hasOwnProperty.call($currentPageValidators.value, 'height')) {
            const feetErrors = $feet.validate();
            const inchesErrors = $inches.validate();
            if (feetErrors || inchesErrors) {
                $errorMessage.set(t('validatorErrors.invalidDataFormWarning'));
                return;
            }
        }

        if (firstInputIndex + $profileItemsPerPageCount.value < inputs.length) {
            $isForceValidating.set(false);
            $currentProfilePage.set($currentProfilePage.value + 1);
            refreshCurrentPageValidators($profileItemsPerPageCount.value, $currentProfilePage.value + 1);
            return;
        }

        const newUser = {
            ...$newUser.value,
            height: ($feet.value * 12) + Number($inches.value),
            email: $newUser.value.email ? $newUser.value.email : null
        };
        $newUser.set(newUser);

        $isSaving.set(true);
        UserService.registerUser($site.value.siteId, newUser)
            .then(user => {
                $currentStep.set(RegistrationSteps.SuccessMessage);
                $newUser.set(user);
            })
            .catch(error => $errorMessage.set(error))
            .finally(() => $isSaving.set(false));
    }

    return (
            <div css={registerPageStyles.root}>
                {$currentStep.value === RegistrationSteps.SuccessMessage ?
                    <div className="success-message">
                        <h1>{t('register.message.success')}</h1>
                        <p>
                            {t('register.message.successDescription', $newUser.value.username)}
                        </p>
                    </div>
                    :
                    <>
                        <h1>{t('register.title')}</h1>
                        {$currentStep.value === RegistrationSteps.Code ?
                            <>
                                <div className="content">
                                    <Input
                                        containerRef={inputRef}
                                        placeholder={t('input.placeholder')}
                                        required
                                        label={t('register.fieldName.code')}
                                        $value={$code}
                                    />
                                </div>
                                {$errorMessage.value &&
                                    <AlertText variant="large">{$errorMessage.value}</AlertText>
                                }
                                <div className="actions">
                                    <Button
                                        ref={nextButtonRef}
                                        disabled={$isCheckingSite.value || !$code.isValid}
                                        onClick={validateSitePin}
                                    >
                                        {t('actions.next')}
                                    </Button>
                                </div>
                            </>
                            :
                            <>
                                <div className="content">
                                    {currentPageInputs.concat()}
                                </div>
                                {$errorMessage.value &&
                                    <AlertText variant="large">{$errorMessage.value}</AlertText>
                                }
                                <div className="actions">
                                    <Button variant="outlined" disabled={$isSaving.value} onClick={() => addUser(-1)}>
                                        {t('actions.previous')}
                                    </Button>
                                    <Button disabled={$isSaving.value || !$newUser.isValid} onClick={() => addUser(1)}>
                                        {t('actions.next')}
                                    </Button>
                                </div>
                            </>
                        }
                    </>}
            </div>
    );
}
