/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useMemo } from "react";
import { ActivitySettingsTable } from "../../../../CoreComponents/ActivitySettingsTable";
import { Button } from "../../../../CoreComponents/Button";
import { Checkbox } from "../../../../CoreComponents/Checkbox";
import { Input, TextareaInput } from "../../../../CoreComponents/Input";
import { TabLayout } from "../../../../CoreComponents/Layout";
import { useTranslation } from "../../../../CoreComponents/Translation";
import { ForceValidateContext, useHook, useObjectHook } from "../../../../CoreComponents/Utils";
import { digitsValidator, maxLengthValidator, requiredValidator } from "../../../../Utils/Validators";
import { AlertText } from "../../../../CoreComponents/Alert";

const editScriptedActivityStyles = {
    root: css`
        padding: 40px 0 40px 40px;
        width: unset;
        height: 85vh;
        display: flex;
        flex-direction: column;

        & h2 {
            margin: 0 0 30px 0;
        }

        & > .horizontal-line {
            border-top: 2px solid rgb(211,211,211);
            margin-top: 20px;
            width: 100%;
        }

        & .instructions {
            width: 100%;
            padding-top: 32px;
            margin-left: 0;
            display: flex;
            flex-direction: column;

            & textarea {
                height: 160px;
                resize: none;
                overflow-y: auto;
            }
        }

        & .fields-container{
            display:flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 30px;

            & > .input-root {
                width: 100%;
                margin: 0 0 20px 0;
            }

            & > .skippable {
                margin: 0;
            }
        }

        @media (min-width: 1200px) {
            width: 900px;
            & .fields-container {
                & > .input-root {
                    margin: 0 40px 0 0;
                    width: unset;
                }

                & > .name {
                    width: 50%;
                }

                & > .skippable {
                    margin-right: 0;
                    margin-top: 35px;
                }
            }
        }

        & > .actions {
            display: flex;
            width: 100%;
            margin-top: auto;

            & > button:first-of-type {
                border-radius: 8px;
                width: 136px;
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }
    `
};

export const EditStepContentInformation = ({ $activityStep, $isSaving, onEdit }) => {
    const $isForceValidating = useHook(false);
    const { t } = useTranslation();

    const editStep = () => {
        $isForceValidating.set(true);
        const errors = $activityStep.validate();
        if (errors) {
            return;
        }

        onEdit($activityStep.value);
    }

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={editScriptedActivityStyles.root}>
                <h2>{t('activitySteps.editModal.title')}</h2>
                <div className="fields-container">
                    <Input
                        id="name"
                        className="name"
                        placeholder={t('input.placeholder')}
                        required
                        label={t('activitySteps.fieldName.name')}
                        $value={$activityStep.getPropHook('name')}
                    />
                    <Input
                        id="order"
                        className="order"
                        placeholder={t('input.placeholder')}
                        required
                        label={t('activitySteps.fieldName.order')}
                        $value={$activityStep.getPropHook('orderId')}
                    />
                    <Checkbox
                        id="skippable"
                        className="skippable"
                        checked={$activityStep.value.isSkipped}
                        onChange={value => {
                            $activityStep.set({
                                ...$activityStep.value,
                                isSkipped: value
                            })
                        }}
                        label={t('activitySteps.fieldName.isSkipped')}
                    />
                </div>
                <div className="horizontal-line" />
                <TextareaInput
                    className="instructions"
                    placeholder={t('input.placeholder')}
                    label={t('activitySteps.fieldName.instructions')}
                    $value={$activityStep.getPropHook('instructions')}
                />
                <div className="actions" css={editScriptedActivityStyles.actions}>
                    {$activityStep.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                    <Button disabled={$isSaving.value || !$activityStep.isValid} onClick={editStep}>{t('actions.save')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
}

const settingsTabStyle = {
    root: css `
        height: 85vh;
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        & h2 {
            margin: 0 0 30px 0;
        }

        @media (min-width: 1200px) {
            width: 900px;
        }
    `,
    table: css`
        height: calc(100% - 110px - 1.5em);
        overflow: auto;
        margin-left: -20px;
    `,
    actions: css`
        display: flex;
        flex-direction: row-reverse;
        margin-top: auto;
    `
}

export const EditStepContentSettings = ({ $activityStep, $isSaving, onEdit }) => {
    const { t } = useTranslation();

    return (
        <div css={settingsTabStyle.root}>
            <h2>{t('activitySteps.editModal.title')}</h2>
            <div css={settingsTabStyle.table}>
                <ActivitySettingsTable
                    settings={$activityStep.value.settings}
                    onChange={settings => $activityStep.set({
                        ...$activityStep.value,
                        settings
                    })}
                />
            </div>
            <div className="actions" css={settingsTabStyle.actions}>
                <Button disabled={$isSaving.value || !$activityStep.isValid} onClick={() => onEdit($activityStep.value)}>{t('actions.save')}</Button>
            </div>
        </div>
    )
}

const EditActivityStepValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
    orderId: [requiredValidator, digitsValidator],
    baseActivity: [requiredValidator],
};

export const EditActivityStepModal = ({ scriptedActivity, activityStep, $isSaving, onEdit }) => {
    const { t } = useTranslation();

    const $activityStep = useObjectHook(
        {
            ...activityStep,
            settings: activityStep?.settings && JSON.parse(activityStep.settings)
        },
        EditActivityStepValidator,
        (oldState, newState) => {
            if (scriptedActivity.scriptedActivitySteps
                .some(step =>
                    Number(step.orderId) === Number(newState.orderId)
                    && step.scriptedActivityStepId !== newState.scriptedActivityStepId
                )
            ) {
                return {
                    orderId: [
                        t('scriptedActivity.fieldError.existingOrderNumber')
                    ]
                }
            }

            return null;
        }
    );

    const tabs = useMemo(() => [
        {
            name: t('activitySteps.editModal.stepInformation'),
            ContentComponent: (props) => <EditStepContentInformation {...props} />
        },
        {
            name: t('activitySteps.editModal.settings'),
            ContentComponent: (props) => <EditStepContentSettings {...props} />
        },
    ], [t]);

    return (
        <TabLayout items={tabs} activeTabProps={{ $isSaving, onEdit, $activityStep }} />
    )
}
