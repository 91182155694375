/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTranslation } from '../../../../CoreComponents/Translation';
import { ReportTable } from '../../ReportTable';
import { RadarChart } from '../../../../CoreComponents/ChartComponents/RadarChart';
import { LineChart } from '../../../../CoreComponents/ChartComponents/LineChart';
import { NoData } from '../../../../Utils/Constants';

export const chartCardStyles = css`
    position: relative;
    display: flex;
    // flex-direction: row;
    width: 100%;
    padding: 40px;
    margin-bottom: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #28326529;
    border-radius: 8px;
    // width: 1272px;
    width: 100%;
    break-inside: avoid;
    overflow-x: auto;

    & .info {
        position: absolute;
        // left: 40px;
        // top: 40px;
        z-index: 1;

        & h2 {
            margin: 0 0 9px 0;
        }

        & span {
            font: normal normal normal 16px/24px Roboto;
        }
    }

    & .chart-container {
        top: 0;

        width: 60%;
        min-height: 400px;
        max-height: 600px;
    }

    & .vertical-line {
        position: relative;

        &:after {
            content: '';
            width: 0;
            height: 100%;
            position: absolute;
            border-radius: 2px;
            border: 1px solid #CBCBCB;
            top: 0;
        }
    }

    & .table-data {
        margin: 0 0 12px 40px;
        width: 40%;
    }

    & .radar-chart-container {
        min-height: 460px;
    }

    & .kinematic-chart-container,
    & .upper-extremity-chart-container {
        min-height: unset;
        height: unset;
        max-height: 600px;
    }

    @media screen and (max-width: 1360px) {
        flex-direction: column;
        & .chart-container {
            border-right: none;

            width: 100%;
            max-height: 600px;
            height: 1px;
        }

        & .vertical-line {
            display: none;
        }

        & .kinematic-chart-container,
        & .upper-extremity-chart-container {
            min-height: unset;
            height: unset;
        }

        & .table-data {
            margin: 0;
            width: 100%;
        }
    }

    &.multiple-results {
        flex-direction: column;
        & .chart-container {
            border-right: none;

            width: 100%;
            max-height: 600px;
            height: 1px;
        }

        & .vertical-line {
            display: none;
        }

        & .kinematic-chart-container,
        & .upper-extremity-chart-container {
            min-height: unset;
            height: unset;
        }

        & .table-data {
            margin: 0;
            width: 100%;
        }
    }

    &.history {
        & .chart-container {
            position: sticky;
        }

        @media (max-width: 1600px) {
            & .chart-container {
                width: 50%;
            }

            & .table-data {
                width: 50%;
            }
        }

        @media (max-width: 1360px) {
            flex-direction: column;

            & .chart-container {
                position: relative;
                width: 100%;
                height: 1px;
            }

            & .vertical-line {
                display: none;
            }

            & .table-data {
                margin: 0;
                width: 100%;
            }
        }
    }

    @media print {
        & .chart-container {
            position: static;
            height: 400px;
            min-height: 400px;
            width: 55%;

            &.kinematic-chart-container,
            &.upper-extremity-chart-container {
                max-height: unset;
                height: 100%;
            }
        }

        // &.history {
        //     & .chart-container {
        //         width: 50%;
        //     }
        // }

        & .table-data {
            padding: 60px 10px;
            width: 40%;
        }
    }
`;
export const RadarChartSection = ({ isPrinting, sectionName, units, datasets }) => {
    const data = useMemo(() => {
        const chartData = [];
        const tableData = {};
        const tableDataKeys = Object.keys(datasets.length ? datasets[0].data.tableData : {});
        for (const ds of datasets) {
            // if (!ds.data.tableData) {
            //     continue;
            // }
            if (ds.data?.chartData) {
                chartData.push({
                    ...ds,
                    data: ds.data.chartData
                });
            }

            for (const key of tableDataKeys) {
                if (!tableData[key]) {
                    tableData[key] = {
                        rowName: key,
                    };
                }

                tableData[key][ds.label] = ds.data.tableData[key];
            }
        }

        return {
            chartData,
            tableData: Object.values(tableData)
        };
    }, [datasets]);

    return (!datasets.length ? null :
        <div css={chartCardStyles} className={datasets.length > 2 ? 'multiple-results' : null}>
            <div className="info">
                <h2>{sectionName}</h2>
                <span>{units}</span>
            </div>
            <div className="chart-container radar-chart-container">
                <RadarChart
                    isPrinting={isPrinting}
                    datasets={data.chartData}
                />
            </div>
            <div className="vertical-line"></div>
            <div className="table-data">
                <ReportTable
                    enableRowHeader
                    color="gray"
                    disableRowShadow
                    data={data.tableData}
                />
            </div>
        </div>
    );
};

export const LineChartTimelineSection = ({ isPrinting, sectionName, units, yAxisLabel, datasets }) => {
    const { t } = useTranslation();
    const tableDataKeys = Object.keys(
        datasets.reduce((a, c) => ({ ...a, ...c?.data?.tableData }), {})
    );
    if (!tableDataKeys.length) {
        return null;
    }

    const tableData = {};
    let datasetsWithDataCount = 0;
    for (const ds of datasets) {
        if (!ds.data.tableData) {
            continue;
        }

        ++datasetsWithDataCount;
        for (const key of tableDataKeys) {
            if (!tableData[key]) {
                tableData[key] = {
                    rowName: key,
                };
            }

            tableData[key][ds.label] = ds.data.tableData[key];
        }
    }

    return (!datasetsWithDataCount ? null :
        <div css={chartCardStyles} className={datasetsWithDataCount > 2 ? 'multiple-results' : null}>
            <div className="info">
                <h2>{sectionName}</h2>
            </div>
            <div className="chart-container">
                <LineChart
                    isPrinting={isPrinting}
                    datasets={
                        datasets
                            .filter(x => x.data.tableData && x.data.chartData)
                            .map(x => ({ ...x, data: x.data.chartData }))
                    }
                    xAxisLabel={t('testReport.section.timeline.xAxis')}
                    yAxisLabel={yAxisLabel}
                />
            </div>
            <div className="vertical-line"></div>
            <div className="table-data">
                {datasets.length &&
                    <ReportTable
                        enableRowHeader
                        color="gray"
                        disableRowShadow
                        data={Object.values(tableData)}
                    />
                }
            </div>
        </div>
    );
};

export const LineChartHistorySection = ({ isPrinting, sectionName, units, yAxisLabel, dataMap, data }) => {
    const { t } = useTranslation();
    if (!data || !data.length) {
        return null;
    }

    const remapData = !Boolean(dataMap.value);

    const chartData = remapData ?
        Object.keys(dataMap.values)
            .map(key => ({
                label: dataMap.values[key],
                data: data.map(x => ({
                    label: x.value.label,
                    value: x.value.chartValues[key],
                    isPartial: x.isPartial
                }))
            }))
        : [
            {
                label: sectionName,
                data: data.map(x => ({
                    ...x.value,
                    isPartial: x.isPartial
                }))
            }
        ];

    const tableHeaders = remapData ?
        { label: dataMap.label, ...dataMap.values }
        : dataMap;

    const tableData = remapData ?
        data.map(x => ({ label: x.value.label, ...x.value.tableValues }))
        : data.map(x => x.value);

    return (
        <div css={chartCardStyles} className="history">
            <div className="info">
                <h2>{sectionName}</h2>
            </div>
            <div className="chart-container">
                <LineChart
                    isPrinting={isPrinting}
                    datasets={chartData}
                    xAxisLabel={t('testReport.section.history.xAxis')}
                    yAxisLabel={yAxisLabel}
                    isHistory={true}
                />
            </div>
            <div className="vertical-line"></div>
            <div className="table-data">
                <ReportTable
                    disableRowShadow
                    disableRowHeader
                    color="gray"
                    headers={tableHeaders}
                    data={tableData}
                />
            </div>
        </div>
    );
};

const neurocognitiveSectionStyles = {
    promptTime: css`
        position: relative;
        padding-top: 40px;
    `,
    scores: css`
        padding-top: 40px;
        display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        // grid-gap: max(120px, 8%);
        grid-template-columns: repeat(2, 1fr);
        // grid-gap: 120px;
        grid-gap: 20px;

        // @media print {}
        @media (max-width: 1500px) {
            display: flex;
            flex-direction: column;
        }

        width: 100%;
    `,
    info: css`
        font: normal normal medium 16px/24px Roboto;
        letter-spacing: 0.14px;
        color: #FFFFFF;
        background: #00ABA5 0% 0% no-repeat padding-box;

        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 10px 40px;
        border-radius: 8px 0px;
    `
};
export const NeurocognitiveSection = ({ isPrinting, sectionName, data }) => {
    const { t } = useTranslation();
    const tableColumns = useMemo(() => ({
        correct: t('testReport.fieldName.correct'),
        incorrect: t('testReport.fieldName.incorrect'),
    }), [t]);

    return (data &&
        <div css={chartCardStyles}>
            <h2 css={neurocognitiveSectionStyles.info}>
                {sectionName}
            </h2>
            <div css={neurocognitiveSectionStyles.promptTime}>
                <div>
                    <span className="h2">{t('testReport.neurocognitive.promptTime')}</span>
                    <div>{data.promptTime} {t('constants.units.millisecondsShort')}</div>
                </div>
            </div>
            <div css={neurocognitiveSectionStyles.scores}>
                <div>
                    <span className="h2">{t('testReport.neurocognitive.scores')}</span>
                    <ReportTable
                        enableRowHeader
                        color="gray"
                        disableRowShadow
                        headers={tableColumns}
                        data={data.scoresTableData}
                        showEmptyRows
                    />
                </div>
                <div>
                    <span className="h2">{t('testReport.neurocognitive.averageTimeToTarget')}</span>
                    <ReportTable
                        enableRowHeader
                        color="gray"
                        disableRowShadow
                        headers={tableColumns}
                        data={data.averageTimeToTargetTableData}
                        showEmptyRows
                    />
                </div>
                {data.averageReactionTimeTableData.some(x => x && ((x.correct != null && x.correct !== NoData) || (x.incorrect != null && x.incorrect !== NoData))) &&
                    <div>
                        <span className="h2">{t('testReport.neurocognitive.averageReactionTime')}</span>
                        <ReportTable
                            enableRowHeader
                            color="gray"
                            disableRowShadow
                            headers={tableColumns}
                            data={data.averageReactionTimeTableData}
                            showEmptyRows
                        />
                    </div>
                }
                {data.averageDynamicReactionTimeTableData.some(x => x && ((x.correct != null && x.correct !== NoData) || (x.incorrect != null && x.incorrect !== NoData))) &&
                    <div>
                        <span className="h2">{t('testReport.neurocognitive.averageDynamicReactionTime')}</span>
                        <ReportTable
                            enableRowHeader
                            color="gray"
                            disableRowShadow
                            headers={tableColumns}
                            data={data.averageDynamicReactionTimeTableData}
                            showEmptyRows
                        />
                    </div>
                }
            </div>
        </div>
    );
};

const mobilityRepsStyles = {
    info: css`
        font: normal normal medium 16px/24px Roboto;
        letter-spacing: 0.14px;
        color: #FFFFFF;
        background: #00ABA5 0% 0% no-repeat padding-box;

        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 10px 40px;
        border-radius: 8px 0px;
    `
};

export const MobilityRepsSection = ({ isPrinting, sectionName, dataMap, datasets, trackedMetrics }) => {
    if (!datasets || !datasets.length) {
        return null;
    }

    const tableData = {};
    const tableDataKeys = Object.keys(datasets[0].data || {});
    const datasetLength = !isPrinting || datasets.length < 12 ? datasets.length : 12;

    const trackedDataMap =  {...dataMap};
    if (!trackedMetrics.acceleration){
        delete trackedDataMap.accelerationAvg;
    }
    if (!trackedMetrics.deceleration){
        delete trackedDataMap.decelerationAvg;
    }
    if (!trackedMetrics.acceleration || !trackedMetrics.deceleration){
        delete trackedDataMap.decelerationIndex;
    }
    if (!trackedMetrics.jumpHeight){
        delete trackedDataMap.jumpHeightAvg;
    }
    if (!trackedMetrics.speed){
        delete trackedDataMap.speedAvg;
    }
    if (!trackedMetrics.squatDepth){
        delete trackedDataMap.squatHeightAvg;
    }
    if (!trackedMetrics.distance){
        delete trackedDataMap.totalDistance;
    }
    if (!trackedMetrics.heartRate){
        delete trackedDataMap.hrAverage;
    }
    if (!trackedMetrics.reactionTime){
        delete trackedDataMap.reactionTimeAvg;
        delete trackedDataMap.dynamicReactionTimeAvg;
    }

    for (let i = 0; i < datasetLength; i++) {
        for (const key of tableDataKeys) {
            const mappedKey = trackedDataMap[key];
            if (!mappedKey) {
                continue;
            }

            if (!tableData[mappedKey]) {
                tableData[mappedKey] = {};
            }

            tableData[mappedKey][datasets[i].label] = datasets[i].data[key];
            tableData[mappedKey]['rowName'] = mappedKey;
        }
    }

    return (
        <div css={chartCardStyles}>
            <h2 css={mobilityRepsStyles.info}>
                {sectionName}
            </h2>
            <ReportTable
                enableRowHeader
                color="gray"
                disableRowShadow
                data={Object.values(tableData)}
            />
        </div>
    );
};
