/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button } from '../../../CoreComponents/Button';
import { Input } from '../../../CoreComponents/Input';
import { useTranslation } from '../../../CoreComponents/Translation';
import { ForceValidateContext, useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { TagService } from '../../../Services/TagService';
import { maxLengthValidator, requiredValidator } from '../../../Utils/Validators';

const addTagContentStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        min-height: 340px;
        // height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .input-root {
            margin: 0;
            width: 100%;
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }
    `,
};

const AddTagValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
};

export const AddTagContent = ({onCancel, onAdd}) => {
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const { t } = useTranslation();

    const $newTag = useObjectHook(
        {
            name: '',
        },
        AddTagValidator,
        null,
        $isForceValidating.value
    );
    const addTag = () => {
        // if we want to validate after submission
        // then it should be set to false by default

        $isForceValidating.set(true);
        const errors = $newTag.validate();
        if (errors) {
            return;
        }

        const newTag = {
            ...$newTag.value,

            name: $newTag.value.name
        };
        $newTag.set(newTag);

        $isSaving.set(true);
        TagService.postTag(newTag)
            .then(() => onAdd(newTag))
            .finally(() => $isSaving.set(false));

    };
    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addTagContentStyles.root}>
                <h2>{t('tags.addModal.title')}</h2>

                <Input placeholder={t('input.placeholder')} required label={t('tags.fieldName.name')} $value={$newTag.getPropHook('name')} />

                <div className="actions" css={addTagContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newTag.isValid} onClick={addTag}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
}
