
/**
 * @param {Array.<(value) => string[]>} validators
 * @returns {string[]}
 */
export function combineValidators(validators) {
    return function (value) {
        let err = [];
        for (const validator of validators) {
            err = [...err, ...validator(value)];
        }

        return err;
    }
}

/**
 * @param {T} value
 * @returns string[]
 */
export function requiredValidator(value) {
    return value == null || value === '' ? ['Field is required'] : [];
}

/**
 * @param {T} value
 * @param {number} minValue
 * @returns string[]
 */
export function minValueValidator(minValue) {
    return function (value) {
        return value < minValue || value === '' ? [`Min value ${minValue}`] : [];
    }
}

/**
 * @param {T} value
 * @param {number} maxValue
 * @returns string[]
 */
export function maxValueValidator(maxValue) {
    return function (value) {
        return value > maxValue || value === '' ? [`Max value ${maxValue}`] : [];
    }
}

/**
 * @param {number} maxLength
 * @returns {(value: string) => string[]}
 */
export function maxLengthValidator(maxLength) {
    return function (value) {
        return value == null || value.length < maxLength ? [] : [`Max length is ${maxLength}`];
    }
}

/**
 * @param {number} minLength
 * @returns {(value: string) => string[]}
 */
export function minLengthValidator(minLength) {
    return function (value) {
        return value == null || value.length >= minLength ? [] : [`Min length is ${minLength}`];
    }
}

/**
 * @param {number} length
 * @returns {(value: string) => string[]}
 */
export function exactLengthValidator(length) {
    return function (value) {
        return value == null || value.length === length ? [] : [`Value length must be ${length} characters`];
    }
}

/**
 * @param {string} email
 * @returns {string[]}
 */
export function emailValidator(email) {
    const format = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return email == null || format.test(email) ? [] : ['Invalid format'];
}

/**
 * @param {number} min
 * @param {number} max
 * @returns {string[]}
 */
export function rangeLengthValidator(min, max) {
    return function (value) {
        return value == null || value === '' || (value.length <= max && value.length >= min) ?
            []
            : [`Value length must be in range ${min} and ${max}`];
    }
}

/**
 * @param {string} username
 * @returns {string[]}
 */
export function usernameValidator(username) {
    const format = /^[a-zA-Z0-9_.]*$/
    return username == null || format.test(username) ? [] : ['Special characters are not allowed'];
}

/**
 * @param {string} name
 * @returns {string[]}
 */
export function nameValidator(name) {
    const format = /^[a-zA-Z].*/
    return !name || format.test(name) ? [] : ['Special characters at the beginning are not allowed'];
}

/**
 * @param {string} value
 * @returns {string[]}
 */
export function digitsValidator(value) {
    const format = /^$|^\d+$/
    return value == null || format.test(value) ? [] : ['Only digits are allowed'];
}

/**
 * @param {string} value
 * @returns {string[]}
 */
export function PINValidator(value) {
    const format = /^$|^\d{4}$/
    return value == null || format.test(value) ? [] : ['PIN must be 4 digits.'];
}

/**
 * @param {string} value
 * @returns {string[]}
 */
export function phoneValidator(value) {
    const format = /^(\+?\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    return value == null || value === '' || format.test(value) ? [] : ['Only valid phone numbers are allowed'];
}

/**
 * @param {string} password
 * @returns {string[]}
 */
export function passwordValidator(password) {
    const passwordObj = {
        'specialCharacters': /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/,
        'lowerCaseLetter': /[a-z]/,
        'upperCaseLetter': /[A-Z]/,
        'number': /[0-9]/
    };

    const errorMessages = {
        'specialCharacters': 'Password must have at least one special character',
        'lowerCaseLetter': 'Password must have at least one lowercase character',
        'upperCaseLetter': 'Password must have at least one uppercase character',
        'number': 'Password must have at least one number'
    };

    const keys = Object.keys(passwordObj);
    const passedChecks = keys.filter(key => passwordObj[key].test(password));
    const failedChecks = keys.filter(key => !passedChecks.includes(key));

    return passedChecks.length >= 3 ? []
        : [
            `At least ${keys.length - passedChecks.length - 1} of the following requirements:`,
            ...failedChecks.map(error => '- ' + errorMessages[error])
        ]
}
