/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { subMonths } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../CoreComponents/Button';
import { ChevronLeftIcon, PrintIcon } from '../../CoreComponents/CustomIcons';
import { DateRangeSelector } from '../../CoreComponents/DateRangeSelector';
import { CustomScrollContainer } from '../../CoreComponents/Layout';
import { CircularLoader } from '../../CoreComponents/Loaders';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook, useObjectHook } from '../../CoreComponents/Utils';
import { CustomerService } from '../../Services/CustomerService';
import { parseDate, shortDate } from '../../Utils/Common';
import { ReportTable } from './ReportTable';
import { LineChart } from '../../CoreComponents/ChartComponents/LineChart';
import { BarChart } from '../../CoreComponents/ChartComponents/BarChart';

const asideWidth = '520px';
const infoMargin = 40;
const customerReportStyles = {
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
    layout: css`
        display: flex;
        padding: 20px 40px;
        height: 100%;

        & aside {
            display: flex;
            flex-direction: column;

            // display: none;
            // width: 448px;
            width: ${asideWidth};
            min-width: ${asideWidth};
            padding-right: 39px;
            border-right: 2px solid #CBCBCB;
            height: 100%;

            & > .table-section {
                height: calc(50% - ${infoMargin / 2}px);
                display: flex;
                flex-direction: column;

                // TODO: move this to report table
                & > .table {
                    height: calc(100% - 40px);
                    overflow-y: auto;
                    padding: 0 20px 20px 20px;

                    & thead tr:nth-child(1) th {
                        position: sticky;
                        top: 0;
                        z-index: 10;
                        background: #EEEEEE 0% 0% no-repeat padding-box;
                    }
                }

                &:first-of-type {
                    margin-bottom: ${infoMargin}px;
                }
            }
        }
        @media (max-width: 1200px) {
            // height: 100%;
            // display: block;
            // overflow: auto;

            & aside {
                width: unset;
                min-width: unset;
                // height: auto;
                // width: 100%;
                // border-right: none;
            }
        }

        @media print {
            height: auto;
            display: block;
            // flex-direction: column;

            & aside {
                width: 100%;
                border-right: none;

                & .table-section {
                    break-inside: avoid;
                }
            }
        }
    `,
    mainContent: css`
        // width: 100%;
        width: calc(100% - ${asideWidth});
        height: 100%;
        overflow: clip;

        & .content {
            // 90px controls and padding
            height: calc(100% - 90px);
            padding: 0 40px;
            // width: calc(100vw - 448px - 40px);
        }

        & .controls {
            padding: 0 40px 50px 40px;
            background: #EEEEEE 0% 0% no-repeat padding-box;

            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & .date-range-selector {
                margin-left: auto;
            }

            & > button {
                width: unset;

                &.go-back-action {
                    min-width: unset;
                }

                &.print-action {
                    min-width: unset;
                    // margin-left: 20px;
                    margin-right: 10px;
                }
            }
        }

        @media (max-width: 1200px) {
            overflow: unset;
            width: 100%;
            height: auto;

            & .content {
                height: auto;
                padding: 1px;
                overflow: unset;
            }
        }

        @media print {
            overflow: unset;
            width: 100%;
            height: auto;

            & .controls {
                display: none;
            }

            & .content {
                height: auto;
                padding: 1px;
                overflow: unset;
            }
        }
    `,
};

export const CustomerReport = () => {
    const { customerId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const $isLoading = useHook(true);
    const $isPrinting = useHook(false);
    const $dateRange = useObjectHook({
        dateInterval: 'week',
        startDate: subMonths(new Date(), 2),
        endDate: new Date(),
    });

    const $rawData = useHook({
        userSessionsBySite: [],
        sessionsWeeklyTrend: [],
        newUsersWeeklyTrend: [],
        scriptedActivities: [],
        providerSummary: [],
    });
    const $reportData = useHook(null);

    const sitesOverviewHeaders = useMemo(() => ({
        siteName: t('customerReport.sitesOverviewTable.siteName'),
        lastOnline: t('customerReport.sitesOverviewTable.lastOnline'),
        totalUsers: t('customerReport.sitesOverviewTable.totalUsers'),
        totalSessions: t('customerReport.sitesOverviewTable.totalSessions'),
    }), [t]);

    const mostPopularScriptedActivitiesHeaders = useMemo(() => ({
        name: t('customerReport.scriptedActivitiesTable.name'),
        sessionsCount: t('customerReport.scriptedActivitiesTable.sessionsCount'),
    }), [t]);

    const providerSummaryHeaders = useMemo(() => ({
        providerName: t('customerReport.providerTable.providerName'),
        testsAdministered: t('customerReport.providerTable.testsAdministered'),
    }), [t]);

    useEffect(() => {
        (async () => {
            $isLoading.set(true);
            const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
            const endDate = $dateRange.value.endDate.toISOString().split('T')[0];

            const [
                userSessionsBySite,
                sessionsWeeklyTrend,
                newUsersWeeklyTrend,
                scriptedActivities,
                providerSummary
            ] = await Promise.all([
                CustomerService.overview(
                    customerId,
                    startDate,
                    endDate
                ),
                CustomerService.overviewTrend(
                    customerId,
                    'tests',
                    $dateRange.value.dateInterval,
                    startDate,
                    endDate
                ),
                CustomerService.overviewTrend(
                    customerId,
                    'users',
                    $dateRange.value.dateInterval,
                    startDate,
                    endDate
                ),
                CustomerService.overviewScriptedActivities(
                    customerId,
                    startDate,
                    endDate,
                    10
                ),
                CustomerService.providerSummary(
                    customerId,
                    startDate,
                    endDate
                ),
            ]);

            $rawData.set({
                userSessionsBySite: userSessionsBySite.map(x => ({ ...x, lastOnline: x.lastOnline ? shortDate(x.lastOnline) : null })),
                sessionsWeeklyTrend: sessionsWeeklyTrend.map(x => ({ ...x, item1: shortDate(parseDate(x.item1, 'YYYY-ww')) })),
                newUsersWeeklyTrend: newUsersWeeklyTrend.map(x => ({ ...x, item1: shortDate(parseDate(x.item1, 'YYYY-ww')) })),
                providerSummary,
                scriptedActivities,
            })
            $isLoading.set(false);
        })()
    }, [$dateRange.value]);

    useEffect(() => {
        if (!$rawData.value) {
            return;
        }

        $reportData.set({
            sitesOverview: $rawData.value.userSessionsBySite,
            mostPopularScriptedActivities: $rawData.value.scriptedActivities.map(x => ({ name: x.item1, sessionsCount: x.item2 })),
            sessionsWeeklyTrend: [{
                label: t('customerReport.chartData.sessions'),
                exportSettings: {
                    xAxis: t('customerReport.chartExportDataField.date'),
                    yAxis: t('customerReport.chartData.sessions'),
                },
                data: $rawData.value.sessionsWeeklyTrend.map(x => ({ label: x.item1, value: x.item2 }))
            }],
            newUsersWeeklyTrend: [{
                label: t('customerReport.chartData.users'),
                exportSettings: {
                    xAxis: t('customerReport.chartExportDataField.date'),
                    yAxis: t('customerReport.chartData.users'),
                },
                data: $rawData.value.newUsersWeeklyTrend.map(x => ({ label: x.item1, value: x.item2 }))
            }],
            providerSummaryGraph: [{
                label: t('customerReport.chartData.sessions'),
                exportSettings: {
                    xAxis: t('customerReport.chartData.providers'),
                    yAxis: t('customerReport.chartData.sessions'),
                },
                data: $rawData.value.providerSummary.filter(x => x.testCount > 0).map(x => ({ label: `${x.provider.firstName} ${x.provider.lastName}`, value: x.testCount }))
            }],
            providerSummaryTable: $rawData.value.providerSummary.map(x => ({ providerName: `${x.provider.firstName} ${x.provider.lastName}`, testsAdministered: x.testCount })),
            userSessions: [
                {
                    label: t('customerReport.chartData.users'),
                    exportSettings: {
                        xAxis: t('customerReport.chartExportDataField.siteName'),
                        yAxis: t('customerReport.chartData.users'),
                    },
                    data: $rawData.value.userSessionsBySite.map(x => ({ label: x.siteName, value: x.totalUsers }))
                },
                {
                    label: t('customerReport.chartData.sessions'),
                    exportSettings: {
                        xAxis: t('customerReport.chartExportDataField.siteName'),
                        yAxis: t('customerReport.chartData.sessions'),
                    },
                    data: $rawData.value.userSessionsBySite.map(x => ({ label: x.siteName, value: x.totalSessions }))
                },
            ],
        });
    }, [t, $rawData.value])

    return ($isLoading.value || !$reportData.value ?
        <div css={customerReportStyles.loadingContainer}>
            <CircularLoader />
        </div>
        :
        <div css={customerReportStyles.layout}>
            <aside>
                <div className="table-section">
                    <h2>{t('customerReport.tableTitle.sitesOverview')}</h2>
                    <div className="table">
                        <ReportTable
                            disableRowHeader
                            headers={sitesOverviewHeaders}
                            data={$reportData.value.sitesOverview}
                        />
                    </div>
                </div>
                {$reportData.value.mostPopularScriptedActivities.length ?
                    <div className="table-section">
                        <h2>{t('customerReport.tableTitle.mostPopularScriptedActivities')}</h2>
                        <div className="table">
                            <ReportTable
                                disableRowHeader
                                headers={mostPopularScriptedActivitiesHeaders}
                                data={$reportData.value.mostPopularScriptedActivities}
                            />
                        </div>
                    </div>
                : null}
            </aside>
            <div css={customerReportStyles.mainContent}>
                <div className="controls">
                    <Button
                        className="go-back-action"
                        onClick={() => window.history.length > 2 ?
                            navigate(-1)
                            : navigate('/manage/customers')
                        }
                    >
                        <ChevronLeftIcon />
                    </Button>
                    <DateRangeSelector
                        label={t('customerReport.controls.dateRange')}
                        $value={$dateRange}
                        design="slim"
                        labelProps={{
                            className: 'date-range-selector'
                        }}
                    />
                    <Button
                        className="print-action"
                        disabled={$isPrinting.value || $isLoading.value}
                        onClick={() => {
                            $isPrinting.set(true);
                            setTimeout(function () {
                                window.print();
                                $isPrinting.set(false);
                            }, 1000);
                        }}
                    >
                        <PrintIcon />
                    </Button>
                </div>

                {$isLoading.value ?
                    <div css={customerReportStyles.loadingContainer}>
                        <CircularLoader />
                    </div>
                    :
                    <CustomScrollContainer className="content">
                        <Card title={t('customerReport.chartTitle.sessionsWeeklyTrend')}>
                            <LineChart
                                title={t('customerReport.chartTitle.sessionsWeeklyTrend')}
                                exportFileName={t('customerReport.chartTitle.sessionsWeeklyTrend')}
                                enableOptionsMenu
                                datasets={$reportData.value.sessionsWeeklyTrend}
                                xAxisLabel={t('customerReport.sessionsWeeklyTrend.xAxis')}
                                yAxisLabel={t('customerReport.sessionsWeeklyTrend.yAxis')}
                            />
                        </Card>
                        <Card title={t('customerReport.chartTitle.newUsersWeeklyTrend')}>
                            <LineChart
                                title={t('customerReport.chartTitle.newUsersWeeklyTrend')}
                                exportFileName={t('customerReport.chartTitle.newUsersWeeklyTrend')}
                                enableOptionsMenu
                                datasets={$reportData.value.newUsersWeeklyTrend}
                                xAxisLabel={t('customerReport.newUsersWeeklyTrend.xAxis')}
                                yAxisLabel={t('customerReport.newUsersWeeklyTrend.yAxis')}
                            />
                        </Card>
                        <Card title={t('customerReport.chartTitle.usersAndSessionsBySite')}>
                            <BarChart
                                title={t('customerReport.chartTitle.usersAndSessionsBySite')}
                                exportFileName={t('customerReport.chartTitle.usersAndSessionsBySite')}
                                enableOptionsMenu
                                datasets={$reportData.value.userSessions}
                                xAxisLabel={t('customerReport.usersAndSessionsBySite.xAxis')}
                                yAxisLabel={t('customerReport.usersAndSessionsBySite.yAxis')}
                            />
                        </Card>
                        <Card title={t('customerReport.chartTitle.providerSummary')}>
                            <BarChart
                                title={t('customerReport.chartTitle.providerSummary')}
                                exportFileName={t('customerReport.chartTitle.providerSummary')}
                                enableOptionsMenu
                                datasets={$reportData.value.providerSummaryGraph}
                                xAxisLabel={t('customerReport.providerSummary.xAxis')}
                                yAxisLabel={t('customerReport.providerSummary.yAxis')}
                                displayHorizontally={true}
                            />
                        </Card>
                        <div className="table-section">
                            <h2>{t('customerReport.tableTitle.providerSummary')}</h2>
                            <div className="table">
                                <ReportTable
                                    disableRowHeader
                                    headers={providerSummaryHeaders}
                                    data={$reportData.value.providerSummaryTable}
                                />
                            </div>
                        </div>
                    </CustomScrollContainer>
                }
            </div>
        </div>
    );
};

const cardStyles = css`
    position: relative;
    padding: 40px;
    margin-bottom: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #28326529;
    border-radius: 8px;
    min-height: 500px;
    height: 1px;
    // width: 100%;

    & .info {
        position: absolute;
        // left: 40px;
        // top: 40px;

        & h2 {
            margin: 0 0 9px 0;
        }
    }
`
const Card = ({ title, children }) => (
    <div css={cardStyles}>
        <div className="info">
            <h2>{title}</h2>
        </div>
        {children}
    </div>
);
