// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { useMemo, useRef, useState } from 'react';
import {
    ChartPadding,
    ChartResizeDelay,
    DefaultAnimationDuration,
    SharedPluginsConfig,
    chartStyles,
    useChartPrintEffect,
    ChartColors
} from '../../Utils/Constants';
import { ChartOptionsMenu } from './ChartOptionsMenu';
import { Bar } from 'react-chartjs-2';

export const BarChart = ({ datasets, isPrinting, fractionDigits = 2, hideZeroValues, xAxisLabel, yAxisLabel, displayHorizontally = false, ...props }) => {
    const chartRef = useRef(null);
    const [activeDataSetIndex, setActiveDataSetIndex] = useState(null);
    const [size, setNewSize] = useState(null);
    const chartLabels = useMemo(() => {
        const labels = {};
        for (const ds of datasets) {
            for (const entry of ds.data) {
                labels[entry.label] = 1;
            }
        }

        return Object.keys(labels);
    }, [datasets]);

    /** @type import('chart.js/dist/types').ChartOptions<'bar'> */
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        resizeDelay: isPrinting ? 0 : ChartResizeDelay,
        layout: {
            padding: ChartPadding,
        },

        onResize: (chart, newSize) => {
            setNewSize(newSize);
        },

        animation: {
            duration: DefaultAnimationDuration
        },

        plugins: {
            ...SharedPluginsConfig,
            tooltip: {
                ...SharedPluginsConfig.tooltip,
                fractionDigits,
            },
        },

        scales: {
            x: {
                title: {
                    display: true,
                    text: xAxisLabel
                }
            },
            y: {
                title: {
                    display: true,
                    text: yAxisLabel
                }
            }
        },

        backgroundColor: 'rgba(255, 255, 255, 1)',
    };

    const onHover = (e, item) => {
        if (item.length) {
            setActiveDataSetIndex(item[0].datasetIndex);
        } else {
            setActiveDataSetIndex(null);
        }
    };

    if (datasets.length > 1) {
        options.onHover = onHover;
    }

    if (activeDataSetIndex != null || isPrinting) {
        options.animation.duration = 0;
    }

    if (displayHorizontally) {
        options.indexAxis = 'y';
    }

    /** @type import('chart.js/dist/types').ChartData<'radar'> */
    const chartData = {
        labels: chartLabels,
        datasets:
            datasets.map((ds, index) => ({
                label: ds.label,
                data: chartLabels.map(label => {
                    const entry = ds.data.find(e => e.label === label);
                    return entry?.value;
                }),

                borderWidth: 4,
                pointHitRadius: 8,
                pointRadius: 8,
                pointBorderWidth: 4,
                pointHoverRadius: 8,
                pointHoverBorderWidth: 4,

                minBarLength: hideZeroValues ? 0 : 15,

                ...ChartColors[index % ChartColors.length],

                ...(activeDataSetIndex != null && activeDataSetIndex !== index ?
                    {
                        backgroundColor: `rgba(64, 64, 64, 0.1)`,
                        borderColor: `rgba(64, 64, 64, 0.1)`,
                        pointBackgroundColor: 'rgba(255, 255, 255, 0.1)',
                        pointHoverBackgroundColor: 'rgba(255, 255, 255, 0.1)',
                    } : {})
            }))
    };

    useChartPrintEffect(chartRef.current, size);

    return (props.enableOptionsMenu ?
        <ChartOptionsMenu
            chartRef={chartRef}
            title={props.title}
            unitType={props.unitType}
            exportFileName={props.exportFileName}
            datasets={datasets}
            chartComponent={
                <Bar ref={chartRef} data={chartData} options={options} />
            }
        />
        :
        <div css={chartStyles.responsiveContainer}>
            <Bar ref={chartRef} data={chartData} options={options} />
        </div>
    );
};
