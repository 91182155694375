/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { Button } from '../../../CoreComponents/Button';
import { Checkbox } from '../../../CoreComponents/Checkbox';
import { Switch } from '../../../CoreComponents/Switch';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useHook } from '../../../CoreComponents/Utils';
import { CategoryService } from '../../../Services/CategoryService';
import { ScriptedActivitiesService } from '../../../Services/ScriptedActivitiesService'
import { UnitService } from '../../../Services/UnitService';
import { CircularLoader } from '../../../CoreComponents/Loaders';
import { AdminTypes, TrazerOrientationActivityId } from '../../../Utils/Constants';
import { useSelector } from 'react-redux';

const activityLibraryStyles = {
    tabs: css`
    display: flex;
    width: calc(100% - 8px);

    & > button, & > button:hover {
        border: 2px solid #00ABA5;
        border-radius: 0;
        width: 100%;
        border-left-width: 1px;
        border-right-width: 1px;
        box-shadow: none;
    }

    & > button:first-of-type{
        border-left-width: 2px;
        border-radius: 8px 0 0 8px;
    }
    & > button:last-of-type {
        border-right-width: 2px;
        border-radius: 0 8px 8px 0;
    }
`,
    root: css`
        display: flex;
        flex-direction: column;
        height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 1500px) {
            width: 1200px;
        }

        & > h2 {
            width: 100%;
        }

        & > .inputs {
            width: 100%;
            & > * {
                display: inline-flex;

                width: calc(100% - 40px);
                @media (min-width: 1200px) {
                    width: calc(50% - 40px);
                }
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            padding: 0 0 0 40px;
            width: 100%;
            & > button:first-of-type {
                margin-left: auto;
            }
        }
    `,
    tableContainer: css`
        overflow: auto;
        margin-top: 20px;
        padding-right: 40px;
        height: calc(100% - 213px);
    `,
    table: css`
        border-collapse: separate;
        border-spacing: 0 12px;
        border: 0;
        width: 100%;

        & tr:hover > td {
            background: #EBF5F4 0% 0% no-repeat padding-box;
        };

        & thead tr th {
            font: normal normal bold 16px/24px Mustica Pro;
            letter-spacing: 0.14px;
            color: #3A817F;

            &:first-of-type {
                padding-left: 40px;
            };

            &:last-of-type {
                padding-right: 40px;
                width: 150px;
            };
        }

        & tbody > tr {
            position: relative;

            & > th {
                font: normal normal bold 16px/24px Mustica Pro;
                letter-spacing: 0.14px;
                color: #858585;
                padding-right: 20px;
            }

            & > td {
                font: normal normal normal 16px/24px Roboto;
                color: #858585;
                background: #FFFFFF 0% 0% no-repeat padding-box;

                border-radius: 0;
                height: 40px;

                &:first-of-type {
                    border-radius: 8px 0 0 8px;
                    padding-left: 40px;
                };

                &:last-of-type {
                    border-radius: 0 8px 8px 0;
                    padding-right: 40px;
                };

                & > div {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    & > * {
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
            };
        }
    `,
    loadContainer: css`
        height: 85vh;
        display: flex;
        width: unset;
        @media (min-width: 1500px) {
            width: 1200px;
        }
    `
};

export const ActivityLibraryTab = ({ $selectedUnit }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $isSaving = useHook(false);
    const $isLoading = useHook(false);
    const $selectedTab = useHook(1);
    const $scriptedActivities = useHook([]);
    const $categoriesWithActivities = useHook({});
    const $categories = useHook([]);

    useEffect(() => {
        CategoryService.getAll(9999)
            .then($categories.set)
    }, [])

    useEffect(() => {
        $isLoading.set(true);
        ScriptedActivitiesService.getAllScriptedActivities(9999)
            .then(resp => {
                const groupByCategories = {};
                const categoriesWithActivities = {};
                for (const sa of resp.rows.sort((a, b) => a.name.localeCompare(b.name))) {
                    if (sa.isActive){
                        const unitSA = $selectedUnit.value.unitScriptedActivities
                            ?.find(usa => usa.scriptedActivityId === sa.scriptedActivityId);
                        const entry = {
                            ...sa,
                            isSelected: !!unitSA,
                            isVisible: !!unitSA?.isVisible,
                            isDeprecated: sa.isDeprecated.toString()[0].toUpperCase() + sa.isDeprecated.toString().slice(1),
                        };

                        if (!entry.isSelected && !isTrazerAdmin) {
                            continue
                        }

                        categoriesWithActivities[sa.categoryId] = true;
                        if (!groupByCategories[sa.categoryId]) {
                            groupByCategories[sa.categoryId] = [entry];
                        } else {
                            groupByCategories[sa.categoryId].push(entry);
                        }
                    }
                }

                $scriptedActivities.set(groupByCategories);
                $categoriesWithActivities.set(categoriesWithActivities);
                $isLoading.set(false);
            });
    }, []);

    const updateUnitScriptedActivity = () => {
        $isSaving.set(true);
        const activeScriptedActivities = [];
        for (const key in $scriptedActivities.value) {
            for (const item of $scriptedActivities.value[key]) {
                if (!item.isSelected && item.scriptedActivityId !== TrazerOrientationActivityId) {
                    continue;
                }
                activeScriptedActivities.push({
                    item1: item.scriptedActivityId,
                    item2: item.scriptedActivityId === TrazerOrientationActivityId ? true : item.isVisible
                })
            }
        }
        UnitService.updateScriptedActivities(
            $selectedUnit.value.unitId,
            activeScriptedActivities)
            .then(() => $selectedUnit.set({}))
            .then($isSaving.set(false));
    };

    const currentTabActivities = $scriptedActivities.value[$selectedTab.value];
    return ($isLoading.value ?
        <div css={activityLibraryStyles.loadContainer}>
            <CircularLoader />
        </div>
        :
        <div css={activityLibraryStyles.root}>
            <h2>{t('units.editModal.activityLibraryTab.title')}</h2>
            <div css={activityLibraryStyles.tabs}>
                {$categories.value.map(x => (
                    $categoriesWithActivities.value[x.categoryId] &&
                        <Button
                            key={x.categoryId}
                            variant={$selectedTab.value === x.categoryId ? 'contained' : 'outlined'}
                            onClick={() => $selectedTab.set(x.categoryId)}
                        >
                            {x.name}
                        </Button>
                ))}
            </div>
            <div css={activityLibraryStyles.tableContainer}>
                <table css={activityLibraryStyles.table}>
                    <thead>
                        <tr>
                            <th scope="col">{t('unitScriptedActivities.table.name')}</th>
                            {(isTrazerAdmin || isTrazerViewAdmin) &&
                                <th scope="col">{t('unitScriptedActivities.table.isDeprecated')}</th>
                            }
                            <th scope="col">{t('unitScriptedActivities.table.visible')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {$scriptedActivities.value[$selectedTab.value]?.map((row, rowIndex) => (
                            <tr key={`${row.name}_${rowIndex}`}>
                                <td>
                                    <div>
                                        {(isTrazerAdmin || isTrazerViewAdmin) &&
                                            <Checkbox
                                                disabled={isTrazerViewAdmin || row.scriptedActivityId === TrazerOrientationActivityId}
                                                checked={row.scriptedActivityId === TrazerOrientationActivityId ? true : row.isSelected}
                                                onChange={isChecked => {
                                                    currentTabActivities[rowIndex].isVisible = isChecked;
                                                    currentTabActivities[rowIndex].isSelected = isChecked;

                                                    $scriptedActivities.set({
                                                        ...$scriptedActivities.value,
                                                        [$selectedTab.value]: currentTabActivities
                                                    })
                                                }}
                                            />
                                        }
                                        <span>{row.name}</span>
                                    </div>
                                </td>
                                {(isTrazerAdmin || isTrazerViewAdmin) &&
                                    <td>
                                        <span>{row.isDeprecated}</span>
                                    </td>
                                }
                                <td>
                                    {!isTrazerViewAdmin &&
                                        <Switch
                                            disabled={(!currentTabActivities[rowIndex].isSelected && !isTrazerAdmin) || isTrazerViewAdmin || row.scriptedActivityId === TrazerOrientationActivityId}
                                            value={row.scriptedActivityId === TrazerOrientationActivityId ? true : row.isVisible}
                                            onChange={value => {
                                                if (value) {
                                                    currentTabActivities[rowIndex].isSelected = value;
                                                }
                                                currentTabActivities[rowIndex].isVisible = value;

                                                $scriptedActivities.set({
                                                    ...$scriptedActivities.value,
                                                    [$selectedTab.value]: currentTabActivities

                                                })
                                            }}
                                        />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="actions">
                {(isTrazerAdmin || isTrazerViewAdmin) &&
                    <Checkbox
                        disabled={isTrazerViewAdmin}
                        label={t('actions.markAll')}
                        checked={$scriptedActivities.value[$selectedTab.value]?.every(x => x.isSelected) || false}
                        onChange={isChecked => {
                            $scriptedActivities.set({
                                ...$scriptedActivities.value,
                                [$selectedTab.value]: $scriptedActivities.value[$selectedTab.value]
                                    ?.map(row => ({ ...row, isSelected: isChecked, isVisible: isChecked }))
                            })
                        }}
                    />
                }
                <Button disabled={$isSaving.value || isTrazerViewAdmin} onClick={updateUnitScriptedActivity}>{t('actions.update')}</Button>
            </div>
        </div>
    )
};
