/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { Footer } from "../CoreComponents/Layout";
import { useTranslation } from "../CoreComponents/Translation"
import { EnvelopeIcon, PhoneIcon } from "../CoreComponents/CustomIcons";

const supportStyles = css`
    padding-top: 120px;
    overflow: auto;
    margin-bottom: 40px;

    & > div {
        height: 200px;
        width: 600px;
        margin: auto;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    }

    & .content {
        padding-top: 15px;
        display: flex;
        align-items: center;
    }

    & .text {
        padding-top: 53px;
        display: flex;
        flex-direction: column;
    }

    & .email {
        padding-left: 40px;
        display: flex;
        align-items: center;
    }

    & .phone {
        padding-right: 104px;
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    & a {
        padding-left: 9px;
        font: normal normal normal 16px/24px "Mustica Pro";
        letter-spacing: 0.14px;
        color: #858585;
    }

    & h1 {
        margin-top:0px;
        font-size: 1.5em;
        text-align: center;
    }

    & footer {
        margin-top: 375px;
    }
`

export const Support = () => {
    const { t } = useTranslation();
    return (
        <div css={supportStyles}>
            <div className="contentContainer">
                <div className="text">
                    <h1>{t('support.title')}</h1>
                    <div className="content">
                        <div className="email">
                            <EnvelopeIcon />
                            <a href="mailto:support@trazer.com">support@trazer.com</a>
                        </div>

                        <div className="phone">
                            <PhoneIcon />
                            <a href="tel:14409257852">1-440-925-7852</a>
                            <p></p>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
