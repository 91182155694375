
//This file is auto generated, do not edit it manually
export const ImageFiles = [
    "/images/scripted_activities/DEFAULT.png",
    "/images/scripted_activities/sa1.png",
    "/images/scripted_activities/sa10.png",
    "/images/scripted_activities/sa100.png",
    "/images/scripted_activities/sa101.png",
    "/images/scripted_activities/sa102.png",
    "/images/scripted_activities/sa103.png",
    "/images/scripted_activities/sa104.png",
    "/images/scripted_activities/sa105.png",
    "/images/scripted_activities/sa106.png",
    "/images/scripted_activities/sa107.png",
    "/images/scripted_activities/sa108.png",
    "/images/scripted_activities/sa109.png",
    "/images/scripted_activities/sa11.png",
    "/images/scripted_activities/sa110.png",
    "/images/scripted_activities/sa112.png",
    "/images/scripted_activities/sa113.png",
    "/images/scripted_activities/sa114.png",
    "/images/scripted_activities/sa12.png",
    "/images/scripted_activities/sa121.png",
    "/images/scripted_activities/sa122.png",
    "/images/scripted_activities/sa123.png",
    "/images/scripted_activities/sa127.png",
    "/images/scripted_activities/sa128.png",
    "/images/scripted_activities/sa129.png",
    "/images/scripted_activities/sa13.png",
    "/images/scripted_activities/sa130.png",
    "/images/scripted_activities/sa131.png",
    "/images/scripted_activities/sa132.png",
    "/images/scripted_activities/sa133.png",
    "/images/scripted_activities/sa134.png",
    "/images/scripted_activities/sa135.png",
    "/images/scripted_activities/sa136.png",
    "/images/scripted_activities/sa137.png",
    "/images/scripted_activities/sa138.png",
    "/images/scripted_activities/sa139.png",
    "/images/scripted_activities/sa14.png",
    "/images/scripted_activities/sa140.png",
    "/images/scripted_activities/sa141.png",
    "/images/scripted_activities/sa142.png",
    "/images/scripted_activities/sa143.png",
    "/images/scripted_activities/sa15.png",
    "/images/scripted_activities/sa154.png",
    "/images/scripted_activities/sa155.png",
    "/images/scripted_activities/sa156.png",
    "/images/scripted_activities/sa157.png",
    "/images/scripted_activities/sa158.png",
    "/images/scripted_activities/sa159.png",
    "/images/scripted_activities/sa16.png",
    "/images/scripted_activities/sa160.png",
    "/images/scripted_activities/sa161.png",
    "/images/scripted_activities/sa162.png",
    "/images/scripted_activities/sa163.png",
    "/images/scripted_activities/sa164.png",
    "/images/scripted_activities/sa165.png",
    "/images/scripted_activities/sa166.png",
    "/images/scripted_activities/sa167.png",
    "/images/scripted_activities/sa169.png",
    "/images/scripted_activities/sa17.png",
    "/images/scripted_activities/sa172.png",
    "/images/scripted_activities/sa173.png",
    "/images/scripted_activities/sa175.png",
    "/images/scripted_activities/sa176.png",
    "/images/scripted_activities/sa177.png",
    "/images/scripted_activities/sa178.png",
    "/images/scripted_activities/sa179.png",
    "/images/scripted_activities/sa18.png",
    "/images/scripted_activities/sa180.png",
    "/images/scripted_activities/sa181.png",
    "/images/scripted_activities/sa189.png",
    "/images/scripted_activities/sa19.png",
    "/images/scripted_activities/sa190.png",
    "/images/scripted_activities/sa191.png",
    "/images/scripted_activities/sa193.png",
    "/images/scripted_activities/sa194.png",
    "/images/scripted_activities/sa195.png",
    "/images/scripted_activities/sa2.png",
    "/images/scripted_activities/sa20.png",
    "/images/scripted_activities/sa209.png",
    "/images/scripted_activities/sa21.png",
    "/images/scripted_activities/sa210.png",
    "/images/scripted_activities/sa213.png",
    "/images/scripted_activities/sa214.png",
    "/images/scripted_activities/sa215.png",
    "/images/scripted_activities/sa216.png",
    "/images/scripted_activities/sa219.png",
    "/images/scripted_activities/sa22.png",
    "/images/scripted_activities/sa220.png",
    "/images/scripted_activities/sa221.png",
    "/images/scripted_activities/sa222.png",
    "/images/scripted_activities/sa223.png",
    "/images/scripted_activities/sa224.png",
    "/images/scripted_activities/sa225.png",
    "/images/scripted_activities/sa226.png",
    "/images/scripted_activities/sa227.png",
    "/images/scripted_activities/sa228.png",
    "/images/scripted_activities/sa229.png",
    "/images/scripted_activities/sa23.png",
    "/images/scripted_activities/sa230.png",
    "/images/scripted_activities/sa231.png",
    "/images/scripted_activities/sa232.png",
    "/images/scripted_activities/sa233.png",
    "/images/scripted_activities/sa234.png",
    "/images/scripted_activities/sa235.png",
    "/images/scripted_activities/sa236.png",
    "/images/scripted_activities/sa237.png",
    "/images/scripted_activities/sa24.png",
    "/images/scripted_activities/sa25.png",
    "/images/scripted_activities/sa26.png",
    "/images/scripted_activities/sa27.png",
    "/images/scripted_activities/sa28.png",
    "/images/scripted_activities/sa29.png",
    "/images/scripted_activities/sa3.png",
    "/images/scripted_activities/sa30.png",
    "/images/scripted_activities/sa31.png",
    "/images/scripted_activities/sa32.png",
    "/images/scripted_activities/sa33.png",
    "/images/scripted_activities/sa34.png",
    "/images/scripted_activities/sa35.png",
    "/images/scripted_activities/sa36.png",
    "/images/scripted_activities/sa37.png",
    "/images/scripted_activities/sa38.png",
    "/images/scripted_activities/sa39.png",
    "/images/scripted_activities/sa4.png",
    "/images/scripted_activities/sa40.png",
    "/images/scripted_activities/sa41.png",
    "/images/scripted_activities/sa42.png",
    "/images/scripted_activities/sa43.png",
    "/images/scripted_activities/sa44.png",
    "/images/scripted_activities/sa45.png",
    "/images/scripted_activities/sa46.png",
    "/images/scripted_activities/sa47.png",
    "/images/scripted_activities/sa48.png",
    "/images/scripted_activities/sa49.png",
    "/images/scripted_activities/sa5.png",
    "/images/scripted_activities/sa50.png",
    "/images/scripted_activities/sa51.png",
    "/images/scripted_activities/sa52.png",
    "/images/scripted_activities/sa53.png",
    "/images/scripted_activities/sa54.png",
    "/images/scripted_activities/sa55.png",
    "/images/scripted_activities/sa56.png",
    "/images/scripted_activities/sa57.png",
    "/images/scripted_activities/sa58.png",
    "/images/scripted_activities/sa59.png",
    "/images/scripted_activities/sa6.png",
    "/images/scripted_activities/sa60.png",
    "/images/scripted_activities/sa61.png",
    "/images/scripted_activities/sa62.png",
    "/images/scripted_activities/sa63.png",
    "/images/scripted_activities/sa64.png",
    "/images/scripted_activities/sa65.png",
    "/images/scripted_activities/sa66.png",
    "/images/scripted_activities/sa67.png",
    "/images/scripted_activities/sa68.png",
    "/images/scripted_activities/sa69.png",
    "/images/scripted_activities/sa7.png",
    "/images/scripted_activities/sa70.png",
    "/images/scripted_activities/sa71.png",
    "/images/scripted_activities/sa72.png",
    "/images/scripted_activities/sa73.png",
    "/images/scripted_activities/sa74.png",
    "/images/scripted_activities/sa75.png",
    "/images/scripted_activities/sa76.png",
    "/images/scripted_activities/sa77.png",
    "/images/scripted_activities/sa78.png",
    "/images/scripted_activities/sa79.png",
    "/images/scripted_activities/sa8.png",
    "/images/scripted_activities/sa80.png",
    "/images/scripted_activities/sa81.png",
    "/images/scripted_activities/sa82.png",
    "/images/scripted_activities/sa83.png",
    "/images/scripted_activities/sa84.png",
    "/images/scripted_activities/sa85.png",
    "/images/scripted_activities/sa86.png",
    "/images/scripted_activities/sa9.png",
    "/images/scripted_activities/sa91.png",
    "/images/scripted_activities/sa92.png",
    "/images/scripted_activities/sa93.png",
    "/images/scripted_activities/sa94.png",
    "/images/scripted_activities/sa95.png",
    "/images/scripted_activities/sa96.png",
    "/images/squat.png"
];
