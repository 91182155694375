// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import MultiSelectUnstyled from '@mui/base/MultiSelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { FrozenEmptyObject, grey } from '../../Utils/Constants';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { getLabelFromChildren, useForceValidateContext, useHook } from '../Utils';
import { Checkbox } from '../Checkbox';
import { ClickAwayListener } from '@mui/material';
import { PopperUnstyled } from '@mui/base';
import { Input } from '../Input';
import { IconButton } from '../Button';
import { XMarkIcon } from '../CustomIcons';
import styled from '@emotion/styled';
import { useTranslation } from '../Translation';
import { StyledButton, StyledListbox } from './StyledSelectList';
import { styles } from './SelectList';
import { AlertText } from '../Alert';

const multiSelectStyles = css`
    list-style: none;
    padding: 8px;
    margin: 2px 0;
    cursor: default;
    display: flex;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: flow-root;

    & > * {
        margin: auto 0;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
    }

    &.${optionUnstyledClasses.disabled} {
        background-color: ${grey[50]};
    }

    &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: rgba(224, 224, 224, 0.41);
    }
`;

export const StyledPopper = styled(({
    children,
    valueRef,
    onChangeRef,
    availableItemsRef,
    searchValue,
    onFilter,
    ...props
}) => {
    const { t } = useTranslation();
    const $searchText = useHook(searchValue || '');
    const inputRef = React.useRef(null);

    useEffect(() => {
        if (!props.open) {
            return;
        }

        $searchText.set('');
        onFilter && onFilter('');

        setTimeout(() => {
            inputRef.current?.focus();
        }, 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    const value = valueRef?.current;
    const areAllSelected = !value || availableItemsRef?.current?.every(x => value.includes(x));

    let onBlurProp = () => {}
    const mappedChildren = React.Children.map(children, (child, i) => {
        onBlurProp = child.props.onBlur;
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                onBlur: e => {}
            });
        }
        return child
    });

    return (props.open &&
        <ClickAwayListener
            onClickAway={onBlurProp}
            // disableReactTree
        >
            <PopperUnstyled {...props} keepMounted>
                <div className="select-list">
                    {onFilter &&
                        <Input
                            ref={inputRef}
                            tabIndex="0"
                            variant="outlined"
                            $value={$searchText}
                            onChange={onFilter}
                            disableEndIconStyles
                            endIcon={
                                <IconButton
                                    onClick={() => {
                                        $searchText.set('');
                                        onFilter('');
                                    }}
                                    className="clear-search"
                                    style={{
                                        visibility: !$searchText.value ? 'hidden' : ''
                                    }}
                                >
                                    <XMarkIcon />
                                </IconButton>
                            }
                        />
                    }
                    {availableItemsRef?.current != null && !!availableItemsRef.current.length && onChangeRef?.current &&
                        <Checkbox
                            color="secondary"
                            className="select-all"
                            label={t('actions.selectAll')}
                            checked={areAllSelected}
                            onChange={(isChecked, e) => {
                                let newValue = [];
                                if (isChecked) {
                                    newValue = value.slice(0)
                                    for (const item of availableItemsRef.current) {
                                        if (!value.includes(item)) {
                                            newValue.push(item)
                                        }
                                    }
                                } else {
                                    newValue = value.filter(x => !availableItemsRef.current.includes(x));
                                }

                                onChangeRef.current(null, newValue);
                            }}
                        />
                    }
                    {availableItemsRef?.current != null && !availableItemsRef.current.length ?
                        t('coreComponents.select.noResultsSearchTemplate', $searchText.value)
                        :
                        mappedChildren
                    }
                </div>
            </PopperUnstyled>
        </ClickAwayListener>
    );
})`
    z-index: 1;
    inset: 0px 0px auto 0px !important;
    background: #FFFFFF;
    border-radius: 0 0 8px 8px;
    border: 1px solid ${grey[200]};
    box-shadow: 0px 4px 30px ${grey[200]};

    & .select-list {
        display: flex;
        flex-direction: column;
        padding: 8px;
        gap: 8px;
    }

    & .select-all {
        padding: 10px 8px;
        background: #858585 0% 0% no-repeat padding-box;
        width: 100%;
        border-radius: 8px;
    }

    & .clear-search {
        width: 0.75em;
        height: 0.75em;
        margin: auto;
    }
`;


export const MultiSelectOption = React.forwardRef(function ({ isSelected, children, className, ...props }, ref) {
    return (
        <OptionUnstyled
            ref={ref}
            className={className ? className + ' select-option' : 'select-option'}
            css={multiSelectStyles}
            {...props}
        >
            <Checkbox checked={isSelected} onChange={() => {}} />
            <div>{children}</div>
        </OptionUnstyled>
    );
});

export const CustomMultiSelect = React.forwardRef(function ({ children, enableFiltering, ...props }, ref) {
    const $searchText = useHook('');
    const searchText = $searchText.value.toLowerCase();
    const valueRef = React.useRef(props.value);
    const onChangeRef = React.useRef(props.onChange);
    const availableItemsRef = React.useRef([]);
    const filteredChildren = React.useMemo(() => {
        availableItemsRef.current = [];
        return React.Children.map(children, function (child, i) {
            if (!child || (!React.isValidElement(child) && typeof(child) !== 'string')) {
                return child;
            }

            let asString = ''
            if (typeof(child.props.children) === 'string') {
                asString = child.props.children;
            } else if (child.props.children) {
                asString = getLabelFromChildren(child.props.children);
            }

            const shouldInclude = asString.toLowerCase().includes(searchText);
            if (shouldInclude) {
                availableItemsRef.current.push(child.props.value);
            }

            return React.cloneElement(child, {
                isSelected: props.value?.includes(child.props.value),
                className: shouldInclude ?
                    child.props.className
                    // WARN: Safari needs a container with 'position: relative' for the .visually-hidden class
                    : (child.props.className ?? '') + ' visually-hidden'
            });
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$searchText.value, children]);

    useEffect(() => {
        valueRef.current = props.value;
        onChangeRef.current = props.onChange;
    }, [props.value, props.onChange]);

    const popper = React.useMemo(() => forwardRef((refProps, ref) => (
        <StyledPopper
            ref={ref}
            {...refProps}
            valueRef={valueRef}
            onChangeRef={onChangeRef}
            availableItemsRef={availableItemsRef}
            searchValue={$searchText.value}
            onFilter={enableFiltering && $searchText.set}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    )), [enableFiltering]);

    const slots = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: popper,
        ...props.slots,
    };

    return (
        <MultiSelectUnstyled {...props} ref={ref} slots={slots}>
            {filteredChildren}
        </MultiSelectUnstyled>
    );
});

export const MultiSelect = (props) => {
    const forceValidateContext = useForceValidateContext();
    const {
        id,
        label,
        $value,
        validator,
        children,
        design,
        value,
        onChange,
        disabled,
        enableFiltering = true,
        variant = "default",
        className,
        required,
        ...other
    } = props;
    const [validationErr, setValidationErr] = useState([]);
    const lastCheckedValue = useRef(FrozenEmptyObject);

    useEffect(() => {
        if (!forceValidateContext
            && lastCheckedValue.current === FrozenEmptyObject
        ) {
            return;
        }

        if (validator) {
            const validatedValue = value || $value?.value;
            const validationResult = validator(validatedValue);
            // console.log('useEffect validation', {label, validatedValue, validationResult})
            setValidationErr(validationResult || []);
        } else if ($value?.validate) {
            setValidationErr($value.validate() || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceValidateContext, value, validator, $value?.isValid, $value?.value, $value?.validate]);

    const onChangeInternal = (e, newValue) => {
        if (e == null && newValue == null) {
            return;
        }

        const value = newValue; // e.target.value;
        lastCheckedValue.current = value;
        $value && $value.set(value);
        onChange && onChange(value);
    }

    return (
        <div css={[styles.root, design === 'slim' && styles.slimRoot]} className={'input-root ' + (className || '')} {...other}>
            {label &&
                <label htmlFor={id} css={[styles.label, design === 'slim' && styles.slimLabel]}>
                    {label} {required && <span className="required">*</span>}
                </label>
            }
            <div className="selector-wrapper" css={[styles.selector, variant === 'outlined' && styles.outlined]}>
                <CustomMultiSelect
                    id={id}
                    value={$value ? $value.value : value}
                    onChange={onChangeInternal}
                    css={[styles.input, validationErr.length ? styles.error : null]}
                    disabled={disabled}
                    enableFiltering={enableFiltering}
                >
                    {children}
                </CustomMultiSelect>
            </div>
            {validationErr.map(x => <AlertText key={x}>{x}</AlertText>)}
        </div>
    );
};
