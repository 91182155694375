// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import React from 'react';
import { Button } from '../../../CoreComponents/Button';
import { CircularLoader } from '../../../CoreComponents/Loaders';
import { Checkbox } from '../../../CoreComponents/Checkbox';
import { shortTime } from '../../../Utils/Common';
import { useTranslation } from '../../../CoreComponents/Translation';
import { ActivityTypes, TestStatusesTranslationKeysObj } from '../../../Utils/Constants';
import { TestService } from '../../../Services/TestService';
import { css } from '@emotion/react';
import { Modal } from '../../../CoreComponents/Modal';

const styles = {
    tabs: css`
        border: 2px solid #00ABA5;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        padding: 2px;
        max-width: max-content;
        margin: 40px 0 0 40px;

        & > .tab-selector {
            padding: 6px 32px;
            min-height: 32px;
            max-height: 32px;
            box-shadow: none;
        }
    `,
    selectTestsModal: css`
        padding: 40px 0 40px 40px;
        min-height: 50vh;

        width: 600px;
        // width: calc(100vw - 128px);
        // @media (min-width: 700px) {
        //     width: 600px;
        // }

        display: flex;
        flex-direction: column;
        gap: 10px 0;

        & h2 {
            margin: 0 0 10px 0;
        }

        #tests-selection-headers {
            display: grid;
            gap: 12px 40px;
            grid-template-columns: 380px 1fr;
            // grid-template-columns: repeat(2, minmax(1fr, 380px));

            & .header {
                font: normal normal normal 16px/24px Mustica Pro;
                letter-spacing: 0.14px;
                color: #3A817F;
                display: flex;
                justify-content: space-between;

                &:nth-of-type(1) {
                    padding: 0 40px 0 70px;
                }

                & span:nth-of-type(2) {
                    width: 70px;
                }
            }
        }

        #tests-selection-list {
            max-height: 48vh;
            min-height: 48vh;
            overflow: auto;
            display: grid;
            grid-template-columns: 380px 1fr;
            // grid-template-columns: repeat(2, minmax(1fr, 380px));
            gap: 12px 40px;
            padding: 0 0 20px 0;
            align-content: start;

            & .cell-item {
                display: flex;
                max-height: 44px;

                & .test-selection-info {
                    width: 100%;
                    height: 100%;
                    padding: 10px 40px;
                    margin-left: 10px;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;

                    & span:nth-of-type(2) {
                        width: 70px;
                    }

                    border: none;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 8px 16px #28326529;
                    border-radius: 8px;

                    font: normal normal normal 16px/24px Roboto;
                    letter-spacing: 0.14px;
                    color: #858585;
                }

                & .test-selection-info:disabled {
                    background: rgba(0, 0, 0, 0.08) 0% 0% no-repeat padding-box;
                }

                & .test-selection-info:hover {
                    background: #EBF5F4 0% 0% no-repeat padding-box;
                    color: #858585;
                }

                &.selected .test-selection-info {
                    background: #00ABA5 0% 0% no-repeat padding-box;
                    color: #FFFFFF;

                    &:hover {
                        background: #00ABA580 0% 0% no-repeat padding-box;
                    }
                }
            }
        }

        & .date-range-selector {
            display: flex;
            justify-content: flex-end;
        }

        & .no-records#tests-selection-list {
            display: flex;
            justify-content: space-around;
            color: #858585;
        }

        & .actions {
            margin-top: auto;
            display: flex;
            gap: 20px;

            & .loading {
                display: flex;
                margin-left: auto;
            }
        }
    `,
    selectStepsModal: css`
        padding: 40px 20px 40px 40px;
        min-height: 50vh;

        width: 600px;
        // width: calc(100vw - 128px);
        // @media (min-width: 700px) {
        //     width: 600px;
        // }

        display: flex;
        flex-direction: column;
        gap: 10px 0;

        & h2 {
            margin: 0 0 10px 0;
        }

        #tests-selection-headers {
            display: grid;
            gap: 12px 40px;
            // grid-template-columns: 380px 1fr;
            // grid-template-columns: repeat(2, minmax(1fr, 380px));

            & .header {
                font: normal normal normal 16px/24px Mustica Pro;
                letter-spacing: 0.14px;
                color: #3A817F;
                display: flex;
                justify-content: space-between;

                &:nth-of-type(1) {
                    padding: 0 40px 0 70px;
                }

                & span:nth-of-type(2) {
                    width: 70px;
                }
            }
        }

        #tests-selection-list {
            max-height: 48vh;
            min-height: 48vh;
            overflow: auto;
            display: grid;
            // grid-template-columns: 380px 1fr;
            // grid-template-columns: repeat(2, minmax(1fr, 380px));
            gap: 12px 40px;
            padding: 0 20px 20px 0;
            align-content: start;

            & .cell-item {
                display: flex;
                max-height: 44px;

                & .test-selection-info {
                    width: 100%;
                    height: 100%;
                    padding: 10px 0 10px 40px;
                    margin-left: 10px;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;

                    & span:nth-of-type(2) {
                        width: 70px;
                    }

                    border: none;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 8px 16px #28326529;
                    border-radius: 8px;

                    font: normal normal normal 16px/24px Roboto;
                    letter-spacing: 0.14px;
                    color: #858585;
                }

                & .test-selection-info:disabled {
                    background: rgba(0, 0, 0, 0.08) 0% 0% no-repeat padding-box;
                }

                & .test-selection-info:hover {
                    background: #EBF5F4 0% 0% no-repeat padding-box;
                    color: #858585;
                }

                &.selected .test-selection-info {
                    background: #00ABA5 0% 0% no-repeat padding-box;
                    color: #FFFFFF;

                    &:hover {
                        background: #00ABA580 0% 0% no-repeat padding-box;
                    }
                }
            }
        }

        & .actions {
            margin-top: auto;
            display: flex;
            gap: 20px;

            & .loading {
                display: flex;
                margin-left: auto;
            }
        }
    `,
    loadingContainer: css`
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
    `,
}

export const CompareModal = ({
    $isSelectingOtherTests,
    $isSettingBaseline,
    $selectedSteps,
    $selectedTab,
    $isCompareModalLoading,
    $testsCompareModal,
    $selectedTestsModal,
    $isCompareSteps,
    $selectedTestsIds,
    $selectedStepsModal,
    $selectedTests,
    $baselineTest
}) => {
    const { t } = useTranslation();

    const updateBaseline = async (testId, isChecked) => {
        try {
            $isSettingBaseline.set(true);
            const baselineData = [{
                path: '/isBaseline',
                op: 'replace',
                value: false
            }];

            if ($baselineTest.value && $baselineTest.value.testId !== testId) {
                await TestService.patchTest($baselineTest.value.testId, baselineData);
            }

            baselineData[0].value = isChecked;
            await TestService.patchTest(testId, baselineData);

            const newTestsState = $testsCompareModal.value.map(x => ({ ...x, isBaseline: x.testId === testId && isChecked }));

            $selectedTests.set($selectedTests.value.map(x => ({ ...x, isBaseline: x.testId === testId && isChecked })));

            $testsCompareModal.set(newTestsState);
            $baselineTest.set(newTestsState.find(x => x.isBaseline));
        } catch (ex) {
            // console.error('error', ex);
        } finally {
            // enable button
            $isSettingBaseline.set(false);
        }
    };

    return (
        <Modal
            open={$isSelectingOtherTests.value || $isSettingBaseline.value}
            onClose={() => $isSelectingOtherTests.set(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableCloseButton
        >

            {$selectedSteps.value.length > 1 &&
                <div css={styles.tabs}>
                    <Button
                        className="tab-selector"
                        variant={$selectedTab.value === 1 ? 'contained' : 'text'}
                        onClick={() => $selectedTab.set(1)}
                    >
                        {t('actions.compareTests')}
                    </Button>
                    <Button
                        className="tab-selector"
                        variant={$selectedTab.value === 2 ? 'contained' : 'text'}
                        onClick={() => $selectedTab.set(2)}
                    >
                        {t('actions.compareSteps')}
                    </Button>
                </div>
            }
            {$selectedTab.value === 1 ?
                <div css={styles.selectTestsModal}>
                    <h2>{t('testReport.compareTests.title')}</h2>
                    {$isCompareModalLoading.value ?
                        <div css={styles.loadingContainer}>
                            <CircularLoader />
                        </div>
                        :
                        <React.Fragment>
                            <div id="tests-selection-headers">
                                <div className="header">
                                    <span>{t('testReport.compareTestsTable.date')}</span>
                                    <span>{t('testReport.compareTestsTable.status')}</span>
                                </div>
                                <div className="header">{t('testReport.compareTestsTable.baseline')}</div>
                            </div>
                            <div id="tests-selection-list" className={$testsCompareModal.value.length ? "" : "no-records"}>
                                {$testsCompareModal.value.length ? $testsCompareModal.value.map(at => {
                                    const selectionIndex = $selectedTestsModal.value
                                        .findIndex(x => x.testId === at.testId);

                                    const toggleSelection = () => {
                                        if (selectionIndex < 0) {
                                            $selectedTestsModal.set([
                                                ...$selectedTestsModal.value,
                                                at,
                                            ]);
                                        } else {
                                            $selectedTestsModal.set(
                                                [
                                                    ...$selectedTestsModal.value.slice(0, selectionIndex),
                                                    ...$selectedTestsModal.value.slice(selectionIndex + 1),
                                                ]
                                            );
                                        }
                                    };

                                    return (
                                        <React.Fragment key={at.testId}>
                                            <div
                                                className={'cell-item test-item ' + (selectionIndex >= 0 ? 'selected' : '')}
                                            >
                                                <Checkbox
                                                    disabled={selectionIndex < 0 && $selectedTestsModal.value.length > 5}
                                                    checked={selectionIndex >= 0}
                                                    onChange={isChecked => toggleSelection()}
                                                />
                                                <button
                                                    className="test-selection-info"
                                                    disabled={selectionIndex < 0 && $selectedTestsModal.value.length > 5}
                                                    onClick={() => toggleSelection()}
                                                >
                                                    <span>{shortTime(at.date)}</span>
                                                    <span>{t(TestStatusesTranslationKeysObj[at.testStatus])}</span>
                                                </button>
                                            </div>
                                            <Checkbox
                                                className="cell-item baseline"
                                                checked={at.isBaseline}
                                                disabled={$isSettingBaseline.value}
                                                onChange={isChecked => updateBaseline(at.testId, isChecked)}
                                            />
                                        </React.Fragment>
                                    );
                                })
                                    :
                                    <span>{t('coreComponents.table.noMatchingRecords')}</span>
                                }
                            </div>
                            <div className="actions">
                                <div className="loading">
                                    {$isSettingBaseline.value &&
                                        <CircularLoader />
                                    }
                                </div>
                                <Button
                                    disabled={$isSettingBaseline.value}
                                    variant="outlined"
                                    onClick={() => $isSelectingOtherTests.set(false)}
                                >
                                    {t('actions.cancel')}
                                </Button>
                                <Button
                                    disabled={$selectedTestsModal.value.length < 1 || $isSettingBaseline.value}
                                    onClick={() => {
                                        $isCompareSteps.set(false);
                                        $isSelectingOtherTests.set(false);
                                        const newSelectedList = [
                                            ...$selectedTestsModal.value
                                        ];
                                        newSelectedList.sort((a, b) => a.startTime - b.startTime);
                                        $selectedTestsIds.set(newSelectedList.map(x => x.testId));
                                    }}
                                >
                                    {t('actions.compare')}
                                </Button>
                            </div>
                        </React.Fragment>
                    }
                </div>
                :
                <div css={styles.selectStepsModal}>
                    <h2>{t('testReport.compareSteps.title')}</h2>
                    <div id="tests-selection-headers">
                        <div className="header">
                            <span>{t('testReport.compareStepsTable.name')}</span>
                            <span>{t('testReport.compareStepsTable.order')}</span>
                        </div>
                    </div>
                    <div id="tests-selection-list">
                        {$selectedSteps.value.map(at => {
                            const selectionIndex = $selectedStepsModal.value
                                .findIndex(x => x.scriptedActivityStepId === at.scriptedActivityStepId);

                            const toggleSelection = () => {
                                if (selectionIndex < 0) {
                                    const step = {
                                        ...at,
                                        test: {
                                            ...at.test,
                                            testSteps: at.test.testSteps.map(x => ({
                                                ...x,
                                                testResultBalance: at.testResultBalance,
                                                testResultBalanceMisses: at.testResultBalanceMisses,
                                                testResultCognitive: at.testResultCognitive,
                                                testResultKinematic: at.testResultKinematic,
                                                testResultKinematicsReps: at.testResultKinematicsReps,
                                                testResultMobility: at.testResultMobility,
                                                testResultMobilityCues: at.testResultMobilityCues,
                                                testResultMobilityReps: at.testResultMobilityReps,
                                                testResultMobilityTimelines: at.testResultMobilityTimelines,
                                                testResultUpperExtremity: at.testResultUpperExtremity,
                                            }))
                                        }
                                    };
                                    $selectedStepsModal.set([
                                        ...$selectedStepsModal.value,
                                        step,
                                    ]);
                                } else {
                                    $selectedStepsModal.set(
                                        [
                                            ...$selectedStepsModal.value.slice(0, selectionIndex),
                                            ...$selectedStepsModal.value.slice(selectionIndex + 1),
                                        ]
                                    );
                                }
                            };

                            const CanCompare = (activityTypeId, steps) => {
                                switch (activityTypeId) {
                                    case ActivityTypes.Mobility:
                                    case ActivityTypes.Cognitive:
                                    case ActivityTypes.Games:
                                        return steps.length && !steps.some(x => x.activityTypeId === ActivityTypes.Mobility
                                            || x.activityTypeId === ActivityTypes.Cognitive
                                            || x.activityTypeId === ActivityTypes.Games)
                                    default:
                                        return steps.length && !steps.some(x => x.activityTypeId === activityTypeId)
                                }
                            }

                            return (
                                <React.Fragment key={at.scriptedActivityStepId}>
                                    <div
                                        className={'cell-item test-item ' + (selectionIndex >= 0 ? 'selected' : '')}
                                    >
                                        <Checkbox
                                            disabled={
                                                (selectionIndex < 0 && $selectedStepsModal.value.length > 5)
                                                || ($selectedStepsModal.value.length && !$selectedStepsModal.value.some(x => x.activityTypeId === at.activityTypeId))
                                            }
                                            checked={selectionIndex >= 0}
                                            onChange={isChecked => toggleSelection()}
                                        />
                                        <button
                                            className="test-selection-info"
                                            disabled={
                                                (selectionIndex < 0 && $selectedStepsModal.value.length > 5)
                                                || CanCompare(at.activityTypeId, $selectedStepsModal.value)
                                            }
                                            onClick={() => toggleSelection()}
                                        >
                                            <span>{at.name}</span>
                                            <span>{at.orderId}</span>
                                        </button>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className="actions">
                        <div className="loading">
                            {$isSettingBaseline.value &&
                                <CircularLoader />
                            }
                        </div>
                        <Button
                            variant="outlined"
                            onClick={() => $isSelectingOtherTests.set(false)}
                        >
                            {t('actions.cancel')}
                        </Button>
                        <Button
                            disabled={$selectedStepsModal.value.length < 1}
                            onClick={() => {
                                $isCompareSteps.set(true);
                                $isSelectingOtherTests.set(false);
                                const newSelectedList = [
                                    ...$selectedStepsModal.value
                                ];
                                newSelectedList.sort((a, b) => a.startTime - b.startTime);
                                for (const step of newSelectedList) {
                                    step.test.testSteps = step.test.testSteps
                                        .filter(x => $selectedStepsModal.value
                                            .map(y => y.scriptedActivityStepId)
                                            .includes(x.scriptedActivityStepId)
                                        );
                                }
                                $selectedTests.set(newSelectedList.map(x => x.test));
                            }}
                        >
                            {t('actions.compare')}
                        </Button>
                    </div>
                </div>
            }
        </Modal>
    )
}
