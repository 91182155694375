import { createSlice } from "@reduxjs/toolkit";

const initialTableState = {
    tests: null,
    users: null,
    sites: null,
    customers: null,
};

export const tableSortState = createSlice({
    name: 'tableSortState',
    initialState: initialTableState,
    reducers: {
        setSortOptions: (state, action) => {
            state[action.payload.tableName] = action.payload.value;
        },
    }
})

export const {setSortOptions} = tableSortState.actions;

export default tableSortState.reducer;
