/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { ForceValidateContext, useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { UserService } from '../../../Services/UserService';
import { EditIcon, TrashCanIcon } from '../../../CoreComponents/CustomIcons';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useMemo } from 'react';
import { Button, IconButton } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { newGUID, shortDate } from '../../../Utils/Common';
import { InjuryService } from '../../../Services/InjuryService';
import { SidesTranslationKeysArr } from '../../../Utils/Constants';
import { CircularLoader } from '../../../CoreComponents/Loaders';
import { requiredValidator } from '../../../Utils/Validators';
import { DatePicker, datePickerRangeValidator } from '../../../CoreComponents/DatePicker';
import { AlertText } from '../../../CoreComponents/Alert';
import { subYears } from 'date-fns';
import { DeleteConfirmationModal } from '../../../CoreComponents/DeleteConfirmationModal';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const styles = {
    root: css`
        width: unset;
        height: 85vh;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 28px;

        @media (min-width: 1500px) {
            width: 1200px;
        }

        & h2 {
            margin-left: 12px;
            margin-top: 0;
        }
    `
}

export const InjuryHistoryTab = ({ $selectedUser, isProfileOpened }) => {
    const { t } = useTranslation();
    const $refreshInjuries = useHook({});
    const $injuries = useHook([]);
    const $isLoading = useHook(true);
    const $isAddInjuryModalOpened = useHook(false);
    const $selectedInjury = useHook(null);
    const $injuryForDelete = useHook(null);

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            CellRender: ({ rowData }) => {
                return <IconButton onClick={() => $selectedInjury.set(rowData)} tooltip={t('actions.edit')}>
                    <EditIcon />
                </IconButton>
            }
        },
        {
            id: 'injuryLocation',
            label: t('injury.table.location'),
            isSortable: true,
            columnWidth: 195,
            CellRender: ({ rowData }) => {
                return rowData.injuryLocation || '-';
            }
        },
        {
            id: 'injuryType',
            label: t('injury.table.type'),
            isSortable: true,
            columnWidth: 135,
            CellRender: ({ rowData }) => {
                return rowData.injuryType || '-';
            }
        },
        {
            id: 'injurySide',
            label: t('injury.table.side'),
            isSortable: true,
            columnWidth: 120,
            CellRender: ({ rowData }) => {
                return rowData.injurySide || '-';
            }
        },
        {
            id: 'dateOfInjury',
            label: t('injury.table.date'),
            columnWidth: 150,
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.dateOfInjury ? shortDate(rowData.dateOfInjury) : '-';
            }
        },
        {
            id: 'injuryStatusNavigation',
            label: t('injury.table.status'),
            isSortable: true,
            columnWidth: 140,
            CellRender: ({ rowData }) => {
                return rowData.injuryStatusNavigation || '-';
            }
        },
        {
            id: 'dateOfSurgery',
            label: t('injury.table.surgeryDate'),
            columnWidth: 150,
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.dateOfSurgery ? shortDate(rowData.dateOfSurgery) : '-';
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData }) => {
                return <IconButton onClick={() => $injuryForDelete.set(rowData)} tooltip={t('actions.delete')}>
                    <TrashCanIcon />
                </IconButton>
            }
        }
    ], [t]);

    const deleteInjury = (injuryId) => {
        UserService.deleteUserInjury(injuryId)
            .then(() => {
                $refreshInjuries.set({});
                $injuryForDelete.set(null);
            });
    }

    useEffect(() => {
        $isLoading.set(true);
        UserService.getUserInjuries($selectedUser.value.userId)
            .then(injuries => {
                $injuries.set(injuries.map(x => ({
                    ...x,
                    injuryLocation: x.injuryLocation.name,
                    injuryStatusNavigation: x.injuryStatusNavigation.name,
                    injuryType: x.injuryType.name
                })));
                $isLoading.set(false);
            });
    }, [$refreshInjuries.value])

    return (
        <>
            <div css={styles.root}>
                <h2>{isProfileOpened ?
                    t('myProfile.editModal.title')
                    : t('users.editModal.title')
                }</h2>
                <TableCSF
                    headCells={headCells}
                    rows={$injuries.value}
                    rowKeySelector={r => r.userInjuryId}
                    isLoading={$isLoading.value}
                    customActions={
                        <Button onClick={() => $isAddInjuryModalOpened.set(true)} >{t('injury.addModal.title')}</Button>
                    }
                />
            </div>
            <Modal
                open={$isAddInjuryModalOpened.value}
                onClose={() => $isAddInjuryModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddInjuryContent
                    $selectedUser={$selectedUser}
                    onCancel={() => $isAddInjuryModalOpened.set(false)}
                    onAdd={() => {
                        $isAddInjuryModalOpened.set(false);
                        $refreshInjuries.set({});
                    }} />
            </Modal>
            <Modal
                open={!!$selectedInjury.value}
                onClose={() => $selectedInjury.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EditInjuryContent
                    $selectedInjury={$selectedInjury}
                    onCancel={() => $selectedInjury.set(null)}
                    onEdit={() => {
                        $selectedInjury.set(null);
                        $refreshInjuries.set({});
                    }} />
            </Modal>
            <DeleteConfirmationModal
                onDelete={() => deleteInjury($injuryForDelete.value.userInjuryId)}
                open={Boolean($injuryForDelete.value)}
                onCancel={() => $injuryForDelete.set(null)}
                title={t('userInjury.deleteModal.title')}
                description={t('userInjury.deleteModal.description')}
                deleteLabel={t('actions.delete')}
            />
        </>
    )
}

const addInjuryModalContentStyles = {
    root: css`
        width: unset;
        height: 90vh;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0;
            margin-bottom: 40px;
        }

        & > .field-container {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            max-height: calc(100% - 110px - 1.5em);
            gap: 30px;

            & > .input-root {
                margin: 0;
                width: 100%;
            }

            @media (min-resolution: 96dpi),
                screen and (max-height: 1030px) {
                padding-right: 20px;
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }

        & > .alert-text-root {
            align-self: end;
            margin: auto 0 20px 20px;

            &+.actions {
                margin-top: 0;
            }
        }
    `,
    loadContainer: css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    `,
};

const injuryValidators = {
    userInjuryId: [requiredValidator],
    userId: [requiredValidator],
    injuryLocationId: [requiredValidator],
    injuryTypeId: [requiredValidator],
    dateOfInjury: [requiredValidator, datePickerRangeValidator(subYears(new Date(), 150), new Date())],
    injuryStatus: [requiredValidator],
}

const AddInjuryContent = ({ $selectedUser, onCancel, onAdd }) => {
    const { t } = useTranslation();
    const $isSaving = useHook(false);
    const $locations = useHook([]);
    const $type = useHook([]);
    const $statuses = useHook([]);
    const $isForceValidating = useHook(false);
    const $isInjurySideRequired = useHook(false);
    const $newUserInjury = useObjectHook(
        {
            userInjuryId: newGUID(),
            userId: $selectedUser.value.userId,
            injuryLocationId: '',
            injuryTypeId: '',
            dateOfInjury: null,
            dateOfSurgery: null,
            injuryStatus: '',
            injurySide: ''
        },
        {
            ...injuryValidators,
            injurySide: $isInjurySideRequired.value ? [requiredValidator] : []
        },
        (oldState, newState) => {
            if (newState.dateOfSurgery && newState.dateOfSurgery < newState.dateOfInjury) {
                return { dateOfSurgery: [t('injury.surgeryDate.error')] }
            }
            return null
        },
        $isForceValidating.value
    );

    useEffect(() => {
        InjuryService.getInjuryLocations()
            .then((locations) => $locations.set(locations));

        InjuryService.getInjuryStatuses()
            .then((statuses) => $statuses.set(statuses));
    }, [])

    useEffect(() => {
        if (!$newUserInjury.value.injuryLocationId) {
            return;
        }

        $isInjurySideRequired.set(
            $locations.value.some(x => x.hasSide && x.injuryLocationId === $newUserInjury.value.injuryLocationId)
        );

        InjuryService.getInjuryTypes($newUserInjury.value.injuryLocationId)
            .then(types => {
                $type.set(types);

                if (!types.some(x => x.injuryTypeId === $newUserInjury.value.injuryTypeId)) {
                    $newUserInjury.set({
                        ...$newUserInjury.value,
                        injuryTypeId: '',
                        injurySide: '',
                    });
                }
            })
    }, [$newUserInjury.value.injuryLocationId])

    const addInjury = () => {
        $isForceValidating.set(true);
        const errors = $newUserInjury.validate();
        if (errors) {
            return;
        }

        $isSaving.set(true);
        UserService.postUserInjury($newUserInjury.value)
            .then(() => onAdd($newUserInjury.value))
            .finally(() => $isSaving.set(false));

    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addInjuryModalContentStyles.root}>
                <h2>{t('injury.addModal.title')}</h2>

                <div className="field-container">
                    <SingleSelect $value={$newUserInjury.getPropHook('injuryLocationId')} required label={t('injury.fieldName.location')}>
                        {$locations.value.map(g => <SelectOption key={g.injuryLocationId} value={g.injuryLocationId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                    <SingleSelect $value={$newUserInjury.getPropHook('injuryTypeId')} required label={t('injury.fieldName.type')}>
                        {$type.value.map(g => <SelectOption key={g.injuryTypeId} value={g.injuryTypeId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                    {$isInjurySideRequired.value &&
                        <SingleSelect $value={$newUserInjury.getPropHook('injurySide')} required={$isInjurySideRequired.value} label={t('injury.fieldName.side')}>
                            {SidesTranslationKeysArr.map(x =>
                                <SelectOption key={x.value} value={x.value}>{t(x.translationKey)}</SelectOption>
                            )}
                        </SingleSelect>
                    }
                    <DatePicker
                        $value={$newUserInjury.getPropHook('dateOfInjury')}
                        required
                        label={t('injury.fieldName.dateOfInjury')}
                        minValue={subYears(new Date(), 150)}
                        maxValue={new Date()}
                    />
                    <SingleSelect $value={$newUserInjury.getPropHook('injuryStatus')} required label={t('injury.fieldName.status')}>
                        {$statuses.value.map(g => <SelectOption key={g.injuryStatusId} value={g.injuryStatusId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                    <DatePicker
                        $value={$newUserInjury.getPropHook('dateOfSurgery')}
                        label={t('injury.fieldName.dateOfSurgery')}
                        minValue={$newUserInjury.value.dateOfInjury}
                    />
                </div>
                {!$newUserInjury.isValid &&
                    <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>
                }
                <div className="actions" >
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value} onClick={addInjury}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};

const EditInjuryContent = ({ $selectedInjury, onCancel, onEdit }) => {
    const { t } = useTranslation();
    const $isSaving = useHook(false);
    const $locations = useHook([]);
    const $type = useHook([]);
    const $statuses = useHook([]);
    const $isForceValidating = useHook(false);
    const $isLoading = useHook(true);
    const $isInjurySideRequired = useHook(false);
    const $editedUserInjury = useObjectHook(
        {
            userInjuryId: $selectedInjury.value?.userInjuryId,
            userId: $selectedInjury.value?.userId || '',
            injuryLocationId: $selectedInjury.value?.injuryLocationId || '',
            injuryTypeId: $selectedInjury.value?.injuryTypeId || '',
            dateOfInjury: $selectedInjury.value?.dateOfInjury,
            dateOfSurgery: $selectedInjury.value?.dateOfSurgery,
            injuryStatus: $selectedInjury.value?.injuryStatus || '',
            injurySide: $selectedInjury.value?.injurySide || ''
        },
        {
            ...injuryValidators,
            injurySide: $isInjurySideRequired.value ? [requiredValidator] : []
        },
        (oldState, newState) => {
            if (newState.dateOfSurgery && newState.dateOfSurgery < newState.dateOfInjury) {
                return { dateOfSurgery: [t('injury.surgeryDate.error')] }
            }
            return null
        },
        $isForceValidating.value
    );

    useEffect(() => {
        InjuryService.getInjuryLocations()
            .then((locations) => $locations.set(locations));

        InjuryService.getInjuryStatuses()
            .then((statuses) => $statuses.set(statuses));
    }, [])

    useEffect(() => {
        if (!$editedUserInjury.value.injuryLocationId) {
            return;
        }

        $isInjurySideRequired.set(
            $locations.value.some(x => x.hasSide && x.injuryLocationId === $editedUserInjury.value.injuryLocationId)
        );

        InjuryService.getInjuryTypes($editedUserInjury.value.injuryLocationId)
            .then(types => {
                $type.set(types);

                if (!types.some(x => x.injuryTypeId === $editedUserInjury.value.injuryTypeId)) {
                    $editedUserInjury.set({
                        ...$editedUserInjury.value,
                        injuryTypeId: '',
                        injurySide: '',
                    });
                }

                $isLoading.set(false);
            })
    }, [$editedUserInjury.value.injuryLocationId])

    const editInjury = () => {
        $isForceValidating.set(true);
        const errors = $editedUserInjury.validate();
        if (errors) {
            return;
        }

        $isSaving.set(true);
        UserService.putUserInjury($editedUserInjury.value)
            .then(() => onEdit($editedUserInjury.value))
            .finally(() => $isSaving.set(false));

    };

    return ($isLoading.value ?
        <div css={[addInjuryModalContentStyles.loadContainer, addInjuryModalContentStyles.root]}>
            <CircularLoader />
        </div>
        :
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addInjuryModalContentStyles.root}>
                <h2>{t('injury.editModal.title')}</h2>
                <SingleSelect $value={$editedUserInjury.getPropHook('injuryLocationId')} required label={t('injury.fieldName.location')}>
                    {$locations.value.map(g => <SelectOption key={g.injuryLocationId} value={g.injuryLocationId}>{g.name}</SelectOption>)}
                </SingleSelect>
                <SingleSelect $value={$editedUserInjury.getPropHook('injuryTypeId')} required label={t('injury.fieldName.type')}>
                    {$type.value.map(g => <SelectOption key={g.injuryTypeId} value={g.injuryTypeId}>{g.name}</SelectOption>)}
                </SingleSelect>
                {$isInjurySideRequired.value &&
                    <SingleSelect $value={$editedUserInjury.getPropHook('injurySide')} required={$isInjurySideRequired.value} label={t('injury.fieldName.side')}>
                        {SidesTranslationKeysArr.map(x =>
                            <SelectOption key={x.value} value={x.value}>{t(x.translationKey)}</SelectOption>
                        )}
                    </SingleSelect>
                }
                <DatePicker
                    $value={$editedUserInjury.getPropHook('dateOfInjury')}
                    required
                    label={t('injury.fieldName.dateOfInjury')}
                    minValue={subYears(new Date(), 150)}
                    maxValue={new Date()}
                />
                <SingleSelect $value={$editedUserInjury.getPropHook('injuryStatus')} required label={t('injury.fieldName.status')}>
                    {$statuses.value.map(g => <SelectOption key={g.injuryStatusId} value={g.injuryStatusId}>{g.name}</SelectOption>)}
                </SingleSelect>
                <DatePicker
                    $value={$editedUserInjury.getPropHook('dateOfSurgery')}
                    label={t('injury.fieldName.dateOfSurgery')}
                    minValue={$editedUserInjury.value.dateOfInjury}
                />

                {!$editedUserInjury.isValid &&
                    <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>
                }
                <div className="actions" >
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value} onClick={editInjury}>{t('actions.edit')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
