/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { newGUID } from '../../../Utils/Common';
import { CustomerService } from '../../../Services/CustomerService';
import { SiteService } from '../../../Services/SiteService';
import { GroupService } from '../../../Services/GroupService';
import { UserService } from '../../../Services/UserService';
import { UnitService } from '../../../Services/UnitService';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import { AdminTypes, AdminTypesTranslationKeysArr, GendersTranslationKeysArr } from '../../../Utils/Constants';
import {
    emailValidator,
    maxLengthValidator,
    minValueValidator,
    requiredValidator,
    rangeLengthValidator,
    usernameValidator,
    digitsValidator,
    combineValidators,
    maxValueValidator,
    PINValidator
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { DatePicker, datePickerRangeValidator } from '../../../CoreComponents/DatePicker';
import { AlertText } from '../../../CoreComponents/Alert';
import { subYears } from 'date-fns';
import { RadioGroup, RadioOption } from '../../../CoreComponents/RadioSelection';
import { AdminService } from '../../../Services/AdminService';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const addUserModalContentStyles = {
    root: css`
        width: unset;
        height: 85vh;
        display: flex;
        flex-direction: column;

        @media (min-width: 1200px) {
            width: 920px;

        }

        & > h2 {
            width: 100%;
            margin: 40px 0 40px 40px;
        }

        & > .scroll-content {
            overflow-y: auto;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            height: calc(100% - 205px);
            padding-left: 20px;

            & > .height-weight {
                & > div:nth-of-type(2) {
                    margin-left: 0;
                    width: calc(30% - 10px);
                }

                & > .height {
                    width: calc(70% - 30px);
                    display: flex;
                    flex-wrap: wrap;
                    margin: 8px 40px 14px 20px;

                    & > * {
                        width: calc(50% - 10px);

                        &:last-child {
                            margin-left: 20px;
                        }
                    }


                    & > label {
                        width: 100%;
                        margin: 0 auto 8px 0;
                        letter-spacing: 0.14px;
                        color: #858585;
                        font: normal normal bold 16px Mustica Pro;
                        margin-bottom: 10px;

                        &.invalid {
                            color: #E0758A;
                        }
                    }
                }
            }

            & > * {
                display: inline-flex;

                width: calc(100% - 40px);
                &.height-weight {
                    width: 100%;
                }
                &.vertical {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                @media (min-width: 1200px) {
                    width: calc(50% - 40px);
                    &.height-weight {
                        width: 50%;
                    }
                    &.vertical {
                        width: 50%;
                    }
                }
            }

            & > .horizontal-line {
                border-top: 2px solid rgb(211,211,211);
                padding: 5px;
                margin: auto;
                margin-top: 20px;
                width: calc(100% - 40px);
            }

            & > .role {
                margin: 8px 20px 21px 20px;
            }

            & > .enableUserLogin {
                margin: 8px 20px 21px 20px;
                & .radio-group {
                    display: flex;
                    flex-direction: row;
                    margin-left: 0;
                }
            }
        }

        & .actions {
            display: flex;
            width: 100%;
            padding: 40px 0 40px 40px;

            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }
    `,
};

const UserValidators = {
    firstName: [requiredValidator, maxLengthValidator(50)],
    lastName: [requiredValidator, maxLengthValidator(50)],
    username: [usernameValidator, rangeLengthValidator(6, 20)],
    email: [maxLengthValidator(50), emailValidator, (value, t, obj) => {
        if (obj.adminTypeId === AdminTypes.User && obj.enableUserLogin === 'Off') {
            return [];
        }
        return requiredValidator(value);
    }],
    weight: [requiredValidator, digitsValidator],
    pin: [PINValidator],
    dateOfBirth: [
        requiredValidator,
        datePickerRangeValidator(subYears(new Date(), 150), new Date())
    ],
    gender: [requiredValidator],
    adminTypeId: [requiredValidator],
};

export const AddUserContent = ({ onCancel, onAdd }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $groups = useHook([]);
    const $units = useHook([]);
    const $selectedSite = useHook(null, requiredValidator);
    const $selectedCustomer = useHook(storeState.currentUser.admin?.customerId || null, requiredValidator);
    const $selectedGroup = useHook(null);
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const $availableRoles = useHook([]);
    const $newUser = useObjectHook(
        {
            userId: newGUID(),
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            phoneNumber: '',
            dateOfBirth: '',
            gender: '',
            height: '',
            weight: '',
            pin: '',
            enableUserLogin: 'Off',
            userSites: [],
            userGroups: [],
            adminTypeId: 0
        },
        UserValidators,
        null,
        $isForceValidating.value
    );

    const $feet = useHook('', combineValidators([digitsValidator, minValueValidator(1), maxValueValidator(10)]));
    const $inches = useHook('', combineValidators([requiredValidator, digitsValidator]));

    useEffect(() => {
        $newUser.set({ ...$newUser.value, height: ($feet.value * 12) + Number($inches.value) });
    }, [$feet.value, $inches.value]);

    useEffect(() => {
        CustomerService.getAll(9999, 'Name', true)
            .then(customers => $customers.set(customers));

        $availableRoles.set(
            AdminTypesTranslationKeysArr
                .filter(r => r.value <= storeState.currentUser.admin.adminTypeId || Number(r.value) === AdminTypes.Operator)
        )
    }, [])

    useEffect(() => {
        if (!$selectedCustomer.value) {
            return;
        }
        SiteService.getAll(9999, $selectedCustomer.value, null, 'Name', true)
            .then(sites => $sites.set(sites));
    }, [$selectedCustomer.value])

    useEffect(() => {
        if (!$selectedCustomer.value || !$selectedSite.value) {
            return;
        }
        GroupService.getGroups(9999, $selectedCustomer.value, $selectedSite.value)
            .then(groups => $groups.set(groups));
        
        UnitService.getAll(9999, $selectedCustomer.value, $selectedSite.value)
            .then(units => $units.set(units));
    }, [$selectedCustomer.value, $selectedSite.value])

    const addUser = () => {
        $isForceValidating.set(true);
        let errors = $newUser.validate();
        const feetErrors = $feet.validate();
        const inchesErrors = $inches.validate();
        if (errors || !$selectedCustomer.value || !$selectedSite.value || feetErrors || inchesErrors) {
            return;
        }

        const newUser = {
            ...$newUser.value,
            userSites: [{
                userId: $newUser.value.userId,
                siteId: $selectedSite.value
            }],
            userGroups: [],
            height: ($feet.value * 12) + Number($inches.value),
            email: $newUser.value.email ? $newUser.value.email : null
        };

        if ($selectedGroup.value) {
            newUser.userGroups.push({
                userId: $newUser.value.userId,
                groupId: $selectedGroup.value
            })
        }
        $newUser.set(newUser);

        $isSaving.set(true);
        UserService.postUser(newUser)
            .then(() => {
                if ($newUser.value.adminTypeId !== AdminTypes.User || ($newUser.value.adminTypeId === AdminTypes.User && $newUser.value.enableUserLogin === 'On')) {
                    const admin = {
                        adminId: newUser.userId,
                        adminTypeId: $newUser.value.adminTypeId,
                        customerId: $selectedCustomer.value,
                        email: newUser.email,
                        adminSites: newUser.userSites.map(site => ({ siteId: site.siteId, adminId: newUser.userId })),
                    };

                    $isSaving.set(true);
                    AdminService.postAdmin(admin)
                        .then(admin => {
                            newUser.admin = admin;
                            onAdd(newUser);
                        })
                        .finally(() => $isSaving.set(false));
                }
                else {
                    onAdd(newUser);
                    $isSaving.set(false);
                }
            })
            .catch(() => $isSaving.set(false));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addUserModalContentStyles.root}>
                <h2>{t('users.addModal.title')}</h2>

                <div className="scroll-content">
                    <Input placeholder={t('input.placeholder')} required label={t('users.fieldName.firstName')} $value={$newUser.getPropHook('firstName')} />
                    <Input placeholder={t('input.placeholder')} required label={t('users.fieldName.lastName')} $value={$newUser.getPropHook('lastName')} />
                    <Input placeholder={t('input.placeholder')} required={$newUser.value.adminTypeId !== AdminTypes.User || ($newUser.value.adminTypeId === AdminTypes.User && $newUser.value.enableUserLogin === 'On')} label={t('users.fieldName.email')} $value={$newUser.getPropHook('email')} />
                    <Input placeholder={t('input.placeholder')} label={t('users.fieldName.phoneNumber')} $value={$newUser.getPropHook('phoneNumber')} />
                    <Input placeholder={t('input.placeholder')} label={t('users.fieldName.username')} $value={$newUser.getPropHook('username')} />
                    <Input placeholder={t('input.placeholder')} label={t('users.fieldName.optionalPin')} $value={$newUser.getPropHook('pin')} />
                    <DatePicker
                        label={t('users.fieldName.dateOfBirth')}
                        required
                        placeholder={t('input.placeholder')}
                        $value={$newUser.getPropHook('dateOfBirth')}
                        maxValue={new Date()}
                        minValue={subYears(new Date(), 150)}
                    />
                    <div className="height-weight">
                        <div className="height">
                            <label className={(!$feet.isValid || !$inches.isValid) ? "invalid" : ''}>
                                {t('users.fieldName.height')} <span className="required">*</span>
                            </label>
                            <div>
                                <Input endIcon={<span>{t('constants.unitType.feetShort')}</span>} $value={$feet} />
                            </div>
                            <div>
                                <Input
                                    onBlur={() => {
                                        const value = Number($inches.value)

                                        const feet = Math.floor(value / 12);
                                        if (feet) {
                                            $feet.set(feet);
                                        }
                                        $inches.set('' + (value - feet * 12));
                                    }}
                                    endIcon={<span>{t('constants.unitType.inchShort')}</span>}
                                    $value={$inches}
                                />
                            </div>
                        </div>
                        <Input
                            label={t('users.fieldName.weight')}
                            required
                            $value={$newUser.getPropHook('weight')}
                            endIcon={<span>{t('constants.unitType.poundShort')}</span>}
                        />
                    </div>
                    <SingleSelect $value={$newUser.getPropHook('gender')} required label={t('users.fieldName.gender')} disableFiltering>
                        {GendersTranslationKeysArr.map(x => (
                            <SelectOption key={x.value} value={x.value}>{t(x.translationKey)}</SelectOption>
                        ))}
                    </SingleSelect>
                    <div className="horizontal-line"></div>
                    {storeState.currentUser.admin.adminTypeId > AdminTypes.Provider ?
                        <>
                            <div className="vertical">
                                <AttachDropdowns
                                    $selectedCustomer={$selectedCustomer}
                                    $customers={$customers}
                                    storeState={storeState}
                                    $selectedSite={$selectedSite}
                                    $selectedGroup={$selectedGroup}
                                    $sites={$sites}
                                    $groups={$groups}
                                />
                            </div>
                            <RadioGroup className="role" $value={$newUser.getPropHook('adminTypeId')} required label={t('users.fieldName.role')}>
                                {$availableRoles.value.map(r => (
                                    <RadioOption key={r.value} value={r.value} label={t(r.translationKey)} />
                                ))}
                            </RadioGroup>
                        </>
                        :
                        <AttachDropdowns
                            $selectedCustomer={$selectedCustomer}
                            $customers={$customers}
                            storeState={storeState}
                            $selectedSite={$selectedSite}
                            $selectedGroup={$selectedGroup}
                            $sites={$sites}
                            $groups={$groups}
                        />
                    }
                    {$newUser.value.adminTypeId === AdminTypes.User && $units && $units.value.filter(u => u.adminGlobalSetting.unitModel === 1).length > 0 &&
                        <div className='enableUserLogin'>
                            <RadioGroup $value={$newUser.getPropHook('enableUserLogin')} label={t('users.fieldName.enableUserLogin')}>
                                <RadioOption key='On' value='On' label={t('users.fieldName.enableUserLogin.onOption')} />
                                <RadioOption key='Off' value='Off' label={t('users.fieldName.enableUserLogin.offOption')} />
                            </RadioGroup>
                        </div>
                    }
                </div>
                <div className="actions" css={addUserModalContentStyles.actions}>
                    {(!$newUser.isValid || !$selectedSite.isValid) && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}

                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newUser.isValid || !$selectedSite.isValid} onClick={addUser}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};

const AttachDropdowns = ({ $selectedCustomer, $customers, storeState, $selectedSite, $selectedGroup, $sites, $groups }) => {
    const { t } = useTranslation();
    return (
        <>
            {storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin ?
                <SingleSelect $value={$selectedCustomer} required label={t('users.fieldName.customer')}>
                    {$customers.value.map(g => <SelectOption key={g.customerId} value={g.customerId}>{g.name}</SelectOption>)}
                </SingleSelect>
                :
                null
            }

            <SingleSelect $value={$selectedSite} required label={t('users.fieldName.site')}>
                {$sites.value.map(g => <SelectOption key={g.siteId} value={g.siteId}>{g.name}</SelectOption>)}
            </SingleSelect>
            <SingleSelect $value={$selectedGroup} label={t('users.fieldName.group')}>
                {$groups.value.map(g => <SelectOption key={g.groupId} value={g.groupId}>{g.groupName}</SelectOption>)}
            </SingleSelect>
        </>
    )
}
