/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import { Button } from "../CoreComponents/Button";
import { useTranslation } from "../CoreComponents/Translation";
import { useHook } from "../CoreComponents/Utils"
import { CustomerService } from "../Services/CustomerService";
import { UserService } from "../Services/UserService";
import { CustomerNewUsersTrendWidget } from "./Widgets/CustomerWidgets/CustomerNewUsersTrendWidget";
import { CustomerSessionsTrendWidget } from "./Widgets/CustomerWidgets/CustomerSessionsTrendWidget";
import { UserCaloriesBurnedTrendWidget } from "./Widgets/UserWidgets/UserCaloriesBurnedTrendWidget";
import { UserDistanceTraveledTrendWidget } from "./Widgets/UserWidgets/UserDistanceTraveledTrendWidget";
import { UserOverviewWidget } from "./Widgets/UserWidgets/UserOverviewWidget";
import { UserSessionsTrendWidget } from "./Widgets/UserWidgets/UserSessionsTrendWidget";
import { CircularLoader } from "../CoreComponents/Loaders";
import { deepCopy } from "../Utils/Common";
import { useDispatch, useSelector } from "react-redux";
import { setCustomers, setEditMode, setUsers } from "../globalStates/dashboardState";

const gridColumnsCount = 16;
const gridRowsCount = 12;
const gridCellSize = 100;
const gridGap = 10;
const dashboardStyles = {
    root: css`
        width: 100%;
        height: 100%;

        & .actions {
            margin: 40px 10px 40px 30px;
            & > button {
                margin-left: 10px;
            }
        }

        & > .container {
            height: calc(100% - 160px);
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            padding: 0 40px;

            & > aside {
                overflow: auto;
                min-width: 500px;
                width: 500px;
                height: 100%;
                display: flex;
                flex-direction: column;
                margin-right: 8px;
                padding-right: 10px;
                gap: 8px;
                & > * {
                    min-height: 230px;
                }

            }

            & > .dashboard-area {
                height: 100%;
                width: 100%;
                overflow: auto;
            }
        }

        & .edit-mode.container {
            // zoom: 80%;
        }

        & .draggable.dragging {
            opacity: .5;
        }

        & .dashboard-grid {
            width: ${gridColumnsCount * gridCellSize}px;
            height: ${gridRowsCount * gridCellSize}px;
            display: grid;
            grid-template-columns: repeat(${gridColumnsCount}, 1fr);
            grid-template-rows: repeat(${gridRowsCount}, 1fr);
            grid-gap: ${gridGap}px;
        }

        & .edit-grid {
            background-color: #8b877e63;
            --l: #EEEEEE ${gridGap}px, transparent ${gridGap}px ;
            background-image:
                linear-gradient(0deg, var(--l)),
                linear-gradient(90deg, var(--l));
            background-size: ${gridCellSize + (gridGap / gridColumnsCount)}px ${gridCellSize + (gridGap / gridRowsCount)}px;
            background-position: -${gridGap}px 0;
            margin: 0;
        }

        & .invalid {
            border: 5px solid red;
            animation: blinkingBorder 1s infinite
        }

        @keyframes blinkingBorder{
            0%		{ border-color: red;}
            50%		{ border-color: #ffffff;}
            100%	{ border-color: red;}
        }
    `,
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `
}

let editInitialItems = [];
let gridRender = [];
let initialItems = [];
let currentItems = [];
let canMove;
let draggingItemCursorOffset = {
    x: 0,
    y: 0
};
let nextId = -1;
let startItemId = null;
function handleDragStart(e, $itemsConfig, itemId) {
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;
    const draggingElement = e.currentTarget;
    draggingItemCursorOffset = {
        x: offsetX,
        y: offsetY,
    };
    canMove = true;
    draggingElement.classList.add('dragging')
    gridRender = [];
    for (let row = 0; row < gridRowsCount; ++row) {
        gridRender.push(new Array(gridColumnsCount));
        for (let column = 0; column < gridColumnsCount; ++column) {
            gridRender[row][column] = 0;
        }
    }
    renderGrid(gridRender, $itemsConfig.value);
    initialItems = $itemsConfig.value;
    startItemId = itemId;
    currentItems = $itemsConfig.value.map(x => ({ ...x }));

    $itemsConfig.set(currentItems)
}

function handleDragEnd(e, $itemsConfig) {
    const draggedItemId = startItemId;
    startItemId = null;
    e.currentTarget.classList.remove('invalid')

    const rect = document.querySelector('.dashboard-area').getBoundingClientRect();
    if (e.pageX <= rect.right && e.pageX >= rect.left && e.pageY >= rect.top && e.pageY <= rect.bottom) {
        if (!canMove) {
            $itemsConfig.set(initialItems)
            return;
        }
        $itemsConfig.set(currentItems.slice(0))
    } else {
        $itemsConfig.set(initialItems.filter(x => x.id !== draggedItemId))
    }
}

const WidgetsTypes = {
    UserOverview: UserOverviewWidget,
    UserDistanceTraveledTrend: UserDistanceTraveledTrendWidget,
    CustomerSessionsTrend: CustomerSessionsTrendWidget,
    CustomerNewUsersTrend: CustomerNewUsersTrendWidget,
    UserSessionsTrend: UserSessionsTrendWidget,
    UserCaloriesBurnedTrend: UserCaloriesBurnedTrendWidget
}

const ItemConfigContent = ({ $itemsConfig, item, isEditing, persistConfiguration }) => {
    const $settings = useHook(item.settings);
    useEffect(() => {
        if ($settings.value !== item.settings) {
            const newItemsConfig = $itemsConfig.value
                .map(ic => ic.id === item.id ?
                    ({ ...ic, settings: $settings.value.settings })
                    : ic)
            $itemsConfig.set(newItemsConfig)
            if (!isEditing) {
                persistConfiguration(newItemsConfig);
            }
        }
    }, [$settings.value]);
    const Widget = WidgetsTypes[item.type];
    return <Widget $settings={$settings} demoMode={false} />
}

export const Dashboard = () => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const dashboardState = useSelector((state) => state.dashboardState);
    const isEditing = dashboardState.isEditing;
    const dispatch = useDispatch();
    const $items = useHook([]);
    const $itemsConfig = useHook([]);
    const $isLoading = useHook(true);
    const $availableWidgets = useHook([
        {
            width: 4,
            height: 3,
            Content: () => <UserOverviewWidget demoMode />,
            type: 'UserOverview',
            isDemoWidget: true
        },
        {
            width: 7,
            height: 3,
            Content: () => <UserDistanceTraveledTrendWidget demoMode />,
            type: 'UserDistanceTraveledTrend',
            isDemoWidget: true
        },
        {
            width: 7,
            height: 3,
            Content: () => <UserSessionsTrendWidget demoMode />,
            type: 'UserSessionsTrend',
            isDemoWidget: true
        },
        {
            width: 7,
            height: 3,
            Content: () => <UserCaloriesBurnedTrendWidget demoMode />,
            type: 'UserCaloriesBurnedTrend',
            isDemoWidget: true
        },
        {
            width: 7,
            height: 3,
            Content: () => <CustomerSessionsTrendWidget demoMode />,
            type: 'CustomerSessionsTrend',
            isDemoWidget: true
        },
        {
            width: 7,
            height: 3,
            Content: () => <CustomerNewUsersTrendWidget demoMode />,
            type: 'CustomerNewUsersTrend',
            isDemoWidget: true
        }
        //when you add a new item add it also to the WidgetsTypes mapping
    ]);

    const persistConfiguration = (items) => {
        const timestamp = (new Date()).getTime();
        UserService.putDashboard({
            userId: storeState.currentUser.userId,
            version: '1',
            items: JSON.stringify(
                items.map(x => ({
                    ...x,
                    id: x.id < 0 ? (timestamp - x.id)
                        : x.id
                })))
        })
            .then(() => {
                $itemsConfig.set(items);
            })
    }

    useEffect(() => {
        (async () => {
            await Promise.all([
                UserService.getAll(null, null, null, storeState.currentUser.admin.customerId)
                    .then(users => {
                        dispatch(setUsers(users));
                    }),
                CustomerService.getAll()
                    .then(customers => {
                        dispatch(setCustomers(customers));
                    }),
                UserService.getDashboard(storeState.currentUser.userId)
                    .then(dashboard => {
                        if (dashboard?.items) {
                            $itemsConfig.set(JSON.parse(dashboard.items))
                        }
                    })
            ])
            $isLoading.set(false)
        })();
    }, [])

    useEffect(() => {
        const mappedItems = $itemsConfig.value.map(x => {
            x.Content = ItemConfigContent;
            return x;
        })

        $items.set(mappedItems)
    }, [$itemsConfig.value])

    return ($isLoading.value ?
        <div css={dashboardStyles.loadingContainer}>
            <CircularLoader />
        </div>
        :
        <div css={dashboardStyles.root}>
            <div className="actions">
                <Button
                    onClick={() => {
                        dispatch(setEditMode(!isEditing));
                        if (isEditing) {
                            persistConfiguration($itemsConfig.value);
                        } else {
                            editInitialItems = $itemsConfig.value;
                        }
                    }}
                >
                    {isEditing ? t('actions.done') : t('actions.edit')}
                </Button>
                {isEditing &&
                    <Button
                        onClick={() => {
                            $itemsConfig.set(editInitialItems);
                            dispatch(setEditMode(false));
                        }}
                    >
                        {t('actions.cancel')}
                    </Button>
                }
            </div>
            <div className={`container ${isEditing && 'edit-mode'}`}>
                {isEditing &&
                    <aside>
                        {$availableWidgets.value.map(item =>
                            <DraggableItem
                                key={item.type}
                                onDragStart={(e) => {
                                    const resetGrid = () => {
                                        const gridRender = [];
                                        for (let row = 0; row < gridRowsCount; ++row) {
                                            gridRender.push(new Array(gridColumnsCount));
                                            for (let column = 0; column < gridColumnsCount; ++column) {
                                                gridRender[row][column] = 0;
                                            }
                                        }
                                        return gridRender
                                    }

                                    const getStartPositions = (gridRender, item) => {
                                        for (let row = 0; row < gridRowsCount; ++row) {
                                            for (let column = 0; column < gridColumnsCount; ++column) {
                                                if (gridRender[row][column] === 0 && column < (gridColumnsCount - item.width) && row < (gridRowsCount - item.height)) {
                                                    let hasSpace = true;
                                                    for (let startY = row; startY < (row + item.height); ++startY) {
                                                        for (let startX = column; startX < (column + item.width); ++startX) {
                                                            if (gridRender[startY][startX] !== 0) {
                                                                hasSpace = false;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    if (hasSpace) {
                                                        return { x: column + 1, y: row + 1 }
                                                    }
                                                }
                                            }
                                        }
                                        return { x: 1, y: 1 }
                                    }
                                    gridRender = resetGrid();
                                    renderGrid(gridRender, $itemsConfig.value);
                                    const { x, y } = getStartPositions(gridRender, item)
                                    startItemId = --nextId;
                                    const items = [
                                        ...$itemsConfig.value,
                                        {
                                            ...item,
                                            id: startItemId,
                                            x: x,
                                            y: y,
                                            Content: ItemConfigContent
                                        }
                                    ];
                                    $itemsConfig.set(items)

                                    const offsetX = e.nativeEvent.offsetX;
                                    const offsetY = e.nativeEvent.offsetY;
                                    draggingItemCursorOffset = {
                                        x: offsetX,
                                        y: offsetY,
                                    };
                                    canMove = true;

                                    renderGrid(gridRender, items);
                                    initialItems = items;
                                    currentItems = items.map(x => ({ ...x }));
                                }}
                                onDragEnd={(e) => {
                                    const rect = e.currentTarget.parentElement.getBoundingClientRect();
                                    const draggedItemId = startItemId;
                                    if ((e.pageX <= rect.right && e.pageX >= rect.left && e.pageY >= rect.top && e.pageY <= rect.bottom) || !canMove) {
                                        $itemsConfig.set(initialItems.filter(x => x.id !== draggedItemId))
                                    } else {
                                        $itemsConfig.set(currentItems.map(x => ({ ...x, isDemoWidget: false })))
                                    }
                                    startItemId = null;

                                }}
                            >
                                <item.Content />
                            </DraggableItem>
                        )}
                    </aside>
                }
                <div className="dashboard-area">
                    <DroppableContainer
                        className={`dashboard-grid ${isEditing && 'edit-grid'}`}
                        $items={$items}
                        $itemsConfig={$itemsConfig}
                        isEditing={isEditing}
                        css={containerStyle}
                        users={dashboardState.users}
                        customers={dashboardState.customers}
                        persistConfiguration={persistConfiguration}
                    />
                </div>
            </div>
        </div>
    )
}

const containerStyle = css`
    border-radius: 8px;
`
const DroppableContainer = ({ $items, $itemsConfig, isEditing, users, customers, persistConfiguration, ...props }) => {
    const ref = useRef(null);
    return (
        <div
            ref={ref}
            css={containerStyle}
            onDragOver={(event) => isEditing && handleDragOver(event, $itemsConfig)}
            {...props}
        >
            {$items.value.map((item, index) =>
                <DraggableItem
                    item={item}
                    onDragStart={(e) => isEditing && handleDragStart(e, $itemsConfig, item.id)}
                    onDragEnd={(e) => isEditing && handleDragEnd(e, $itemsConfig)}
                    key={item.id}
                    style={{
                        gridColumnStart: item.x,
                        gridColumnEnd: item.x + item.width,
                        gridRowStart: item.y,
                        gridRowEnd: item.y + item.height
                    }}
                    isDragging={startItemId === item.id}
                    data-index={index}
                    draggable={isEditing}
                >
                    <item.Content
                        $itemsConfig={$itemsConfig}
                        item={item}
                        isEditing={isEditing}
                        persistConfiguration={persistConfiguration}
                    />
                </DraggableItem>
            )}
        </div>
    )
}

const draggableItemStyle = css`
    padding: 1rem;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: 1s;
    &[draggable=true] {
        cursor: move;
    }`

const DraggableItem = ({ item, children, draggable = true, isDragging, ...props }) => {
    return (
        <div
            className={`draggable ${isDragging ? 'dragging' : ''}`}
            {...props}
            css={draggableItemStyle}
            draggable={draggable}
        >
            {children}
        </div>
    )
}
function handleDragOver(e, $items) {
    e.preventDefault();

    const container = e.currentTarget;
    const draggingElement = container.querySelectorAll('.draggable.dragging')[0]
    const rect = container.getBoundingClientRect();

    const item = currentItems.find(x => x.id === startItemId)
    const x = e.pageX - rect.left - draggingItemCursorOffset.x;
    const y = e.pageY - rect.top - draggingItemCursorOffset.y;
    if (!draggingElement || !item) {
        return;
    }

    const width = item.width || 1;
    const startX = Math.max(1, Math.min(Math.ceil((x / container.clientWidth) * gridColumnsCount), gridColumnsCount - width + 1));
    const height = item.height || 1;
    const startY = Math.max(1, Math.min(Math.ceil((y / container.clientHeight) * gridRowsCount), gridRowsCount - height + 1));
    draggingElement.classList.remove('invalid')
    canMove = true;

    const getOverlappingId = (grid, item) => {
        const overlappedItems = {}
        for (let y = item.y; y < item.y + item.height; ++y) {
            for (let x = item.x; x < item.x + item.width; ++x) {
                if (grid[y - 1][x - 1] && (grid[y - 1][x - 1] !== item.id)) {
                    overlappedItems[grid[y - 1][x - 1]] = 1;
                }
            }
        }
        return Object.keys(overlappedItems).map(Number);
    }

    const pushAsideItems = (gridRender, initialItems, item, startX, startY, iteration = 10) => {
        if (iteration < 1) {
            return initialItems
        }

        let initialOverlappedItemIds = getOverlappingId(gridRender, { ...item, x: startX, y: startY });
        let itemsToReset = initialItems.map(x => ({ ...x }));

        const updateItem = (itemId, data) => {
            const index = itemsToReset.findIndex(x => x.id === itemId);
            itemsToReset[index] = data;
        }

        updateItem(item.id, { ...item, x: startX, y: startY })

        for (const overlappedItemId of initialOverlappedItemIds) {
            const gridRenderCopy = deepCopy(gridRender);
            renderGrid(gridRenderCopy, itemsToReset)
            const overlappingItem = initialItems.find(x => x.id === overlappedItemId);
            let tempItems = itemsToReset.map(x => x.id === item.id ? { ...x, x: startX, y: startY } : x)

            tempItems.sort((a, b) => {
                if (a.id === item.id) {
                    return 1
                }
                if (b.id === item.id) {
                    return -1
                }
                return 0
            })

            renderGrid(gridRenderCopy, tempItems)

            const overlappingId = getOverlappingId(gridRenderCopy, overlappingItem);

            if (!overlappingId.includes(item.id)) {
                updateItem(overlappingItem.id, overlappingItem)
                continue
            }

            const moveUpStepsCount = (grid, item, startY) => {
                if (startY - item.height < 1) {
                    return 0;
                }
                for (let y = startY - 1; y >= startY - item.height; --y) {
                    for (let x = item.x; x < item.x + item.width; ++x) {
                        if (grid[y - 1][x - 1] && (grid[y - 1][x - 1] !== item.id)) {
                            return -(item.y - startY + item.height);
                        }
                    }
                }
                return item.y - startY + item.height;
            }

            const moveLeftStepsCount = (grid, item, startX) => {
                if (startX - item.width < 1) {
                    return 0;
                }
                for (let y = item.y; y < item.y + item.height; ++y) {
                    for (let x = startX - 1; x >= startX - item.width; --x) {
                        if (grid[y - 1][x - 1] && (grid[y - 1][x - 1] !== item.id)) {
                            return -(item.x - startX + item.width);
                        }
                    }
                }
                return item.x - startX + item.width;
            }

            const moveRightStepsCount = (grid, item, startX) => {
                if (startX + item.width - 1 > gridColumnsCount) {
                    return 0;
                }
                for (let y = item.y; y < item.y + item.height; ++y) {
                    for (let x = startX; x < startX + item.width; ++x) {
                        if (grid[y - 1][x - 1] && (grid[y - 1][x - 1] !== item.id)) {
                            return -(startX - item.x);
                        }
                    }
                }
                return startX - item.x;
            }

            const moveDownStepsCount = (grid, item, startY) => {
                if (startY + item.height - 1 > gridRowsCount) {
                    return 0;
                }
                for (let y = startY; y < startY + item.height; ++y) {
                    for (let x = item.x; x < item.x + item.width; ++x) {
                        if (grid[y - 1][x - 1] && (grid[y - 1][x - 1] !== item.id)) {
                            return -(startY - item.y);
                        }
                    }
                }
                return startY - item.y;
            }

            const stepsUpCount = moveUpStepsCount(gridRenderCopy, overlappingItem, startY)
            const stepsLeftCount = moveLeftStepsCount(gridRenderCopy, overlappingItem, startX)
            const stepsRightCount = moveRightStepsCount(gridRenderCopy, overlappingItem, startX + item.width)
            const stepsDownCount = moveDownStepsCount(gridRenderCopy, overlappingItem, startY + item.height)

            if (stepsUpCount > 0) {
                updateItem(overlappingItem.id, { ...overlappingItem, y: overlappingItem.y - stepsUpCount })
            } else if (stepsLeftCount > 0) {
                updateItem(overlappingItem.id, { ...overlappingItem, x: overlappingItem.x - stepsLeftCount })
            } else if (stepsRightCount > 0) {
                updateItem(overlappingItem.id, { ...overlappingItem, x: overlappingItem.x + stepsRightCount })
            } else if (stepsDownCount > 0) {
                updateItem(overlappingItem.id, { ...overlappingItem, y: overlappingItem.y + stepsDownCount })
            } else if (stepsUpCount < 0) {
                itemsToReset = pushAsideItems(gridRenderCopy, tempItems, overlappingItem, overlappingItem.x, overlappingItem.y + stepsUpCount, --iteration)
                updateItem(overlappingItem.id, { ...overlappingItem, y: overlappingItem.y + stepsUpCount })
            } else if (stepsLeftCount < 0) {
                itemsToReset = pushAsideItems(gridRenderCopy, tempItems, overlappingItem, overlappingItem.x + stepsLeftCount, overlappingItem.y, --iteration)
                updateItem(overlappingItem.id, { ...overlappingItem, x: overlappingItem.x + stepsLeftCount })
            } else if (stepsRightCount < 0) {
                itemsToReset = pushAsideItems(gridRenderCopy, tempItems, overlappingItem, overlappingItem.x - stepsRightCount, overlappingItem.y, --iteration)
                updateItem(overlappingItem.id, { ...overlappingItem, x: overlappingItem.x - stepsRightCount })
            } else if (stepsDownCount < 0) {
                itemsToReset = pushAsideItems(gridRenderCopy, tempItems, overlappingItem, overlappingItem.x, overlappingItem.y - stepsDownCount, --iteration)
                updateItem(overlappingItem.id, { ...overlappingItem, y: overlappingItem.y - stepsDownCount })
            }
            itemsToReset.sort((a, b) => {
                if (a.id === overlappingItem.id) {
                    return -1
                }
                if (b.id === overlappingItem.id) {
                    return 1
                }
                return 0
            })
            renderGrid(gridRenderCopy, itemsToReset)
            const canMoveOverlapped = !getOverlappingId(gridRenderCopy, itemsToReset.find(x => x.id === overlappingItem.id)).length;

            if (!canMoveOverlapped) {
                canMove = false;
            }
        }
        return itemsToReset.map(x => ({ ...x }));
    }

    currentItems = pushAsideItems(gridRender, initialItems.map(x => ({ ...x })).slice(0), { ...item, x: startX, y: startY }, startX, startY);
    currentItems.sort((a, b) => {
        if (a.id === item.id) {
            return -1
        }
        if (b.id === item.id) {
            return 1
        }
        return 0
    })
    const tmp = gridRender.map(x => [...x]);
    renderGrid(tmp, currentItems)
    if (canMove && !getOverlappingId(tmp, { ...item, x: startX, y: startY }).length) {
        draggingElement.classList.remove('invalid');
    } else {
        draggingElement.classList.add('invalid');
    }
    const currentItem = currentItems.find(x => x.id === item.id);
    currentItem.x = startX;
    currentItem.y = startY;

    $items.set(currentItems);
}

function renderGrid(grid, items) {
    for (let row = 0; row < gridRowsCount; ++row) {
        for (let column = 0; column < gridColumnsCount; ++column) {
            grid[row][column] = 0;
        }
    }
    for (const item of items) {
        for (let y = item.y; y < item.y + item.height; ++y) {
            for (let x = item.x; x < item.x + item.width; ++x) {
                grid[y - 1][x - 1] = item.id;
            }
        }
    }
}
