// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { forwardRef } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "../CustomIcons";
import styled from "@emotion/styled";
import { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import { blue, grey } from "../../Utils/Constants";

const styledButtonStyles = css`
    display: flex;

    & > * {
        margin: auto 0;
    }

    font: normal normal normal 16px/24px Roboto;
    color: #858585;
    letter-spacing: 0.14px;

    box-sizing: border-box;
    min-height: calc(1.5em + 16px);
    min-width: 320px;
    padding: 12px;
    border-radius: 8px;
    text-align: left;
    background: ${'#fff'};

    display: inline-flex;
    width: 100%;
    & .selected-item {
        width: calc(100% - 20px);
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;

    &:hover {
        background: ${grey[50]};
    }

    &.${selectUnstyledClasses.focusVisible} {
        border-color: ${blue[400]};
        outline: 1px solid ${blue[200]};
    }

    &.${selectUnstyledClasses.expanded} {
        border-radius: 8px 8px 0 0;
    }

    &.${selectUnstyledClasses.disabled} {
        color: #858585;
        background-color: #8585851a;
    }

    & svg {
        margin-left: auto;
        margin-right: 0;
    }
`;

export const StyledButton = forwardRef(({ children, ownerState, ...props }, ref) => {
    const isExpanded = props["aria-expanded"];
    return (
        <button ref={ref} css={styledButtonStyles} {...props}>
            <div className="selected-item">
                {children}
            </div>
            {isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </button>
    )
});

export const StyledListbox = styled('ul')( // forwardRef((props, ref) => <ul ref={ref} {...props}  onBlur={() => {}} />))(
    ({ theme }) => `
        position: relative;

        font: normal normal normal 16px/24px Roboto;
        color: #858585;
        letter-spacing: 0.14px;

        padding: 0 8px 0 0;
        margin: 0;
        max-height: 300px;
        overflow: auto;
        outline: 0px;
    `,
);
