// ts-check
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { usePrintEffect } from "../CoreComponents/Utils";
import {
    Chart as ChartJS,
    RadialLinearScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Filler,
    Tooltip,
    Legend,
    LineController,
    BarController,
    ScatterController,
} from 'chart.js';

export const ApiHost = process.env.REACT_APP_API_HOST;

export const SentrySettings = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || 0,
    isEnabled: !!process.env.REACT_APP_ENABLE_SENTRY,
};

export const Auth0Settings = {
    Domain: process.env.REACT_APP_DOMAIN,
    ApiAudience: process.env.REACT_APP_API_AUDIENCE,
    ClientId: process.env.REACT_APP_CLIENT_ID,
    Scope: process.env.REACT_APP_SCOPE,
};

export const LogoutSettings = {
    AutoLogoutTimeMinutes: process.env.REACT_AUTO_LOGOUT_TIME_MINUTES || 30,
};

export const NoData = '--';

export const SortOrder = {
    Asc: 'asc',
    Desc: 'desc'
};
export const DefaultSortingOrder = SortOrder.Asc;

export const TestStatuses = {
    Empty: 0,
    Complete: 1,
    Partial: 2,
    Deleted: 3
};

export const TestStatusesTranslationKeysObj = {
    [TestStatuses.Empty]: 'constants.testStatuses.empty',
    [TestStatuses.Complete]: 'constants.testStatuses.complete',
    [TestStatuses.Partial]: 'constants.testStatuses.partial',
    [TestStatuses.Deleted]: 'constants.testStatuses.deleted',
};

export const TestStatusesTranslationKeysArr =
    Object.values(TestStatuses)
        .map(value => ({ value, translationKey: TestStatusesTranslationKeysObj[value] }));

export const AdminTypes = {
    Operator: 6,
    TRAZERAdmin: 5,
    TRAZERView: 4,
    SuperAdmin: 3,
    SiteAdmin: 2,
    Provider: 1,
    User: 0
};

export const AdminTypesTranslationKeysObj = {
    [AdminTypes.Operator]: 'constants.adminType.Operator',
    [AdminTypes.TRAZERAdmin]: 'constants.adminType.TRAZERAdmin',
    [AdminTypes.TRAZERView]: 'constants.adminType.TRAZERView',
    [AdminTypes.SuperAdmin]: 'constants.adminType.SuperAdmin',
    [AdminTypes.SiteAdmin]: 'constants.adminType.SiteAdmin',
    [AdminTypes.Provider]: 'constants.adminType.Provider',
    [AdminTypes.User]: 'constants.adminType.User',
};

export const AdminTypesTranslationKeysArr =
    Object.values(AdminTypes)
        .map(value => ({ value, translationKey: AdminTypesTranslationKeysObj[value] }));

export const Genders = {
    Male: 1,
    Female: 2,
    NonBinary: 3
};

export const GendersTranslationKeysObj = {
    [Genders.Male]: 'constants.gender.male',
    [Genders.Female]: 'constants.gender.female',
    [Genders.NonBinary]: 'constants.gender.nonBinary'
};

export const GendersTranslationKeysArr =
    Object.values(Genders)
        .map(value => ({ value, translationKey: GendersTranslationKeysObj[value] }));

export const Dominance = {
    Unknown: 0,
    Left: 1,
    Right: 2,
    Ambidextrous: 3,
};

export const DominanceTranslationKeysObj = {
    [Dominance.Unknown]: 'constants.dominance.unknown',
    [Dominance.Left]: 'constants.dominance.left',
    [Dominance.Right]: 'constants.dominance.right',
    [Dominance.Ambidextrous]: 'constants.dominance.ambidextrous',
};

export const DominanceTranslationKeysArr =
    Object.values(Dominance)
        .map(value => ({ value, translationKey: DominanceTranslationKeysObj[value] }));

export const AvailableLanguages = {
    EN: "EN",
    ES: "ES",
    DE: "DE",
};

export const AvailableLanguagesArray = Object.entries(AvailableLanguages);

export const ExportTypes = {
    CSV: 1,
    Excel: 2,
};

export const ExportTypesTranslationKeysObj = {
    [ExportTypes.CSV]: 'actions.exportCsv',
    [ExportTypes.Excel]: 'actions.exportExcel',
};

export const ExportTypesTranslationKeysArr =
    Object.values(ExportTypes)
        .map(value => ({ value, translationKey: ExportTypesTranslationKeysObj[value] }));

export const Sides = {
    Left: 'L',
    Right: 'R',
    None: 'NA',
};

export const SidesTranslationKeysObj = {
    [Sides.Left]: 'constants.side.left',
    [Sides.Right]: 'constants.side.right',
    [Sides.None]: 'constants.side.none',
};

export const SidesTranslationKeysArr =
    Object.values(Sides)
        .map(value => ({ value, translationKey: SidesTranslationKeysObj[value] }));

export const UnitType = {
    Feet: 0,
    Meters: 1,
};

export const UnitTypeTranslationKeysObj = {
    [UnitType.Meters]: 'constants.unitType.meters',
    [UnitType.Feet]: 'constants.unitType.feet',
};

export const UnitTypeTranslationKeysArr =
    Object.values(UnitType)
        .map(value => ({ value, translationKey: UnitTypeTranslationKeysObj[value] }));

export const ActivityTypes = {
    Mobility: 1,
    Cognitive: 2,
    Balance: 3,
    Kinematics: 4,
    Games: 5,
    UpperExtremity: 6,
};

export const BaseActivities = {
    QuadJump: 1,
    Jump180: 2,
    React: 3,
    Box: 4,
    Bounding: 5,
    Cutting: 6,
    GetBack: 7,
    Jump: 8,
    JumpAndRecover: 9,
    Lunge: 10,
    ShiftAndBump: 11,
    Shuffle: 12,
    MiniT: 13,
    Lateral: 14,
    Linear: 15,
    DropStep: 16,
    GPA: 17,
    BilateralBalance: 19,
    TandemBalance: 20,
    UnilateralBalance: 21,
    Flanker: 28,
    Squat: 29,
    SingleLegSquat: 30,
    VerticalDropJump: 31,
    Stroop: 32,
    MathChallenge: 33,
    ColorConflict: 34,
    SpikeDodge: 35,
    TrapAttack: 36,
    MemoryMatch: 37,
    JumpExplosion: 38,
    GoalieWars: 39,
    QB1: 40,
    Reach: 41,
    CognitiveReach: 42,
    Flanker2: 43,
    MathAddition: 44,
    MathSubtraction: 45,
    MathMultiplication: 46,
    BalanceGame: 47,
    FlankerReach: 48,
    Flanker2Reach: 49,
    StroopReach: 50,
    ColorChallengeReach: 51,
    MathAddSubtractReach: 52,
    MathAdditionReach: 53,
    MathSubtractionReach: 54,
    MathMultiplicationReach: 55,
    ROMShoulderInternalExternalRotation: 56,
    ROMShoulderHorizontalAdductionAbduction: 57,
    ROMShoulderLinearFlexionExtension: 58,
    ROMShoulderLateralAdductionAbduction: 59,
    ROMElbowFlexionExtension: 60,
    ROMFlexionExtension: 61,
    ROMLateralFlexion: 62,
    ROMRotation: 63,
    KinematicJump: 64,
    ChairStand30: 65,
    TUG: 66,
    KinematicSingleLegJump: 67,
};

export const ActivityTypesColors = {
    [ActivityTypes.Mobility]: '#5FE37D',
    [ActivityTypes.Cognitive]: '#C9FA75',
    [ActivityTypes.Balance]: '#FDBF6A',
    [ActivityTypes.Kinematics]: '#F06A6A',
    [ActivityTypes.Games]: '#F9AAEF',
    [ActivityTypes.UpperExtremity]: '#B36BD4',
};

export const ActivityTypesTranslationKeysObj = {
    [ActivityTypes.Mobility]: 'constants.activityTypes.mobility',
    [ActivityTypes.Cognitive]: 'constants.activityTypes.cognitive',
    [ActivityTypes.Balance]: 'constants.activityTypes.balance',
    [ActivityTypes.Kinematics]: 'constants.activityTypes.kinematics',
    [ActivityTypes.Games]: 'constants.activityTypes.games',
    [ActivityTypes.UpperExtremity]: 'constants.activityTypes.upperExtremity',
};

export const ActivityTypesTranslationKeysArr =
    Object.values(ActivityTypes)
        .map(value => ({ value, translationKey: ActivityTypesTranslationKeysObj[value] }));

export const AlertType = {
    Info: 0,
    Warning: 1,
    Success: 2,
    Error: 3
};

export const AlertTypeTranslationKeysObj = {
    [AlertType.Info]: 'constants.alertType.info',
    [AlertType.Warning]: 'constants.alertType.warning',
    [AlertType.Success]: 'constants.alertType.success',
    [AlertType.Error]: 'constants.alertType.error',
};

export const SystemModes = {
    Provider: 0,
    UserLite: 1
};

export const SystemModesTranslationKeysObj = {
    [SystemModes.Provider]: 'constants.systemModes.provider',
    [SystemModes.UserLite]: 'constants.systemModes.userLite'
};

export const SystemModesTranslationKeysArr =
    Object.values(SystemModes)
        .map(value => ({ value, translationKey: SystemModesTranslationKeysObj[value] }));

export const UserLiteTimes = {
    Option1: 0,
    Option2: 15,
    Option3: 30,
    Option4: 45,
    Option5: 60
};

export const UserLiteTimesTranslationKeysObj = {
    [UserLiteTimes.Option1]: 'constants.userLiteTimes.option1',
    [UserLiteTimes.Option2]: 'constants.userLiteTimes.option2',
    [UserLiteTimes.Option3]: 'constants.userLiteTimes.option3',
    [UserLiteTimes.Option4]: 'constants.userLiteTimes.option4',
    [UserLiteTimes.Option5]: 'constants.userLiteTimes.option5'
};

export const UserLiteTimesTranslationKeysArr =
    Object.values(UserLiteTimes)
        .map(value => ({ value, translationKey: UserLiteTimesTranslationKeysObj[value] }));

// WARN: Don't change that!
export const FrozenEmptyObject = Object.freeze({});

export const TrazerOrientationActivityId = 188;

export const TableOptionsTypes = {
    Customers: 'customers',
    Sites: 'sites',
    Users: 'users',
    Tests: 'tests',
}

// could be useful
// if we wanna improve readability
// https://betweentwobrackets.com/data-graphics-and-colour-vision/
export const baseColors = [
    '0, 171, 165',
    '253, 191, 106',
    '95, 227, 125',
    '240, 106, 106',
    '249, 170, 239',
    '179, 107, 212',
]

export const ChartColors =
    baseColors.map(c => ({
        color: c,
        backgroundColor: `rgba(${c}, 0.2)`,
        borderColor: `rgba(${c}, 1)`,
        pointBackgroundColor: `rgba(${c}, 1)`,
        pointHoverBackgroundColor: 'rgba(255, 255, 255, 1)',
    }));
// const DisabledColor = '203, 203, 203';
export const SharedPluginsConfig = {
    legend: {
        labels: {
            // pointStyle: 'circle',
            usePointStyle: true,
            // workaround because the pointStyle circle adds white background indicator
            borderRadius: 6,
            boxHeight: 10,
            boxWidth: 10,
            useBorderRadius: true,
        },
        position: 'bottom'
    },
    tooltip: {
        boxHeight: 12,
        boxWidth: 12,
        boxPadding: 8,
        pointStyle: 'circle',
        usePointStyle: true,
        fractionDigits: 2,
        callbacks: {
            label: function (context) {
                const fractionDigits = context.chart.config.options.plugins.tooltip.fractionDigits ?? 2;
                const label = context.dataset.label || '';
                const value = context.raw !== null ?
                    Number(context.raw).toFixed(fractionDigits)
                    : context.formattedValue;
                return `${label}: ${value}`;
            },
        },
    }
}

export const chartStyles = {
    responsiveContainer: css`
        margin: auto;
        position: relative;
        height: 100%;
        // min-height:100%;
        width: 100%;

        & .options {
            position: absolute;
            top: 0;
            right: 0;
        }
    `,
    optionsButton: css`
        height: 1.5em;
        width: 1.5em;
    `,
    fullScreenContainer: css`
        display: flex;
        padding: 160px 40px 160px 80px;
        width: 100%;
        height: 100%;
        background: #EEEEEE 0% 0% no-repeat padding-box;

        @media print {
            padding: 80px 0px 80px 40px;
        }
    `,
};

// in milliseconds used to debounce the resize and
// hopefully improve the performance
export const ChartResizeDelay = 0;
export const DefaultAnimationDuration = 0;
// const ChartResizeDelay = 50;
// const DefaultAnimationDuration = 1000;
export const ChartPadding = 40;

// TODO: refactor this so we won't have to track isPrinting prop
export const useChartPrintEffect = (chart, chartSize) => {
    usePrintEffect(
        () => {
            chart.resize();
            chart.update('none');
        },
        () => {
            chart.resize();
            chart.update('none');
        },
        () => {
            // console.log({ chartSize });
            return chart
                && chart.canvas
                && chartSize;
        },
        [chart, chartSize]
    );
};

export const ChartExportTypes = {
    PNG: 1,
    JPEG: 2,
    CSV: 3,
    Excel: 4,
};

export const ChartExportTypesTranslationKeysObj = {
    [ChartExportTypes.PNG]: 'chart.options.downloadPngImage',
    [ChartExportTypes.JPEG]: 'chart.options.downloadJpgImage',
    [ChartExportTypes.CSV]: 'chart.options.downloadCsv',
    [ChartExportTypes.Excel]: 'chart.options.downloadXls',
};

ChartJS.register(
    RadialLinearScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Filler,
    Tooltip,
    Legend,
    LineController,
    BarController,
    ScatterController,
);

export const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

export const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

export const AdvancedSearchDefaultValue = '';

export const AdvancedSearchType = {
    Text: 0,
    Select: 1,
    MultiSelect: 2,
}

export const AdvancedSearchMatchType = {
    Exact: 0,
    Contains: 1,
    // Includes: 2,
}

export const ReportSections = {
    Details: 1,
    Timeline: 2,
    History: 3,
};

export const ReportSectionsTranslationKeysObj = {
    [ReportSections.Details]: 'testReport.section.details',
    [ReportSections.Timeline]: 'testReport.section.timeline',
    [ReportSections.History]: 'testReport.section.history',
};

export const ReportSectionsTranslationKeysArr =
    Object.values(ReportSections)
        .map(value => ({ value, translationKey: ReportSectionsTranslationKeysObj[value] }));

export const reduxDevToolsHistoryLimit = 30;
