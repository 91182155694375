/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { UserService } from '../../../Services/UserService';
import { CustomerService } from '../../../Services/CustomerService';
import { SiteService } from '../../../Services/SiteService';
import { AdminService } from '../../../Services/AdminService';
import { UnitService } from '../../../Services/UnitService';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    emailValidator,
    maxLengthValidator,
    requiredValidator,
    minLengthValidator,
    combineValidators,
} from '../../../Utils/Validators';
import { RadioGroup, RadioOption } from '../../../CoreComponents/RadioSelection';
import { AdminTypes, AdminTypesTranslationKeysArr } from '../../../Utils/Constants';
import { useTranslation } from '../../../CoreComponents/Translation';
import { AlertText } from '../../../CoreComponents/Alert';
import { CircularLoader } from '../../../CoreComponents/Loaders';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';
import { MultiSelect, MultiSelectOption } from '../../../CoreComponents/SelectListComponents/MultiSelect';

const adminSettingsTabStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        height: 85vh;
        width: unset;

        @media (min-width: 1200px) {
            width: 900px;
        }
        & > .fields-container{
            overflow-y: auto;

            & > .row {
                display: flex;
                flex-wrap: wrap;
                width: calc(100% - 40px);
                margin-bottom: 20px;
                margin-left: 20px;
                & > * {
                    margin: 8px 20px;
                }
            }

            & .inputs > .input-root {
                margin: 8px 20px;

                width: calc(100% - 40px);
                @media (min-width: 1300px) {
                    width: calc(50% - 40px);
                }
            }
        }

        & > h2 {
            width: calc(100% - 40px);
            margin: 40px auto 40px 40px;
        }

        & > .actions {
            display: inline-flex;
            width: 100%;
            margin-top: auto;
            margin-bottom: 40px;

            & > button {
                margin-left: auto;
            }

            & > .alert-text-root {
                margin-left: 40px;
                align-self: center;
            }
        }
    `,
    loadContainer: css`
        width: 100%;
        height: 100%;
        display: flex;
    `,
};

export const AdminSettingsTab = ({ $selectedUser }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $units = useHook([]);
    const $isForceValidating = useHook(false);
    const $errorMessage = useHook('');
    const $isSaving = useHook(false);
    const $availableRoles = useHook([]);
    const $isLoading = useHook(true);

    const $newAdmin = useObjectHook(
        {
            adminId: $selectedUser.value.userId,
            adminTypeId: $selectedUser.value.admin?.adminTypeId || 0,
            customerId: $selectedUser.value.admin?.customerId || storeState.currentUser.admin.customerId || '',
            email: $selectedUser.value.email || '',
            enableUserLogin: $selectedUser.value.auth0_sub && $selectedUser.value.admin?.adminTypeId === 0 ? 'On' : 'Off',
            adminSites: [],
        },
        {
            adminTypeId: [requiredValidator],
            customerId: [requiredValidator]
        },
        (oldState, newState) => {
            const validationResult = minLengthValidator(1)(newState.adminSites);
            if ((newState.adminTypeId === AdminTypes.Operator || newState.adminTypeId === AdminTypes.Provider || newState.adminTypeId === AdminTypes.SiteAdmin || (newState.adminTypeId === AdminTypes.User && newState.enableUserLogin === 'On')) && validationResult.length) {
                return { adminSites: validationResult };
            }
            const emailValidationResult = combineValidators([maxLengthValidator(50), emailValidator, requiredValidator])(newState.email);
            if ((newState.adminTypeId !== AdminTypes.User || (newState.adminTypeId === AdminTypes.User && newState.enableUserLogin === 'On')) && emailValidationResult.length) {
                return { email: emailValidationResult }
            }

        },
        $isForceValidating.value
    );

    useEffect(() => {
        CustomerService.getAll(9999, 'Name', true)
            .then($customers.set);

        UserService.getUser($selectedUser.value.userId)
            .then(user => {
                $newAdmin.getPropHook('adminSites').set(
                    user.admin?.adminTypeId === AdminTypes.User || user.admin?.adminTypeId === AdminTypes.Operator || user.admin?.adminTypeId === AdminTypes.Provider || user.admin?.adminTypeId === AdminTypes.SiteAdmin ?
                        user.admin?.adminSites.map(s => s.siteId)
                        : []
                )
            });

        $availableRoles.set(
            AdminTypesTranslationKeysArr
                .filter(r => r.value <= storeState.currentUser.admin.adminTypeId || Number(r.value) === AdminTypes.Operator)
        )
    }, []);

    useEffect(() => {
        if (!$selectedUser.value.isActive) {
            return;
        }

        $errorMessage.set('');
    }, [$newAdmin.value]);

    useEffect(() => {
        if (!$newAdmin.value.customerId) {
            return;
        }
        SiteService.getAll(9999, $newAdmin.value.customerId, null, 'Name', true)
            .then(sites => {
                $sites.set(sites);
                $isLoading.set(false);
            });
    }, [$newAdmin.value.customerId]);

    useEffect(() => {
        if (!$newAdmin.value.customerId || !$selectedUser.value.userSites.length) {
            return;
        }
        UnitService.getAll(9999, $newAdmin.value.customerId, $selectedUser.value.userSites.map(s => s.siteId))
            .then(units => $units.set(units));
    }, [$newAdmin.value.customerId, $newAdmin.value.adminSites, $selectedUser.value])

    const updateAdmin = () => {
        if (!$selectedUser.value.isActive) {
            $errorMessage.set(t('validatorErrors.userInactive'));
            return;
        }

        $isForceValidating.set(true);

        const errors = $newAdmin.validate();
        if (errors) {
            $errorMessage.set(t('validatorErrors.emptyFormWarning'));
            return;
        }

        const admin = {
            adminId: $selectedUser.value.userId,
            adminTypeId: $newAdmin.value.adminTypeId,
            customerId: $newAdmin.value.customerId,
            email: $newAdmin.value.email,
            adminSites: $newAdmin.value.adminSites.map(siteId => ({ siteId, adminId: $selectedUser.value.userId })),
        };

        if (!$selectedUser.value.admin) {
            if (admin.adminTypeId !== AdminTypes.User || (admin.adminTypeId === AdminTypes.User && $newAdmin.value.enableUserLogin === 'On')) {
                $isSaving.set(true);
                AdminService.postAdmin(admin)
                    .then(admin => $selectedUser.set({
                        ...$selectedUser.value,
                        admin: admin,
                        role: admin.adminTypeId
                    }))
                    .finally(() => $isSaving.set(false));
            }
            return;
        }

        if (admin.adminTypeId !== AdminTypes.User || (admin.adminTypeId === AdminTypes.User && $newAdmin.value.enableUserLogin === 'On')) {
            $isSaving.set(true);
            AdminService.putAdmin(admin)
                .then(admin => $selectedUser.set({
                    ...$selectedUser.value,
                    admin: admin,
                    role: admin.adminTypeId
                }))
                .finally(() => $isSaving.set(false));
        } else {
            $isSaving.set(true);
            AdminService.deleteAdmin(admin.adminId)
                .then(() => $selectedUser.set({
                    ...$selectedUser.value,
                    admin: null,
                    role: 0
                }))
                .finally(() => $isSaving.set(false));
        }

    };
    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={adminSettingsTabStyles.root}>
                <h2>{t('users.editModal.title')}</h2>
                {$isLoading.value ?
                    <div css={adminSettingsTabStyles.loadContainer}>
                        <CircularLoader />
                    </div>
                    :
                    <div className="fields-container">
                        <div className="row inputs">
                            <Input label={t('users.fieldName.username')} value={$selectedUser.value.username} disabled />
                            {storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin && ($newAdmin.value.adminTypeId !== AdminTypes.User || ($newAdmin.value.adminTypeId === AdminTypes.User && $newAdmin.value.enableUserLogin === 'On')) ?
                                <SingleSelect label={t('users.fieldName.customer')} required $value={$newAdmin.getPropHook('customerId')}>
                                    {$customers.value.map(c => (
                                        <SelectOption key={c.customerId} value={c.customerId}>
                                            {c.name}
                                        </SelectOption>
                                    ))}
                                </SingleSelect>
                                : null
                            }
                            {!$selectedUser.value.email && ($newAdmin.value.adminTypeId !== AdminTypes.User || ($newAdmin.value.adminTypeId === AdminTypes.User && $newAdmin.value.enableUserLogin === 'On')) ?
                                <Input label={t('users.fieldName.email')} required $value={$newAdmin.getPropHook('email')} />
                                : null
                            }
                            {$newAdmin.value.adminTypeId === AdminTypes.Operator || $newAdmin.value.adminTypeId === AdminTypes.Provider || $newAdmin.value.adminTypeId === AdminTypes.SiteAdmin || ($newAdmin.value.adminTypeId === AdminTypes.User && $newAdmin.value.enableUserLogin === 'On') ?
                                <MultiSelect disabled={!$newAdmin.value.customerId} required $value={$newAdmin.getPropHook('adminSites')} label={t('users.fieldName.site')}>
                                    {$sites.value.map(s => <MultiSelectOption key={s.siteId} value={s.siteId}>{s.name}</MultiSelectOption>)}
                                </MultiSelect>
                                : null
                            }
                        </div>
                        <div className="row">
                            <RadioGroup $value={$newAdmin.getPropHook('adminTypeId')} required label={t('users.fieldName.adminRole')}>
                                {$availableRoles.value.map(r => (
                                    <RadioOption key={r.value} value={r.value} label={t(r.translationKey)} />
                                ))}
                            </RadioGroup>
                        </div>
                        {$newAdmin.value.adminTypeId === AdminTypes.User && $units && $units.value.filter(u => u.adminGlobalSetting.unitModel === 1).length > 0 &&
                            <div className='row'>
                                <RadioGroup $value={$newAdmin.getPropHook('enableUserLogin')} label={t('users.fieldName.enableUserLogin')}>
                                    <RadioOption key='On' value='On' label={t('users.fieldName.enableUserLogin.onOption')} />
                                    <RadioOption key='Off' value='Off' label={t('users.fieldName.enableUserLogin.offOption')} />
                                </RadioGroup>
                            </div>
                        }
                    </div>}
                <div className="actions">
                    {$errorMessage.value &&
                        <AlertText variant="large">{$errorMessage.value}</AlertText>
                    }
                    <Button disabled={$errorMessage.value || $isSaving.value} onClick={updateAdmin}>Update</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
