// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';
import SelectUnstyled from '@mui/base/SelectUnstyled';
import { useState } from 'react';
import { getLabelFromChildren, useForceValidateContext, useHook } from '../Utils';
import { useEffect } from 'react';
import { forwardRef } from 'react';
import { AlertText } from '../Alert';
import { FrozenEmptyObject } from '../../Utils/Constants';
import { useRef } from 'react';
import { StyledPopper } from './MultiSelect';
import { StyledButton, StyledListbox } from './StyledSelectList';

export const CustomSelect = React.forwardRef(function ({ children, disableFiltering, ...props }, ref) {
    const $searchText = useHook('');
    const searchText = $searchText.value.toLowerCase();
    const availableItemsRef = React.useRef([]);
    const filteredChildren = React.useMemo(() => {
        availableItemsRef.current = [];
        return React.Children.map(children, function (child, i) {
            if (!child || (!React.isValidElement(child) && typeof(child) !== 'string')) {
                return child;
            }

            let asString = ''
            if (typeof(child.props.children) === 'string') {
                asString = child.props.children;
            } else if (child.props.children) {
                asString = getLabelFromChildren(child.props.children);
            }

            const shouldInclude = asString.toLowerCase().includes(searchText);
            if (shouldInclude) {
                availableItemsRef.current.push(child.props.value);
            }

            return React.cloneElement(child, {
                className: shouldInclude ?
                    child.props.className
                    // WARN: Safari needs a container with 'position: relative' for the .visually-hidden class
                    : (child.props.className ?? '') + ' visually-hidden'
            });
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$searchText.value, children]);

    const popper = React.useMemo(() => forwardRef((refProps, ref) => (
        <StyledPopper
            ref={ref}
            {...refProps}
            availableItemsRef={availableItemsRef}
            searchValue={$searchText.value}
            onFilter={!disableFiltering && $searchText.set}
        />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    )), [disableFiltering]);

    const slots = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: popper,
        ...props.slots,
    };

    return (
        <SelectUnstyled {...props} ref={ref} slots={slots}>
            {filteredChildren}
        </SelectUnstyled>
    );
});

export const styles = {
    root: css`
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        color: theme.palette.text.primary;
        margin: 8px 20px 21px 20px;
        flex-wrap: wrap;

        & button {
            padding: 11px 16px 10px 20px;
            line-height: 19px;
            height: auto;
        }
    `,
    slimRoot: css`
        display: flex;
        flex-direction: row;
        margin: 0 20px 0 0;

        & .selector-wrapper {
            width: unset;
        }

        & button {
            min-height: unset;
            min-width: 140px;
            width: 100%;
        }
    `,
    label: css`
        margin: 0 auto 8px 0;
        letter-spacing: 0.14px;
        color: #858585;
        font: normal normal bold 16px Mustica Pro;
        margin-bottom: 18px;
    `,
    slimLabel: css`
        margin: auto 8px auto 0;
    `,
    input: css`
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 8px;
        padding: 10px 20px;
        height: 40px;
    `,
    error: css`
        border: 2px solid #F42A82;
    `,
    selector: css`
        position: relative;
        width: 100%;
    `,
    outlined: css`
        border-radius: 8px;
        background-color: #FFFFFF;

        & .MuiSelect-root {
            border: 1px solid #707070;
            &[aria-expanded="true"] {
                border-bottom: none;
            }
        }

        & .MuiSelect-popper {
            border: 1px solid #707070;
            border-top: none;
            border-radius: 0 0 8px 8px;
            box-shadow: none;
        }
    `,
};

export const SingleSelect = (props) => {
    const forceValidateContext = useForceValidateContext();
    const {
        id,
        label,
        $value,
        validator,
        children,
        design,
        value,
        onChange,
        disabled,
        disableFiltering = false,
        variant = "default",
        className,
        required,
        ...other
    } = props;
    const [validationErr, setValidationErr] = useState([]);
    const lastCheckedValue = useRef(FrozenEmptyObject);

    useEffect(() => {
        if (!forceValidateContext
            && lastCheckedValue.current === FrozenEmptyObject
        ) {
            return;
        }

        if (validator) {
            const validatedValue = value || $value?.value;
            const validationResult = validator(validatedValue);
            // console.log('useEffect validation', {label, validatedValue, validationResult})
            setValidationErr(validationResult || []);
        } else if ($value?.validate) {
            setValidationErr($value.validate() || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceValidateContext, value, validator, $value?.isValid, $value?.value, $value?.validate]);

    const onChangeInternal = (e, newValue) => {
        if (e == null && newValue == null) {
            return;
        }

        const value = newValue; // e.target.value;
        lastCheckedValue.current = value;
        $value && $value.set(value);
        onChange && onChange(value);
    }

    return (
        <div css={[styles.root, design === 'slim' && styles.slimRoot]} className={'input-root ' + (className || '')} {...other}>
            {label &&
                <label htmlFor={id} css={[styles.label, design === 'slim' && styles.slimLabel]}>
                    {label} {required && <span className="required">*</span>}
                </label>
            }
            <div className="selector-wrapper" css={[styles.selector, variant === 'outlined' && styles.outlined]}>
                <CustomSelect
                    id={id}
                    value={$value ? $value.value : value}
                    onChange={onChangeInternal}
                    css={[styles.input, validationErr.length ? styles.error : null]}
                    disabled={disabled}
                    disableFiltering={disableFiltering}
                >
                    {children}
                </CustomSelect>
            </div>
            {validationErr.map(x => <AlertText key={x}>{x}</AlertText>)}
        </div>
    );
};
