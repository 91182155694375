// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { css } from '@emotion/react';
import { useTranslation } from '../CoreComponents/Translation';
import { ForceValidateContext, useHook } from '../CoreComponents/Utils';
import { PINValidator, combineValidators, requiredValidator } from '../Utils/Validators';
import { UserService } from '../Services/UserService';
import { Input } from '../CoreComponents/Input';
import { Button } from '../CoreComponents/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from '../globalStates/storeState';

const mandatoryAdminPinSetupStyles = {
    root: css`
        width: unset;
        // height: 85vh;
        height: 420px;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > p {
            font: normal normal normal 16px/24px Roboto;
            letter-spacing: 0.14px;
            color: #858585;
        }

        & > .input-root {
            margin: 0 0 30px 0;
            width: 100%;
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;

            & > button:first-of-type {
                margin-right: 20px;
                margin-left: auto;
            }
        }
    `,
};

export const MandatoryAdminPinSetup = () => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const dispatch = useDispatch();

    const $isForceValidating = useHook(false);
    const $isSaving = useHook(false);
    const $pin = useHook('', combineValidators([requiredValidator, PINValidator]));

    const updateCurrentUser = async () => {
        $isSaving.set(true);

        try {
            await UserService.patchUser({
                ...storeState.currentUser,
                dateOfBirth: new Date(storeState.currentUser.dateOfBirth),
                pin: $pin.value,
            });

            const currentUser = await UserService.getMe();
            dispatch(setCurrentUser(currentUser));
        } catch (e) {
            console.log(e.message);
        }

        $isSaving.set(false);
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={mandatoryAdminPinSetupStyles.root}>
                <h2>{t('setPin.modal.title')}</h2>
                <p>{t('setPin.modal.description')}</p>
                <Input
                    label={t('users.fieldName.pin')}
                    required
                    placeholder={t('input.placeholder')}
                    $value={$pin}
                />
                <div className="actions">
                    <Button
                        disabled={$isSaving.value || !$pin.isValid}
                        onClick={updateCurrentUser}
                    >
                        {t('actions.save')}
                    </Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    );
}
