/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { subYears } from 'date-fns';
import { useEffect } from 'react';
import { Button } from '../../CoreComponents/Button';
import { Checkbox } from '../../CoreComponents/Checkbox';
import { DateRangeSelector } from '../../CoreComponents/DateRangeSelector';
import { Input } from '../../CoreComponents/Input';
import { CircularLoader } from '../../CoreComponents/Loaders';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook, useObjectHook } from '../../CoreComponents/Utils';
import { CustomerService } from '../../Services/CustomerService';
import { GroupService } from '../../Services/GroupService';
import { ScriptedActivitiesService } from '../../Services/ScriptedActivitiesService';
import { SiteService } from '../../Services/SiteService';
import { SportService } from '../../Services/SportService';
import { InjuryService } from '../../Services/InjuryService';
import { TestService } from '../../Services/TestService';
import { shortDate } from '../../Utils/Common';
import { AdminTypes, DominanceTranslationKeysArr, GendersTranslationKeysArr } from '../../Utils/Constants';
import { SearchResultLayout } from './SearchResultLayout';
import { useSelector } from 'react-redux';
import { MultiSelect, MultiSelectOption } from '../../CoreComponents/SelectListComponents/MultiSelect';

const analyticsStyles = {
    root: css`
        width: 100%;
        height: 100%;
        display: flex;
        padding: 20px;

        & .filters {
            height: 100%;
            width: 400px;
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            & .filter-list {
                margin: 20px 0 40px 0;
                margin-right: 20px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                height: 100%;
                overflow-y: auto;

                & > label, & > section {
                    margin: 0 20px;
                    & > button: {
                        min-width: auto;
                    }
                }

                & .range-input {
                    display: flex;
                    flex-direction: column;

                    & > span {
                        margin: 0 auto 18px 0;
                        letter-spacing: 0.14px;
                        color: #858585;
                        font: normal normal bold 16px Mustica Pro;
                    }

                    & > .inputs {
                        display: flex;
                        gap: 20px;
                        align-items: baseline;
                    }
                }

                & > section {
                    & > h3 {
                        font: normal normal bold 16px/24px Mustica Pro;
                        letter-spacing: 0.14px;
                        color: #858585;
                        margin: 0 auto 16px 0;
                    }

                    & > label {
                        margin-left: 20px;
                    }
                }
            }

            & .filter-actions {
                margin-top: auto;
                display: flex;
                flex-direction: column;
                gap: 20px;

                & > button {
                    width: 180px;
                    margin: auto;
                }
            }
        }

        & .vertical-line {
            position: relative;

            &:after {
                content: '';
                width: 0;
                height: 100%;
                position: absolute;
                border-radius: 2px;
                border: 1px solid #CBCBCB;
                top: 0;
            }
        }

        & .content {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-right: 20px;

            // TODO: remove this when the test selection in implemented
            overflow: auto;
        }
    `,
};
export const AnalyticsPage = () => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);

    let isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin
        || storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;

    const $showAdditionalFilters = useHook(false);
    const $filters = useObjectHook({
        customer_id: isTrazerAdmin ? [] : [storeState.currentUser.admin.customerId],
        site_id: isTrazerAdmin ? [] : storeState.currentUser.admin.adminSites.map(x => x.siteId),
        scriptedActivity_id: [],
        excludePartials: false,
        excludeTUsers: false,
    });
    const $additionalFilters = useObjectHook({
        startDate: subYears(new Date(), 2),
        endDate: new Date(),
        genders: [],
        ageMin: '',
        ageMax: '',
        heightMin: '',
        heightMax: '',
        weightMin: '',
        weightMax: '',
        sports: [],
        positions: [],
        injuryLocations:[],
        injuryTypes:[],
        dominance: [],
        userGroups: [],
    });

    const $customers = useHook([]);
    const $sites = useHook([]);
    const $scriptedActivities = useHook([]);
    const $userGroups = useHook([]);
    const $sports = useHook([]);
    const $positions = useHook([]);
    const $injuries = useHook([]);
    const $injuryTypes = useHook([]);

    const $isLoading = useHook(false);
    const $isGeneratingReportData = useHook(false);
    const $availableTests = useHook(null);

    useEffect(() => {
        isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin
        || storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;

        $filters.set(({
            ...$filters.value,
            customer_id: isTrazerAdmin ? [] : [storeState.currentUser.admin.customerId]
        }))

        if (isTrazerAdmin) {
            CustomerService.getAll(9999, 'Name', true)
                .then($customers.set);
        }

        SportService.getAll()
            .then($sports.set);

        InjuryService.getInjuryLocations()
            .then($injuries.set)
    }, [storeState.currentUser]);

    useEffect(() => {
        $additionalFilters.set(filters => ({
            ...filters,
            positions: []
        }));

        if (!$additionalFilters.value.sports.length) {
            return;
        }

        SportService.getPositions($additionalFilters.value.sports)
            .then($positions.set)
    }, [$additionalFilters.value.sports]);

    useEffect(() => {
        $additionalFilters.set(filters => ({
            ...filters,
            injuryTypes: []
        }))

        if (!$additionalFilters.value.injuryLocations.length) {
            return;
        }

        InjuryService.getInjuryTypesByIds($additionalFilters.value.injuryLocations)
            .then($injuryTypes.set)
    }, [$additionalFilters.value.injuryLocations])

    useEffect(() => {
        $filters.set(filters => ({
            ...filters,
            site_id: []
        }));

        if (!$filters.value.customer_id.length) {
            return;
        }

        let siteIds = null;
        const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin;

        if (!isTrazerAdmin) {
            if (isLessThenSuperAdmin) {
                siteIds = storeState.currentUser.admin.adminSites.map(site => site.siteId);
            }
        }

        SiteService.getAll(9999, $filters.value.customer_id, null, 'Name', true, siteIds)
            .then($sites.set);
    }, [$filters.value.customer_id]);

    useEffect(() => {
        $additionalFilters.set(filters => ({
            ...filters,
            userGroups: []
        }));

        if (!$filters.value.customer_id.length && !$filters.value.site_id.length) {
            return;
        }

        GroupService.getGroups(9999, $filters.value.customer_id, $filters.value.site_id)
            .then($userGroups.set);
    }, [$filters.value.customer_id, $filters.value.site_id]);

    useEffect(() => {
        $filters.set(filters => ({
            ...filters,
            scriptedActivity_id: []
        }));

        if (!$filters.value.customer_id.length && !$filters.value.site_id.length) {
            return;
        }

        ScriptedActivitiesService.getScriptedActivitiesFiltered($filters.value.customer_id, $filters.value.site_id)
            .then($scriptedActivities.set);
    }, [$filters.value.customer_id, $filters.value.site_id]);

    const searchHandler = () => {
        const startDate = shortDate($additionalFilters.value.startDate);
        const endDate = shortDate($additionalFilters.value.endDate);
        $isLoading.set(true);

        const filters = {
            ...$filters.value,
        };
        if ($showAdditionalFilters.value) {
            Object.assign(filters, {
                ...$additionalFilters.value,
                dateStart: startDate,
                dateEnd: endDate
            })
        }

        TestService.filtered(filters)
            .then(data => {
                $availableTests.set(data.map(x => ({
                    ...x,
                    fullName: x.user.firstName + ' ' + x.user.lastName,
                    scriptedActivity: x.scriptedActivity?.name,
                    username: x.user?.username,
                    site: x.site?.name,
                    status: x.testStatus
                })));
            })
            .finally(() => $isLoading.set(false));
    }

    return (
        <div css={analyticsStyles.root}>
            <div className="filters">
                <div className="filter-list">
                    {isTrazerAdmin ?
                        <MultiSelect id="customerId" $value={$filters.getPropHook('customer_id')} required label={t('analytics.filters.customer')}>
                            {$customers.value.map(x => <MultiSelectOption key={x.customerId} value={x.customerId}>{x.name}</MultiSelectOption>)}
                        </MultiSelect>
                        :
                        null
                    }
                    <MultiSelect $value={$filters.getPropHook('site_id')} label={t('analytics.filters.site')}>
                        {$sites.value.map(x => <MultiSelectOption key={x.siteId} value={x.siteId}>{x.name}</MultiSelectOption>)}
                    </MultiSelect>
                    <MultiSelect $value={$filters.getPropHook('scriptedActivity_id')} label={t('analytics.filters.scriptedActivity')}>
                        {$scriptedActivities.value.map(x => <MultiSelectOption key={x.scriptedActivityId} value={x.scriptedActivityId}>{`${x.name} (${x.category.name})`}</MultiSelectOption>)}
                    </MultiSelect>

                    {$showAdditionalFilters.value &&
                        <>
                            <DateRangeSelector
                                label={t('analytics.filters.dateRange')}
                                $value={$additionalFilters}
                            />

                            <MultiSelect $value={$additionalFilters.getPropHook('genders')} label={t('analytics.filters.gender')}>
                                {GendersTranslationKeysArr.map(x => <MultiSelectOption key={x.value} value={x.value}>{t(x.translationKey)}</MultiSelectOption>)}
                            </MultiSelect>

                            <label className="range-input">
                                <span>{t('analytics.filters.heightInches')}</span>
                                <div className="inputs">
                                    <Input
                                        placeholder={t('input.placeholder.min')}
                                        $value={$additionalFilters.getPropHook('heightMin')}
                                    />
                                    {t('analytics.filters.rangeTemplate')}
                                    <Input
                                        placeholder={t('input.placeholder.max')}
                                        $value={$additionalFilters.getPropHook('heightMax')}
                                    />
                                </div>
                            </label>

                            <label className="range-input">
                                <span>{t('analytics.filters.weightLbs')}</span>
                                <div className="inputs">
                                    <Input
                                        placeholder={t('input.placeholder.min')}
                                        $value={$additionalFilters.getPropHook('weightMin')}
                                    />
                                    {t('analytics.filters.rangeTemplate')}
                                    <Input
                                        placeholder={t('input.placeholder.max')}
                                        $value={$additionalFilters.getPropHook('weightMax')}
                                    />
                                </div>
                            </label>

                            <label className="range-input">
                                <span>{t('analytics.filters.ageYears')}</span>
                                <div className="inputs">
                                    <Input
                                        placeholder={t('input.placeholder.min')}
                                        $value={$additionalFilters.getPropHook('ageMin')}
                                    />
                                    {t('analytics.filters.rangeTemplate')}
                                    <Input
                                        placeholder={t('input.placeholder.max')}
                                        $value={$additionalFilters.getPropHook('ageMax')}
                                    />
                                </div>
                            </label>

                            <MultiSelect $value={$additionalFilters.getPropHook('userGroups')} label={t('analytics.filters.userGroup')}>
                                {$userGroups.value.map(x => <MultiSelectOption key={x.groupId} value={x.groupId}>{x.groupName}</MultiSelectOption>)}
                            </MultiSelect>
                            <MultiSelect $value={$additionalFilters.getPropHook('sports')} label={t('analytics.filters.sport')}>
                                {$sports.value.map(x => <MultiSelectOption key={x.sportId} value={x.sportId}>{x.name}</MultiSelectOption>)}
                            </MultiSelect>
                            <MultiSelect $value={$additionalFilters.getPropHook('positions')} label={t('analytics.filters.position')}>
                                {$positions.value.map(x => <MultiSelectOption key={x.positionId} value={x.positionId}>{x.name}</MultiSelectOption>)}
                            </MultiSelect>
                            <MultiSelect $value={$additionalFilters.getPropHook('injuryLocations')} label={t('analytics.filters.injury')}>
                                {$injuries.value.map(x => <MultiSelectOption key={x.injuryLocationId} value={x.injuryLocationId}>{x.name}</MultiSelectOption>)}
                            </MultiSelect>
                            <MultiSelect $value={$additionalFilters.getPropHook('injuryTypes')} label={t('analytics.filters.injuryType')}>
                                {$injuryTypes.value.map(x => <MultiSelectOption key={x.injuryTypeId} value={x.injuryTypeId}>{x.name}</MultiSelectOption>)}
                            </MultiSelect>
                            <MultiSelect $value={$additionalFilters.getPropHook('dominance')} label={t('analytics.filters.dominance')}>
                                {DominanceTranslationKeysArr.map(x => <MultiSelectOption key={x.value} value={x.value}>{t(x.translationKey)}</MultiSelectOption>)}
                            </MultiSelect>
                        </>
                    }
                    <section>
                        <h3>{t('analytics.filters.excludeSectionLabel')}</h3>
                        <Checkbox
                            checked={$filters.value.excludePartials}
                            onChange={value => {
                                $filters.set({
                                    ...$filters.value,
                                    excludePartials: value
                                });
                            }}
                            label={t('analytics.filters.partials')}
                        />
                        {
                        // Could be uncommented in the future if customers want test users excluded
                        /*<Checkbox
                            checked={$filters.value.excludeTUsers}
                            onChange={value => {
                                $filters.set({
                                    ...$filters.value,
                                    excludeTUsers: value
                                });
                            }}
                            label={t('analytics.filters.testUsers')}
                        />*/}
                    </section>
                </div>
                <div className="filter-actions">
                    {$showAdditionalFilters.value ?
                        <Button onClick={() => $showAdditionalFilters.set(false)}>
                            {t('actions.showLessFilters')}
                        </Button>
                        :
                        <Button onClick={() => $showAdditionalFilters.set(true)}>
                            {t('actions.showMoreFilters')}
                        </Button>
                    }
                    <Button disabled={$isLoading.value} onClick={searchHandler}>
                        {t('actions.search')}
                    </Button>
                </div>
            </div>
            <div className="vertical-line"></div>
            <div className="content">
                {$isLoading.value ?
                    <CircularLoader />
                    : $availableTests.value &&
                        <SearchResultLayout $availableTests={$availableTests} $isGeneratingReportData={$isGeneratingReportData}/>
                }
            </div>
        </div>
    );
};
