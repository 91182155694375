// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useMemo } from "react";
import { KinematicChart } from "../../../../CoreComponents/ChartComponents/KinematicsChart";
import { ReportTable } from "../../ReportTable";
import { chartCardStyles } from "./TestReportCharts";
import { useTranslation } from "../../../../CoreComponents/Translation";
import { NoData, BaseActivities } from "../../../../Utils/Constants";
import { RadioGroup, RadioOption } from '../../../../CoreComponents/RadioSelection';
import { useHook } from '../../../../CoreComponents/Utils';

const radioGroupStyles = {
    test: css`
        align-self: center;
        & .radio-group {
            display: flex;
            flex-direction: row;
            margin-left: 0;
        }
    `
}

export const UpperExtremityChartSection = ({ isPrinting, sectionName, dataMap, datasets }) => {
    const { t } = useTranslation();
    if (!datasets || !datasets.length) {
        return null;
    }

    const tableData = {};
    const tableDataKeys = Object.keys(
        datasets.reduce((a, c) => ({ ...a, ...c?.data }), {})
    );
    const trackedDataMap =  { ...dataMap };
    for (const ds of datasets) {
        for (const key of tableDataKeys) {
            const mappedKey = trackedDataMap[key];
            if (!mappedKey) {
                continue;
            }

            if (!tableData[mappedKey]) {
                tableData[mappedKey] = {
                    rowName: mappedKey,
                };
            }

            tableData[mappedKey][ds.label] = ds.data[key];
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { chartData } = useMemo(() => {
        const chartData = datasets.map(x => {
            const currentChartData = x.data;

            const torso = [
                { name: dataMap.totalTargetsHit, value: currentChartData.totalTargetsHit },
                { name: dataMap.targetsMissed, value: currentChartData.targetsMissed },
            ];
            const rightKnee = [
                { name: dataMap.targetsHitRight180, value: currentChartData.targetsHitRight180 },
                { name: dataMap.targetsHitRight45, value: currentChartData.targetsHitRight45 },
                { name: dataMap.targetsHitRight90, value: currentChartData.targetsHitRight90 },
                { name: dataMap.targetsHitRight135, value: currentChartData.targetsHitRight135 },
                { name: dataMap.targetsHitForwardRight45, value: currentChartData.targetsHitForwardRight45 },
                { name: dataMap.targetsHitForwardRight90, value: currentChartData.targetsHitForwardRight90 },
                { name: dataMap.targetsHitForwardRight135, value: currentChartData.targetsHitForwardRight135 },
            ];
            const leftKnee = [
                { name: dataMap.targetsHitLeft180, value: currentChartData.targetsHitLeft180 },
                { name: dataMap.targetsHitLeft45, value: currentChartData.targetsHitLeft45 },
                { name: dataMap.targetsHitLeft90, value: currentChartData.targetsHitLeft90 },
                { name: dataMap.targetsHitLeft135, value: currentChartData.targetsHitLeft135 },
                { name: dataMap.targetsHitForwardLeft45, value: currentChartData.targetsHitForwardLeft45 },
                { name: dataMap.targetsHitForwardLeft90, value: currentChartData.targetsHitForwardLeft90 },
                { name: dataMap.targetsHitForwardLeft135, value: currentChartData.targetsHitForwardLeft135 },
            ];

            return {
                ...x,
                data: {
                    torso,
                    leftKnee,
                    rightKnee,
                },
            }
        });
        return {
            chartData
        }
    }, [t, datasets, dataMap]);

    return (
        <div css={chartCardStyles} className={datasets.length > 2 ? 'multiple-results' : null}>
            <div className="info">
                <h2>{sectionName}</h2>
            </div>
            <div className="chart-container upper-extremity-chart-container">
                <KinematicChart
                    isPrinting={isPrinting}
                    datasets={chartData}
                />
            </div>
            <div className="vertical-line"></div>
            <div className="table-data">
                <ReportTable
                    enableRowHeader
                    color="gray"
                    disableRowShadow
                    data={Object.values(tableData)}
                />
            </div>
        </div>
    );
};


export const UpperExtremityKinematicChartSection = ({ isPrinting, sectionName, baseActivityId, dataMap, datasets, trackedMetrics }) => {
    const { t } = useTranslation();
    const $isJumpActivity = baseActivityId === BaseActivities.KinematicJump || baseActivityId === BaseActivities.KinematicSingleLegJump || baseActivityId === BaseActivities.VerticalDropJump;
    const $isFlexionExtensionActivity = baseActivityId === BaseActivities.ROMElbowFlexionExtension || baseActivityId === BaseActivities.ROMFlexionExtension || baseActivityId === BaseActivities.ROMShoulderLinearFlexionExtension;
    const $isAdductionAbductionActivity = baseActivityId === BaseActivities.ROMShoulderHorizontalAdductionAbduction || baseActivityId === BaseActivities.ROMShoulderLateralAdductionAbduction;
    const $isLeftRightActivity = baseActivityId === BaseActivities.ROMRotation || baseActivityId === BaseActivities.ROMLateralFlexion;
    const $isInternalExternalActivity = baseActivityId === BaseActivities.ROMShoulderInternalExternalRotation;
    const $selectedJumpMetric = useHook($isJumpActivity ? 'Post-Jump' : '');
    const $selectedFlexionExtensionMetric = useHook($isFlexionExtensionActivity ? 'Flexion' : '');
    const $selectedAdductionAbductionMetric = useHook($isAdductionAbductionActivity ? 'Abduction' : '');
    const $selectedLeftRightMetric = useHook($isLeftRightActivity ? 'Left' : '');
    const $selectedInternalExternalMetric = useHook($isInternalExternalActivity ? 'External' : '');
    if (!datasets || !datasets.length) {
        return null;
    }

    const tableData = {};
    const tableDataKeys = Object.keys(
        datasets.reduce((a, c) => ({ ...a, ...c?.data }), {})
    );
    const trackedDataMap =  { ...dataMap };
    if (!trackedMetrics.spine){
        delete trackedDataMap.initialSpineFlexion;
        delete trackedDataMap.spineFlexion;
        delete trackedDataMap.initialSpineLatFlexion;
        delete trackedDataMap.spineLatFlexion;
        delete trackedDataMap.initialSpineRotation;
        delete trackedDataMap.spineRotation;
        delete trackedDataMap.initialNeckRotation;
        delete trackedDataMap.neckRotation;
        delete trackedDataMap.initialNeckFlexion;
        delete trackedDataMap.neckFlexion;
        delete trackedDataMap.initialNeckLatFlexion;
        delete trackedDataMap.neckLatFlexion;
        delete trackedDataMap.initialShoulderRotation;
        delete trackedDataMap.shoulderRotation;
        delete trackedDataMap.initialThoracicRotation;
        delete trackedDataMap.thoracicRotation;
        delete trackedDataMap.initialLumbarRotation;
        delete trackedDataMap.lumbarRotation;
    }
    if (!trackedMetrics.arms){
        delete trackedDataMap.initialRightShoulderRotation;
        delete trackedDataMap.rightShoulderRotation;
        delete trackedDataMap.initialRightShoulderFlexion;
        delete trackedDataMap.rightShoulderFlexion;
        delete trackedDataMap.initialRightShoulderAbduction;
        delete trackedDataMap.rightShoulderAbduction;
        delete trackedDataMap.initialRightShoulderHorizontalAbduction;
        delete trackedDataMap.rightShoulderHorizontalAbduction;
        delete trackedDataMap.initialRightElbowFlexion;
        delete trackedDataMap.rightElbowFlexion;
        delete trackedDataMap.initialLeftShoulderRotation;
        delete trackedDataMap.leftShoulderRotation;
        delete trackedDataMap.initialLeftShoulderFlexion;
        delete trackedDataMap.leftShoulderFlexion;
        delete trackedDataMap.initialLeftShoulderAbduction;
        delete trackedDataMap.leftShoulderAbduction;
        delete trackedDataMap.initialLeftShoulderHorizontalAbduction;
        delete trackedDataMap.leftShoulderHorizontalAbduction;
        delete trackedDataMap.initialLeftElbowFlexion;
        delete trackedDataMap.leftElbowFlexion;
    }

    if(($isJumpActivity && $selectedJumpMetric.value === 'Post-Jump') ||
    ($isFlexionExtensionActivity && $selectedFlexionExtensionMetric.value === 'Extension') ||
    ($isAdductionAbductionActivity && $selectedAdductionAbductionMetric.value === 'Adduction') ||
    ($isLeftRightActivity && $selectedLeftRightMetric.value === 'Right') ||
    ($isInternalExternalActivity && $selectedInternalExternalMetric.value === 'Internal')){
        delete trackedDataMap.initialSpineFlexion;
        delete trackedDataMap.initialSpineLatFlexion;
        delete trackedDataMap.initialSpineRotation;
        delete trackedDataMap.initialNeckRotation;
        delete trackedDataMap.initialNeckFlexion;
        delete trackedDataMap.initialNeckLatFlexion;
        delete trackedDataMap.initialShoulderRotation;
        delete trackedDataMap.initialThoracicRotation;
        delete trackedDataMap.initialLumbarRotation;
        delete trackedDataMap.initialRightShoulderRotation;
        delete trackedDataMap.initialRightShoulderFlexion;
        delete trackedDataMap.initialRightShoulderAbduction;
        delete trackedDataMap.initialRightShoulderHorizontalAbduction;
        delete trackedDataMap.initialRightElbowFlexion;
        delete trackedDataMap.initialLeftShoulderRotation;
        delete trackedDataMap.initialLeftShoulderFlexion;
        delete trackedDataMap.initialLeftShoulderAbduction;
        delete trackedDataMap.initialLeftShoulderHorizontalAbduction;
        delete trackedDataMap.initialLeftElbowFlexion;
    }
    else if($isJumpActivity || $isFlexionExtensionActivity ||$isAdductionAbductionActivity || $isLeftRightActivity || $isInternalExternalActivity){
        delete trackedDataMap.spineFlexion;
        delete trackedDataMap.spineLatFlexion;
        delete trackedDataMap.spineRotation;
        delete trackedDataMap.neckRotation;
        delete trackedDataMap.neckFlexion;
        delete trackedDataMap.neckLatFlexion;
        delete trackedDataMap.shoulderRotation;
        delete trackedDataMap.thoracicRotation;
        delete trackedDataMap.lumbarRotation;
        delete trackedDataMap.rightShoulderRotation;
        delete trackedDataMap.rightShoulderFlexion;
        delete trackedDataMap.rightShoulderAbduction;
        delete trackedDataMap.rightShoulderHorizontalAbduction;
        delete trackedDataMap.rightElbowFlexion;
        delete trackedDataMap.leftShoulderRotation;
        delete trackedDataMap.leftShoulderFlexion;
        delete trackedDataMap.leftShoulderAbduction;
        delete trackedDataMap.leftShoulderHorizontalAbduction;
        delete trackedDataMap.leftElbowFlexion;
    }

    for (const ds of datasets) {
        for (const key of tableDataKeys) {
            const mappedKey = trackedDataMap[key];
            if (!mappedKey) {
                continue;
            }

            if (!tableData[mappedKey]) {
                tableData[mappedKey] = {
                    rowName: mappedKey,
                };
            }

            tableData[mappedKey][ds.label] = ds.data[key];
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { chartData } = useMemo(() => {
        const chartData = datasets.map(x => {
            const currentChartData = x.data;
            const isPostSelected = $selectedJumpMetric.value === 'Post-Jump' || $selectedFlexionExtensionMetric.value === 'Extension' || $selectedAdductionAbductionMetric.value === 'Adduction' || $selectedLeftRightMetric.value === 'Right' || $selectedInternalExternalMetric.value === 'Internal' ||
                ($selectedJumpMetric.value === '' && $selectedFlexionExtensionMetric.value === '' && $selectedAdductionAbductionMetric.value === '' && $selectedLeftRightMetric.value === '' && $selectedInternalExternalMetric.value === '');
            const spine = [];
            if (trackedMetrics.spine) {
                spine.push(
                    { name: t('testReport.fieldName.neckRotation'), value: isPostSelected ? currentChartData.neckRotation : currentChartData.initialNeckRotation },
                    { name: t('testReport.fieldName.spineFlexion'), value: isPostSelected ? currentChartData.spineFlexion : currentChartData.initialSpineFlexion },
                    { name: t('testReport.fieldName.spineLatFlexion'), value: isPostSelected ? currentChartData.spineLatFlexion : currentChartData.initialSpineLatFlexion },
                    { name: t('testReport.fieldName.spineRotation'), value: isPostSelected ? currentChartData.spineRotation : currentChartData.initialSpineRotation },
                    { name: t('testReport.fieldName.shoulderRotation'), value: isPostSelected ? currentChartData.shoulderRotation : currentChartData.initialShoulderRotation },
                    { name: t('testReport.fieldName.thoracicRotation'), value: isPostSelected ? currentChartData.thoracicRotation : currentChartData.initialThoracicRotation },
                    { name: t('testReport.fieldName.lumbarRotation'), value: isPostSelected ? currentChartData.lumbarRotation : currentChartData.initialLumbarRotation },
                );
            }

            const leftShoulder = [];
            const rightShoulder = [];
            const leftElbow = [];
            const rightElbow = [];
            if (trackedMetrics.arms) {
                rightShoulder.push(
                    { name: t('testReport.fieldName.rotation'), value: isPostSelected ? currentChartData.rightShoulderRotation : currentChartData.initialRightShoulderRotation },
                    { name: t('testReport.fieldName.abduction'), value: isPostSelected ? currentChartData.rightShoulderAbduction : currentChartData.initialRightShoulderAbduction },
                    { name: t('testReport.fieldName.horizontalAbduction'), value: isPostSelected ? currentChartData.rightShoulderHorizontalAbduction : currentChartData.initialRightShoulderHorizontalAbduction },
                    { name: t('testReport.fieldName.shoulderFlexion'), value: isPostSelected ? currentChartData.rightShoulderFlexion : currentChartData.initialRightShoulderFlexion },
                );
                leftShoulder.push(
                    { name: t('testReport.fieldName.rotation'), value: isPostSelected ? currentChartData.leftShoulderRotation : currentChartData.initialLeftShoulderRotation },
                    { name: t('testReport.fieldName.abduction'), value: isPostSelected ? currentChartData.leftShoulderAbduction : currentChartData.initialLeftShoulderAbduction},
                    { name: t('testReport.fieldName.horizontalAbduction'), value: isPostSelected ? currentChartData.leftShoulderHorizontalAbduction : currentChartData.initialLeftShoulderHorizontalAbduction },
                    { name: t('testReport.fieldName.shoulderFlexion'), value: isPostSelected ? currentChartData.leftShoulderFlexion : currentChartData.initialLeftShoulderFlexion },
                );
                rightElbow.push(
                    { name: t('testReport.fieldName.elbowFlexion'), value: isPostSelected ? currentChartData.rightElbowFlexion : currentChartData.initialRightElbowFlexion },
                );
                leftElbow.push(
                    { name: t('testReport.fieldName.elbowFlexion'), value: isPostSelected ? currentChartData.leftElbowFlexion : currentChartData.initialLeftElbowFlexion },
                );
            }

            return {
                ...x,
                data: {
                    spine,
                    leftShoulder,
                    rightShoulder,
                    leftElbow,
                    rightElbow,
                },
            }
        });
        return {
            chartData
        }
    }, [t, datasets, trackedMetrics, $selectedJumpMetric.value, $selectedFlexionExtensionMetric.value, $selectedAdductionAbductionMetric.value, $selectedLeftRightMetric.value, $selectedInternalExternalMetric.value]);

    return ((trackedMetrics.spine || trackedMetrics.arms) &&
        <div css={chartCardStyles} className={datasets.length > 2 ? 'multiple-results' : null}>
            <div className="info">
                <h2>{sectionName}</h2>
            </div>
            <div className="chart-container kinematic-chart-container">
                <KinematicChart
                    isPrinting={isPrinting}
                    datasets={chartData}
                />
            </div>
            {$isJumpActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedJumpMetric}>
                        <RadioOption key='Pre-Jump' value='Pre-Jump' label='Pre-Jump' />
                        <RadioOption key='Post-Jump' value='Post-Jump' label='Post-Jump' />
                    </RadioGroup>
                </div>
            }
            {$isFlexionExtensionActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedFlexionExtensionMetric}>
                        <RadioOption key='Flexion' value='Flexion' label='Flexion' />
                        <RadioOption key='Extension' value='Extension' label='Extension' />
                    </RadioGroup>
                </div>
            }
            {$isAdductionAbductionActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedAdductionAbductionMetric}>
                        <RadioOption key='Abduction' value='Abduction' label='Abduction' />
                        <RadioOption key='Adduction' value='Adduction' label='Adduction' />
                    </RadioGroup>
                </div>
            }
            {$isLeftRightActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedLeftRightMetric}>
                        <RadioOption key='Left' value='Left' label='Left' />
                        <RadioOption key='Right' value='Right' label='Right' />
                    </RadioGroup>
                </div>
            }
            {$isInternalExternalActivity &&
                <div css={radioGroupStyles.test}>
                    <RadioGroup $value={$selectedInternalExternalMetric}>
                        <RadioOption key='External' value='External' label='External' />
                        <RadioOption key='Internal' value='Internal' label='Internal' />
                    </RadioGroup>
                </div>
            }
            <div className="vertical-line"></div>
            <div className="table-data">
                <ReportTable
                    enableRowHeader
                    color="gray"
                    disableRowShadow
                    data={Object.values(tableData)}
                />
            </div>
        </div>
    );
};
