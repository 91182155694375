/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button } from "../../../CoreComponents/Button";
import { Input, TextareaInput } from "../../../CoreComponents/Input";
import { useTranslation } from "../../../CoreComponents/Translation";
import { ForceValidateContext, useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { UnitService } from "../../../Services/UnitService";
import { newGUID } from "../../../Utils/Common";
import { SystemModesTranslationKeysArr } from '../../../Utils/Constants';
import { maxLengthValidator, requiredValidator } from "../../../Utils/Validators";
import { useEffect } from "react";
import { CustomerService } from "../../../Services/CustomerService";
import { SiteService } from "../../../Services/SiteService";
import { AlertText } from "../../../CoreComponents/Alert";
import { SingleSelect } from "../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../CoreComponents/SelectListComponents/SelectOption";

const addUnitModalContentStyles = {
    root: css`
        width: unset;
        height: 90vh;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0;
            margin-bottom: 40px;
        }

        & > .field-container {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            max-height: calc(100% - 110px - 1.5em);
            gap: 30px;

            & > .input-root {
                margin: 0;
                width: 100%;
            }

            @media (min-resolution: 96dpi),
                screen and (max-height: 1030px) {
                padding-right: 20px;
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }

        & > .alert-text-root {
            align-self: end;
            margin: 20px 0;
        }

        & textarea {
            height: 200px;
            resize: none;
            overflow-y: auto;
        }
    `,
};

const UnitValidator = {
    customerId: [requiredValidator],
    siteId: [requiredValidator],
    skuNumber: [requiredValidator, maxLengthValidator(30)],
};

export const AddUnitContent = ({ onCancel, onAdd, customerId, siteId }) => {
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $systemMode = useHook(0);

    const { t } = useTranslation();
    const $newUnit = useObjectHook(
        {
            unitId: newGUID(),
            customerId: customerId || '',
            siteId: siteId || '',
            skuNumber: '',
            notes: ''
        },
        UnitValidator,
        null,
        $isForceValidating.value
    );

    useEffect(() => {
        CustomerService.getAll(9999, 'Name', true)
            .then(customers => $customers.set(customers))
    }, [])

    useEffect(() => {
        if (!$newUnit.value.customerId || siteId) {
            return;
        }
        $newUnit.set({ ...$newUnit.value, siteId: '' })
        SiteService.getAll(9999, $newUnit.value.customerId, null, 'Name', true)
            .then(sites => $sites.set(sites))
    }, [$newUnit.value.customerId])

    const addUnit = () => {
        $isForceValidating.set(true);

        const errors = $newUnit.validate();
        if (errors) {
            return;
        };

        $isSaving.set(true);
        UnitService.postUnit($systemMode.value, $newUnit.value)
            .then(() => onAdd($newUnit.value))
            .finally(() => $isSaving.set(false));
    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addUnitModalContentStyles.root}>
                <h2>{t('units.addModal.title')}</h2>

                <div className="field-container">
                    {!customerId &&
                        <SingleSelect onChange={() => $isForceValidating.set(true)} required label={t('units.fieldName.customer')} $value={$newUnit.getPropHook('customerId')}>
                            {$customers.value.map(c => <SelectOption key={c.customerId} value={c.customerId}>{c.name}</SelectOption>)}
                        </SingleSelect>
                    }
                    {!siteId &&
                        <SingleSelect onChange={() => $isForceValidating.set(true)} required label={t('units.fieldName.site')} $value={$newUnit.getPropHook('siteId')}>
                            {$sites.value.map(s => <SelectOption key={s.siteId} value={s.siteId}>{s.name}</SelectOption>)}
                        </SingleSelect>
                    }

                    <Input placeholder={t('input.placeholder')} required label={t('units.fieldName.skuNumber')} $value={$newUnit.getPropHook('skuNumber')} />

                    {<SingleSelect label={t('units.fieldName.systemMode')} $value={$systemMode}>
                        {SystemModesTranslationKeysArr.map(x => (
                            <SelectOption key={x.value} value={x.value}>
                                {t(x.translationKey)}
                            </SelectOption>
                        ))}
                    </SingleSelect>}

                    <TextareaInput
                        textAreaProps={{ style: { minHeight: 200 } }}
                        label={t('units.fieldName.notes')}
                        placeholder={t('input.placeholder')}
                        $value={$newUnit.getPropHook('notes')}
                    />
                </div>

                {$newUnit.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                <div className="actions" css={addUnitModalContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newUnit.isValid} onClick={addUnit}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
}
