/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { CircularProgress, css } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Button } from "./Button";
import { useTranslation } from "./Translation";
import { useHook } from "./Utils";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const uploadFileAreaStyle = {
    formFileUpload: css`
        margin-top: 73px;
        max-width: 100%;
        text-align: center;
        position: relative;
    `,
    inputFileUpload: css`
        display: none;
    `,
    labelFileUpload: css`
        width: 600px;
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 1rem;
        background: #e0e0e0 0% 0% no-repeat padding-box;
        border-radius: 8px;
      `,
    rectContentContainer: css`
        & > p {
            color: #858585;
        }
    `,
    uploadButton: css`
        text-align: center;
        letter-spacing: 0.14px;
        width: 136px;
        height: 40px;
    `,
    dragFileElement: css`
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;w
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    `,
    progressContainer: css`
        display: flex;
        & > div {
            margin: auto;
            position: relative;
            display: flex;

            & .empty-indicator {
                position: absolute;
                color: #858585;
            }
            & .arrow {
                height: 46px;
                width: 48px;
                color: #00ABA5;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    `
}

export const FileUploadArea = ({$file, fileTypes, $disableNextButton}) => {
    const { t } = useTranslation();

    const [dragActive, setDragActive] = useState(false);
    // ref
    const $progress = useHook(0);
    const inputRef = useRef(null);

    // handle drag events
    /**
     * @param {DragEvent} e
     */
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    /**
     * @param {DragEvent} e
     */
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        const file = e.dataTransfer.files[0];
        for (const fileType of fileTypes) {
            if (file?.name.endsWith(fileType)) {
                $file.set(file);
                break;
            }
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        $file.set(e.target.files[0]);
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    useEffect(() => {
        $progress.set(0);
        if (!$file.value) {
            return;
        }
        const timer = setInterval(() => {
            $progress.set((prevProgress) => {
                if (prevProgress >= 100) {
                    clearTimeout(timer);
                    return 100;
                }
                return prevProgress + 10;
            });
        }, 100);
        return () => {
            clearInterval(timer);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$file.value]);

    useEffect(() => {
        $disableNextButton.set($progress.value < 100)
    }, [$progress.value])


    return (
        <form css={uploadFileAreaStyle.formFileUpload} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <label css={uploadFileAreaStyle.labelFileUpload} className={dragActive ? "drag-active" : ""}>
                <input ref={inputRef} type="file" css={uploadFileAreaStyle.inputFileUpload} accept={fileTypes.join(',')} onChange={handleChange} />
                <div css={uploadFileAreaStyle.rectContentContainer}>
                    <p>{$file.value?.name}</p>
                    <div css={uploadFileAreaStyle.progressContainer}>
                        <div>
                            <CircularProgress
                                size={80}
                                thickness={1.5}
                                variant="determinate"
                                value={100}
                                className="empty-indicator"
                            />
                            <CircularProgress
                                size={80}
                                thickness={1.5}
                                variant="determinate"
                                value={$progress.value}
                                className="indicator"
                            />
                            <ArrowUpwardIcon className="arrow" />
                        </div>
                    </div>
                    <p>{t('importUsers.importFileStep.dragToUpload')}</p>
                    <Button css={uploadFileAreaStyle.uploadButton} onClick={onButtonClick}>{t('actions.chooseFile')}</Button>
                </div>
            </label>
            {dragActive && <div css={uploadFileAreaStyle.dragFileElement} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </form>
    )
}
