// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useState, forwardRef, useRef } from 'react';
import { AlertText } from './Alert';
import { useForceValidateContext } from './Utils';
import { FrozenEmptyObject } from '../Utils/Constants';

const styles = {
    rootWithIcon: css`
        position: relative;
        box-sizing: content-box;
        display: flex;
    `,
    root: css`
        position: relative;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        margin: 8px 20px 21px 20px;
        flex-wrap: wrap;
    `,
    label: css`
        margin: 0 auto 8px 0;
        letter-spacing: 0.14px;
        color: #858585;
        font: normal normal bold 16px Mustica Pro;
        margin-bottom: 18px;
    `,
    labelError: css`
        color: #E0758A;
    `,
    input: css`
        font: normal normal normal 16px/24px Roboto;
        color: #858585;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        &::placeholder {
            color: #CBCBCB;
        }

        border: none;
        border-radius: 8px;
        padding: 10px 20px;
        width: 100%;
        height: 40px;

        &[disabled] {
            color: #858585;
            background-color: #8585851a;
        }
    `,
    outlinedInput: css`
        border: 2px solid #858585;
    `,
    inputWithEndIcon: css`
        // border-top-right-radius: 0;
        // border-bottom-right-radius: 0;
    `,
    inputError: css`
        border: 2px solid #E0758A;
    `,
    endIconRoot: css`
        position: absolute;
        right: 0;
        // background: #00ABA5 0% 0% no-repeat padding-box;

        border-radius: 0px 8px 8px 0px;
        width: 48px;
        height: 40px;
        display: flex;

        & > * {
            margin: auto;
        }
    `,
    endIconColor: css`
        background: #CBCBCB 0% 0% no-repeat padding-box;
        & > * {
            color: #FFFFFF;
        }
    `,
};

export const Input = forwardRef(function (props, ref) {
    const forceValidateContext = useForceValidateContext();
    const {
        id,
        label,
        labelProps,
        inputProps,
        placeholder,
        $value,
        value,
        onChange,
        onBlur,
        validator,
        disabled,
        disableEndIconStyles,
        valueRef,
        validateWithValueRef,
        endIcon,
        type,
        variant = 'contained',
        className,
        allowUndefinedValue,
        required,
        containerRef,
        ...other
    } = props;
    const [validationErr, setValidationErr] = useState([]);
    const hasErrors = validationErr.length > 0;
    const lastCheckedValue = useRef(FrozenEmptyObject);

    useEffect(() => {
        if (value == null && $value?.value == null && !allowUndefinedValue) {
            console.warn(`Input value for [${label}] is null or undefined`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!forceValidateContext
            && lastCheckedValue.current === FrozenEmptyObject
        ) {
            return;
        }

        if (validator) {
            const validatedValue = validateWithValueRef ?
                valueRef
                : (value || $value?.value);
            const validationResult = validator(validatedValue);
            // console.log('useEffect validation', {label, validatedValue, validationResult})
            setValidationErr(validationResult || []);
        } else if ($value?.validate && !validateWithValueRef) {
            setValidationErr($value.validate() || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceValidateContext, value, validator, $value?.isValid, $value?.value, $value?.validate]);

    const valueInternal = $value ?
        $value.value || ''
        : value;

    const onChangeInternal = (e) => {
        const value = e.target.value;
        lastCheckedValue.current = value;
        $value && $value.set(value);
        onChange && onChange(value, e);
    }

    const InputComponent = (
        <input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            ref={ref}
            css={[
                styles.input,
                variant === 'outlined' && styles.outlinedInput,
                endIcon && !disableEndIconStyles && styles.inputWithEndIcon,
                hasErrors && styles.inputError,
                inputProps?.css
            ]}
            {...inputProps}
            value={valueInternal}
            onChange={onChangeInternal}
            onBlur={onBlur}
        />
    );

    const EndIconComponent = endIcon &&
        <div css={[styles.endIconRoot, !disableEndIconStyles && styles.endIconColor]} className="side-icon">
            {endIcon}
        </div>

    return (label ?
        <div ref={containerRef} css={styles.root} className={'input-root ' + (className || '')} {...other}>
            <label htmlFor={id} css={[styles.label, hasErrors && styles.labelError]} {...labelProps}>
                {label} {required && <span className="required">*</span>}
            </label>
            {endIcon ?
                <div css={styles.rootWithIcon}>
                    {InputComponent}
                    {EndIconComponent}
                </div>
                : InputComponent
            }
            {validationErr.map(x => <AlertText key={x}>{x}</AlertText>)}
        </div>
        :
        <>
            {endIcon ?
                <div ref={containerRef} css={styles.rootWithIcon} className={className}>
                    {InputComponent}
                    {EndIconComponent}
                </div>
                : InputComponent
            }
            {validationErr.map(x => <AlertText key={x}>{x}</AlertText>)}
        </>
    );
});

export const TextareaInput = (props) => {
    const forceValidateContext = useForceValidateContext();
    const {
        id,
        label,
        labelProps,
        textAreaProps,
        placeholder,
        $value,
        value,
        onChange,
        validator,
        disabled,
        className,
        required,
        ...other
    } = props;
    const [validationErr, setValidationErr] = useState([]);
    const hasErrors = validationErr.length > 0;
    const lastCheckedValue = useRef(FrozenEmptyObject);

    useEffect(() => {
        if (value == null && $value?.value == null) {
            console.warn(`Input value for [${label}] is null or undefined`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!forceValidateContext
            && lastCheckedValue.current === FrozenEmptyObject
        ) {
            return;
        }

        if (validator) {
            const validatedValue = value || $value?.value;
            const validationResult = validator(validatedValue);
            setValidationErr(validationResult || []);
        } else if ($value?.validate) {
            setValidationErr($value.validate() || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceValidateContext, value, validator, $value?.isValid, $value?.value, $value?.validate]);

    const valueInternal = $value ?
        $value.value || ''
        : value;

    const onChangeInternal = (e) => {
        const value = e.target.value;
        lastCheckedValue.current = value;
        $value && $value.set(value);
        onChange && onChange(value);
    }

    const TextareaComponent = (
        <textarea
            id={id}
            value={valueInternal}
            onChange={onChangeInternal}
            placeholder={placeholder}
            disabled={disabled}
            css={[styles.input, hasErrors && styles.inputError, textAreaProps?.css]}
            {...textAreaProps}
        />
    );

    return (label ?
        <div css={styles.root} className={'input-root ' + (className || '')} {...other}>
            <label htmlFor={id} css={[styles.label, hasErrors && styles.labelError]} {...labelProps}>
                {label} {required && <span className="required">*</span>}
            </label>
            {TextareaComponent}
            {validationErr.map(x => <AlertText key={x}>{x}</AlertText>)}
        </div>
        :
        <>
            {TextareaComponent}
            {validationErr.map(x => <AlertText key={x}>{x}</AlertText>)}
        </>
    );
};
