/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { ActivitySettingsTable } from "../../../../CoreComponents/ActivitySettingsTable";
import { Button } from "../../../../CoreComponents/Button";
import { Checkbox } from "../../../../CoreComponents/Checkbox";
import { Input, TextareaInput } from "../../../../CoreComponents/Input";
import { TabLayout } from "../../../../CoreComponents/Layout";
import { useTranslation } from "../../../../CoreComponents/Translation";
import { ForceValidateContext, useHook, useObjectHook } from "../../../../CoreComponents/Utils";
import { digitsValidator, maxLengthValidator, requiredValidator } from "../../../../Utils/Validators";
import { AlertText } from "../../../../CoreComponents/Alert";
import { SingleSelect } from "../../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../../CoreComponents/SelectListComponents/SelectOption";

const addScriptedActivityStyles = {
    root: css`
        width: unset;
        height: 85vh;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        & > h2 {
            margin: 0 0 40px 0;
        }

        & > .horizontal-line {
            border-top: 2px solid rgb(211,211,211);
            margin: 40px 0;
            width: 100%;
        }

        & > .fields-container {
            & > .input-root {
                margin: 0 40px 0 0;
                width: 100%;
            }

            & > .first-row {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                margin-bottom: 30px;

                & > .input-root {
                    margin: 0 40px 0 0;
                }

                & > .name {
                    width: 100%;
                }

                & > .skippable {
                    margin-right: 0;
                    margin-top: 35px;
                }
            }
        }

        @media (min-width: 1200px) {
            width: 900px;

            & > .fields-container {
                & > .input-root {
                    margin: 0 40px 0 0;
                    width: 50%;
                }

                & > .first-row {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 30px;

                    & > .input-root {
                        margin: 0 40px 0 0;
                    }

                    & > .name {
                        width: 50%;
                    }

                    & > .skippable {
                        margin-right: 0;
                        margin-top: 35px;
                    }
                }
            }
        }

        & > .actions {
            display: flex;
            width: 100%;
            margin: auto 0 0 0;

            & > button {
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }

        & > .instructions {
            margin: 0;

            & > textarea {
                height: 160px;
                resize: none;
                overflow-y: auto;
            }
        }
    `
};

export const AddStepContentInformation = ({ $baseActivities, $newActivityStep, onCancel, onAdd, $isSaving }) => {
    const $isForceValidating = useHook(false);
    const { t } = useTranslation();

    useEffect(() => {
        $isForceValidating.set(!!$newActivityStep.value.baseActivityId);
    }, [$newActivityStep.value.baseActivityId])

    const addStep = () => {
        $isForceValidating.set(true);
        const errors = $newActivityStep.validate();
        if (errors) {
            return;
        }

        onAdd($newActivityStep.value);
    }

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addScriptedActivityStyles.root}>
                <h2>{t('activitySteps.addModal.title')}</h2>
                <div className="fields-container">
                    <div className="first-row">
                        <Input
                            placeholder={t('input.placeholder')}
                            required
                            label={t('activitySteps.fieldName.name')}
                            $value={$newActivityStep.getPropHook('name')}
                            className="name"
                        />
                        <Input
                            placeholder={t('input.placeholder')}
                            required
                            label={t('activitySteps.fieldName.order')}
                            $value={$newActivityStep.getPropHook('orderId')}
                        />
                        <Checkbox
                            className="skippable"
                            checked={$newActivityStep.value.isSkipped}
                            onChange={value => {
                                $newActivityStep.set({
                                    ...$newActivityStep.value,
                                    isSkipped: value
                                })
                            }}
                            label={t('activitySteps.fieldName.isSkipped')}
                        />
                    </div>
                    <SingleSelect
                        label={t('activitySteps.fieldName.activity')}
                        $value={$newActivityStep.getPropHook('baseActivityId')}
                        onChange={baseActivityId => {
                            const baseActivity = $baseActivities.value.find(x => x.baseActivityId === baseActivityId);
                            $newActivityStep.set({
                                ...$newActivityStep.value,
                                settings: baseActivity?.defaultSettings ? JSON.parse(baseActivity.defaultSettings) : [],
                                baseActivity,
                                baseActivityId
                            });
                        }}
                    >
                        {$baseActivities.value.map(ba => <SelectOption key={ba.baseActivityId} value={ba.baseActivityId}>{ba.name}</SelectOption>)}
                    </SingleSelect>
                </div>
                <div className="horizontal-line" />
                <TextareaInput
                    className="instructions"
                    placeholder={t('input.placeholder')}
                    label={t('activitySteps.fieldName.instructions')}
                    $value={$newActivityStep.getPropHook('instructions')}
                />

                <div className="actions" css={addScriptedActivityStyles.actions}>
                    {$newActivityStep.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                    <Button disabled={$isSaving.value || !$newActivityStep.isValid} onClick={addStep}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
}
const settingsTabStyle = {
    root: css `
        height: 85vh;
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        & h2 {
            margin: 0 0 30px 0;
        }

        @media (min-width: 1200px) {
            width: 900px;
        }
    `,
    table: css`
        height: calc(100% - 110px - 1.5em);
        overflow: auto;
        margin-left: -20px;
    `,
    actions: css`
        display: flex;
        flex-direction: row-reverse;
        margin-top: auto;
    `
}

export const AddStepContentSettings = ({ $newActivityStep, onAdd, $isSaving }) => {
    const { t } = useTranslation();

    return (
        <div css={settingsTabStyle.root}>
            <h2>{t('activitySteps.addModal.title')}</h2>
            <div css={settingsTabStyle.table}>
                <ActivitySettingsTable
                    settings={$newActivityStep.value.settings}
                    onChange={settings => $newActivityStep.set({
                        ...$newActivityStep.value,
                        settings
                    })}
                />
            </div>
            <div className="actions" css={settingsTabStyle.actions}>
                <Button disabled={$isSaving.value || !$newActivityStep.isValid} onClick={() => onAdd($newActivityStep.value)}>{t('actions.add')}</Button>
            </div>
        </div>
    )
}

const AddActivityStepValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
    orderId: [requiredValidator, digitsValidator],
    baseActivityId: [requiredValidator],
};

export const AddActivityStepModal = ({ $baseActivities, onAdd, scriptedActivity, $isSaving }) => {
    const { t } = useTranslation();

    const $newActivityStep = useObjectHook(
        {
            name: '',
            orderId: '',
            isSkipped: false,
            baseActivityId: '',
            instructions: '',
            settings: '',
            baseActivity: null
        },
        AddActivityStepValidator,
        (oldState, newState) => {
            if (scriptedActivity.scriptedActivitySteps
                .some(step => Number(step.orderId) === Number(newState.orderId))
            ) {
                return {
                    orderId: [
                        t('scriptedActivity.fieldError.existingOrderNumber')
                    ]
                }
            }

            return null;
        }
    );

    const tabs = useMemo(() => [
        {
            name: t('activitySteps.addModal.stepInformation'), ContentComponent: (props) =>
                <AddStepContentInformation
                    $baseActivities={$baseActivities}
                    {...props}
                />
        },
        $newActivityStep.value.settings && {
            name: t('activitySteps.addModal.settings'), ContentComponent: (props) =>
                <AddStepContentSettings
                    {...props}
                />
        },
    ].filter(x => x), [t, $newActivityStep.value.baseActivity]);

    return (
        <TabLayout items={tabs} activeTabProps={{ onAdd, $newActivityStep, $isSaving }} />
    )
}