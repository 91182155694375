// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { useMemo, useRef, useState } from 'react';
import { useTranslation } from '../Translation';
import {
    ChartPadding,
    ChartResizeDelay,
    DefaultAnimationDuration,
    SharedPluginsConfig,
    chartStyles,
    useChartPrintEffect,
    ChartColors
} from '../../Utils/Constants';
import { ChartOptionsMenu } from './ChartOptionsMenu';
import { BellCurveAndHistogramPlugins, calculateNormalDistributionData } from '../../Utils/NormalDistribution';
import { Chart } from 'react-chartjs-2';

export const DistributionChart = ({ data, bottomAxisTitle, unitType, unitTypeShort, pointsInInterval = 2, interval = 4, isPrinting, ...props }) => {
    const { t } = useTranslation();
    const chartRef = useRef(null);
    const [size, setNewSize] = useState(null);

    const labels = ['4σ', '', '3σ', '', '2σ ', '', 'σ ', '', 'μ', '', ' σ', '', '  2σ', '', '3σ', '', '4σ'];

    const rounding = 10;
    const rawDataItemRounding = 1000;
    const {
        histogramData,
        histogramLabels,
        histogramMax,
        normalDistributionData,
        normalDistributionMax
    } = useMemo(() => {
        return calculateNormalDistributionData(data, labels, interval, pointsInInterval, rounding);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, interval, pointsInInterval]);

    /** @type import('chart.js/dist/types').ChartOptions<'bar'> */
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        resizeDelay: isPrinting ? 0 : ChartResizeDelay,
        layout: {
            padding: ChartPadding,
        },

        onResize: (chart, newSize) => {
            setNewSize(newSize);
        },

        animation: {
            duration: DefaultAnimationDuration
        },

        plugins: {
            ...SharedPluginsConfig,
            customScale: {
                grace: '5%',
            },
            tooltip: {
                ...SharedPluginsConfig.tooltip,
                callbacks: {
                    title: function (context) {
                        if (!context.length) {
                            return '';
                        }

                        const item = context[0];
                        if (item.datasetIndex === 0) {
                            return item.label + (item.dataset.data[item.dataIndex + 1] ?
                                ' - ' + item.dataset.data[item.dataIndex + 1].x
                                : '+'
                            );
                        } else if (item.label) {
                            return Math.round(item.chart.config.data.datasets[1].data[item.dataIndex].x * 10000) / 10000;
                        } else if (labels.length > 0 && item.dataIndex < labels.length) {
                            return labels[item.dataIndex];
                        }
                    }
                }
            },
            // legend: {
            //     ...CharedPluginsConfig.legend,
            //     position: 'bottom'
            // },
            dataLabels: {
                unitType: unitTypeShort,
                backgroundColor: `rgba(${ChartColors[0].color}, 0.5)`,
                // borderColor: `rgba(${ChartColors[0].color}, 1)`,
                // backgroundColor: `rgb(204, 238, 237)`,
                borderColor: `rgb(0, 171, 165)`,
                borderRadius: 8,
                // infoPlacement: 'right',
                t,
            }
        },

        backgroundColor: 'rgba(255, 255, 255, 1)',

        scales: {
            xAxisA: {
                type: 'category',
                display: true,
                labels: histogramLabels,
                ...(bottomAxisTitle ? {
                    title: {
                        display: true,
                        text: bottomAxisTitle
                    }
                } : {})
            },
            xAxisB: {
                type: 'category',
                display: true,
                labels: normalDistributionData.map(a => Math.round(a.x * rounding) / rounding),
                position: 'top',
                title: {
                    display: true,
                    text: t('chart.normalDistribution.rightAxisClarification')
                },
            },
            xAxisC: {
                type: 'category',
                display: false,
                labels: normalDistributionData.map(a => Math.round(a.x * rawDataItemRounding) / rawDataItemRounding)
            },
            yAxisA: {
                display: true,
                type: 'linear',
                title: {
                    display: true,
                    text: t('chart.normalDistribution.leftAxisClarification')
                },
                max: histogramMax * 1.15
            },
            yAxisB: {
                display: true,
                type: 'linear',
                position: 'right',
                title: {
                    display: true,
                    text: t('chart.normalDistribution.rightAxisClarification')
                },
                max: normalDistributionMax * 1.15
            },
            yAxisC: {
                display: false,
                type: 'linear',
                position: 'right',
            }
        }
    };

    const datasetSettings = {
        borderWidth: 4,

        pointHitRadius: 8,
        pointRadius: 8,
        pointBorderWidth: 4,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 4,

        // minBarLength: 15,
    };
    const chartData = {
        datasets: [
            {
                label: t('chart.normalDistribution.datasetNameHistogram'),
                data: histogramData,
                xAxisID: 'xAxisA',
                yAxisID: 'yAxisA',
                barPercentage: 1,
                categoryPercentage: 1,
                minBarLength: 15,

                ...datasetSettings,
                ...ChartColors[1]
            },
            {
                type: 'scatter',
                label: t('chart.normalDistribution.datasetNameNormalDistribution'),
                data: normalDistributionData,
                showLine: true,
                fill: true,
                hidden: true,
                xAxisID: 'xAxisB',
                yAxisID: 'yAxisB',
                tension: 0.4,
                pointsInInterval: 2,

                ...datasetSettings,
                ...ChartColors[0]
            },
            {
                type: '',
                label: '',//t('chart.normalDistribution.datasetNameData'),
                data: data,
                hidden: true,
                xAxisID: 'xAxisB',
                yAxisID: 'yAxisB',

                // ...datasetSettings,
                // ...ChartColors[3]
            }
        ]
    };

    useChartPrintEffect(chartRef.current, size);

    return (props.enableOptionsMenu ?
        <ChartOptionsMenu
            chartRef={chartRef}
            title={props.title}
            unitType={unitType}
            exportFileName={props.exportFileName}
            datasets={[
                {
                    label: t('chart.normalDistribution.datasetNameHistogram'),
                    data: histogramData,
                    exportSettings: {
                        xAxis: t('chart.normalDistribution.exportRangeTemplate', props.title),
                        xValue: (entry, index, dataset) => entry.x + (dataset.data[index + 1] ? ` - ${dataset.data[index + 1].x}` : '+'),
                        yAxis: t('chart.normalDistribution.exportColumnCount'),
                        yValue: (entry, index, dataset) => entry.y || 0,
                    },
                },
                {
                    label: t('chart.normalDistribution.datasetNameNormalDistribution'),
                    data: normalDistributionData,
                    disableExport: true,
                },
                {
                    label: t('chart.normalDistribution.datasetNameData'),
                    data: data,
                    hidden:true,
                    disableExport: true,
                },
            ]}
            chartComponent={
                <Chart type="bar" ref={chartRef} data={chartData} options={options} plugins={[BellCurveAndHistogramPlugins]} />
            }
        />
        :
        <div css={chartStyles.responsiveContainer}>
            <Chart type="bar" ref={chartRef} data={chartData} options={options} plugins={[BellCurveAndHistogramPlugins]} />
        </div>
    );
};
