// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { Snackbar } from '@mui/material';
import SnackbarUnstyled from '@mui/base/SnackbarUnstyled';
import { useHook } from './Utils';
import { AlertType, AlertTypeTranslationKeysObj } from '../Utils/Constants';
import { useTranslation } from './Translation';

const alertTextStyles = {
    warning: css`
        color: #E0758A;
    `,
    success: css`
        color: #00c851;
    `,
    small: css`
        font: normal normal normal 12px/24px Roboto;
        letter-spacing: 0.11px;
    `,
    large: css`
        font: normal normal normal 18px/24px Roboto;
        letter-spacing: 0.11px;
    `,
};

export const AlertText = ({ variant = 'small', ...params }) =>
    <div css={[
            params.success ? alertTextStyles.success : alertTextStyles.warning,
            variant === 'large' ? alertTextStyles.large : alertTextStyles.small,
        ]}
        className="alert-text-root"
        {...params}
    >
        {params.children}
    </div>

const alertTypesMap = {
    [AlertType.Info]: 'info',
    [AlertType.Warning]: 'warning',
    [AlertType.Success]: 'success',
    [AlertType.Error]: 'error'
};

const alertStyles = {
    root: css`
        display: flex;
        min-width: 400px;
        min-height: 96px;
        max-height: 96px;

        background-color: #FFFFFF;
        box-shadow: 0px 8px 16px #28326529;
        border-radius: 8px;

        & > .alert-color {
            min-width: 16px;
            width: 16px;
            min-height: 96px;
            max-height: 96px;
            border-radius: 8px 0px 0px 8px;
        }

        & > .alert-content {
            padding: 21px 28px;
            text-align: left;
            font: normal normal normal 16px/24px Roboto;
            letter-spacing: 0.14px;
            color: #858585;

            & > h3 {
                margin: 0;
                margin-bottom: 9px;
                text-align: left;
                font: normal normal bold 20px/24px Mustica Pro;
                letter-spacing: 0.18px;
                color: #858585;
            }

        }

        & > .${alertTypesMap[AlertType.Error]} {
            background-color: #F06A6A;
        }
        & > .${alertTypesMap[AlertType.Success]} {
            background-color: #5FE37D;
        }
        & > .${alertTypesMap[AlertType.Warning]} {
            background-color: #FDBF6A;
        }
        & > .${alertTypesMap[AlertType.Info]} {
            background-color: #00ABA5;
        }
    `,
}

const Alert = ({ type, children }) => {
    const { t } = useTranslation();

    return (
        <div css={alertStyles.root}>
            <div className={`alert-color ${alertTypesMap[type]}`}></div>
            <div className="alert-content">
                <h3>{t(AlertTypeTranslationKeysObj[type])}</h3>
                {children}
            </div>
        </div>
    );
}

const AlertBarItem = ({ item, handleClose }) => {
    const $isOpen = useHook(true);

    return (
        <SnackbarUnstyled open={$isOpen.value} autoHideDuration={item.duration} onClose={(event, reason) => handleClose(event, reason, item)}>
            <div>
                <Alert className="alert" onClose={(event, reason) => handleClose(event, reason, item)} type={item.type} sx={{ width: '100%' }}>
                    {item.text}
                </Alert>
            </div>
        </SnackbarUnstyled>
    )
}

const alertBarStyles = {
    root: css`
        & .MuiSnackbar-root{
            top: 80px;
            right: 80px;
        }

        & .alerts-container > * {
            margin: 10px 0;
        }

        & .progress-bar {
            position: absolute;
            inset: auto 0 0 0;
            background-color: #858585;

            & .MuiLinearProgress-bar {
                background-color: #ffffff;
            }
        }

        & .alert {
            position: relative;
        }
    `,
}

export const AlertBar = ({ handleClose, ...props }) => {
    const { alerts } = useAlertsContext();
    return (
        <div css={alertBarStyles.root}>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={true} >
                <div className="alerts-container">
                    {alerts.map(item =>
                        <AlertBarItem key={item.id} item={item} items={alerts} handleClose={handleClose} />)}
                </div>
            </Snackbar>
        </div>
    );
}

export const initialAlertsState = {
    alerts: [],
    makeAlert: (type, text, duration = 5000) => { }
};

export const AlertContext = React.createContext(initialAlertsState);

export const useAlertsContext = () => React.useContext(AlertContext);
