/* eslint-disable react-hooks/rules-of-hooks */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"

const activityDetailsStyle = {
    root: css`
        width:100%;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding-left: 40px;

        & > .details-container {
            display: flex;
            height: 100%;
            align-items: flex-start;
            width: 100%;
            justify-content: space-between;

            & > .details {
                display: flex;
                flex-direction: column;
                width: calc(50% - 70px);
                height: 100%;

                & > img {
                    box-shadow: 0px 8px 16px #28326529;
                    border-radius: 8px;
                }

                & > .summary {
                    margin-top: 40px;
                    font: normal normal normal 20px/32px Roboto;
                    letter-spacing: 0.18px;
                    color: #2C2E35;
                }

                & > .category {
                    font: normal normal normal 28px/24px Roboto;
                    letter-spacing: 0.25px;
                    color: #858585;
                    margin-top: 10px;
                }

                & > .activity {
                    font: normal normal bold 40px/24px Mustica Pro;
                    letter-spacing: 0.36px;
                    line-height: 40px;
                    color: #2C2E35;
                    margin-top: 40px;
                }

                & .step {
                    color: #2C2E35;
                }
            }
        }

        & .step-container {
            margin-left: 40px;
            height: 100%;
            overflow-y: auto;
            padding-right: 32px;
        }

        @media (max-width: 1340px) {
            flex-direction: column;

            & > .details-container {
                flex-direction: column;
                height: unset;

                & > .details {
                    width: 100%;
                    height: unset;
                    margin-bottom: 20px;

                    & > img {
                        max-width: unset;
                        width: 100%;
                    }
                }

                & > .step-container {
                    padding-right: 0;
                    margin-left: 0;
                    width: 100%;
                    overflow: unset;
                    height: 100%;
                }
            }
        }
    `,
    card: css`
        margin-bottom: 20px;

        & > .header{
            min-width: 450px;
            height: 60px;
            background: #00ABA5 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 28px;

            & > .title {
                text-align: left;
                font: normal normal bold 24px/24px Roboto;
                letter-spacing: 0.22px;
                color: #FFFFFF;
            }
        }

        & > .description {
            min-width: 450px;
            background-color: #FFFFFF;
            border-radius: 0 0 8px 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 28px;

            & > .instructions {
                text-align: left;
                font: normal normal normal 16px/24px Roboto;
                letter-spacing: 0.14px;
                color: #666666;
                overflow-y: auto;
                position: relative;
                height: 100%;
            }
        }
    `
}

export const LibraryActivityDetails = ({ $selectedActivity }) => {
    return (
        <div css={activityDetailsStyle.root}>
            <div className="details-container">
                <div className="details">
                    <img
                        alt={`sa${$selectedActivity.value.scriptedActivityId}`}
                        src={$selectedActivity.value.imageSrc}
                    />
                    <h1 className="activity">{$selectedActivity.value.name}</h1>
                    <span className="category">{$selectedActivity.value.category.name}</span>
                    <p className="summary">{$selectedActivity.value.summary}</p>

                </div>
                <div className="step-container">
                    {$selectedActivity.value.scriptedActivitySteps
                        .map(s =>
                            <ActivityStepCard
                                key={s.scriptedActivityStepId}
                                orderId={s.orderId}
                                instructions={s.instructions}
                                name={s.name}
                            />)}
                </div>
            </div>
        </div>
    );
}

const ActivityStepCard = ({ name, instructions, orderId }) => {
    return (
        <div css={activityDetailsStyle.card}>
            <div className="header">
                <span className="title">
                    {orderId}. {name}
                </span>
            </div>
            <div className="description">
                <span className="instructions">
                    {instructions}
                </span>
            </div>
        </div>
    )
}
