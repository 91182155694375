/* eslint-disable react-hooks/rules-of-hooks */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useHook } from '../../CoreComponents/Utils';
import { useEffect } from 'react';
import { CategoryService } from '../../Services/CategoryService';
import { TabLayout } from '../../CoreComponents/Layout';
import { ScriptedActivitiesService } from '../../Services/ScriptedActivitiesService';
import { Button } from '../../CoreComponents/Button';
import { useTranslation } from '../../CoreComponents/Translation';
import { ImageFiles } from '../../Utils/ImageList';
import { Input } from '../../CoreComponents/Input';
import { ChevronLeftIcon, FilterCircleXmarkIcon, MagnifyingGlassIcon, PrintIcon } from '../../CoreComponents/CustomIcons';
import { CircularLoader } from '../../CoreComponents/Loaders';
import { LibraryActivityDetails } from './LibraryActivityDetails';
import { PrintWrapper } from '../Reports/TestReport/PrintWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setActivities } from '../../globalStates/libraryState';

const styles = {
    clearFiltersButton: css`
        width: 100%;
        height: 100%;
        min-width: unset;
        border-radius: 0px 8px 8px 0px;
    `,
    layout: css`
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        & > div {
            padding: 20px 40px 40px 0;
        }

        @media print {
            & > aside {
                display: none;
            }
            & > div {
                height: 100%;
            }
        }
    `,
    loadContainer: css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    `
};

const DefaultImage = '/images/scripted_activities/DEFAULT.png'

export const LibraryPage = () => {
    const libraryState = useSelector((state) => state.libraryState);
    const dispatch = useDispatch();

    const $categories = useHook(libraryState.activities.map(x => x.category));
    const $activities = useHook(libraryState.activities);

    useEffect(() => {
        CategoryService.getAll()
            .then(categories => {
                if ($categories.value.length !== categories.length){
                    $categories.set(categories);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!$categories.value.length) {
            return;
        }

        (async () => {
            const values = [];
            await Promise.all($categories.value.map(category => category &&
                ScriptedActivitiesService.getScriptedActivitiesCustomerLibrary(category.categoryId)
                    .then(scriptedActivities => {
                        scriptedActivities = scriptedActivities.map(x => {
                            const imagePath = `/images/scripted_activities/sa${x.scriptedActivityId}.png`;
                            return {
                                ...x,
                                imageSrc: ImageFiles.includes(imagePath) ? imagePath : DefaultImage
                            }
                        })
                        values.push({ category, scriptedActivities });
                    })
            ));
            if (values.length !== $activities.value.length) {
                const activities = values.sort(function (a, b) {
                    if (a.category.categoryId < b.category.categoryId)
                        return -1;
                    if (a.category.categoryId > b.category.categoryId)
                        return 1;
                    return 0;
                });
                $activities.set(activities);
                dispatch(setActivities(activities));
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$categories.value])

    return $activities.value.length ?
        <TabLayout
            items={$activities.value.filter(x => x.scriptedActivities.length).map((c, index) => ({
                name: c.category.name,
                path: c.category.categoryId.toString(),
                ContentComponent: LibraryContent(c.scriptedActivities),
                isDefault: index === 0
            }))}
            isPageWide
            type="router"
            css={styles.layout}
        />
        :
        <div css={styles.loadContainer}>
            <CircularLoader />
        </div>
};

const activityContainerStyle = {
    root: css`
        height: calc(100% - 60px);
    `,
    search: css`
        width: 100%;
        display: flex;

        & .back-search {
            height: 40px;
            width: calc(100% - 32px);
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-right: 40px;
            padding-left: 40px;

            & > button {
                min-width: unset;
            }

            & > input {
                width: unset;
            }
        }

        & .print > button {
            min-width: 32px;
        }

        @media print {
            display: none;
        }
    `,
    activityContainer: css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: 80vh;
        overflow-y: auto;
        padding-right: 22px ;
    `
}

const LibraryContent = (scriptedActivities) => () => {
    const $filteredScriptedActivities = useHook([]);
    const $search = useHook('');
    const $selectedActivity = useHook(null);
    const { t } = useTranslation();
    const $isPrinting = useHook(false);

    useEffect(() => {
        $selectedActivity.set(null);
        $filteredScriptedActivities.set(scriptedActivities.filter(sa =>
            sa.name.toLowerCase().includes($search.value.toLowerCase()) ||
            sa.summary?.toLowerCase().includes($search.value.toLowerCase())
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$search.value])

    const Content = () => (
        <div css={activityContainerStyle.root}>
            {$selectedActivity.value ?
                <LibraryActivityDetails $selectedActivity={$selectedActivity} />
                :
                $filteredScriptedActivities.value.length ?
                    <div css={activityContainerStyle.activityContainer}>
                        {$filteredScriptedActivities.value.map(sa =>
                            <ActivityCard
                                key={sa.scriptedActivityId}
                                scriptedActivity={sa}
                                $selectedActivity={$selectedActivity}
                            />
                        )}
                    </div>
                    :
                    <div css={activityContainerStyle.loadContainer}>
                        {t('library.noMatchingActivities')}
                    </div>
            }
        </div>
    )

    return ($isPrinting.value ?
        <PrintWrapper isPrinting={$isPrinting.value}>
            <Content />
        </PrintWrapper>
        :
        <div css={activityContainerStyle.root}>
            <div css={activityContainerStyle.search}>
                <div className="back-search">
                    {$selectedActivity.value &&
                        <Button id="go-back" disabled={!$selectedActivity.value} onClick={() => $selectedActivity.set(null)}>
                            <ChevronLeftIcon />
                        </Button>
                    }
                    {!$selectedActivity.value &&
                        <Input
                            $value={$search}
                            onChange={$search.set}
                            placeholder={t('input.searchPlaceholder')}
                            endIcon={
                                ($search.value)
                                    ?
                                    <Button
                                        css={styles.clearFiltersButton}
                                        onClick={() => {
                                            $search.set('');
                                        }}
                                    >
                                        <FilterCircleXmarkIcon />
                                    </Button>
                                    :
                                    <MagnifyingGlassIcon />
                            }
                        />
                    }
                </div>
                {$selectedActivity.value &&
                    <div className="print">
                        <Button
                            className="print-action"
                            disabled={!$selectedActivity.value}
                            onClick={() => {
                                $isPrinting.set(true);
                                setTimeout(() => {
                                    window.print();
                                    $isPrinting.set(false);
                                }, 200)
                            }}
                        >
                            <PrintIcon />
                        </Button>
                    </div>
                }
            </div>
            {$selectedActivity.value ?
                <LibraryActivityDetails $selectedActivity={$selectedActivity} />
                :
                $filteredScriptedActivities.value.length ?
                    <div css={activityContainerStyle.activityContainer}>
                        {$filteredScriptedActivities.value.map(sa =>
                            <ActivityCard
                                key={sa.scriptedActivityId}
                                scriptedActivity={sa}
                                $selectedActivity={$selectedActivity}
                            />
                        )}
                    </div>
                    :
                    <div css={activityContainerStyle.loadContainer}>
                        {t('library.noMatchingActivities')}
                    </div>
            }
        </div>
    )
}

const activityCardStyle = {
    root: css`
        width: 360px;
        height: 552px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 16px #28326529;
        border-radius: 8px;
        margin-left: 40px;
        margin-bottom: 40px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        & > img {
            width: 100%;
            height: 256px;
            border-radius: 8px;
        }

        & .section {
            position: absolute;
            bottom: 20px;
            left: 20px;
            width: calc(100% - 40px);
            padding: 4px;
            background: transparent linear-gradient(180deg, #E0E0E0 0%, #B8B8B8 100%)
                0% 0% no-repeat padding-box;
            box-shadow: inset 0px 0px 4px #ffffff66;
            opacity: 95%;
            border-radius: 8px;
            height: fit-content;
        }

        & .section:first-of-type {
            top: 215px;
            height: fit-content;
        }

        & .title {
            color: #2C2E35;
            align-items: center;
            width: 100%;
            height: fit-content;
            padding: 10px;
            background: transparent linear-gradient(180deg, #FFFFFF 0%, #E0E0E0 100%)
                0% 0% no-repeat padding-box;
            border-radius: 4px;
        }

        & .summary {
            font: normal normal normal 16px/24px Roboto;
            color: #2C2E35;
            width: 100%;
            padding: 20px;
            background: transparent linear-gradient(180deg, #FFFFFF 0%, #E0E0E0 100%)
                0% 0% no-repeat padding-box;
            border-radius: 4px;

            & > p {
                width: 100%;
                height: 116px;
                overflow: hidden;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    left: 0;
                    pointer-events: none;
                    background-image: linear-gradient(to bottom,
                                      rgba(255,255,255, 0),
                                      #ECECEC 90%);
                    width: 100%;
                    height: 1em;
                  }
            }
        }

        @media print {
            margin: 0;
            padding: 0;
        }
    `
}

const ActivityCard = ({ scriptedActivity, $selectedActivity }) => {
    const { t } = useTranslation();

    return (
        <div css={activityCardStyle.root}>
            <img
                alt={`sa${scriptedActivity.scriptedActivityId}`}
                src={scriptedActivity.imageSrc}
            />
            <div className="section">
                <div className="title">
                    {scriptedActivity.name}
                </div>
            </div>
            <div className="section">
                <div className="summary">
                    <p>{scriptedActivity.summary}</p>
                    <Button onClick={() => $selectedActivity.set(scriptedActivity)}>{t('actions.learnMore')}</Button>
                </div>
            </div>
        </div>
    )
}
