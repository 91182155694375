import { createSlice } from "@reduxjs/toolkit";

const initialAnalyticsState = {
    tests: []
};

export const analyticsState = createSlice({
    name: 'analyticsState',
    initialState: initialAnalyticsState,
    reducers: {
        setTests: (state, action) => {
            state.tests = action.payload;
        }
    }
})

export const {
    setTests
} = analyticsState.actions;

export default analyticsState.reducer;