/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect } from 'react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { newGUID } from '../../../Utils/Common';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    emailValidator,
    maxLengthValidator,
    phoneValidator,
    requiredValidator,
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';
import { SiteService } from '../../../Services/SiteService';
import { CustomerService } from '../../../Services/CustomerService';
import { AddressService } from '../../../Services/AddressService';
import { AdminTypes } from '../../../Utils/Constants';
import { AlertText } from '../../../CoreComponents/Alert';
import { useSelector } from 'react-redux';
import { SingleSelect } from '../../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../../CoreComponents/SelectListComponents/SelectOption';

const addSiteModalContentStyles = {
    root: css`
    height: 85vh;
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 1200px) {
            width: 900px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 30px 0;
        }

        & > .fields-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow-y: auto;
            max-height: calc(100% - 110px - 1.5em);
            gap: 30px 40px;

            & > .horizontal-line {
                border-top: 2px solid rgb(211,211,211);
                width: 100%;
            }

            & > * {
                display: inline-flex;
                margin: 0;
                width: 100%;
                box-sizing: border-box;

                @media (min-width: 1200px) {
                    width: calc(50% - 20px);
                }
            }

            @media (min-resolution: 96dpi),
                screen and (max-height: 1030px) {
                padding-right: 20px;
            }
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;

            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }

            & > .alert-text-root {
                align-self: center;
            }
        }
    `,
};

const SiteValidators = {
    name: [requiredValidator, maxLengthValidator(250)],
    customerId: [requiredValidator],
    contactName: [maxLengthValidator(150)],
    phone: [phoneValidator],
    email: [maxLengthValidator(150), emailValidator],
    address: [maxLengthValidator(150)],
    address2: [maxLengthValidator(150)],
    city: [maxLengthValidator(150)],
    // if it's changed back to text input
    // state: [maxLengthValidator(150)],
    zipCode: [maxLengthValidator(150)],
    // if it's changed back to text input
    // country: [maxLengthValidator(150)],
};

export const AddSiteContent = ({ onCancel, onAdd, customerId }) => {
    const { t, currentLanguage } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin;
    const $isForceValidating = useHook(false);
    const $isSaving = useHook(false);
    const $customers = useHook([]);
    const $countries = useHook([]);
    const $states = useHook([]);
    const $newSite = useObjectHook(
        {
            siteId: newGUID(),
            name: '',
            contactName: '',
            phone: '',
            email: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            customerId: customerId || '',
        },
        SiteValidators,
        null,
        $isForceValidating.value
    );

    useEffect(() => {
        CustomerService.getAll(9999, 'Name', true)
            .then(customers => $customers.set(customers));
    }, []);

    useEffect(() => {
        const currentLanguageLowerCase = currentLanguage.toLowerCase();
        AddressService.getCountries(currentLanguageLowerCase)
            .then($countries.set);
        AddressService.getStates(currentLanguageLowerCase)
            .then($states.set);
    }, [currentLanguage]);

    const addSite = () => {
        $isForceValidating.set(true);
        const errors = $newSite.validate();
        if (errors) {
            return;
        }

        $isSaving.set(true);
        SiteService.postSite($newSite.value)
            .then(() => onAdd($newSite.value))
            .finally(() => $isSaving.set(false));

    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addSiteModalContentStyles.root}>
                <h2>{t('sites.addModal.title')}</h2>
                <div className="fields-container">
                    {(isTrazerAdmin || !customerId) &&
                        <>
                            <SingleSelect
                                $value={$newSite.getPropHook('customerId')}
                                required
                                label={t('sites.fieldName.customer')}
                                // NOTE: disabled for now
                                // for this to work we need to add loading indicator
                                // onChange={() => $isForceValidating.set(true)}
                            >
                                {$customers.value.map(c => <SelectOption key={c.customerId} value={c.customerId}>{c.name}</SelectOption>)}
                            </SingleSelect>
                            <div className="horizontal-line" />
                        </>
                    }
                    <Input placeholder={t('input.placeholder')} required label={t('sites.fieldName.name')} $value={$newSite.getPropHook('name')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.contactName')} $value={$newSite.getPropHook('contactName')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.phone')} $value={$newSite.getPropHook('phone')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.email')} $value={$newSite.getPropHook('email')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.address')} $value={$newSite.getPropHook('address')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.addressLine2')} $value={$newSite.getPropHook('address2')} />
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.city')} $value={$newSite.getPropHook('city')} />
                    <SingleSelect
                        placeholder={t('input.placeholder')}
                        label={t('sites.fieldName.country')}
                        $value={$newSite.getPropHook('country')}
                        onChange={country => {
                            $newSite.set({
                                ...$newSite.value,
                                country,
                                state: ''
                            });
                        }}
                    >
                        {$countries.value.map(x => <SelectOption key={x.code} value={x.code}>{x.name}</SelectOption>)}
                    </SingleSelect>
                    <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.zipCode')} $value={$newSite.getPropHook('zipCode')} />
                    {$newSite.value.country !== 'usa' ?
                        <Input placeholder={t('input.placeholder')} label={t('sites.fieldName.state')} $value={$newSite.getPropHook('state')} />
                        :
                        <SingleSelect placeholder={t('input.placeholder')} label={t('sites.fieldName.state')} $value={$newSite.getPropHook('state')}>
                            {$states.value.map(x => <SelectOption key={x.abbreviation} value={x.abbreviation}>{x.name}</SelectOption>)}
                        </SingleSelect>
                    }
                </div>
                <div className="actions" css={addSiteModalContentStyles.actions}>
                    {$newSite.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}

                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newSite.isValid} onClick={addSite}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
