/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { PillarService } from '../../../Services/PillarService';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { Button } from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { AddPillarContent } from './AddPillarContent';
import { EditPillarContent } from './EditPillarContent';

import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { IconButton } from '../../../CoreComponents/Button';
import { AdminTypes } from '../../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setPillars } from '../../../globalStates/tableDataState';

const pillarsStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}

export const PillarsList = ({ $selectedPillar, $refreshPillars }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $pillars = useHook(tableDataState.pillars);
    const $isLoading = useHook(!Boolean(tableDataState.pillars.length));
    const $isAddPillarModalOpened = useHook(false);
    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton disabled={isTrazerViewAdmin} onClick={() => $selectedPillar.set(rowData)}>
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('pillars.table.name'),
            isSortable: true,
        },
    ], [t]);

    useEffect(() => {
        PillarService.getAll(9999)
            .then(pillars => {
                dispatch(setPillars(pillars));
                $pillars.set(pillars);
                $isLoading.set(false);
            })
    }, [$refreshPillars.value]);

    return (
        <div css={pillarsStyles.root}>
            <TableCSF
                customActions={
                    <div css={pillarsStyles.actions}>
                        <Button disabled={isTrazerViewAdmin} onClick={() => $isAddPillarModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$pillars.value}
                rowKeySelector={r => r.pillarId}
                isLoading={$isLoading.value}
                totalItemsCount={$pillars.value.length}
                showAdvancedSearch={false}
            />
            <Modal
                open={$isAddPillarModalOpened.value}
                onClose={() => $isAddPillarModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <AddPillarContent onCancel={() => $isAddPillarModalOpened.set(false)}
                    onAdd={() => {
                        $isAddPillarModalOpened.set(false);
                        $refreshPillars.set({});
                    }} />
            </Modal>
        </div>
    )
};

export const Pillars = () => {
    const $selectedPillar = useObjectHook(null);
    const $refreshPillars = useHook({});
    const $prevSelectedPillars = useHook(null);

    useEffect(() => {
        if ($prevSelectedPillars.value && $selectedPillar.value) {
            $refreshPillars.set({});
            $prevSelectedPillars.set(null);
            $selectedPillar.set(null);
            return;
        }

        $prevSelectedPillars.set($selectedPillar.value);
    }, [$selectedPillar.value]);

    return (
        <>
            <PillarsList $selectedPillar={$selectedPillar} $refreshPillars={$refreshPillars} />
            <Modal
                open={!!$selectedPillar.value}
                onClose={() => $selectedPillar.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <EditPillarContent $selectedPillar={$selectedPillar} onCancel={() => $selectedPillar.set(null)} />
            </Modal>
        </>
    )
}
