import { getData, postData, putData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const TagService = {
    getAll: function(){
        return getData(`${ApiHost}/api/Tags`)
    },
    postTag: function(tag){
        return postData(`${ApiHost}/api/Tags`, tag);
    },
    putTag: function(tag){
        return putData(`${ApiHost}/api/Tags`, tag);
    },
}
