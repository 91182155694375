// ts-check
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const cardStyles = css`
    position: relative;
    padding: 40px;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #28326529;
    border-radius: 8px;
    min-height: 500px;
    max-height: 1000px;

    // new
    height: 100%;
    width: 100%;

    & .info {
        position: absolute;
        z-index: 1;

        & h2 {
            margin: 0 0 9px 0;
        }

        & span {
            font: normal normal normal 16px/24px Roboto;
        }
    }

    @media print {
        break-inside: avoid;
    }
`;

export const Card = ({ title, unitType, children, ...props }) => (
    <div css={cardStyles} className="card-root" {...props}>
        {title &&
            <div className="info">
                <h2>{title}</h2>
                {unitType &&
                    <span>{unitType}</span>
                }
            </div>
        }
        {children}
    </div>
);
