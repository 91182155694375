/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { TableCSF } from '../../CoreComponents/TableComponents/TableCSF';
import { useHook } from '../../CoreComponents/Utils';
import { TestService } from '../../Services/TestService';
import { shortTime } from '../../Utils/Common';
import { TrashCanIcon } from '../../CoreComponents/CustomIcons';
import { useTranslation } from '../../CoreComponents/Translation';
import { InternalLink } from '../../CoreComponents/Layout';
import { TestStatusesTranslationKeysObj } from '../../Utils/Constants';
import { IconButton } from '../../CoreComponents/Button';
import { CircularLoader } from '../../CoreComponents/Loaders';

const testsListStyles = {
    root: css`
        display: flex;
        flex-direction: column;

        height: 85vh;
        padding: 40px 0 40px 28px;

        & > .table {
            height: 65vh;
        }

        width: unset;
        // @media (min-width: 1500px) {
        //     width: 1200px;
        // }

        & h2 {
            margin-left: 12px;
            margin-top: 0;
        }
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
}

export const UserTestsTab = ({ $selectedUser }) => {
    const { t } = useTranslation();
    const $refreshTests = useHook({});
    const $tests = useHook([]);
    const $isLoading = useHook(true);

    const [headCells] = useState([
        {
            id: 'actions',
            label: '',
            columnWidth: 50,
            CellRender: ({ rowData }) => {
                return (
                    <IconButton
                        component={InternalLink}
                        to={`/reports/test/${rowData.testId}`}
                    >
                        <SignalCellularAltIcon />
                    </IconButton>
                );
            }
        },
        {
            id: 'scriptedActivity',
            label: t('tests.table.scriptedActivity'),
            isSortable: true,
            columnWidth: 200,
        },
        {
            id: 'startTime',
            label: t('tests.table.time'),
            columnWidth: 120,
            isSortable: true,
            CellRender: ({ rowData }) => {
                return rowData.startTime ? shortTime(new Date(rowData.startTime)) : '-';
            }
        },
        {
            id: 'userFullName',
            label: t('tests.table.name'),
            isSortable: true,
            columnWidth: 150,
        },
        {
            id: 'username',
            label: t('tests.table.username'),
            isSortable: true,
            columnWidth: 140,
        },
        {
            id: 'site',
            label: t('tests.table.site'),
            isSortable: true,
            columnWidth: 180,
        },
        {
            id: 'status',
            label: t('tests.table.status'),
            isSortable: true,
            columnWidth: 120,
            CellRender: ({ rowData }) => {
                return t(TestStatusesTranslationKeysObj[rowData.status]);
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton onClick={() => { deleteTest(rowData.testId) }}>
                            <TrashCanIcon />
                        </IconButton>
                    </>
                );
            }
        },
    ]);

    const deleteTest = (testId) => {
        const patchContent = [{
            path: '/testStatus',
            op: 'replace',
            value: '3'
        }];
        TestService.patchTest(testId, patchContent)
            .then(() => $refreshTests.set({}))
    }

    useEffect(() => {
        $isLoading.set(true);
        TestService.getAllTests(9999, $selectedUser.value.userId)
            .then(tests => {
                $tests.set(tests.map(x => ({
                    ...x,
                    scriptedActivity: x.scriptedActivityName,
                    username: x.username,
                    site: x.siteName,
                    status: x.testStatus
                })));
                $isLoading.set(false);
            });
    }, [$refreshTests.value])

    return $isLoading.value ?
    <div css={testsListStyles.loadingContainer}>
        <CircularLoader />
    </div>
    :
    (
        <div css={testsListStyles.root}>
            <TableCSF
                className="table"
                headCells={headCells}
                rows={$tests.value}
                rowKeySelector={r => r.testId}
                isLoading={$isLoading.value}
            />
        </div>
    )
}
