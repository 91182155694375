import { getData, postData, putData } from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const PillarService = {
    getAll: function(){
        return getData(`${ApiHost}/api/Pillars`);
    },
    postPillar: function(pillar){
        return postData(`${ApiHost}/api/Pillars`, pillar);
    },
    putPillar: function(pillar){
        return putData(`${ApiHost}/api/Pillars`, pillar);
    },
    updateScriptedActivities: function(pillarId, scriptedActivities) {
        return putData(`${ApiHost}/api/Pillars/${pillarId}/scriptedActivities`, scriptedActivities);
    },
}
