import {
    deleteData,
    getData,
    isoDate,
    makeFetchData,
    patchData,
    postData,
    putData,
    urlQueryString
} from '../Utils/Common';
import { ApiHost } from '../Utils/Constants';

export const UserService = {
    getAll: function (limit, sortBy, isAsc, customerId, siteId) {
        const qs = urlQueryString({
            limit,
            sort_by: sortBy,
            isAsc,
            customer_id: customerId,
            site_id: siteId
        }, true);
        return getData(`${ApiHost}/api/users?${qs}`)
            .then(res =>
                res.map(u => ({
                    ...u,
                    email: u.email || '',
                    dateOfBirth: u.dateOfBirth ? new Date(u.dateOfBirth.split('Z')[0]) : null,
                    dateCreated: u.dateCreated ? new Date(u.dateCreated) : null,
                    lastUnitLogin: u.lastUnitLogin ? new Date(u.lastUnitLogin) : null,
                }))
            );
    },
    getMe: function () {
        return getData(`${ApiHost}/api/users/me`);
    },
    getUser: function (userId) {
        return getData(`${ApiHost}/api/users/${userId}`);
    },
    signIn: function (username, pin) {
        return getData(`${ApiHost}/api/users/login?username=${username}&pin=${pin}`);
    },
    getUserSports: function (userId) {
        return getData(`${ApiHost}/api/users/sports/${userId}`)
    },
    getUserInjuries: function (userId) {
        return getData(`${ApiHost}/api/users/injuries/${userId}`)
            .then(res =>
                res.map(u => ({
                    ...u,
                    dateOfInjury: u.dateOfInjury ? new Date(u.dateOfInjury.split('Z')[0]) : null,
                    dateOfSurgery: u.dateOfSurgery ? new Date(u.dateOfSurgery.split('Z')[0]) : null
                }))
            );
    },
    patchUser: function (userData) {
        const data = [
            { op: 'replace', path: '/Height', value: userData.height },
            { op: 'replace', path: '/PIN', value: userData.pin },
            { op: 'replace', path: '/FirstName', value: userData.firstName },
            { op: 'replace', path: '/LastName', value: userData.lastName },
            { op: 'replace', path: '/Weight', value: userData.weight },
            { op: 'replace', path: '/Gender', value: userData.gender },
            { op: 'replace', path: '/PhoneNumber', value: userData.phoneNumber },
            { op: 'replace', path: '/DateOfBirth', value: isoDate(userData.dateOfBirth) },
            { op: 'replace', path: '/Email', value: userData.email },
            { op: 'replace', path: '/Username', value: userData.username },
            { op: 'replace', path: '/MedicalRecordNumber', value: userData.medicalRecordNumber }
        ];
        return patchData(`${ApiHost}/api/users/${userData.userId}`, data);
    },
    unblockUser: function (userId) {
        const data = [
            { op: 'replace', path: '/IsLocked', value: false },
            { op: 'replace', path: '/PinAttempts', value: 5 },
        ];
        return patchData(`${ApiHost}/api/users/${userId}`, data);
    },
    updateLastCloudLogin: function (userId) {
        const data = [
            { op: 'replace', path: '/LastCloudLogin', value: new Date() },
        ];
        return patchData(`${ApiHost}/api/users/${userId}`, data);
    },
    activateUser: function (userId) {
        return patchData(`${ApiHost}/api/users/${userId}`, [{
            op: 'replace',
            path: '/isActive',
            value: true
        }]);
    },
    putUserInjury: function (injury) {
        return putData(`${ApiHost}/api/users/injuries`, {
            ...injury,
            dateOfInjury: isoDate(injury.dateOfInjury),
            dateOfSurgery: injury.dateOfSurgery ? isoDate(injury.dateOfSurgery) : null,
        });
    },
    postUser: function (user) {
        return postData(`${ApiHost}/api/users`, user);
    },
    postUserSite: function (site) {
        return postData(`${ApiHost}/api/users/site`, site);
    },
    postUserSport: function (sport) {
        return postData(`${ApiHost}/api/users/sports`, sport);
    },
    postUserInjury: function (injury) {
        return postData(`${ApiHost}/api/users/injuries`, injury);
    },
    changeUserPassword: function (userId, password) {
        return postData(`${ApiHost}/api/Users/${userId}/passwordChange`, password);
    },
    deactivateUser: function (userId) {
        return deleteData(`${ApiHost}/api/users/${userId}`);
    },
    deleteUserSite: function (userId, siteId) {
        return deleteData(`${ApiHost}/api/users/${userId}/site/${siteId}`)
    },
    deleteUserSport: function (sportId) {
        return deleteData(`${ApiHost}/api/users/sports/${sportId}`);
    },
    deleteUserInjury: function (injuryId) {
        return deleteData(`${ApiHost}/api/users/injuries/${injuryId}`);
    },
    sendVerificationEmail: function (userId) {
        return postData(`${ApiHost}/api/Users/auth0/sendEmail/${userId}`)
    },
    importUsers: function (users, siteId, groupId) {
        const qs = urlQueryString({
            siteId,
            groupId
        }, true);
        return postData(`${ApiHost}/api/Users/import?${qs}`, users)
    },
    overview: function(userId, startDate, endDate) {
        const qs = urlQueryString({
            startDate,
            endDate,
        }, true);
        return getData(`${ApiHost}/api/users/${userId}/overview?${qs}`);
    },
    overviewTrend: function(userId, orderBy, dateInterval, startDate, endDate) {
        const qs = urlQueryString({
            order_by: orderBy,
            date_interval: dateInterval,
            startDate,
            endDate,
        }, true);
        return getData(`${ApiHost}/api/users/${userId}/overview/trend?${qs}`);
    },
    countTrends: function(numDataPoints, customerId, siteId) {
        const qs = urlQueryString({
            num_data_points: numDataPoints,
            customer_id: customerId,
            site_id: siteId
        }, true);
        return getData(`${ApiHost}/api/users/trend?${qs}`);
    },
    getDashboard: function(userId) {
        return getData(`${ApiHost}/api/users/${userId}/dashboard`);
    },
    putDashboard: function(dashboard) {
        return putData(`${ApiHost}/api/users/dashboard`, dashboard)
    },
    registerUser: function (siteId, user) {
        const url = `${ApiHost}/api/Users/Site/${siteId}`;
        return window.extendedFetch(url, makeFetchData('POST', user));
    }
};
