/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useMemo, useRef } from "react";
import { useHook, useOnResize } from "../../../../CoreComponents/Utils";

const importTableStyles = {
    table: css`
        border-collapse: separate;
        border-spacing: 0 20px;
        border: 0;
        width: 100%;
        padding-right: 20px;

        & thead tr th {
            font: normal normal bold 16px/24px Mustica Pro;
            letter-spacing: 0.14px;
            color: #3A817F;

            & > div {
                width: 160px;
                margin-left: 0;
                margin-right: 12px;
            }

            &.row-number {
                width: 80px;
            }

            &:first-of-type {
                width: 80px;
            }

            & .selector-wrapper {
                & > button {
                    min-width: unset;
                }
            }

            &:nth-of-type(2) {
                padding-left: 40px;
            };

            &:last-of-type {
                padding-right: 40px;
            };

            & > label {
                margin: 42px 12px 28px 0;

                & > button {
                    margin: 0;
                    min-width: 0;
                }
            }
        }

        & thead tr th:last-of-type {
            & > label {
                margin: 42px 0 28px 0;

                & > button {
                    margin: 0;
                    min-width: 0;
                }
            }
        }

        & tbody > tr {
            position: relative;
            height: 80px;
            & .shadow-box {
                box-shadow: 0px 8px 16px #28326529;
                border-radius: 8px;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0px;
                margin-left: 20px;
            }

            & > th {
                font: normal normal bold 16px/24px Mustica Pro;
                letter-spacing: 0.14px;
                color: #858585;
                padding-right: 20px;
                text-align: right;
            }

            & > td {
                font: normal normal normal 16px/24px Roboto;
                color: #858585;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-radius: 0;
                word-wrap: anywhere;

                &:first-of-type {
                    border-radius: 8px 0 0 8px;
                    padding-left: 40px;
                };

                &:last-of-type {
                    border-radius: 0 8px 8px 0;
                    padding-right: 40px;
                };
            };

            &.invalid-row {
                & > th {
                    color: #E0758A;
                }
                & > td {
                    color: #858585;
                    background: #FFE0E6 0% 0% no-repeat padding-box;
                }
            }
            & > td.invalid-column {
                color: #E0758A;
                font-weight: bold;
            }
        }
    `
};

export const ImportUsersTable = ({ headers, data }) => {
    const $forceRefresh = useHook(null);
    const thRef = useRef(null);

    const headerKeys = Object
        .keys(headers);

    const missingHeaders = headerKeys.filter(x => !Object.keys(data[0] || {}).some(y => x === y))

    const shadowStyle = useMemo(() => ({
        width: `calc(100% - ${thRef.current?.clientWidth || 120}px)`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [thRef.current?.clientWidth, $forceRefresh.value]);

    useEffect(() => {
        $forceRefresh.set({});
    }, []);

    useOnResize(() => $forceRefresh.set({}), thRef);

    return (
        <table css={importTableStyles.table}>
            <thead>
                <tr>
                    <th className="row-number" ref={thRef}>
                        <span className="visually-hidden">File Row</span>
                    </th>
                    {headerKeys.map((key, keyIndex) => {
                        const hasHeader = Boolean(headers[key]);
                        const header = hasHeader ? headers[key] : headers[missingHeaders.pop()];
                        return (
                            <th key={`${key}_${keyIndex}`} scope="col">{header}</th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={`${row}_${rowIndex}`} className={row.isValid ? 'valid-row' : 'invalid-row'}>
                        <th scope="row">{row.rowNumber + '.'}<div className="shadow-box disable-on-safari" style={shadowStyle}></div></th>
                        {Object.keys(row).map((key, keyIndex) => (
                            (key !== 'rowNumber' && key !== 'isValid') &&
                            <td key={`${key}_${keyIndex}`} className={row[key]?.isValid ? '' : 'invalid-column'}>
                                {row[key].value ?? '--'}
                            </td>
                        )
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
