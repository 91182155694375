// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Input, TextareaInput } from '../CoreComponents/Input';
import { RadioGroup, RadioOption } from '../CoreComponents/RadioSelection';
import { ForceValidateContext, useHook } from '../CoreComponents/Utils';
import { minLengthValidator } from '../Utils/Validators';
import ChartData from './ChartData.json';
import BaseActivitySettings from './BaseActivitySettings.json';
import { ActivitySettingsTable } from '../CoreComponents/ActivitySettingsTable';
import { RadarChart } from '../CoreComponents/ChartComponents/RadarChart';
import { LineChart } from '../CoreComponents/ChartComponents/LineChart';
import { BarChart } from '../CoreComponents/ChartComponents/BarChart';
import { SingleSelect } from '../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../CoreComponents/SelectListComponents/SelectOption';
import { MultiSelect, MultiSelectOption } from '../CoreComponents/SelectListComponents/MultiSelect';


const Options = [...Array(5)].map((_, i) => ({ value: i + 1, label: `Option ${i + 1}` }));

const samplesStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: auto;
        // background-color: white;

        & hr {
            width: 95%;
            margin: 1em auto;
        }

        & .row {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-basis: 100%;
            padding: 0 1em;
        }
    `,
    chart: css`
        width: 512px;
        height: 512px;
    `,
};
export const Samples = () => {
    const $textInputWithoutValidation = useHook('');
    const $textInputWithValidation = useHook('', minLengthValidator(5));
    const $radioValue = useHook(0);
    const $singleSelectValue = useHook(0);
    const $singleSelectObjectValue = useHook(null);
    const $multiSelectValue = useHook([]);
    const $multiSelectObjectValue = useHook([]);
    const $activitySettings = useHook(BaseActivitySettings.slice(0, 8));

    return (
        <div css={samplesStyles.root}>
            <div className="row">
                <pre>
                    {JSON.stringify($activitySettings.value, null, 4)}
                </pre>
                <div>
                    <ActivitySettingsTable settings={$activitySettings.value} onChange={$activitySettings.set} />
                </div>
            </div>
            <div className="row">
                <pre>
                    {JSON.stringify($textInputWithoutValidation.value, null, 4)}
                </pre>
                <div>
                    Without validation
                    <Input label="Input label" placeholder="Type here..." $value={$textInputWithoutValidation} />
                    <TextareaInput
                        textAreaProps={{style: { minHeight: 150 }}}
                        label="TextareaInput label"
                        placeholder="Type here..."
                        $value={$textInputWithoutValidation}
                    />
                </div>

                <pre>
                    {JSON.stringify($textInputWithValidation.value, null, 4)}
                </pre>
                <div>
                    With validation
                    <ForceValidateContext.Provider value={true}>
                        <Input label="Input label" placeholder="Type here..." $value={$textInputWithValidation} />
                        <TextareaInput
                            textAreaProps={{style: { minHeight: 150 }}}
                            label="TextareaInput label"
                            placeholder="Type here..."
                            $value={$textInputWithValidation}
                        />
                    </ForceValidateContext.Provider>
                </div>
            </div>
            <div className="row">
                <pre>
                    {JSON.stringify(ChartData, null, 4)}
                </pre>
                <div>
                    <div css={samplesStyles.chart}>
                        <RadarChart datasets={ChartData} />
                    </div>
                    <div css={samplesStyles.chart}>
                        <LineChart datasets={ChartData} />
                    </div>
                    <div css={samplesStyles.chart}>
                        <BarChart datasets={ChartData} />
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
                <RadioGroup $value={$radioValue} label="Radio">
                    <RadioOption value={1} label="Option 1" />
                    <RadioOption value={2} label="Option 2" />
                </RadioGroup>
                <RadioGroup $value={$radioValue} label="Radio required" required>
                    <RadioOption value={1} label="Option 1" />
                    <RadioOption value={2} label="Option 2" />
                </RadioGroup>
                <RadioGroup $value={$radioValue} label="Radio disabled" disabled>
                    <RadioOption value={1} label="Option 1" />
                    <RadioOption value={2} label="Option 2" />
                </RadioGroup>
            </div>
            <hr />

            <div className="row">
                <pre>
                    {$singleSelectValue.value}
                </pre>
                <SingleSelect $value={$singleSelectValue} label="Single select">
                    {Options.map(o => <SelectOption key={o.value} value={o.value}>{o.label}</SelectOption>)}
                </SingleSelect>
                Object as value
                <pre>
                    {JSON.stringify($singleSelectObjectValue.value, null, 4)}
                </pre>
                <SingleSelect $value={$singleSelectObjectValue} label="Single select">
                    {Options.map(o => <SelectOption key={o.value} value={o}>{o.label}</SelectOption>)}
                </SingleSelect>
            </div>
            <hr />

            <div className="row">
                <pre>
                    {JSON.stringify($multiSelectValue.value)}
                </pre>
                <MultiSelect $value={$multiSelectValue} label="Multiselect">
                    {Options.map(o => <MultiSelectOption key={o.value} value={o.value}>{o.label}</MultiSelectOption>)}
                </MultiSelect>
                Object as value
                <pre>
                    {JSON.stringify($multiSelectObjectValue.value, null, 4)}
                </pre>
                <MultiSelect $value={$multiSelectObjectValue} label="Multiselect">
                    {Options.map(o => <MultiSelectOption key={o.value} value={o}>{o.label}</MultiSelectOption>)}
                </MultiSelect>
            </div>
        </div>
    );
};
