import { configureStore } from '@reduxjs/toolkit'
import storeStateReducer from '../globalStates/storeState';
import tableSortStateReducer from '../globalStates/tableSortState';
import dashboardStateReducer from '../globalStates/dashboardState';
import tableDataStateReducer from '../globalStates/tableDataState';
import libraryStateReducer from '../globalStates/libraryState';
import overviewStateReducer from '../globalStates/overviewState';
import analyticsStateReducer from '../globalStates/analyticsState';
import { reduxDevToolsHistoryLimit } from '../Utils/Constants';

const isDevelopment = process.env.NODE_ENV !== 'production';

export default configureStore({
  reducer: {
    storeState: storeStateReducer,
    tableSortState: tableSortStateReducer,
    dashboardState: dashboardStateReducer,
    tableDataState: tableDataStateReducer,
    libraryState: libraryStateReducer,
    overviewState: overviewStateReducer,
    analyticsState: analyticsStateReducer,
  },
    devTools: isDevelopment &&
    {
      maxAge: reduxDevToolsHistoryLimit,
    }
})
