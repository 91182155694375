/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { IconButton } from "../CoreComponents/Button"
import { Footer } from "../CoreComponents/Layout"
import { TrazerLogoSmall } from "../CoreComponents/CustomIcons"
import { useTranslation } from "../CoreComponents/Translation"

const style = css`
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;

    & > .versions {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & > h2 {
            text-align: center;
            font: normal normal bold 48px/24px Mustica Pro;
            letter-spacing: 0.43px;
            color: #929091;
            opacity: 1;
        }

        & > .buttons {
            display: flex;

            & > a {
                display: flex;
                flex-direction: column;
                text-align: center;
                font: normal normal bold 24px/24px Mustica Pro;
                letter-spacing: 0.22px;
                color: #929091;
                border-radius: 8px;

                & > svg {
                    width: 200px;
                    height: 200px;
                    border-radius: 8px;
                    margin-bottom: 14px;
                }
            }

            & > a: first-of-type > svg {
                background-color: white;
            }

            & > a: last-of-type > svg {
                background-color: black;
                & > g {
                    fill: white;
                }
            }
        }
    }

    & > .footer {
        grid-column-start: 1;
        grid-column-end: -1;

    }
`

export const PortalVersionPage = () => {
    const {t} = useTranslation();
    return (
        <div css={style}>
            <div className="versions">
                <h2>{t('portalVersion.title')}</h2>
                <div className="buttons">
                    <IconButton href="https://portal.trazer.com">
                        <TrazerLogoSmall></TrazerLogoSmall>
                        TRAZER
                    </IconButton>
                    <IconButton href="/overview">
                        <TrazerLogoSmall></TrazerLogoSmall>
                        TRAZER XP
                    </IconButton>
                </div>
            </div>
            <Footer className="footer"></Footer>
        </div>
    )
}
