/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Button } from '../../CoreComponents/Button';
import { Card } from '../../CoreComponents/Card';
import { TableCSF } from '../../CoreComponents/TableComponents/TableCSF';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook } from '../../CoreComponents/Utils';
import { NoData } from '../../Utils/Constants';
import { DistributionChart } from '../../CoreComponents/ChartComponents/DistributionChart';
import { LineChart } from '../../CoreComponents/ChartComponents/LineChart';
import { shortTime } from '../../Utils/Common';

const kinematicsTabStyles = {
    root: css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    `,
    tabSelection: css`
        border: 2px solid #00ABA5;
        border-radius: 8px;
        padding: 2px;
        margin: 40px auto 20px auto;
        width: 256px;

        & > button {
            border-radius: 4px;
            width: 50%;
            min-width: unset;
        }
    `,
    tabContent: css`
        height: calc(100% - 80px);
        overflow: auto;
    `,
    graphTabContent: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 32px 0 12px;
        height: 100%;

        & > * {
            max-height: 600px;
        }
    `,
};

const SubTabs = {
    Graphs: 1,
    Table: 2
};

export const SubTabsTranslationKeysArr = [
    { value: SubTabs.Graphs, translationKey: 'analytics.subTabs.graphs' },
    { value: SubTabs.Table, translationKey: 'analytics.subTabs.table' },
];

export const KinematicsTab = ({ reportData }) => {
    const { t } = useTranslation();
    const $selectedTab = useHook(SubTabs.Graphs);

    const chartData = useMemo(() => {
        const { charts } = reportData.kinematics;

        const trend = {
            label: 'trend-label',
            data: [],
            exportSettings: {
                xAxis: t('analytics.chartExportDataField.date'),
                yAxis: t('analytics.charts.reactionTimeTrend'),
            },
        };

        return {
            neckRotationDistribution: charts.neckRotation.distribution,
            neckRotationTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsNeckRotationTrend'),
                data: charts.neckRotation.trend
            }],
            spineFlexionDistribution: charts.spineFlexion.distribution,
            spineFlexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsSpineFlexionTrend'),
                data: charts.spineFlexion.trend
            }],
            spineRotationDistribution: charts.spineRotation.distribution,
            spineRotationTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsSpineRotationTrend'),
                data: charts.spineRotation.trend
            }],
            leftShoulderFlexionDistribution: charts.leftShoulderFlexion.distribution,
            leftShoulderFlexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsLeftShoulderFlexionTrend'),
                data: charts.leftShoulderFlexion.trend
            }],
            rightShoulderFlexionDistribution: charts.rightShoulderFlexion.distribution,
            rightShoulderFlexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsRightShoulderFlexionTrend'),
                data: charts.rightShoulderFlexion.trend
            }],
            leftShoulderAbductionDistribution: charts.leftShoulderAbduction.distribution,
            leftShoulderAbductionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsLeftShoulderAbductionTrend'),
                data: charts.leftShoulderAbduction.trend
            }],
            rightShoulderAbductionDistribution: charts.rightShoulderAbduction.distribution,
            rightShoulderAbductionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsRightShoulderAbductionTrend'),
                data: charts.rightShoulderAbduction.trend
            }],
            leftElbowFlexionDistribution: charts.leftElbowFlexion.distribution,
            leftElbowFlexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematics '),
                data: charts.leftElbowFlexion.trend
            }],
            rightElbowFlexionDistribution: charts.rightElbowFlexion.distribution,
            rightElbowFlexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsRightElbowFlexionTrend'),
                data: charts.rightElbowFlexion.trend
            }],

            trunkLeanDistribution: charts.trunkLean.distribution,
            trunkLeanTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsTrunkLeanTrend'),
                data: charts.trunkLean.trend
            }],
            pelvicTiltDistribution: charts.pelvicTilt.distribution,
            pelvicTiltTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsPelvicTiltTrend'),
                data: charts.pelvicTilt.trend
            }],
            pelvicRotationDistribution: charts.pelvicRotation.distribution,
            pelvicRotationTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsPelvicRotationTrend'),
                data: charts.pelvicRotation.trend
            }],
            shoulderRotationDistribution: charts.shoulderRotation.distribution,
            shoulderRotationTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsShoulderRotationTrend'),
                data: charts.shoulderRotation.trend
            }],
            thoracicRotationDistribution: charts.thoracicRotation.distribution,
            thoracicRotationTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsThoracicRotationTrend'),
                data: charts.thoracicRotation.trend
            }],
            lumbarRotationDistribution: charts.lumbarRotation.distribution,
            lumbarRotationTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsLumbarRotationTrend'),
                data: charts.lumbarRotation.trend
            }],
            leftHipRotationDistribution: charts.leftHipRotation.distribution,
            leftHipRotationTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsLeftHipRotationTrend'),
                data: charts.leftHipRotation.trend
            }],
            rightHipRotationDistribution: charts.rightHipRotation.distribution,
            rightHipRotationTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsRightHipRotationTrend'),
                data: charts.rightHipRotation.trend
            }],
            leftKneeValgusVarusDistribution: charts.leftKneeValgusVarus.distribution,
            leftKneeValgusVarusTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsLeftKneeValgusVarusTrend'),
                data: charts.leftKneeValgusVarus.trend
            }],
            rightKneeValgusVarusDistribution: charts.rightKneeValgusVarus.distribution,
            rightKneeValgusVarusTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsRightKneeValgusVarusTrend'),
                data: charts.rightKneeValgusVarus.trend
            }],
            leftKneeFlexionDistribution: charts.leftKneeFlexion.distribution,
            leftKneeFlexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsLeftKneeFlexionTrend'),
                data: charts.leftKneeFlexion.trend
            }],
            rightKneeFlexionDistribution: charts.rightKneeFlexion.distribution,
            rightKneeFlexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsRightKneeFlexionTrend'),
                data: charts.rightKneeFlexion.trend
            }],
            leftAnkleDorsiflexionDistribution: charts.leftAnkleDorsiflexion.distribution,
            leftAnkleDorsiflexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsLeftAnkleDorsiflexionTrend'),
                data: charts.leftAnkleDorsiflexion.trend
            }],
            rightAnkleDorsiflexionDistribution: charts.rightAnkleDorsiflexion.distribution,
            rightAnkleDorsiflexionTrend: [{
                ...trend,
                label: t('analytics.charts.kinematicsRightAnkleDorsiflexionTrend'),
                data: charts.rightAnkleDorsiflexion.trend
            }],
        }
    }, [t, reportData.kinematics]);

    const columnsWithData = useMemo(() => {
        const columnsWithData = {};
        const { table } = reportData.kinematics;
        for (const row of table) {
            for (const key in row) {
                if (row[key] != null && row[key] !== NoData) {
                    columnsWithData[key] = true;
                }
            }
        }

        return columnsWithData;
    }, [reportData.kinematics]);

    const headCells = useMemo(() => {
        const headCells = [
            {
                id: 'date',
                label: t('analytics.table.date'),
                columnWidth: 150,
                isSortable: true,
                CellRender: ({ rowData }) => {
                    return rowData.date ? shortTime(rowData.date) : '-';
                }
            },
            {
                id: 'fullName',
                label: t('analytics.table.fullName'),
                columnWidth: 150,
                isSortable: true
            },
            {
                id: 'username',
                label: t('analytics.table.username'),
                columnWidth: 150,
                isSortable: true
            },
            {
                id: 'scriptedActivity',
                label: t('analytics.table.scriptedActivity'),
                columnWidth: 200,
                isSortable: true
            },
        ];

        const possibleHeaders = [
            // lower body
            {
                id: 'plantFoot',
                label: t('analytics.table.plantFoot'),
                isSortable: true,
                columnWidth: 150,
            },
            {
                id: 'stanceWidthRatio',
                label: t('analytics.table.stanceWidthRatio'),
                isSortable: true,
                columnWidth: 200,
            },
            {
                id: 'stanceWidthDistance',
                label: t('analytics.table.stanceWidthDistance'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'trunkLean',
                label: t('analytics.table.trunkLean'),
                isSortable: true,
                columnWidth: 150,
            },
            {
                id: 'pelvicTilt',
                label: t('analytics.table.pelvicTilt'),
                isSortable: true,
                columnWidth: 150,
            },
            {
                id: 'pelvicRotation',
                label: t('analytics.table.pelvicRotation'),
                isSortable: true,
                columnWidth: 150,
            },
            {
                id: 'shoulderRotation',
                label: t('analytics.table.shoulderRotation'),
                isSortable: true,
                columnWidth: 150,
            },
            {
                id: 'thoracicRotation',
                label: t('analytics.table.thoracicRotation'),
                isSortable: true,
                columnWidth: 150,
            },
            {
                id: 'lumbarRotation',
                label: t('analytics.table.lumbarRotation'),
                isSortable: true,
                columnWidth: 150,
            },
            {
                id: 'rightHipRotation',
                label: t('analytics.table.rightHipRotation'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'rightKneeValgusVarus',
                label: t('analytics.table.rightKneeValgusVarus'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'rightKneeFlexion',
                label: t('analytics.table.rightKneeFlexion'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'rightAnkleDorsiflexion',
                label: t('analytics.table.rightAnkleDorsiflexion'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'leftHipRotation',
                label: t('analytics.table.leftHipRotation'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'leftKneeValgusVarus',
                label: t('analytics.table.leftKneeValgusVarus'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'leftKneeFlexion',
                label: t('analytics.table.leftKneeFlexion'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'leftAnkleDorsiflexion',
                label: t('analytics.table.leftAnkleDorsiflexion'),
                isSortable: true,
                columnWidth: 240,
            },

            // upper body
            {
                id: 'neckRotation',
                label: t('analytics.table.neckRotation'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'spineFlexion',
                label: t('analytics.table.spineFlexion'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'spineRotation',
                label: t('analytics.table.spineRotation'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'leftShoulderFlexion',
                label: t('analytics.table.leftShoulderFlexion'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'rightShoulderFlexion',
                label: t('analytics.table.rightShoulderFlexion'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'leftShoulderAbduction',
                label: t('analytics.table.leftShoulderAbduction'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'rightShoulderAbduction',
                label: t('analytics.table.rightShoulderAbduction'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'leftElbowFlexion',
                label: t('analytics.table.leftElbowFlexion'),
                isSortable: true,
                columnWidth: 240,
            },
            {
                id: 'rightElbowFlexion',
                label: t('analytics.table.rightElbowFlexion'),
                isSortable: true,
                columnWidth: 240,
            },
        ];

        for (const header of possibleHeaders) {
            if (columnsWithData[header.id]) {
                headCells.push(header);
            }
        }

        headCells.push({
            id: 'quick-fix',
            label: '',
            CellRender: () => null
        });
        return headCells;
    }, [t, reportData.kinematics]);

    const unitType = ''; // t('constants.units.degrees');
    const unitTypeShort = t('constants.units.degrees');

    const { table } = reportData.kinematics;
    return (
        <div css={kinematicsTabStyles.root}>
            <div css={kinematicsTabStyles.tabContent}>
                {$selectedTab.value === SubTabs.Graphs ?
                    <div css={kinematicsTabStyles.graphTabContent}>
                        {chartData.neckRotationDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsNeckRotationDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsNeckRotationDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicNeckRotationDistribution')}
                                    enableOptionsMenu
                                    data={chartData.neckRotationDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsNeckRotationDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.neckRotationTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsNeckRotationTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsNeckRotationTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsNeckRotationTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.neckRotationTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsNeckRotationTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.spineFlexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsSpineFlexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsSpineFlexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicSpineFlexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.spineFlexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsSpineFlexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.spineFlexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsSpineFlexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsSpineFlexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsSpineFlexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.spineFlexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsSpineFlexionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.spineRotationDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsSpineRotationDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsSpineRotationDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicSpineRotationDistribution')}
                                    enableOptionsMenu
                                    data={chartData.spineRotationDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsSpineRotationDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.spineRotationTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsSpineRotationTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsSpineRotationTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsSpineRotationTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.spineRotationTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsSpineRotationTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftShoulderFlexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsLeftShoulderFlexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsLeftShoulderFlexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicLeftShoulderFlexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.leftShoulderFlexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsLeftShoulderFlexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftShoulderFlexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsLeftShoulderFlexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsLeftShoulderFlexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsLeftShoulderFlexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.leftShoulderFlexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsLeftShoulderFlexionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightShoulderFlexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsRightShoulderFlexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsRightShoulderFlexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicRightShoulderFlexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.rightShoulderFlexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsRightShoulderFlexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightShoulderFlexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsRightShoulderFlexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsRightShoulderFlexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsRightShoulderFlexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.rightShoulderFlexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsRightShoulderFlexionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftShoulderAbductionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsLeftShoulderAbductionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsLeftShoulderAbductionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicLeftShoulderAbductionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.leftShoulderAbductionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsLeftShoulderAbductionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftShoulderAbductionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsLeftShoulderAbductionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsLeftShoulderAbductionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsLeftShoulderAbductionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.leftShoulderAbductionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsLeftShoulderAbductionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightShoulderAbductionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsRightShoulderAbductionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsRightShoulderAbductionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicRightShoulderAbductionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.rightShoulderAbductionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsRightShoulderAbductionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightShoulderAbductionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsRightShoulderAbductionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsRightShoulderAbductionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsRightShoulderAbductionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.rightShoulderAbductionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsRightShoulderAbductionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftElbowFlexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsLeftElbowFlexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsLeftElbowFlexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicLeftElbowFlexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.leftElbowFlexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsLeftElbowFlexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftElbowFlexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsLeftElbowFlexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsLeftElbowFlexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsLeftElbowFlexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.leftElbowFlexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsLeftElbowFlexionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightElbowFlexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsRightElbowFlexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsRightElbowFlexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicRightElbowFlexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.rightElbowFlexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsRightElbowFlexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightElbowFlexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsRightElbowFlexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsRightElbowFlexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsRightElbowFlexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.rightElbowFlexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsRightElbowFlexionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.trunkLeanDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsTrunkLeanDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsTrunkLeanDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsTrunkLeanDistribution')}
                                    enableOptionsMenu
                                    data={chartData.trunkLeanDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsTrunkLeanDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.trunkLeanTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsTrunkLeanTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsTrunkLeanTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsTrunkLeanTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.trunkLeanTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsTrunkLeanTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.pelvicTiltDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsPelvicTiltDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsPelvicTiltDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsPelvicTiltDistribution')}
                                    enableOptionsMenu
                                    data={chartData.pelvicTiltDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsPelvicTiltDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.pelvicTiltTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsPelvicTiltTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsPelvicTiltTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsPelvicTiltTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.pelvicTiltTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsPelvicTiltTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.pelvicRotationDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsPelvicRotationDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsPelvicRotationDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsPelvicRotationDistribution')}
                                    enableOptionsMenu
                                    data={chartData.pelvicRotationDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsPelvicRotationDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.pelvicRotationTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsPelvicRotationTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsPelvicRotationTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsPelvicRotationTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.pelvicRotationTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsPelvicRotationTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.shoulderRotationDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsShoulderRotationDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsShoulderRotationDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsShoulderRotationDistribution')}
                                    enableOptionsMenu
                                    data={chartData.shoulderRotationDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsShoulderRotationDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.shoulderRotationTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsShoulderRotationTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsShoulderRotationTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsShoulderRotationTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.shoulderRotationTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsShoulderRotationTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.thoracicRotationDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsThoracicRotationDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsThoracicRotationDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsThoracicRotationDistribution')}
                                    enableOptionsMenu
                                    data={chartData.thoracicRotationDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsThoracicRotationDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.thoracicRotationTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsThoracicRotationTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsThoracicRotationTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsThoracicRotationTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.thoracicRotationTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsThoracicRotationTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.lumbarRotationDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsLumbarRotationDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsLumbarRotationDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsLumbarRotationDistribution')}
                                    enableOptionsMenu
                                    data={chartData.lumbarRotationDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsLumbarRotationDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.lumbarRotationTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsLumbarRotationTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsLumbarRotationTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsLumbarRotationTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.lumbarRotationTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsLumbarRotationTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftHipRotationDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsLeftHipRotationDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsLeftHipRotationDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsLeftHipRotationDistribution')}
                                    enableOptionsMenu
                                    data={chartData.leftHipRotationDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsLeftHipRotationDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftHipRotationTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsLeftHipRotationTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsLeftHipRotationTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsLeftHipRotationTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.leftHipRotationTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsLeftHipRotationTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightHipRotationDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsRightHipRotationDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsRightHipRotationDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsRightHipRotationDistribution')}
                                    enableOptionsMenu
                                    data={chartData.rightHipRotationDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsRightHipRotationDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightHipRotationTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsRightHipRotationTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsRightHipRotationTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsRightHipRotationTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.rightHipRotationTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsRightHipRotationTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftKneeValgusVarusDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsLeftKneeValgusVarusDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsLeftKneeValgusVarusDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsLeftKneeValgusVarusDistribution')}
                                    enableOptionsMenu
                                    data={chartData.leftKneeValgusVarusDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsLeftKneeValgusVarusDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftKneeValgusVarusTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsLeftKneeValgusVarusTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsLeftKneeValgusVarusTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsLeftKneeValgusVarusTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.leftKneeValgusVarusTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsLeftKneeValgusVarusTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightKneeValgusVarusDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsRightKneeValgusVarusDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsRightKneeValgusVarusDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsRightKneeValgusVarusDistribution')}
                                    enableOptionsMenu
                                    data={chartData.rightKneeValgusVarusDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsRightKneeValgusVarusDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightKneeValgusVarusTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsRightKneeValgusVarusTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsRightKneeValgusVarusTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsRightKneeValgusVarusTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.rightKneeValgusVarusTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsRightKneeValgusVarusTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftKneeFlexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsLeftKneeFlexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsLeftKneeFlexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsLeftKneeFlexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.leftKneeFlexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsLeftKneeFlexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftKneeFlexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsLeftKneeFlexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsLeftKneeFlexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsLeftKneeFlexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.leftKneeFlexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsLeftKneeFlexionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightKneeFlexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsRightKneeFlexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsRightKneeFlexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsRightKneeFlexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.rightKneeFlexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsRightKneeFlexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightKneeFlexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsRightKneeFlexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsRightKneeFlexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsRightKneeFlexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.rightKneeFlexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsRightKneeFlexionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftAnkleDorsiflexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsLeftAnkleDorsiflexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsLeftAnkleDorsiflexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsLeftAnkleDorsiflexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.leftAnkleDorsiflexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsLeftAnkleDorsiflexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.leftAnkleDorsiflexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsLeftAnkleDorsiflexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsLeftAnkleDorsiflexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsLeftAnkleDorsiflexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.leftAnkleDorsiflexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsLeftAnkleDorsiflexionTrend.yAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightAnkleDorsiflexionDistribution.length > 1 &&
                            <Card title={t('analytics.charts.kinematicsRightAnkleDorsiflexionDistribution')}>
                                <DistributionChart
                                    title={t('analytics.charts.kinematicsRightAnkleDorsiflexionDistribution')}
                                    unitType={unitType}
                                    unitTypeShort={unitTypeShort}
                                    exportFileName={t('analytics.charts.kinematicsRightAnkleDorsiflexionDistribution')}
                                    enableOptionsMenu
                                    data={chartData.rightAnkleDorsiflexionDistribution}
                                    bottomAxisTitle={t('analytics.charts.kinematicsRightAnkleDorsiflexionDistribution.xAxis')}
                                />
                            </Card>
                        }
                        {chartData.rightAnkleDorsiflexionTrend[0].data.length > 0 &&
                            <Card title={t('analytics.charts.kinematicsRightAnkleDorsiflexionTrend')}>
                                <LineChart
                                    title={t('analytics.charts.kinematicsRightAnkleDorsiflexionTrend')}
                                    unitType={unitType}
                                    exportFileName={t('analytics.charts.kinematicsRightAnkleDorsiflexionTrend')}
                                    enableOptionsMenu
                                    datasets={chartData.rightAnkleDorsiflexionTrend}
                                    xAxisLabel={t('analytics.charts.xAxis')}
                                    yAxisLabel={t('analytics.charts.kinematicsRightAnkleDorsiflexionTrend.yAxis')}
                                />
                            </Card>
                        }
                    </div>
                :
                <TableCSF
                    headCells={headCells}
                    rows={table}
                    totalItemsCount={table.length}
                    paginationSettingsPosition="bottom-left"
                    rowKeySelector={r => r.testStepId}
                    isLoading={false}
                    showAdvancedSearch={false}
                    showExport
                    exportFileNameGenerator={() => `kinematics-${(new Date()).toISOString().split('T')[0]}`}
                />
            }
            </div>
            <div css={kinematicsTabStyles.tabSelection}>
                {SubTabsTranslationKeysArr.map(x => (
                    <Button
                        key={x.value}
                        variant={$selectedTab.value === x.value ?
                            'contained'
                            : 'text'
                        }
                        onClick={() => {
                            $selectedTab.set(x.value);
                        }}
                    >
                        {t(x.translationKey)}
                    </Button>
                ))}
            </div>
        </div>
    );
};
