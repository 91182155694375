/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom";
import { ForceValidateContext, useHook, useObjectHook } from "../CoreComponents/Utils";
import { Input } from "../CoreComponents/Input";
import { Button } from "../CoreComponents/Button";
import { css } from "@emotion/react";
import { UserService } from "../Services/UserService";
import { TrazerLogo } from "../CoreComponents/CustomIcons";
import { requiredValidator } from "../Utils/Validators";
import { AlertText } from "../CoreComponents/Alert";
import { useTranslation } from "../CoreComponents/Translation";
import { useDispatch } from "react-redux";
import { setCurrentUser, setOriginalUser } from "../globalStates/storeState";

const style = {
    root: css`
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: black;
    `,
    content: css`
        background-color: white;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        width: 400px;
        height: 500px;
        border: 1px solid #black;
        overflow: hidden;
        align-items: center;

        & > .logo {
            margin-top: 40px;
            margin-bottom: 24px;
            width: calc(100% - 40px);
            height: 52px;
            color: black;
        }

        & > .actions {
            width: calc(100% - 40px);

            & > button {
                margin-top: 25px;
                width: 100%;
            }
        }

        & > .input-field {
            margin-bottom: 10px;
            box-sizing: border-box;
            width: calc(100% - 20px);
            padding: 8px;
        }
    `
};

const validators = {
    username: [requiredValidator],
    pin: [requiredValidator]
}

export const OfflineLoginPage = () => {
    const $disableLoginButton = useHook();
    const $isForceValidating = useHook(false);
    const $showAlert = useHook(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const $userData = useObjectHook({
        username: '',
        pin: ''
    }, validators)

    const login = () => {
        $isForceValidating.set(true);
        $disableLoginButton.set(true);
        $showAlert.set(false);

        const errors = $userData.validate();

        if (errors) {
            return;
        }

        UserService.signIn($userData.value.username, $userData.value.pin)
            .then(user => {
                if (user.username === $userData.value.username && user.pin === $userData.value.pin) {
                    dispatch(setCurrentUser(user));
                    dispatch(setOriginalUser(user));
                    navigate('/overview');
                }
                else {
                    $showAlert.set(true);
                }
            })
            .catch(() => $showAlert.set(true))
            .finally(() => $disableLoginButton.set(false));
    }

    return (
        <div css={style.root}>
            <ForceValidateContext.Provider value={$isForceValidating.value}>
                <div css={style.content}>
                    <TrazerLogo className="logo" />
                    <Input variant="outlined" className="input-field" label={t('login.username')} $value={$userData.getPropHook('username')} />
                    <Input variant="outlined" className="input-field" type="password" label={t('login.pin')} $value={$userData.getPropHook('pin')} />
                    <div className="actions">
                        {$showAlert.value && <AlertText variant="large">{t('login.wrongAlert')}</AlertText>}
                        <Button onClick={login} disabled={$disableLoginButton.value && !$userData.isValid}>
                            {t('actions.login')}
                        </Button>
                    </div>
                </div>
            </ForceValidateContext.Provider>
        </div>
    )
}