import { createSlice } from "@reduxjs/toolkit";

const initialOverviewState = {
    sessions: [],
    units: [],
    users: [],
    usersData: [],
    sessionsData: [],
}

export const overviewState = createSlice({
    name: 'overviewState',
    initialState: initialOverviewState,
    reducers: {
        setSessions: (state, action) => {
            state.sessions = action.payload;
        },
        setUnits: (state, action) => {
            state.units = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setUsersData: (state, action) => {
            state.usersData = action.payload;
        },
        setSessionsData: (state, action) => {
            state.sessionsData = action.payload;
        },
    }
})

export const {
    setSessions,
    setUnits,
    setUsers,
    setUsersData,
    setSessionsData
} = overviewState.actions;

export default overviewState.reducer;
