/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { Button, IconButton } from "../../../CoreComponents/Button";
import { EditIcon, TrashCanIcon } from "../../../CoreComponents/CustomIcons";
import { TableCSF } from "../../../CoreComponents/TableComponents/TableCSF";
import { useTranslation } from "../../../CoreComponents/Translation";
import { useHook } from "../../../CoreComponents/Utils";
import AddIcon from '@mui/icons-material/Add';
import { Modal } from "../../../CoreComponents/Modal";
import { AddGroupContent } from "./AddGroupContent";
import { GroupService } from "../../../Services/GroupService";
import { EditUserGroup } from "./EditUserGroup";
import { DeleteConfirmationModal } from "../../../CoreComponents/DeleteConfirmationModal";
import { AdminTypes, AdvancedSearchType, SortOrder } from "../../../Utils/Constants";
import { defaultSort } from '../../../Utils/Common';
import { useDispatch, useSelector } from "react-redux";
import { setUserGroups } from "../../../globalStates/tableDataState";

const groupsListStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}
export const UserGroups = () => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const isLessThenSuperAdmin = storeState.currentUser.admin.adminTypeId < AdminTypes.SuperAdmin;
    const isTrazerAdmin = storeState.currentUser.admin.adminTypeId > AdminTypes.SuperAdmin;
    const $selectedGroup = useHook(null);
    const $groups = useHook(tableDataState.userGroups);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $isAddGroupModalOpened = useHook(false);
    const $isLoading = useHook(!Boolean(tableDataState.userGroups.length));
    const $refreshGroups = useHook(false);
    const $groupIdToDelete = useHook(null)

    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <IconButton disabled={isTrazerViewAdmin} onClick={() => $selectedGroup.set(rowData)}>
                        <EditIcon />
                    </IconButton>
                );
            }
        },
        {
            id: 'groupName',
            label: t('userGroups.table.group'),
            isSortable: true,
        },
        {
            id: 'customerName',
            label: isTrazerAdmin || isTrazerViewAdmin ? t('userGroups.table.customer') : '',
            columnWidth: isTrazerAdmin || isTrazerViewAdmin ? 300 : 0,
            isSortable: isTrazerAdmin || isTrazerViewAdmin,
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($customers.value, 'value', SortOrder.Asc)
            },
            CellRender: ({ rowData }) => {
                return isTrazerAdmin || isTrazerViewAdmin ? rowData?.customerName || '-' : '';
            }
        },
        {
            id: 'siteName',
            label: t('userGroups.table.site'),
            isSortable: true,
            advancedSearch: {
                type: AdvancedSearchType.MultiSelect,
                label: '',
                values: defaultSort($sites.value, 'value', SortOrder.Asc)
            }
        },
        {
            id: 'delete',
            label: '',
            CellRender: ({ rowData }) => {
                return (
                    <IconButton disabled={isTrazerViewAdmin} onClick={() => $groupIdToDelete.set(rowData.groupId)} tooltip={t('actions.delete')}>
                        <TrashCanIcon />
                    </IconButton>
                );
            }
        },
    ], [t, $sites.value, $customers.value]);

    useEffect(() => {
        let siteIds = null;
        let customerIds = null

        if (!isTrazerAdmin) {
            if (isLessThenSuperAdmin) {
                siteIds = storeState.currentUser.admin.adminSites.map(site => site.siteId);
            } else {
                customerIds = [storeState.currentUser.admin.customerId];
            }
        }

        GroupService.getGroups(9999, customerIds, siteIds)
            .then(groups => {
                const customers = {};
                const sites = {};

                for (const group of groups) {
                    customers[group.site.customer.name] = 1;
                    sites[group.site.name] = 1;
                    group.customerName = group.site.customer.name || '-';
                    group.siteName = group.site.name || '-';
                }

                $customers.set(Object.keys(customers).map(x => ({value: x, label: x})))
                $sites.set(Object.keys(sites).map(x => ({value: x, label: x})))

                dispatch(setUserGroups(groups));
                $groups.set(groups);
                $isLoading.set(false);
            });
    }, [$refreshGroups.value, storeState.currentUser])

    const deleteGroup = groupId => {
        GroupService.deleteGroup(groupId)
            .then(() => {
                $refreshGroups.set({});
                $groupIdToDelete.set(null);
            })
    }

    return (
        <div css={groupsListStyles.root}>
            <TableCSF
                customActions={
                    <div css={groupsListStyles.actions}>
                        <Button disabled={isTrazerViewAdmin} onClick={() => $isAddGroupModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$groups.value}
                rowKeySelector={r => r.groupId}
                isLoading={$isLoading.value}
            />
            <Modal
                open={$isAddGroupModalOpened.value}
                onClose={() => $isAddGroupModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddGroupContent
                    onCancel={() => $isAddGroupModalOpened.set(false)}
                    onAdd={() => {
                        $isAddGroupModalOpened.set(false);
                        $refreshGroups.set({});
                    }}
                />
            </Modal>
            <Modal
                open={!!$selectedGroup.value}
                onClose={() => {
                    $selectedGroup.set(null);
                    $refreshGroups.set({});
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EditUserGroup
                    $selectedGroup={$selectedGroup}
                    enableGroupNameEdit
                />
            </Modal>
            <DeleteConfirmationModal
            onDelete={() => deleteGroup($groupIdToDelete.value)}
            open={Boolean($groupIdToDelete.value)}
            onCancel={() => $groupIdToDelete.set(null)}
            title={t('userGroups.deactivateModal.title')}
            description={t('userGroups.deactivateModal.description')}
            deleteLabel={t('actions.delete')}
        />
        </div>
    );
}
