/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
    ForceValidateContext,
    useHook,
    useObjectHook
} from '../../../CoreComponents/Utils';
import { newGUID } from '../../../Utils/Common';
import { CustomerService } from '../../../Services/CustomerService';
import { Input } from '../../../CoreComponents/Input';
import { Button } from '../../../CoreComponents/Button';
import {
    maxLengthValidator,
    requiredValidator,
} from '../../../Utils/Validators';
import { useTranslation } from '../../../CoreComponents/Translation';

const addCustomerModalContentStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        min-height: 340px;
        // height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .input-root {
            margin: 0;
            width: 100%;
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }
    `,
};

const CustomerValidator = {
       name: [requiredValidator, maxLengthValidator(50)],
};

export const AddCustomerContent = ({ onCancel, onAdd }) => {
    const { t } = useTranslation();
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const $newCustomer = useObjectHook(
        {
            customerId: newGUID(),
            name: '',
        },
        CustomerValidator,
        null,
        $isForceValidating.value
    );

    const addCustomer = () => {
        // if we want to validate after submission
        // then it should be set to false by default

        $isForceValidating.set(true);
        const errors = $newCustomer.validate();
        if (errors) {
            return;
        }

        const newCustomer = {
            ...$newCustomer.value,

            name: $newCustomer.value.name
        };
        $newCustomer.set(newCustomer);

        $isSaving.set(true);
        CustomerService.postCustomer(newCustomer)
            .then(() => onAdd(newCustomer))
            .finally(() => $isSaving.set(false));

    };

    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addCustomerModalContentStyles.root}>
                <h2>{t('customers.addModal.title')}</h2>

                <Input placeholder={t('input.placeholder')} required label={t('customers.fieldName.name')} $value={$newCustomer.getPropHook('name')} />

                <div className="actions" css={addCustomerModalContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newCustomer.isValid} onClick={addCustomer}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
};
