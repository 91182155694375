import { createSlice } from "@reduxjs/toolkit";

const initialLibraryState = {
    activities: []
}

export const libraryState = createSlice({
    name: 'libraryState',
    initialState: initialLibraryState,
    reducers: {
        setActivities: (state, action) => {
            state.activities = action.payload;
        },
    }
})

export const { setActivities } = libraryState.actions;

export default libraryState.reducer;
