/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css, Step, StepLabel, Stepper } from "@mui/material";
import { Button } from "../../../../CoreComponents/Button";
import { Switch } from "../../../../CoreComponents/Switch";
import { useTranslation } from "../../../../CoreComponents/Translation";
import { ForceValidateContext, useHook, useObjectHook } from "../../../../CoreComponents/Utils";
import { emailValidator, maxLengthValidator, minLengthValidator, phoneValidator, requiredValidator, usernameValidator } from "../../../../Utils/Validators";
import { useEffect, useMemo } from "react";
import { InternalLink } from "../../../../CoreComponents/Layout";
import { AdminTypes, AdminTypesTranslationKeysArr, GendersTranslationKeysObj, NoData } from "../../../../Utils/Constants";
import { parseCSV, shortDate, parseGender, parseHeight, parseWeight, twiceInArray } from "../../../../Utils/Common";
import { CircleCheckIcon } from "../../../../CoreComponents/CustomIcons";
import { AdminService } from "../../../../Services/AdminService";
import { TRAZStepConnector, TRAZStepIcon } from "../../../../CoreComponents/StepConnector"
import { FileUploadArea } from "../../../../CoreComponents/FileUploadArea";
import { ImportUsersTable } from "./ImportUsersTable";
import { AttachStep } from "./AttachStep";
import { useSelector } from "react-redux";
import { SingleSelect } from "../../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../../CoreComponents/SelectListComponents/SelectOption";

const steps = ['importUsers.steps.importFile', 'importUsers.steps.editEntries', 'importUsers.steps.attach'];

const importUsersStyle = {
    root: css`
        height: 100%;

        & > .horizontal-line {
            border-top: 2px solid rgb(211,211,211);
            padding: 0 70px;
            margin: auto;
            width: calc(100% - 160px);
        }

        & > .step-content {
            padding-right: 20px;
            overflow-y: auto;
            height: calc(100% - 160px);
            position: relative;
        }
    `,
    stepLabel: css`
        & .MuiStepLabel-labelContainer {
            position: absolute;
            top: -2.5em;
        }

        & .MuiStepLabel-label {
            &.Mui-completed {
                color: #858585;
            }

            &.Mui-active {
                color: #00ABA5;
            }
        }
    `,
    stepper: css`
        width: 680px;
    `,
    stepperContainer: css`
        justify-content: space-between;
        padding: 40px 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    `,
    navButtonsContainer: css`
        position: relative;
        width: 136px;

        & > button, & > div {
            position: absolute;
            transform: translateY(-50%);
        }

        & > div {
            width: max-content;
            right: 0;

            & button:last-of-type {
                margin-left: 20px;
            }
        }
    `,
    table: css`
        overflow-y: auto;
        height: calc(100% - 200px);
    `,
}

const invalidUsersListStyles = {
    root: css`
        padding: 30px;
        width: 100%;
        height: 100%;
    `,
    checkIcon: css`
        width: 15%;
        height: 15%;
        color: #00ABA5;
        & > path {
            stroke: #eeeeee;
            stroke-width: 30px;
        }
    `,
    errorMessageContainer: css`
        text-align: center;
        background: #FFE0E6 0% 0% no-repeat padding-box;
        width: 50%;
        max-width: 500px;
        margin: 0 auto;
        color: #E0758A;
        padding: 20px;
    `,
    successContainer: css`
        text-align: center;
    `,
    actions: css`
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
    contentContainer: css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        position: relative;

        & > table {
            margin-top: 40px;
        }

        & > button {
            position: absolute;
            top: 4em;
            right: 6em;
        }
    `
}

export const ImportAdmins = () => {
    const { t } = useTranslation();
    const $currentStep = useHook(0);
    const $file = useHook(null);
    const $disableNextButton = useHook(true);
    const $users = useHook([]);
    const $selectedSite = useHook(null, requiredValidator);
    const storeState = useSelector((state) => state.storeState);
    const $selectedCustomer = useHook(storeState.currentUser.admin?.customerId || null, requiredValidator);
    const $problematicUsers = useHook(null)
    const $selectedGroup = useHook(null);
    const $areHeadersIncluded = useHook(true);
    const $usersTableData = useHook([]);
    const headers = useMemo(() => ({
        username: t('users.table.username'),
        firstName: t('users.table.firstName'),
        lastName: t('users.table.lastName'),
        dateOfBirth: t('users.table.dateOfBirth'),
        height: t('users.table.height'),
        weight: t('users.table.weight'),
        gender: t('users.table.gender'),
        email: t('users.table.email'),
        phoneNumber: t('users.table.phoneNumber'),
        medicalRecordNumber: t('users.table.medicalRecordNumber')
    }), [t]);

    const addAdmins = () => {
        AdminService.importAdmins($users.value, $selectedCustomer.value, $selectedSite.value, $selectedGroup.value)
            .then(problematicUsers => {
                if (!problematicUsers || problematicUsers.length < 1) {
                    $problematicUsers.set([]);
                    return;
                }

                const failedUsers = problematicUsers.map(x => ({
                    ...x,
                    dateOfBirth: new Date(x.dateOfBirth)
                }));

                $problematicUsers.set(
                    failedUsers.map(x => ({
                        username: { value: x.username },
                        firstName: { value: x.firstName },
                        lastName: { value: x.lastName },
                        dateOfBirth: { value: shortDate(x.dateOfBirth) },
                        height: { value: x.height },
                        weight: { value: x.weight },
                        gender: { value: t(GendersTranslationKeysObj[x.gender]) },
                        email: { value: x.email },
                        phoneNumber: { value: x.phoneNumber },
                        medicalRecordNumber: { value: x.medicalRecordNumber },
                        isValid: false,
                        rowNumber: 1 + $users.value.findIndex(i =>
                            (i.username == null || i.username == x.username) &&
                            i.firstName == x.firstName &&
                            i.lastName == x.lastName &&
                            i.phoneNumber == x.phoneNumber &&
                            i.email == x.email &&
                            i.gender == x.gender &&
                            i.height == x.height &&
                            i.weight == x.weight &&
                            i.medicalRecordNumber == x.medicalRecordNumber &&
                            i.adminType == x.adminType
                        )
                    }))
                );
            })
    }

    return (
        <div css={importUsersStyle.root}>
            {!$problematicUsers.value ?
                <>
                    <div css={importUsersStyle.stepperContainer}>
                        <div css={importUsersStyle.navButtonsContainer}>
                            <Button
                                variant="outlined"
                                component={InternalLink}
                                to={`/manage/users`}
                            >
                                {t('actions.cancel')}
                            </Button>
                        </div>
                        <Stepper
                            css={importUsersStyle.stepper}
                            activeStep={$currentStep.value}
                            orientation="horizontal"
                            alternativeLabel
                            connector={<TRAZStepConnector />}
                        >
                            {steps.map((label) => {
                                const stepProps = {};
                                const labelProps = {
                                    StepIconProps: {
                                        icon: '',
                                        style: {
                                            zIndex: 1
                                        },

                                    },
                                    StepIconComponent: TRAZStepIcon
                                };
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel css={importUsersStyle.stepLabel} {...labelProps}>{t(label)}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div css={importUsersStyle.navButtonsContainer}>
                            <div>
                                <Button
                                    disabled={$currentStep.value === 0}
                                    onClick={() => {
                                        $currentStep.set(Math.max(0, $currentStep.value - 1));
                                        if ($currentStep.value === 1) {
                                            $file.set(null);
                                        }
                                    }}
                                >
                                    {t('actions.previous')}
                                </Button>
                                <Button
                                    disabled={$disableNextButton.value}
                                    onClick={() => {
                                        if ($currentStep.value === steps.length - 1) {
                                            addAdmins();
                                        }
                                        $currentStep.set(Math.min(steps.length - 1, $currentStep.value + 1));
                                    }}
                                >
                                    {$currentStep.value === steps.length - 1 ? t('actions.submit') : t('actions.next')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="horizontal-line"></div>
                    <div className="step-content">
                        {$currentStep.value === 0 &&
                            <UploadFileStep
                                $file={$file}
                                $disableNextButton={$disableNextButton}
                                $areHeadersIncluded={$areHeadersIncluded}
                            />}
                        {$currentStep.value === 1 &&
                            <EditEntriesStep
                                $file={$file}
                                $disableNextButton={$disableNextButton}
                                $users={$users}
                                $areHeadersIncluded={$areHeadersIncluded}
                                $usersTableData={$usersTableData}
                            />}
                        {$currentStep.value === 2 &&
                            <AttachStep
                                $disableNextButton={$disableNextButton}
                                $selectedSite={$selectedSite}
                                $selectedCustomer={$selectedCustomer}
                                $selectedGroup={$selectedGroup}
                            />}
                    </div>
                </>
                :
                <div css={invalidUsersListStyles.root}>
                    {$problematicUsers.value.length ?
                        <>
                            <div css={invalidUsersListStyles.contentContainer}>
                                <div css={invalidUsersListStyles.errorMessageContainer}>
                                    <p>{t('importUsers.wrongUsers.errorMessage')}</p>
                                </div>
                                <Button variant="outlined" onClick={() => {
                                    $problematicUsers.set(null);
                                    $currentStep.set(0);
                                    $file.set(null);
                                }}
                                >
                                    {t('actions.tryAgain')}</Button>
                                <ImportUsersTable
                                    headers={headers}
                                    data={$problematicUsers.value}
                                />
                            </div>
                        </>
                        :
                        <div css={invalidUsersListStyles.successContainer}>
                            <CircleCheckIcon css={invalidUsersListStyles.checkIcon} />
                            <p className="h2">{t('importUsers.successfullyAdded.successMessage')}</p>
                            <Button
                                variant="outlined"
                                component={InternalLink}
                                to={`/manage/users`}
                            >
                                {t('actions.backToManage')}
                            </Button>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

const uploadFileStepStyle = {
    page: css`
        display: flex;
        // min-height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,
    importText: css`
        margin-top: 78px;
        text-align: center;
        font: normal normal bold 24px/32px Mustica Pro;
        letter-spacing: 0.22px;
        color: #3A817F;
        `,
    mainContentContainer: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        & > div {
            width:600px;
            padding: 0 60px;
            & > * {
                color: #3A817F;
                font-weight: bold;
            }
        }
    `,
    switchLabel: css`
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        & > .switch {
            margin-right: 10px;
        }

    `
}

const fileTypes = ['.csv', '.txt'];

const UploadFileStep = ({ $file, $disableNextButton, $areHeadersIncluded }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);

    const adminTypes = useMemo(() =>
        AdminTypesTranslationKeysArr.filter(x => Number(x.value) !== AdminTypes.User && (x.value <= storeState.currentUser.admin.adminTypeId || Number(x.value) === AdminTypes.Operator))
            .map(x => t(x.translationKey).replaceAll(' ', ''))
            .reverse()
            .join(', ')
        , [t])

    return (
        <div css={uploadFileStepStyle.page}>
            <p css={uploadFileStepStyle.importText}>{t('importUsers.importFileStep.hint')}</p>
            <div css={uploadFileStepStyle.mainContentContainer}>
                <div>
                    <h3>{t('importUsers.importFileStep.unitFormatsTitle')}</h3>
                    <ul>
                        <li>{t('importUsers.importFileStep.unitFormatsFirstName')}</li>
                        <li>{t('importUsers.importFileStep.unitFormatsLastName')}</li>
                        <li>{t('importUsers.importFileStep.unitFormatsEmail')}</li>
                        <li>{t('importUsers.importFileStep.unitFormatsAdminType') + adminTypes + t('importUsers.importFileStep.unitFormatsAdminTypeDescription')}</li>
                        <li>{t('importUsers.importFileStep.unitFormatsDateOfBirth')}: MM/DD/YYYY</li>
                        <li>{t('importUsers.importFileStep.unitFormatsHeight')}: in, inch, inches, ft, feet, m, meters, cm, centimeters {t('importUsers.importFileStep.unitFormatsHeightDescription')}</li>
                        <li>{t('importUsers.importFileStep.unitFormatsWeight')}: lb, lbs, pounds, kg, kilograms {t('importUsers.importFileStep.unitFormatsWeightDescription')}</li>
                        <li>{t('importUsers.importFileStep.unitFormatsGender')}: M, F, NB, Male, Female, Non-binary</li>
                    </ul>
                    <h3>{t('importUsers.importFileStep.unitFormatsTitleOptional')}</h3>
                    <ul>
                        <li>{t('importUsers.importFileStep.unitFormatsUsername')}</li>
                        <li>{t('importUsers.importFileStep.unitFormatsPhoneNumber')}</li>
                        <li>{t('importUsers.importFileStep.unitFormatsMedicalRecordNumber')}</li>
                    </ul>

                    <h3>{t('importUsers.importFileStep.columnHeaderIncludedTitle')}</h3>

                    <Switch
                        label={t('importUsers.importFileStep.columnHeaderIncludedCheckbox')}
                        labelProps={{ css: uploadFileStepStyle.switchLabel }}
                        $value={$areHeadersIncluded}
                    />
                </div>
                <FileUploadArea
                    $file={$file}
                    $disableNextButton={$disableNextButton}
                    fileTypes={fileTypes}
                />
            </div>
        </div >
    );
};

const editEntriesStepStyle = {
    root: css`
        display: flex;
        flex-direction: column;
        height: 100%;
    `,
    messagesContainer: css`
        display: flex;
        justify-content: flex-end;
        margin-right: 80px;
    `,
    text: css`
        text-align: center;
        color: #3A817F;
        font: normal normal bold 24px/32px Mustica Pro;
        letter-spacing: 0.22px;
        margin: 78px auto;
    `,
    errorContainer: css`
        width: 20%;
        background: #FFE0E6 0% 0% no-repeat padding-box;
        box-shadow: 0px 8px 16px #28326529;
        border-radius: 8px;
        margin-top: 30px;
        position: absolute;
        top: 0;
        right: 5em;
        align-items: center;
        padding: 40px;
    `,
    errorMessage: css`
        text-align: left;
        font: normal normal normal 16px/24px Roboto;
        letter-spacing: 0.14px;
        margin: 0;
        color: #E0758A;
    `
}

const validators = {
    username: [minLengthValidator(6), maxLengthValidator(20), usernameValidator],
    firstName: [requiredValidator, maxLengthValidator(50)],
    lastName: [requiredValidator, maxLengthValidator(50)],
    email: [emailValidator, maxLengthValidator(50)],
    dateOfBirth: [requiredValidator],
    gender: [requiredValidator],
    height: [requiredValidator],
    weight: [requiredValidator],
    phoneNumber: [phoneValidator],
    medicalRecordNumber: [maxLengthValidator(50)],
    adminType: []
};

const EditEntriesStep = ({ $file, $disableNextButton, $users, $areHeadersIncluded, $usersTableData }) => {
    const storeState = useSelector((state) => state.storeState);
    const $fileResult = useHook(null);
    const $hasInvalidUsers = useHook(false);

    const { t } = useTranslation();
    const columnNames = useMemo(
        () => [...Array(10)].map((x, i) => `"${t('importUsers.columnTemplate', i + 1)}"`),
        [t]);

    const mappingOptions = useMemo(() => [
        { value: 'username', label: t('users.table.username') },
        { value: 'firstName', label: t('users.table.firstName') },
        { value: 'lastName', label: t('users.table.lastName') },
        { value: 'email', label: t('users.table.email') },
        { value: 'dateOfBirth', label: t('users.table.dateOfBirth') },
        { value: 'gender', label: t('users.table.gender') },
        { value: 'height', label: t('users.table.height') },
        { value: 'weight', label: t('users.table.weight') },
        { value: 'phoneNumber', label: t('users.table.phoneNumber') },
        { value: 'medicalRecordNumber', label: t('users.table.medicalRecordNumber') },
        { value: 'adminType', label: t('users.table.adminType') },
    ], [t])

    const $mappings = useObjectHook({
        column1: t('users.import.skip'),
        column2: t('users.import.skip'),
        column3: t('users.import.skip'),
        column4: t('users.import.skip'),
        column5: t('users.import.skip'),
        column6: t('users.import.skip'),
        column7: t('users.import.skip'),
        column8: t('users.import.skip'),
        column9: t('users.import.skip'),
        column10: t('users.import.skip'),
        column11: t('users.import.skip'),
    },
        null,
        (oldState, newState) => {
            const selectedMappings = Object.values(newState);
            const errorResult = {}
            let missingRequiredColumnsCount = 0;
            for (const key in validators) {
                if (!validators[key].includes(requiredValidator)) {
                    continue;
                }

                if (!selectedMappings.includes(key)) {
                    ++missingRequiredColumnsCount;
                }
            }

            for (const key in newState) {
                if (missingRequiredColumnsCount && newState[key] === t('users.import.skip')) {
                    errorResult[key] = ['Error'];
                } else if (newState[key] !== t('users.import.skip') && twiceInArray(selectedMappings, newState[key])) {
                    errorResult[key] = ['This entry already exists!'];
                }
            }

            return Object.keys(errorResult).length ? errorResult : null;
        },
        true
    );

    const adminTypes = useMemo(() =>
        AdminTypesTranslationKeysArr
            .filter(x => x.value <= storeState.currentUser.admin.adminTypeId || Number(x.value) === AdminTypes.Operator)
            .reduce((a, c) => ({
                ...a,
                [t(c.translationKey).replaceAll(' ', '').toLowerCase()]: c.value
            }), {})
        , [t])

    const $fileHeaders = useHook(null);

    const headers = useMemo(() => {
        $mappings.validate();

        return {
            column1: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[0] : false} $value={$mappings.getPropHook('column1')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column2: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[1] : false} $value={$mappings.getPropHook('column2')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column3: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[2] : false} $value={$mappings.getPropHook('column3')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column4: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[3] : false} $value={$mappings.getPropHook('column4')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column5: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[4] : false} $value={$mappings.getPropHook('column5')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column6: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[5] : false} $value={$mappings.getPropHook('column6')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column7: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[6] : false} $value={$mappings.getPropHook('column7')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column8: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[7] : false} $value={$mappings.getPropHook('column8')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column9: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[8] : false} $value={$mappings.getPropHook('column9')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column10: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[9] : false} $value={$mappings.getPropHook('column10')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            ),
            column11: (
                <SingleSelect label={$fileHeaders.value ? $fileHeaders.value[10] : false} $value={$mappings.getPropHook('column11')}>
                    <SelectOption key={0} value={t('users.import.skip')}>{t('users.import.skip')}</SelectOption>
                    {mappingOptions.map(x => (
                        <SelectOption key={x.value} value={x.value}>{x.label}</SelectOption>
                    ))}
                </SingleSelect>
            )
        };
    }, [t, $mappings.value, $fileResult.value]);

    useEffect(() => {
        parseCSV($file.value, !$areHeadersIncluded.value && columnNames)
            .then(result => {
                $fileResult.set({
                    data: result.data.map((val, index) => ({ ...val, rowNumber: index + 1 })),
                    errors: result.errors
                });
                $fileHeaders.set($areHeadersIncluded.value && result.data[0] ? Object.keys(result.data[0]) : null);
            })
    }, [columnNames])

    useEffect(() => {
        if (!$fileResult.value || !$fileResult.value.data) {
            return;
        }

        const users = [];
        const mapping = { ...$mappings.value };
        for (const row of $fileResult.value.data) {
            const rowRes = {
                rowNumber: row.rowNumber,
                isValid: true
            };

            const rowKeys = Object.keys(row).filter(x => x !== 'rowNumber');
            const mappingsKeys = Object.keys($mappings.value);
            for (let i = 0; i < mappingsKeys.length; i++) {
                const mappingColumn = mappingsKeys[i];
                const fileHeader = rowKeys[i] || mappingColumn;

                rowRes[mappingColumn] = {
                    value: null,
                    isValid: true
                }

                // eslint-disable-next-line no-loop-func
                const header = mappingOptions.find(x => x.label.toLowerCase() === fileHeader.toLowerCase());
                if (header) {
                    mapping[`column${i + 1}`] = header.value;
                } else {
                    rowRes.isValid = false;
                    rowRes[mappingColumn].value = row[fileHeader] || null;
                    rowRes[mappingColumn].isValid = false;
                    continue;
                }

                rowRes[mappingColumn].value = row[fileHeader] || null;
            }
            users.push(rowRes);
        }

        $mappings.set(mapping);

        const hasInvalidUser = Boolean(users.some(x => !x.isValid));
        $hasInvalidUsers.set(hasInvalidUser);
        $disableNextButton.set(hasInvalidUser);
        $usersTableData.set(users);
        $users.set(users);
    }, [$fileResult.value])

    useEffect(() => {
        const users = [...$usersTableData.value];
        let rowIndex = 0;
        let missingRequiredColumnsCount = 0;
        const selectedMappings = Object.values($mappings.value);
        for (const key in validators) {
            if (!validators[key].includes(requiredValidator)) {
                continue;
            }

            if (!selectedMappings.includes(key)) {
                ++missingRequiredColumnsCount;
            }
        }

        for (const row of users) {
            users[rowIndex].isValid = true;
            for (const mappingColumn in $mappings.value) {
                if ($mappings.value[mappingColumn] === t('users.import.skip') && missingRequiredColumnsCount > 0) {
                    users[rowIndex].isValid = false;
                    users[rowIndex][mappingColumn].isValid = false;
                    continue;
                }

                let isColumnValid = true;
                const key = $mappings.value[mappingColumn];
                if (Array.isArray(validators[key])) {
                    for (const validator of validators[key]) {
                        if (validator(row[mappingColumn].value).length) {
                            users[rowIndex].isValid = false;
                            isColumnValid = false;
                        }
                    }
                }

                users[rowIndex][mappingColumn].isValid = isColumnValid;
            }

            ++rowIndex;
        }

        const hasInvalidData = Boolean(users.some(x => !x.isValid));
        $hasInvalidUsers.set(hasInvalidData);
        $disableNextButton.set(!$mappings.isValid || hasInvalidData);
        $usersTableData.set(users);
        if (!hasInvalidData) {
            const mappedUsers = [];

            const mappingKeys = Object.keys($mappings.value);
            for (const row of users) {
                const user = {
                    rowNumber: row.rowNumber
                };
                for (const map of mappingKeys) {
                    user[$mappings.value[map]] = row[map].value;
                }

                mappedUsers.push(user);
            }

            $users.set(mappedUsers.map(u => ({
                ...u,
                username: u.username === NoData ? null : u.username,
                firstName: u.firstName,
                lastName: u.lastName,
                dateOfBirth: u.dateOfBirth,
                gender: u.gender ? parseGender(u.gender) : null,
                height: u.height ? parseHeight(u.height) : null,
                weight: u.weight ? parseWeight(u.weight) : null,
                phoneNumber: u.phoneNumber === NoData ? null : u.phoneNumber,
                medicalRecordNumber: u.medicalRecordNumber === NoData ? null : u.medicalRecordNumber,
                email: u.email === NoData ? null : u.email,
                adminType: !u.adminType || u.adminType === NoData ? 0 : adminTypes[u.adminType?.replaceAll(' ', '').toLowerCase()]
            })));
        } else {
            $users.set(users);
        }
    }, [$mappings.value, $mappings.isValid])

    return (
        <div css={editEntriesStepStyle.root}>
            <div css={editEntriesStepStyle.messagesContainer}>
                <p css={editEntriesStepStyle.text}>{t('importUsers.editEntriesStep.hint')}</p>
                {$hasInvalidUsers.value &&
                    <div css={editEntriesStepStyle.errorContainer}>
                        <p css={editEntriesStepStyle.errorMessage}>{t('importUsers.editEntriesStep.errorMessage')}</p>
                    </div>
                }
            </div>
            <div css={importUsersStyle.table}>
                <ForceValidateContext.Provider value={true}>
                    <ImportUsersTable
                        headers={headers}
                        data={$usersTableData.value}
                    />
                </ForceValidateContext.Provider>
            </div>
        </div>
    )
}
