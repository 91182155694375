// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route } from 'react-router';
import {
    Routes,
    BrowserRouter as Router,
    Navigate,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import './App.css';

import { useHook, getCurrentSearchParams } from './CoreComponents/Utils';

import { OverviewPage } from './Components/OverviewPage';
import { ManagePage } from './Components/Manage/ManagePage';
import { AnalyticsPage } from './Components/Analytics/AnalyticsPage';
import { LibraryPage } from './Components/Library/LibraryPage';
import { PrivacyPolicy } from './Components/PrivacyPolicy';
import { Support } from './Components/Support';
import { UsersImport } from './Components/Manage/Users/Import/UsersImport';
import { ImportAdmins } from './Components/Manage/Users/Import/ImportAdmins';

import { CircularLoader } from './CoreComponents/Loaders';
import { Layout } from './CoreComponents/Layout';
import { AdminTypes, AlertType, Auth0Settings, LogoutSettings, SentrySettings, InactivityLogoutTime } from './Utils/Constants';
import { UserService } from './Services/UserService';
import { TranslationProvider, useTranslation } from './CoreComponents/Translation';
import { Samples } from './CodeSamples/Samples';
import { TestReport } from './Components/Reports/TestReport/TestReport';
import { CustomerReport } from './Components/Reports/CustomerReport';
import { UserReport } from './Components/Reports/UserReport';
import { EditScriptedActivity } from './Components/Manage/ScriptedActivities/EditScriptedActivity';
import { Dashboard } from './Components/Dashboard';
import { AlertBar, AlertContext } from './CoreComponents/Alert';
import { newGUID } from './Utils/Common';
import { Button } from './CoreComponents/Button';
import { SiteReport } from './Components/Reports/SiteReport';
import { RegisterPage } from './Components/RegisterPage';
import { Modal } from './CoreComponents/Modal';
import { MandatoryAdminPinSetup } from './Components/MandatoryUserPinSetup';
import { PortalVersionPage } from './Components/PortalVersionPage';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setAuth0User, setCurrentUser, setOriginalUser } from './globalStates/storeState';
import { OfflineLoginPage } from './Components/OfflineLoginPage';

if (SentrySettings.isEnabled) {
    Sentry.init({
        dsn: SentrySettings.dsn,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: SentrySettings.tracesSampleRate
    });
}

const AppRoutes = SentrySettings.isEnabled ?
    Sentry.withSentryReactRouterV6Routing(Routes)
    : Routes;

const styles = {
    autoLogin: css`
        display: flex;
        width: 100vw;
        height: 100%;
    `,
    error: css`
        display: flex;
        flex-direction: column;
        margin: auto;

        & > .actions {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-top: 40px;
        }
    `
};

const AutoLoginComponent = ({ children }) => {
    const { user, error, isAuthenticated: isAuth0Authenticated, isLoading: isAuth0Loading, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
    const storeState = useSelector((state) => state.storeState);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const { staticAccessToken } = getCurrentSearchParams();
    const isLoading = staticAccessToken ? false : isAuth0Loading;
    const isAuthenticated = staticAccessToken ? true : isAuth0Authenticated;
    const isOfflineMode = !!process.env.REACT_APP_ISOFFLINE;

    useEffect(() => {
        if (staticAccessToken) {
            dispatch(setAccessToken(staticAccessToken));
        }
    }, [])

    useEffect(() => {
        if (!getAccessTokenSilently || !user?.sub || staticAccessToken) {
            return;
        }

        (async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: Auth0Settings.ApiAudience,
                    // audience: `https://${domain}/api/v2/`,
                    // scope: "read:current_user",
                });

                dispatch(setAccessToken(accessToken));
            } catch (e) {
                console.log(e.message);
            }
        })();
    }, [getAccessTokenSilently, user?.sub]);

    useEffect(() => {
        if (isLoading || error || staticAccessToken) {
            return;
        }

        if (!isAuthenticated && !isOfflineMode) {
            loginWithRedirect({
                redirectUri: window.location.origin,
                appState: {
                    returnTo: window.location.pathname + window.location.search,
                    callback: true
                },
            })
        }
        dispatch(setAuth0User(user));
    }, [isAuthenticated, isLoading]);

    useEffect(() => {
        if (localStorage.getItem('disable-auto-logout') || staticAccessToken) {
            return;
        }

        let logoutTimer = null;
        let lastActivity = Date.now();

        const timeoutInterval = LogoutSettings.AutoLogoutTimeMinutes * 60 * 1000;
        function autoLogoutHandle() {
            const timeLeftMilliseconds = timeoutInterval - (Date.now() - lastActivity);
            if (timeLeftMilliseconds <= 0) {
                logout({
                    returnTo: window.location.origin,
                });
            } else {
                logoutTimer = setTimeout(autoLogoutHandle, timeLeftMilliseconds);
            }
        };
        logoutTimer = setTimeout(autoLogoutHandle, timeoutInterval);

        const refreshTimeout = () => {
            lastActivity = Date.now();
        };

        document.addEventListener('mousemove', refreshTimeout);
        document.addEventListener('mousedown', refreshTimeout);
        document.addEventListener('mouseup', refreshTimeout);
        document.addEventListener('keydown', refreshTimeout);
        document.addEventListener('keyup', refreshTimeout);
        document.addEventListener('focus', refreshTimeout);
        document.addEventListener('mouseover', refreshTimeout);

        return () => {
            clearTimeout(logoutTimer);
            document.removeEventListener('mousemove', refreshTimeout);
            document.removeEventListener('mousedown', refreshTimeout);
            document.removeEventListener('mouseup', refreshTimeout);
            document.removeEventListener('keydown', refreshTimeout);
            document.removeEventListener('keyup', refreshTimeout);
            document.removeEventListener('focus', refreshTimeout);
            document.removeEventListener('mouseover', refreshTimeout);
        }
    }, [])

    return error ?
        <div css={styles.autoLogin}>
            <div css={styles.error}>
                <h1>{error.error_description}</h1>
                <div className="actions">
                    <Button
                        onClick={() => logout({ returnTo: window.location.origin })}
                    >
                        {t('actions.retry')}
                    </Button>
                </div>
            </div>
        </div>
        :
        (!isAuthenticated || isLoading || !storeState.accessToken) && !isOfflineMode ?
            <div css={styles.autoLogin}>
                <CircularLoader />
            </div>
            :
            children;
}

// In case we need this
const GuardedRoute = ({ hasAccess, children }) => (
    hasAccess ?
        children
        : <Navigate to="/forbidden" replace />
);

function App() {
    const [isFetchAttached, setIsFetchAttached] = useState(false);
    const storeState = useSelector((state) => state.storeState);
    const dispatch = useDispatch();
    const isOfflineMode = !!process.env.REACT_APP_ISOFFLINE;

    const { isUnityView: isUnityWebView } = getCurrentSearchParams();

    const $alerts = useHook([]);

    const makeAlert = (type, error, duration = 5000) => {
        const errorString = typeof (error) === 'string' ? error : JSON.stringify(error);
        $alerts.set(alerts => [
            ...alerts,
            { id: newGUID(), text: errorString, type, duration }
        ])
    }

    useEffect(() => {
        const originalFetch = window.fetch;
        const extendedFetch = function (input, init) {
            return originalFetch(input, init)
                .then(async resp => {
                    if (!resp.ok) {
                        const text = await resp.text();

                        let parsedError = '';
                        try {
                            const errorObj = JSON.parse(text)
                            if (Array.isArray(errorObj)) {
                                parsedError = errorObj;
                            } else if (typeof (errorObj) === 'object') {
                                parsedError = errorObj.title || text;
                            } else {
                                parsedError = errorObj;
                            }
                        } catch {
                            parsedError = text;
                        }

                        makeAlert(AlertType.Error, parsedError);
                        if (SentrySettings.isEnabled) {
                            Sentry.captureMessage(text, AlertType.Error);
                        }

                        switch (resp.status) {
                            case 401:
                                dispatch(setAuth0User(null));
                                // $snackbarMessage.set(parsedError);
                                throw new Error('Unauthorized');
                            case 413:
                                throw new Error('File size too large.');
                            default:
                                break;
                        }
                        // throw new Error(parsedError);
                        throw parsedError;
                    }

                    const contentLength = resp.headers.get('content-length');
                    if (contentLength === 0 || contentLength === '0') {
                        return null;
                    }

                    // parses JSON response into native JavaScript objects
                    return resp.json()
                        .catch(err => console.error('response parse error', input, 'error', err));
                },
                    err => {
                        // if fetch throws an error
                        //   $snackbarMessage.set(err.message);
                        console.error('fetch error', err);
                    });
        }
        window.extendedFetch = extendedFetch;
        if (isOfflineMode) {
            window.authorizedFetch = extendedFetch;
            setIsFetchAttached(true);
        }

        function setCssVars() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', vh + 'px');
        }

        function vuplexPrintCall() {
            if (window.vuplex?.postMessage && typeof (window.vuplex?.postMessage) === 'function') {
                window.vuplex.postMessage('FocusPrint');
            }
        }

        setCssVars();
        window.addEventListener('resize', setCssVars);
        window.addEventListener("beforeprint", vuplexPrintCall);
        return () => {
            window.removeEventListener('resize', setCssVars);
            window.removeEventListener('resize', vuplexPrintCall);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!storeState.accessToken) {
            return;
        }

        window.authorizedFetch = function (input, init) {
            const authorizedInit = {
                ...init,
                headers: {
                    ...init.headers,
                    'Authorization': `Bearer ${storeState.accessToken}`
                }
            };
            return window.extendedFetch(input, authorizedInit);
        }

        // setStoreState(state => (state.returnUrl = window.location.href.replace(window.location.origin, ''), { ...state }))
        setIsFetchAttached(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeState.accessToken]);

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }

    useEffect(() => {
        if (!isFetchAttached) {
            return;
        }
        if (isOfflineMode) {
            (async () => {
                try {
                    const myCookie = getCookie('user');
                    const userId = myCookie.split('%2C%20')[0].split('%3A')[1];
                    const currentUser = await UserService.getUser(userId);

                    dispatch(setCurrentUser(currentUser));
                    dispatch(setOriginalUser(currentUser));

                    if (localStorage.getItem('updateUser')) {
                        UserService.updateLastCloudLogin(currentUser.userId);
                        localStorage.removeItem('updateUser');
                    }
                } catch (e) {
                    console.log(e.message);
                }
            })();
        } else {
            (async () => {
                try {
                    const currentUser = await UserService.getMe();

                    dispatch(setCurrentUser(currentUser));
                    dispatch(setOriginalUser(currentUser));

                    if (localStorage.getItem('updateUser')) {
                        UserService.updateLastCloudLogin(currentUser.userId);
                        localStorage.removeItem('updateUser');
                    }
                } catch (e) {
                    console.log(e.message);
                }
            })();
        }
    }, [isFetchAttached]);

    const handleAlertClose = (event, reason, item) => {
        if (reason === 'clickaway') {
            return;
        }

        $alerts.set(alerts => alerts.filter(x => x.id !== item.id))
    };

    return (
        <div className={'main-container ' + (isUnityWebView ? 'unity-web-view ' : '') + (storeState.hideMainContent ? 'hide-main-content' : '')}>
            <TranslationProvider>
                <Router>
                    <AppRoutes>
                        <Route exact path="/user/register" element={<RegisterPage />} />
                        <Route exact path="/account/portalversion" element={<PortalVersionPage />} />
                        <Route exact path="/login" element={<OfflineLoginPage />} />
                        <Route exact path="/*" element={
                            <AutoLoginComponent>
                                {isFetchAttached && storeState.currentUser ?
                                    <AlertContext.Provider value={{
                                        alerts: $alerts.value,
                                        makeAlert
                                    }}>
                                        <Layout>
                                            <AppRoutes>
                                                <Route exact path="/library/*" element={<LibraryPage />} />
                                                <Route exact path="/reports/test/:testId" element={<TestReport />} />
                                                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                                                <Route exact path="/support" element={<Support />} />
                                                
                                                {storeState.currentUser.admin.adminTypeId !== AdminTypes.Operator && storeState.currentUser.admin.adminTypeId !== AdminTypes.User ?
                                                    <>
                                                        <Route exact path="/overview" element={<OverviewPage />} />
                                                        <Route exact path="/manage/*" element={<ManagePage />} />
                                                        <Route exact path="/analytics" element={<AnalyticsPage />} />
                                                        <Route exact path="/reports/customer/:customerId" element={<CustomerReport />} />
                                                        <Route exact path="/reports/user/:userId" element={<UserReport />} />
                                                        <Route exact path="/reports/site/:siteId" element={<SiteReport />} />
                                                        <Route exact path="/users/import" element={<UsersImport />} />
                                                        {storeState.currentUser.admin.adminTypeId !== AdminTypes.Provider &&
                                                            <Route exact path="/admins/import" element={<ImportAdmins />} />
                                                        }
                                                        <Route exact path="/dashboard" element={<Dashboard />} />
                                                        <Route exact path="/scripted-activity/edit/:scriptedActivityId" element={<EditScriptedActivity />} />
                                                        <Route exact path="/samples" element={<Samples />} />
                                                        <Route exact path="/*" element={<Navigate to="/overview" replace={true} />} />
                                                    </>
                                                    :
                                                    <>
                                                        <Route exact path="/*" element={<Navigate to="/library" replace={true} />} />
                                                    </>
                                                }
                                            </AppRoutes>
                                        </Layout>
                                        <Modal
                                            open={!storeState.currentUser.pin}
                                            disableCloseButton
                                            onClose={() => { }}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <MandatoryAdminPinSetup />
                                        </Modal>
                                        <AlertBar handleClose={handleAlertClose} />
                                    </AlertContext.Provider>
                                    : null}
                            </AutoLoginComponent>
                        } />
                    </AppRoutes>
                </Router>
            </TranslationProvider>
        </div>
    );
}

export default Sentry.withProfiler(App);
