/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button as ButtonBase, IconButton as IconButtonBase, Tooltip } from '@mui/material';
import { forwardRef } from "react";

const buttonBaseStyles = css`
    text-transform: none;
    font-size: 16px;
    letter-spacing: 0.14px;
    height: auto;
    min-width: 136px;
    min-height: 40px;
    box-shadow: none;
    border-radius: 8px;

    &.Mui-disabled.Mui-contained {
        color: #FFFFFF;
    }

    &.MuiButton-outlined {
        border: 2px solid #858585;
        color: #858585;
    }
`;

export const Button = forwardRef(function (props, ref) {
    return <ButtonBase ref={ref} css={buttonBaseStyles} variant="contained" {...props} />
});

export const IconButton = ({ tooltip, ...props }) =>
    props.disabled || !tooltip ?
        <IconButtonBase color="primary" {...props} />
        :
        <Tooltip title={tooltip}>
            <IconButtonBase color="primary" {...props} />
        </Tooltip>
