// ts-check
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useTranslation } from "../Translation";
import React, { useEffect, useRef } from "react";
import { useHook } from "../Utils";
import { NoData, chartStyles } from "../../Utils/Constants";
import { IconButton } from "@mui/material";
import { ChevronLeftIcon, ChevronRightIcon } from "../CustomIcons";

const KinematicCanvasRenderHeight = 700;
const kinematicChartStyles = {
    arrowButtons: css`
        font-size: 16px;
        width: 1.75em;
        height: 1.75em;

        background: #00ABA5 0% 0% no-repeat padding-box;
        color: #FFFFFF;

        &:hover {
            background: #00ABA5 0% 0% no-repeat padding-box;
            color: #FFFFFF;
        }

        &:disabled {
            background: #CBCBCB 0% 0% no-repeat padding-box;
            color: #FFFFFF;
        }

        @media print {
            display: none;
        }
    `,
    chartSelection: css`
        position: absolute;
        right: 40px;
        display: flex;
        align-items: center;
        gap: 10px;
    `,
    kinematicCanvas: css`
        opacity: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        overflow: hidden;
        position: absolute;
    `,
    container: css`
        display: flex;
        flex-direction: column;

        & > .kinematic-test-chart {
            position: relative;
            width: 100%;
            height: 100%;
        }

        & img {
            width: 100%;
            height: 100%;
            margin: 25px auto 5px auto;
            object-fit: scale-down;
            object-position: top;
            max-height: ${KinematicCanvasRenderHeight}px
        }

        @media print {
            & canvas {
                display: none;
            }
        }
    `,
};

export const KinematicChart = ({ isPrinting, datasets }) => {
    const { t } = useTranslation();
    const canvasesRef = useRef([]);
    const imagesRef = useRef([]);
    const containerRef = useRef(null);
    const backgroundImageRef = useRef(null);
    const $currentTestIndex = useHook(0);
    const currentIndex = $currentTestIndex.value;
    const $lastTestIndex = useHook(0);

    useEffect(() => {
        $currentTestIndex.set(0);
        if (datasets && datasets.length) {
            let lastTestIndex = 0;
            for (; lastTestIndex < datasets.length; ++lastTestIndex) {
                const ds = datasets[lastTestIndex];
                if (ds.isDifference) {
                    $lastTestIndex.set(lastTestIndex);
                    break;
                }
            }

            $lastTestIndex.set(lastTestIndex - 1);
        } else {
            $lastTestIndex.set(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasets]);

    const drawInfoBox = (ctx, x, y, lines, pivotPosition = 'top-left', scale = 1.0) => {
        ctx.save();

        const borderRadius = 8,
            borderColor = '#00ABA5',
            backgroundColor = '#00ABA5',
            textColor = '#FFFFFF';
        // =========================
        // Info box
        // =========================
        const fontSize = 16 * scale;
        const lineHeight = 24 * scale;
        const verticalSidePadding = 20 * scale;
        const horizontalSidePadding = 20 * scale;
        const lineOffset = lineHeight - fontSize;
        // not supported by the current canvas API
        // const letterSpacing = 0.14;

        ctx.font = `${fontSize}px/${lineHeight}px "Mustica Pro", sans-serif`;
        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/textBaseline
        ctx.textBaseline = 'ideographic';

        const textMaxWidth = Math.max(...lines.map(x => ctx.measureText(x).width));
        const rectWidth = textMaxWidth + 2 * horizontalSidePadding;
        const rectHeight = lines.length * fontSize + (lines.length - 1) * lineOffset + 2 * verticalSidePadding;

        let rectX = x;
        let rectY = y;
        const [verticalPosition, horizontalPosition] = pivotPosition.split('-');
        if (verticalPosition === 'middle') {
            rectY -= rectHeight / 2;
        } else if (verticalPosition === 'bottom') {
            rectY -= rectHeight;
        }
        if (horizontalPosition === 'center') {
            rectX -= rectWidth / 2;
        } else if (horizontalPosition === 'right') {
            rectX -= rectWidth;
        }

        const textX = rectX + horizontalSidePadding;
        // offset based on the textBaseline if is set to ideographic
        const textY = rectY + verticalSidePadding + fontSize;

        ctx.strokeStyle = borderColor;
        ctx.fillStyle = backgroundColor;
        ctx.beginPath();
        ctx.roundRect = ctx.roundRect || ctx.rect
        ctx.roundRect(rectX, rectY, rectWidth, rectHeight, borderRadius);

        ctx.stroke();
        ctx.fill();
        ctx.fillStyle = textColor;
        lines.forEach((x, index) => {
            ctx.fillText(x, textX, textY + index * lineHeight);
        });
        ctx.restore()
    }

    const drawSideLabel = (ctx, x, y, text, pivotPosition = 'top-left', scale = 1.0) => {
        ctx.save();

        const textColor = 'rgb(150, 150, 150)';
        const fontSize = 24 * scale;
        // not supported by the current canvas API
        // const letterSpacing = 0.14;

        ctx.font = `${fontSize}px "Mustica Pro", sans-serif`;
        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/textBaseline
        ctx.textBaseline = 'ideographic';

        const textWidth = ctx.measureText(text).width;

        let textX = x;
        let textY = y;
        const [verticalPosition, horizontalPosition] = pivotPosition.split('-');
        if (verticalPosition === 'middle') {
            textY -= fontSize / 2;
        } else if (verticalPosition === 'bottom') {
            textY -= fontSize;
        }
        if (horizontalPosition === 'center') {
            textX -= textWidth / 2;
        } else if (horizontalPosition === 'right') {
            textX -= textWidth;
        }

        ctx.fillStyle = textColor;
        ctx.fillText(text, textX, textY);
        ctx.restore()
    }

    /**
     * @param {HTMLCanvasElement} canvas
     * @param {*} data
     */
    const renderChartData = (canvas, data) => {
        if (!canvas || !data) {
            return;
        }

        const {
            spine,
            leftShoulder,
            rightShoulder,
            leftElbow,
            rightElbow,

            torso,
            leftKnee,
            rightKnee,
            leftAnkle,
            rightAnkle,
        } = data;

        const img = backgroundImageRef.current;
        if (!img?.aspectRatio) {
            return;
        }

        // If canvas can be resized without over-sizing the container than this should be used
        // const container = containerRef.current;
        // let newWidth = container.clientWidth, newHeight = container.clientHeight;
        // canvas.width = newWidth;
        // canvas.height = newHeight;
        // canvas.aspectRatio = `auto ${newWidth} / ${newHeight}`;
        // canvas.styles = {
        //     boxSizing: 'border-box',
        //     display: 'block',
        //     height: `${newHeight}px`,
        //     width: `${newWidth}px`,
        // }

        canvas.width = KinematicCanvasRenderHeight;
        canvas.height = canvas.width / img?.aspectRatio;
        canvas.styles = {
            boxSizing: 'border-box',
            display: 'block',
            height: `${canvas.height}px`,
            width: `${canvas.width}px`,
        }

        const ctx = canvas.getContext('2d');
        ctx.save();

        const { clientWidth: width, clientHeight: height } = canvas;
        ctx.fillStyle = 'rgba(255, 255, 255, 0)';
        ctx.rect(0, 0, width, height);
        ctx.fill();

        const canvasAspectRatio = width / height;
        let imgWidth = width;
        let imgHeight = height;
        let imgPositionX = 0;
        let imgPositionY = 0;
        if (canvasAspectRatio > img.aspectRatio) {
            imgWidth = height * img.aspectRatio;
            imgPositionX = (width - imgWidth) * 0.5;
            ctx.drawImage(img, imgPositionX, 0, imgWidth, imgHeight);
        } else {
            imgHeight = width / img.aspectRatio;
            ctx.drawImage(img, imgPositionX, 0, imgWidth, imgHeight);
        }

        ctx.restore();

        const textScale = 1;

        if (spine || rightShoulder || leftShoulder || rightElbow || leftElbow) {
            drawSideLabel(ctx, imgPositionX + imgWidth * 0.05, imgPositionY + imgHeight * 0.5, t('testReport.kinematic.right'), 'top-left', textScale);
            drawSideLabel(ctx, imgPositionX + imgWidth * 0.95, imgPositionY + imgHeight * 0.5, t('testReport.kinematic.left'), 'top-right', textScale);
        } else {
            drawSideLabel(ctx, imgPositionX + imgWidth * 0.05, imgPositionY + imgHeight * 0.1, t('testReport.kinematic.right'), 'top-left', textScale);
            drawSideLabel(ctx, imgPositionX + imgWidth * 0.95, imgPositionY + imgHeight * 0.1, t('testReport.kinematic.left'), 'top-right', textScale);
        }

        const dataFilter = x => (Boolean(x.value) && x.value !== NoData);
        const buildDataEntry = x => {
            if (x.name) {
                return `${x.name}: ${x.value}`;
            }

            return x.value;
        }

        // Upper Body
        const spineArr = spine ?
            spine.filter(dataFilter).map(buildDataEntry)
            : [];
        if (spineArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.5, imgPositionY + imgHeight * 0.3, spineArr, 'top-center', textScale);
        }
        const rightShoulderArr = rightShoulder ?
            rightShoulder.filter(dataFilter).map(buildDataEntry)
            : [];
        if (rightShoulderArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.44, imgPositionY + imgHeight * 0.1, rightShoulderArr, 'middle-right', textScale);
        }
        const leftShoulderArr = leftShoulder ?
            leftShoulder.filter(dataFilter).map(buildDataEntry)
            : [];
        if (leftShoulderArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.56, imgPositionY + imgHeight * 0.1, leftShoulderArr, 'middle-left', textScale);
        }
        const rightElbowArr = rightElbow ?
            rightElbow.filter(dataFilter).map(buildDataEntry)
            : [];
        if (rightElbowArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.3, imgPositionY + imgHeight * 0.3, rightElbowArr, 'top-right', textScale);
        }
        const leftElbowArr = leftElbow ?
            leftElbow.filter(dataFilter).map(buildDataEntry)
            : [];
        if (leftElbowArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.7, imgPositionY + imgHeight * 0.3, leftElbowArr, 'top-left', textScale);
        }

        // Lower Body
        const torsoArr = torso ?
            torso.filter(dataFilter).map(buildDataEntry)
            : [];
        if (torsoArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.5, imgPositionY + imgHeight * 0.45, torsoArr, 'bottom-center', textScale);
        }
        const leftKneeArr = leftKnee ?
            leftKnee.filter(dataFilter).map(buildDataEntry)
            : [];
        if (leftKneeArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.55, imgPositionY + imgHeight * 0.5, leftKneeArr, 'top-left', textScale);
        }
        const rightKneeArr = rightKnee ?
            rightKnee.filter(dataFilter).map(buildDataEntry)
            : [];
        if (rightKneeArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.45, imgPositionY + imgHeight * 0.5, rightKneeArr, 'top-right', textScale);
        }
        const leftAnkleArr = leftAnkle ?
            leftAnkle.filter(dataFilter).map(buildDataEntry)
            : [];
        if (leftAnkleArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.6, imgPositionY + imgHeight * 0.8, leftAnkleArr, 'top-left', textScale);
        }
        const rightAnkleArr = rightAnkle ?
            rightAnkle.filter(dataFilter).map(buildDataEntry)
            : [];
        if (rightAnkleArr.length) {
            drawInfoBox(ctx, imgPositionX + imgWidth * 0.4, imgPositionY + imgHeight * 0.8, rightAnkleArr, 'top-right', textScale);
        }
    };

    const renderCharts = () => {
        if (!datasets) {
            return;
        }

        for (let index = 0; index < datasets.length; ++index) {
            if (!isPrinting && index !== currentIndex) {
                continue;
            }

            const canvas = canvasesRef.current[index];
            const data = datasets[index]?.data;
            renderChartData(canvas, data);
            if (imagesRef.current[index] && canvas) {
                imagesRef.current[index].src = canvas.toDataURL('image/png');
            }
        }
    }

    useEffect(() => {
        // backgroundImageRef.current = document.crete
        const img = new Image(); // Create new img element
        img.addEventListener(
            'load',
            () => {
                // execute drawImage statements here
                img.aspectRatio = img.width / img.height;
                backgroundImageRef.current = img;
                renderCharts();
            },
            false,
        );
        img.src = '/images/squat.png'; // Set source path
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(renderCharts, [datasets, currentIndex]);

    return (isPrinting ?
        <div ref={containerRef} css={[chartStyles.responsiveContainer, kinematicChartStyles.container]}>
            {datasets.map((x, index) => !x.isDifference &&
                <div key={x.label} className="kinematic-test-chart">
                    {datasets.length > 1 &&
                        <div css={kinematicChartStyles.chartSelection}>
                            <span>{x.label}</span>
                        </div>
                    }
                    <canvas ref={ref => canvasesRef.current[index] = ref} css={kinematicChartStyles.kinematicCanvas} />
                    <img ref={ref => imagesRef.current[index] = ref} alt={'Chart for ' + x.label} />
                </div>
            )}
        </div>
        :
        <div ref={containerRef} css={[chartStyles.responsiveContainer, kinematicChartStyles.container]}>
            {datasets.length > 1 &&
                <div css={kinematicChartStyles.chartSelection}>
                    <span>{datasets[currentIndex].label}</span>
                    <IconButton
                        css={kinematicChartStyles.arrowButtons}
                        tooltip={t('testReport.actions.previousTest')}
                        disabled={currentIndex < 1}
                        onClick={() => $currentTestIndex.set(currentIndex - 1)}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                        css={kinematicChartStyles.arrowButtons}
                        tooltip={t('testReport.actions.nextTest')}
                        disabled={currentIndex >= $lastTestIndex.value}
                        onClick={() => $currentTestIndex.set(currentIndex + 1)}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </div>
            }
            {datasets.map((x, index) => index === currentIndex && !x.isDifference &&
                <React.Fragment key={x.label}>
                    <canvas ref={ref => canvasesRef.current[index] = ref} css={kinematicChartStyles.kinematicCanvas} />
                    <img ref={ref => imagesRef.current[index] = ref} alt={'Chart for ' + x.label} />
                </React.Fragment>
            )}
        </div>
    );
}
