// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../Translation";
import { setStoreState } from "../../globalStates/storeState";
import { useState } from "react";
import { useHook } from "../Utils";
import { downloadCanvasAsImage, exportCSV, exportExcel } from "../../Utils/Common";
import { ChartExportTypes, ChartExportTypesTranslationKeysObj, chartStyles } from "../../Utils/Constants";
import { IconButton } from "../Button";
import { EllipsisVerticalIcon } from "../CustomIcons";
import { Menu, MenuItem, Typography } from "@mui/material";
import { Modal } from "../Modal";
import { Card } from "../Card";

export const ChartExportTypesTranslationKeysArr =
    Object.values(ChartExportTypes)
        .map(value => ({ value, translationKey: ChartExportTypesTranslationKeysObj[value] }));

export const ChartOptionsMenu = ({ chartRef, title, unitType, exportFileName, datasets, chartComponent, additionalSettings = [], isPrinting }) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const dispatch = useDispatch();
    const setStore = (value) => dispatch(setStoreState(value))

    const [exportSelectionAnchor, setExportSelectionAnchor] = useState(null);
    const $isFullscreen = useHook(false);
    const $isPrinting = useHook(false);

    const handleOpenExportMenu = (e) => setExportSelectionAnchor(e.currentTarget);
    const handleCloseExportMenu = () => setExportSelectionAnchor(null);

    const onExport = (exportType) => {
        let exportMethod = exportCSV;
        switch (exportType) {
            case ChartExportTypes.CSV: break;
            case ChartExportTypes.Excel:
                exportMethod = exportExcel;
                break;
            case ChartExportTypes.PNG:
                // chartRef.current.options.plugins.title.display = true;
                // chartRef.current.update('none');
                downloadCanvasAsImage(chartRef.current.canvas, exportFileName, 'png');
                // chartRef.current.options.plugins.title.display = false;
                // chartRef.current.update('none');
                return;
            case ChartExportTypes.JPEG:
                downloadCanvasAsImage(chartRef.current.canvas, exportFileName, 'jpeg');
                return;
            default:
                throw new Error(`Unsupported export type [${exportType}][${ChartExportTypesTranslationKeysObj[exportType]}]`);
        }

        const exportData = [];
        for (const ds of datasets) {
            if (ds.disableExport) {
                continue;
            }

            const exportSettings = ds.exportSettings;
            if (!exportSettings) {
                console.warn(`Chart [${title}] missing exportSettings for dataset ${ds.label}`);
                continue;
            }

            for (let index = 0; index < ds.data.length; ++index) {
                const dataEntry = ds.data[index];
                if (isNaN(dataEntry)) {
                    exportData[index] = {
                        ...exportData[index],
                        [exportSettings.xAxis]: dataEntry.label != null ?
                            dataEntry.label
                            : (exportSettings.xValue && exportSettings.xValue(dataEntry, index, ds)),
                        [exportSettings.yAxis]: dataEntry.value != null ?
                            dataEntry.value
                            : (exportSettings.yValue && exportSettings.yValue(dataEntry, index, ds)),
                    };
                } else {
                    exportData[index] = {
                        ...exportData[index],
                        [exportSettings.xAxis]: dataEntry,
                    };
                }
            }
        }

        exportMethod(
            exportData,
            exportFileName || `chart-data-${(new Date()).toISOString().split('T')[0]}`
        );
    }

    const printHandler = () => {
        handleCloseExportMenu();
        $isFullscreen.set(true);
        $isPrinting.set(true);
        setStore({ ...storeState, hideMainContent: true });

        setTimeout(() => {
            try {
                window.print();
                setStore(storeState);
                $isFullscreen.set($isFullscreen.value);
            } catch (ex) {
                console.error(ex)
            } finally {
                $isPrinting.set(false);
            }
        }, 500);
    }

    const Content = (
        <div className="chart-with-options-container" css={chartStyles.responsiveContainer}>
            {chartComponent}
            <div className="options">
                {!$isPrinting.value && !isPrinting &&
                    <IconButton css={chartStyles.optionsButton} onClick={handleOpenExportMenu}>
                        <EllipsisVerticalIcon />
                    </IconButton>
                }
                <Menu
                    sx={{ mt: '32px' }}
                    // id="chart-options"
                    anchorEl={exportSelectionAnchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(exportSelectionAnchor)}
                    onClose={handleCloseExportMenu}
                >
                    {additionalSettings.map(x =>
                        <MenuItem
                            key={x.key}
                            onClick={() => {
                                x.handleClick();
                                handleCloseExportMenu();
                            }}
                        >
                            <Typography textAlign="left">
                                {x.label}
                            </Typography>
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            $isFullscreen.set(!$isFullscreen.value);
                            handleCloseExportMenu();
                        }}
                    >
                        <Typography textAlign="left">
                            {$isFullscreen.value ?
                                t('chart.options.exitFullScreen')
                                : t('chart.options.viewInFullScreen')
                            }
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={printHandler}
                    >
                        <Typography textAlign="left">{t('chart.options.print')}</Typography>
                    </MenuItem>
                    {ChartExportTypesTranslationKeysArr.map(x => (
                        <MenuItem
                            key={x.value}
                            onClick={() => {
                                handleCloseExportMenu();
                                onExport(x.value);
                            }}
                        >
                            <Typography textAlign="left">{t(x.translationKey)}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </div>
    );

    return (
        $isFullscreen.value ?
            <Modal
                fullScreen
                open={$isFullscreen.value}
                onClose={() => $isFullscreen.set(false)}
            // TransitionComponent={Transition}
            >
                <div css={chartStyles.fullScreenContainer}>
                    <Card title={title} unitType={unitType}>
                        {Content}
                    </Card>
                </div>
            </Modal>
            : Content
    );
};
