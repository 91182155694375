/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button } from '../../../CoreComponents/Button';
import { Input } from '../../../CoreComponents/Input';
import { useTranslation } from '../../../CoreComponents/Translation';
import { ForceValidateContext, useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { PillarService } from '../../../Services/PillarService';
import { maxLengthValidator, requiredValidator } from '../../../Utils/Validators';

const addPillarContentStyles = {
    root: css`
        display: flex;
        flex-direction: column;
        min-height: 340px;
        // height: 85vh;
        padding: 40px 0 40px 40px;

        width: unset;
        @media (min-width: 580px) {
            width: 480px;
        }

        & > h2 {
            width: 100%;
            margin: 0 0 40px 0;
        }

        & > .input-root {
            margin: 0;
            width: 100%;
        }

        & > .actions {
            display: flex;
            margin-top: auto;
            width: 100%;
            & > button:first-of-type {
                margin-right: 10px;
                margin-left: auto;
            }
        }
    `,
};

const AddPillarValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
};

export const AddPillarContent = ({onCancel, onAdd}) => {
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const { t } = useTranslation();

    const $newPillar = useObjectHook(
        {
            name: '',
        },
        AddPillarValidator,
        null,
        $isForceValidating.value
    );
    const addPillar = () => {
        // if we want to validate after submission
        // then it should be set to false by default

        $isForceValidating.set(true);
        const errors = $newPillar.validate();
        if (errors) {
            return;
        }

        const newPillar = {
            ...$newPillar.value,

            name: $newPillar.value.name
        };
        $newPillar.set(newPillar);

        $isSaving.set(true);
        PillarService.postPillar(newPillar)
            .then(() => onAdd(newPillar))
            .finally(() => $isSaving.set(false));

    };
    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addPillarContentStyles.root}>
                <h2>{t('pillars.addModal.title')}</h2>

                <Input placeholder={t('input.placeholder')} required label={t('pillars.fieldName.name')} $value={$newPillar.getPropHook('name')} />

                <div className="actions" css={addPillarContentStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newPillar.isValid} onClick={addPillar}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
}
