/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useTranslation } from '../../../CoreComponents/Translation';
import { useHook, useObjectHook } from '../../../CoreComponents/Utils';
import { TableCSF } from '../../../CoreComponents/TableComponents/TableCSF';
import { Button} from '../../../CoreComponents/Button';
import { Modal } from '../../../CoreComponents/Modal';
import { AddCategoryContent } from './AddCategoryContent';
import { EditCategoryContent } from './EditCategoryContent';

import { EditIcon } from '../../../CoreComponents/CustomIcons';
import { IconButton } from '../../../CoreComponents/Button';
import { CategoryService } from '../../../Services/CategoryService';
import { AdminTypes } from '../../../Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setCategories } from '../../../globalStates/tableDataState';

const categoriesStyles = {
    root: css`
        padding: 20px;
        width: 100%;
        height: 100%;
    `,
    actions: css`
        position: relative;
        & > button {
            min-width: unset;
            margin-right: 8px;
        }
    `,
}

export const CategoriesList = ({$selectedCategory, $refreshCategories}) => {
    const { t } = useTranslation();
    const storeState = useSelector((state) => state.storeState);
    const tableDataState = useSelector((state) => state.tableDataState);
    const dispatch = useDispatch();
    const isTrazerViewAdmin = storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERView;
    const $categories = useHook(tableDataState.categories);
    const $isLoading = useHook(!Boolean(tableDataState.categories.length));
    const $isAddCategoryModalOpened = useHook(false);
    const headCells = useMemo(() => [
        {
            id: 'actions',
            label: '',
            columnWidth: 100,
            CellRender: ({ rowData }) => {
                return (
                    <>
                        <IconButton disabled={isTrazerViewAdmin} onClick={() => $selectedCategory.set(rowData)}>
                            <EditIcon />
                        </IconButton>
                    </>
                );
            }
        },
        {
            id: 'name',
            label: t('categories.table.name'),
            isSortable: true,
        },
    ],[t]);

    useEffect(() => {
        CategoryService.getAll(9999)
        .then(categories => {
            dispatch(setCategories(categories));
            $categories.set(categories);
            $isLoading.set(false);
        })
    },[$refreshCategories.value]);

    return (
        <div css={categoriesStyles.root}>
            <TableCSF
                customActions={
                    <div css={categoriesStyles.actions}>
                        <Button disabled={isTrazerViewAdmin} onClick={() => $isAddCategoryModalOpened.set(true)} >
                            <AddIcon />
                        </Button>
                    </div>
                }
                headCells={headCells}
                rows={$categories.value}
                rowKeySelector={r => r.categoryId}
                isLoading={$isLoading.value}
                totalItemsCount={$categories.value.length}
                showAdvancedSearch={false}
            />
            <Modal
                open={$isAddCategoryModalOpened.value}
                onClose={() => $isAddCategoryModalOpened.set(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddCategoryContent onCancel={() => $isAddCategoryModalOpened.set(false)}
                    onAdd={() => {
                        $isAddCategoryModalOpened.set(false);
                        $refreshCategories.set({});
                    }} />
            </Modal>
        </div>
    )
};

export const Categories = () => {
    const $selectedCategory = useObjectHook(null);
    const $refreshCategories = useHook({});
    const $prevSelectedCategories = useHook(null);

    useEffect(() => {
        if ($prevSelectedCategories.value && $selectedCategory.value) {
            // changes...
            $refreshCategories.set({});
            $prevSelectedCategories.set(null);
            $selectedCategory.set(null);
            return;
        }

        $prevSelectedCategories.set($selectedCategory.value);
    }, [$selectedCategory.value]);

    return (
        <>
            <CategoriesList $selectedCategory={$selectedCategory} $refreshCategories={$refreshCategories}/>
            <Modal
                open={!!$selectedCategory.value}
                onClose={() => $selectedCategory.set(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableCloseButton
            >
                <EditCategoryContent $selectedCategory={$selectedCategory} onCancel={() => $selectedCategory.set(null)} />
            </Modal>
        </>
    )
}
