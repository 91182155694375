/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { subMonths} from 'date-fns';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../CoreComponents/Button';
import { ChevronLeftIcon, PrintIcon } from '../../CoreComponents/CustomIcons';
import { DateRangeSelector } from '../../CoreComponents/DateRangeSelector';
import { CustomScrollContainer } from '../../CoreComponents/Layout';
import { CircularLoader } from '../../CoreComponents/Loaders';
import { useTranslation } from '../../CoreComponents/Translation';
import { useHook, useObjectHook } from '../../CoreComponents/Utils';
import { UserService } from '../../Services/UserService';
import { shortDate, durationInHours, metersToFeet, parseDate } from '../../Utils/Common';
import { NoData, UnitType, UnitTypeTranslationKeysArr } from '../../Utils/Constants';
import { Card } from '../../CoreComponents/Card';
import { UserTestsTab } from './UserTestsTable';
import { LineChart } from '../../CoreComponents/ChartComponents/LineChart';
import { SingleSelect } from '../../CoreComponents/SelectListComponents/SelectList';
import { SelectOption } from '../../CoreComponents/SelectListComponents/SelectOption';

const asideWidth = '520px';
const userReportStyles = {
    loadingContainer: css`
        display: flex;
        height: 100%;
        width: 100%;
    `,
    layout: css`
        display: flex;
        padding: 20px 40px;
        height: 100%;

        & aside {
            display: flex;
            flex-direction: column;

            // display: none;
            // width: 448px;
            width: ${asideWidth};
            min-width: ${asideWidth};
            padding-right: 39px;
            border-right: 2px solid #CBCBCB;
            height: 100%;

            & .user-info {
                display: flex;
                flex-direction: column;
                height: 100%;

                & > div:last-of-type {
                    flex: 1 0;
                }

                & dl {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    overflow: visible;

                    & dt {
                        float: left;
                        clear: left;
                        width: calc(50% - 20px);

                        text-align: right;
                        font: normal normal normal 16px/24px Roboto;
                        letter-spacing: 0.14px;
                        color: #858585;
                    }
                    & dd {
                        margin: 0 0 0 40px;
                        padding: 0 0 11px 0;
                        width: calc(50% - 20px);

                        text-align: left;
                        font: normal normal bold 16px/24px Mustica Pro;
                        letter-spacing: 0.14px;
                        color: #676767;
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            // height: 100%;
            // display: block;
            // overflow: auto;

            & aside {
                width: unset;
                min-width: unset;
                // height: auto;
                // width: 100%;
                // border-right: none;
            }
        }

        @media print {
            height: auto;
            display: block;
            // flex-direction: column;

            & aside {
                width: 100%;
                border-right: none;

                & .user-info {
                    break-inside: avoid;
                }
            }
        }
    `,
    userContent: css`
        // width: 100%;
        width: calc(100% - ${asideWidth});
        height: 100%;
        overflow: clip;

        & .content {
            // 90px controls and padding
            height: calc(100% - 90px);
            padding: 0 40px;
            // width: calc(100vw - 448px - 40px);
            gap: 20px;
            display: flex;
            flex-direction: column;
        }

        & .controls {
            padding: 0 40px 50px 40px;
            background: #EEEEEE 0% 0% no-repeat padding-box;

            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & .date-range-selector {
                margin-left: auto;
            }

            & > button {
                width: unset;

                &.go-back-action {
                    min-width: unset;
                }

                &.print-action {
                    min-width: unset;
                    // margin-left: 20px;
                    margin-right: 10px;
                }
            }

            & > .sections {
                height: 40px;
                display: flex;
                margin-right: auto;
                margin-left: 20px;

                & > button {
                    border-radius: 0;
                    border: 2px solid #00ABA5;
                    color: #00ABA5;
                }

                & > button.active {
                    color: #FFFFFF;
                    background: #00ABA5 0% 0% no-repeat padding-box;
                }

                & > button:first-of-type {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                & > button:last-of-type {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }

        @media (max-width: 1200px) {
            overflow: unset;
            width: 100%;
            height: auto;

            & .content {
                height: auto;
                padding: 1px;
                overflow: unset;
            }
        }

        @media print {
            overflow: unset;
            width: 100%;
            height: auto;

            & .controls {
                display: none;
            }

            & .content {
                height: auto;
                padding: 1px;
                overflow: unset;
            }
        }
    `,
};

const ReportSections = {
    Weekly: 1,
    History: 2,
    // Stats: 3,
};

export const ReportSectionsTranslationKeysObj = {
    [ReportSections.Weekly]: 'userReport.section.weekly',
    [ReportSections.History]: 'userReport.section.history',
    // [ReportSections.Stats]: 'userReport.section.stats',
};

export const ReportSectionsTranslationKeysArr =
    Object.values(ReportSections)
        .map(value => ({ value, translationKey: ReportSectionsTranslationKeysObj[value] }));

export const UserReport = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const { t, /* tWithHtml */ } = useTranslation();
    const $isLoading = useHook(false);
    const $dateRange = useObjectHook({
        dateInterval: 'week',
        startDate: subMonths(new Date(), 2),
        endDate: new Date(),
    });
    const $selectedUnitType = useHook(UnitType.Feet);
    const $isPrinting = useHook(false);
    const $selectedUser = useHook({});
    const $rawData = useHook({
        user: null,
        overview: {
            name: '',
            totalTime: 0,
            totalCalories: 0,
            totalDistance: 0,
            totalTargets: 0,
        },
        tests: [],
        distance: [],
        calories: [],
        targets: [],
    });
    const $reportData = useHook(null);
    const $selectedSection = useHook(1);

    useEffect(() => {
        (async () => {
            $isLoading.set(true);
            const startDate = $dateRange.value.startDate.toISOString().split('T')[0];
            const endDate = $dateRange.value.endDate.toISOString().split('T')[0];

            const [
                user,
                overview,
                tests,
                distance,
                calories,
                targets,
            ] = await Promise.all([
                UserService.getUser(userId),
                UserService.overview(
                    userId,
                    // Uncomment this if you want the user overview information to be affected by the date range selector
                    // startDate,
                    // endDate
                ),
                UserService.overviewTrend(
                    userId,
                    'tests',
                    $dateRange.value.dateInterval,
                    startDate,
                    endDate
                ),
                UserService.overviewTrend(
                    userId,
                    'distance',
                    $dateRange.value.dateInterval,
                    startDate,
                    endDate
                ),
                UserService.overviewTrend(
                    userId,
                    'calories',
                    $dateRange.value.dateInterval,
                    startDate,
                    endDate
                ),
                UserService.overviewTrend(
                    userId,
                    'targets',
                    $dateRange.value.dateInterval,
                    startDate,
                    endDate
                ),
            ]);
            $selectedUser.set(user);
            $rawData.set({
                user: user,
                overview: {
                    totalTime: overview.totalTime || 0,
                    totalCalories: overview.totalCalories || 0,
                    totalDistance: overview.totalDistance || 0,
                    totalTargets: overview.totalTargets || 0,
                },
                tests: (tests || []).map(x => ({ label: shortDate(parseDate(x.item1, 'YYYY-ww')), value: x.item2 })),
                distance: (distance || []).map(x => ({ label: shortDate(parseDate(x.item1, 'YYYY-ww')), value: x.item2 })),
                calories: (calories || []).map(x => ({ label: shortDate(parseDate(x.item1, 'YYYY-ww')), value: x.item2 })),
                targets: (targets || []).map(x => ({ label: shortDate(parseDate(x.item1, 'YYYY-ww')), value: x.item2 })),
            });
            $isLoading.set(false);
        })();
    }, [$dateRange.value]);

    useEffect(() => {
        if (!$rawData.value) {
            return;
        }

        const convertUnits = (metersValue, fractionDigits = 2) =>
            ($selectedUnitType.value === UnitType.Meters ?
                metersValue
                : metersToFeet(metersValue)
            ).toFixed(fractionDigits)

        const distanceChartTitle = $selectedUnitType.value === UnitType.Meters ?
            t('userReport.chartData.distanceMeters')
            : t('userReport.chartData.distanceFeet');
        $reportData.set({
            overview: {
                name: $rawData.value.user ?
                    `${$rawData.value.user.firstName} ${$rawData.value.user.lastName}`
                    : NoData,
                ...$rawData.value.overview,
                totalTime: durationInHours($rawData.value.overview.totalTime),
                totalDistance: convertUnits($rawData.value.overview.totalDistance),
                totalCalories: $rawData.value.overview.totalCalories.toFixed(2),
            },
            distance: [{
                label: distanceChartTitle,
                exportSettings: {
                    xAxis: t('userReport.chartExportDataField.date'),
                    yAxis: distanceChartTitle,
                },
                data: $rawData.value.distance.map(x => ({
                    ...x,
                    value: convertUnits(x.value),
                })),
            }],
            sessions: [{
                label: t('userReport.chartData.sessions'),
                exportSettings: {
                    xAxis: t('userReport.chartExportDataField.date'),
                    yAxis: t('userReport.chartData.sessions'),
                },
                data: $rawData.value.tests,
            }],
            calories: [{
                label: t('userReport.chartData.calories'),
                exportSettings: {
                    xAxis: t('userReport.chartExportDataField.date'),
                    yAxis: t('userReport.chartData.calories'),
                },
                data: $rawData.value.calories,
            }],
            targets: [{
                label: t('userReport.chartData.targets'),
                exportSettings: {
                    xAxis: t('userReport.chartExportDataField.date'),
                    yAxis: t('userReport.chartData.targets'),
                },
                data: $rawData.value.targets,
            }],
        });
    }, [t, $rawData.value, $selectedUnitType.value]);

    const unitTypeString = $selectedUnitType.value === UnitType.Meters ?
        t('constants.unitType.metersShort')
        : t('constants.unitType.feetShort');

    return (!$reportData.value ?
        <div css={userReportStyles.loadingContainer}>
            <CircularLoader />
        </div>
        :
        <div css={userReportStyles.layout}>
            <aside>
                <div>
                    <h1>{t('userReport.section.userInformation')}</h1>
                </div>
                <div className="user-info">
                    {/* {userId} */}
                    <dl>
                        <dt>{t('userReport.userInfo.name')}</dt>
                        <dd>{$reportData.value.overview.name}</dd>
                        <dt>{t('userReport.userInfo.totalTime')}</dt>
                        <dd>{$reportData.value.overview.totalTime}</dd>
                        <dt>{t('userReport.userInfo.totalDistance')}</dt>
                        <dd>{$reportData.value.overview.totalDistance} {unitTypeString}</dd>
                        <dt>{t('userReport.userInfo.totalCalories')}</dt>
                        <dd>{$reportData.value.overview.totalCalories} {t('constants.unitType.calories')}</dd>
                        <dt>{t('userReport.userInfo.totalTargets')}</dt>
                        <dd>{$reportData.value.overview.totalTargets}</dd>
                    </dl>
                </div>
            </aside>
            <div css={userReportStyles.userContent}>
                <div className="controls">
                    <Button
                        className="go-back-action"
                        onClick={() => window.history.length > 2 ?
                            navigate(-1)
                            : navigate('/manage/users')
                        }
                    >
                        <ChevronLeftIcon />
                    </Button>
                    <div className="sections">
                            {ReportSectionsTranslationKeysArr.map(x => {
                                return (
                                    <Button
                                        key={x.value}
                                        {...($selectedSection.value !== x.value ?
                                            { variant: 'outlined' }
                                            : { className: 'active' }
                                        )}
                                        onClick={() => {
                                            $selectedSection.set(x.value);
                                        }}
                                    >
                                        {t(x.translationKey)}
                                    </Button>
                                )
                            })}
                        </div>
                    <DateRangeSelector
                        label={t('userReport.controls.dateRange')}
                        $value={$dateRange}
                        design="slim"
                        labelProps={{
                            className: 'date-range-selector'
                        }}
                    />
                    <SingleSelect
                        label={t('userReport.controls.unitType')}
                        design="slim"
                        $value={$selectedUnitType}
                        disableFiltering
                    >
                        {UnitTypeTranslationKeysArr.map(x => (
                            <SelectOption key={x.value} value={x.value}>
                                {t(x.translationKey)}
                            </SelectOption>
                        ))}
                    </SingleSelect>
                    <Button
                        className="print-action"
                        disabled={$isPrinting.value || $isLoading.value}
                        onClick={() => {
                            $isPrinting.set(true);
                            setTimeout(function () {
                                window.print();
                                $isPrinting.set(false);
                            }, 1000);
                        }}
                    >
                        <PrintIcon />
                    </Button>
                </div>

                {$isLoading.value ?
                    <div css={userReportStyles.loadingContainer}>
                        <CircularLoader />
                    </div>
                    :
                    <CustomScrollContainer className="content">
                        {$selectedSection.value === ReportSections.History &&
                            <UserTestsTab $selectedUser={$selectedUser}></UserTestsTab>
                        }
                        {$selectedSection.value === ReportSections.Weekly &&
                            <>
                                <Card title={t('userReport.chartTitle.distance')}>
                                <LineChart
                                    title={t('userReport.chartTitle.distance')}
                                    exportFileName={t('userReport.chartTitle.distance')}
                                    enableOptionsMenu
                                    datasets={$reportData.value.distance}
                                    xAxisLabel={t('userReport.distance.xAxis')}
                                    yAxisLabel={t('userReport.distance.yAxis', unitTypeString)}
                                    isPrinting={$isPrinting.value}
                                />
                                </Card>
                                <Card title={t('userReport.chartTitle.sessions')}>
                                    <LineChart
                                        title={t('userReport.chartTitle.sessions')}
                                        exportFileName={t('userReport.chartTitle.sessions')}
                                        enableOptionsMenu
                                        datasets={$reportData.value.sessions}
                                        xAxisLabel={t('userReport.sessions.xAxis')}
                                        yAxisLabel={t('userReport.sessions.yAxis')}
                                        isPrinting={$isPrinting.value}
                                    />
                                </Card>
                                <Card title={t('userReport.chartTitle.calories')}>
                                    <LineChart
                                        title={t('userReport.chartTitle.calories')}
                                        exportFileName={t('userReport.chartTitle.calories')}
                                        enableOptionsMenu
                                        datasets={$reportData.value.calories}
                                        xAxisLabel={t('userReport.calories.xAxis')}
                                        yAxisLabel={t('userReport.calories.yAxis')}
                                        isPrinting={$isPrinting.value}
                                    />
                                </Card>
                                <Card title={t('userReport.chartTitle.targets')}>
                                    <LineChart
                                        title={t('userReport.chartTitle.targets')}
                                        exportFileName={t('userReport.chartTitle.targets')}
                                        enableOptionsMenu
                                        datasets={$reportData.value.targets}
                                        xAxisLabel={t('userReport.targets.xAxis')}
                                        yAxisLabel={t('userReport.targets.yAxis')}
                                        isPrinting={$isPrinting.value}
                                    />
                                </Card>
                            </>
                        }
                    </CustomScrollContainer>
                }
            </div>
        </div>
    );
};
