/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useTranslation } from "../../../CoreComponents/Translation";
import { ForceValidateContext, useHook, useObjectHook } from "../../../CoreComponents/Utils";
import { maxLengthValidator, requiredValidator } from "../../../Utils/Validators";
import { BaseActivityService } from "../../../Services/BaseActivityService";
import { Input, TextareaInput } from "../../../CoreComponents/Input";
import { Button } from "../../../CoreComponents/Button";
import { ActivityTypesColors, ActivityTypesTranslationKeysArr } from "../../../Utils/Constants";
import { AlertText } from "../../../CoreComponents/Alert";
import { SingleSelect } from "../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../CoreComponents/SelectListComponents/SelectOption";

const addBaseActivityStyles = {
    root: css`
        width: unset;
        display: flex;
        flex-direction: column;
        padding: 40px 0 40px 40px;

        @media (min-width: 580px) {
            width: 480px;
        }

        & > .input-root {
            margin-left: 0;
            width: 100%;
        }

        & > h2 {
            width: 100%;
            margin: 0;
            margin-bottom: 40px;
        }

        & > .actions {
            margin-top: auto;
            width: 100%;
            display: flex;

            & > button:first-of-type {
                margin-right: 20px;
                margin-left: auto;
            }
        }

        & > .alert-text-root {
            margin: 0 0 20px 0;
            align-self: end;
        }

        & .selected-item, & .select-option {
            display: flex;
            & > * {
                margin: auto 0;
            }
        }

        & textarea {
            resize: none;
            overflow-y: auto;
        }
    `,
};

const addBaseActivityValidator = {
    name: [requiredValidator, maxLengthValidator(50)],
    activityTypeId: [requiredValidator],
    defaultSettings: [
        requiredValidator,
        (value) => {
            try {
                if (JSON.parse(value)) {
                    return [];
                }
            } catch {
                return ['Failed to parse JSON'];
            }
        }
    ],
};

export const AddBaseActivitiesContent = ({ onCancel, onAdd }) => {
    const $isSaving = useHook(false);
    const $isForceValidating = useHook(false);
    const { t } = useTranslation();

    const $newBaseActivity = useObjectHook(
        {
            name: '',
            activityTypeId: '',
            defaultSettings: '',
        },
        addBaseActivityValidator,
        null,
        $isForceValidating.value
    );

    const addBaseActivity = () => {
        $isForceValidating.set(true);

        const errors = $newBaseActivity.validate();
        if (errors) {
            return;
        }

        $isSaving.set(true);
        BaseActivityService.postBaseActivity($newBaseActivity.value)
            .then(() => onAdd($newBaseActivity.value))
            .finally(() => $isSaving.set(false));
    };
    return (
        <ForceValidateContext.Provider value={$isForceValidating.value}>
            <div css={addBaseActivityStyles.root}>
                <h2>{t('baseActivities.addModal.title')}</h2>

                <Input placeholder={t('input.placeholder')} required label={t('baseActivities.fieldName.name')} $value={$newBaseActivity.getPropHook('name')} />

                <SingleSelect $value={$newBaseActivity.getPropHook('activityTypeId')} required label={t('baseActivities.fieldName.type')}>
                    {ActivityTypesTranslationKeysArr.map(x =>
                        <SelectOption key={x.value} value={x.value}>
                            <div style={{
                                backgroundColor: ActivityTypesColors[x.value],
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                marginRight: '8px',
                                }}>
                            </div>
                            {t(x.translationKey)}
                        </SelectOption>
                    )}
                </SingleSelect>

                <TextareaInput
                    textAreaProps={{ style: { minHeight: 200 } }}
                    label="Settings"
                    required
                    placeholder="Type here..."
                    $value={$newBaseActivity.getPropHook('defaultSettings')}
                />

                {$newBaseActivity.hasEmptyFields && <AlertText variant="large">{t('validatorErrors.emptyFormWarning')}</AlertText>}
                <div className="actions" css={addBaseActivityStyles.actions}>
                    <Button variant="outlined" disabled={$isSaving.value} onClick={onCancel}>{t('actions.cancel')}</Button>
                    <Button disabled={$isSaving.value || !$newBaseActivity.isValid} onClick={addBaseActivity}>{t('actions.add')}</Button>
                </div>
            </div>
        </ForceValidateContext.Provider>
    )
}
