/* eslint-disable react-hooks/exhaustive-deps */
// The next line is required for the css prop to work!
/** @jsxImportSource @emotion/react */

/* eslint-disable react-hooks/exhaustive-deps */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useTranslation } from "../../../../CoreComponents/Translation";
import { useHook } from "../../../../CoreComponents/Utils";
import { CustomerService } from "../../../../Services/CustomerService";
import { GroupService } from "../../../../Services/GroupService";
import { SiteService } from "../../../../Services/SiteService";
import { AdminTypes } from "../../../../Utils/Constants";
import { useSelector } from "react-redux";
import { SingleSelect } from "../../../../CoreComponents/SelectListComponents/SelectList";
import { SelectOption } from "../../../../CoreComponents/SelectListComponents/SelectOption";

const attachStepStyle = {
    hint: css`
        text-align: center;
        margin-top: 78px;
        margin-bottom: 74px;
    `,
    dropdownContainer: css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > label {
            min-width: 600px;
        }

    `
}

export const AttachStep = ({ $disableNextButton, $selectedSite, $selectedGroup, $selectedCustomer }) => {
    const storeState = useSelector((state) => state.storeState);
    const $customers = useHook([]);
    const $sites = useHook([]);
    const $groups = useHook([]);
    const { t } = useTranslation();

    useEffect(() => {
        CustomerService.getAll(9999, 'Name', true)
            .then(customers => $customers.set(customers));
    }, [])

    useEffect(() => {
        $disableNextButton.set(!$selectedSite.value);
    }, [$selectedSite.value])

    useEffect(() => {
        if (!$selectedCustomer.value) {
            return;
        }
        $selectedSite.set(null);
        $selectedGroup.set(null);
        $groups.set([]);
        SiteService.getAll(9999, $selectedCustomer.value, null, 'Name', true)
            .then(sites => {
                $sites.set(sites);
            });
    }, [$selectedCustomer.value])

    useEffect(() => {
        if (!$selectedCustomer.value) {
            return;
        }
        GroupService.getGroups(9999, $selectedCustomer.value, $selectedSite.value)
            .then(groups => $groups.set(groups));
    }, [$selectedCustomer.value, $selectedSite.value])

    return (
        <div>
            <h2 css={attachStepStyle.hint}>{t('importUsers.attachStep.hint')}</h2>
            <div css={attachStepStyle.dropdownContainer}>
                {storeState.currentUser.admin.adminTypeId === AdminTypes.TRAZERAdmin ?
                    <SingleSelect $value={$selectedCustomer} required label={t('importUsers.attachStep.customer')}>
                        {$customers.value.map(g => <SelectOption key={g.customerId} value={g.customerId}>{g.name}</SelectOption>)}
                    </SingleSelect>
                    :
                    null
                }
                <SingleSelect $value={$selectedSite} disabled={!$selectedCustomer.value} required label={t('importUsers.attachStep.site')}>
                    {$sites.value.map(g => <SelectOption key={g.siteId} value={g.siteId}>{g.name}</SelectOption>)}
                </SingleSelect>
                <SingleSelect $value={$selectedGroup} disabled={!$selectedCustomer.value} label={t('importUsers.attachStep.userGroup')}>
                <SelectOption key={0} value={0}>{t('dropdown.emptyOption')}</SelectOption>
                    {$groups.value.map(g => <SelectOption key={g.groupId} value={g.groupId}>{g.groupName}</SelectOption>)}
                </SingleSelect>
            </div>
        </div>
    )
}
